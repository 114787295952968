import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Asset } from '../models/asset';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { myComponents } from 'src/environments/environment';
import { PopupServiceService } from './popup-service.service';
import { FrontSelection } from '../models/front-selection';
import { ifNullReturnTiret } from './functions';

@Injectable({
  providedIn: 'root'
})
export class ParameterVitrineVariableService {

  myComponents: any;
  
  constructor(private popupService: PopupServiceService) { 
    this.myComponents = myComponents;
  }

  breakingNews: {
    Popup: { 
      backgroundColorContent: string;
      colorContent: string;
    }
  } = {
    Popup: { 
      backgroundColorContent: '#ffffff',
      colorContent: '#303a44'
    }
  }

  accpetedRuleProjectCTC2: {
    Popup: { 
      backgroundColorContent: string;
      colorContent: string;
    }
  } = {
    Popup: { 
      backgroundColorContent: '#ffffff',
      colorContent: '#303a44'
    }
  }

  salesOffers: {
    Popup: { 
      backgroundColorContent: string;
      colorContent: string;
    }
  } = {
    Popup: { 
      backgroundColorContent: '#ffffff',
      colorContent: '#303a44'
    }
  }

  pageStock: {
    checked: boolean;
    isActivedMenu: string;
    initMapMarker: any;
    initPaginationSroll: boolean;
    customPaginationSroll: boolean;
    nbAssetReturnPagiantion: number;
    size_2_WidthContainerMap: string;
    size_WidthContainerMap: string;

    initParmMap: any,
    zoomMap: number;
  } = {
    checked: true,
    isActivedMenu: 'mixte',
    initMapMarker: { TopLeft:{ Latitude: 51.49506473014368, Longitude: -5.9326171875 }, BottomRight: { Latitude: 41.73852846935917, Longitude: 9.074707031250002 }},
    initPaginationSroll: true,
    customPaginationSroll: true,
    nbAssetReturnPagiantion: 11,
    size_2_WidthContainerMap: '390px',
    size_WidthContainerMap: '25%',

    initParmMap: { attributionControl: false },
    zoomMap: 5.4,
  };

  pageOldStock: {
    activationMap: Boolean
  } = {
    activationMap: true
  }

  public getLatLongCenterMap(dataCoordinateMap: any) {
    return [ dataCoordinateMap.Latitude.Min - (dataCoordinateMap.Latitude.Min * 0.1), dataCoordinateMap.Longitude.Max - (dataCoordinateMap.Longitude.Max * 0.1) ]
  }
  
  public Carousel_ConstructionSection(selection: FrontSelection) {
    return { numSection: selection['front.if_sequence'], idOperation: selection['operation.if_operationid'], 
    nameStock: ifNullReturnTiret(selection['city.if_name'], 0) + ' (' + selection['department.if_code'] + ')', nameProgram: ifNullReturnTiret(selection['operation.if_operationbusinessname_calc'], 0), 
    courtDescription: ifNullReturnTiret(selection['front.if_mkgtext1'], 0) , longDescription: ifNullReturnTiret(selection['front.if_mkgtext2'], 0), 
    location: ifNullReturnTiret(selection['operation.if_address_street1'], 0), namePromoter: ifNullReturnTiret(selection['operation.if_developeraccountid_label'], 0), 
    image: "../../../../Resources/" + selection['front.if_operation_visual_docid'] + ".jpg", statutOperation: '' }
  }

  public CardLot_PostOptionLot(size: SCREEN_SIZE, namePopup: string, dataPopup: any) {
    let maxWidth =  '80vw'
    let minHeight = '492px'
    let  width = '689px';
    let height = '492px';
    let panelClass = '';

    let dialogRef: any = null
    if (size !== undefined) {
      if (size < 2) {
        if (namePopup == 'postOptionLot') {
          maxWidth = '95vw';
          width = 'auto';
          height = 'auto';    
          minHeight = 'auto';
          panelClass = 'CustomPostOptionSM'
        }
      }
    }

    this.popupService.openPopupCardLot_PostOption(namePopup, dataPopup, size, width, height, maxWidth, minHeight, panelClass, 'stock')
  }

  public PageDescriptionProgramme_returnTabFilterAsset(tabAssetsSelected: Array<Asset>, tagFilterAsset: Array<any>) {
    tabAssetsSelected.sort((a_assetsSelected, b_assetsSelected) => (a_assetsSelected['assettype.if_roomnumber'] < b_assetsSelected['assettype.if_roomnumber'] ? -1 : 1) * (1 ? 1 : -1))
    
    tabAssetsSelected.forEach((asset: Asset) => {
      let priceMin = Infinity;
      let priceMax = 0;

      let prixTTC = Math.round(asset['pricing.if_pricenetcom_tax1_cur']);

      let indexFind = tagFilterAsset.findIndex((element) => element.nbPiece == asset['assettype.if_roomnumber'])
      if (indexFind !== (-1)) {

        tagFilterAsset[indexFind].nbLot += 1;

        if (prixTTC !== 0) {
          tagFilterAsset[indexFind].priceMin = tagFilterAsset[indexFind].priceMin > prixTTC ? prixTTC : tagFilterAsset[indexFind].priceMin;
          tagFilterAsset[indexFind].priceMax = tagFilterAsset[indexFind].priceMax < prixTTC ? prixTTC : tagFilterAsset[indexFind].priceMax;
        }
      } else {
        if (prixTTC !== 0) {
          priceMin = priceMin > prixTTC ? prixTTC : priceMin;
          priceMax = priceMax < prixTTC ? prixTTC : priceMax;
        }

        tagFilterAsset.push({
          nbPiece: asset['assettype.if_roomnumber'],
          nbLot: 1,
          priceMin: priceMin,
          priceMax: priceMax,
        })
      }

      if (prixTTC !== 0) {
        tagFilterAsset[0].priceMin = tagFilterAsset[0].priceMin > prixTTC ? prixTTC : tagFilterAsset[0].priceMin;
        tagFilterAsset[0].priceMax = tagFilterAsset[0].priceMax < prixTTC ? prixTTC : tagFilterAsset[0].priceMax;
      }
      
    });

    return tagFilterAsset;
  }
}
