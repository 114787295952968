import { User } from 'src/app/shared/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { UserRole } from '../models/user-role';
import { AuthCasApereoService } from './auth-cas-apereo.service';

interface responseApi {
  datas: Array<User>;
  sqls: Array<string>;
}

@Injectable({
	providedIn: 'root'
})
export class AuthentificationService {
	static URL = environment.urlServer;

  headers: HttpHeaders = new HttpHeaders();

	constructor(private http: HttpClient, private userService: UserService, private authCasApereo: AuthCasApereoService) {}

  public login(Login: string, Password: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });
      
		return this.http
			.post(AuthentificationService.URL + 'Users/Token', JSON.stringify({ Login, Password }), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          this.headers = new HttpHeaders({
            'accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': response.body.datas
          });
          localStorage.setItem('TOKEN', response.body.datas);
				  // const token: any = response.headers.get('JWT-TOKEN');
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					// localStorage.setItem('TOKEN', token);
					// this.userService.user = response.body;
					return response.body.datas;
				})
      );
	}

  public getUserProfiles(): Observable<any> {
    return this.http
      .get(AuthentificationService.URL + 'Users/Profiles', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getChangeAffectationToken(idAssigment: string): Observable<any> {
    return this.http
      .get(AuthentificationService.URL + 'Users/ChangeAffectationToken/' + idAssigment, { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.headers = new HttpHeaders({
            'accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': response.body.datas
          });
          localStorage.setItem('TOKEN', response.body.datas);
          return response.body.datas;
        })
      );
  }

  public forgotPassword(email: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });
      
		return this.http
			.put(AuthentificationService.URL + 'Users/AskResetPassword/' + email, JSON.stringify({ email }), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body.datas)
          return response.body.datas;
        })
      );
	}

  public resetPassword(Id: string, Token: string, NewPassword: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });
      
		return this.http
			.put(AuthentificationService.URL + 'Users/ResetPassword', JSON.stringify({ Id, Token, NewPassword }), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body.datas)
          return response.body.datas;
        })
      );
	}

  public changePasswordSimple(NewPassword: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });
      
		return this.http
			.put(AuthentificationService.URL + 'Users/ChangePassword', JSON.stringify({ NewPassword }), { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body.datas)
          return response.body.datas;
        })
      );
	}

  public activeConditionsRead(): Observable<any> {
      
		return this.http
			.put(AuthentificationService.URL + 'Users/ConditionsRead', JSON.stringify({}), { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body.datas)
          return response.body.datas;
        })
      );
	}

  // /api/Users/ChangePassword

  // /api/Users/AskResetPassword/{email}














	public loginBefore(Login: string, Password: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });
      
		return this.http
			.post(AuthentificationService.URL + 'Users/Profiles', JSON.stringify({ Login, Password }), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response);
          let choiceRoleUser: Array<UserRole> = new Array<UserRole>();
          response.body.datas.forEach((profilUser: User) => {
            console.log(profilUser);
            //A l'avenir garder que le GUID
            choiceRoleUser.push({ dataUser: profilUser, role: profilUser['role.if_name'] })
          });

          localStorage.setItem('AllRoleUser', JSON.stringify(choiceRoleUser));
				  // const token: any = response.headers.get('JWT-TOKEN');
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					// localStorage.setItem('TOKEN', token);
					// this.userService.user = response.body;
					return choiceRoleUser;
				})
      );
	}

  public getToken(AssignmentCommercialId: User, Login: string, Password: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json',
      'Authorization': AssignmentCommercialId['if_commercialassignmentid']
    });
      
		return this.http
			.post(AuthentificationService.URL + 'Users/Token', JSON.stringify({ AssignmentCommercialId: AssignmentCommercialId['if_commercialassignmentid'], Login, Password }), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response);
          
				  // const token: any = response.headers.get('JWT-TOKEN');
					// store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('CommercialId', AssignmentCommercialId['if_commercialassignmentid']);
					localStorage.setItem('TOKEN', response.body.datas);
          this.headers = new HttpHeaders({
            'accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': response.body.datas
          });
				  this.userService.user = AssignmentCommercialId;
					return response.body.datas;
				})
      );
	}

  public getTokenSSO(tokenSSO: string): Observable<any> {
  
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json'
    });
      
		return this.http
			.post(AuthentificationService.URL + 'Users/SsoToken', JSON.stringify({ SsoParams: tokenSSO }), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response);
          
				  // const token: any = response.headers.get('JWT-TOKEN');
					// store user details and jwt token in local storage to keep user logged in between page refreshes

          // localStorage.setItem('CommercialId', AssignmentCommercialId['assignment.if_commercialassignmentid']);
					this.headers = new HttpHeaders({
            'accept': 'text/plain',
            'Content-Type': 'application/json',
            'Authorization': response.body.datas
          });
          localStorage.setItem('TOKEN', response.body.datas);
				  // this.userService.user = AssignmentCommercialId;
					return response.body.datas;
				})
      );
	}

	logout() {
    localStorage.clear();

    if (environment.subModuleUse == 'submodule_Stellium') {
      this.authCasApereo.logout();
    }
	}
}