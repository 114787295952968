import { ProjectService } from 'src/app/shared/services/project.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { environment } from 'src/environments/environment';


import { Component, Inject, OnInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ReceivePopupCustom } from 'src/app/shared/models/receive-popup-custom';
import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { ActivityApiService } from 'src/app/shared/services/activity-api.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { first, takeUntil } from 'rxjs/operators';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { CodeConclusionProject } from 'src/app/shared/models/code-conclusion-project';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InvestService } from 'src/app/shared/services/invest.service';


@Component({
  selector: 'app-popup-custom',
  templateUrl: './popup-custom.component.html',
  styleUrls: ['./popup-custom.component.scss']
})
export class PopupCustomComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  subModuleUse: string;

  checkbox1: string = "1";

  isOpenSelect = {
    conclusionCode: false
  };

  disabledButton: boolean = false;
  requiredComment: boolean = false;

  initErrorSelect: boolean = true;

  listFieldConclusionCode: Array<any> = [];

  labelComment: string = "Commentaire";

  // Declaration & Init form group with validator error for the form rule project
  insertCloseProjectForm: FormGroup = this.formBuilder.group({
    conclusionCode: [ null, Validators.required ],
    comment: [ "", Validators.required ]
  })
  
  // checkbox1Name: string = this.objectReceivePopup.activateCheckbox ? this.objectReceivePopup.objectCheckbox.checkbox1.name : "Aucun Nom";
  // checkbox2: boolean = false;

  // private serviceMessageLoading;

  // Declaration - Injectable data mat dialog of parent - root to popup choice
  constructor(private injector: Injector, public dialogRef: MatDialogRef<PopupCustomComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: ReceivePopupCustom, 
    private serviceErrorMessage: ErrorMessageService, public popupLoading: MatDialog, private formBuilder: FormBuilder, private serviceInvest: InvestService,
    private serviceConfigObservable: ConfigObservableService, private serviceActivityApi: ActivityApiService, private servicePopup: PopupServiceService, private serviceProject: ProjectService, private snackbar: SnackbarService) {
    this.subModuleUse = environment.subModuleUse;
  }

  ngOnInit() {
    if (this.objectReceivePopup.namePopup === 'duplicateProject') {
      try {
        this.serviceProject.getProjectCodeConclusions()
          .pipe(first(), takeUntil(this.ngUnsubscribe)).subscribe((listCodeConclusion: Array<CodeConclusionProject>) => {
            if(listCodeConclusion.length === 0) {
              this.snackbar.openSnackBar(
                this.serviceErrorMessage.errorMessageProjectListCodeConclusionCustom, ["custom-style-zero-data"]
              );
            }
            listCodeConclusion.forEach((codeConclusion: CodeConclusionProject) => {
              this.listFieldConclusionCode.push({ name: codeConclusion['conclusion.if_fluxsiteb2blabel'] , id: { name: codeConclusion['conclusion.if_fluxsiteb2blabel'] , id: codeConclusion['conclusion.if_conclusioncodeid'], commentRequired: codeConclusion['conclusion.if_cc_project_commentrequired'] } })
            });
        })
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageProjectListCodeConclusion, ["custom-style-error"]
        );
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Compare with for select (compare value observe in select with item.id (show construction array items))
  compareWithFn(item: any, selected: any) {
    return item.id === selected;
  }

  // Method for observe the open of select of city and reset of select of city for activate label or not
  openSelectAndReset(keyUp: any, formValue: any, isOpenSelect: any) {
    if (formValue !== null ) {
      isOpenSelect[keyUp] = true;   
    } else {
      isOpenSelect[keyUp] = false;  
    }
  }

  closePopupAnnulation() {
    this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false })
  }

  closePopupValidation() {

    if (this.objectReceivePopup.namePopup === "closeTask") {
      this.servicePopup.openDialogLoading('closeTask', this.objectReceivePopup.size, "");
      this.serviceActivityApi.closeTaskActivity(this.objectReceivePopup.id).pipe().subscribe((data: any) => {
        localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    } else if (this.objectReceivePopup.namePopup === "closeTaskAnnulation") {
      this.servicePopup.openDialogLoading('closeTaskAnnulation', this.objectReceivePopup.size, "");
      this.serviceActivityApi.cancelTaskActivity(this.objectReceivePopup.id).pipe().subscribe((data: any) => {
        localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    } else if (this.objectReceivePopup.namePopup === "closeCallTel") {
      this.servicePopup.openDialogLoading('closeCallTel', this.objectReceivePopup.size, "")
      this.serviceActivityApi.closePhoneCalls(this.objectReceivePopup.id).pipe().subscribe((data: any) => {
        localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    } else if (this.objectReceivePopup.namePopup === "closeCallTelAnnulation") {
      this.servicePopup.openDialogLoading('closeCallTelAnnulation', this.objectReceivePopup.size, "")
      this.serviceActivityApi.cancelPhoneCalls(this.objectReceivePopup.id).pipe().subscribe((data: any) => {
        localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    }

    if (this.objectReceivePopup.namePopup === "duplicateProject") {
      
      let dataDuplicateProject = null;

      dataDuplicateProject = this.switchGoodDuplicateProject()

      if (dataDuplicateProject == null) {
        localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      } else {
        this.servicePopup.openDialogLoading('duplicateProject', this.objectReceivePopup.size, "");
        this.serviceProject.putProject(dataDuplicateProject, this.objectReceivePopup.objectCheckbox.projectId).pipe().subscribe((data: any) => {
          localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
          this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true });
          this.snackbar.open(
            "Votre demande a bien été prise en compte. Elle apparaîtra dans quelques minutes.", ["bg-success", "text-white"], 7000
          );
        }, error => {
          localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
          this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false });
          this.popupLoading.closeAll();
          this.snackbar.open(
            "Votre demande n'à pas été prise en compte.", ["custom-style-error"], 7000
          );
        })
      }
      

    }

    if (this.objectReceivePopup.namePopup === "duplicateProjectPlacement") {
      
      let dataDuplicateProject = null;

      dataDuplicateProject = this.switchGoodDuplicateProjectPlacement()

      if (dataDuplicateProject == null) {
        localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      } else {
        this.servicePopup.openDialogLoading('duplicateProject', this.objectReceivePopup.size, "");
        // this.serviceProject.putProject(dataDuplicateProject, this.objectReceivePopup.objectCheckbox.projectId).pipe().subscribe((data: any) => {
          // this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true });
          // this.snackbar.open(
          //   "Votre demande a bien été prise en compte. Elle apparaîtra dans quelques minutes.", ["bg-success", "text-white"], 7000
          // );
        // })

        this.serviceInvest.putInvestPlacement(this.objectReceivePopup.objectCheckbox.projectId, dataDuplicateProject).pipe(first()).subscribe(
          (dataReturn: any) => {
            localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
            this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true });
            this.snackbar.open(
              "Votre demande a bien été prise en compte. Elle apparaîtra dans quelques minutes.", ["bg-success", "text-white"], 7000
            );
          }, error => {
            localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
            this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false });
            this.popupLoading.closeAll();
            this.snackbar.open(
              "Votre demande n'à pas été prise en compte.", ["custom-style-error"], 7000
            );
          })
      }
      

    }

  }

  switchGoodDuplicateProjectPlacement() {
    let dataDuplicateProject: any = null;

    switch (this.objectReceivePopup.objectCheckbox.nameChoice) {
      case "placement":
        switch (this.checkbox1) {
          case '1':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_process_projectduplicate_trigger", 'Value': new Date()

              }, {
                'Key': "if_process_projectduplicate_code", 'Value': 'DPROJVP01'
              }]
            }
            break;
          case '2':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_process_projectduplicate_trigger", 'Value': new Date()

              }, {
                'Key': "if_process_projectduplicate_code", 'Value': 'DPROJVP02'
              }]
            }
            break;
        }
        break;
      default:
        dataDuplicateProject = {}
        break;
    }

    return dataDuplicateProject;
  }

  switchGoodDuplicateProject() {
    let dataDuplicateProject: any = null;

    switch (this.objectReceivePopup.objectCheckbox.nameChoice) {
      case "immobilier":
        switch (this.checkbox1) {
          case '1':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_trigger_duplicate", 'Value': new Date()

              }, {
                'Key': "if_process_duplicate_code", 'Value': 'DPROJ01'
              }]
            }
            break;
          case '2':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_trigger_duplicate", 'Value': new Date()

              }, {
                'Key': "if_process_duplicate_code", 'Value': 'DPROJ02'
              }]
            }
            break;
          case '3':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_trigger_duplicate", 'Value': new Date()

              }, {
                'Key': "if_process_duplicate_code", 'Value': 'CPROJ01'
              }]
            }
            break;
        }
        break;
      case "placement":
        switch (this.checkbox1) {
          case '1':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_trigger_duplicate", 'Value': new Date()

              }, {
                'Key': "if_process_duplicate_code", 'Value': 'DPROJ04'
              }]
            }
            break;
          case '2':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_trigger_duplicate", 'Value': new Date()

              }, {
                'Key': "if_process_duplicate_code", 'Value': 'DPROJ03'
              }]
            }
            break;
          case '3':
            dataDuplicateProject = {
              Fields: [{
                'Key': "if_trigger_duplicate", 'Value': new Date()
              }, {
                'Key': "if_process_duplicate_code", 'Value': 'CPROJ02'
              }]
            }
            break;
        }
        break;
      default:
        dataDuplicateProject = {}
        break;
    }

    return dataDuplicateProject;
  }



  // Function submit post rule project (Not use for the moment)
  onSubmitPostFormCloseProject(disableButton: boolean) {
    if (this.objectReceivePopup.namePopup === 'duplicateProject') {
      console.log(this.objectReceivePopup.objectCheckbox.projectId)
      if (this.insertCloseProjectForm.value.conclusionCode !== null) {
        if (this.insertCloseProjectForm.value.conclusionCode.commentRequired !== null) {
          if (this.insertCloseProjectForm.value.conclusionCode.commentRequired === 1) {
            if (this.insertCloseProjectForm.value.comment === "") {
              this.requiredComment = true;
              this.disabledButton = false;
              return
            } else {
              this.requiredComment = false
            }
          }
        }
        this.openDialogLoading("closeProject");
        // let newDataClose: any = {} 
        // newDataClose["if_process_closeproject_date"] = new Date().toISOString();
        // newDataClose["if_process_closeproject_ccid"] = this.insertCloseProjectForm.value.conclusionCode.id;
        // if (this.insertCloseProjectForm.value.comment !== '') {          
        //   newDataClose["if_process_closeproject_desc"] = this.insertCloseProjectForm.value.comment;
        // }
        // newDataClose['if_process_duplicate_closeproject'] = 750010000;

        let dataDuplicateProject = null;

        dataDuplicateProject = this.switchGoodDuplicateProject()

        dataDuplicateProject?.Fields?.push({
          'Key': "if_process_closeproject_date", 'Value': new Date().toISOString()
        })
        dataDuplicateProject?.Fields?.push({
          'Key': "if_process_closeproject_ccid", 'Value': this.insertCloseProjectForm.value.conclusionCode.id
        })
        dataDuplicateProject?.Fields?.push({
          'Key': "if_process_duplicate_closeproject", 'Value': 750010000
        })
        if (this.insertCloseProjectForm.value.comment !== '') { 
          dataDuplicateProject?.Fields?.push({
            'Key': "if_process_closeproject_desc", 'Value': this.insertCloseProjectForm.value.comment
          })
        }
        // CC → if_process_closeproject_ccid
        // NOW → if_process_closeproject_date
        // Commentaire → if_process_closeproject_desc
        // 750 010 000 →  if_process_duplicate_closeproject

        // this.serviceProject.closeProject(newDataClose, this.objectReceivePopup.objectCheckbox.projectId)
        //   .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data:any) => {
        //     console.log(data)
        //     this.serviceConfigObservable.emitRefreshInProgress(true);
        //     this.popupLoading.closeAll()
        //   }, (error) => {
        //     this.snackbar.openSnackBar(
        //       this.serviceErrorMessage.errorMessageProjectCloseProject, ["custom-style-zero-data"]
        //     );
        //     this.popupLoading.closeAll()
        //   })
        this.servicePopup.openDialogLoading('duplicateProject', this.objectReceivePopup.size, "");
        this.serviceProject.putProject(dataDuplicateProject, this.objectReceivePopup.objectCheckbox.projectId).pipe().subscribe((data: any) => {
          localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.objectCheckbox.projectId);
          this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true });
          this.snackbar.open(
            "Votre demande a bien été prise en compte. Elle apparaîtra dans quelques minutes.", ["bg-success", "text-white"], 7000
          );
        })
      } else {
        this.initErrorSelect = false;
      }
      this.disabledButton = false;
    } 
    this.disabledButton = false;
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.servicePopup.openDialogLoading(namePopupLoading, this.objectReceivePopup.size, "");
  }
}

