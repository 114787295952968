// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// Declaration & Init Var envirronement for SubModule choice INOVIA -----------------------------------------------------------------------------------------------------------
import { FooterAllPageComponent } from 'src/app/components/footer-all-page/footer-all-page.component';
import { PopupPostOptionDenunciationComponent } from 'src/app/pages/connected/components/Popup/popup-post-option-denunciation/popup-post-option-denunciation.component';
import { PopupSearchSaveComponent } from 'src/app/pages/connected/components/popup-search-save/popup-search-save.component';
import { PopupPostDenunciationComponent } from 'src/app/pages/connected/components/Popup/popup-post-denunciation/popup-post-denunciation.component';

export const myComponents = [
  PopupSearchSaveComponent,
  PopupPostDenunciationComponent,
  PopupPostOptionDenunciationComponent,
  FooterAllPageComponent,
];

import { TableHousingService } from 'src/app/shared/services/table-housing.service';
import { TrackedWorksiteService } from 'src/app/shared/services/tracked-worksite.service';
import { ParameterVitrineVariableService } from "src/app/shared/services/parameter-vitrine-variable.service";
import { RuleOtherServiceInovia } from 'src/app/submodule_Inovia/shared/services/rule-other.service';
import { MessageLoadingServiceInovia } from 'src/app/submodule_Inovia/shared/services/message-loading.service';
import { RulePostOptionServiceInovia } from 'src/app/submodule_Inovia/shared/services/rule-post-option.service';

export const myService = {
  serviceDiapason: null,
  serviceTrackedWorksite: TrackedWorksiteService,
  serviceTableHousing : TableHousingService,
  serviceParameterVitrineVariable : ParameterVitrineVariableService,
  serviceRuleOther: RuleOtherServiceInovia,
  serviceMessageLoading: MessageLoadingServiceInovia,
  serviceRulePostOption: RulePostOptionServiceInovia
};

export const environment = {
  subModuleUse: 'submodule_Inovia',

  // Declaration & Init Var envirronement for Api DEV-WORK-INOVIA -----------------------------------------------------------------------------------------------------------
  production: false,
  urlActivityApi: '#{authActivityAPI}#',
  urlGestOptions: '#{authGestOptionAPI}#',
  urlServer: '#{authServeurAPI}#',
  urlEripApi: '#{authEripAPI}#',
  urlErip : '#{authEripUrlAPI}#',
  tokenErip: '#{tokenEripAPI}#',
  activeBtnNewProjectInPostOption: false,

  client_id: "",
  appUrl: "",
  casUrl: "",
  callback: "",
  isCustomerAreaEnabled:true,
  customerAreaProjectUrl: "#{customerAreaProjectUrl}#",
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

