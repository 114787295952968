import { ProjectTableHousing } from 'src/app/shared/models/project-table-housing';
import { PopupServiceService } from './../../../../shared/services/popup-service.service';
import { Tmi } from './../../../../shared/models/tmi';
import { ReferentialPurchaseTimeframe } from './../../../../shared/models/referential-purchase-timeframe';
import { ReferentialMainSearchprofil } from './../../../../shared/models/referential-main-searchprofil';
import { ReferentialInvestmentType } from './../../../../shared/models/referential-investment-type';
import { ReferentialInvestmentObjective } from './../../../../shared/models/referential-investment-objective';
import { ReferentialCommunicationchannel } from './../../../../shared/models/referential-communicationchannel';
import { OperationService } from './../../../../shared/services/operation.service';
import { ResearchService } from 'src/app/shared/services/research.service';
import { DepartmentService } from './../../../../shared/services/department.service';
import { DepartmentResearch } from './../../../../shared/models/department-research';
import { Salutation } from './../../../../shared/models/salutation';
import { MatrimonialRegime } from './../../../../shared/models/matrimonial-regime';
import { CategorySP } from './../../../../shared/models/category-sp';
import { FamilySituation } from './../../../../shared/models/family-situation';
import { ProjectService } from './../../../../shared/services/project.service';
import { EripService } from './../../../../shared/services/erip.service';
import { Component, Inject, OnInit, ViewChild, Injector, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { CityService } from 'src/app/shared/services/city.service';
import { first, takeUntil } from 'rxjs/operators';
import { Origin } from 'src/app/shared/models/origin';
import { CountryService } from 'src/app/shared/services/country.service';
import { Country } from 'src/app/shared/models/country';
import { isNullFieldForm, numberWithCommas, convertTZ, initSizeScreen } from 'src/app/shared/services/functions';
import { Types } from 'src/app/shared/models/types';
import { ReferentialAssetType } from 'src/app/shared/models/referential-asset-type';
import { CustomerPropertie } from 'src/app/shared/models/customer-propertie';
import { HttpResponse } from '@angular/common/http';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { Risk } from 'src/app/shared/models/risk';
import { environment, myService } from 'src/environments/environment';
import { ActivityApiService } from 'src/app/shared/services/activity-api.service';
import { ProjectId } from 'src/app/shared/models/project-id';
import { Subject, Subscription } from 'rxjs';

// Interface for send several data in the popup for the save of research
export interface DialogDataPopup {
  namePopup: string;
  dataPopup: ProjectTableHousing;
  size: number;
}

interface ObjectSubscription {
  configObservablePutDetachedContactSecond: Subscription | undefined;
}

@Component({
  selector: 'app-popup-completed-project',
  templateUrl: './popup-completed-project.component.html',
  styleUrls: ['./popup-completed-project.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class PopupCompletedProjectComponent implements OnInit, OnDestroy {

  // Observe stepper of mat horizontal stepper
  @ViewChild('stepper') stepper!: MatStepper;

  private ngUnsubscribe = new Subject();

  private objectForSubscribtion: ObjectSubscription = {
    configObservablePutDetachedContactSecond: undefined
  }

  // Declaration and Init stepper index next to the name popup choice in the parent
  selectedStepIndex: number = this.data.namePopup === "EditPlacement" || this.data.namePopup === "EditImmovable" ? 3 : this.data.namePopup === "EditContactSecond" ? 1 : (this.data.namePopup === "EditContact" || this.data.namePopup === "EditContactOtherPageProject") ? 0 : 2;

  subModuleUse: string;

  activeRecall: boolean = false;
  toggleRecall: any = [];

  // Declaration referential stock for add referential stock api
  referentialStock: any;

  // Declaration for receive value baf synchronized true or false
  valueResponseBafSynchronize: any;

  // Declaration and init value for load and show or hide loading
  initFormAllStepper: boolean = true;

  // Declaration and Init loading form all stepper ==> view if all field of stepper is init  
  loadingFormAllStepper: number = 0;

  // Declaration and init object for label of select for the contact principal 
  isOpenSelectPrincipal: any = {
    city: false,
    countryBirthday: false,
    countryResidence: false,
    civility: false,
    matrimonialRegime: false,
    familySituation: false,
    contractWork: false,
    category: false,
    typeRent: false,
  };

  // Declaration and init object for label of select for the contact second
  isOpenSelectSecond: any = {
    city: false,
    countryBirthday: false,
    countryResidence: false,
    civility: false,
    matrimonialRegime: false,
    familySituation: false,
    contractWork: false,
    category: false,
    typeRent: false,
  };

  // Declaration and init object for label of select for the other select
  isOpenSelectOther: any = {
    origin: false,
    canal: false,

    goalInvestment1: false,
    goalInvestment2: false,
    goalInvestment3: false,
    goalInvestment4: false,
    goalInvestment5: false,

    situation: false,
    goal: false,
    investmentHorizon: false,
    taxSystem: false,


    typology: false,
    programCible: false,
    // profil: false,
    product: false,
    tmi: false,
    acceptWarning: false,
    typeProperty: false,
    departmentResearch: false,
  }

  disableOneCityNotPicking: boolean = false;
  disableTwoCityNotPicking: boolean = false;

  // Declaration and init city of contact principal without any id
  contactPrincipalCityNotId: any = null;

  // Declaration and init city of contact second without any id
  contactSecondCityNotId: any = null;

  // Declaration and init state select of contact principal 
  contactPrincipalCity: any = null;
  contactPrincipalCivility: any = null;
  contactPrincipalFamilySituation: any = null;
  contactPrincipalCountryResidence: any = null;
  contactPrincipalCountryBirthday: any = null;
  contactPrincipalCategory: any = null;
  contactPrincipalMatrimonialRegime: any = null;
  contactPrincipalContractWork: any = null;
  contactPrincipalTypeRent: any = null;

  // Declaration and init state select of contact second 
  contactSecondCity: any = null;
  contactSecondCivility: any = null;
  contactSecondCountryResidence: any = null;
  contactSecondCountryBirthday: any = null;
  contactSecondCategory: any = null;
  contactSecondFamilySituation: any = null;
  contactSecondMatrimonialRegime: any = null;
  contactSecondContractWork: any = null;
  contactSecondTypeRent: any = null;

  // Declaration and init state of other select
  immoProjectTmi: any = null;
  immoProjectInvestmentRisks: any = null;
  immoProjectDepartmentResearch: any = null;
  immoProjectProfil: any = null;
  immoProjectSituation: any = null;
  immoProjectGoal: any = null;
  immoProjectProgramCible: any = null;
  immoProjectOrigin: any = null;
  immoProjectCanal: any = null;
  immoProjectTaxSystem: any = null;
  immoProjectInvestHorizon: any = null;
  immoProjectGoalInvestment1: any = null;
  immoProjectGoalInvestment2: any = null;
  immoProjectGoalInvestment3: any = null;
  immoProjectGoalInvestment4: any = null;
  immoProjectGoalInvestment5: any = null;
  immoProjectProductCible: Array<any> = [];
  immoProjectTypology: Array<any> = [];

  // Declaration and init state before the slect change of other select (for the update comparated)
  immoProjectProductCibleBeforeUpdate: Array<any> = [];
  immoProjectTypologyBeforeUpdate: Array<any> = [];

  // Declaration and init all label of contact principal and second for the created or completed project
  labelFirstName: string = 'Prénom';
  labelLastName: string = 'Nom de famille';
  labelYoungLastName: string = 'Nom de jeune fille';
  labelEmail: string = 'Email';
  labelPhone: string = 'Tél. personnel';
  labelMobilePhone: string = 'Tél. mobile';
  labelPhonePro: string = 'Tél. Professionnel';
  labelNumAddress: string = 'N°';
  labelTypeVoie: string = 'Type de voie';
  labelNameVoie: string = 'Nom de la voie';
  labelAddressOne: string = 'Adresse 1';
  labelAddressTwo: string = 'Adresse 2';
  labelZipCode: string = 'Code postal';
  labelCity: string = 'Référentiel Villes';
  labelCityNotPicking: string = 'Ville';
  labelEmailPro: string = 'Email Professionnel';
  labelBirthday: string = 'Né le';
  labelCityBirthday: string = 'Né à';
  labelCountryBirthday: string = 'Pays de naissance';
  labelNationality: string = 'Nationalité';
  labelNumCardDay: string = 'N° carte de séjour';
  labelPeriodicityValidity: string = 'Période de validité';
  labelProfession: string = 'Profession';
  labelEmployer: string = 'Employeur';
  labelSeniority: string = 'Depuis (Ancienneté)';
  labelEmployerActual: string = 'Employeur actuel';
  labelEmployerAddress: string = 'Adresse employeur';
  labelFamilySituationDate = 'Situation familiale – Date';
  labelFamilySituationLocation: string = "Situation familiale – Lieu";
  labelFamilySituationNotary: string = "Situation familiale – Notaire";
  labelRentMonth = 'Salaire Annuel';

  // Declaration and init all label of form foyer fiscal for the created or completed project
  labelNbPartFiscal: string = 'Nb de parts fiscales du foyer';
  labelNbPersCharge: string = 'Nb de personnes à charge';
  labelNbChildren: string = 'Nombre d’enfants';
  labelNbChildrenCharge: string = 'Nb d’enfants à charge';
  labelYearsChildren = "Ages des enfants";
  labelNameChildren = "Noms de enfants";
  labelGrossTaxAmount = "Montant de l’impôt brut";
  labelPaidTaxAmount = "Montant de l’impôt à payer";
  labelTaxIncomeRefN = "Revenu Fisc. ref. N";
  labelTaxIncomeRefN1 = "Revenu Fisc. ref. N-1";
  labelTaxIncomeRefN2 = "Revenu Fisc. ref. N-2";

  labelSavingConstituted = "Epargne constituée";
  labelMinSavingEffort = "Effort d’épargne Min";
  labelMaxSavingEffort = "Effort d’épargne Max";

  // Declaration and init all label of  for the created or completed project
  labelWishRentability = 'Rentabilité souhaitée';
  labelAcceptWarning = 'Risque accepté';
  labelComment = 'Commentaires'
  labelNeedMore = 'Description du besoin'
  labelSurfaceMin: string = 'Surface min';
  labelSurfaceMax: string = 'Surface max';
  labelBudgetMin: string = 'Budget min';
  labelBudgetMax: string = 'Budget max'
  labelDisponibilityMin: string = 'Montant des disponibilités Min'
  labelDisponibilityMax: string = 'Montant des disponibilités Max'
  labelEpargneMin: string = 'Capacité d’épargne mensuelle Min';
  labelEpargneMax: string = 'Capacité d’épargne mensuelle Max';
  labelOriginDisponibility: string = 'Origine des disponibilités';

  // Active or not style header stepper
  hiddenArrowForward: Boolean = this.data.namePopup === "EditPlacement" || this.data.namePopup === "EditImmovable" ? false : true;
  hiddenArrowBack: Boolean = this.data.namePopup === "EditContact" ? false : true;


  // ERIP integration : Validation fields
  isFieldReadOnlyAccess: boolean = false;



  // All the array for the list of ng-select in the created or completed project
  // Stepper One and stepper two
  listFieldCivility: Array<any> = [];
  listFieldCity: Array<any> = [];
  listFieldCountryBirthday: Array<any> = [];
  listFieldCountryResidence: Array<any> = [];
  listFieldFamilySituation: Array<any> = [];
  listFieldMatrimonialRegime: Array<any> = [];
  listFieldCategory: Array<any> = [];
  listFieldContractWork: Array<any> = [];
  listFieldTypeRent: Array<any> = [];

  // Stepper Three
  listFieldTmi: Array<any> = [];
  listFieldInvestmentRisks: Array<any> = [];

  // Stepper Four
  listFieldOrigin: Array<any> = [];
  listFieldCanal: Array<any> = [];
  listFieldGoalInvestment: Array<any> = [];
  listFieldSituation: Array<any> = [];
  listFieldGoal: Array<any> = [];
  listFieldInvestmentHorizon: Array<any> = [];
  listFieldTaxSystem: Array<any> = [];
  listFieldTypology: Array<any> = [];
  listFieldProgramCible: Array<any> = [];
  listFieldProduct: Array<any> = [];
  listFieldProfil: Array<any> = [];
  // Not use for the moment
  listFieldDepartmentResearch: Array<any> = [];

  // Declaration & Init form group with validator error for the form in the stepper one
  insertContactPrincipalProjectForm: FormGroup = this.formBuilder.group({
    civility: [null],
    firstname: ["", Validators.required],
    lastname: ["", Validators.required],
    youngLastname: [""],
    phone: [""],
    mobilePhone: ["", [Validators.required]],
    phonePro: [""],
    email: ["", [Validators.required]],
    emailPro: [""],

    numAddress: [""],
    typeVoie: [""],
    nameVoie: [""],

    addressOne: [{ value: "", disabled: true }],
    addressTwo: [""],
    zipCode: [""],
    city: [null],
    cityNotPicking: [{ value: "", disabled: this.disableOneCityNotPicking }],
    birthday: [""],
    cityBirthday: [""],
    countryBirthday: [null],
    countryResidence: [null],
    nationality: [""],
    numCardDay: [""],
    periodicityValidity: [""],
    familySituation: [null],
    familySituationDate: [""],
    familySituationLocation: [""],
    familySituationNotary: [""],

    matrimonialRegime: [null],
    category: [null],
    profession: [""],
    contractWork: [null],
    seniority: [""],
    employerActual: [""],
    employerAddress: [""],
    rentMonth: [""],
    typeRent: [null]
  })

  // Declaration & Init form group with validator error for the form in the stepper two
  insertContactSecondProjectForm: FormGroup = this.formBuilder.group({
    checkedCleanCTC2: [false],

    checkedCoordination: [false],
    checkedFamilyStitutation: [false],

    civility: [null],
    firstname: [""],
    lastname: [""],
    youngLastname: [""],
    phone: [""],
    mobilePhone: [""],
    phonePro: [""],
    email: ["", [Validators.email]],
    emailPro: ["", [Validators.email]],

    numAddress: [""],
    typeVoie: [""],
    nameVoie: [""],

    addressOne: [{ value: "", disabled: true }],
    addressTwo: [""],
    zipCode: [""],
    city: [null],
    cityNotPicking: [{ value: "", disabled: this.disableTwoCityNotPicking }],
    birthday: [""],
    cityBirthday: [""],
    countryBirthday: [null],
    countryResidence: [null],
    nationality: [""],
    numCardDay: [""],
    periodicityValidity: [""],
    toggleCoBooking: false,
    familySituation: [null],
    familySituationDate: [""],
    familySituationLocation: [""],
    familySituationNotary: [""],

    matrimonialRegime: [null],
    category: [null],
    profession: [""],
    contractWork: [null],
    seniority: [""],
    employerActual: [""],
    employerAddress: [""],
    rentMonth: [""],
    typeRent: [null]
  })

  // Declaration & Init form group with validator error for the form in the stepper two
  insertFoyerFiscalProjectForm: FormGroup = this.formBuilder.group({      //   //!\\ Pease be careful about the method [refreshFoyerFiscalForm()]
    nbPartFiscal: "", //    This method override [insertFoyerFiscalProjectForm] variable.
    tmi: [null],
    nbPersCharge: "",
    nbChildren: "",
    nbChildrenCharge: "",
    yearsChildren: "",
    nameChildren: "",
    grossTaxAmount: "",
    paidTaxAmount: "",
    taxIncomeRefN: "",
    taxIncomeRefN1: "",
    taxIncomeRefN2: "",
    // savingConstituted:"",
    minSavingEffort: "",
    maxSavingEffort: "",
    disponibilityMin: "",
    disponibilityMax: "",
    originDisponibility: "",
    wishRentability: "",
    acceptWarning: "",
    // profil:"",
    goalInvestment1: "",
    goalInvestment2: "",
    goalInvestment3: "",
    goalInvestment4: "",
    goalInvestment5: "",
  })

  // Declaration & Init form group with validator error for the form in the stepper three
  insertImmoProjectForm: FormGroup = this.formBuilder.group({
    origin: [{ value: null, disabled: true }],
    canal: [null],
    needMore: [""],
    comment: [""],

    immovable: [true, Validators.required],
    financial: [false, Validators.required],

    situation: [null],
    goal: [null],
    investmentHorizon: [null],
    taxSystem: [null],

    typology: [null],
    programCible: [null],
    surfaceMin: [""],
    surfaceMax: [""],
    budgetMin: [""],
    budgetMax: [""],
    // epargneMin: [ "" ],
    // epargneMax: [ "" ],
    product: [null]
    // adminCompleted: [false]
  });


  insertContactPrincipalProjectFormKvp: any = [
    { "civility": "if_ctc1salutationid" },
    { "firstname": "if_ctc1firstname" },
    { "lastname": "if_ctc1lastname" },
    { "youngLastname": "if_ctc1_maidenname" },
    { "phone": "if_ctc1personnalphone" },
    { "mobilePhone": "if_ctc1mobilephone" },
    { "phonePro": "if_ctc1professionalphone" },
    { "zipCode": "if_ctc1_postalcode" },
    { "email": "if_ctc1email1" },
    { "emailPro": "if_ctc1email2" },
    { "numAddress": "if_ctc1_streetnumber" },
    { "typeVoie": "if_ctc1_streettype" },
    { "nameVoie": "if_ctc1_streetname" },
    { "addressOne": "if_ctc1_street1" },
    { "addressTwo": "if_ctc1_street2" },
    { "city": "if_ctc1cityid" },
    { "cityNotPicking": "if_ctc1city" },
    { "birthday": "if_ctc1birthday" },
    { "cityBirthday": "if_ctc1_birthplace" },
    { "countryBirthday": "if_ctc1_birthcountryid" },
    { "countryResidence": "if_ctc1_residencecountryid" },
    { "nationality": "if_ctc1_nationality" },
    { "numCardDay": "if_ctc1_residencepermitnumber" },
    { "periodicityValidity": "if_ctc1_residencepermitperioddesc" },
    { "familySituation": "if_ctc1_familysituationid" },
    { "familySituationDate": "if_ctc1_familysituation_date" },
    { "familySituationLocation": "if_ctc1_familysituation_place" },
    { "familySituationNotary": "if_ctc1_familysituation_notarydesc" },
    { "matrimonialRegime": "if_ctc1_matrimonialregimeid" },
    { "category": "if_ctc1_cspid" },
    { "profession": "if_ctc1_jobtitle" },
    { "contractWork": "if_ctc1_employmentcontractid" },
    { "seniority": "if_ctc1_professionalsituationsincedate" },
    { "employerActual": "if_ctc1_currentemployer" },
    { "employerAddress": "if_ctc1_currentemployeraddress" },
    { "rentMonth": "qui_ctc1_salaryamount" },
    { "typeRent": "qui_ctc1_annualincometype" }
  ]

  insertContactSecondProjectFormKvp: any = [
    { "civility": "if_ctc2salutationid" },
    { "firstname": "if_ctc2firstname" },
    { "lastname": "if_ctc2lastname" },
    { "youngLastname": "if_ctc2_maidenname" },
    { "phone": "if_ctc2personnalphone" },
    { "mobilePhone": "if_ctc2mobilephone" },
    { "phonePro": "if_ctc2professionalphone" },
    { "zipCode": "if_ctc2_postalcode" },
    { "email": "if_ctc2email1" },
    { "emailPro": "if_ctc2email2" },
    { "numAddress": "if_ctc2_streetnumber" },
    { "typeVoie": "if_ctc2_streettype" },
    { "nameVoie": "if_ctc2_streetname" },
    { "addressOne": "if_ctc2_street1" },
    { "addressTwo": "if_ctc2_street2" },
    { "city": "if_ctc2cityid" },
    { "cityNotPicking": "if_ctc2city" },
    { "birthday": "if_ctc2birthday" },
    { "cityBirthday": "if_ctc2_birthplace" },
    { "countryBirthday": "if_ctc2_birthcountryid" },
    { "countryResidence": "if_ctc2_residencecountryid" },
    { "nationality": "if_ctc2_nationality" },
    { "numCardDay": "if_ctc2_residencepermitnumber" },
    { "periodicityValidity": "if_ctc2_residencepermitperioddesc" },
    { "toggleCoBooking": "if_cobooking" },
    { "familySituation": "if_ctc2_familysituationid" },
    { "familySituationDate": "if_ctc2_familysituation_date" },
    { "familySituationLocation": "if_ctc2_familysituation_place" },
    { "familySituationNotary": "if_ctc2_familysituation_notarydesc" },
    { "matrimonialRegime": "if_ctc2_matrimonialregimeid" },
    { "category": "if_ctc2_cspid" },
    { "profession": "if_ctc2_jobtitle" },
    { "contractWork": "if_ctc2_employmentcontractid" },
    { "seniority": "if_ctc2_professionalsituationsincedate" },
    { "employerActual": "if_ctc2_currentemployer" },
    { "employerAddress": "if_ctc2_currentemployeraddress" },
    { "rentMonth": "qui_ctc2_salaryamount" },
    { "typeRent": "qui_ctc2_annualincometype" }
  ]


  insertFoyerFiscalProjectFormKvp: any = [
    { "nbPartFiscal": "if_taxpartsnumber" },
    { "tmi": "if_tmiid" },
    { "nbPersCharge": "if_dependentpeoplenumber" },
    { "nbChildren": "if_childrencount" },
    { "nbChildrenCharge": "if_dependentchildsnumber" },
    { "yearsChildren": "if_childrenages" },
    { "nameChildren": "if_childrensnames" },
    { "grossTaxAmount": "if_incometax_grossamount" },
    { "paidTaxAmount": "if_incometax_payableamount" },
    { "taxIncomeRefN": "if_incometaxref_n" },
    { "taxIncomeRefN1": "if_incometaxref_n1" },
    { "taxIncomeRefN2": "if_incometaxref_n2" },
    // savingConstituted: [ "" ],
    { "minSavingEffort": "if_investment_savingscapacitymonthlymin" },
    { "maxSavingEffort": "if_investment_savingscapacitymonthlymax" },
    { "disponibilityMin": "if_investment_availabilityamountmin" },
    { "disponibilityMax": "if_investment_availabilityamountmax" },
    { "originDisponibility": "if_investment_availabilityorigin" },
    { "wishRentability": "if_wishprofitabilitymin" },
    { "acceptWarning": "risk_if_fluxsiteb2bid" },
    // profil: [ null ],\
    { "goalInvestment1": "if_investmentobjective1id" },
    { "goalInvestment2": "if_investmentobjective2id" },
    { "goalInvestment3": "if_investmentobjective3id" },
    { "goalInvestment4": "if_investmentobjective4id" },
    { "goalInvestment5": "if_investmentobjective5id" }
  ]

  insertImmoProjectFormKvp: any = [
    { "origin": "if_marketingoriginid" },
    { "canal": "if_mkg_channelid" },
    { "needMore": "if_requirementdescription" },
    { "comment": "if_projectcomments" },
    // { "immovable": "if_taxpartsnumber" },
    // { "financial": "if_taxpartsnumber" },
    { "situation": "if_customerproperties" },
    { "goal": "if_mainsearchprofil" },
    { "investmentHorizon": "if_purchasetimeframe" },
    { "taxSystem": "if_immo_taxexemptionid" },
    { "typology": "if_assettypeid" },
    { "programCible": "if_mainoperationid" },
    { "surfaceMin": "if_immo_surfacemin" },
    { "surfaceMax": "if_immo_surfacemax" },
    { "budgetMin": "if_wishbudgetmin" },
    { "budgetMax": "if_wishbudgetmax" },
    { "product": "if_investment_typeid" },
  ]

  eripReadOnlyFields: any = [];

  errorMessageContact: Boolean = false;

  private serviceParameterVitrineVariable: any = null;
  // Declaration - public var for mat dialog - private service for observe and receive data (not use)  - private var for form builder - Injectable data mat dialog of parent - root to popup choice
  // Init in the constructor the select with selection city and country with research with 3 characters
  constructor( private injector: Injector,
    public popupCompletedProject: MatDialog, public popupLoading: MatDialog, public popupFormRdv: MatDialog, private serviceConfigObservable: ConfigObservableService,
    private serviceActivityApi: ActivityApiService, private formBuilder: FormBuilder, private serviceCity: CityService, private serviceCountry: CountryService, private serviceProject: ProjectService, private serviceOperation: OperationService,
    private serviceDepartments: DepartmentService, private serviceErip: EripService, private serviceResearch: ResearchService, public dialogRef: MatDialogRef<PopupCompletedProjectComponent>, private serviceErrorMessage: ErrorMessageService, private snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPopup, private servicePopup: PopupServiceService) {
      this.serviceParameterVitrineVariable = this.injector.get(myService.serviceParameterVitrineVariable);
    // Refresh FoyerFiscal form if an Erip ID is present with the project
    // this.isFieldReadOnlyAccess = !(this.data.dataPopup.eripId == null || this.data.dataPopup.eripId == ""); // FoyerFiscal section is locked if EripId is present.
    // this.refreshFoyerFiscalForm();
    // ERIP Configuration
    if (this.serviceErip.isEripActivated && this.serviceErip.isEripEnabledForCurrentSubModule) {
      if (this.data.dataPopup.eripId != "")
        this.lockProjectFiles();
    }
    // End EripConfiguration

    this.subModuleUse = environment.subModuleUse;
    if (this.data.namePopup === "EditPlacement" || this.data.namePopup === "EditContactSecond" || this.data.namePopup === "EditContact" || this.data.namePopup === "EditImmovable" || this.data.namePopup === "EditFoyerFiscal") {
      if (this.data.dataPopup.event2['if_ctc1city'] !== null) {
        if (this.data.dataPopup.event2['if_ctc1cityid'] !== null) {
          this.customSearchFn("city", this.data.dataPopup.event2['if_ctc1city'], "city", this.isOpenSelectPrincipal);
        } else {
          if (this.contactPrincipalCityNotId === null) {
            this.contactPrincipalCityNotId = this.data.dataPopup.event2['if_ctc1city']
            this.insertContactPrincipalProjectForm.patchValue({
              cityNotPicking: this.contactPrincipalCityNotId
            })
          }
        }
      }
      if (this.data.dataPopup.event2['if_ctc2_city'] !== null) {
        if (this.data.dataPopup.event2['if_ctc2cityid'] !== null) {
          this.customSearchFn("city", this.data.dataPopup.event2['if_ctc2_city'], "city", this.isOpenSelectSecond);
        } else {
          if (this.contactSecondCityNotId === null) {
            this.contactSecondCityNotId = this.data.dataPopup.event2['if_ctc2_city']
            this.insertContactSecondProjectForm.patchValue({
              cityNotPicking: this.contactSecondCityNotId
            })
          }
        }
      }


      if (this.data.dataPopup.event2['if_ctc1_birthcountryid_label'] !== null) {
        this.customSearchFn("countryBirthday", this.data.dataPopup.event2['if_ctc1_birthcountryid_label'], "countryBirthday", this.isOpenSelectPrincipal);
      }
      if (this.data.dataPopup.event2['if_ctc2_birthcountryid_label'] !== null) {
        this.customSearchFn("countryBirthday", this.data.dataPopup.event2['if_ctc2_birthcountryid_label'], "countryBirthday", this.isOpenSelectSecond);
      }
      if (this.data.dataPopup.event2['if_ctc1_residencecountryid_label'] !== null) {
        this.customSearchFn("countryResidence", this.data.dataPopup.event2['if_ctc1_residencecountryid_label'], "countryResidence", this.isOpenSelectPrincipal);
      }
      if (this.data.dataPopup.event2['if_ctc2_residencecountryid_label'] !== null) {
        this.customSearchFn("countryResidence", this.data.dataPopup.event2['if_ctc2_residencecountryid_label'], "countryResidence", this.isOpenSelectSecond);
      }
      // this.customSearchFnDepartmentResearch("departmentResearch", this.data.dataPopup.VILLE, "departmentResearch");
    }

    this.objectForSubscribtion.configObservablePutDetachedContactSecond = this.serviceConfigObservable.configObservablePutDetachedContactSecond.subscribe((activation: boolean) => {
      this.removeDataContactSecond();
    })
  }

  // Init after view ---------------------------------------------------------------------------------------------------------------------------------------------------------------
  ngOnInit() {
    // Get referential stock of the api for get all operation for the select program cible
    try {
      this.serviceResearch.getReferentialStock().pipe(first()).subscribe((referentialStock: any) => {

        this.referentialStock = referentialStock.datas;
        this.referentialStock.Operations.forEach((operation: any) => {
          this.initFormAllStepper = true;

          if (this.data.dataPopup.event2['if_mainoperationid_label'] !== null) {
            if (this.data.dataPopup.event2['if_mainoperationid_label'] === operation.if_operationbusinessname_calc) {
              this.isOpenSelectOther.programCible = true;
              this.immoProjectProgramCible = { name: operation.if_operationbusinessname_calc, id: operation.if_mainoperationid }
            }
          }
          this.listFieldProgramCible.push({ name: operation.if_operationbusinessname_calc, id: { name: operation.if_operationbusinessname_calc, id: operation.if_operationid } });
        });

        setTimeout(() => {
          this.insertImmoProjectForm.patchValue({
            programCible: isNullFieldForm(this.immoProjectProgramCible, false),
          })

          this.ifGoodNumberCloseLoading()
        }, 500)
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetReferentialStock, ["custom-style-error"]
      );
    }

    // Get customers properties of the api for get all situation for the select situation in stepper four
    try {
      this.serviceProject.getCustomerProperties().pipe(first()).subscribe((listCustomerProperties: Array<CustomerPropertie>) => {
        listCustomerProperties.forEach((customerPropertie: CustomerPropertie) => {
          if (this.data.dataPopup.event2['if_customerproperties_label'] !== null) {
            if (this.data.dataPopup.event2['if_customerproperties_label'] === customerPropertie.Label) {
              this.isOpenSelectOther.situation = true;
              this.initFormAllStepper = false;
              this.immoProjectSituation = { name: customerPropertie.Label, id: customerPropertie.Value };
            }
          }
          this.listFieldSituation.push({ name: customerPropertie.Label, id: { name: customerPropertie.Label, id: customerPropertie.Value } });
        })

        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetCustomerProperties, ["custom-style-error"]
      );
    }

    // Get purchase time frame of the api for get all the investment horizon for the select investment hotizon in stepper four
    try {
      this.serviceProject.getPurchaseTimeframes().pipe(first()).subscribe((listPurchaseTimeframes: Array<ReferentialPurchaseTimeframe>) => {
        listPurchaseTimeframes.forEach((purchaseTimeframes: ReferentialPurchaseTimeframe) => {
          if (this.data.dataPopup.event2['if_purchasetimeframe_label'] !== null) {
            if (this.data.dataPopup.event2['if_purchasetimeframe_label'] === purchaseTimeframes.Label) {
              this.isOpenSelectOther.investmentHorizon = true;
              this.immoProjectInvestHorizon = { name: purchaseTimeframes.Label, id: purchaseTimeframes.Value };
            }
          }
          this.listFieldInvestmentHorizon.push({ name: purchaseTimeframes.Label, id: { name: purchaseTimeframes.Label, id: purchaseTimeframes.Value } });
        });

        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetPurchaseTimeframes, ["custom-style-error"]
      );
    }

    // Get main search profils of the api for get all the goal possible for the select goal in stepper four
    try {
      this.serviceProject.getMainSearchprofils().pipe(first()).subscribe((listMainSearchprofils: Array<ReferentialMainSearchprofil>) => {
        listMainSearchprofils.forEach((mainSearchprofils: ReferentialMainSearchprofil) => {
          if (this.data.dataPopup.event2['if_mainsearchprofil_label'] !== null) {
            if (this.data.dataPopup.event2['if_mainsearchprofil_label'] === mainSearchprofils.Label) {
              this.isOpenSelectOther.goal = true;
              this.immoProjectGoal = { name: mainSearchprofils.Label, id: mainSearchprofils.Value };
            }
          }
          this.listFieldGoal.push({ name: mainSearchprofils.Label, id: { name: mainSearchprofils.Label, id: mainSearchprofils.Value } });
        });

        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetMainSearchprofils, ["custom-style-error"]
      );
    }

    // Get investment type of the api for get all the product placement possible for the select product cible in stepper four
    try {
      this.serviceProject.getInvestmentTypes().pipe(first()).subscribe((listInvestmentTypes: Array<ReferentialInvestmentType>) => {
        listInvestmentTypes.forEach((investmentTypes: ReferentialInvestmentType) => {

          if (this.data.dataPopup.event2.InvestmentTypes.length !== 0) {
            this.data.dataPopup.event2.InvestmentTypes.forEach((programCible: any) => {
              if (programCible.if_name !== null) {
                if (programCible.if_name === investmentTypes['investment_type.if_name']) {
                  this.isOpenSelectOther.product = true;
                  this.immoProjectProductCible.push({ name: investmentTypes['investment_type.if_name'], id: { name: investmentTypes['investment_type.if_name'], id: investmentTypes['investment_type.if_investment_typeid'] } });
                  this.immoProjectProductCibleBeforeUpdate.push({ name: investmentTypes['investment_type.if_name'], id: investmentTypes['investment_type.if_investment_typeid'] });
                }
              }
            });
            this.listFieldProduct.push({ name: investmentTypes['investment_type.if_name'], id: { name: investmentTypes['investment_type.if_name'], id: investmentTypes['investment_type.if_investment_typeid'] } });
          } else {
            this.listFieldProduct.push({ name: investmentTypes['investment_type.if_name'], id: { name: investmentTypes['investment_type.if_name'], id: investmentTypes['investment_type.if_investment_typeid'] } });
          }
        })
        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetInvestmentTypes, ["custom-style-error"]
      );
    }

    // Get asset types of the api for get all the typology of asset possible for the select of typlology in stepper four
    try {
      this.serviceProject.getAssetTypes().pipe(first()).subscribe((listAssetTypes: Array<ReferentialAssetType>) => {
        listAssetTypes.forEach((assetTypes: ReferentialAssetType) => {
          this.data.dataPopup.event2.AssetTypes.forEach((typeProject: Types) => {
            this.initFormAllStepper = true;

            if (typeProject.if_fluxsiteb2blabel !== null) {
              if (typeProject.if_fluxsiteb2blabel === assetTypes['assettype.if_fluxsiteb2blabel']) {
                this.isOpenSelectOther.typology = true;
                this.immoProjectTypology.push({ name: assetTypes['assettype.if_fluxsiteb2blabel'], id: { name: assetTypes['assettype.if_fluxsiteb2blabel'], id: assetTypes['assettype.if_assettypeid'] } })
                this.immoProjectTypologyBeforeUpdate.push({ name: assetTypes['assettype.if_fluxsiteb2blabel'], id: assetTypes['assettype.if_assettypeid'] });
              }
            }
          });

          this.listFieldTypology.push({ name: assetTypes['assettype.if_fluxsiteb2blabel'], id: { name: assetTypes['assettype.if_fluxsiteb2blabel'], id: assetTypes['assettype.if_assettypeid'] } });
        });

        this.insertImmoProjectForm.patchValue({
          typology: isNullFieldForm(this.immoProjectTypology, false)
        });

        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetAssetTypes, ["custom-style-error"]
      );
    }

    // Get investment goal of the api for get all the investment goal possible for the select investment goal in stepper four
    try {
      this.serviceProject.getInvestmentObjectives().pipe(first()).subscribe((listInvestmentObjectives: Array<ReferentialInvestmentObjective>) => {
        listInvestmentObjectives.forEach((investmentObjectives: ReferentialInvestmentObjective) => {
          if (this.data.dataPopup.event2['if_investmentobjective1id_label'] !== null) {
            if (this.data.dataPopup.event2['if_investmentobjective1id_label'] === investmentObjectives['investmentobjective.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.goalInvestment1 = true;
              this.immoProjectGoalInvestment1 = { name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: investmentObjectives['investmentobjective.if_investmentobjectiveid'] };
            }
          }
          if (this.data.dataPopup.event2['if_investmentobjective2id_label'] !== null) {
            if (this.data.dataPopup.event2['if_investmentobjective2id_label'] === investmentObjectives['investmentobjective.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.goalInvestment2 = true;
              this.immoProjectGoalInvestment2 = { name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: investmentObjectives['investmentobjective.if_investmentobjectiveid'] };
            }
          }
          if (this.data.dataPopup.event2['if_investmentobjective3id_label'] !== null) {
            if (this.data.dataPopup.event2['if_investmentobjective3id_label'] === investmentObjectives['investmentobjective.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.goalInvestment3 = true;
              this.immoProjectGoalInvestment3 = { name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: investmentObjectives['investmentobjective.if_investmentobjectiveid'] };
            }
          }
          if (this.data.dataPopup.event2['if_investmentobjective4id_label'] !== null) {
            if (this.data.dataPopup.event2['if_investmentobjective4id_label'] === investmentObjectives['investmentobjective.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.goalInvestment4 = true;
              this.immoProjectGoalInvestment4 = { name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: investmentObjectives['investmentobjective.if_investmentobjectiveid'] };
            }
          }
          if (this.data.dataPopup.event2['if_investmentobjective5id_label'] !== null) {
            if (this.data.dataPopup.event2['if_investmentobjective5id_label'] === investmentObjectives['investmentobjective.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.goalInvestment5 = true;
              this.immoProjectGoalInvestment5 = { name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: investmentObjectives['investmentobjective.if_investmentobjectiveid'] };
            }
          }
          this.listFieldGoalInvestment.push({ name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: { name: investmentObjectives['investmentobjective.if_fluxsiteb2blabel'], id: investmentObjectives['investmentobjective.if_investmentobjectiveid'] } });
        });

        this.insertFoyerFiscalProjectForm.patchValue({
          goalInvestment1: isNullFieldForm(this.immoProjectGoalInvestment1, false),
          goalInvestment2: isNullFieldForm(this.immoProjectGoalInvestment2, false),
          goalInvestment3: isNullFieldForm(this.immoProjectGoalInvestment3, false),
          goalInvestment4: isNullFieldForm(this.immoProjectGoalInvestment4, false),
          goalInvestment5: isNullFieldForm(this.immoProjectGoalInvestment5, false)
        });

        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetInvestmentObjectives, ["custom-style-error"]
      );
    }

    // Get communication channel of the api for get all the canal of communication possible for the select of canal in stepper four
    try {
      this.serviceProject.getCommunicationChannel().pipe(first()).subscribe((listCommunicationChannel: Array<ReferentialCommunicationchannel>) => {
        listCommunicationChannel.forEach((communicationChannel: ReferentialCommunicationchannel) => {
          if (this.data.dataPopup.event2['if_mkg_channelid_label'] !== null) {
            if (this.data.dataPopup.event2['if_mkg_channelid_label'] === communicationChannel['communicationchannel.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.canal = true;
              this.immoProjectCanal = { name: communicationChannel['communicationchannel.if_fluxsiteb2blabel'], id: communicationChannel['communicationchannel.if_communicationchannelid'] };
            }
          }
          this.listFieldCanal.push({ name: communicationChannel['communicationchannel.if_fluxsiteb2blabel'], id: { name: communicationChannel['communicationchannel.if_fluxsiteb2blabel'], id: communicationChannel['communicationchannel.if_communicationchannelid'] } });
        });

        this.ifGoodNumberCloseLoading()
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetCommunicationChannel, ["custom-style-error"]
      );
    }

    // Get types of contract employment of the api for get all the types of contract employment possible for the select contract work in stepper one and two
    try {
      this.serviceProject.getEmploymentContractTypes().pipe(first()).subscribe((listEmploymentContract: any) => {
        console.log(listEmploymentContract.datas);
        listEmploymentContract.datas.forEach((employmentContract: any) => {
          if (this.data.dataPopup.event2['if_ctc1_employmentcontractid_label'] !== null) {
            if (this.data.dataPopup.event2['if_ctc1_employmentcontractid'] === employmentContract['contract.if_employmentcontracttypeid']) {
              this.isOpenSelectPrincipal.contractWork = true;
              this.contactPrincipalContractWork = { name: employmentContract['contract.if_fluxsiteb2blabel'], id: employmentContract['contract.if_employmentcontracttypeid'] };
            }
          }
          if (this.data.dataPopup.event2['if_ctc2_employmentcontractid_label'] !== null) {
            if (this.data.dataPopup.event2['if_ctc2_employmentcontractid'] === employmentContract['contract.if_employmentcontracttypeid']) {
              this.isOpenSelectPrincipal.contractWork = true;
              this.contactSecondContractWork = { name: employmentContract['contract.if_fluxsiteb2blabel'], id: employmentContract['contract.if_employmentcontracttypeid'] };
            }
          }
          this.listFieldContractWork.push({ name: employmentContract['contract.if_fluxsiteb2blabel'], id: { name: employmentContract['contract.if_fluxsiteb2blabel'], id: employmentContract['contract.if_employmentcontracttypeid'] } })
        });

        setTimeout(() => {
          this.insertContactPrincipalProjectForm.patchValue({
            contractWork: isNullFieldForm(this.contactPrincipalContractWork, false),
          })
          this.insertContactSecondProjectForm.patchValue({
            contractWork: isNullFieldForm(this.contactSecondContractWork, false),
          })

          this.ifGoodNumberCloseLoading()
        }, 500)
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetEmploymentContractTypes, ["custom-style-error"]
      );
    }

    // Get Tmi of the api for get all the Tmo possible for the select TMI in stepper three
    try {
      this.serviceProject.getTmiValue().pipe(first()).subscribe((listTmi: Array<Tmi>) => {
        listTmi.forEach((tmi: Tmi) => {
          if (this.data.dataPopup.event2['if_tmiid'] !== null) {
            if (this.data.dataPopup.event2['if_tmiid'] === tmi['tmi.if_tmiid']) {
              this.isOpenSelectOther.tmi = true;
              this.immoProjectTmi = { name: tmi['tmi.if_fluxsiteb2blabel'], id: tmi['tmi.if_tmiid'] };
            }
          }
          this.listFieldTmi.push({ name: tmi['tmi.if_fluxsiteb2blabel'], id: { name: tmi['tmi.if_fluxsiteb2blabel'], id: tmi['tmi.if_tmiid'] } });
        });

        setTimeout(() => {
          this.insertFoyerFiscalProjectForm.patchValue({
            tmi: isNullFieldForm(this.immoProjectTmi, false),
          })
          this.ifGoodNumberCloseLoading()
        }, 500)
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        //Créé message error
        this.serviceErrorMessage.errorMessageGetEmploymentContractTypes, ["custom-style-error"]
      );
    }

    // Get InvestmentRisks of the api for get all the InvestmentRisks possible for the select InvestmentRisks in stepper three
    try {
      this.serviceProject.getInvestmentRisks().pipe(first()).subscribe((listInvestmentRisks: Array<Risk>) => {
        listInvestmentRisks.forEach((investmentRisks: Risk) => {
          if (this.data.dataPopup.event2['risk_if_fluxsiteb2blabel'] !== null) {
            if (this.data.dataPopup.event2['risk_if_fluxsiteb2blabel'] === investmentRisks['risk.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.acceptWarning = true;
              this.immoProjectInvestmentRisks = { name: investmentRisks['risk.if_fluxsiteb2blabel'], id: investmentRisks['risk.if_investment_riskid'] };
            }
          }
          this.listFieldInvestmentRisks.push({ name: investmentRisks['risk.if_fluxsiteb2blabel'], id: { name: investmentRisks['risk.if_fluxsiteb2blabel'], id: investmentRisks['risk.if_investment_riskid'] } });
        });

        setTimeout(() => {
          this.insertFoyerFiscalProjectForm.patchValue({
            acceptWarning: isNullFieldForm(this.immoProjectInvestmentRisks, false),
          })
          this.ifGoodNumberCloseLoading()
        }, 500)
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        //Créé message error
        this.serviceErrorMessage.errorMessageGetEmploymentContractTypes, ["custom-style-error"]
      );
    }

    // Get Tmi of the api for get all the Tmo possible for the select TMI in stepper three
    try {
      if (this.subModuleUse === 'submodule_Quintesens') {
        this.serviceProject.getAnnualInComeTypeQuintesensCustom().pipe(first()).subscribe((listAnnualInComeType: Array<any>) => {
          listAnnualInComeType.forEach((annualInComeType: any) => {
            if (this.data.dataPopup.event2['qui_ctc1_annualincometype'] !== null) {
              if (this.data.dataPopup.event2['qui_ctc1_annualincometype'] === annualInComeType.Value) {
                this.isOpenSelectPrincipal.typeRent = true;
                this.contactPrincipalTypeRent = { name: annualInComeType.Label, id: annualInComeType.Value };
              }
              if (this.data.dataPopup.event2['qui_ctc2_annualincometype'] === annualInComeType.Value) {
                this.isOpenSelectSecond.typeRent = true;
                this.contactSecondTypeRent = { name: annualInComeType.Label, id: annualInComeType.Value };
              }
            }
            this.listFieldTypeRent.push({ name: annualInComeType.Label, id: { name: annualInComeType.Label, id: annualInComeType.Value } });
          });

          setTimeout(() => {
            this.insertContactPrincipalProjectForm.patchValue({
              typeRent: isNullFieldForm(this.contactPrincipalTypeRent, false),
            })
            this.insertContactSecondProjectForm.patchValue({
              typeRent: isNullFieldForm(this.contactSecondTypeRent, false),
            })
            this.ifGoodNumberCloseLoading()
          }, 500)
        });
      }
    } catch (e) {
      this.snackbar.openSnackBar(
        //Créé message error
        this.serviceErrorMessage.errorMessageGetEmploymentContractTypes, ["custom-style-error"]
      );
    }


    // Get tax exemption laws of the api for get all the tax exemption laws possible for the select tax system in stepper four
    try {
      this.serviceProject.getTaxExemptionLaws().pipe(first()).subscribe((taxSystem: any) => {
        console.log(taxSystem.datas)
        taxSystem.datas.forEach((taxexemptionlaw: any) => {
          if (this.data.dataPopup.event2['if_immo_taxexemptionid_label'] !== null) {
            if (this.data.dataPopup.event2['if_immo_taxexemptionid_label'] === taxexemptionlaw['law.if_fluxsiteb2blabel']) {
              this.isOpenSelectOther.taxSystem = true;
              this.immoProjectTaxSystem = { name: taxexemptionlaw['law.if_fluxsiteb2blabel'], id: taxexemptionlaw['law.if_taxexemptionlawid'] }
            }
          }
          this.listFieldTaxSystem.push({ name: taxexemptionlaw['law.if_fluxsiteb2blabel'], id: { name: taxexemptionlaw['law.if_fluxsiteb2blabel'], id: taxexemptionlaw['law.if_taxexemptionlawid'] } });
        });

        setTimeout(() => {
          this.insertImmoProjectForm.patchValue({
            taxSystem: isNullFieldForm(this.immoProjectTaxSystem, false)
          })

          this.ifGoodNumberCloseLoading()
        }, 500)
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetTaxExemptionLaws, ["custom-style-error"]
      );
    }

    // Get salutations of the api for get all the civility type possible for the select civility in stepper one and two
    try {
      this.serviceProject.getSalutations()
        .pipe(first()).subscribe((listCivitlity: any) => {
          this.listFieldCivility = [];
          listCivitlity.datas.forEach((salutation: Salutation) => {
            if (this.data.dataPopup.event2 !== undefined) {
              if (this.data.dataPopup.event2['if_ctc1salutationid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc1salutationid'] === salutation['salutation.if_salutationid']) {
                  this.isOpenSelectPrincipal.civility = true
                  this.contactPrincipalCivility = { name: salutation['salutation.if_name'], id: salutation['salutation.if_salutationid'] };
                }
              }
              if (this.data.dataPopup.event2['if_ctc2salutationid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc2salutationid'] === salutation['salutation.if_salutationid']) {
                  this.isOpenSelectSecond.civility = true
                  this.contactSecondCivility = { name: salutation['salutation.if_name'], id: salutation['salutation.if_salutationid'] };
                }
              }
            }
            this.listFieldCivility.push({ name: salutation['salutation.if_name'], id: { name: salutation['salutation.if_name'], id: salutation['salutation.if_salutationid'] } });
          });
          setTimeout(() => {
            this.insertContactPrincipalProjectForm.patchValue({
              civility: isNullFieldForm(this.contactPrincipalCivility, false)
            })

            this.insertContactSecondProjectForm.patchValue({
              civility: isNullFieldForm(this.contactSecondCivility, false)
            })

            this.ifGoodNumberCloseLoading()
          }, 100)
        });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetSalutations, ["custom-style-error"]
      );
    }

    // Get matrimonial regimes of the api for get all the matrimonial regimes type possible for the select matrimonial regimes in stepper one and two
    try {
      this.serviceProject.getMatrimonialRegimes()
        .pipe(first()).subscribe((listMatrimonialRegime: any) => {
          this.listFieldMatrimonialRegime = [];
          listMatrimonialRegime.datas.forEach((matrimonialRegime: MatrimonialRegime) => {
            if (this.data.dataPopup.event2 !== undefined) {
              if (this.data.dataPopup.event2['if_ctc1_matrimonialregimeid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc1_matrimonialregimeid'] === matrimonialRegime['matrimonialregime.if_matrimonialregimeid']) {
                  this.isOpenSelectPrincipal.matrimonialRegime = true;
                  this.contactPrincipalMatrimonialRegime = { name: matrimonialRegime['matrimonialregime.if_name'], id: matrimonialRegime['matrimonialregime.if_matrimonialregimeid'] }
                }
              }
              if (this.data.dataPopup.event2['if_ctc2_matrimonialregimeid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc2_matrimonialregimeid'] === matrimonialRegime['matrimonialregime.if_matrimonialregimeid']) {
                  this.isOpenSelectSecond.matrimonialRegime = true;
                  this.contactSecondMatrimonialRegime = { name: matrimonialRegime['matrimonialregime.if_name'], id: matrimonialRegime['matrimonialregime.if_matrimonialregimeid'] }
                }
              }
            }
            this.listFieldMatrimonialRegime.push({ name: matrimonialRegime['matrimonialregime.if_name'], id: { name: matrimonialRegime['matrimonialregime.if_name'], id: matrimonialRegime['matrimonialregime.if_matrimonialregimeid'] } });
          });
          setTimeout(() => {
            this.insertContactPrincipalProjectForm.patchValue({
              matrimonialRegime: isNullFieldForm(this.contactPrincipalMatrimonialRegime, false)
            })

            this.insertContactSecondProjectForm.patchValue({
              matrimonialRegime: isNullFieldForm(this.contactSecondMatrimonialRegime, false)
            })

            this.ifGoodNumberCloseLoading()
          }, 500)
        });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetMatrimonialRegimes, ["custom-style-error"]
      );
    }

    // Get category socio-professional of the api for get all the category socio-professional possible for the select category in stepper one and two
    try {
      this.serviceProject.getCsps()
        .pipe(first()).subscribe((listCategory: any) => {
          this.listFieldCategory = [];
          listCategory.datas.forEach((category: CategorySP) => {
            if (this.data.dataPopup.event2 !== undefined) {
              if (this.data.dataPopup.event2['if_ctc1_cspid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc1_cspid'] === category['csp.if_cspid']) {
                  this.isOpenSelectPrincipal.category = true;
                  this.contactPrincipalCategory = { name: category['csp.if_name'], id: category['csp.if_cspid'] };
                }
              }
              if (this.data.dataPopup.event2['if_ctc2_cspid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc2_cspid'] === category['csp.if_cspid']) {
                  this.isOpenSelectSecond.category = true;
                  this.contactSecondCategory = { name: category['csp.if_name'], id: category['csp.if_cspid'] };
                }
              }
            }
            this.listFieldCategory.push({ name: category['csp.if_name'], id: { name: category['csp.if_name'], id: category['csp.if_cspid'] } });
          });
          setTimeout(() => {
            this.insertContactPrincipalProjectForm.patchValue({
              category: isNullFieldForm(this.contactPrincipalCategory, false),
            })

            this.insertContactSecondProjectForm.patchValue({
              category: isNullFieldForm(this.contactSecondCategory, false),
            })

            this.ifGoodNumberCloseLoading()
          }, 500)
        });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetCsps, ["custom-style-error"]
      );
    }

    // Get family situtation of the api for get all the family situtation type possible for the select family situtation in stepper one and two
    try {
      this.serviceProject.getFamilySituations()
        .pipe(first()).subscribe((listFamilySituations: any) => {
          this.listFieldFamilySituation = [];
          listFamilySituations.datas.forEach((familySituation: FamilySituation) => {
            if (this.data.dataPopup.event2 !== undefined) {
              if (this.data.dataPopup.event2['if_ctc1_familysituationid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc1_familysituationid'] === familySituation['familysituation.if_familysituationid']) {
                  this.isOpenSelectPrincipal.familySituation = true;
                  this.contactPrincipalFamilySituation = { name: familySituation['familysituation.if_name'], id: familySituation['familysituation.if_familysituationid'] };
                }
              }
              if (this.data.dataPopup.event2['if_ctc2_familysituationid'] !== null) {
                if (this.data.dataPopup.event2['if_ctc2_familysituationid'] === familySituation['familysituation.if_familysituationid']) {
                  this.isOpenSelectSecond.familySituation = true;
                  this.contactSecondFamilySituation = { name: familySituation['familysituation.if_name'], id: familySituation['familysituation.if_familysituationid'] };
                }
              }
            }
            this.listFieldFamilySituation.push({ name: familySituation['familysituation.if_name'], id: { name: familySituation['familysituation.if_name'], id: familySituation['familysituation.if_familysituationid'] } });
          });
          setTimeout(() => {
            this.insertContactPrincipalProjectForm.patchValue({
              familySituation: isNullFieldForm(this.contactPrincipalFamilySituation, false)
            })
            this.insertContactSecondProjectForm.patchValue({
              familySituation: isNullFieldForm(this.contactSecondFamilySituation, false)
            })

            this.ifGoodNumberCloseLoading()
          }, 500)
        });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetFamilySituations, ["custom-style-error"]
      );
    }

    // Get informations from client's E-Rip projet. 
    // this.serviceErip.GetEripsInformationsByEripGuid(this.data.dataPopup.event2['if_id_erip']).pipe().subscribe(
    //   (response:any) => {
    //       var getAge = (strAge:string) => {return new Date(Date.now() - new Date(strAge).getTime()).getUTCFullYear() - 1970};
    //       var concatMultipleStringValue = (l:any, value:string, isAge:boolean = false) => {
    //         var finalStr = '';
    //         l.map((el: any) => {finalStr += isAge? getAge(el[value]).toString() : el[value]; 
    //           if(l.length-1 > l.indexOf(el))
    //             finalStr += ", ";
    //         });
    //         return finalStr;
    //       };
    //       this.insertFoyerFiscalProjectForm.patchValue({
    //         nbPartFiscal : response.body.fiscalParticularCases,
    //         minSavingEffort : response.body.projectMinimumSavings,
    //         maxSavingEffort : response.body.projectMaximumSavings,
    //         disponibilityMin : response.body.financialMinimumSupply,
    //         disponibilityMax : response.body.financialMaximumSupply,
    //         originDisponibility : response.body.financialSource,
    //         nbChildrenCharge : response.body.children.length,
    //         nbChildren : response.body.children.length,
    //         yearsChildren : response.body.children.length > 0 ? concatMultipleStringValue(response.body.children, "birthDate", true) : "",
    //         nameChildren : response.body.children.length > 0 ? concatMultipleStringValue(response.body.children, "firstNames") : "",
    //         taxIncomeRefN : response.body.incomes[0]['income'],
    //         paidTaxAmount : response.body.previousTaxes,
    //       });
    //   },

    //   (error) => {
    //   });

    // Get marketing origin of the api for get all the marketing origin type possible for the select origin in stepper one and two
    try {
      this.serviceProject.getMarketingOrigins()
        .pipe(first()).subscribe((listOrigin: any) => {
          this.listFieldOrigin = [];
          listOrigin.datas.forEach((origin: Origin) => {
            if (this.data.dataPopup.event2.marketorg_if_marketingoriginid !== null) {
              if (this.data.dataPopup.event2.marketorg_if_marketingoriginid === origin['origin.if_marketingoriginid']) {
                this.isOpenSelectOther.origin = true;
                this.immoProjectOrigin = { name: origin['origin.if_fluxsiteb2blabel'], id: origin['origin.if_marketingoriginid'] }
              }
            }
            this.listFieldOrigin.push({ name: origin['origin.if_fluxsiteb2blabel'], id: { name: origin['origin.if_fluxsiteb2blabel'], id: origin['origin.if_marketingoriginid'] } });
          });
          if (this.immoProjectOrigin === null) {
            this.isOpenSelectOther.origin = true;
            this.immoProjectOrigin = { name: this.data.dataPopup.event2.marketorg_if_fluxsiteb2blabel, id: this.data.dataPopup.event2.marketorg_if_marketingoriginid }
            this.listFieldOrigin.push({ name: this.data.dataPopup.event2.marketorg_if_fluxsiteb2blabel, id: { name: this.data.dataPopup.event2.marketorg_if_fluxsiteb2blabel, id: this.data.dataPopup.event2.marketorg_if_marketingoriginid } });
          }

          this.insertImmoProjectForm.patchValue({
            origin: this.immoProjectOrigin
          })

          this.ifGoodNumberCloseLoading()
        });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetMarketingOrigins, ["custom-style-error"]
      );
    }

    // Set time out for patch all value of all the form of project and for close loading of all the stepper
    setTimeout(() => {
      this.insertContactPrincipalProjectForm.patchValue({
        firstname: isNullFieldForm(this.data.dataPopup.event2['if_ctc1firstname'], true),
        lastname: isNullFieldForm(this.data.dataPopup.event2['if_ctc1lastname'], true),
        youngLastname: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_maidenname'], true),
        phone: isNullFieldForm(this.data.dataPopup.event2['if_ctc1personnalphone'], true),
        mobilePhone: isNullFieldForm(this.data.dataPopup.event2['if_ctc1mobilephone'], true),
        email: isNullFieldForm(this.data.dataPopup.event2['if_ctc1email1'], true),
        zipCode: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_postalcode'], true),

        numAddress: this.data.dataPopup.event2['if_ctc1_streetnumber'] !== null ? this.data.dataPopup.event2['if_ctc1_streetnumber'] : "",
        typeVoie: this.data.dataPopup.event2['if_ctc1_streettype'] !== null ? this.data.dataPopup.event2['if_ctc1_streettype'] : "",
        nameVoie: this.data.dataPopup.event2['if_ctc1_streetname'] !== null ? this.data.dataPopup.event2['if_ctc1_streetname'] : "",

        // this.data.dataPopup.event2['if_ctc1_streetnumber'] + " " + this.data.dataPopup.event2['if_ctc1_streetnumber'] + " " + this.data.dataPopup.event2['if_ctc1_streetname']
        addressOne: this.data.dataPopup.event2['if_ctc1_street1'] !== null ? this.data.dataPopup.event2['if_ctc1_street1'].replace('\n', " ") : "",
        addressTwo: this.data.dataPopup.event2['if_ctc1_street2'] !== null ? this.data.dataPopup.event2['if_ctc1_street2'].replace('\n', " ") : "",
        phonePro: isNullFieldForm(this.data.dataPopup.event2['if_ctc1professionalphone'], true),
        emailPro: isNullFieldForm(this.data.dataPopup.event2['if_ctc1email2'], true),
        birthday: isNullFieldForm(this.data.dataPopup.event2['if_ctc1birthday'], true),
        cityBirthday: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_birthplace'], true),
        nationality: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_nationality'], true),
        numCardDay: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_residencepermitnumber'], true),
        periodicityValidity: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_residencepermitperioddesc'], true),
        familySituationDate: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_familysituation_date'], true),
        familySituationLocation: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_familysituation_place'], true),
        familySituationNotary: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_familysituation_notarydesc'], true),

        profession: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_jobtitle'], true),
        seniority: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_professionalsituationsincedate'], true),
        employerActual: isNullFieldForm(this.data.dataPopup.event2['if_ctc1_currentemployer'], true),
        employerAddress: this.data.dataPopup.event2['if_ctc1_currentemployeraddress'] !== null ? this.data.dataPopup.event2['if_ctc1_currentemployeraddress'].replace('\n', " ") : "",

        rentMonth: (this.subModuleUse === 'submodule_Quintesens') ? (this.data.dataPopup.event2['qui_ctc1_salaryamount'] !== null) ? this.data.dataPopup.event2['qui_ctc1_salaryamount'] : "" : ""
      });

      this.insertContactSecondProjectForm.patchValue({
        firstname: isNullFieldForm(this.data.dataPopup.event2['if_ctc2firstname'], true),
        lastname: isNullFieldForm(this.data.dataPopup.event2['if_ctc2lastname'], true),
        youngLastname: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_maidenname'], true),
        phone: isNullFieldForm(this.data.dataPopup.event2['if_ctc2personnalphone'], true),
        mobilePhone: isNullFieldForm(this.data.dataPopup.event2['if_ctc2mobilephone'], true),
        email: isNullFieldForm(this.data.dataPopup.event2['if_ctc2email1'], true),
        zipCode: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_postalcode'], true),

        numAddress: this.data.dataPopup.event2['if_ctc2_streetnumber'] !== null ? this.data.dataPopup.event2['if_ctc2_streetnumber'] : "",
        typeVoie: this.data.dataPopup.event2['if_ctc2_streettype'] !== null ? this.data.dataPopup.event2['if_ctc2_streettype'] : "",
        nameVoie: this.data.dataPopup.event2['if_ctc2_streetname'] !== null ? this.data.dataPopup.event2['if_ctc2_streetname'] : "",
        
        addressOne: this.data.dataPopup.event2['if_ctc2_street1'] !== null ? this.data.dataPopup.event2['if_ctc2_street1'].replace('\n', " ") : "",
        addressTwo: this.data.dataPopup.event2['if_ctc2_street2'] !== null ? this.data.dataPopup.event2['if_ctc2_street2'].replace('\n', " ") : "",
        phonePro: isNullFieldForm(this.data.dataPopup.event2['if_ctc2professionalphone'], true),
        emailPro: isNullFieldForm(this.data.dataPopup.event2['if_ctc2email2'], true),
        birthday: isNullFieldForm(this.data.dataPopup.event2['if_ctc2birthday'], true),
        cityBirthday: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_birthplace'], true),
        nationality: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_nationality'], true),
        numCardDay: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_residencepermitnumber'], true),
        periodicityValidity: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_residencepermitperioddesc'], true),
        toggleCoBooking: this.data.dataPopup.event2['if_cobooking'],
        familySituationDate: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_familysituation_date'], true),
        familySituationLocation: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_familysituation_place'], true),
        familySituationNotary: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_familysituation_notarydesc'], true),

        profession: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_jobtitle'], true),
        contractWork: isNullFieldForm(this.contactSecondContractWork, false),
        seniority: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_professionalsituationsincedate'], true),
        employerActual: isNullFieldForm(this.data.dataPopup.event2['if_ctc2_currentemployer'], true),
        employerAddress: this.data.dataPopup.event2['if_ctc2_currentemployeraddress'] !== null ? this.data.dataPopup.event2['if_ctc2_currentemployeraddress'].replace('\n', " ") : "",

        rentMonth: (this.subModuleUse === 'submodule_Quintesens') ? this.data.dataPopup.event2['qui_ctc2_salaryamount'] !== null ? this.data.dataPopup.event2['qui_ctc2_salaryamount'] : "" : "",
      });

      this.insertFoyerFiscalProjectForm.patchValue({
        nbPartFiscal: isNullFieldForm(this.data.dataPopup.event2['if_taxpartsnumber'], true),
        nbPersCharge: isNullFieldForm(this.data.dataPopup.event2['if_dependentpeoplenumber'], true),
        nbChildren: isNullFieldForm(this.data.dataPopup.event2['if_childrencount'], true),
        nbChildrenCharge: isNullFieldForm(this.data.dataPopup.event2['if_dependentchildsnumber'], true),
        yearsChildren: isNullFieldForm(this.data.dataPopup.event2['if_childrenages'], true),
        nameChildren: isNullFieldForm(this.data.dataPopup.event2['if_childrensnames'], true),
        grossTaxAmount: isNullFieldForm(this.data.dataPopup.event2['if_incometax_grossamount'], true),
        paidTaxAmount: isNullFieldForm(this.data.dataPopup.event2['if_incometax_payableamount'], true),
        taxIncomeRefN: isNullFieldForm(this.data.dataPopup.event2['if_incometaxref_n'], true),
        taxIncomeRefN1: isNullFieldForm(this.data.dataPopup.event2['if_incometaxref_n1'], true),
        taxIncomeRefN2: isNullFieldForm(this.data.dataPopup.event2['if_incometaxref_n2'], true),
        // savingConstituted: isNullFieldForm(this.data.dataPopup.event2['if_constitutedthrift'], true),
        minSavingEffort: isNullFieldForm(this.data.dataPopup.event2['if_investment_savingscapacitymonthlymin'], true),
        maxSavingEffort: isNullFieldForm(this.data.dataPopup.event2['if_investment_savingscapacitymonthlymax'], true),
        // profil: this.immoProjectProfil,
        disponibilityMax: this.data.dataPopup.event2.if_investment_availabilityamountmax !== null ? numberWithCommas(this.data.dataPopup.event2.if_investment_availabilityamountmax) : "",
        disponibilityMin: this.data.dataPopup.event2.if_investment_availabilityamountmin !== null ? numberWithCommas(this.data.dataPopup.event2.if_investment_availabilityamountmin) : "",
        originDisponibility: isNullFieldForm(this.data.dataPopup.event2['if_investment_availabilityorigin'], true),
        wishRentability: isNullFieldForm(this.data.dataPopup.event2['if_wishprofitabilitymin'], true),
      })

      let immovable = this.data.dataPopup.event2['if_isrealestateproject'];
      let financial = this.data.dataPopup.event2['if_isrealestateproject'];
      // if(this.data.dataPopup.event2['if_isrealestateproject']) {
      //   immovable = true;
      // } else if (this.data.dataPopup.event2['if_isrealestateproject'] === false) {
      //   immovable = false;
      // }

      // if(this.data.dataPopup.event2['if_isrealestateproject'] === true) {
      //   immovable = true;
      // } else if (this.data.dataPopup.event2['if_isrealestateproject'] === false) {
      //   immovable = false;
      // }

      // if(this.data.dataPopup.event2['if_isinvestmentproject']) {
      //   financial = true;
      // } else if (this.data.dataPopup.event2['if_isinvestmentproject'] === false) {
      //   financial = false;
      // }

      // if(this.data.dataPopup.event2['if_isinvestmentproject'] === true) {
      //   financial = true;
      // } else if (this.data.dataPopup.event2['if_isinvestmentproject'] === false) {
      //   financial = false;
      // }

      this.insertImmoProjectForm.patchValue({
        origin: this.immoProjectOrigin,
        canal: this.immoProjectCanal,
        needMore: isNullFieldForm(this.data.dataPopup.event2['if_requirementdescription'], true),

        immovable: immovable,
        financial: financial,

        comment: isNullFieldForm(this.data.dataPopup.event2['if_projectcomments'], true),

        situation: this.immoProjectSituation,
        goal: this.immoProjectGoal,
        investmentHorizon: this.immoProjectInvestHorizon,
        // epargneMax: numberWithCommas(this.data.dataPopup.event2['if_investment_savingscapacitymonthlymax'] !== null ? this.data.dataPopup.event2['if_investment_savingscapacitymonthlymax'] : ""),
        // epargneMin: numberWithCommas(this.data.dataPopup.event2['if_investment_savingscapacitymonthlymin'] !== null ? this.data.dataPopup.event2['if_investment_savingscapacitymonthlymin'] : ""),
        budgetMax: this.data.dataPopup.event2['if_wishbudgetmax'] !== null ? numberWithCommas(this.data.dataPopup.event2['if_wishbudgetmax']) : "",
        budgetMin: this.data.dataPopup.event2['if_wishbudgetmin'] !== null ? numberWithCommas(this.data.dataPopup.event2['if_wishbudgetmin']) : "",
        product: this.immoProjectProductCible,
        surfaceMin: this.data.dataPopup.event2['if_immo_surfacemin'] !== null ? this.data.dataPopup.event2['if_immo_surfacemin'] : "",
        surfaceMax: this.data.dataPopup.event2['if_immo_surfacemax'] !== null ? this.data.dataPopup.event2['if_immo_surfacemax'] : "",
        // adminCompleted: false
      });

      this.ifGoodNumberCloseLoading()


    }, 1500);
  } // End Ng On Init ------------------------------------------------------------------------------------------------------------------------------------------------------------

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    if (this.objectForSubscribtion.configObservablePutDetachedContactSecond !== undefined) {
      this.objectForSubscribtion.configObservablePutDetachedContactSecond.unsubscribe();
    }
  }

  // Function use after the initiation of the view 
  ngAfterViewInit() {
    if (this.data.namePopup === "EditPlacement" || this.data.namePopup === "EditImmovable") {
      this.stepper.selectedIndex = 3;
    } else if (this.data.namePopup === "EditFoyerFiscal") {
      this.stepper.selectedIndex = 2;
    } else if (this.data.namePopup === "EditContactSecond") {
      this.stepper.selectedIndex = 1;
    } else if (this.data.namePopup === "EditContact") {
      this.stepper.selectedIndex = 0;
    }
  }

  ifGoodNumberCloseLoading() {
    this.loadingFormAllStepper += 1;
    if (this.loadingFormAllStepper === 18) {
      this.initFormAllStepper = false;
    }
  }

  // Compare with for select (compare value observe in select with item.id (show construction array items))
  compareWithFn(item: any, selected: any) {
    return item.id === selected;
  }

  compareWithFnMultiple(item: any, selected: any) {
    return item.id.id === selected.id.id;
  }

  // Function get error of your form and return string at your view
  getErrorMessageRequired(controlFormSelected: { getError: (arg0: string) => any; }, nameControl: string) {
    if (nameControl == "firstName") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Prénom';
      } else {
        return "";
      }
    } else if (nameControl == "lastName") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Nom de famille';
      } else {
        return "";
      }
    } else if (nameControl == "addressOne") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre adresse 1';
      } else {
        return "";
      }
    } else if (nameControl == "zipCode") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Code Postal';
      } else if (controlFormSelected.getError('maxLength') && controlFormSelected.getError('maxLength')) {
        return "Votre Code Postal doit être composé de 5 chiffre";
      } else if (controlFormSelected.getError('pattern')) {
        return "Entrez un Code Postal valide";
      } else {
        return "";
      }
    } else if (nameControl == "city") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Ville';
      } else {
        return "";
      }
    } else if (nameControl == "phone") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Téléphone';
      } else if (controlFormSelected.getError('maxLength') && controlFormSelected.getError('maxLength')) {
        return "Votre Téléphone doit être composé de 10 chiffre";
      } else if (controlFormSelected.getError('pattern')) {
        return "Entrez un Téléphone valide";
      } else {
        return "";
      }
    } else if (nameControl == "mobilePhone") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Téléphone mobile';
      } else if (controlFormSelected.getError('maxLength') && controlFormSelected.getError('maxLength')) {
        return "Votre Téléphone mobile doit être composé de 10 chiffre";
      } else if (controlFormSelected.getError('pattern')) {
        return "Entrez un Téléphone mobile valide";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  // Function get error email of your form and return string at your view
  getErrorMessageEmail(nameControl: string) {
    if (nameControl === 'email') {
      if (this.insertContactPrincipalProjectForm.controls.email.getError('required')) {
        return 'Entrer votre Email';
      } else if (this.insertContactPrincipalProjectForm.controls.email.hasError('email')) {
        return 'Entrer une adresse email valide';
      } else {
        return "";
      }
    } else if (nameControl === 'emailPro') {
      if (this.insertContactPrincipalProjectForm.controls.email.hasError('email')) {
        return 'Entrer une adresse email valide';
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  // Submit update project for save and close popup or save project and open popup for post a new appointment (rdv)
  onSubmitCompletedProject(nameChoice: string) {
    this.openDialogLoading("updateProject");
    let dataPutProject = this.getDataPostProject(true, true, true, true);
    if (dataPutProject.AssetTypes.length !== 0 || (dataPutProject.AssetTypes.length === 0 && this.immoProjectTypologyBeforeUpdate.length !== 0) || dataPutProject.InvestmentTypes.length !== 0 || (dataPutProject.InvestmentTypes.length === 0 && this.immoProjectProductCibleBeforeUpdate.length !== 0) || dataPutProject.Fields.length !== 0) {
      let newDataPutProject: any = {};
      if (dataPutProject.AssetTypes.length !== 0 || dataPutProject.InvestmentTypes.length !== 0) {
        newDataPutProject.Fields = dataPutProject.Fields
      }
      if (dataPutProject.Fields.length !== 0) {
        newDataPutProject.Fields = dataPutProject.Fields
      }
      if (dataPutProject.AssetTypes.length !== 0) {
        newDataPutProject.AssetTypes = dataPutProject.AssetTypes
      }
      if (dataPutProject.InvestmentTypes.length !== 0) {
        newDataPutProject.InvestmentTypes = dataPutProject.InvestmentTypes
      }
      if (newDataPutProject.Fields !== undefined) {
        try {
          this.serviceProject.putProject(newDataPutProject, this.data.dataPopup.projectId).subscribe((data: any) => {
            // this.openDialogLoading('synchronizedBafProject');
            localStorage.setItem('IdProjectBafUpdate', this.data.dataPopup.projectId)
            this.serviceConfigObservable.emitRefreshInProgress(true);
            this.popupLoading.closeAll();
            if (nameChoice !== "saveAndClose") {
              this.openPopupCreateNewRdv(data.dataPopup.event2)
            }
          })
        } catch (e) {
          console.log(e);
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessagePutProject, ["custom-style-error"]
          );
          this.popupLoading.closeAll();
        }
      } else {
        this.popupLoading.closeAll();
        if (nameChoice !== "saveAndClose") {
          this.openPopupCreateNewRdv(this.data.dataPopup.event2)
        }
      }
    } else {
      this.popupLoading.closeAll();
      if (nameChoice !== "saveAndClose") {
        this.openPopupCreateNewRdv(this.data.dataPopup.event2)
      }
    }
  }

  // Methode for get if baf is synchronized after update project
  async getBafSynchronized(data: any, openNewRdv: boolean) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        // this.popupLoading.closeAll();
        this.openDialogLoading('notGetBafSynchronizedProject');
        clearInterval(setIntervalProjectId);
      }

      this.serviceProject.getBafSynchronized(data.dataPopup.projectId).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize.datas === true) {
          localStorage.setItem('IdProjectBafUpdate', data.dataPopup.projectId)
          this.serviceConfigObservable.emitRefreshInProgress(true);
          this.popupLoading.closeAll();
          if (openNewRdv) {
            this.openPopupCreateNewRdv(data.dataPopup.event)
            clearInterval(setIntervalProjectId);
          } else {
            clearInterval(setIntervalProjectId);
          }
        }
      }
      console.log(i)
      i++
    }, 1000)
  }

  // Check for input field if not null or empty and compare value before update with new value if value as change
  checkIfModifySample(nameFieldProjectId: string, formValue: any) {
    if (this.serviceErip.isEripActivated && this.serviceErip.isEripEnabledForCurrentSubModule) {
      if (formValue == undefined && this.serviceErip.readOnlyFields.includes(nameFieldProjectId))
        return false
      if (formValue != undefined && this.serviceErip.readOnlyFields.includes(this.insertContactPrincipalProjectFormKvp[formValue])) // Si un champ est en ReadOnly, on l'ignore.
        return false
      else if (this.serviceErip.readOnlyFields.includes(this.insertFoyerFiscalProjectFormKvp[formValue])) // Si un champ est en ReadOnly, on l'ignore.
        return false
      else { // Répétition, à refactoriser
        // Check if field is
        let data: any = this.data.dataPopup.event2
        let fieldComparation = data[nameFieldProjectId]
        if ((fieldComparation !== null && fieldComparation !== "") || (formValue !== null && formValue !== "")) {
          if (fieldComparation !== formValue) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } // Fin de la répétition
    }
    else { // Répétition, à refactoriser
      // Check if field is
      let data: any = this.data.dataPopup.event2 
      let fieldComparation = data[nameFieldProjectId]
      if ((fieldComparation !== null && fieldComparation !== "") || (formValue !== null && formValue !== "")) {
        if (fieldComparation !== formValue) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } // Fin de la répétition
  }

  // Check for input field special if not null or empty and compare value special before update with new value if value as change
  checkIfModifySampleCustom(nameFieldProjectId: string, formValue: any) {
    let data: any = this.data.dataPopup.event2
    let fieldComparation = data[nameFieldProjectId]
    if ((fieldComparation !== null && fieldComparation !== "") || (formValue !== null && formValue !== "")) {
      if (fieldComparation !== null) {
        if (fieldComparation.replace('\n', " ") !== formValue) {
          return true;
        } else {
          return false;
        }
      } else {
        if (fieldComparation !== formValue) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  }

  // Check if not null or empty and return value or return value empty
  isNullOrEmptySample(formValue: any) {
    if (formValue !== null) {
      return formValue
    } else {
      return ""
    }
  }

  // Check if not null or empty and return value or return value empty
  isNullOrEmptySampleDate(formValue: any) {
    if (formValue !== null) {
      return convertTZ(formValue)
    } else {
      return ""
    }
  }

  // Check select field for compare value before update with new value if value as change
  checkIfModifySelect(nameFieldProjectId: string, formValue: any) {
    let data: any = this.data.dataPopup.event2
    let fieldComparation = data[nameFieldProjectId]
    if (formValue === fieldComparation) {
      return false
    } else {
      return true
    }
  }

  // Check select multiple field if the array not empty and create new array for update project
  isNullOrEmptySelectMultiple(formValue: any, getIdOrName: string) {
    if (formValue.length !== 0) {
      let newFormValue: Array<string> = []
      formValue.forEach((value: any) => {
        newFormValue.push(value[getIdOrName])
      });
      return newFormValue
    } else {
      return []
    }
  }

  // Verify for a select multiple one, Verify if the data one and the data two are different of null or empty and compare with a other method 
  checkIfModifySelectMultiple(nameFieldProjectId: string, formValue: any, nameFieldId: string, compareIdOrName: string) {
    let data: any = this.data.dataPopup.event2
    let fieldComparation = data[nameFieldProjectId]
    if ((fieldComparation !== null && fieldComparation.length !== 0) || (formValue !== null && formValue.length !== 0)) {
      if ((fieldComparation !== null && fieldComparation.length !== 0) && (formValue !== null && formValue.length !== 0)) {
        if (nameFieldProjectId === "InvestmentTypes") {
          var onlyInA = this.immoProjectProductCibleBeforeUpdate.filter(this.comparer(formValue, compareIdOrName, compareIdOrName));
          var onlyInB = formValue.filter(this.comparer(this.immoProjectProductCibleBeforeUpdate, compareIdOrName, compareIdOrName));
        } else {
          var onlyInA = this.immoProjectTypologyBeforeUpdate.filter(this.comparer(formValue, compareIdOrName, compareIdOrName));
          var onlyInB = formValue.filter(this.comparer(this.immoProjectTypologyBeforeUpdate, compareIdOrName, compareIdOrName));
        }

        if (onlyInA.concat(onlyInB).length !== 0) {
          return true;
        } else {
          return false;
        }
      }
      return true
    } else {
      return false
    }
  }

  // Verify for a select multiple two, Verify if the data one and the data two are different of null or empty and compare with a other method 
  checkIfModifySelectMultiple2(nameFieldProjectId: string, formValue: any, nameFieldId: string, compareIdOrName: string) {
    let data: any = this.data.dataPopup.event2
    let fieldComparation = data[nameFieldProjectId]
    if ((fieldComparation !== null && fieldComparation.length !== 0) && (formValue !== null && formValue.length !== 0)) {
      var onlyInA = fieldComparation.filter(this.comparer(formValue, compareIdOrName, nameFieldId));
      var onlyInB = formValue.filter(this.comparer(fieldComparation, nameFieldId, compareIdOrName));

      if (onlyInA.concat(onlyInB).length !== 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Method for compare data of two array and return if he is the same array or not
  comparer(otherArray: Array<any>, nameFieldCurrentId: string, nameFieldOtherId: string) {
    return function (current: any) {
      return otherArray.filter(function (other) {
        return other[nameFieldOtherId] == current[nameFieldCurrentId] && other[nameFieldOtherId] == current[nameFieldCurrentId]
      }).length == 0;
    }
  }

  // Method for the construction and compare the data for update the project
  getDataPostProject(activation_ctc1: boolean, activation_ctc2: boolean, activation_FFiscal: boolean, activation_Project: boolean) {
    let dataProjectUpdate: any = {};

    let Fields: Array<any> = new Array<any>();
    let AssetTypes: Array<any> = new Array<any>();
    let InvestmentTypes: Array<any> = new Array<any>();


    // if (this.data.dataPopup.event2['if_isrealestateproject'] !== (this.insertImmoProjectForm.value.immovable ? 1 : 0)) {
    //   Fields.push({ Key: "if_projecttype", Value : (this.insertImmoProjectForm.value.immovable ? 1 : 0) })
    // }

    if (activation_ctc1) {
      if (this.data.dataPopup.event2['if_isinvestmentproject'] !== this.insertImmoProjectForm.value.financial || this.data.dataPopup.event2['if_isrealestateproject'] !== this.insertImmoProjectForm.value.immovable) {
        Fields.push({ Key: "if_projecttype", Value: (this.insertImmoProjectForm.value.immovable ? 750010000 : 750010001) })
      }
      if (this.checkIfModifySample("if_ctc1lastname", this.insertContactPrincipalProjectForm.value.lastname)) {
        Fields.push({ Key: "if_ctc1lastname", Value: this.insertContactPrincipalProjectForm.value.lastname })
      }
      if (this.checkIfModifySample("if_ctc1firstname", this.insertContactPrincipalProjectForm.value.firstname)) {
        Fields.push({ Key: "if_ctc1firstname", Value: this.insertContactPrincipalProjectForm.value.firstname })
      }
      if (this.checkIfModifySample("if_ctc1email1", this.insertContactPrincipalProjectForm.value.email)) {
        Fields.push({ Key: "if_ctc1email1", Value: this.insertContactPrincipalProjectForm.value.email })
      }
  
      if (this.checkIfModifySample("if_ctc1mobilephone", this.insertContactPrincipalProjectForm.value.mobilePhone)) {
        Fields.push({ Key: "if_ctc1mobilephone", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.mobilePhone) })
      }

      if (this.checkIfModifySample("if_ctc1personnalphone", this.insertContactPrincipalProjectForm.value.phone)) {
        Fields.push({ Key: "if_ctc1personnalphone", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.phone) })
      }
      if (this.checkIfModifySample("if_ctc1_postalcode", this.insertContactPrincipalProjectForm.value.zipCode)) {
        Fields.push({ Key: "if_ctc1_postalcode", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.zipCode) })
      }
  
      if (this.insertContactPrincipalProjectForm.value.city !== null) {
        if (this.checkIfModifySample("if_ctc1cityid", this.insertContactPrincipalProjectForm.value.city.id)) {
          Fields.push({ Key: "if_ctc1cityid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.city.id) })
        }
      }
      // else if (this.insertContactPrincipalProjectForm.value.cityNotPicking !== null) {
      //   if (this.checkIfModifySample("if_ctc1cityid", this.insertContactPrincipalProjectForm.value.cityNotPicking.id)) {
      //     Fields.push({ Key: "if_ctc1cityid", Value : this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.cityNotPicking.id) })
      //   }
      // } 
      else {
        if (this.insertContactPrincipalProjectForm.value.cityNotPicking === '' || this.insertContactPrincipalProjectForm.value.cityNotPicking === "") {
          if (this.checkIfModifySelect("if_ctc1cityid", this.insertContactPrincipalProjectForm.value.city)) {
            Fields.push({ Key: "if_ctc1cityid", Value: "" })
          }
          if (this.contactPrincipalCityNotId !== null) {
            Fields.push({ Key: "if_ctc1city", Value: "" })
          }
        }
        if (this.insertContactPrincipalProjectForm.value.cityNotPicking !== '') {
          Fields.push({ Key: "if_ctc1cityid", Value: "" })
          Fields.push({ Key: "if_ctc1city", Value: this.insertContactPrincipalProjectForm.value.cityNotPicking })
        }
      }

      if (this.insertContactPrincipalProjectForm.value.civility !== null && this.insertContactPrincipalProjectForm.value.civility !== undefined) {
        if (this.checkIfModifySample("if_ctc1salutationid", this.insertContactPrincipalProjectForm.value.civility.id)) {
          Fields.push({ Key: "if_ctc1salutationid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.civility.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc1salutationid", this.insertContactPrincipalProjectForm.value.civility)) {
          Fields.push({ Key: "if_ctc1salutationid", Value: "" })
        }
      }
      
      if (this.checkIfModifySample("if_ctc1_maidenname", this.insertContactPrincipalProjectForm.value.youngLastname)) {
        Fields.push({ Key: "if_ctc1_maidenname", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.youngLastname) })
      }

      if (this.checkIfModifySample("if_ctc1birthday", this.insertContactPrincipalProjectForm.value.birthday)) {
        let date = this.insertContactPrincipalProjectForm.value.birthday
        Fields.push({ Key: "if_ctc1birthday", Value: this.isNullOrEmptySample(new Date(date.setHours(date.getHours() + 3))) })
      }

      if (this.checkIfModifySample("if_ctc1_birthplace", this.insertContactPrincipalProjectForm.value.cityBirthday)) {
        Fields.push({ Key: "if_ctc1_birthplace", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.cityBirthday) })
      }

      if (this.insertContactPrincipalProjectForm.value.countryBirthday !== null && this.insertContactPrincipalProjectForm.value.countryBirthday !== undefined) {
        if (this.checkIfModifySample("if_ctc1_birthcountryid", this.insertContactPrincipalProjectForm.value.countryBirthday.id)) {
          Fields.push({ Key: "if_ctc1_birthcountryid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.countryBirthday.id) })
        }
      }

      if (this.checkIfModifySample("if_ctc1_nationality", this.insertContactPrincipalProjectForm.value.nationality)) {
        Fields.push({ Key: "if_ctc1_nationality", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.nationality) })
      }

      if (this.insertContactPrincipalProjectForm.value.countryResidence !== null && this.insertContactPrincipalProjectForm.value.countryResidence !== undefined) {
        if (this.checkIfModifySample("if_ctc1_residencecountryid", this.insertContactPrincipalProjectForm.value.countryResidence.id)) {
          Fields.push({ Key: "if_ctc1_residencecountryid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.countryResidence.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc1_residencecountryid", this.insertContactPrincipalProjectForm.value.countryResidence)) {
          Fields.push({ Key: "if_ctc1_residencecountryid", Value: "" })
        }
      }

      if (this.checkIfModifySample("if_ctc1_residencepermitnumber", this.insertContactPrincipalProjectForm.value.numCardDay)) {
        Fields.push({ Key: "if_ctc1_residencepermitnumber", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.numCardDay) })
      }

      if (this.insertContactPrincipalProjectForm.value.familySituation !== null && this.insertContactPrincipalProjectForm.value.familySituation !== undefined) {
        if (this.checkIfModifySample("if_ctc1_familysituationid", this.insertContactPrincipalProjectForm.value.familySituation.id)) {
          Fields.push({ Key: "if_ctc1_familysituationid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.familySituation.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc1_familysituationid", this.insertContactPrincipalProjectForm.value.familySituation)) {
          Fields.push({ Key: "if_ctc1_familysituationid", Value: "" })
        }
      }

      if (this.checkIfModifySample("if_ctc1_familysituation_date", this.insertContactPrincipalProjectForm.value.familySituationDate)) {
        let date = this.insertContactPrincipalProjectForm.value.familySituationDate
        Fields.push({ Key: "if_ctc1_familysituation_date", Value: this.isNullOrEmptySample(new Date(date.setHours(date.getHours() + 3))) })
      }

      if (this.checkIfModifySample("if_ctc1email2", this.insertContactPrincipalProjectForm.value.emailPro)) {
        Fields.push({ Key: "if_ctc1email2", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.emailPro) })
      }

      if (this.checkIfModifySample("if_ctc1_streetnumber", this.insertContactPrincipalProjectForm.value.numAddress)) {
        Fields.push({ Key: "if_ctc1_streetnumber", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.numAddress) })
      }

      if (this.checkIfModifySample("if_ctc1_residencepermitperioddesc", this.insertContactPrincipalProjectForm.value.periodicityValidity)) {
        Fields.push({ Key: "if_ctc1_residencepermitperioddesc", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.periodicityValidity) })
      }

      if (this.checkIfModifySample("if_ctc1_familysituation_place", this.insertContactPrincipalProjectForm.value.familySituationLocation)) {
        Fields.push({ Key: "if_ctc1_familysituation_place", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.familySituationLocation) })
      }

      if (this.checkIfModifySample("if_ctc1_familysituation_notarydesc", this.insertContactPrincipalProjectForm.value.familySituationNotary)) {
        Fields.push({ Key: "if_ctc1_familysituation_notarydesc", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.familySituationNotary) })
      }
      if (this.insertContactPrincipalProjectForm.value.category !== null && this.insertContactPrincipalProjectForm.value.category !== undefined) {
        if (this.checkIfModifySample("if_ctc1_cspid", this.insertContactPrincipalProjectForm.value.category.id)) {
          Fields.push({ Key: "if_ctc1_cspid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.category.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc1_cspid", this.insertContactPrincipalProjectForm.value.category)) {
          Fields.push({ Key: "if_ctc1_cspid", Value: "" })
        }
      }
      if (this.insertContactPrincipalProjectForm.value.matrimonialRegime !== null && this.insertContactPrincipalProjectForm.value.matrimonialRegime !== undefined) {
        if (this.checkIfModifySample("if_ctc1_matrimonialregimeid", this.insertContactPrincipalProjectForm.value.matrimonialRegime.id)) {
          Fields.push({ Key: "if_ctc1_matrimonialregimeid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.matrimonialRegime.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc1_matrimonialregimeid", this.insertContactPrincipalProjectForm.value.matrimonialRegime)) {
          Fields.push({ Key: "if_ctc1_matrimonialregimeid", Value: "" })
        }
      }

      if (this.checkIfModifySample("if_ctc1professionalphone", this.insertContactPrincipalProjectForm.value.phonePro)) {
        Fields.push({ Key: "if_ctc1professionalphone", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.phonePro) })
      }

      if (this.checkIfModifySample("if_ctc1_streettype", this.insertContactPrincipalProjectForm.value.typeVoie)) {
        Fields.push({ Key: "if_ctc1_streettype", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.typeVoie) })
      }
      if (this.checkIfModifySample("if_ctc1_streetname", this.insertContactPrincipalProjectForm.value.nameVoie)) {
        Fields.push({ Key: "if_ctc1_streetname", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.nameVoie) })
      }
      if (this.checkIfModifySample("if_ctc1_street1", this.insertContactPrincipalProjectForm.value.addressOne)) {
        Fields.push({ Key: "if_ctc1_street1", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.addressOne) })
      }
      if (this.checkIfModifySample("if_ctc1_street2", this.insertContactPrincipalProjectForm.value.addressTwo)) {
        Fields.push({ Key: "if_ctc1_street2", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.addressTwo) })
      }
      if (this.checkIfModifySample("if_ctc1_jobtitle", this.insertContactPrincipalProjectForm.value.profession)) {
        Fields.push({ Key: "if_ctc1_jobtitle", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.profession) })
      }
      if (this.insertContactPrincipalProjectForm.value.contractWork !== null && this.insertContactPrincipalProjectForm.value.contractWork !== undefined) {
        if (this.checkIfModifySample("if_ctc1_employmentcontractid", this.insertContactPrincipalProjectForm.value.contractWork.id)) {
          Fields.push({ Key: "if_ctc1_employmentcontractid", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.contractWork.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc1_employmentcontractid", this.insertContactPrincipalProjectForm.value.contractWork)) {
          Fields.push({ Key: "if_ctc1_employmentcontractid", Value: "" })
        }
      }
      if (this.checkIfModifySample("if_ctc1_professionalsituationsincedate", this.insertContactPrincipalProjectForm.value.seniority)) {
        let date = this.insertContactPrincipalProjectForm.value.seniority
        Fields.push({ Key: "if_ctc1_professionalsituationsincedate", Value: this.isNullOrEmptySample(new Date(date.setHours(date.getHours() + 3))) })
      }
      if (this.checkIfModifySample("if_ctc1_currentemployer", this.insertContactPrincipalProjectForm.value.employerActual)) {
        Fields.push({ Key: "if_ctc1_currentemployer", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.employerActual) })
      }
      if (this.checkIfModifySampleCustom("if_ctc1_currentemployeraddress", this.insertContactPrincipalProjectForm.value.employerAddress)) {
        Fields.push({ Key: "if_ctc1_currentemployeraddress", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.employerAddress) })
      }

      if (this.subModuleUse === 'submodule_Quintesens') {
        if (this.checkIfModifySample("qui_ctc1_salaryamount", this.insertContactPrincipalProjectForm.value.rentMonth)) {
          Fields.push({ Key: "qui_ctc1_salaryamount", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.rentMonth) })
        }
        if (this.insertContactPrincipalProjectForm.value.typeRent !== null && this.insertContactPrincipalProjectForm.value.typeRent !== undefined) {
          if (this.checkIfModifySample("qui_ctc1_annualincometype", this.insertContactPrincipalProjectForm.value.typeRent.id)) {
            Fields.push({ Key: "qui_ctc1_annualincometype", Value: this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.typeRent.id) })
          }
        } else {
          if (this.checkIfModifySelect("qui_ctc1_annualincometype", this.insertContactPrincipalProjectForm.value.typeRent)) {
            Fields.push({ Key: "qui_ctc1_annualincometype", Value: "" })
          }
        }
      }





    }

    if (activation_ctc2) {
      if (this.checkIfModifySample("if_ctc2mobilephone", this.insertContactSecondProjectForm.value.mobilePhone)) {
        Fields.push({ Key: "if_ctc2mobilephone", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.mobilePhone) })
      }
      if (this.checkIfModifySample("if_ctc2personnalphone", this.insertContactSecondProjectForm.value.phone)) {
        Fields.push({ Key: "if_ctc2personnalphone", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.phone) })
      }
      
      if (this.checkIfModifySample("if_ctc2_postalcode", this.insertContactSecondProjectForm.value.zipCode)) {
        Fields.push({ Key: "if_ctc2_postalcode", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.zipCode) })
      }
      if (this.insertContactSecondProjectForm.value.city !== null) {
        if (this.checkIfModifySample("if_ctc2cityid", this.insertContactSecondProjectForm.value.city.id)) {
          Fields.push({ Key: "if_ctc2cityid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.city.id) })
        }
      } else {
        if (this.insertContactSecondProjectForm.value.cityNotPicking === '' || this.insertContactSecondProjectForm.value.cityNotPicking === "") {
          if (this.checkIfModifySelect("if_ctc2cityid", this.insertContactSecondProjectForm.value.city)) {
            Fields.push({ Key: "if_ctc2cityid", Value: "" })
          }
          if (this.contactSecondCityNotId !== null) {
            Fields.push({ Key: "if_ctc2_city", Value: "" })
          }
        }
        if (this.insertContactSecondProjectForm.value.cityNotPicking !== '' || this.insertContactSecondProjectForm.value.cityNotPicking !== "") {
          Fields.push({ Key: "if_ctc2cityid", Value: "" })
          Fields.push({ Key: "if_ctc2_city", Value: this.insertContactSecondProjectForm.value.cityNotPicking })
        }
      }
      
      if (this.insertContactSecondProjectForm.value.civility !== null && this.insertContactSecondProjectForm.value.civility !== undefined) {
        if (this.checkIfModifySample("if_ctc2salutationid", this.insertContactSecondProjectForm.value.civility.id)) {
          Fields.push({ Key: "if_ctc2salutationid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.civility.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc2salutationid", this.insertContactSecondProjectForm.value.civility)) {
          Fields.push({ Key: "if_ctc2salutationid", Value: "" })
        }
      }

      if (this.checkIfModifySample("if_ctc2_maidenname", this.insertContactSecondProjectForm.value.youngLastname)) {
        Fields.push({ Key: "if_ctc2_maidenname", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.youngLastname) })
      }

      if (this.checkIfModifySample("if_ctc2birthday", this.insertContactSecondProjectForm.value.birthday)) {
        let date = this.insertContactSecondProjectForm.value.birthday
        Fields.push({ Key: "if_ctc2birthday", Value: this.isNullOrEmptySample(new Date(date.setHours(date.getHours() + 3))) })
      }
  
      if (this.checkIfModifySample("if_ctc2_birthplace", this.insertContactSecondProjectForm.value.cityBirthday)) {
        Fields.push({ Key: "if_ctc2_birthplace", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.cityBirthday) })
      }
      if (this.insertContactSecondProjectForm.value.countryBirthday !== null && this.insertContactSecondProjectForm.value.countryBirthday !== undefined) {
        if (this.checkIfModifySample("if_ctc2_birthcountryid", this.insertContactSecondProjectForm.value.countryBirthday.id)) {
          Fields.push({ Key: "if_ctc2_birthcountryid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.countryBirthday.id) })
        }
      }
      if (this.checkIfModifySample("if_ctc2_nationality", this.insertContactSecondProjectForm.value.nationality)) {
        Fields.push({ Key: "if_ctc2_nationality", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.nationality) })
      }
      if (this.insertContactSecondProjectForm.value.countryResidence !== null && this.insertContactSecondProjectForm.value.countryResidence !== undefined) {
        if (this.checkIfModifySample("if_ctc2_residencecountryid", this.insertContactSecondProjectForm.value.countryResidence.id)) {
          Fields.push({ Key: "if_ctc2_residencecountryid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.countryResidence.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc2_residencecountryid", this.insertContactSecondProjectForm.value.countryResidence)) {
          Fields.push({ Key: "if_ctc2_residencecountryid", Value: "" })
        }
      }
      if (this.checkIfModifySample("if_ctc2_residencepermitnumber", this.insertContactSecondProjectForm.value.numCardDay)) {
        Fields.push({ Key: "if_ctc2_residencepermitnumber", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.numCardDay) })
      }

      if (this.checkIfModifySample("if_ctc2_residencepermitperioddesc", this.insertContactSecondProjectForm.value.periodicityValidity)) {
        Fields.push({ Key: "if_ctc2_residencepermitperioddesc", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.periodicityValidity) })
      }
  
      if (this.data.dataPopup.event2['if_cobooking'] !== null) {
        let cobooking = this.data.dataPopup.event2['if_cobooking'];
        if (this.insertContactSecondProjectForm.value.toggleCoBooking !== cobooking) {
          Fields.push({ Key: "if_cobooking", Value: this.insertContactSecondProjectForm.value.toggleCoBooking ? 1 : 0 })
        }
  
      } else {
        Fields.push({ Key: "if_cobooking", Value: this.insertContactSecondProjectForm.value.toggleCoBooking ? 1 : 0 })
      }
      if (this.insertContactSecondProjectForm.value.familySituation !== null && this.insertContactSecondProjectForm.value.familySituation !== undefined) {
        if (this.checkIfModifySample("if_ctc2_familysituationid", this.insertContactSecondProjectForm.value.familySituation.id)) {
          Fields.push({ Key: "if_ctc2_familysituationid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.familySituation.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc2_familysituationid", this.insertContactSecondProjectForm.value.familySituation)) {
          Fields.push({ Key: "if_ctc2_familysituationid", Value: "" })
        }
      }
      if (this.checkIfModifySample("if_ctc2_familysituation_date", this.insertContactSecondProjectForm.value.familySituationDate)) {
        let date = this.insertContactSecondProjectForm.value.familySituationDate;
        Fields.push({ Key: "if_ctc2_familysituation_date", Value: this.isNullOrEmptySample(new Date(date.setHours(date.getHours() + 3))) })
      }
      if (this.checkIfModifySample("if_ctc2_familysituation_place", this.insertContactSecondProjectForm.value.familySituationLocation)) {
        Fields.push({ Key: "if_ctc2_familysituation_place", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.familySituationLocation) })
      }
  
      if (this.checkIfModifySample("if_ctc2_familysituation_notarydesc", this.insertContactSecondProjectForm.value.familySituationNotary)) {
        Fields.push({ Key: "if_ctc2_familysituation_notarydesc", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.familySituationNotary) })
      }
  
      if (this.insertContactSecondProjectForm.value.matrimonialRegime !== null && this.insertContactSecondProjectForm.value.matrimonialRegime !== undefined) {
        if (this.checkIfModifySample("if_ctc2_matrimonialregimeid", this.insertContactSecondProjectForm.value.matrimonialRegime.id)) {
          Fields.push({ Key: "if_ctc2_matrimonialregimeid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.matrimonialRegime.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc2_matrimonialregimeid", this.insertContactSecondProjectForm.value.matrimonialRegime)) {
          Fields.push({ Key: "if_ctc2_matrimonialregimeid", Value: "" })
        }
      }

      if (this.checkIfModifySample("if_ctc2professionalphone", this.insertContactSecondProjectForm.value.phonePro)) {
        Fields.push({ Key: "if_ctc2professionalphone", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.phonePro) })
      }
  
  
      if (this.checkIfModifySample("if_ctc2firstname", this.insertContactSecondProjectForm.value.firstname)) {
        Fields.push({ Key: "if_ctc2firstname", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.firstname) })
      }
      if (this.checkIfModifySample("if_ctc2lastname", this.insertContactSecondProjectForm.value.lastname)) {
        Fields.push({ Key: "if_ctc2lastname", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.lastname) })
      }
      if (this.checkIfModifySample("if_ctc2email1", this.insertContactSecondProjectForm.value.email)) {
        Fields.push({ Key: "if_ctc2email1", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.email) })
      }

      if (this.checkIfModifySample("if_ctc2email2", this.insertContactSecondProjectForm.value.emailPro)) {
        Fields.push({ Key: "if_ctc2email2", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.emailPro) })
      }
  
      if (this.checkIfModifySample("if_ctc2_streetnumber", this.insertContactSecondProjectForm.value.numAddress)) {
        Fields.push({ Key: "if_ctc2_streetnumber", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.numAddress) })
      }

      if (this.checkIfModifySample("if_ctc2_streettype", this.insertContactSecondProjectForm.value.typeVoie)) {
        Fields.push({ Key: "if_ctc2_streettype", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.typeVoie) })
      }
      if (this.checkIfModifySample("if_ctc2_streetname", this.insertContactSecondProjectForm.value.nameVoie)) {
        Fields.push({ Key: "if_ctc2_streetname", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.nameVoie) })
      }
      if (this.checkIfModifySample("if_ctc2_street1", this.insertContactSecondProjectForm.value.addressOne)) {
        Fields.push({ Key: "if_ctc2_street1", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.addressOne) })
      }
      if (this.checkIfModifySample("if_ctc2_street2", this.insertContactSecondProjectForm.value.addressTwo)) {
        Fields.push({ Key: "if_ctc2_street2", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.addressTwo) })
      }
      if (this.insertContactSecondProjectForm.value.category !== null && this.insertContactSecondProjectForm.value.category !== undefined) {
        if (this.checkIfModifySample("if_ctc2_cspid", this.insertContactSecondProjectForm.value.category.id)) {
          Fields.push({ Key: "if_ctc2_cspid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.category.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc2_cspid", this.insertContactSecondProjectForm.value.category)) {
          Fields.push({ Key: "if_ctc2_cspid", Value: "" })
        }
      }
      if (this.checkIfModifySample("if_ctc2_jobtitle", this.insertContactSecondProjectForm.value.profession)) {
        Fields.push({ Key: "if_ctc2_jobtitle", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.profession) })
      }
      if (this.insertContactSecondProjectForm.value.contractWork !== null && this.insertContactSecondProjectForm.value.contractWork !== undefined) {
        if (this.checkIfModifySample("if_ctc2_employmentcontractid", this.insertContactSecondProjectForm.value.contractWork.id)) {
          Fields.push({ Key: "if_ctc2_employmentcontractid", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.contractWork.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_ctc2_employmentcontractid", this.insertContactSecondProjectForm.value.contractWork)) {
          Fields.push({ Key: "if_ctc2_employmentcontractid", Value: "" })
        }
      }
      if (this.checkIfModifySample("if_ctc2_professionalsituationsincedate", this.insertContactSecondProjectForm.value.seniority)) {
        let date = this.insertContactSecondProjectForm.value.seniority
        Fields.push({ Key: "if_ctc2_professionalsituationsincedate", Value: this.isNullOrEmptySample(new Date(date.setHours(date.getHours() + 3))) })
      }
      if (this.checkIfModifySample("if_ctc2_currentemployer", this.insertContactSecondProjectForm.value.employerActual)) {
        Fields.push({ Key: "if_ctc2_currentemployer", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.employerActual) })
      }
      if (this.checkIfModifySampleCustom("if_ctc2_currentemployeraddress", this.insertContactSecondProjectForm.value.employerAddress)) {
        Fields.push({ Key: "if_ctc2_currentemployeraddress", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.employerAddress) })
      }

      if (this.subModuleUse === 'submodule_Quintesens') {
        if (this.checkIfModifySample("qui_ctc2_salaryamount", this.insertContactSecondProjectForm.value.rentMonth)) {
          Fields.push({ Key: "qui_ctc2_salaryamount", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.rentMonth) })
        }
        if (this.insertContactSecondProjectForm.value.typeRent !== null && this.insertContactSecondProjectForm.value.typeRent !== undefined) {
          if (this.checkIfModifySample("qui_ctc2_annualincometype", this.insertContactSecondProjectForm.value.typeRent.id)) {
            Fields.push({ Key: "qui_ctc2_annualincometype", Value: this.isNullOrEmptySample(this.insertContactSecondProjectForm.value.typeRent.id) })
          }
        } else {
          if (this.checkIfModifySelect("qui_ctc2_annualincometype", this.insertContactSecondProjectForm.value.typeRent)) {
            Fields.push({ Key: "qui_ctc2_annualincometype", Value: "" })
          }
        }
  
      }
    } else {
      Fields.push({ Key: "if_trigger_cleanctc2", Value: new Date() });
    }

    // change en annuelle
    // if (this.checkIfModifySample("", this.insertContactPrincipalProjectForm.value.rentMonth)) {
    //   (Not Use for moment) Fields.push({ Key: "", Value : this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.rentMonth) })
    // }

    // if (this.checkIfModifySample("", this.insertContactPrincipalProjectForm.value.typeRent)) {
    //   (Not Use for moment) Fields.push({ Key: "", Value : this.isNullOrEmptySample(this.insertContactPrincipalProjectForm.value.typeRent) })
    // }


    //--------------------------------------------------------   FOYER FISCAL   ------------------------------------------------------------------------------------------------------------------


    if (!this.isFieldReadOnlyAccess && activation_FFiscal) { // Check si l'Erip est initialiser lors de 

      if (this.checkIfModifySample("if_taxpartsnumber", this.insertFoyerFiscalProjectForm.value.nbPartFiscal)) {
        Fields.push({ Key: "if_taxpartsnumber", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.nbPartFiscal) })
      }

      if (this.checkIfModifySample("if_dependentpeoplenumber", this.insertFoyerFiscalProjectForm.value.nbPersCharge)) {
        Fields.push({ Key: "if_dependentpeoplenumber", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.nbPersCharge) })
      }

      if (this.checkIfModifySample("if_childrencount", this.insertFoyerFiscalProjectForm.value.nbChildren)) {
        Fields.push({ Key: "if_childrencount", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.nbChildren) })
      }

      if (this.checkIfModifySample("if_dependentchildsnumber", this.insertFoyerFiscalProjectForm.value.nbChildrenCharge)) {
        Fields.push({ Key: "if_dependentchildsnumber", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.nbChildrenCharge) })
      }

      if (this.checkIfModifySample("if_childrenages", this.insertFoyerFiscalProjectForm.value.yearsChildren)) {
        Fields.push({ Key: "if_childrenages", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.yearsChildren) })
      }

      if (this.checkIfModifySample("if_childrensnames", this.insertFoyerFiscalProjectForm.value.nameChildren)) {
        Fields.push({ Key: "if_childrensnames", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.nameChildren) })
      }

      if (this.checkIfModifySample("if_incometax_grossamount", this.insertFoyerFiscalProjectForm.value.grossTaxAmount)) {
        Fields.push({ Key: "if_incometax_grossamount", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.grossTaxAmount) })
      }

      if (this.checkIfModifySample("if_incometax_payableamount", this.insertFoyerFiscalProjectForm.value.paidTaxAmount)) {
        Fields.push({ Key: "if_incometax_payableamount", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.paidTaxAmount) })
      }

      if (this.checkIfModifySample("if_incometaxref_n", this.insertFoyerFiscalProjectForm.value.taxIncomeRefN)) {
        Fields.push({ Key: "if_incometaxref_n", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.taxIncomeRefN) })
      }

      if (this.checkIfModifySample("if_incometaxref_n1", this.insertFoyerFiscalProjectForm.value.taxIncomeRefN1)) {
        Fields.push({ Key: "if_incometaxref_n1", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.taxIncomeRefN1) })
      }

      if (this.checkIfModifySample("if_incometaxref_n2", this.insertFoyerFiscalProjectForm.value.taxIncomeRefN2)) {
        Fields.push({ Key: "if_incometaxref_n2", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.taxIncomeRefN2) })
      }

      // if (this.checkIfModifySample("if_constitutedthrift", this.insertFoyerFiscalProjectForm.value.savingConstituted)) {
      //   Fields.push({ Key: "if_constitutedthrift", Value : this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.savingConstituted) })
      // }

      if (this.checkIfModifySample("if_investment_savingscapacitymonthlymin", this.insertFoyerFiscalProjectForm.value.minSavingEffort)) {
        Fields.push({ Key: "if_investment_savingscapacitymonthlymin", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.minSavingEffort) })
      }

      if (this.checkIfModifySample("if_investment_savingscapacitymonthlymax", this.insertFoyerFiscalProjectForm.value.maxSavingEffort)) {
        Fields.push({ Key: "if_investment_savingscapacitymonthlymax", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.maxSavingEffort) })
      }


      if (this.checkIfModifySample("if_wishprofitabilitymin", this.insertFoyerFiscalProjectForm.value.wishRentability)) {
        Fields.push({ Key: "if_wishprofitabilitymin", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.wishRentability) })
      }

      // if (this.insertFoyerFiscalProjectForm.value.acceptWarning !== null) {
      //   Fields.push({ Key: "Investment_risks", Value : this.insertFoyerFiscalProjectForm.value.acceptWarning })
      // }
      if (this.insertFoyerFiscalProjectForm.value.acceptWarning !== null && this.insertFoyerFiscalProjectForm.value.acceptWarning !== undefined) {
        if (this.checkIfModifySample("risk_if_fluxsiteb2blabel", this.insertFoyerFiscalProjectForm.value.acceptWarning.name)) {
          Fields.push({ Key: "if_acceptedinvestmentriskid", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.acceptWarning.id) })
        }
      } else {
        if (this.checkIfModifySelect("risk_if_fluxsiteb2blabel", this.insertFoyerFiscalProjectForm.value.acceptWarning)) {
          Fields.push({ Key: "if_acceptedinvestmentriskid", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.tmi !== null && this.insertFoyerFiscalProjectForm.value.tmi !== undefined) {
        if (this.checkIfModifySample("if_tmiid", this.insertFoyerFiscalProjectForm.value.tmi.id)) {
          Fields.push({ Key: "if_tmiid", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.tmi.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_tmiid", this.insertFoyerFiscalProjectForm.value.tmi)) {
          Fields.push({ Key: "if_tmiid", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.goalInvestment1 !== null && this.insertFoyerFiscalProjectForm.value.goalInvestment1 !== undefined) {
        if (this.checkIfModifySample("if_investmentobjective1id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment1.name)) {
          Fields.push({ Key: "if_investmentobjective1id", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.goalInvestment1.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_investmentobjective1id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment1)) {
          Fields.push({ Key: "if_investmentobjective1id", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.goalInvestment2 !== null && this.insertFoyerFiscalProjectForm.value.goalInvestment2 !== undefined) {
        if (this.checkIfModifySample("if_investmentobjective2id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment2.name)) {
          Fields.push({ Key: "if_investmentobjective2id", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.goalInvestment2.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_investmentobjective2id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment2)) {
          Fields.push({ Key: "if_investmentobjective2id", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.goalInvestment3 !== null && this.insertFoyerFiscalProjectForm.value.goalInvestment3 !== undefined) {
        if (this.checkIfModifySample("if_investmentobjective3id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment3.name)) {
          Fields.push({ Key: "if_investmentobjective3id", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.goalInvestment3.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_investmentobjective3id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment3)) {
          Fields.push({ Key: "if_investmentobjective3id", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.goalInvestment4 !== null && this.insertFoyerFiscalProjectForm.value.goalInvestment4 !== undefined) {
        if (this.checkIfModifySample("if_investmentobjective4id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment4.name)) {
          Fields.push({ Key: "if_investmentobjective4id", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.goalInvestment4.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_investmentobjective4id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment4)) {
          Fields.push({ Key: "if_investmentobjective4id", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.goalInvestment5 !== null && this.insertFoyerFiscalProjectForm.value.goalInvestment5 !== undefined) {
        if (this.checkIfModifySample("if_investmentobjective5id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment5.name)) {
          Fields.push({ Key: "if_investmentobjective5id", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.goalInvestment5.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_investmentobjective5id_label", this.insertFoyerFiscalProjectForm.value.goalInvestment5)) {
          Fields.push({ Key: "if_investmentobjective5id", Value: "" })
        }
      }

      if (this.insertFoyerFiscalProjectForm.value.disponibilityMin != null && this.insertFoyerFiscalProjectForm.value.disponibilityMin != undefined) {
        if (this.checkIfModifySample("if_investment_availabilityamountmin", this.insertFoyerFiscalProjectForm.value.disponibilityMin !== "" ? parseInt(this.insertFoyerFiscalProjectForm.value.disponibilityMin.replace(' ', '')) : this.insertFoyerFiscalProjectForm.value.disponibilityMin)) {
          Fields.push({ Key: "if_investment_availabilityamountmin", Value: parseInt(this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.disponibilityMin)) })
        }
      }
      if (this.insertFoyerFiscalProjectForm.value.disponibilityMax != null && this.insertFoyerFiscalProjectForm.value.disponibilityMax != undefined) {
        if (this.checkIfModifySample("if_investment_availabilityamountmax", this.insertFoyerFiscalProjectForm.value.disponibilityMax !== "" ? parseInt(this.insertFoyerFiscalProjectForm.value.disponibilityMax.replace(' ', '')) : this.insertFoyerFiscalProjectForm.value.disponibilityMax)) {
          Fields.push({ Key: "if_investment_availabilityamountmax", Value: parseInt(this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.disponibilityMax)) })
        }
      }

      if (this.checkIfModifySample("if_investment_availabilityorigin", this.insertFoyerFiscalProjectForm.value.originDisponibility)) {
        Fields.push({ Key: "if_investment_availabilityorigin", Value: this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.originDisponibility) })
      }

      // if (this.insertFoyerFiscalProjectForm.value.profil !== null) {
      //   if (this.checkIfModifySample("if_investorprofileid_label", this.insertFoyerFiscalProjectForm.value.profil.name)) {
      //     Fields.push({ Key: "if_investorprofileid", Value : this.isNullOrEmptySample(this.insertFoyerFiscalProjectForm.value.profil.id) })
      //   }
      // } else {
      //   if (this.checkIfModifySelect("if_investorprofileid_label", this.insertFoyerFiscalProjectForm.value.profil)) {
      //     Fields.push({ Key: "if_investorprofileid", Value : "" })
      //   }
      // }
    }


    //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    if (activation_Project) {
      if (this.immoProjectOrigin !== null && this.immoProjectOrigin !== undefined) {
        if (this.checkIfModifySample("origin.if_marketingoriginid", this.immoProjectOrigin.id)) {
          Fields.push({ Key: "if_mkg_originid", Value: this.isNullOrEmptySample(this.immoProjectOrigin.id) })
        }
      } else {
        if (this.checkIfModifySelect("origin.if_marketingoriginid", this.immoProjectOrigin)) {
          Fields.push({ Key: "if_mkg_originid", Value: "" })
        }
      }
  
      if (this.insertImmoProjectForm.value.canal !== null && this.insertImmoProjectForm.value.canal !== undefined) {
        if (this.checkIfModifySample("if_mkg_channelid_label", this.insertImmoProjectForm.value.canal.name)) {
          Fields.push({ Key: "if_mkg_channelid", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.canal.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_mkg_channelid_label", this.insertImmoProjectForm.value.canal)) {
          Fields.push({ Key: "if_mkg_channelid", Value: "" })
        }
      }
  
      if (this.checkIfModifySample("if_requirementdescription", this.insertImmoProjectForm.value.needMore)) {
        Fields.push({ Key: "if_requirementdescription", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.needMore) })
      }
  
  
  
      if (this.checkIfModifySample("if_projectcomments", this.insertImmoProjectForm.value.comment)) {
        Fields.push({ Key: "if_projectcomments", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.comment) })
      }
  
      if (this.insertImmoProjectForm.value.situation !== null && this.insertImmoProjectForm.value.situation !== undefined) {
        if (this.checkIfModifySample("if_customerproperties_label", this.insertImmoProjectForm.value.situation.name)) {
          Fields.push({ Key: "if_customerproperties", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.situation.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_customerproperties_label", this.insertImmoProjectForm.value.situation)) {
          Fields.push({ Key: "if_customerproperties", Value: "" })
        }
      }
  
      if (this.insertImmoProjectForm.value.goal !== null && this.insertImmoProjectForm.value.goal !== undefined) {
        if (this.checkIfModifySample("if_mainsearchprofil_label", this.insertImmoProjectForm.value.goal.name)) {
          Fields.push({ Key: "if_mainsearchprofil", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.goal.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_mainsearchprofil_label", this.insertImmoProjectForm.value.goal)) {
          Fields.push({ Key: "if_mainsearchprofil", Value: "" })
        }
      }
  
      if (this.insertImmoProjectForm.value.investmentHorizon !== null && this.insertImmoProjectForm.value.investmentHorizon !== undefined) {
        if (this.checkIfModifySample("if_purchasetimeframe_label", this.insertImmoProjectForm.value.investmentHorizon.name)) {
          Fields.push({ Key: "if_purchasetimeframe", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.investmentHorizon.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_purchasetimeframe_label", this.insertImmoProjectForm.value.investmentHorizon)) {
          Fields.push({ Key: "if_purchasetimeframe", Value: "" })
        }
      }
  
      if (this.insertImmoProjectForm.value.taxSystem !== null && this.insertImmoProjectForm.value.taxSystem !== undefined) {
        if (this.checkIfModifySample("if_immo_taxexemptionid_label", this.insertImmoProjectForm.value.taxSystem.name)) {
          Fields.push({ Key: "if_immo_taxexemptionid", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.taxSystem.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_immo_taxexemptionid_label", this.insertImmoProjectForm.value.taxSystem)) {
          Fields.push({ Key: "if_immo_taxexemptionid", Value: "" })
        }
      }
  
      if (this.checkIfModifySelectMultiple("AssetTypes", this.insertImmoProjectForm.value.typology, "if_fluxsiteb2blabel", "name")) {
        if (this.checkIfModifySelectMultiple2("AssetTypes", this.insertImmoProjectForm.value.typology, "if_fluxsiteb2blabel", "name")) {
          AssetTypes = this.isNullOrEmptySelectMultiple(this.insertImmoProjectForm.value.typology, "id")
        } else {
          if (this.insertImmoProjectForm.value.typology !== null && this.insertImmoProjectForm.value.typology.length !== 0 && this.insertImmoProjectForm.value.typology !== undefined) {
            AssetTypes = this.isNullOrEmptySelectMultiple(this.insertImmoProjectForm.value.typology, "id")
          } else {
            AssetTypes = [];
          }
        }
      }
  
      if (this.insertImmoProjectForm.value.programCible !== null && this.insertImmoProjectForm.value.programCible !== undefined) {
        if (this.checkIfModifySample("if_mainoperationid_label", this.insertImmoProjectForm.value.programCible.name)) {
          Fields.push({ Key: "if_mainoperationid", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.programCible.id) })
        }
      } else {
        if (this.checkIfModifySelect("if_mainoperationid_label", this.insertImmoProjectForm.value.programCible)) {
          Fields.push({ Key: "if_mainoperationid", Value: "" })
        }
      }
  
      if (this.checkIfModifySample("if_immo_surfacemin", this.insertImmoProjectForm.value.surfaceMin)) {
        Fields.push({ Key: "if_immo_surfacemin", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.surfaceMin) })
      }
  
      if (this.checkIfModifySample("if_immo_surfacemax", this.insertImmoProjectForm.value.surfaceMax)) {
        Fields.push({ Key: "if_immo_surfacemax", Value: this.isNullOrEmptySample(this.insertImmoProjectForm.value.surfaceMax) })
      }
  
      if (this.checkIfModifySample("if_wishbudgetmin", this.insertImmoProjectForm.value.budgetMin !== "" ? parseInt(this.insertImmoProjectForm.value.budgetMin.replace(' ', '')) : this.insertImmoProjectForm.value.budgetMin)) {
        Fields.push({ Key: "if_wishbudgetmin", Value: parseInt(this.isNullOrEmptySample(this.insertImmoProjectForm.value.budgetMin)) })
      }
      if (this.checkIfModifySample("if_wishbudgetmax", this.insertImmoProjectForm.value.budgetMax !== "" ? parseInt(this.insertImmoProjectForm.value.budgetMax.replace(' ', '')) : this.insertImmoProjectForm.value.budgetMax)) {
        Fields.push({ Key: "if_wishbudgetmax", Value: parseInt(this.isNullOrEmptySample(this.insertImmoProjectForm.value.budgetMax.replace(' ', ''))) })
      }
  
      // if (this.checkIfModifySample("if_investment_savingscapacitymonthlymin", this.insertImmoProjectForm.value.epargneMin !== "" ? parseInt(this.insertImmoProjectForm.value.epargneMin.replace(' ', '')) : this.insertImmoProjectForm.value.epargneMin)) {
      //   Fields.push({ Key: "if_investment_savingscapacitymonthlymin", Value : parseInt(this.isNullOrEmptySample(this.insertImmoProjectForm.value.epargneMin)) })
      // }
      // if (this.checkIfModifySample("if_investment_savingscapacitymonthlymax", this.insertImmoProjectForm.value.epargneMax !== "" ? parseInt(this.insertImmoProjectForm.value.epargneMax.replace(' ', '')) : this.insertImmoProjectForm.value.epargneMax)) {
      //   Fields.push({ Key: "if_investment_savingscapacitymonthlymax", Value : parseInt(this.isNullOrEmptySample(this.insertImmoProjectForm.value.epargneMax)) })
      // }
  
      // Before if_category
      if (this.checkIfModifySelectMultiple("InvestmentTypes", this.insertImmoProjectForm.value.product, "if_investment_typeid", "name")) {
        if (this.checkIfModifySelectMultiple2("InvestmentTypes", this.insertImmoProjectForm.value.product, "if_investment_typeid", "name")) {
          InvestmentTypes = this.isNullOrEmptySelectMultiple(this.insertImmoProjectForm.value.product, "id")
        } else {
          if (this.insertImmoProjectForm.value.product !== null && this.insertImmoProjectForm.value.product.length !== 0 && this.insertImmoProjectForm.value.product !== undefined) {
            InvestmentTypes = this.isNullOrEmptySelectMultiple(this.insertImmoProjectForm.value.product, "id");
          } else {
            InvestmentTypes = [];
          }
        }
      }
    }



    dataProjectUpdate.AssetTypes = AssetTypes;
    dataProjectUpdate.InvestmentTypes = InvestmentTypes;
    dataProjectUpdate.Fields = Fields;

    return dataProjectUpdate
  }

  // Method for open popup with form for create new rdv with the project select in progress 
  openPopupCreateNewRdv(eventDataPopup: any) {
    let size = initSizeScreen()

    this.servicePopup.openPopupCreateNewRdv_CustomCardTableHousingMobile('editRdvWithInProgress', eventDataPopup, size, true)
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.servicePopup.openDialogLoading(namePopupLoading, this.data.size, "")
  }

  // Function go to pevious stepper
  goBack(stepper: MatStepper) {
    stepper.previous();
    this.checkStepperChange(stepper);
  }

  // Function go to next stepper
  goForward(stepper: MatStepper) {
    stepper.next();
    this.checkStepperChange(stepper);
  }

  // Method for text label on select and reset of select simple
  openSelectAndReset(selectName: string, insertFormValue: FormGroup, isOpenSelect: any) {
    this.checkSecondContact()
    if (insertFormValue.value[selectName] !== null) {
      isOpenSelect[selectName] = true;
    } else {
      isOpenSelect[selectName] = false;
    }
  }

  getDisabledValueOne() {
    if (this.insertContactPrincipalProjectForm.value.city !== null) {
      // this.insertContactPrincipalProjectForm.value.cityNotPicking.disable();
      // this.insertContactPrincipalProjectForm.patchValue({
      //   cityNotPicking: this.contactPrincipalCityNotId
      // }) 
      return this.disableOneCityNotPicking = true;
    } else {
      if (this.disableOneCityNotPicking) {
        // this.insertContactPrincipalProjectForm.patchValue({
        //   cityNotPicking: ""
        // })
      }
      // this.insertContactPrincipalProjectForm.value.cityNotPicking.enable();
      return this.disableOneCityNotPicking = false;
    }
  }
  getDisabledValueTwo() {
    if (this.insertContactSecondProjectForm.value.city !== null) {
      // this.insertContactSecondProjectForm.value.cityNotPicking.disable();
      // this.insertContactSecondProjectForm.patchValue({
      //   cityNotPicking: this.contactSecondCityNotId
      // }) 
      return this.disableTwoCityNotPicking = true;
    } else {
      // this.insertContactSecondProjectForm.value.cityNotPicking.enable();
      if (this.disableTwoCityNotPicking) {
        // this.insertContactSecondProjectForm.patchValue({
        //   cityNotPicking: ""
        // })
      }
      return this.disableTwoCityNotPicking = false;
    }
  }

  resetDate(formUse: any, formControlName: string) {
    formUse.controls[formControlName].setValue("");
    this.checkSecondContact()
  }

  // Method for text label on select and reset of select multiple
  openSelectAndResetMultiple(selectName: string, insertFormValue: FormGroup, isOpenSelect: any) {
    this.checkSecondContact();
    if (insertFormValue.value[selectName] !== null) {
      if (insertFormValue.value[selectName].length !== 0) {
        isOpenSelect[selectName] = true;
      } else {
        isOpenSelect[selectName] = false;
      }
    } else {
      isOpenSelect[selectName] = false;
    }
  }

  // Custom research for ( city, country birthday, country residence and department but department is not use ) with string of 3 letter min
  customSearchFn(keyUp: any, term: any, nameSearch: any, isOpenSelect: any) {
    if (keyUp === "city" || keyUp === "countryBirthday" || keyUp === "countryResidence" || keyUp === "departmentResearch") {
      isOpenSelect[keyUp] = true;
      this.getDataApiwithSearchTerm(keyUp, term, true);
    } else {
      if (term.searchTerm !== null) {
        isOpenSelect[keyUp] = true;

        if (term.searchTerm.toLocaleLowerCase().length >= 3) {
          this.getDataApiwithSearchTerm(nameSearch, term.searchTerm, false);
        }
      } else {
        isOpenSelect[keyUp] = false;
      }
    }
  }

  // Get data geographic of the API with string of 3 letter min  
  getDataApiwithSearchTerm(choiceSearchFn: string, term: string, activeInit: boolean) {

    if (choiceSearchFn === "city") {
      this.serviceCity.getCities(1, 1000, term).pipe(first()).subscribe((cities: any) => {
        console.log(cities)
        this.listFieldCity = [];
        cities.datas.forEach((city: any, index: number) => {
          if (this.data.dataPopup.event2['if_ctc1cityid'] === city['city.if_cityid'] && activeInit) {
            this.contactPrincipalCity = { name: city['city.if_name'] + " (" + city['city.if_postalcode'] + ")", id: city['city.if_cityid'] };
          }
          if (this.data.dataPopup.event2['if_ctc2cityid'] === city['city.if_cityid'] && activeInit) {
            this.contactSecondCity = { name: city['city.if_name'] + " (" + city['city.if_postalcode'] + ")", id: city['city.if_cityid'] };
          }
          this.listFieldCity.push({ name: city['city.if_name'] + " (" + city['city.if_postalcode'] + ")", id: { name: city['city.if_name'] + " (" + city['city.if_postalcode'] + ")", id: city['city.if_cityid'] } });
        });
        setTimeout(() => {
          this.insertContactPrincipalProjectForm.patchValue({
            city: isNullFieldForm(this.contactPrincipalCity, false),
            cityNotPicking: isNullFieldForm(this.contactPrincipalCityNotId, true)
          })
          this.insertContactSecondProjectForm.patchValue({
            city: isNullFieldForm(this.contactSecondCity, false),
            cityNotPicking: isNullFieldForm(this.contactSecondCityNotId, true)
          })
        }, 100)
        return;
      })
    } else if (choiceSearchFn === "countryBirthday") {
      this.serviceCountry.getCountries(1, 1000, term).pipe(first()).subscribe((countries: any) => {
        console.log(countries)
        this.listFieldCountryBirthday = [];
        countries.datas.forEach((country: Country, index: number) => {
          if (country['country.if_countryid'] === this.data.dataPopup.event2['if_ctc1_birthcountryid'] && activeInit) {
            this.contactPrincipalCountryBirthday = { name: country['country.if_name'], id: country['country.if_countryid'] };
          }
          if (country['country.if_countryid'] === this.data.dataPopup.event2['if_ctc2_birthcountryid'] && activeInit) {
            this.contactSecondCountryBirthday = { name: country['country.if_name'], id: country['country.if_countryid'] };
          }
          this.listFieldCountryBirthday.push({ name: country['country.if_name'], id: { name: country['country.if_name'], id: country['country.if_countryid'] } });
        });
        setTimeout(() => {
          this.insertContactPrincipalProjectForm.patchValue({
            countryBirthday: isNullFieldForm(this.contactPrincipalCountryBirthday, false)
          })
          this.insertContactSecondProjectForm.patchValue({
            countryBirthday: isNullFieldForm(this.contactSecondCountryBirthday, false)
          })
        }, 100)
        return;
      })
    } else if (choiceSearchFn === "countryResidence") {
      this.serviceCountry.getCountries(1, 1000, term).pipe(first()).subscribe((countries: any) => {
        console.log(countries)
        this.listFieldCountryResidence = [];
        countries.datas.forEach((country: Country, index: number) => {
          if (country['country.if_countryid'] === this.data.dataPopup.event2['if_ctc1_residencecountryid'] && activeInit) {
            this.contactPrincipalCountryResidence = { name: country['country.if_name'], id: country['country.if_countryid'] };
          }
          if (country['country.if_countryid'] === this.data.dataPopup.event2['if_ctc2_residencecountryid'] && activeInit) {
            this.contactSecondCountryResidence = { name: country['country.if_name'], id: country['country.if_countryid'] };
          }
          this.listFieldCountryResidence.push({ name: country['country.if_name'], id: { name: country['country.if_name'], id: country['country.if_countryid'] } });
        });
        setTimeout(() => {
          this.insertContactPrincipalProjectForm.patchValue({
            countryResidence: isNullFieldForm(this.contactPrincipalCountryResidence, false)
          })
          this.insertContactSecondProjectForm.patchValue({
            countryResidence: isNullFieldForm(this.contactSecondCountryResidence, false)
          })
        }, 100)
        return;
      })
    } else if (choiceSearchFn === "departmentResearch") {
      this.serviceDepartments.getDepartments(1, 1000, term).pipe(first()).subscribe((departments: any) => {
        console.log(departments)
        this.listFieldDepartmentResearch = [];
        departments.datas.forEach((department: DepartmentResearch, index: number) => {
          if (index === 0 && activeInit) {
            this.isOpenSelectOther.departmentResearch = true;
            this.immoProjectDepartmentResearch = { name: department['department.if_name'], id: department['department.if_departmentid'] };
          }
          this.listFieldDepartmentResearch.push({ name: department['department.if_name'], id: { name: department['department.if_name'], id: department['department.if_departmentid'] } });
        });
        setTimeout(() => {
          this.insertImmoProjectForm.patchValue({
            departmentResearch: this.immoProjectDepartmentResearch
          })
        }, 100)
        return;
      })
    }
  }

  // Observe if selection stepper change 
  selectionChange(stepper: MatStepper) {
    setTimeout(() => {
      console.log(stepper.selectedIndex);
      this.checkStepperChange(stepper);
    })
  }

  checkStepperChange(stepper: MatStepper) {
    if (stepper.selectedIndex === 1 || stepper.selectedIndex === 2) {
      this.hiddenArrowForward = true;
      this.hiddenArrowBack = true;
      if (stepper.selectedIndex === 1) {
        this.insertContactSecondProjectForm.patchValue({
          checkedCoordination: false,
          checkedFamilyStitutation: false
        });
      } 
    } else {
      if (stepper.selectedIndex === 0) {
        this.hiddenArrowForward = true;
        this.hiddenArrowBack = false;
      } else {
        this.hiddenArrowForward = false;
        this.hiddenArrowBack = true;
      }
    }
    this.selectedStepIndex = stepper.selectedIndex;
  }

  eventChangeDuplicateData(nameSectionDuplicate: string): void {
    switch (nameSectionDuplicate) {
      case 'Coordination':
        this.insertContactSecondProjectForm.patchValue({
          city: isNullFieldForm(this.insertContactPrincipalProjectForm.value.city, false),
          countryResidence: isNullFieldForm(this.insertContactPrincipalProjectForm.value.countryResidence, false),

          // mobilePhone: this.insertContactPrincipalProjectForm.value.mobilePhone,
          phone: this.insertContactPrincipalProjectForm.value.phone,
          phonePro: this.insertContactPrincipalProjectForm.value.phonePro,
          // email: this.insertContactPrincipalProjectForm.value.email,
          emailPro: this.insertContactPrincipalProjectForm.value.emailPro,
          typeVoie: this.insertContactPrincipalProjectForm.value.typeVoie,
          nameVoie: this.insertContactPrincipalProjectForm.value.nameVoie,
          numAddress: this.insertContactPrincipalProjectForm.value.numAddress,
          // addressOne: this.insertContactPrincipalProjectForm.value.addressOne,
          addressTwo: this.insertContactPrincipalProjectForm.value.addressTwo,
          zipCode: this.insertContactPrincipalProjectForm.value.zipCode,
          cityNotPicking: this.insertContactPrincipalProjectForm.value.cityNotPicking
        })
        break;

      case 'FamilyStitutation':
        this.insertContactSecondProjectForm.patchValue({
          familySituation: isNullFieldForm(this.insertContactPrincipalProjectForm.value.familySituation, false),
          
          familySituationDate: new Date(this.insertContactPrincipalProjectForm.value.familySituationDate),
          familySituationLocation: this.insertContactPrincipalProjectForm.value.familySituationLocation,
          familySituationNotary: this.insertContactPrincipalProjectForm.value.familySituationNotary,
          matrimonialRegime: this.insertContactPrincipalProjectForm.value.matrimonialRegime,
        })
        break;
      default:
        break;
    }
  }

  eventChangeRemoveDataContactSecond() {
    let objectReceivePopup = {
      size: this.data.size,
      namePopup: "accpetedRuleProjectCTC2",
      title: "Contact secondaire",
      backgroundColorContent: this.serviceParameterVitrineVariable.accpetedRuleProjectCTC2.Popup.backgroundColorContent ? this.serviceParameterVitrineVariable.accpetedRuleProjectCTC2.Popup.backgroundColorContent : '#ffffff',
      colorContent: this.serviceParameterVitrineVariable.accpetedRuleProjectCTC2.Popup.colorContent ? this.serviceParameterVitrineVariable.accpetedRuleProjectCTC2.Popup.colorContent : '#475564',
      dataPopup: { contentValue: 'Voulez-vous retirer ce contact du projet ?' }
    }

    this.servicePopup.openPopupAcceptedRule(objectReceivePopup)
  }
  
  removeDataContactSecond() {
    this.openDialogLoading('detachedCTC2')
    let dataPutProject = this.getDataPostProject(true, false, true, true);
    if (dataPutProject.AssetTypes.length !== 0 || (dataPutProject.AssetTypes.length === 0 && this.immoProjectTypologyBeforeUpdate.length !== 0) || dataPutProject.InvestmentTypes.length !== 0 || (dataPutProject.InvestmentTypes.length === 0 && this.immoProjectProductCibleBeforeUpdate.length !== 0) || dataPutProject.Fields.length !== 0) {
      let newDataPutProject: any = {};
      if (dataPutProject.AssetTypes.length !== 0 || dataPutProject.InvestmentTypes.length !== 0) {
        newDataPutProject.Fields = dataPutProject.Fields
      }
      if (dataPutProject.Fields.length !== 0) {
        newDataPutProject.Fields = dataPutProject.Fields
      }
      if (dataPutProject.AssetTypes.length !== 0) {
        newDataPutProject.AssetTypes = dataPutProject.AssetTypes
      }
      if (dataPutProject.InvestmentTypes.length !== 0) {
        newDataPutProject.InvestmentTypes = dataPutProject.InvestmentTypes
      }
      if (newDataPutProject.Fields !== undefined) {
        this.serviceProject.putProject(newDataPutProject, this.data.dataPopup.projectId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
          
          this.servicePopup.dialogRefLoading.close();
        }, error => {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessagePutProject, ["custom-style-error"]
          );
          this.servicePopup.dialogRefLoading.close()
        })
      }      
    }
  }
  // Observe mat checkbox immovable and financial
  changeValueImmovable(value: any, nameCheckBox: string) {
    if (nameCheckBox === 'immovable') {
      if (value.checked === false && this.insertImmoProjectForm.value.financial === false) {
        this.insertImmoProjectForm.patchValue({
          financial: [false, Validators.required]
        })
      }
    }
    if (nameCheckBox === 'financial') {
      if (value.checked === false && this.insertImmoProjectForm.value.immovable === false) {
        this.insertImmoProjectForm.patchValue({
          immovable: [false, Validators.required]
        })
      }
    }
  }

  // Observe field for delete label if input touched or valid
  openFieldStartDate(eventField: any, insertProjectForm: any) {
    if (eventField.type === "focus" || eventField === true || insertProjectForm != null) {
      this.checkSecondContact()
    } else if (eventField.type === "blur") {

    }
  }

  checkSecondContact() {
    let i = 0;
    let index = 0;
    if (this.selectedStepIndex === 1) {
      // this.errorMessageContact = false;

      for (const fieldValueForm in this.insertContactSecondProjectForm.value) {
        index += 1
        if (this.insertContactSecondProjectForm.value[fieldValueForm] !== null && this.insertContactSecondProjectForm.value[fieldValueForm] !== "" && fieldValueForm !== "toggleCoBooking") {
          i += 1;
          if (this.insertContactSecondProjectForm.value.lastname !== "" || this.insertContactSecondProjectForm.value.email !== "") {
            if (!(this.insertContactSecondProjectForm.value.email.search('@') !== -1) && !(this.insertContactSecondProjectForm.value.email.search('.') !== -1)) {
              this.errorMessageContact = true;
            } else {
              this.errorMessageContact = false;
            }
          } else {
            let form = this.insertContactSecondProjectForm.value
            if (form.civility === null && form.firstname === "" && form.youngLastname === "" && form.phone === "" && form.mobilePhone === "" && form.phonePro === "" &&
              form.emailPro === "" && form.addressOne === "" && form.addressTwo === "" && form.zipCode === "" && (form.city === null || form.cityNotPicking === "") &&
              form.birthday === "" && form.cityBirthday === "" && form.countryBirthday === null && form.countryResidence === null && form.nationality === "" && form.numCardDay === "" &&
              form.periodicityValidity === "" && form.familySituation === null && form.familySituationDate === "" && form.familySituationLocation === "" && form.familySituationNotary === "" &&
              form.matrimonialRegime === null && form.category === null && form.profession === "" && form.contractWork === null && form.seniority === "" &&
              form.employerActual === "" && form.employerAddress === "" && form.rentMonth === "" && form.typeRent === null) {
              this.errorMessageContact = false;
            } else {
              this.errorMessageContact = true;
            }
          }
        }

        if (index === 32 && i === 0) {
          this.errorMessageContact = false;
        }
      }
    }
  }

  addToggleRecall(toggleRecall: boolean, idProject: string) {
    if (this.toggleRecall[idProject] === undefined) {
      this.toggleRecall[idProject] = toggleRecall;
    }
  }

  // idProjectUse:string = '';
  openPopupRecall(recallValue: boolean, dataRecall: any) {
  }

  isFieldIsLocked(fieldName: string): Boolean {
    if (this.serviceErip.isEripActivated && this.serviceErip.isEripEnabledForCurrentSubModule) {
      if (this.data.dataPopup.eripId != "")
        return this.serviceErip.readOnlyFields.includes(fieldName);
      else return false;
    }
    else return false;
  }


  // refreshFoyerFiscalForm() {
  //   this.insertFoyerFiscalProjectForm.patchValue({
  //     nbPartFiscal: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     tmi: [{ value: null, disabled: this.isFieldReadOnlyAccess }],
  //     nbPersCharge: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     nbChildren: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     nbChildrenCharge: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     yearsChildren: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     nameChildren: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     grossTaxAmount: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     paidTaxAmount: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     taxIncomeRefN: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     taxIncomeRefN1: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     taxIncomeRefN2: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     // savingConstituted: [ "" ],
  //     minSavingEffort: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     maxSavingEffort: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     disponibilityMin: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     disponibilityMax: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     originDisponibility: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     wishRentability: [{ value: "", disabled: this.isFieldReadOnlyAccess }],
  //     acceptWarning: [null],
  //     // profil: [ null ],
  //     goalInvestment1: [{ value: null, disabled: this.isFieldReadOnlyAccess }],
  //     goalInvestment2: [{ value: null, disabled: this.isFieldReadOnlyAccess }],
  //     goalInvestment3: [{ value: null, disabled: this.isFieldReadOnlyAccess }],
  //     goalInvestment4: [{ value: null, disabled: this.isFieldReadOnlyAccess }],
  //     goalInvestment5: [{ value: null, disabled: this.isFieldReadOnlyAccess }],
  //   })
  // }

  lockProjectFiles() {
    // Contact principal
    this.insertContactPrincipalProjectFormKvp.map((element: any) => {
      const key: string = Object.keys(element)[0];
      var isLocked: Boolean = this.serviceErip.readOnlyFields.includes(element[key])
      if (isLocked)
        this.insertContactPrincipalProjectForm.get(key)?.disable()
      // this.insertContactPrincipalProjectForm.setControl(key,new FormControl({ disabled: true}));
    })

    // Foyer fiscal
    this.insertFoyerFiscalProjectFormKvp.map((element: any) => {
      var key: string = Object.keys(element)[0];
      var isLocked: Boolean = this.serviceErip.readOnlyFields.includes(element[key])
      if (isLocked)
        this.insertFoyerFiscalProjectForm.get(key)?.disable()
      // this.insertFoyerFiscalProjectForm.setControl(key,new FormControl({ disabled: true}));

    }
    )

    // Projet Immo / Placement
    this.insertImmoProjectFormKvp.map((element: any) => {
      var key: string = Object.keys(element)[0];
      var isLocked: Boolean = this.serviceErip.readOnlyFields.includes(element[key])
      if (isLocked)
        this.insertImmoProjectForm.get(key)?.disable()
      // this.insertFoyerFiscalProjectForm.setControl(key,new FormControl({ disabled: true}));

    }
    )

  }

}