import { UserService } from './user.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class PropositionService {

  activationTagPageSales: string = '';

  // static URL = environment.urlServer;
  environment: any

	constructor(private http: HttpClient, private userService: UserService, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  /** Retourne les options en cours sur critères de recherche 
   * @dataResearchSaleOption : Paramètre de recherche afin d'affiner les résultats. Peux être vide
  */
  public postPropositionInProgress(dataResearchSaleOption: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Propositions/InProgressSearch', dataResearchSaleOption, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getPropositionInProgressIndicator(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Propositions/InProgress/Indicator', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getPropositionReservation(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Reservations/', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public postPropositionPrebooking(dataResearchSalePrebooking: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Propositions/PrebookingSearch', dataResearchSalePrebooking, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getPropositionPrebookingIndicator(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Propositions/PreBooking/Indicator', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public async getBafSynchronized(id: string) {
    const headers = this.authentificationService.headers;

    return await this.http
      .get(this.environment.urlServer + 'Baf/IsBafSynchronized/if_proposition/if_propositionid/' + id , { headers: headers, observe: 'response' })
      .pipe().toPromise();
  }

  /** Retourne les options en cours sur critères de recherche 
   * @dataResearchSaleOption : Paramètre de recherche afin d'affiner les résultats. Peux être vide
  */
  public getSalesPropositions(req: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Sales/Propositions/Search', req, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

    /** Retourne les options en cours sur critères de recherche 
   * @dataResearchSaleOption : Paramètre de recherche afin d'affiner les résultats. Peux être vide
  */
    public getSalesPropositionsIndicator(req: any): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .post(this.environment.urlServer + 'Sales/Propositions/SearchCount', req, { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    /** Retourne les pré-réservations en cours sur critères de recherche 
   * @dataResearchSaleOption : Paramètre de recherche afin d'affiner les résultats. Peux être vide
  */
    public getSalesPrebooking(req: any): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .post(this.environment.urlServer + 'Sales/PreBookings/Search', req, { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

        /** Retourne les pré-réservations en cours sur critères de recherche 
   * @dataResearchSaleOption : Paramètre de recherche afin d'affiner les résultats. Peux être vide
  */
        public getSalesPrebookingIndicator(req: any): Observable<any> {
          const headers = this.authentificationService.headers;
      
          return this.http
            .post(this.environment.urlServer + 'Sales/PreBookings/SearchCount', req, { headers: headers, observe: 'response' })  
            .pipe(map((response: HttpResponse<any>) => {
                console.log(response.body);
                return response.body;
              })
            );
        }

}
