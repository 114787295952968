import { Injectable } from '@angular/core';

const TOKEN_KEY = "tokenCasUser"

@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {

  constructor() { }

  saveToken(token: string) {
    window.localStorage.setItem(TOKEN_KEY, token);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  getToken(): string {
      let token = window.sessionStorage.getItem(TOKEN_KEY);
      if (token !== null)
        return token; 
      else
        token = window.localStorage.getItem(TOKEN_KEY);
        return token !== null ? token : "";
  }

  removeToken() {
      window.sessionStorage.removeItem(TOKEN_KEY)
      window.sessionStorage.clear()
    }
}
