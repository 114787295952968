<div class="containerPopupPostOption">
    <!-- Container title popup with mat dialog title -->
    <div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
        <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
            <div class="containerTitleImg">
                <img *ngIf="subModuleUse === 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
                <img *ngIf="subModuleUse !== 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/picto-popup.png">
                <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>      
            <div>
        </div>
    </div>
    
    <!-- Container content popup cancel option -->
    <mat-dialog-content class="containerMatContent d-flex align-items-center" [style]="'background:' + this.objectReceivePopup.backgroundColorContent + '; color:' + this.objectReceivePopup.colorContent">
        <div *ngIf="this.objectReceivePopup.namePopup === 'postDenunciation'" class="containerContent">
            <form class="d-flex flex-column justify-content-between containerFormPostDenunciation" [formGroup]="insertPostDenunciationForm">
                <div class="d-flex flex-row flex-wrap justify-content-between">
                    <div class="d-flex">
                        <mat-form-field class="formFieldCustomTwo" appearance="fill">
                            <mat-label class="labelField">{{ labelLastname }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="lastname"
                                >
                        </mat-form-field>
                        <span class="symboleRequired">*</span>
                    </div>

                    <div class="d-flex">
                        <mat-form-field class="formFieldCustomTwo" appearance="fill">
                            <mat-label class="labelField">{{ labelFirstname }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="firstname"
                                >
                        </mat-form-field>
                        <span class="symboleRequired">*</span>
                    </div>

                    <div>
                        <mat-form-field class="formFieldCustomTwo" appearance="fill">
                            <mat-label class="labelField">{{ labelZipcode }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="zipcode"
                                >
                        </mat-form-field>
                    </div>

                    <div style="margin-right: 15px;">
                        <mat-form-field class="formFieldCustomTwo" appearance="fill">
                            <mat-label class="labelField">{{ labelCity }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="city"
                                >
                        </mat-form-field>
                    </div>

                    <div class="d-flex">
                        <mat-form-field class="formFieldCustomTwo" appearance="fill">
                            <mat-label class="labelField">{{ labelEmail }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="email"
                                >
                        </mat-form-field>
                        <span class="symboleRequired">*</span>
                    </div>

                    <div class="d-flex">
                        <mat-form-field class="formFieldCustomTwo" appearance="fill">
                            <mat-label class="labelField">{{ labelPhone }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="phone"
                                >
                        </mat-form-field>
                        <span class="symboleRequired">*</span>
                    </div>
                </div>
                <div class="containerLegend">
                    * Champs requis
                </div>
            </form>
        </div>
    </mat-dialog-content>
    
    <div *ngIf="this.objectReceivePopup.namePopup === 'postDenunciation'" class="containerAction">
        <button mat-dialog-close class="btnActionAnnulation">Annuler</button>
        <button (click)="onSubmitPostDenunciation()" class="btnActionValidation">Valider</button>
    </div>
</div>