import { EventTaskCall } from './event-task-call';
import { InvestmentSale } from './investment-sale';
import { InvestmentSaleProjectId } from './investment-sale-project-id';
import { InvestmentType } from './investment-type';
import { ProjectReservation } from './project-reservation';
import { Propositions } from './propositions';
import { Types } from './types';
export class ProjectId {
    public "AssetTypes"!: Array<Types>;
    public "InvestmentSales"!: Array<InvestmentSaleProjectId>;
    public "InvestmentTypes"!: Array<InvestmentType>;
    public "Reservations"!: Array<ProjectReservation>;
    public "Propositions"!: Array<Propositions>;
    public "Todolist"!: Array<EventTaskCall>
    public "address1_city"!: string;
    public "address1_line1"!: string;
    public "address1_line2"!: string;
    public "address1_line3"!: string;
    public "address1_postalcode"!: string;
    public "appcustomercall_category"!: string;
    public "appcustomercall_description"!: string;
    public "appcustomercall_if_availability"!: string;
    public "appcustomercall_if_closedate"!: string;
    public "appcustomercall_if_conclusiondesc"!: string;
    public "appcustomercall_if_id_activitysync"!: string;
    public "appcustomercall_if_lock_appointmentdates"!: string;
    public "appcustomercall_if_privatecomments"!: string;
    public "appcustomercall_if_rdvtype_calc"!: string;
    public "appcustomercall_if_rdvtype_calc_label"!: string;
    public "appcustomercall_if_siteb2b_visibility"!: string;
    public "appcustomercall_if_subjectcomplement"!: string;
    public "appcustomercall_location"!: string;
    public "appcustomercall_prioritycode"!: string;
    public "appcustomercall_prioritycode_label"!: string;
    public "appcustomercall_scheduledend"!: string;
    public "appcustomercall_scheduledstart"!: string;
    public "appcustomercall_statecode"!: string;
    public "appcustomercall_statecode_label"!: string;
    public "appcustomercall_statuscode"!: string;
    public "appcustomercall_statuscode_label"!: string;
    public "appcustomercall_subject"!: string;
    public "createdon"!: string;
    public "docder_if_show_bt_docusign_sync"!: number;
    public "docder_if_signing_date"!: string;
    public "docder_statuscode"!: boolean;
    public "docder_statuscode_label"!: string;
    public "emailaddress1"!: string;
    public "firstname"!: string;
    public "if_childrenages"!: string;
    public "if_childrencount"!: string;
    public "if_childrensnames"!: string;
    public "if_closedate"!: string;
    public "if_cobooking"!: boolean;
    public "if_commercialassignmentid"!: string;
    public "if_conclusiondesc"!: string;
    public "if_constitutedthrift"!: string;
    public "if_ctc1_birthcountryid"!: string;
    public "if_ctc1_birthcountryid_label"!: string;
    public "if_ctc1_birthplace"!: string;
    public "if_ctc1_cspid"!: string;
    public "if_ctc1_cspid_label"!: string;
    public "if_ctc1_currentemployer"!: string;
    public "if_ctc1_currentemployeraddress"!: string;
    public "if_ctc1_employmentcontractid"!: string;
    public "if_ctc1_employmentcontractid_label"!: string;
    public "if_ctc1_familysituation_date"!: string;
    public "if_ctc1_familysituation_notarydesc"!: string;
    public "if_ctc1_familysituation_place"!: string;
    public "if_ctc1_familysituationid"!: string;
    public "if_ctc1_familysituationid_label"!: string;
    public "if_ctc1_jobtitle"!: string;
    public "if_ctc1_maidenname"!: string;
    public "if_ctc1_matrimonialregimeid"!: string;
    public "if_ctc1_matrimonialregimeid_label"!: string;
    public "if_ctc1_nationality"!: string;
    public "if_ctc1_postalcode"!: string;
    public "if_ctc1_professionalsituationsincedate"!: string;
    public "if_ctc1_residencecountryid"!: string;
    public "if_ctc1_residencecountryid_label"!: string;
    public "if_ctc1_residencepermitnumber"!: string;
    public "if_ctc1_residencepermitperioddesc"!: string;
    public "if_ctc1_streetnumber"!: string;
    public "if_ctc1_streettype"!: string;
    public "if_ctc1_streetname"!: string;
    public "if_ctc2_streetnumber"!: string;
    public "if_ctc2_streettype"!: string;
    public "if_ctc2_streetname"!: string;
    public "if_ctc1_street1"!: string;
    public "if_ctc1_street2"!: string;
    public "if_ctc1birthday"!: string;
    public "if_ctc1city"!: string;
    public "if_ctc1cityid"!: string;
    public "if_ctc1cityid_label"!: string;
    public "if_ctc1email1"!: string;
    public "if_ctc1email2"!: string;
    public "if_ctc1firstname"!: string;
    public "if_ctc1lastname"!: string;
    public "if_ctc1mobilephone"!: string;
    public "if_ctc1personnalphone"!: string;
    public "if_ctc1professionalphone"!: string;
    public "if_ctc1salutationid"!: string;
    public "if_ctc1salutationid_label"!: string;
    public "if_ctc2_birthcountryid"!: string;
    public "if_ctc2_birthcountryid_label"!: string;
    public "if_ctc2_birthplace"!: string;
    public "if_ctc2_city"!: string;
    public "if_ctc2_cspid"!: string;
    public "if_ctc2_cspid_label"!: string;
    public "if_ctc2_currentemployer"!: string;
    public "if_ctc2_currentemployeraddress"!: string;
    public "if_ctc2_employmentcontractid"!: string;
    public "if_ctc2_employmentcontractid_label"!: string;
    public "if_ctc2_familysituation_date"!: string;
    public "if_ctc2_familysituation_notarydesc"!: string;
    public "if_ctc2_familysituation_place"!: string;
    public "if_ctc2_familysituationid"!: string;
    public "if_ctc2_jobtitle"!: string;
    public "if_ctc2_maidenname"!: string;
    public "if_ctc2_matrimonialregimeid"!: string;
    public "if_ctc2_matrimonialregimeid_label"!: string;
    public "if_ctc2_nationality"!: string;
    public "if_ctc2_postalcode"!: string;
    public "if_ctc2_professionalsituationsincedate"!: string;
    public "if_ctc2_residencecountryid"!: string;
    public "if_ctc2_residencecountryid_label"!: string;
    public "if_ctc2_residencepermitnumber"!: string;
    public "if_ctc2_residencepermitperioddesc"!: string;
    public "if_ctc2_street1"!: string;
    public "if_ctc2_street2"!: string;
    public "if_ctc2birthday"!: string;
    public "if_ctc2cityid"!: string;
    public "if_ctc2cityid_label"!: string;
    public "if_ctc2email1"!: string;
    public "if_ctc2email2"!: string;
    public "if_ctc2firstname"!: string;
    public "if_ctc2lastname"!: string;
    public "if_ctc2mobilephone"!: string;
    public "if_ctc2personnalphone"!: string;
    public "if_ctc2professionalphone"!: string;
    public "if_ctc2salutationid"!: string;
    public "if_ctc2salutationid_label"!: string;
    public "if_customer1id"!: string;
    public "if_customerproperties"!: number;
    public "if_customerproperties_label"!: string;
    public "if_customertocallback_action"!: number;
    public "if_customertocallback_date"!: string;
    public "if_customertocallback_description"!: string;
    public "if_dependentchildsnumber"!: number;
    public "if_dependentpeoplenumber"!: string;
    public "if_id_erip_calc"!: string;
    public "if_id_lafactory"!: string;
    public "if_immo_surfacemax"!: string;
    public "if_immo_surfacemin"!: string;
    public "if_immo_taxexemptionid"!: string;
    public "if_immo_taxexemptionid_label"!: string;
    public "if_incometax_grossamount"!: string;
    public "if_incometax_payableamount"!: string;
    public "if_incometaxref_n"!: string;
    public "if_incometaxref_n1"!: string;
    public "if_incometaxref_n2"!: string;
    public "if_investment_availabilityamountmax"!: number;
    public "if_investment_availabilityamountmin"!: number;
    public "if_investment_availabilityorigin"!: string;
    public "if_investment_savingscapacitymonthlymax"!: number;
    public "if_investment_savingscapacitymonthlymin"!: number;
    public "if_investmentobjective1id"!: string;
    public "if_investmentobjective1id_label"!: string;
    public "if_investmentobjective2id"!: string;
    public "if_investmentobjective2id_label"!: string;
    public "if_investmentobjective3id"!: string;
    public "if_investmentobjective3id_label"!: string;
    public "if_investmentobjective4id"!: string;
    public "if_investmentobjective4id_label"!: string;
    public "if_investmentobjective5id"!: string;
    public "if_investmentobjective5id_label"!: string;
    public "if_investorprofileid"!: string;
    public "if_investorprofileid_label"!: string;
    public "if_isinvestmentproject"!: boolean;
    public "if_isrealestateproject"!: boolean;
    public "if_mainoperationid"!: string;
    public "if_mainoperationid_label"!: string;
    public "if_mainsearchprofil"!: number;
    public "if_mainsearchprofil_label"!: string;
    public "if_mkg_channelid"!: string;
    public "if_mkg_channelid_label"!: string;
    public "if_name"!: string;
    public "if_nextconclusioncodename"!: string;
    public "if_progressioncode_date"!: string;
    public "if_progressioncodeid"!: string;
    public "if_progressioncodeid_label"!: string;
    public "if_progressionmaincodeid"!: string;
    public "if_progressionmaincodeid_label"!: string;
    public "if_projectactivity_calc"!: number;
    public "if_projectactivity_calc_label"!: string;
    public "if_projectcomments"!: string;
    public "if_projectconclusioncodeid"!: string;
    public "if_projectid"!: string;
    public "if_projectinitdate"!: string;
    public "if_purchasetimeframe"!: string;
    public "if_purchasetimeframe_label"!: string;
    public "if_show_bt_conclusion_process"!: number;
    public "if_r1_appointmentid"!: string;
    public "if_r1_appointmentid_label"!: string;
    public "if_r1_infosprogressrate"!: number;
    public "if_r2_appointmentid"!: string;
    public "if_r2_appointmentid_label"!: string;
    public "if_r2_infosprogressrate"!: number;
    public "if_r3_appointmentid"!: string;
    public "if_r3_appointmentid_label"!: string;
    public "if_r3_infosprogressrate"!: number;
    public "if_rdvstep"!: string;
    public "if_requirementdescription"!: string;
    public "if_taxpartsnumber"!: string;
    public "if_wishbudgetmax"!: number;
    public "if_wishbudgetmin"!: number;
    public "if_wishprofitabilitymin"!: string;
    public "lastname"!: string;
    public "marketorg_if_fluxsiteb2bcode"!: string;
    public "marketorg_if_fluxsiteb2blabel"!: string;
    public "marketorg_if_marketingoriginid"!: string;
    public "mobilephone"!: string;
    public "nextconclusion_if_conclusioncodeid"!: string;
    public "nextconclusion_if_fluxsiteb2bcode"!: string;
    public "nextconclusion_if_fluxsiteb2blabel"!: string;
    public "nextmainconclusion_if_fluxsiteb2bcode"!: string;
    public "nextmainconclusion_if_fluxsiteb2blabel"!: string;
    public "nextmainconclusion_if_mainconclusioncodeid"!: string;
    public "ownerid"!: string;
    public "progressionconclusion_if_conclusioncodeid"!: string;
    public "progressionconclusion_if_fluxsiteb2bcode"!: string;
    public "progressionconclusion_if_fluxsiteb2blabel"!: string;
    public "progressionmainconclusion_if_fluxsiteb2bcode"!: string;
    public "progressionmainconclusion_if_fluxsiteb2blabel"!: string;
    public "progressionmainconclusion_if_mainconclusioncodeid"!: string;
    public "rdv1_if_conclusioncodeid"!: string;
    public "rdv1_if_conclusioncodeid_label"!: string;
    public "rdv1_if_rdvtype"!: string;
    public "rdv1_scheduledstart"!: string;
    public "rdv1_statecode"!: number;
    public "rdv1_statecode_label"!: string;
    public "rdv1_statuscode"!: number;
    public "rdv1_statuscode_label"!: string;
    public "rdv2_if_conclusioncodeid"!: string;
    public "rdv2_if_conclusioncodeid_label"!: string;
    public "rdv2_if_rdvtype"!: string;
    public "rdv2_scheduledstart"!: string;
    public "rdv2_statecode"!: number;
    public "rdv2_statecode_label"!: string;
    public "rdv2_statuscode"!: string;
    public "rdv2_statuscode_label"!: string;
    public "rdv3_if_conclusioncodeid"!: string;
    public "rdv3_if_conclusioncodeid_label"!: string;
    public "rdv3_if_rdvtype"!: string;
    public "rdv3_scheduledstart"!: string;
    public "rdv3_statecode"!: number;
    public "rdv3_statecode_label"!: string;
    public "rdv3_statuscode"!: string;
    public "rdv3_statuscode_label"!: string;
    public "sign_if_der_statuscode"!: number;
    public "sign_if_der_statuscode_label"!: string;
    public "sign_if_fluxsiteb2bcode"!: string;
    public "sign_if_fluxsiteb2blabel"!: string;
    public "statuscode"!: number;
    public "statuscode_label"!: string;
    public "telephone1"!: string;
    public "if_ctc1_derid"!: string;
    public "if_tmiid"!: string;
    public "risk_if_fluxsiteb2blabel"!: string;
    public "risk_if_fluxsiteb2bcode"!: string;
    public "risk_if_investment_riskid"!: string;

    public "qui_ctc1_salaryamount"?: string;
    public "qui_ctc2_salaryamount"?: string;
    public "qui_ctc1_annualincometype"?: string;
    public "qui_ctc2_annualincometype"?: string;
    public "if_show_bt_frontduplication"?: number;


    // public "AssetTypes": Array<Types> = [];
    // public "InvestmentTypes": Array<InvestmentType> = [];
    // public "project.if_isrealestateproject": string | null = null;
    // public "project.if_isinvestmentproject": string| null = null;
    // public "project.if_ctc1salutationid": string| null = null;
    // public "project.if_ctc1salutationid_label": string| null = null;
    // public "project.if_ctc1lastname": string| null = null;
    // public "project.if_ctc1firstname": string| null = null;
    // public "project.if_ctc1_maidenname": string| null = null;
    // public "project.if_ctc1birthday": string| null = null;
    // public "project.if_ctc1_birthplace": string| null = null;
    // public "project.if_ctc1_birthcountryid": string| null = null;
    // public "project.if_ctc1_birthcountryid_label": string| null = null;
    // public "project.if_ctc1_nationality": string| null = null;
    // public "project.if_ctc1_residencecountryid": string| null = null;
    // public "project.if_ctc1_residencecountryid_label": string| null = null;
    // public "project.if_ctc1_residencepermitnumber": string| null = null;
    // public "project.if_ctc1_residencepermitperioddesc": string| null = null;
    // public "project.if_ctc1mobilephone": string| null = null;
    // public "project.if_ctc1personnalphone": string| null = null;
    // public "project.if_ctc1professionalphone": string| null = null;
    // public "project.if_ctc1email1": string| null = null;
    // public "project.if_ctc1email2": string| null = null;
    // public "project.if_ctc1_street1": string| null = null;
    // public "project.if_ctc1_street2": string| null = null;
    // public "project.if_ctc1_postalcode": string| null = null;
    // public "project.if_ctc1cityid": string| null = null;
    // public "project.if_ctc1cityid_label": string| null = null;
    // public "project.if_ctc1city": string| null = null;
    // public "project.if_ctc1_familysituationid": string| null = null;
    // public "project.if_ctc1_familysituationid_label": string| null = null;
    // public "project.if_ctc1_familysituation_date": string| null = null;
    // public "project.if_ctc1_familysituation_place": string| null = null;
    // public "project.if_ctc1_familysituation_notarydesc": string| null = null;
    // public "project.if_ctc1_matrimonialregimeid": string| null = null;
    // public "project.if_ctc1_matrimonialregimeid_label": string| null = null;
    // public "project.if_ctc1_cspid": string| null = null;
    // public "project.if_ctc1_cspid_label": string| null = null;
    // public "project.if_ctc1_jobtitle": string| null = null;
    // public "project.if_ctc1_employmentcontractid": string| null = null;
    // public "project.if_ctc1_employmentcontractid_label": string| null = null;
    // public "project.if_ctc1_professionalsituationsincedate": string| null = null;
    // public "project.if_ctc1_currentemployer": string| null = null;
    // public "project.if_ctc1_currentemployeraddress": string| null = null;
    // public "project.if_ctc2firstname": string| null = null;
    // public "project.if_ctc2_maidenname": string| null = null;
    // public "project.if_ctc2lastname": string| null = null;
    // public "project.if_ctc2_residencepermitperioddesc": string| null = null;
    // public "project.if_ctc2_residencecountryid": string| null = null;
    // public "project.if_ctc2_residencecountryid_label": string| null = null;
    // public "project.if_ctc2_birthcountryid": string| null = null;
    // public "project.if_ctc2_birthcountryid_label": string| null = null;
    // public "project.if_ctc2_nationality": string| null = null;
    // public "project.if_ctc2_residencepermitnumber": string| null = null;
    // public "project.if_ctc2_birthplace": string| null = null;
    // public "project.if_ctc2birthday": string| null = null;
    // public "project.if_ctc2_familysituation_notarydesc": string| null = null;
    // public "project.if_ctc2_familysituation_place": string| null = null;
    // public "project.if_ctc2_familysituation_date": string| null = null;
    // public "project.if_ctc2_familysituationid": string| null = null;
    // public "project.if_ctc2_matrimonialregimeid": string| null = null;
    // public "project.if_ctc2_matrimonialregimeid_label": string| null = null;
    // public "project.if_ctc2mobilephone": string| null = null;
    // public "project.if_ctc2_street2": string| null = null;
    // public "project.if_ctc2_street1": string| null = null;
    // public "project.if_ctc2email2": string| null = null;
    // public "project.if_ctc2email1": string| null = null;
    // public "project.if_ctc2cityid": string| null = null;
    // public "project.if_ctc2cityid_label": string| null = null;
    // public "project.if_ctc2_city": string| null = null;
    // public "project.if_ctc2_postalcode": string| null = null;
    // public "project.if_ctc2_jobtitle": string| null = null;
    // public "project.if_ctc2_cspid": string| null = null;
    // public "project.if_ctc2_cspid_label": string| null = null;
    // public "project.if_ctc2_currentemployer": string| null = null;
    // public "project.if_ctc2_professionalsituationsincedate": string| null = null;
    // public "project.if_ctc2_employmentcontractid": string| null = null;
    // public "project.if_ctc2_employmentcontractid_label": string| null = null;
    // public "project.if_ctc2_currentemployeraddress": string| null = null;
    // public "project.if_ctc2professionalphone": string| null = null;
    // public "project.if_ctc2personnalphone": string| null = null;
    // public "project.if_incometaxref_n2": string| null = null;
    // public "project.if_incometaxref_n1": string| null = null;
    // public "project.if_incometaxref_n": string| null = null;
    // public "project.if_taxpartsnumber": string| null = null;
    // public "project.if_dependentchildsnumber": string| null = null;
    // public "project.if_childrencount": string| null = null;
    // public "project.if_incometax_grossamount": string| null = null;
    // public "project.if_incometax_payableamount": string| null = null;
    // public "project.if_childrenages": string| null = null;
    // public "project.if_childrensnames": string| null = null;
    // public "project.if_dependentpeoplenumber": string| null = null;
    // public "project.if_constitutedthrift": string| null = null;
    // public "project.if_investment_savingscapacitymonthlymin": number| null = null;
    // public "project.if_investment_savingscapacitymonthlymax": number| null = null;
    // public "project.if_wishprofitabilitymin": number| null = null;
    // public "project.if_investmentobjective5id": string| null = null;
    // public "project.if_investmentobjective5id_label": string| null = null;
    // public "project.if_investmentobjective4id": string| null = null;
    // public "project.if_investmentobjective4id_label": string| null = null;
    // public "project.if_investmentobjective3id": string| null = null;
    // public "project.if_investmentobjective3id_label": string| null = null;
    // public "project.if_investmentobjective2id": string| null = null;
    // public "project.if_investmentobjective2id_label": string| null = null;
    // public "project.if_investmentobjective1id": string| null = null;
    // public "project.if_investmentobjective1id_label": string| null = null;
    // public "project.if_mkg_channelid": string| null = null;
    // public "project.if_mkg_channelid_label": string| null = null;
    // public "project.if_mkg_originid": string| null = null;
    // public "project.if_mkg_originid_label": string| null = null;
    // public "assettype.if_fluxsiteb2bcode": string| null = null;
    // public "assettype.if_fluxsiteb2blabel": string| null = null;
    // public "investmenttype.if_category": number| null = null;
    // public "investmenttype.if_category_label": string| null = null;
    // public "investmenttype.if_code": string| null = null;
    // public "investmenttype.if_name": string| null = null;
    // public "assettype.if_assettypeid": string| null = null;
    // public "investmenttype.if_investment_typeid": string| null = null;
    // public "project.if_ctc2salutationid": string| null = null;
    // public "project.if_ctc2salutationid_label": string| null = null;
    // public "project.if_customerproperties_label": string| null = null;
    // public "project.if_immo_surfacemin": string| null = null;
    // public "project.if_immo_taxexemptionid_label": string| null = null;
    // public "project.if_investment_availabilityamountmax": string| null = null;
    // public "project.if_investment_availabilityamountmin": string| null = null;
    // public "project.if_investment_availabilityorigin": string| null = null;
    // public "project.if_investorprofileid_label": string| null = null;
    // public "project.if_mainoperationid_label": string| null = null;
    // public "project.if_mainsearchprofil_label": string| null = null;
    // public "project.if_name": string| null = null;
    // public "project.if_projectcomments": string| null = null;
    // public "project.if_purchasetimeframe_label": string| null = null;
    // public "project.if_requirementdescription": string| null = null;
    // public "project.if_wishbudgetmax": string| null = null;
    // public "project.if_wishbudgetmin": string| null = null;
}
