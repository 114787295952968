import { UserService } from './user.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {

  // static URL = environment.urlServer;
  environment: any;

	constructor(private http: HttpClient, private userService: UserService, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getAppointmentType(idType: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .get(this.environment.urlServer + 'Appointments/Indicators/' + idType, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public postAppointment(dataEvent: any): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .post(this.environment.urlServer + 'Appointments/', JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public updateAppointmentId(appointmentId: string,dataEvent: any): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlServer + 'Appointments/' + appointmentId, JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public async getBafSynchronizedAppointment(id: string) {
    const headers = this.authentificationService.headers;

    return await this.http
      .get(this.environment.urlServer + 'Baf/IsBafSynchronized/appointment/activityid/' + id , { headers: headers, observe: 'response' })
      .pipe().toPromise();
  }


  public async getBafSynchronizedCloseAppointment(id: string) {
    const headers = this.authentificationService.headers;

    return await this.http
      .get(this.environment.urlServer + 'Baf/IsBafSynchronized/if_closeappointment/if_appointmentid/' + id , { headers: headers, observe: 'response' })
      .pipe().toPromise();
  }
}


