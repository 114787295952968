<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>      
            <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon" style="margin-left: auto;">close</mat-icon>
        <div>
    </div>
</div>

<!-- Container content popup cancel option -->
<mat-dialog-content style="max-height: 100% !important">
    <div class="containerContent">
        <div class="slider-wrapper">
            <!-- The Slider -->
            <div class="slider">
              <div class="slides">
                <div class="slide" [ngClass]="{ 'active': imgCarousel.src === objectReceivePopup.dataPopup.imgCarouselSelected }" *ngFor="let imgCarousel of objectReceivePopup.dataPopup.listImgCarousel">
                  <img *ngIf="objectReceivePopup.namePopup != 'imgOperation'" [src]="imgCarousel.src" alt="image 1" >
                  <img *ngIf="objectReceivePopup.namePopup == 'imgOperation' && imgCarousel.src != '' && imgCarousel.src" src="../../../../../Resources/Big/{{imgCarousel.src}}" alt="image 1" onerror="this.src='assets/defaultimage.png'" >

                </div>
              </div>
              <!-- The previous and next buttons -->
              <button *ngIf="objectReceivePopup.dataPopup.listImgCarousel.length > 1" class="controls prev-btn" id="prev">
                <svg viewBox="0 0 320 512" width="100" title="chevron-left">
                  <path
                    d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                  />
                </svg>
              </button>
              <button *ngIf="objectReceivePopup.dataPopup.listImgCarousel.length > 1" class="controls next-btn" id="next">
                <svg viewBox="0 0 320 512" width="100" title="chevron-right">
                  <path
                    d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                  />
                </svg>
              </button>
            </div>
      
            <!-- The pagination -->
            <div class="pagination">
              <button *ngFor="let imgCarousel of objectReceivePopup.dataPopup.listImgCarousel; let i = index" class="pagination-btn" [ngClass]="{ 'active': imgCarousel.src === objectReceivePopup.dataPopup.imgCarouselSelected }" data-slide="{{i}}"></button>
              <!-- <button class="pagination-btn active" data-slide="1"></button>
              <button class="pagination-btn" data-slide="2"></button>
              <button class="pagination-btn" data-slide="3"></button>
              <button class="pagination-btn" data-slide="4"></button>
              <button class="pagination-btn" data-slide="5"></button>
              <button class="pagination-btn" data-slide="6"></button>
              <button class="pagination-btn" data-slide="7"></button> -->

            </div>
        </div>
    </div>
</mat-dialog-content>