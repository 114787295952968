import { AuthentificationService } from './../shared/services/authentification.service';
import { UserService } from './../shared/services/user.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeaderInterceptor implements HttpInterceptor {

	constructor(private authentificationService: AuthentificationService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const commercialId = localStorage.getItem('CommercialId');
        
        if (!commercialId) {
            return next.handle(req);
        }
        let headers = req.headers.set('accept', 'text/plain');
        headers = req.headers.set('Authorization', commercialId);
        headers = req.headers.set('Content-Type', "application/json");

        this.authentificationService.headers = headers;

        const authReq = req.clone({ headers });
        return next.handle(authReq);
	}
}
