<!-- Container popup completed subscribe full placement or placment -->
<!-- <div class="containerPopupCompleteProjectLarge" [ngClass]="{'containerPopupCompleteProject': data.size < 3 }"> -->
<div [ngClass]="{'containerPopupCompleteProjectLarge': data.size > 2, 'containerPopupCompleteProjectLargeSM': data.size <= 2 }">
    
    <!-- Container title popup -->
    <div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
        <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
            <img [ngClass]="{'picto-popup-completed-project': data.size >= 2, 'picto-popup-completed-projectSM': data.size < 2 }" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">

            <h1 [ngClass]="{'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2}">
                ({{ this.data.dataPopup.ID }}) - <span style="text-transform: uppercase;" [ngClass]="{'titlePopupBind': data.size >= 2, 'titlePopupBindSM': data.size < 2}">&nbsp;{{ this.data.dataPopup.event2.if_name }}</span>
            </h1>
            <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
        </div>
        
    </div>

    <!-- Container content popup full placement or placement -->
    <mat-dialog-content [ngClass]="{'containerCompletedProject': data.size > 2, 'containerCompletedProjectMD': data.size == 2, 'containerCompletedProjectSM': data.size < 2 }">
        <!-- Button go to back or go to next stepper and form subscribe in stepper -->
        <button mat-button *ngIf="hiddenArrowBack" [ngClass]="{'iconBackStepper': data.size > 2, 'iconBackStepperSM': data.size <= 2 }" (click)="goBack(stepper)">
            <mat-icon [ngClass]="{ 'iconCloseSM': data.size <= 2 }" aria-hidden="false" aria-label="Example user verified icon">arrow_back_ios</mat-icon>
        </button>
        <button mat-button *ngIf="hiddenArrowForward" [ngClass]="{'iconForwardStepper': data.size > 2, 'iconForwardStepperSM': data.size <= 2 }" (click)="goForward(stepper)">
            <mat-icon [ngClass]="{ 'iconCloseSM': data.size <= 2 }" aria-hidden="false" aria-label="Example user verified icon">arrow_forward_ios</mat-icon>
        </button>

        <!-- Container horizontal all mat stepper with observer if stepper change -->
        <mat-horizontal-stepper #stepper class="containerStepper" [selectedIndex]="selectedStepIndex" (selectionChange)="selectionChange(stepper)">
            
            <!-- First stepper with form subscribe -->
            <mat-step [stepControl]="insertContactPrincipalProjectForm" errorMessage="Tous les champs ne sont pas remplis">
                
                <ng-template *ngIf="initFormAllStepper" matStepLabel aria-selected="true">Contact principal</ng-template>
                <div *ngIf="initFormAllStepper" [ngClass]="{'containerSpinnerLoadStepper': data.size > 2, 'containerSpinnerLoadStepperSM': data.size <= 2}">
                    <mat-spinner class="spinnerLoad" [mode]="'indeterminate'"></mat-spinner>
                </div>
                
                <form *ngIf="!initFormAllStepper" [ngClass]="{'postNewProjectForm': data.size > 2, 'postNewProjectFormMD': data.size == 2, 'postNewProjectFormSM': data.size < 2 }" [formGroup]="insertContactPrincipalProjectForm">

<!----------------------------------------------------------------- Contact principal => Identité ------------------------------------------------------------------------------>
                    <ng-template matStepLabel aria-selected="true">Contact principal</ng-template>
                    
                    <div [ngClass]="{'containerFieldIdentityContactPrincipal': data.size < 2 }">
                        <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Identité</span>
                        <div [ngClass]="{'containerPartCompletedProjectHeader': data.size > 2, 'containerPartCompletedProjectHeaderMD': data.size == 2, 'containerPartCompletedProjectHeaderSM': data.size < 2 }">
                            <div [ngClass]="{'containerPartHeader': data.size >= 2, 'containerPartHeaderSM': data.size < 2 }">
                                <div [ngClass]="{'divCivility': data.size >= 2, 'divCivilitySM': data.size < 2 }">
                                    <!-- formControl civitility -->
                                    <span *ngIf="isOpenSelectPrincipal.civility" class="spanSelectOrigin">Civilité</span>
                                    <ng-select *ngIf="listFieldCivility.length !== 0" class="selectCustomOneLargePopup hideSelectArrow" [ngClass]="{'selectCustomOne': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1salutationid') }" formControlName="civility" placeholder="Civilité"
                                        [items]="listFieldCivility" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('civility', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('civility', insertContactPrincipalProjectForm, isOpenSelectPrincipal)"
                                        >
                                    </ng-select>
                                </div>

                                <!-- formControl lastName and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomTwo': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelLastName }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="lastname"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1lastname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.lastname.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.lastname, "lastName") }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div [ngClass]="{'containerPartHeader': data.size >= 2, 'containerPartHeaderSM': data.size < 2 }">
                                <!-- formControl firstname and error -->
                                <mat-form-field class="formFieldCustomTwo" appearance="fill">
                                    <mat-label class="labelField">{{ labelFirstName }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="firstname"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1firstname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.firstname.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.firstname, "firstName") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl youngLastName and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLastName': data.size >= 2, 'formFieldCustomOneLastNameSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelYoungLastName }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="youngLastname"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_maidenname')">lock</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

<!------------------------------------------------------- Contact principal => Situation personnelle ------------------------------------------------------------------------------>

                    <div [ngClass]="{'divContainerFieldWithTwoPart': data.size >= 2, 'divContainerFieldWithTwoPartSM': data.size < 2 }">
                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Situation personnelle</span>
                            
                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">
        
                                <!-- Div + formControl birthday with mat date picker -->
                                <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size <= 2 }">
                                    <mat-form-field [ngClass]="{ 'formFieldDatePickerLarge': data.size > 2, 'formFieldDatePickerLargeSM': data.size <= 2 }">
                                        <mat-label class="labelInput">{{ labelBirthday }}</mat-label>
                                        <input matInput readonly [matDatepicker]="picker1" formControlName="birthday">
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_birthplace')">lock</mat-icon>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <button [disabled]="isFieldIsLocked('if_ctc1_birthplace')" [ngClass]="{'btnGommeDate': data.size >= 2, 'btnGommeDateSM': data.size < 2}" (click)="resetDate(insertContactPrincipalProjectForm, 'birthday')"><img class="imgGommeDate" src="../../../../../assets/gomme.svg"></button>
                                </div>
        
                                <!-- formControl cityBirthday and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelCityBirthday }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="cityBirthday"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_birthplace')">lock</mat-icon>
                                </mat-form-field>
        
                                <div style="padding-top: 5px;">
                                    <!-- formControl countryBirthday -->
                                    <span *ngIf="isOpenSelectPrincipal.countryBirthday" class="spanSelectOrigin">Pays de naissance</span>
                                    <ng-select #countryBirthdayTerm [ngClass]="{'selectOriginLarge': data.size > 2, 'selectOriginLargeSM': data.size <= 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_birthcountryid') }" formControlName="countryBirthday" placeholder="Pays de naissance"
                                        [items]="listFieldCountryBirthday" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, countryBirthdayTerm, 'countryBirthday', isOpenSelectPrincipal)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('countryBirthday', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('countryBirthday', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                    
                                </div>
                                
                                <!-- formControl nationality and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelNationality }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="nationality"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_nationality')">lock</mat-icon>
                                </mat-form-field>


                                <div [ngClass]="{'divContainerCountryResidence': data.size >= 2, 'divContainerCountryResidenceSM': data.size < 2}">
                                    <!-- formControl countryResidence -->
                                    <span *ngIf="isOpenSelectPrincipal.countryResidence" class="spanSelectOrigin">Pays de résidence</span>
                                    <ng-select #countryResidenceTerm class="selectCountryResidence" [ngClass]="{'selectOrigin': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_residencecountryid')  }" formControlName="countryResidence" placeholder="Pays de résidence"
                                        [items]="listFieldCountryResidence" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, countryResidenceTerm, 'countryResidence', isOpenSelectPrincipal)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('countryResidence', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('countryResidence', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>
        
                                <!-- formControl numCardDay and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLarge': data.size >= 2, 'formFieldCustomOneLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelNumCardDay }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="numCardDay"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_residencepermitnumber')">lock</mat-icon>
                                </mat-form-field>

                                <!-- formControl periodicityValidity and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLarge': data.size >= 2, 'formFieldCustomOneLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelPeriodicityValidity }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="periodicityValidity"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_residencepermitperioddesc')">lock</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

<!-------------------------------------------------------------- Contact principal => Coordonnées ------------------------------------------------------------------------------>

                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Coordonnées</span>
                        
                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">
                                <!-- formControl mobilePhone and error -->
                                <mat-form-field [ngClass]="{'formFieldCompletedProjectPhoneOne': data.size >= 2, 'formFieldCompletedProjectPhoneOneSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelMobilePhone }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="mobilePhone"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1mobilephone')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.mobilePhone.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.mobilePhone, "mobilePhone") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl phone and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelPhone }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="phone"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1personnalphone')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.phone.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.phone, "phone") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl phonePro and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelPhonePro }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="phonePro"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1professionalphone')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.phonePro.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.phonePro, "phonePro") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl email and error -->
                                <mat-form-field [ngClass]="{ 'formFieldNewProjectLargeCustom': data.size >= 2, 'formFieldNewProjectLargeCustomSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmail }}</mat-label>
                                    <input type="email"
                                        matInput
                                        formControlName="email"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1email1')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.email.invalid">
                                        {{ getErrorMessageEmail("email") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl emailPro and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmailPro }}</mat-label>
                                    <input type="email"
                                        matInput
                                        formControlName="emailPro"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1email2')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.emailPro.invalid">
                                        {{ getErrorMessageEmail("emailPro") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelNumAddress }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="numAddress"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_streetnumber')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.numAddress.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.numAddress, "numAddress") }}
                                    </mat-error>
                                </mat-form-field>

                                <div style="height: 51px; width: 262px;">
                                </div>
                                
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustom': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelTypeVoie }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="typeVoie"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_streettype')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.typeVoie.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.typeVoie, "typeVoie") }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustom': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelNameVoie }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="nameVoie"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_streetname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.nameVoie.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.nameVoie, "nameVoie") }}
                                    </mat-error>
                                </mat-form-field>
                                
                                <!-- formControl addressOne and error -->
                                <mat-form-field [ngClass]="{ 'formFieldCustomOneLargeCityNotPicking disableCity': data.size >= 2, 'formFieldNewProjectLargeCustomSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelAddressOne }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="addressOne"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_street1')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.addressOne.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.addressOne ,"addressOne") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl addressTwo and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomTwoLargeCityNotPicking': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelAddressTwo }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="addressTwo"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_street2')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.addressTwo.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.addressTwo ,"addressTwo") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl zipCode and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomTwoLarge': data.size >= 2, 'formFieldCustomTwoLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelZipCode }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="zipCode"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_postalcode')">lock</mat-icon>
                                </mat-form-field>
        
                                <div style="padding-top: 5px;">
                                    <!-- formControl city and error with span ngif -->
                                    <span *ngIf="isOpenSelectPrincipal.city" class="spanSelectOrigin">{{ labelCity }}</span>
                                    <ng-select #cityTerm class="selectCity" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1cityid')}"  formControlName="city" [placeholder]="labelCity"
                                        [items]="listFieldCity" bindLabel="name" bindValue="id"  [compareWith]="compareWithFn" (keyup)="customSearchFn($event, cityTerm, 'city', isOpenSelectPrincipal)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('city', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('city', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                    <!-- <span *ngIf="contactPrincipalCityNotId !== null">Ville: {{ contactPrincipalCityNotId }}</span> -->
                                </div>

                                <!-- formControl cityNotPicking and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustom': data.size > 2, 'formFieldNewProjectLargeCustomSM': data.size <= 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelCityNotPicking }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="cityNotPicking"
                                        [readonly]="getDisabledValueOne()"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1city')">lock</mat-icon>
                                </mat-form-field>

                                <div [ngClass]="{'divContainerCountryResidence': data.size >= 2, 'divContainerCountryResidenceSM': data.size < 2}">
                                    <!-- formControl countryResidence -->
                                    <span *ngIf="isOpenSelectPrincipal.countryResidence" class="spanSelectOrigin">Pays de résidence <mat-icon class="matIconTitle" *ngIf="isFieldIsLocked('if_ctc1_residencecountryid')">lock</mat-icon></span>
                                    <ng-select #countryResidenceTerm class="selectCountryResidence" [ngClass]="{'selectOrigin': data.size < 3 }" formControlName="countryResidence" placeholder="Pays de résidence"
                                        [items]="listFieldCountryResidence" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, countryResidenceTerm, 'countryResidence', isOpenSelectPrincipal)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('countryResidence', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('countryResidence', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>    
                    </div>

<!------------------------------------------------------ Contact principal => Situation Familiale ------------------------------------------------------------------------------>

                    <div [ngClass]="{'divContainerFieldWithTwoPart': data.size >= 2, 'divContainerFieldWithTwoPartSM': data.size < 2 }">
                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Situation Familiale </span>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">

                                <div style="height: 56px;">
                                    <!-- formControl familySituation -->
                                    <span *ngIf="isOpenSelectPrincipal.familySituation" class="spanSelectOrigin">Situation familiale</span>
                                    <ng-select *ngIf="listFieldFamilySituation.length !== 0" [ngClass]="{'selectCustomLarge': data.size >= 2, 'selectCustomLargeSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_familysituationid')  }" formControlName="familySituation" placeholder="Situation familiale"
                                        [items]="listFieldFamilySituation" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('familySituation', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('familySituation', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>

                                <!-- Div + formControl familySituationDate with mat date picker -->
                                <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size <= 2 }">
                                    <mat-form-field [ngClass]="{ 'formFieldDatePickerLargeCustom': data.size > 2, 'formFieldDatePickerLargeCustomSM': data.size <= 2 }">
                                        <mat-label class="labelInput">{{ labelFamilySituationDate }}</mat-label>
                                        <input matInput readonly [matDatepicker]="picker3" formControlName="familySituationDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_familysituation_date')">lock</mat-icon>
                                    </mat-form-field>
                                    <button [disabled]="isFieldIsLocked('if_ctc1_familysituation_date')" [ngClass]="{'btnGommeDate': data.size >= 2, 'btnGommeDateSM': data.size < 2}" (click)="resetDate(insertContactPrincipalProjectForm, 'familySituationDate')"><img class="imgGommeDate" src="../../../../../assets/gomme.svg"></button>
                                </div>

                                 <!-- formControl familySituationLocation and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomThreeLarge': data.size >= 2, 'formFieldCustomThreeLargeSM': data.size < 2 }" style="margin-top: 1px; margin-bottom: 5px;" appearance="fill">
                                    <mat-label class="labelField">{{ labelFamilySituationLocation }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="familySituationLocation"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_familysituation_place')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.familySituationLocation.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.familySituationLocation, "familySituationLocation") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl familySituationNotary and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLarge': data.size >= 2, 'formFieldCustomOneLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelFamilySituationNotary }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="familySituationNotary"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_familysituation_notarydesc')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.familySituationNotary.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.familySituationNotary, "familySituationNotary") }}
                                    </mat-error>
                                </mat-form-field>

                                <div style="margin-top: 5px;">
                                    <!-- formControl matrimonialRegime -->
                                    <span *ngIf="isOpenSelectPrincipal.matrimonialRegime" class="spanSelectOrigin">Régime matrimonial</span>
                                    <ng-select *ngIf="listFieldMatrimonialRegime.length !== 0" class="selectMatrimonialRegime" [ngClass]="{'selectOrigin': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_matrimonialregimeid')}" formControlName="matrimonialRegime" placeholder="Régime matrimonial"
                                        [items]="listFieldMatrimonialRegime" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('matrimonialRegime', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('matrimonialRegime', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

<!------------------------------------------------------ Contact principal => Situation Professionnelle ------------------------------------------------------------------------>

                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectCustomSM': data.size < 2 }">Situation Professionnelle</span>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">

                                <!-- formControl employerActual and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomTwo': data.size >= 2, 'formFieldNewProjectLargeCustomTwoSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmployerActual }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="employerActual"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_currentemployer')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.employerActual.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.employerActual, "employerActual") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl profession and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomTwo': data.size >= 2, 'formFieldNewProjectLargeCustomTwoSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelProfession }}</mat-label>
                                    <input
                                        matInput 
                                        #input
                                        formControlName="profession"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_jobtitle')">lock</mat-icon>
                                    <mat-hint align="end">{{input.value?.length || 0}}/10</mat-hint>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.profession.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.profession, "profession") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl rentMonth and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomTwo': data.size >= 2, 'formFieldNewProjectLargeCustomTwoSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelRentMonth }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="rentMonth"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('qui_ctc1_salaryamount')">lock</mat-icon>
                                    <mat-icon matSuffix>euro</mat-icon>
                                </mat-form-field>

                                <div style="padding-top: 4px;">
                                    <!-- formControl typeRent -->
                                    <span *ngIf="isOpenSelectPrincipal.typeRent" class="spanSelectOrigin">Type de revenu</span>
                                    <ng-select *ngIf="listFieldTypeRent.length !== 0" [ngClass]="{'selectCustomLarge': data.size > 2, 'selectCustomLargeSM': data.size <= 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_residencecountryid') }" formControlName="typeRent" placeholder="Type de revenu"
                                        [items]="listFieldTypeRent" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('typeRent', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('typeRent', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>

                                <div>
                                    <!-- formControl category -->
                                    <span *ngIf="isOpenSelectPrincipal.category" class="spanSelectOrigin">CSP</span>
                                    <ng-select *ngIf="listFieldCategory.length !== 0" [ngClass]="{ 'selectCustomTwoLargePopupPrincipal': data.size >= 2, 'selectCustomTwoLargePopupSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_cspid') }" formControlName="category" placeholder="CSP"
                                        [items]="listFieldCategory" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('category', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('category', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>

                                <!-- Div + formControl seniority with mat date picker -->
                                <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size <= 2 }" style="padding-top: 8px;">
                                    <mat-form-field [ngClass]="{ 'formFieldDatePickerLarge': data.size > 2, 'formFieldDatePickerLargeSM': data.size <= 2 }">
                                        <mat-label class="labelInput">{{ labelSeniority }}</mat-label>
                                        <input matInput readonly [matDatepicker]="picker10" formControlName="seniority">
                                        <mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
                                        <mat-datepicker #picker10></mat-datepicker>
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_professionalsituationsincedate')">lock</mat-icon>
                                    </mat-form-field>
                                    <button [disabled]="isFieldIsLocked('if_ctc1_professionalsituationsincedate')" [ngClass]="{'btnGommeDate': data.size >= 2, 'btnGommeDateSM': data.size < 2}" (click)="resetDate(insertContactPrincipalProjectForm, 'seniority')"><img class="imgGommeDate" src="../../../../../assets/gomme.svg"></button>
                                </div>

                                <div style="height: 61px; padding-top: 8px;">
                                    <!-- formControl contractWork -->
                                    <span *ngIf="isOpenSelectPrincipal.contractWork" class="spanSelectOrigin">Contrat de travail</span>
                                    <ng-select *ngIf="listFieldContractWork.length !== 0" [ngClass]="{'selectContractPrincipal': data.size >= 2, 'selectContractPrincipalSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc1_employmentcontractid') }" formControlName="contractWork" placeholder="Contrat de travail"
                                        [items]="listFieldContractWork" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('contractWork', insertContactPrincipalProjectForm, isOpenSelectPrincipal)" (clear)="openSelectAndReset('contractWork', insertContactPrincipalProjectForm, isOpenSelectPrincipal)">
                                    </ng-select>
                                </div>                                

                                <!-- formControl employerAddress and error -->
                                <mat-form-field [ngClass]="{'formFieldEmployerAddress': data.size >= 2, 'formFieldEmployerAddress_SM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmployerAddress }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="employerAddress"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc1_currentemployeraddress')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactPrincipalProjectForm.controls.employerAddress.invalid">
                                        {{ getErrorMessageRequired(this.insertContactPrincipalProjectForm.controls.employerAddress, "employerAddress") }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <!-- Container btn for first form in first stepper -->
                    <div [ngClass]="{'containerBtnStepper': data.size >= 2, 'containerBtnStepperSM': data.size < 2 }">
                        <button class="btnCancelStepper" mat-button mat-dialog-close>Annuler</button>
                        <button *ngIf="!errorMessageContact" [ngClass]="{'btnFinishStepper': data.size > 2, 'btnFinishStepperMD': data.size == 2, 'btnFinishStepperSM': data.size < 2}" mat-button (click)="onSubmitCompletedProject('saveAndClose')">Enregistrer et fermer</button>
                        <button [ngClass]="{'btnNextStepper': data.size >= 2, 'btnNextStepperSM': data.size < 2}" mat-button matStepperNext>Suivant</button>
                    </div>
                </form>
            </mat-step>

<!---------------------------------------------------------------------------- SECOND STEPPER ---------------------------------------------------------------------------------->

            <!-- Second stepper with form subscribe -->
            <mat-step [stepControl]="insertContactSecondProjectForm" errorMessage="Tous les champs ne sont pas remplis">

                <ng-template matStepLabel aria-selected="true">Contact secondaire</ng-template>
                
                <div *ngIf="initFormAllStepper" [ngClass]="{'containerSpinnerLoadStepper': data.size >= 2, 'containerSpinnerLoadStepperSM': data.size < 2}">
                    <mat-spinner class="spinnerLoad" [mode]="'indeterminate'"></mat-spinner>
                </div>
                <form *ngIf="!initFormAllStepper" [ngClass]="{'postNewProjectForm': data.size > 2, 'postNewProjectFormMD': data.size == 2, 'postNewProjectFormSM': data.size < 2 }" (change)="checkSecondContact()" [formGroup]="insertContactSecondProjectForm">

<!----------------------------------------------------------------- Contact secondaire => Identité ------------------------------------------------------------------------------>

                    <div class="containerErrorMesssage">
                        <span class="errorMessageContactStyle" *ngIf="errorMessageContact">* Attention les champ Nom de famille et Email sont obligatoire</span>
                    </div>
                    
                    <div [ngClass]="{'containerFieldIdentityContactPrincipal': data.size < 2 }">
                        <div class="d-flex flex-row justify-content-between">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Identité</span>
                            <div class="d-flex align-items-baseline flex-wrap">
                                <button class="ml-2 btnKillContactSecond" (click)="eventChangeRemoveDataContactSecond()"><img class="removeContactSecond" alt="Icon supprimé lot comparé" src="../../../../../assets/poubelle.svg"></button>
                            </div>
                        </div>
                        <!-- if_trigger_cleanctc2 -->
                        <div [ngClass]="{'containerPartCompletedProjectHeader': data.size > 2, 'containerPartCompletedProjectHeaderMD': data.size == 2, 'containerPartCompletedProjectHeaderSM': data.size < 2 }">
                            <div [ngClass]="{'containerPartHeader': data.size >= 2, 'containerPartHeaderSM': data.size < 2 }">
                                <div [ngClass]="{'divCivility': data.size >= 2, 'divCivilitySM': data.size < 2 }">
                                    <!-- formControl civitility -->
                                    <span *ngIf="isOpenSelectSecond.civility" class="spanSelectOrigin">Civilité</span>
                                    <ng-select *ngIf="listFieldCivility.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2salutationid')}" class="selectCustomOneLargePopup" formControlName="civility" placeholder="Civilité"
                                        [items]="listFieldCivility" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('civility', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('civility', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>

                                <!-- formControl lastName and error -->
                                <mat-form-field class="formFieldCustomThree" appearance="fill">
                                    <mat-label class="labelField" [ngClass]="{'errorMessageContactStyle': errorMessageContact}"><span class="errorMessageContactStyle" *ngIf="errorMessageContact">* </span>{{ labelLastName }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="lastname"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2lastname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.lastname.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.lastname, "lastName") }}
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div [ngClass]="{'containerPartHeader': data.size >= 2, 'containerPartHeaderSM': data.size < 2 }">
                                <!-- formControl firstname and error -->
                                <mat-form-field class="formFieldCustomThree" appearance="fill">
                                    <mat-label class="labelField">{{ labelFirstName }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="firstname"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2firstname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.firstname.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.firstname, "firstName") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl youngLastName and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLastName': data.size >= 2, 'formFieldCustomOneLastNameSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelYoungLastName }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="youngLastname"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_maidenname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.youngLastname.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.youngLastname, "youngLastname") }}
                                    </mat-error>
                                </mat-form-field>                                
                            </div>
                        </div>
                    </div>

<!------------------------------------------------------- Contact secondaire => Situation personnelle ------------------------------------------------------------------------------>

                    <div [ngClass]="{'divContainerFieldWithTwoPart': data.size >= 2, 'divContainerFieldWithTwoPartSM': data.size < 2 }">
                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Situation personnelle</span>
        
                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">
        
                                <!-- Div + formControl birthday with mat date picker -->
                                <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size <= 2 }">
                                    <mat-form-field [ngClass]="{ 'formFieldDatePickerLarge': data.size > 2, 'formFieldDatePickerLargeSM': data.size <= 2 }">
                                        <mat-label class="labelInput">{{ labelBirthday }}</mat-label>
                                        <input matInput readonly [matDatepicker]="picker4" (dateInput)="openFieldStartDate($event, insertContactSecondProjectForm.value.birthday)" formControlName="birthday">
                                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2birthday')">lock</mat-icon>
                                        <mat-datepicker #picker4></mat-datepicker>
                                    </mat-form-field>
                                    <button [disabled]="isFieldIsLocked('if_ctc2birthday')" [ngClass]="{'btnGommeDate': data.size >= 2, 'btnGommeDateSM': data.size < 2}" (click)="resetDate(insertContactSecondProjectForm, 'birthday')"><img class="imgGommeDate" src="../../../../../assets/gomme.svg"></button>
                                </div>
        
                                
                                <!-- formControl cityBirthday and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelCityBirthday }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="cityBirthday"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_birthplace')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.cityBirthday.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.cityBirthday, "cityBirthday") }}
                                    </mat-error>
                                </mat-form-field> 
        
                                <div style="padding-top: 5px;">
                                    <!-- formControl countryBirthday -->
                                    <span *ngIf="isOpenSelectSecond.countryBirthday" class="spanSelectOrigin">Pays de naissance</span>
                                    <ng-select #countryBirthdayTerm [ngClass]="{'selectOriginLarge': data.size > 2, 'selectOriginLargeSM': data.size <= 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2_birthcountryid')}" formControlName="countryBirthday" placeholder="Pays de naissance"
                                        [items]="listFieldCountryBirthday" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, countryBirthdayTerm, 'countryBirthday', isOpenSelectSecond)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('countryBirthday', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('countryBirthday', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>
                                
                                <!-- formControl nationality and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelNationality }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="nationality"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_nationality')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.nationality.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.nationality, "nationality") }}
                                    </mat-error>
                                </mat-form-field>

                                <div style="width: 262px; height: 55px; padding-top: 5px;">
                                    <!-- formControl countryResidence -->
                                    <span *ngIf="isOpenSelectSecond.countryResidence" class="spanSelectOrigin">Pays de résidence</span>
                                    <ng-select #countryResidenceTerm class="selectCountryResidence" [ngClass]="{'selectOrigin': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2_residencecountryid')  }" formControlName="countryResidence" placeholder="Pays de résidence"
                                        [items]="listFieldCountryResidence" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, countryResidenceTerm, 'countryResidence', isOpenSelectSecond)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('countryResidence', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('countryResidence', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>
        
                                <!-- formControl numCardDay and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLargeNumCardDay': data.size >= 2, 'formFieldCustomOneLargeNumCardDaySM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelNumCardDay }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="numCardDay"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_residencepermitnumber')">lock</mat-icon>
                                </mat-form-field>

                                <!-- formControl periodicityValidity and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLarge': data.size >= 2, 'formFieldCustomOneLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelPeriodicityValidity }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="periodicityValidity"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_residencepermitperioddesc')">lock</mat-icon>
                                </mat-form-field>

                                <div style="width: 100%;"></div>

                                <div style="display: flex;">
                                    <!-- <mat-slide-toggle class="toggleRecall" [readonly]="addToggleRecall(element.dataRecall.recall, element.projectId)" [ngClass]="{'toggleRecallTrue': toggleRecall[element.projectId], 'toggleRecallFalse': !toggleRecall[element.projectId]}" [(ngModel)]="toggleRecall[element.projectId]" (change)="openPopupRecall(toggleRecall[element.projectId], element.dataRecall)">À rappeler</mat-slide-toggle> -->
                                    <mat-slide-toggle class="toggleRecall" [ngClass]="{'toggleRecallFalse': !insertContactSecondProjectForm.value.toggleCoBooking && data.size >= 2, 'toggleRecallFalseSM': !insertContactSecondProjectForm.value.toggleCoBooking && data.size < 2, 'toggleRecallTrue': insertContactSecondProjectForm.value.toggleCoBooking && data.size >= 2, 'toggleRecallTrueSM': insertContactSecondProjectForm.value.toggleCoBooking && data.size < 2 }" formControlName="toggleCoBooking">Le contact secondaire est co-signataire :&nbsp;&nbsp;<span *ngIf="insertContactSecondProjectForm.value.toggleCoBooking" class="valueToggleBooking">OUI</span><span *ngIf="!insertContactSecondProjectForm.value.toggleCoBooking" class="valueToggleBooking">NON</span></mat-slide-toggle>
                                </div>
                            </div>
                        </div>

<!-------------------------------------------------------------- Contact secondaire => Coordonnées ------------------------------------------------------------------------------>

                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            
                            <div class="d-flex align-items-baseline flex-wrap">
                                <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Coordonnées</span>
                                <!-- <mat-checkbox class="checkbox_DuplicateDate ml-2" formControlName="checkedCoordination" (change)="eventChangeDuplicateData($event, 'Coordination')">Adresse identique au contact principal</mat-checkbox> -->
                                <button class="ml-2 btnDuplicateData" (click)="eventChangeDuplicateData('Coordination')">Copié coordonnées contact principal</button>
                            </div>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">
                                <!-- formControl mobilePhone and error -->
                                <mat-form-field [ngClass]="{'formFieldCompletedProjectPhoneTwo': data.size >= 2, 'formFieldCompletedProjectPhoneTwoSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelMobilePhone }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="mobilePhone"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2mobilephone')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.mobilePhone.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.mobilePhone, "mobilePhone") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl phone and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelPhone }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="phone"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2personnalphone')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.phone.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.phone, "phone") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl phonePro and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelPhonePro }}</mat-label>
                                    <input
                                        matInput
                                        formControlName="phonePro"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2professionalphone')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.phonePro.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.phonePro, "phonePro") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl email and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomThree': data.size >= 2, 'formFieldNewProjectLargeCustomThreeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField" [ngClass]="{'errorMessageContactStyle': errorMessageContact}"><span class="errorMessageContactStyle" *ngIf="errorMessageContact">* </span>{{ labelEmail }}</mat-label>
                                    <input type="email"
                                        matInput
                                        formControlName="email"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2email1')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.email.invalid">
                                        {{ getErrorMessageEmail("email") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl emailPro and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmailPro }}</mat-label>
                                    <input type="email"
                                        matInput
                                        formControlName="emailPro"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2email2')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.emailPro.invalid">
                                        {{ getErrorMessageEmail("emailPro") }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelNumAddress }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="numAddress"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_streetnumber')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.numAddress.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.numAddress, "numAddress") }}
                                    </mat-error>
                                </mat-form-field>

                                <div style="height: 51px; width: 262px;">
                                </div>
                                
                                <mat-form-field class="bg-green-field" [ngClass]="{'formFieldNewProjectLarge': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelTypeVoie }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="typeVoie"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_streettype')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.typeVoie.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.typeVoie, "typeVoie") }}
                                    </mat-error>
                                </mat-form-field>

                                <mat-form-field class="bg-green-field" [ngClass]="{'formFieldNewProjectLarge': (data.size > 2) || (data.size < 2), 'formFieldCustomTwoMD': data.size == 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelNameVoie }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="nameVoie"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_streetname')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.nameVoie.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.nameVoie, "nameVoie") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl addressOne and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLargeCityNotPicking disableCity': data.size >= 2, 'formFieldCustomOneLargeCityNotPickingSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelAddressOne }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="addressOne"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_street1')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.addressOne.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.addressOne ,"addressOne") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl addressTwo and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLargeCityNotPicking': data.size >= 2, 'formFieldCustomOneLargeCityNotPickingSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelAddressTwo }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="addressTwo"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_street2')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.addressTwo.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.addressTwo ,"addressTwo") }}
                                    </mat-error>
                                </mat-form-field>
        
                                <!-- formControl zipCode and error -->
                                <mat-form-field  class="bg-green-field" [ngClass]="{'formFieldCustomOneLarge': data.size >= 2, 'formFieldCustomOneLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelZipCode }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="zipCode"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_postalcode')">lock</mat-icon>
                                </mat-form-field>

                                <div>
                                    <!-- formControl city and error with span ngif -->
                                    <span *ngIf="isOpenSelectSecond.city" class="spanSelectOrigin">{{ labelCity }}</span>
                                    <ng-select #cityTerm [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2cityid')}" class="selectCityTwo" formControlName="city" [placeholder]="labelCity"
                                        [items]="listFieldCity" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, cityTerm, 'city', isOpenSelectSecond)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('city', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('city', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                    <!-- <span *ngIf="contactSecondCityNotId !== null">Ville: {{ contactSecondCityNotId }}</span> -->
                                </div>

                                 <!-- formControl cityNotPicking and error -->
                                 <mat-form-field class="bg-green-field" [ngClass]="{'formFieldCustomOneLarge': data.size > 2, 'formFieldCustomOneLargeSM': data.size <= 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelCityNotPicking }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="cityNotPicking"
                                        [readonly]="getDisabledValueTwo()"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2city')">lock</mat-icon>
                                </mat-form-field>

                                <div style="width: 262px; height: 55px; padding-top: 5px;">
                                    <!-- formControl countryResidence -->
                                    <span *ngIf="isOpenSelectSecond.countryResidence" class="spanSelectOrigin">Pays de résidence</span>
                                    <ng-select #countryResidenceTerm class="selectCountryResidence" [ngClass]="{'selectOrigin': data.size < 3 }" formControlName="countryResidence" placeholder="Pays de résidence"
                                        [items]="listFieldCountryResidence" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, countryResidenceTerm, 'countryResidence', isOpenSelectSecond)"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('countryResidence', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('countryResidence', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>    
                    </div>

<!------------------------------------------------------ Contact secondaire => Situation Familiale ------------------------------------------------------------------------------>

                    <div [ngClass]="{'divContainerFieldWithTwoPart': data.size >= 2, 'divContainerFieldWithTwoPartSM': data.size < 2 }">
                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            
                            <div class="d-flex align-items-baseline flex-wrap">
                                <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Situation Familiale</span>
                                <!-- <mat-checkbox class="checkbox_DuplicateDate ml-2" formControlName="checkedFamilyStitutation" (change)="eventChangeDuplicateData($event, 'FamilyStitutation')">Identique au contact principal</mat-checkbox> -->
                                <button class="ml-2 btnDuplicateData" (click)="eventChangeDuplicateData('FamilyStitutation')">Copié situation familiale contact principal</button>
                            </div>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">

                                <div style="height: 55px;">
                                    <!-- formControl familySituation -->
                                    <span *ngIf="isOpenSelectSecond.familySituation" class="spanSelectOrigin">Situation familiale</span>
                                    <ng-select *ngIf="listFieldFamilySituation.length !== 0" [ngClass]="{'selectCustomLarge': data.size >= 2, 'selectCustomLargeSM': data.size < 2,'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2_familysituationid') }" formControlName="familySituation" placeholder="Situation familiale"
                                        [items]="listFieldFamilySituation" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" 
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('familySituation', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('familySituation', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>

                                <!-- Div + formControl familySituationDate with mat date picker -->
                                <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size < 2 }">
                                    <mat-form-field [ngClass]="{ 'formFieldDatePickerLargeCustom': data.size > 2, 'formFieldDatePickerLargeCustomSM': data.size <= 2 }">
                                        <mat-label class="labelInput">{{ labelFamilySituationDate }}</mat-label>
                                        <input matInput readonly [matDatepicker]="picker6" (dateInput)="openFieldStartDate($event, insertContactSecondProjectForm.value.familySituationDate)"  formControlName="familySituationDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_familysituation_date')">lock</mat-icon>
                                        <mat-datepicker #picker6></mat-datepicker>
                                    </mat-form-field>
                                    <button [disabled]="isFieldIsLocked('if_ctc2_familysituation_date')" [ngClass]="{'btnGommeDate': data.size >= 2, 'btnGommeDateSM': data.size < 2}" (click)="resetDate(insertContactSecondProjectForm, 'familySituationDate')"><img class="imgGommeDate" src="../../../../../assets/gomme.svg"></button>
                                </div>

                                 <!-- formControl familySituationLocation and error -->
                                 <mat-form-field [ngClass]="{'formFieldCustomThreeLarge': data.size >= 2, 'formFieldCustomThreeLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelFamilySituationLocation }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="familySituationLocation"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_familysituation_place')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.familySituationLocation.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.familySituationLocation, "familySituationLocation") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl familySituationNotary and error -->
                                <mat-form-field [ngClass]="{'formFieldCustomOneLarge': data.size >= 2, 'formFieldCustomOneLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelFamilySituationNotary }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="familySituationNotary"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_familysituation_notarydesc')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.familySituationNotary.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.familySituationNotary, "familySituationNotary") }}
                                    </mat-error>
                                </mat-form-field>

                                <div style="padding-top: 10px;">
                                    <!-- formControl matrimonialRegime -->
                                    <span *ngIf="isOpenSelectSecond.matrimonialRegime" class="spanSelectOrigin">Régime matrimonial</span>
                                    <ng-select *ngIf="listFieldMatrimonialRegime.length !== 0" class="selectMatrimonialRegime" [ngClass]="{'selectOrigin': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2_matrimonialregimeid')}" formControlName="matrimonialRegime" placeholder="Régime matrimonial"
                                        [items]="listFieldMatrimonialRegime" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('matrimonialRegime', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('matrimonialRegime', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

<!------------------------------------------------------ Contact secondaire => Situation Professionnelle ------------------------------------------------------------------------>

                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectCustomSM': data.size < 2 }">Situation Professionnelle</span>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">

                                <!-- formControl rentMonth and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomTwo': data.size >= 2, 'formFieldNewProjectLargeCustomTwoSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelRentMonth }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="rentMonth"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('qui_ctc2_salaryamount')">lock</mat-icon>
                                    <mat-icon matSuffix>euro</mat-icon>
                                </mat-form-field>

                                <div style="padding-top: 4px;">
                                    <!-- formControl typeRent -->
                                    <span *ngIf="isOpenSelectSecond.typeRent" class="spanSelectOrigin">Type de revenu</span>
                                    <ng-select *ngIf="listFieldTypeRent.length !== 0" [ngClass]="{'selectCustomLarge': data.size > 2, 'selectCustomLargeMD': data.size == 2 , 'selectCustomLargeSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('qui_ctc2_annualincometype') }" formControlName="typeRent" placeholder="Type de revenu"
                                        [items]="listFieldTypeRent" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('typeRent', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('typeRent', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>

                                <div style="padding-top: 5px;">
                                    <!-- formControl category -->
                                    <span *ngIf="isOpenSelectSecond.category" class="spanSelectOrigin">CSP</span>
                                    <ng-select *ngIf="listFieldCategory.length !== 0" [ngClass]="{ 'selectCustomCSP': data.size >= 2, 'selectCustomCSP_SM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2_cspid') }" formControlName="category" placeholder="CSP"
                                        [items]="listFieldCategory" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('category', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('category', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>

                                <!-- formControl profession and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelProfession }}</mat-label>
                                    <input
                                        matInput 
                                        formControlName="profession"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_jobtitle')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.profession.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.profession, "profession") }}
                                    </mat-error>
                                </mat-form-field>

                                <div style="height: 61px; padding-top: 5px;">
                                    <!-- formControl contractWork -->
                                    <span *ngIf="isOpenSelectSecond.contractWork" class="spanSelectOrigin">Contrat de travail</span>
                                    <ng-select *ngIf="listFieldContractWork.length !== 0" [ngClass]="{'selectOriginLarge': data.size >= 2, 'selectOriginLargeSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_ctc2_employmentcontractid')}" formControlName="contractWork" placeholder="Contrat de travail"
                                        [items]="listFieldContractWork" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('contractWork', insertContactSecondProjectForm, isOpenSelectSecond)" (clear)="openSelectAndReset('contractWork', insertContactSecondProjectForm, isOpenSelectSecond)">
                                    </ng-select>
                                </div>

                                <!-- Div + formControl seniority with mat date picker -->
                                <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size <= 2 }">
                                    <mat-form-field [ngClass]="{ 'formFieldDatePickerLarge': data.size > 2, 'formFieldDatePickerLargeSM': data.size <= 2 }">
                                        <mat-label class="labelInput">{{ labelSeniority }}</mat-label>
                                        <input matInput readonly [matDatepicker]="picker11" (dateInput)="openFieldStartDate($event, insertContactSecondProjectForm.value.seniority)" formControlName="seniority">
                                        <mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_professionalsituationsincedate')">lock</mat-icon>
                                        <mat-datepicker #picker11></mat-datepicker>
                                    </mat-form-field>
                                    <button [disabled]="isFieldIsLocked('if_ctc2_professionalsituationsincedate')" [ngClass]="{'btnGommeDate': data.size >= 2, 'btnGommeDateSM': data.size < 2}" (click)="resetDate(insertContactSecondProjectForm, 'seniority')"><img class="imgGommeDate" src="../../../../../assets/gomme.svg"></button>
                                </div>

                                <!-- formControl employerActual and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmployerActual }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="employerActual"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_currentemployer')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.employerActual.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.employerActual, "employerActual") }}
                                    </mat-error>
                                </mat-form-field>

                                <!-- formControl employerActual and error -->
                                <mat-form-field [ngClass]="{'formFieldEmployerAddress': data.size >= 2, 'formFieldEmployerAddress_SM': data.size < 2 }" appearance="fill">
                                    <mat-label class="labelField">{{ labelEmployerAddress }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="employerAddress"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_ctc2_currentemployeraddress')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertContactSecondProjectForm.controls.employerActual.invalid">
                                        {{ getErrorMessageRequired(this.insertContactSecondProjectForm.controls.employerAddress, "employerAddress") }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <!-- Container btn for first form in first stepper -->
                    <div [ngClass]="{'containerBtnStepper': data.size >= 2, 'containerBtnStepperSM': data.size < 2 }">
                        <button [ngClass]="{'btnBackStepper': data.size >= 2, 'btnBackStepperSM': data.size < 2}" mat-button matStepperPrevious>Précédent</button>
                        <button [ngClass]="{'btnCancelStepper': data.size >= 2, 'btnCancelStepperSM': data.size < 2}" mat-button mat-dialog-close>Annuler</button>
                        <button *ngIf="!errorMessageContact" [ngClass]="{'btnFinishStepper': data.size >= 2, 'btnFinishStepperMD': data.size == 2, 'btnFinishStepperSM': data.size < 2}" mat-button (click)="onSubmitCompletedProject('saveAndClose')">Enregistrer et fermer</button>
                        <button [ngClass]="{'btnNextStepper': data.size >= 2, 'btnNextStepperSM': data.size < 2}" mat-button matStepperNext>Suivant</button>
                    </div>
                </form>
            </mat-step>

<!---------------------------------------------------------------------------- THIRD STEPPER ---------------------------------------------------------------------------------->

            <!-- Third stepper with form subscribe -->
            <mat-step [stepControl]="insertFoyerFiscalProjectForm" errorMessage="Tous les champs ne sont pas remplis">
<!----------------------------------------------------------------- Foyer fiscal => Identidé ------------------------------------------------------------------------------>

                <ng-template matStepLabel aria-selected="true">Foyer fiscal</ng-template>

                <div *ngIf="initFormAllStepper" [ngClass]="{'containerSpinnerLoadStepper': data.size >= 2, 'containerSpinnerLoadStepperSM': data.size < 2}">
                    <mat-spinner class="spinnerLoad" [mode]="'indeterminate'"></mat-spinner>
                </div>
                <form *ngIf="!initFormAllStepper" [ngClass]="{'postNewProjectForm': data.size > 2, 'postNewProjectFormMD': data.size == 2, 'postNewProjectFormSM': data.size < 2 }" [formGroup]="insertFoyerFiscalProjectForm">

<!------------------------------------------------------ Foyer fiscal => Situation Fiscal ------------------------------------------------------------------------------>

                    <div [ngClass]="{'divContainerFieldWithTwoPart': data.size >= 2, 'divContainerFieldWithTwoPartSM': data.size < 2 }">
                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Situation Fiscale</span>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">
                                
                                <div [ngClass]="{'containerOneThirdPart': data.size >= 2, 'containerOneThirdPartSM': data.size < 2 }">
                                    <!-- formControl nbPartFiscal and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomThree': data.size >= 2, 'formFieldNewProjectLargeCustomThreeSM': data.size < 2 }" appearance="fill">
                                        <mat-label class="labelField">{{ labelNbPartFiscal }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="nbPartFiscal"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_taxpartsnumber')">lock</mat-icon>
                                        <mat-error *ngIf="this.insertFoyerFiscalProjectForm.controls.nbPartFiscal.invalid">
                                            {{ getErrorMessageRequired(this.insertFoyerFiscalProjectForm.controls.nbPartFiscal, "nbPartFiscal") }}
                                        </mat-error>
                                    </mat-form-field>
    
                                    <div>
                                        <!-- formControl tmi -->    
                                        <span *ngIf="isOpenSelectOther.tmi" class="spanSelectOrigin">TMI</span>
                                        <ng-select *ngIf="listFieldTmi.length !== 0" [ngClass]="{'selectCustomLarge': data.size > 2, 'selectCustomLargeSM': data.size <= 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_tmiid')}" formControlName="tmi" placeholder="TMI"
                                            [items]="listFieldTmi" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('tmi', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('tmi', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
    
                                    <!-- formControl nbPersCharge and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelNbPersCharge }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="nbPersCharge"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_dependentpeoplenumber')">lock</mat-icon>
                                        <mat-error *ngIf="this.insertFoyerFiscalProjectForm.controls.nbPersCharge.invalid">
                                            {{ getErrorMessageRequired(this.insertFoyerFiscalProjectForm.controls.nbPersCharge, "nbPersCharge") }}
                                        </mat-error>
                                    </mat-form-field>
    
                                    <!-- formControl nbChildrenCharge and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelNbChildrenCharge }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="nbChildrenCharge"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_dependentchildsnumber')">lock</mat-icon>
                                        <mat-error *ngIf="this.insertFoyerFiscalProjectForm.controls.nbChildrenCharge.invalid">
                                            {{ getErrorMessageRequired(this.insertFoyerFiscalProjectForm.controls.nbChildrenCharge, "nbChildrenCharge") }}
                                        </mat-error>
                                    </mat-form-field>
    
                                    <!-- formControl nbChildren and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelNbChildren }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="nbChildren"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_childrencount')">lock</mat-icon>
                                        <mat-error *ngIf="this.insertFoyerFiscalProjectForm.controls.nbChildren.invalid">
                                            {{ getErrorMessageRequired(this.insertFoyerFiscalProjectForm.controls.nbChildren, "nbChildren") }}
                                        </mat-error>
                                    </mat-form-field>
     
                                    <!-- formControl yearsChildren and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelYearsChildren }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="yearsChildren"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_childrenages')">lock</mat-icon>
                                        <mat-error *ngIf="this.insertFoyerFiscalProjectForm.controls.yearsChildren.invalid">
                                            {{ getErrorMessageRequired(this.insertFoyerFiscalProjectForm.controls.yearsChildren, "yearsChildren") }}
                                        </mat-error>
                                    </mat-form-field>
    
    
                                </div>
                                <!-- formControl nameChildren and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectLargeNameChildren': data.size > 2, 'formFieldNewProjectLargeNameChildrenSM': data.size <= 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelNameChildren }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="nameChildren"
                                        >
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_childrensnames')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertFoyerFiscalProjectForm.controls.nameChildren.invalid">
                                        {{ getErrorMessageRequired(this.insertFoyerFiscalProjectForm.controls.nameChildren, "nameChildren") }}
                                    </mat-error>
                                </mat-form-field>
                                
                                <div [ngClass]="{'divContainerFieldOneCustomSM': data.size < 2 }">

                                    <!-- formControl taxIncomeRefN and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelTaxIncomeRefN }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="taxIncomeRefN"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_incometaxref_n')">lock</mat-icon>
                                        <mat-icon matSuffix>euro</mat-icon>
                                    </mat-form-field>
                                    
                                    <!-- formControl grossTaxAmount and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelGrossTaxAmount }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="grossTaxAmount"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_incometax_grossamount')">lock</mat-icon>
                                        <mat-icon matSuffix>euro</mat-icon>
                                    </mat-form-field>

                                    <!-- formControl taxIncomeRefN1 and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelTaxIncomeRefN1 }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="taxIncomeRefN1"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_incometaxref_n1')">lock</mat-icon>
                                        <mat-icon matSuffix>euro</mat-icon>
                                    </mat-form-field>
                                    
                                    <!-- formControl paidTaxAmount and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelPaidTaxAmount }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="paidTaxAmount"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_incometax_payableamount')">lock</mat-icon>
                                        <mat-icon matSuffix>euro</mat-icon>
                                    </mat-form-field>
                                    
                                    <!-- formControl taxIncomeRefN2 and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLarge': data.size >= 2, 'formFieldNewProjectLargeSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelTaxIncomeRefN2 }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="taxIncomeRefN2"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_incometaxref_n2')">lock</mat-icon>
                                        <mat-icon matSuffix>euro</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

<!------------------------------------------------------ Foyer fiscal => Situation Patrimoniale ------------------------------------------------------------------------>

                        <div [ngClass]="{'divContainerFieldOne': data.size >= 2, 'divContainerFieldOneCustomSM': data.size < 2 }">
                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Situation Patrimoniale</span>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">
                                
                                <div class="containerSavingConstitued">
                                    <!-- formControl minSavingEffort and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomThree': data.size >= 2, 'formFieldNewProjectLargeCustomThreeSM': data.size < 2 }" appearance="fill">
                                        <mat-label class="labelField">{{ labelMinSavingEffort }}</mat-label>
                                        <input
                                            matInput 
                                            formControlName="minSavingEffort"
                                            >
                                        <mat-icon class="twoMatIcon" matSuffix>euro</mat-icon>
                                        <mat-icon class="twoMatIcon" matSuffix *ngIf="isFieldIsLocked('if_investment_savingscapacitymonthlymin')">lock</mat-icon>
                                    </mat-form-field>
                                </div>
                                
                                <div class="containerSavingConstitued">
                                    <!-- formControl maxSavingEffort and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLargeCustomThree': data.size >= 2, 'formFieldNewProjectLargeCustomThreeSM': data.size < 2 }" appearance="fill">
                                        <mat-label class="labelField">{{ labelMaxSavingEffort }}</mat-label>
                                        <input
                                            matInput 
                                            formControlName="maxSavingEffort"
                                            >
                                        <mat-icon class="twoMatIcon" matSuffix>euro</mat-icon>
                                        <mat-icon class="twoMatIcon" matSuffix *ngIf="isFieldIsLocked('if_investment_savingscapacitymonthlymax')">lock</mat-icon>
                                    </mat-form-field>
                                </div>

                                <div class="divDispobibilityAndEpargne">
                                
                                    <!-- formControl disponibilityMin and error -->
                                    <mat-form-field class="formFieldNewProject" appearance="fill">
                                        <mat-label class="labelField">{{ labelDisponibilityMin }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="disponibilityMin"
                                            >
                                        <mat-icon class="twoMatIcon" matSuffix matSuffix>euro</mat-icon>
                                        <mat-icon class="twoMatIcon" matSuffix *ngIf="isFieldIsLocked('if_investment_availabilityamountmin')">lock</mat-icon>
                                    </mat-form-field>
                                </div>  
    
                                <!-- formControl disponibilityMax and error -->
                                <mat-form-field [ngClass]="{'formFieldNewProjectDisponibilityMax': data.size > 2, 'formFieldNewProjectDisponibilityMaxSM': data.size <= 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelDisponibilityMax }}</mat-label>
                                    <input type="text"
                                        matInput
                                        formControlName="disponibilityMax"
                                        >
                                        <mat-icon class="twoMatIcon" matSuffix matSuffix>euro</mat-icon>
                                        <mat-icon class="twoMatIcon" matSuffix *ngIf="isFieldIsLocked('if_investment_availabilityamountmax')">lock</mat-icon>
                                </mat-form-field>

                                <div style="width: 100%;">
                                    <!-- formControl originDisponibility and error -->
                                    <mat-form-field [ngClass]="{'formFieldNewProjectLargeNameChildren': data.size > 2, 'formFieldNewProjectLargeNameChildrenSM': data.size <= 2}" appearance="fill">
                                       <mat-label class="labelField">{{ labelOriginDisponibility }}</mat-label>
                                       <input type="text"
                                           matInput
                                           formControlName="originDisponibility"
                                           >
                                           <mat-icon matSuffix *ngIf="isFieldIsLocked('if_investment_availabilityorigin')">lock</mat-icon>
                                    </mat-form-field>
                                </div>
                            </div>

                            <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Profil</span>

                            <div [ngClass]="{'containerPartCompletedProjectLarge': data.size >= 2, 'containerPartCompletedProjectLargeSM': data.size < 2 }">

                                <div [ngClass]="{'containerCollumnOneProfil': data.size >= 2 }">
                                    <!-- formControl wishRentability -->
                                    <mat-form-field [ngClass]="{'formFieldCritèreProject': data.size >= 2, 'formFieldCritèreProjectSM': data.size < 2 }" appearance="fill">
                                        <mat-label class="labelField">{{ labelWishRentability }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="wishRentability"
                                            >
                                            <mat-icon matSuffix *ngIf="isFieldIsLocked('if_wishprofitabilitymin')">lock</mat-icon>
                                        <span style="font-size: 14px; margin-right: 7px;" matSuffix>%</span>
                                    </mat-form-field>
    
                                    <!-- formControl acceptWarning -->
                                    <div style="height: 61px; padding-top: 11px;">
                                        <span *ngIf="isOpenSelectOther.acceptWarning" class="spanSelectOrigin">{{ labelAcceptWarning }}</span>
                                        <ng-select *ngIf="listFieldInvestmentRisks.length !== 0" [ngClass]="{'selectCustomLarge': data.size >= 2, 'selectCustomLargeSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('risk_if_fluxsiteb2bid') }" formControlName="acceptWarning" [placeholder]="labelAcceptWarning"
                                            [items]="listFieldInvestmentRisks" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('acceptWarning', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('acceptWarning', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
                                    <!-- formControl acceptWarning -->
                                    <!-- <mat-form-field class="formFieldCritèreProject"  [ngClass]="{'formFieldNewProject': data.size < 3}" appearance="fill">
                                        <mat-label class="labelField">{{ labelAcceptWarning }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="acceptWarning"
                                            >
                                    </mat-form-field> -->

                                    <!-- <div>
                                        <span *ngIf="isOpenSelectOther.profil" class="spanSelectOrigin">Profil</span>
                                        <ng-select *ngIf="listFieldProfil.length !== 0" class="selectImmoProject" formControlName="profil" placeholder="Profil"
                                            [items]="listFieldProfil" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('profil', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('profil', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div> -->
                                </div>

                                <div [ngClass]="{'collumnCritère': data.size >= 2, 'collumnCritèreSM': data.size < 2}">
                                    <!-- formControl goalInvestment1 and error -->
                                    <div style="height: 61px;">
                                        <span *ngIf="isOpenSelectOther.goalInvestment1" class="spanSelectOrigin">Objectif d’investissement 1</span>
                                        <ng-select *ngIf="listFieldGoalInvestment.length !== 0" [ngClass]="{'selectCustomLarge': data.size >= 2, 'selectCustomLargeSM': data.size < 2, 'ngSelect-setLockedIcon': isFieldIsLocked('if_investmentobjective1id') }" formControlName="goalInvestment1" placeholder="Objectif d’investissement 1"
                                            [items]="listFieldGoalInvestment" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('goalInvestment1', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('goalInvestment1', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
                                    
                                    <!-- formControl goalInvestment2 and error -->
                                    <div>
                                        <span *ngIf="isOpenSelectOther.goalInvestment2" class="spanSelectOrigin">Objectif d’investissement 2</span>
                                        <ng-select *ngIf="listFieldGoalInvestment.length !== 0" class="selectCustomOneLargePopup" [ngClass]="{'selectCustomOne': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_investmentobjective2id')  }" formControlName="goalInvestment2" placeholder="Objectif d’investissement 2"
                                            [items]="listFieldGoalInvestment" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('goalInvestment2', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('goalInvestment2', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
    
                                    <!-- formControl goalInvestment3 and error -->
                                    <div>
                                        <span *ngIf="isOpenSelectOther.goalInvestment3" class="spanSelectOrigin">Objectif d’investissement 3</span>
                                        <ng-select *ngIf="listFieldGoalInvestment.length !== 0" class="selectCustomOneLargePopup" [ngClass]="{'selectCustomOne': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_investmentobjective3id')  }" formControlName="goalInvestment3" placeholder="Objectif d’investissement 3"
                                            [items]="listFieldGoalInvestment" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('goalInvestment3', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('goalInvestment3', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
    
                                    <!-- formControl goalInvestment4 and error -->
                                    <div>
                                        <span *ngIf="isOpenSelectOther.goalInvestment4" class="spanSelectOrigin">Objectif d’investissement 4</span>
                                        <ng-select *ngIf="listFieldGoalInvestment.length !== 0" class="selectCustomOneLargePopup" [ngClass]="{'selectCustomOne': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_investmentobjective4id')  }" formControlName="goalInvestment4" placeholder="Objectif d’investissement 4"
                                            [items]="listFieldGoalInvestment" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('goalInvestment4', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('goalInvestment4', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
    
                                    <!-- formControl goalInvestment5 and error -->
                                    <div>
                                        <span *ngIf="isOpenSelectOther.goalInvestment5" class="spanSelectOrigin">Objectif d’investissement 5</span>
                                        <ng-select *ngIf="listFieldGoalInvestment.length !== 0" class="selectCustomOneLargePopup" [ngClass]="{'selectCustomOne': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_investmentobjective5id')  }" formControlName="goalInvestment5" placeholder="Objectif d’investissement 5"
                                            [items]="listFieldGoalInvestment" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('goalInvestment5', insertFoyerFiscalProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('goalInvestment5', insertFoyerFiscalProjectForm, isOpenSelectOther)">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Container btn for first form in first stepper -->
                    <div [ngClass]="{'containerBtnStepper': data.size >= 2, 'containerBtnStepperSM': data.size < 2 }">
                        <button [ngClass]="{'btnBackStepper': data.size >= 2, 'btnBackStepperSM': data.size < 2}" mat-button matStepperPrevious>Précédent</button>
                        <button [ngClass]="{'btnCancelStepper': data.size >= 2, 'btnCancelStepperSM': data.size < 2}" mat-button mat-dialog-close>Annuler</button>
                        <button *ngIf="!errorMessageContact" [ngClass]="{'btnFinishStepper': data.size >= 2, 'btnFinishStepperMD': data.size == 2, 'btnFinishStepperSM': data.size < 2}" mat-button (click)="onSubmitCompletedProject('saveAndClose')">Enregistrer et fermer</button>
                        <button [ngClass]="{'btnNextStepper': data.size >= 2, 'btnNextStepperSM': data.size < 2}" mat-button matStepperNext>Suivant</button>
                    </div>
                </form>
            </mat-step>

    <!------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------>
            
            <!-- Third stepper with form subscribe -->
            <mat-step [stepControl]="insertImmoProjectForm" errorMessage="Tous les champs ne sont pas remplis">
                <ng-template matStepLabel><span class="stepLabelThird">Le projet</span></ng-template>
                <div *ngIf="initFormAllStepper" [ngClass]="{'containerSpinnerLoadStepper': data.size >= 2, 'containerSpinnerLoadStepperSM': data.size < 2}">
                    <mat-spinner class="spinnerLoad" [mode]="'indeterminate'"></mat-spinner>
                </div>
                <form *ngIf="!initFormAllStepper" [ngClass]="{'postNewProjectForm': data.size > 2, 'postNewProjectFormMD': data.size == 2, 'postNewProjectFormSM': data.size < 2 }" [formGroup]="insertImmoProjectForm">
                    
                    <div [ngClass]="{'Flex_Row': data.size > 2, 'Flex_Collumn': data.size < 2, 'Flex_CollumnMD': data.size == 2}">
                        <div [ngClass]="{'containerCommentImmoProject': data.size > 2, 'containerCommentImmoProjectSM': data.size <= 2}">
                            <div [ngClass]="{'containerTitlePart': data.size >= 2, 'containerTitlePartSM': data.size < 2}">
                                <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Commentaire</span>
                            </div>
                            <div [ngClass]="{'collumnCritère': data.size > 2, 'collumnCritèreSM': data.size <= 2}">
                                <!-- formControl comment and error -->
                                <mat-form-field [ngClass]="{'formFieldTextAreaCommentCritèreProject': data.size > 2, 'formFieldTextAreaCommentCritèreProjectMD': data.size == 2, 'formFieldTextAreaCommentCritèreProjectSM': data.size < 2}" appearance="fill">
                                    <mat-label class="labelField">{{ labelComment }}</mat-label>
                                    <textarea class="comment" #txtarea matInput maxlength="15000" formControlName="comment"></textarea>
                                    <mat-icon matSuffix *ngIf="isFieldIsLocked('if_projectcomments')">lock</mat-icon>
                                    <mat-error *ngIf="this.insertImmoProjectForm.controls.comment.invalid">
                                        {{ getErrorMessageRequired(this.insertImmoProjectForm.controls.comment, "comment") }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div [ngClass]="{'containerMartketingImmoProject': data.size >= 2, 'containerMartketingImmoProjectSM': data.size < 2}">
                            <div [ngClass]="{'containerTitlePart': data.size >= 2, 'containerTitlePartSM': data.size < 2}">
                                <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Marketing</span>
                            </div>

                            <div [ngClass]="{'Flex_Row': data.size >= 2, 'Flex_Collumn': data.size < 2}">
                                <!-- formControl origin -->
                                <div [ngClass]="{'containerOriginMkg': data.size >= 2, 'containerOriginMkgSM': data.size < 2}">
                                    <span *ngIf="isOpenSelectOther.origin" class="spanSelectOrigin">Origine Mkg</span>
                                    <ng-select *ngIf="listFieldOrigin.length !== 0" class="selectCustomOneLargePopup" [ngClass]="{'selectCustomOne': data.size < 3,'ngSelect-setLockedIcon': isFieldIsLocked('if_marketingoriginid') }" formControlName="origin" placeholder="Origine Mkg"
                                        [items]="listFieldOrigin" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('origin', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('origin', insertImmoProjectForm, isOpenSelectOther)">
                                    </ng-select>
                                </div> 
    
                                <!-- formControl canal -->
                                <div>
                                    <span *ngIf="isOpenSelectOther.canal" class="spanSelectOrigin">Canal</span>
                                    <ng-select *ngIf="listFieldCanal.length !== 0" class="selectCustomOneLargePopup" [ngClass]="{'selectCustomOne': data.size < 3, 'ngSelect-setLockedIcon': isFieldIsLocked('if_mkg_channelid') }" formControlName="canal" placeholder="Canal"
                                        [items]="listFieldCanal" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('canal', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('canal', insertImmoProjectForm, isOpenSelectOther)">
                                    </ng-select>
                                </div> 
                            </div>

                            <div [ngClass]="{ 'containerFieldPartFinancialImmoSM': data.size < 2,  'containerFinancial': insertImmoProjectForm.value.financial }">
                                <div [ngClass]="{'containerTitlePart': data.size >= 2, 'containerTitlePartSM': data.size < 2}">
                                    <span [ngClass]="{'titleContainerPartCompletedProject': data.size >= 2, 'titleContainerPartCompletedProjectSM': data.size < 2 }">Critères projet</span>
                                </div>

                                <div [ngClass]="{'Flex_Row': data.size >= 2, 'Flex_Collumn': data.size < 2}">

                                    <div [ngClass]="{'containerFieldProjectCriteria': data.size >= 2, 'containerFieldProjectCriteriaSM': data.size < 2 }">
        
                                        <div *ngIf="insertImmoProjectForm.value.immovable" style="height: 55px;">
                                            <!-- formControl situation -->
                                            <span *ngIf="isOpenSelectOther.situation" class="spanSelectOrigin">Situation</span>
                                            <ng-select *ngIf="listFieldSituation.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_customerproperties')}" class="selectProjectStepperThird" formControlName="situation" placeholder="Situation"
                                                [items]="listFieldSituation" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('situation', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('situation', insertImmoProjectForm, isOpenSelectOther)">
                                            </ng-select>
                                        </div>
        
                                        <div [ngClass]="{'containerFieldProjectInvestmentHorizon': data.size >= 2, 'containerFieldProjectInvestmentHorizonSM': data.size < 2 }">
                                            <!-- formControl investmentHorizon -->
                                            <span *ngIf="isOpenSelectOther.investmentHorizon" class="spanSelectOrigin">Horizon d’investissement</span>
                                            <ng-select *ngIf="listFieldInvestmentHorizon.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_purchasetimeframe')}" class="selectProjectStepperThird2" formControlName="investmentHorizon" placeholder="Horizon d’investissement"
                                                [items]="listFieldInvestmentHorizon" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('investmentHorizon', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('investmentHorizon', insertImmoProjectForm, isOpenSelectOther)">
                                            </ng-select>
                                        </div>
        
                                        <!-- formControl budgetMin and error -->
                                        <mat-form-field class="formFieldNewProject" appearance="fill">
                                            <mat-label class="labelField">{{ labelBudgetMin }}</mat-label>
                                            <input type="text"
                                                matInput
                                                formControlName="budgetMin"
                                                >
                                                <mat-icon matSuffix *ngIf="isFieldIsLocked('if_wishbudgetmin')">lock</mat-icon>
                                            <mat-icon matSuffix>euro</mat-icon>
                                        </mat-form-field>
        
                                        <!-- formControl surfaceMin and error -->
                                        <mat-form-field *ngIf="insertImmoProjectForm.value.immovable" class="formFieldNewProject" style="margin-top: 5px;" appearance="fill">
                                            <mat-label class="labelField">{{ labelSurfaceMin }}</mat-label>
                                            <input type="text"
                                                matInput
                                                formControlName="surfaceMin"
                                                >
                                                <mat-icon matSuffix *ngIf="isFieldIsLocked('if_immo_surfacemin')">lock</mat-icon>
                                            <span style="font-size: 14px; margin-right: 7px;" matSuffix>m²</span>
                                        </mat-form-field>

                                        
        
                                        <div *ngIf="insertImmoProjectForm.value.immovable" style="margin-bottom: 10px; margin-top: 10px;">
                                            <!-- formControl typology -->
                                            <span *ngIf="isOpenSelectOther.typology" class="spanSelectOrigin">Types de biens recherchés</span>
                                            <ng-select *ngIf="listFieldTypology.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_assettypeid')}" class="selectProjectStepperThird" formControlName="typology" placeholder="Types de biens recherchés"
                                                [items]="listFieldTypology" bindLabel="name" bindValue="id" [compareWith]="compareWithFnMultiple"
                                                [closeOnSelect]="true" [multiple]="true" [hideSelected]="true" (click)="openSelectAndReset('typology', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('typology', insertImmoProjectForm, isOpenSelectOther)">
                                            </ng-select>
                                        </div>
        
                                    </div>
                                    <div [ngClass]="{'containerFieldProjectCriteria': data.size >= 2, 'containerFieldProjectCriteriaSM': data.size < 2 }">

                                        <div style="height: 55px; padding-top: 9px;" *ngIf="insertImmoProjectForm.value.financial && !insertImmoProjectForm.value.immovable">
                                            <!-- <mat-slide-toggle [ngClass]="{'toggleAdminCompleteFalse': !insertImmoProjectForm.value.adminCompleted, 'toggleAdminCompleteTrue': insertImmoProjectForm.value.adminCompleted }" formControlName="adminCompleted">Administratif complet</mat-slide-toggle>
                                            <div  class="spanSlideToggle">
                                                <span *ngIf="insertImmoProjectForm.value.adminCompleted">Oui</span>
                                                <span style="margin-left: 20px;" *ngIf="!insertImmoProjectForm.value.adminCompleted">Non</span>
                                            </div> -->
                                        </div>

                                        <div *ngIf="insertImmoProjectForm.value.immovable" style="height: 55px;">
                                            <!-- formControl taxSystem -->
                                            <span *ngIf="isOpenSelectOther.taxSystem" class="spanSelectOrigin">Dispositif</span>
                                            <ng-select *ngIf="listFieldTaxSystem.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_immo_taxexemptionid')}" class="selectProjectStepperThird" formControlName="taxSystem" placeholder="Dispositif"
                                                [items]="listFieldTaxSystem" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('taxSystem', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('taxSystem', insertImmoProjectForm, isOpenSelectOther)">
                                            </ng-select>
                                        </div>
        
                                        
        
                                        <div *ngIf="insertImmoProjectForm.value.immovable" style="height: 55px;">
                                            <!-- formControl goal -->
                                            <span *ngIf="isOpenSelectOther.goal" class="spanSelectOrigin">Projet Client</span>
                                            <ng-select *ngIf="listFieldGoal.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_mainsearchprofil')}" class="selectProjectStepperThird" formControlName="goal" placeholder="Projet Client"
                                                [items]="listFieldGoal" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('goal', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('goal', insertImmoProjectForm, isOpenSelectOther)">
                                            </ng-select>
                                        </div>

                                        <!-- formControl budgetMax and error -->
                                        <mat-form-field class="formFieldNewProject" appearance="fill">
                                            <mat-label class="labelField">{{ labelBudgetMax }}</mat-label>
                                            <input type="text"
                                                matInput
                                                formControlName="budgetMax"
                                                >
                                                <mat-icon matSuffix *ngIf="isFieldIsLocked('if_wishbudgetmax')">lock</mat-icon>
                                            <mat-icon matSuffix>euro</mat-icon>
                                        </mat-form-field>

                                        <!-- formControl surfaceMax and error -->
                                        <mat-form-field *ngIf="insertImmoProjectForm.value.immovable" class="formFieldNewProject" style="margin-top: 5px;" appearance="fill">
                                            <mat-label class="labelField">{{ labelSurfaceMax }}</mat-label>
                                            <input type="text"
                                                matInput
                                                formControlName="surfaceMax"
                                                >
                                                <mat-icon matSuffix *ngIf="isFieldIsLocked('if_immo_surfacemax')">lock</mat-icon>
                                            <span style="font-size: 14px; margin-right: 7px;" matSuffix>m²</span>
                                        </mat-form-field>

                                        <div *ngIf="insertImmoProjectForm.value.immovable" style="margin-top: 10px;">
                                            <!-- formControl programCible -->
                                            <span *ngIf="isOpenSelectOther.programCible" class="spanSelectOrigin">Programme ciblé</span>
                                            <ng-select *ngIf="listFieldProgramCible.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_mainoperationid')}" class="selectImmoProject" formControlName="programCible" placeholder="Programme ciblé"
                                                [items]="listFieldProgramCible" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('programCible', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndReset('programCible', insertImmoProjectForm, isOpenSelectOther)">
                                            </ng-select>
                                        </div>
        
                                    </div>
                                </div>
                                <div style="margin-bottom: 20px;" *ngIf="insertImmoProjectForm.value.financial && !insertImmoProjectForm.value.immovable">                                
                                    <!-- formControl product -->
                                    <span *ngIf="isOpenSelectOther.product" class="spanSelectOrigin">Produit</span>
                                    <ng-select *ngIf="listFieldProduct.length !== 0" [ngClass]="{'ngSelect-setLockedIcon': isFieldIsLocked('if_investment_typeid')}" class="selectProjectMultiple" formControlName="product" placeholder="Produit"
                                        [items]="listFieldProduct" bindLabel="name" bindValue="id" [compareWith]="compareWithFnMultiple"
                                        [closeOnSelect]="true" [multiple]="true" [hideSelected]="true" (click)="openSelectAndResetMultiple('product', insertImmoProjectForm, isOpenSelectOther)" (clear)="openSelectAndResetMultiple('product', insertImmoProjectForm, isOpenSelectOther)">
                                    </ng-select>
                                </div>
                                <div>
                                    <!-- formControl needMore and error -->
                                    <mat-form-field [ngClass]="{'formFieldTextAreaNeedMoreCritèreProject': data.size >= 2, 'formFieldTextAreaNeedMoreCritèreProjectSM': data.size < 2}" appearance="fill">
                                        <mat-label class="labelField">{{ labelNeedMore }}</mat-label>
                                        <textarea #txtarea matInput maxlength="1999" formControlName="needMore"></textarea>
                                        <mat-icon matSuffix *ngIf="isFieldIsLocked('if_requirementdescription')">lock</mat-icon>
                                        <mat-error *ngIf="this.insertImmoProjectForm.controls.needMore.invalid">
                                            {{ getErrorMessageRequired(this.insertImmoProjectForm.controls.needMore, "needMore") }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Container btn for third form in third stepper -->
                    <div [ngClass]="{'containerBtnStepper': data.size >= 2, 'containerBtnStepperSM': data.size < 2 }" >
                        <button [ngClass]="{'btnBackStepper': data.size >= 2, 'btnBackStepperSM': data.size < 2}" mat-button matStepperPrevious>Précédent</button>
                        <button [ngClass]="{'btnCancelStepper': data.size >= 2, 'btnCancelStepperCustomSM': data.size < 2}" mat-button mat-dialog-close>Annuler</button>
                        <button *ngIf="!errorMessageContact" [ngClass]="{'btnFinishStepper': data.size > 2, 'btnFinishStepperMD': data.size == 2, 'btnFinishStepperTwoSM': data.size < 2}" mat-button (click)="onSubmitCompletedProject('saveAndClose')">Terminer</button>
                        <!-- <button *ngIf="!errorMessageContact" class="btnFinishAndPostRdvtepper" mat-button (click)="onSubmitCompletedProject('SaveAndRdv')">Terminer et poser un RDV</button> -->
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-dialog-content>
</div>