import { Particularity } from "./particularity";
import { Typology } from "./typology";

export class Operation {
    public "operation.if_operationid"!: string;
    public "operation.if_realactabilitydate"!: string;
    public "operation.if_code"!: string;
    public "operation.if_name"!: string;
    public "operation.if_latitude"!: number;
    public "operation.if_longitude"!: number;
    public "operation.if_deliverydate"!: string;
    public "operation.if_actabilitydate"!: string;
    public "operation.if_address_street1"!: string;
    public "operation.if_address_street2"?: string;
    public "operation.if_pinelplus_operation_calc"!: number;
    public "operation.if_operationbusinessname_calc"!: string;
    public "operation.if_operationprogression_description"!: string;
    public "operation.if_operationprogression_mode"!: number;
    public "operation.if_developeraccountid_label"!: string;
    public "operation.if_completiondate"!: string;
    public "operation.if_shortdescription"!: any;
    public "operation.if_fees_boost"!: number;
    public "operation.if_boosthonorairestaux"!: number;
    public "market.if_additionaldescription1"?: string;
    public "market.if_additionaldescription2"?: string;
    public "market.if_additionaldescription3"?: string;
    public "market.if_additionaldescription4"?: string;
    public "market.if_additionaldescription5"?: string;
    public "market.if_additionaldescription6"?: string;
    public "market.if_additionaldescription7"?: string;
    public "market.if_additionaldescription8"?: string;
    public "market.if_additionaldescription9"?: string;
    public "market.if_additionaldescription10"?: string;
    public "market.if_longdescription_mode"!: number;
    public "market.if_shortdescription_mode"!: number;
    public "market.if_longdescription"?: string;
    public "market.if_shortdescription"?: string;
    public "market.if_title"?: string;
    public "program.if_programdeveloper"!: string;
    public "program.if_programdeveloper_label"!: string;
    public "program.if_programid"!: string;
    public "program.if_code"!: string;
    public "program.if_name"!: string;
    public "city.if_cityid"!: string;
    public "city.if_postalcode"!: string;
    public "city.if_name"!: string;
    public "department.if_departmentid"!: string;
    public "department.if_code"!: string;
    public "department.if_name"!: string;
    public "region.if_regionid"!: string;
    public "region.if_code"!: string;
    public "region.if_name"!: string;
    public "operationtaxlaw_if_fluxsiteb2bcode"?: string;
    public "operationtaxlaw_if_fluxsiteb2blabel"?: string;// Melvin 27.01.2023 : Es ce que ça marche ce champ sans le point ? Dans le doute je duplique le champ avec sa valeur correcte 
    public "operation.taxlaw_if_fluxsiteb2blabel"?: string; // <-- Champ dupliqué.
    public "operationtaxlaw2_if_fluxsiteb2bcode"?: string;
    public "operationtaxlaw2_if_fluxsiteb2blabel"?: string;
    public "operation.taxlaw2_if_fluxsiteb2blabel"?: string; // <-- Champ dupliqué.
    public "saleoffers"!: Array<any>;
    public "SaleOffersCount"!: any;
    public "typologies"!: Typology;
    
    // Champs spécifiques STELLIUM
    public "program.if_valuepoint"!: string;
    public "operation.if_url2"!: string;
    public "operation.taxlaw_if_fluxsiteb2b_picto_filename"!: string; // utilisé chez stellium
    public "operationtaxlaw_if_fluxsiteb2b_picto_filename"!: string; // A confirmer avec Bertrand car il a ajouté ça à la volée.
    public "operation.taxlaw2_if_fluxsiteb2b_picto_filename"!: string; // Idem
    public "operation.if_url1"!: string;
    public "operation.if_visualtext1"!: string;
    public "operation.if_worksite_infosid"!: string;
    public "operation.if_linkedcityid_label"!: string;
    public "operation.if_realdeliverydate"!: string;
    public "qui_relancepromoteur"!: string;
    public "operation_developer.if_city" !: string;
    public "operation_developer.if_name" !: string;
    public "operation_developer.if_phonenumber1" !: string;
    public "operation_developer.if_postalcode" !: string;
    public "operation_developer.if_siret" !: string;
    public "operation_developer.if_street1" !: string;
    public "propertymanager" !: any;


    // Champs spécifiques QUINTESENS
    public "buildingpermit_if_realcompletiondate" !: Date;
    public "operationdatacustom_if_realdatdate" !: Date;
    public "operationdatacustom_if_estimateddatdate" !: Date;
    public "operationdatacustom_if_realcompletiondate" !: Date;
}
