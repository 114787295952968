import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from '../../../../shared/services/snackbar.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { ProjectService } from '../../../../shared/services/project.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupLoadingComponent } from '../popup-loading/popup-loading.component';
import { first, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

// Interface for receive several data in the popup get of parent component
export interface DialogDataPopup {
  namePopup: string;
  dataPopup: any;
  size: number;
}

@Component({
  selector: 'app-popup-convert-project',
  templateUrl: './popup-convert-project.component.html',
  styleUrls: ['./popup-convert-project.component.scss']
})
export class PopupConvertProjectComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  activePlacement: boolean = this.data.namePopup !== 'placement' ? false : true;

  subModuleUse: string;

  // Declaration - public var for mat dialog (completed project, and loading) - private var for form builder - Injectable data mat dialog of parent - root to popup choice
  // Service with root api of all the project
  constructor(
    public popupLoading: MatDialog, private snackbar: SnackbarService, private serviceErrorMessage: ErrorMessageService, 
    private serviceProject: ProjectService, private popupService: PopupServiceService, private serviceConfigObservable: ConfigObservableService,
    public dialogRef: MatDialogRef<PopupConvertProjectComponent>, public dialogRefPopup: MatDialogRef<PopupLoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPopup) {
      this.subModuleUse = environment.subModuleUse;
    }

  // Init - popup rule project if name popup is true
  ngOnInit() {
  }
  
  closeConvertProject() {
    this.dialogRef.close()
  }
  
  // Function submit deduplication contact
  convertProject() {
    try {   
      let dataCloseRecall = {}
      
      if (this.data.namePopup === 'placement') {
        this.openDialogLoading('putProjectPlacement');      
        dataCloseRecall = {
          Fields:[{ Key: "if_projecttype", Value : 750010001 }]}
      } else {
        this.openDialogLoading('putProjectImmobilier');
        dataCloseRecall = {
          Fields:[{ Key: "if_projecttype", Value : 750010000 }]
        }
      }
      this.serviceProject.putProject(dataCloseRecall, this.data.dataPopup.projectId)
        .pipe(first(), takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
          this.popupLoading.closeAll()
          this.serviceConfigObservable.emitRefreshInProgress(true)
          // this.spinnerLoadList['ConvertProject'] = recallValue;
        })
    } catch(e) {
      this.popupLoading.closeAll()
      console.log(e)
    }
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.popupService.openDialogLoading(namePopupLoading, this.data.size, "");
  }

}
