import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { ContactExist } from './../../../../shared/models/contact-exist';
import { convertTZ } from 'src/app/shared/services/functions';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from './../../../../shared/services/snackbar.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { ProjectId } from './../../../../shared/models/project-id';
import { Origin } from './../../../../shared/models/origin';
import { CityService } from './../../../../shared/services/city.service';
import { ProjectService } from './../../../../shared/services/project.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupCompletedProjectComponent } from '../popup-completed-project/popup-completed-project.component';
import { PopupLoadingComponent } from '../popup-loading/popup-loading.component';
import { first, takeUntil } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { CodeConclusionProject } from 'src/app/shared/models/code-conclusion-project';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

// Interface for receive several data in the popup get of parent component
export interface DialogDataPopup {
  namePopup: string;
  dataPopup: any;
  size: number;
}

@Component({
  selector: 'app-popup-new-project',
  templateUrl: './popup-new-project.component.html',
  styleUrls: ['./popup-new-project.component.scss']
})
export class PopupNewProjectComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  subModuleUse: string;

  // Declaration & Init Boolean for activated or not activated label of select of city
  isOpenSelect = {
    city: false,
    origin: false,
    conclusionCode: false
  };

  disabledButton: boolean = false;

  requiredComment: boolean = false;

  // Declaration & init value for the response baf of synchronise for the creation a new project
  valueResponseBafSynchronize: any;

  // Declaration & Init boolean for active or not create project and loading popup
  clickOnCreate: boolean = false;
  // Declaration & Init boolean for active or not create and completed project
  clickOnCreateAndComplete: boolean = false;
  
  // Declaration & Init boolean for active or not message error select origin
  activeMessageErrorSelect: Boolean = false;

  // Declaration & Init boolean for active or not message error checkbox
  activeMessageErrorCheckBox: Boolean = false;
  
  // Declaration & Init boolean for active or not deduplication contact
  activePopupDeduplication: Boolean = false;
  // Declaration & Init boolean for active or not rule project
  activePopupRuleProject: Boolean = false;

  // Declaration & Init Array for list of contact exist get with api ( not api for the moment )
  listContactExist: Array<any> = new Array<any>();
  
  // All label for the created and rule project
  labelFirstName: string = 'Prénom';
  labelLastName: string = 'Nom de famille';
  labelEmail: string = 'Email';
  labelPhone: string = 'Téléphone';
  labelMobilePhone: string = 'Téléphone mobile';
  labelAddressOne: string = 'Adresse 1';
  labelAddressTwo: string = 'Adresse 2';
  labelZipCode: string = 'Code postal';
  labelCity: string = 'Ville';
  labelComment: string = "Commentaire";

  // Boolean for the checkbox in form of create of project
  immovable: Boolean = false;
  financial: Boolean = false;

  // All the array for the list of ng-select in the created and rule project
  listFieldOrigin: Array<any> = [];
  listFieldCity: Array<any> = [];
  listFieldConclusionCode: Array<any> = [];

  // Declaration & Init form group with validator error for the form create new project
  insertNewProjectForm: FormGroup = this.formBuilder.group({
    immovable: [ false, Validators.required ],
    financial: [ false, Validators.required ],
    firstname: [ "", Validators.required ],
    lastname: [ "", Validators.required ],
    email: [ "", [ Validators.required, Validators.email ] ],
    mobilePhone: [ "", [ Validators.required ] ],
    phone: [ "" ],
    addressOne: [ "" ],
    addressTwo: [ "" ],
    zipCode:[ "" ],
    city: [ null ],
    origin: [ null, Validators.required ]
  });

  // Declaration & Init form group with validator error for the form rule project
  insertRuleProjectForm: FormGroup = this.formBuilder.group({
    conclusionCode: [ null, Validators.required ],
    comment: [ "", Validators.required ]
  })

  // Declaration - public var for mat dialog (completed project, and loading) - private var for form builder - Injectable data mat dialog of parent - root to popup choice
  // Service with root api of all the project
  constructor(
    public popupCompletedProject: MatDialog, public popupLoading: MatDialog, private snackbar: SnackbarService, private serviceErrorMessage: ErrorMessageService, 
    private formBuilder: FormBuilder, private serviceProject: ProjectService, private popupService: PopupServiceService,
    private serviceCity: CityService, private serviceConfigObservable: ConfigObservableService,
    public dialogRef: MatDialogRef<PopupNewProjectComponent>, public dialogRefPopup: MatDialogRef<PopupLoadingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPopup) {
      this.subModuleUse = environment.subModuleUse;
    }

  // Init - popup rule project if name popup is true
  ngOnInit() {
    if (this.data.namePopup === 'ruleProject') {
      this.activePopupRuleProject = true;
      // f_cc_project_commentrequired === 1
      // active requiredComment = true
      try {
        this.serviceProject.getProjectCodeConclusions()
          .pipe(first()).subscribe((listCodeConclusion: Array<CodeConclusionProject>) => {
            if(listCodeConclusion.length === 0) {
              this.snackbar.openSnackBar(
                this.serviceErrorMessage.errorMessageProjectListCodeConclusionCustom, ["custom-style-zero-data"]
              );
            }
            listCodeConclusion.forEach((codeConclusion: CodeConclusionProject) => {
              this.listFieldConclusionCode.push({ name: codeConclusion['conclusion.if_fluxsiteb2blabel'] , id: { name: codeConclusion['conclusion.if_fluxsiteb2blabel'] , id: codeConclusion['conclusion.if_conclusioncodeid'], commentRequired: codeConclusion['conclusion.if_cc_project_commentrequired'] } })
            });
        })
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageProjectListCodeConclusion, ["custom-style-error"]
        );
      }
    } else {
      try {
        this.serviceProject.getMarketingOrigins()
          .pipe(first()).subscribe((listOrigin: any) => {
            console.log(origin);
            this.listFieldOrigin = [];
            listOrigin.datas.forEach((origin: Origin) => {
              this.listFieldOrigin.push({ name: origin['origin.if_fluxsiteb2blabel'], id: { name: origin['origin.if_fluxsiteb2blabel'], id: origin['origin.if_marketingoriginid'] } });
            });
          });
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetMarketingOrigins, ["custom-style-error"]
        );
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Compare with for select (compare value observe in select with item.id (show construction array items))
  compareWithFn(item: any, selected: any) {
    return item.id === selected;
  }

  // Method for observe the open of select of city and reset of select of city for activate label or not
  openSelectAndReset(keyUp: any, formValue: any, isOpenSelect: any) {
    if (formValue !== null ) {
      isOpenSelect[keyUp] = true;   
    } else {
      isOpenSelect[keyUp] = false;  
    }
  }

  // Function get error of your form and return string at your view
  getErrorMessageRequired(controlFormSelected: any, nameControl: string) {
    if ( nameControl == "firstName") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Prénom';
      } else {
        return "";
      }
    } else if ( nameControl == "lastName") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Nom';
      } else {
        return "";
      }
    } else if ( nameControl == "zipCode") {
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Code Postal';
      } else {
        return "";
      }
    } else if ( nameControl == "phone") {
      if (controlFormSelected.value.length < 10) {
        return "Votre numéro doit être composé de 10 chiffre";
      } else if (controlFormSelected.getError('pattern')) {
        return "Entrez un Téléphone valide";
      } else {
        return "";
      }
    } else if ( nameControl == "mobilePhone") {
      console.log(controlFormSelected)
      if (controlFormSelected.getError('required')) {
        return 'Entrer votre Téléphone mobile';
      } else if (controlFormSelected.value.length < 10) {
        return "Votre numéro doit être composé de 10 chiffre";
      } else if (controlFormSelected.getError('pattern')) {
        return "Entrez un Téléphone mobile valide";
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  // Function get error email of your form and return string at your view
  getErrorMessageEmail() {
    if (this.insertNewProjectForm.controls.email.getError('required')) {
      return 'Entrer votre Email';
    } else if (this.insertNewProjectForm.controls.email.hasError('email')) {
      return 'Entrer une adresse email valide';
    } else {
      return '';
    }
  }

  // Custom research for city with string of 3 letter min
  customSearchFn(keyUp: any, term: any) {
    if (term.searchTerm !== null) {
      if (term.searchTerm.toLocaleLowerCase().length >= 3) {
        this.serviceCity.getCities(1, 1000, term.searchTerm).pipe(first()).subscribe((cities: any) => {
          this.listFieldCity = [];
          cities.datas.forEach((city: any) => {
            // this.listFieldCity.push({ name: city['city.if_name'] + " (" + city['city.if_postalcode'].substring(0, 2) + ")", id: { name: city['city.if_name'] + " (" + city['city.if_postalcode'].substring(0, 2) + ")", id: city['city.if_cityid']} });
            this.listFieldCity.push({ name: city['city.if_name'] + " (" + city['city.if_postalcode'] + ")", id: { name: city['city.if_name'] + " (" + city['city.if_postalcode'] + ")", id: city['city.if_cityid']} });
          });
        })
        return;
      }
    }
    return;
  }

  // Observe if the user change the value immovable or financial 
  changeValueImmovable(value: any, nameCheckBox: string) {
    if (nameCheckBox === 'immovable') {
      if (value.checked === false && this.insertNewProjectForm.value.financial === false) {
        this.insertNewProjectForm.patchValue({
          financial: true,
          immovable: false
        })
      }
      if (value.checked === true) {
        if (this.insertNewProjectForm.value.financial === true) {
          this.insertNewProjectForm.patchValue({
            financial: false,
            immovable: true
          })
        }
      }
    }
    if (nameCheckBox === 'financial') {
      if (value.checked === false && this.insertNewProjectForm.value.immovable === false) {
        this.insertNewProjectForm.patchValue({
          immovable: true,
          financial: false
        })
      } else if (value.checked === true) {
        if (this.insertNewProjectForm.value.immovable === true) {
          this.insertNewProjectForm.patchValue({
            financial: true,
            immovable: false
          })
        }
      }
    }
  }

  // Function submit form create new project 
  onSubmitCreateProject(clickOnCreateAndComplete: boolean) {
    this.clickOnCreateAndComplete = clickOnCreateAndComplete;
    console.log(this.insertNewProjectForm);
    if (this.insertNewProjectForm.invalid || (this.insertNewProjectForm.value.origin === "" || this.insertNewProjectForm.value.origin === null) || (this.insertNewProjectForm.value.immovable === false && this.insertNewProjectForm.value.financial === false)) {
      if (this.insertNewProjectForm.value.origin === "" || this.insertNewProjectForm.value.origin === null) {
        this.activeMessageErrorSelect = true;
      } else {
        this.activeMessageErrorSelect = false;
      }

      if (this.insertNewProjectForm.value.immovable === false && this.insertNewProjectForm.value.financial === false) {
        this.activeMessageErrorCheckBox = true;
      } else {
        this.activeMessageErrorCheckBox = false;
      }
      // this.clickOnCreate = false; 
      // this.clickOnCreateAndComplete = false;
      return;
    } else {
      
      try {
        this.openDialogLoading('postContactExist');
        let dataContactExist: any;
        if (this.subModuleUse == 'submodule_Quintesens') {
          dataContactExist = {
            "Email": this.insertNewProjectForm.value.email,
          }
        } else if (this.subModuleUse == 'submodule_Quartus') {
          dataContactExist = {
            "Email": this.insertNewProjectForm.value.email,
            "Mobile": this.insertNewProjectForm.value.mobilePhone !== null ? this.insertNewProjectForm.value.mobilePhone : ""
          }
        } else if (this.subModuleUse == 'submodule_Edelis') {
          dataContactExist = {
            "Email": this.insertNewProjectForm.value.email,
            "Mobile": this.insertNewProjectForm.value.mobilePhone !== null ? this.insertNewProjectForm.value.mobilePhone : ""
          }
        } else if (this.subModuleUse == 'submodule_Stellium') {
          dataContactExist = {
            "Email": this.insertNewProjectForm.value.email,
            "Mobile": this.insertNewProjectForm.value.mobilePhone !== null ? this.insertNewProjectForm.value.mobilePhone : ""
          }
        } else if (this.subModuleUse == 'submodule_Inovia') {
          dataContactExist = {
            "Email": this.insertNewProjectForm.value.email,
            "Mobile": this.insertNewProjectForm.value.mobilePhone !== null ? this.insertNewProjectForm.value.mobilePhone : ""
          }
        }
        this.serviceProject.postProjectContactExist(dataContactExist).subscribe((listContactExistReceive: any) => {
          console.log(listContactExistReceive)
          if (listContactExistReceive.length !== 0) {
            this.listContactExist = []; 
            listContactExistReceive.forEach((contactExist: ContactExist, index: number ) => {
              if (index == 0) {
                this.listContactExist.push({ firstname: contactExist['contact.firstname'], lastname: contactExist['contact.lastname'], dateCreate: '', tel: contactExist['contact.telephone1'], mobile: contactExist['contact.mobilephone'], adresse: contactExist['contact.address1_line1'] + ', ' + contactExist['contact.address1_city'], mail: contactExist['contact.emailaddress1'], data: contactExist, checked: true })
              } else {
                this.listContactExist.push({ firstname: contactExist['contact.firstname'], lastname: contactExist['contact.lastname'], dateCreate: '', tel: contactExist['contact.telephone1'], mobile: contactExist['contact.mobilephone'], adresse: contactExist['contact.address1_line1'] + ', ' + contactExist['contact.address1_city'], mail: contactExist['contact.emailaddress1'], data: contactExist, checked: false })
              }  
            });
            this.activePopupDeduplication = true;
            this.serviceConfigObservable.emitClosePopupLoading(true);
          } else {
            let panelClass = 'customDialogCompleteProjectLarge';
            if (this.data.size == 2) {
              panelClass = 'customDialogCompleteProjectLargeMD';
            }
            if ( this.data.size < 3) {
              panelClass = 'customDialogCompleteProject';
            }
            this.openDialogLoading("createNewProject");
            let newData: ProjectId = new ProjectId();
            newData["if_isrealestateproject"] = this.insertNewProjectForm.value.immovable;
            newData["if_isinvestmentproject"] = this.insertNewProjectForm.value.financial;
            newData["if_ctc1lastname"] = this.insertNewProjectForm.value.lastname;
            newData["if_ctc1firstname"] = this.insertNewProjectForm.value.firstname;
            newData["if_ctc1email1"] = this.insertNewProjectForm.value.email;
            newData.marketorg_if_marketingoriginid = this.insertNewProjectForm.value.origin.id; 
    
            let dataPostProject = {
              Fields: [{
                Key: "if_projecttype", Value : this.insertNewProjectForm.value.immovable ? 750010000 : 750010001
              }, {
                Key: "if_ctc1lastname", Value : this.insertNewProjectForm.value.lastname
              }, {
                Key: "if_ctc1firstname", Value : this.insertNewProjectForm.value.firstname
              }, {
                Key: "if_ctc1email1", Value : this.insertNewProjectForm.value.email
              }, { 
                Key: "if_mkg_originid", Value : this.insertNewProjectForm.value.origin.id 
              }]
            }
            
            if (this.insertNewProjectForm.value.mobilePhone !== null) {
              dataPostProject.Fields.push({ Key: "if_ctc1mobilephone", Value : this.insertNewProjectForm.value.mobilePhone })
              newData['if_ctc1mobilephone'] = this.insertNewProjectForm.value.mobilePhone
            }
    
            if (this.insertNewProjectForm.value.phone !== null) {
              dataPostProject.Fields.push({ Key: "if_ctc1personnalphone", Value : this.insertNewProjectForm.value.phone })
              newData['if_ctc1personnalphone'] = this.insertNewProjectForm.value.phone;
            }
    
            if (this.insertNewProjectForm.value.addressOne !== null) {
              dataPostProject.Fields.push({ Key: "if_ctc1_street1", Value : this.insertNewProjectForm.value.addressOne })
              newData['if_ctc1_street1'] = this.insertNewProjectForm.value.addressOne;
            }
    
            if (this.insertNewProjectForm.value.addressTwo !== null) {
              dataPostProject.Fields.push({ Key: "if_ctc1_street2", Value : this.insertNewProjectForm.value.addressTwo })
              newData['if_ctc1_street2'] = this.insertNewProjectForm.value.addressTwo;
            }
    
            if (this.insertNewProjectForm.value.zipCode !== null) {
              dataPostProject.Fields.push({ Key: "if_ctc1_postalcode", Value : this.insertNewProjectForm.value.zipCode })
              newData['if_ctc1_postalcode'] = this.insertNewProjectForm.value.zipCode;
            }
    
            if(this.insertNewProjectForm.value.city !== null) {
              if (this.insertNewProjectForm.value.city.id !== null) {
                dataPostProject.Fields.push({ Key: "if_ctc1cityid", Value : this.insertNewProjectForm.value.city.id })
                newData['if_ctc1city'] = this.insertNewProjectForm.value.city.name.substr(0,this.insertNewProjectForm.value.city.name.length - 5);
                newData['if_ctc1cityid'] = this.insertNewProjectForm.value.city.id;
              }
            }
    
            dataPostProject.Fields.push(this.serviceProject.addCommercialId());
    
            try {
              this.serviceProject.postProject(dataPostProject).subscribe((data: any) => {
                this.openDialogLoading('synchronizedBafProject');
                this.getBafSynchronized(data, panelClass, clickOnCreateAndComplete);
              })
            } catch(e) {
              this.snackbar.openSnackBar(
                this.serviceErrorMessage.errorMessagePostProject, ["custom-style-error"]
              );
              this.popupLoading.closeAll()
            }
          }
        })
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessagePostProject, ["custom-style-error"]
        );
        this.popupLoading.closeAll()
      }

      this.activeMessageErrorSelect = false;
      this.activeMessageErrorCheckBox = true;
    }
  }

  closeAssociateContact() {
    this.insertNewProjectForm.patchValue({
      origin: this.insertNewProjectForm.value.origin
    })
    this.activePopupDeduplication = false;
  }

  // Methode for get if baf is synchronized after have create a new project
  async getBafSynchronized(data: any, panelClass: any, completedProject: boolean) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        this.openDialogLoading('notGetBafSynchronizedProject');
        clearInterval(setIntervalProjectId);
      }

      this.serviceProject.getBafSynchronized(data.datas.ProjectId).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize.datas === true) {
          if (completedProject) {
            this.openCompletedProject(data, panelClass)
            clearInterval(setIntervalProjectId);
          } else {
            localStorage.setItem('IdProjectBafUpdate', data.datas.ProjectId)
            this.serviceConfigObservable.emitRefreshInProgress(true);
            this.popupLoading.closeAll();
            clearInterval(setIntervalProjectId);
          }
        }
      }
      console.log(i)
      i++
    }, 1000)
  }

  // Method for open popup for completed project
  openCompletedProject(data: any, panelClass: any) {
    this.serviceProject.getProjectWithId(data.datas.ProjectId).pipe(first()).subscribe((project: any) => {
      let dataForPopup = { event2: project, projectId: data.datas.ProjectId, ID: project['if_id_lafactory'], if_name: project['if_name'] }
      const dialogRef = this.popupCompletedProject.open(PopupCompletedProjectComponent, {
        width: '100%',
        height: '1300px',
        maxWidth: '150vw',
        data: { namePopup: 'EditContact', dataPopup: dataForPopup, size: this.data.size },
        panelClass
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log(`Close dialog popup form rdv EditContact, Result : ${result}`);
        this.popupCompletedProject.closeAll()
      });
    })
  }


  // Function submit post rule project (Not use for the moment)
  onSubmitPostFormRule(disableButton: boolean) {
    if (this.data.namePopup === 'ruleProject') {
      console.log(this.data.dataPopup.projectId)
      if (this.insertRuleProjectForm.value.conclusionCode !== null) {
        if (this.insertRuleProjectForm.value.conclusionCode.commentRequired !== null) {
          if (this.insertRuleProjectForm.value.conclusionCode.commentRequired === 1) {
            if (this.insertRuleProjectForm.value.comment === "") {
              this.requiredComment = true;
              this.disabledButton = false;
              return
            } else {
              this.requiredComment = false
            }
          }
        }
        this.openDialogLoading("closeProject");
        let newDataClose: any = {} 
        newDataClose["if_closedate"] = new Date().toISOString();
        newDataClose["if_conclusioncodeid"] = this.insertRuleProjectForm.value.conclusionCode.id;
        if (this.insertRuleProjectForm.value.comment !== '') {          
          newDataClose["if_conclusiondesc"] = this.insertRuleProjectForm.value.comment;
        }
        this.serviceProject.closeProject(newDataClose, this.data.dataPopup.projectId)
          .pipe(takeUntil(this.ngUnsubscribe)).subscribe((data:any) => {
            console.log(data)
            this.serviceConfigObservable.emitRefreshInProgress(true);
            this.popupLoading.closeAll()
          }, (error) => {
            this.snackbar.openSnackBar(
              this.serviceErrorMessage.errorMessageProjectCloseProject, ["custom-style-zero-data"]
            );
            this.popupLoading.closeAll()
          })
      }
      this.disabledButton = false;
    } 
    this.disabledButton = false;
  }

  // Observe change contact in mat dialog content deduplication contact 
  changeContactExist(indexListContact: number) {
    console.log('ok')
    for (let i = 0; i < this.listContactExist.length; i++) {
      if (indexListContact === i) {
        this.listContactExist[i].checked = true;
      } else {
        this.listContactExist[i].checked = false;
      }
    }
  }

  // Function submit deduplication contact
  associateContact() {
    this.listContactExist.forEach((contactExist: any) => {
      let panelClass = 'customDialogCompleteProjectLarge';
      if (this.data.size == 2) {
        panelClass = 'customDialogCompleteProjectLargeMD';
      }
      if ( this.data.size < 3) {
        panelClass = 'customDialogCompleteProject';
      }
      if (contactExist.checked) {
        this.openDialogLoading("createNewProject");
        let newData: ProjectId = new ProjectId();
        newData["if_isrealestateproject"] = this.insertNewProjectForm.value.immovable;
        newData["if_isinvestmentproject"] = this.insertNewProjectForm.value.financial;
        newData["if_ctc1lastname"] = contactExist.data['contact.lastname'];
        newData["if_ctc1firstname"] = contactExist.data['contact.firstname'];
        newData["if_ctc1email1"] = contactExist.data['contact.emailaddress1'];
        newData.marketorg_if_marketingoriginid = this.insertNewProjectForm.value.origin.id; 

        let dataPostProject = {
          Fields: [{
            Key: "if_projecttype", Value : this.insertNewProjectForm.value.immovable ? 750010000 : 750010001
          }, {
            Key: "if_ctc1lastname", Value : contactExist.data['contact.lastname']
          }, {
            Key: "if_ctc1firstname", Value : contactExist.data['contact.firstname']
          }, {
            Key: "if_ctc1email1", Value : contactExist.data['contact.emailaddress1']
          }, { 
            Key: "if_mkg_originid", Value : this.insertNewProjectForm.value.origin.id 
          }]
        }
        

        if (contactExist.data['contact.emailaddress2'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1email2", Value : contactExist.data['contact.emailaddress2'] })
          newData['if_ctc1email2'] = contactExist.data['contact.emailaddress2']
        }
        
        if (contactExist.data['contact.mobilephone'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1mobilephone", Value : contactExist.data['contact.mobilephone'] })
          newData['if_ctc1mobilephone'] = contactExist.data['contact.mobilephone']
        }

        if (contactExist.data['contact.telephone1'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1personnalphone", Value : contactExist.data['contact.telephone1'] })
          newData['if_ctc1personnalphone'] = contactExist.data['contact.telephone1'];
        }

        if (contactExist.data['contact.telephone2'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1professionalphone", Value : contactExist.data['contact.telephone2'] })
          newData['if_ctc1professionalphone'] = contactExist.data['contact.telephone2'];
        }

        if (contactExist.data['contact.address1_line1'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_street1", Value : contactExist.data['contact.address1_line1'] })
          newData['if_ctc1_street1'] = contactExist.data['contact.address1_line1'];
        }

        if (contactExist.data['contact.address1_line2'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_street2", Value : contactExist.data['contact.address1_line2'] })
          newData['if_ctc1_street2'] = contactExist.data['contact.address1_line2'];
        }

        if (contactExist.data['contact.address1_postalcode'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_postalcode", Value : contactExist.data['contact.address1_postalcode'] })
          newData['if_ctc1_postalcode'] = contactExist.data['contact.address1_postalcode'];
        }

        if (contactExist.data['contact.if_cityid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1cityid", Value : contactExist.data['contact.if_cityid'] })
          newData['if_ctc1cityid'] = contactExist.data['contact.if_cityid'];
        }

        if (contactExist.data['contact.address1_city'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1city", Value : contactExist.data['contact.address1_city'] })
          newData['if_ctc1city'] = contactExist.data['contact.address1_city'];
        }

        if (contactExist.data['contact.if_salutationid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1salutationid", Value : contactExist.data['contact.if_salutationid'] })
          newData['if_ctc1salutationid'] = contactExist.data['contact.if_salutationid'];
        }

        if (contactExist.data['contact.contactid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_customer1id", Value : contactExist.data['contact.contactid'] })
        }

        if (contactExist.data['contact.if_maidenname'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_maidenname", Value : contactExist.data['contact.if_maidenname'] })
          newData['if_ctc1_maidenname'] = contactExist.data['contact.if_maidenname'];
        }

        if (contactExist.data['contact.birthdate'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1birthday", Value : contactExist.data['contact.birthdate'] })
          newData['if_ctc1birthday'] = contactExist.data['contact.birthdate'];
        }

        if (contactExist.data['contact.if_birthplace'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_birthplace", Value : contactExist.data['contact.if_birthplace'] })
          newData['if_ctc1_birthplace'] = contactExist.data['contact.if_birthplace'];
        }

        if (contactExist.data['contact.if_birthcountryid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_birthcountryid", Value : contactExist.data['contact.if_birthcountryid'] })
          newData['if_ctc1_birthcountryid'] = contactExist.data['contact.if_birthcountryid'];
        }

        if (contactExist.data['contact.if_nationality'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_nationality", Value : contactExist.data['contact.if_nationality'] })
          newData['if_ctc1_nationality'] = contactExist.data['contact.if_nationality'];
        }

        if (contactExist.data['contact.if_familysituation'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_familysituationid", Value : contactExist.data['contact.if_familysituation'] })
          newData['if_ctc1_familysituationid'] = contactExist.data['contact.if_familysituation'];
        }

        if (contactExist.data['contact.if_matrimonialregimeid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_matrimonialregimeid", Value : contactExist.data['contact.if_matrimonialregimeid'] })
          newData['if_ctc1_matrimonialregimeid'] = contactExist.data['contact.if_matrimonialregimeid'];
        }

        if (contactExist.data['contact.if_familysituation_place'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_familysituation_place", Value : contactExist.data['contact.if_familysituation_place'] })
          newData['if_ctc1_familysituation_place'] = contactExist.data['contact.if_familysituation_place'];
        }

        if (contactExist.data['contact.if_familysituation_notarydesc'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_familysituation_notarydesc", Value : contactExist.data['contact.if_familysituation_notarydesc'] })
          newData['if_ctc1_familysituation_notarydesc'] = contactExist.data['contact.if_familysituation_notarydesc'];
        }

        if (contactExist.data['contact.numberofchildren'] !== null) {
          dataPostProject.Fields.push({ Key: "if_childrencount", Value : contactExist.data['contact.numberofchildren'] })
          newData['if_childrencount'] = contactExist.data['contact.numberofchildren'];
        }

        if (contactExist.data['contact.childrensnames'] !== null) {
          dataPostProject.Fields.push({ Key: "if_childrensnames", Value : contactExist.data['contact.childrensnames'] })
          newData['if_childrensnames'] = contactExist.data['contact.childrensnames'];
        }

        if (contactExist.data['contact.if_childrenages'] !== null) {
          dataPostProject.Fields.push({ Key: "if_childrenages", Value : contactExist.data['contact.if_childrenages'] })
          newData['if_childrenages'] = contactExist.data['contact.if_childrenages'];
        }

        if (contactExist.data['contact.if_childrencountdesc'] !== null) {
          dataPostProject.Fields.push({ Key: "if_childrencountdesc", Value : contactExist.data['contact.if_childrencountdesc'] })
          newData['if_dependentchildsnumber'] = contactExist.data['contact.if_childrencountdesc'];
        }

        if (contactExist.data['contact.if_residencecountryid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_residencecountryid", Value : contactExist.data['contact.if_residencecountryid'] })
          newData['if_ctc1_residencecountryid'] = contactExist.data['contact.if_residencecountryid'];
        }

        if (contactExist.data['contact.if_residencepermitnumber'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_residencepermitnumber", Value : contactExist.data['contact.if_residencepermitnumber'] })
          newData['if_ctc1_residencepermitnumber'] = contactExist.data['contact.if_residencepermitnumber'];
        }

        if (contactExist.data['contact.if_residencepermitperioddesc'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_residencepermitperioddesc", Value : contactExist.data['contact.if_residencepermitperioddesc'] })
          newData['if_ctc1_residencepermitperioddesc'] = contactExist.data['contact.if_residencepermitperioddesc'];
        }

        if (contactExist.data['contact.jobtitle'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_jobtitle", Value : contactExist.data['contact.jobtitle'] })
          newData['if_ctc1_jobtitle'] = contactExist.data['contact.jobtitle'];
        }

        if (contactExist.data['contact.if_employer'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_currentemployer", Value : contactExist.data['contact.if_employer'] })
          newData['if_ctc1_currentemployer'] = contactExist.data['contact.if_employer'];
        }

        if (contactExist.data['contact.if_currentemployeraddress'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_currentemployeraddress", Value : contactExist.data['contact.if_currentemployeraddress'] })
          newData['if_ctc1_currentemployeraddress'] = contactExist.data['contact.if_currentemployeraddress'];
        }

        if (contactExist.data['contact.if_jobentrydate'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_professionalsituationsincedate", Value : contactExist.data['contact.if_jobentrydate'] })
          newData['if_ctc1_professionalsituationsincedate'] = contactExist.data['contact.if_jobentrydate'];
        }

        if (contactExist.data['contact.if_csp'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_cspid", Value : contactExist.data['contact.if_csp'] })
          newData['if_ctc1_cspid'] = contactExist.data['contact.if_csp'];
        }

        if (contactExist.data['contact.if_employmentcontractid'] !== null) {
          dataPostProject.Fields.push({ Key: "if_ctc1_employmentcontractid", Value : contactExist.data['contact.if_employmentcontractid'] })
          newData['if_ctc1_employmentcontractid'] = contactExist.data['contact.if_employmentcontractid'];
        }


        dataPostProject.Fields.push(this.serviceProject.addCommercialId());
        
        try {
          this.serviceProject.postProject(dataPostProject).subscribe((data: any) => {
            this.openDialogLoading('synchronizedBafProject');
            
            this.getBafSynchronized(data, panelClass, this.clickOnCreateAndComplete);
          })
        } catch(e) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessagePostProject, ["custom-style-error"]
          );
          this.popupLoading.closeAll()
        }
      }
    })

    this.activeMessageErrorSelect = false;
    this.activeMessageErrorCheckBox = true;
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.popupService.openDialogLoading(namePopupLoading, this.data.size, "");
  }

}
