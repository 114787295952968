import { DocumentService } from './../../../../shared/services/document.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PdfService } from 'src/app/shared/services/pdf.service';
import { DomSanitizer } from '@angular/platform-browser';

// import {
//   LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
//   ToolbarService, NavigationService, AnnotationService, TextSearchService, TextSelectionService, PrintService
// } from '@syncfusion/ej2-angular-pdfviewer';

declare const PDFObject: any;

// Interface for send several data in the popup for the pdf
export interface DialogDataPdf {
  pdf: any;
  namePdf: string;
  id?: string;
  image: string;
  size: number;
} 

@Component({
  selector: 'app-popup-plan-viewer',
  templateUrl: './popup-plan-viewer.component.html',
  styleUrls: ['./popup-plan-viewer.component.scss'],
  // providers: [
  //   LinkAnnotationService, BookmarkViewService, MagnificationService, ThumbnailViewService,
  //   ToolbarService, NavigationService, AnnotationService, TextSearchService, TextSelectionService, PrintService
  // ]
})
export class PopupPlanViewerComponent implements OnInit {

  // public service = 'https://ej2services.syncfusion.com/production/web-services/api/pdfviewer';
  // public document = 'PDF_Succinctly.pdf';

  zoom_to: number = 1;
  activeOriginalSize: boolean = true;

  srcPdf = this.data.pdf

  filenamePdf = this.data.namePdf

  // pdfData: any = null;
  // pdfUrl: any = '';
  // isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<PopupPlanViewerComponent>, public serviceConfigObservable: ConfigObservableService, public popupLoading: MatDialog,
    private documentService: DocumentService, private pdfService: PdfService, public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPdf) {}

    ngOnInit(): void {

    }

  // ngOnInit(): void {
    // setTimeout(() => {
    //   var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
    //   var reader = new FileReader();
    //   reader.readAsDataURL(this.data.pdf); 
    //   reader.onloadend = function() {
    //     var base64data = reader.result;
    //     viewer.load(base64data)
    //   }
    // }, 2000)

  //   console.log(this.data)
  //   if ( this.data.size < 2) {
  //     this.activeOriginalSize = false;
  //     this.serviceConfigObservable.emitOverflowHidden(true);
  //   }
  // }

  // /* Download the document selected */
  // downloadDocument() {
  //   this.documentService.getDownloadDocumentWithId(this.data.id, this.data.namePdf)
  // }

  // zoom_in() {
  //   // this.zoom_to = this.zoom_to + 0.25;
  //   let listContainerImg = document.getElementsByClassName('imagePlanLot') as HTMLCollectionOf<HTMLElement>;
  //   if ( listContainerImg[0] !== undefined) {
  //     this.zoom_to = this.zoom_to + 0.25;
  //     listContainerImg[0].style.transform = "scale(" + this.zoom_to + ")";
  //   }
  // }

  // zoom_out() {
  //   let listContainerImg = document.getElementsByClassName('imagePlanLot') as HTMLCollectionOf<HTMLElement>;
  //   if (this.zoom_to > 1 && this.data.size > 2) {
  //     if ( listContainerImg[0] !== undefined) {
  //       this.zoom_to = this.zoom_to - 0.25;
  //       listContainerImg[0].style.transform = "scale(" + this.zoom_to + ")";
  //     }
  //   } else {
  //     if ( listContainerImg[0] !== undefined) {
  //       this.zoom_to = this.zoom_to - 0.25;
  //       listContainerImg[0].style.transform = "scale(" + this.zoom_to + ")";
  //     }
  //   }
  // }

  // zoomin(){
  //   let listContainerMap = document.getElementById("map") as HTMLElement;
  //   let currWidth = null
  //   if ( listContainerMap !== undefined) {
  //       currWidth = listContainerMap.clientWidth;    
  //       if (currWidth !== null) {
  //         listContainerMap.style.width = (currWidth + 100) + "px";
  //       }
  //   }
  // }

  // zoomout() {
  //   let listContainerMap = document.getElementById("map") as HTMLElement;
  //   let currWidth = null
  //   if ( listContainerMap !== undefined) {
  //     currWidth = listContainerMap.clientWidth;
  //     if (currWidth == 100) {
        
  //     } else{
  //       listContainerMap.style.width = (currWidth - 100) + "px";
  //     }
  //   }
  // }





















  // this.isLoading = true;
  //     // var reader = new FileReader();
  //     // reader.readAsDataURL(this.data.pdf); 
  //     // var base64data: any;
  //     // reader.onloadend = function() {
  //     //   base64data = reader.result;
  //     // }

  //     // setTimeout(() => {
  //     //   // var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //     //   var reader = new FileReader();
  //     //   reader.readAsDataURL(this.data.pdf); 
  //     //   reader.onloadend = function() {
  //     //     var base64data = reader.result;
  //     //     PDFObject.embed(base64data, '#pdfContainer');
  //     //   }
  //     // }, 2000)


  //     this.pdfUrl = window.URL.createObjectURL(new Blob([this.data.pdf], {type: 'application/pdf'}));
  //     this.pdfData = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl)
      
  //     // this.handleRenderPdf(this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrl))
  //     // i.e. display the PDF content via iframe
  //     // let iframe = document.querySelector("iframe");
  //     // if (iframe !== null) {
  //     //   iframe.src = this.pdfUrl;
  //     // }

  //     // setTimeout(() => {
  //     //   // var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
  //     //   var reader = new FileReader();
  //     //   reader.readAsDataURL(new Blob([this.data.pdf], {type: 'application/pdf'})); 
  //     //   reader.onloadend = function() {
  //     //     var base64data = reader.result;
  //     //     PDFObject.embed(base64data, '#pdfContainer');
  //     //   }
  //     // }, 2000)

  //     // this.getBase64().then(
  //     //   (response: any) => {
  //     //     // response.data -> response data base64 encoded
  //     //     // decoding the data via atob()
  //     //     // const byteArray = new Uint8Array(atob(response).split('').map(char => char.charCodeAt(0)));
  //     //     return new Blob([this.data.pdf], {type: 'application/pdf'});
  //     //   })
  //     // .then(blob => {
  //     //   // Here is your URL you can use
  //     //   const url = window.URL.createObjectURL(new Blob([this.data.pdf], {type: 'application/pdf'}));
    
  //     //   // i.e. display the PDF content via iframe
  //     //   let iframe = document.querySelector("iframe");
  //     //   if (iframe !== null) {
  //     //     iframe.src = url;
  //     //   }
  //     // });

  //     // this.getBase64().then(
  //     //   data => {
  //     //     this.pdfService.getPdf(data).subscribe({
  //     //       next: (res: any) => {
  //     //         this.pdfData = res;
  //     //         console.log(this.pdfData);
  //     //         this.isLoading = false;
  //     //         if (this.pdfData) {
  //     //           this.handleRenderPdf(this.pdfData);
  //     //         }
  //     //       },
  //     //     });
  //     //   }
  //     // );
  //   }

  //   getBase64() {
  //     return new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(this.data.pdf);
  //       reader.onloadend = () => resolve(reader.result);
  //       reader.onerror = error => reject(error);
  //     });
  //   }

  //   // getBase64() {
  //   //   return new Promise((resolve, reject) => {
  //   //     const reader = new FileReader();
  //   //     reader.readAsBinaryString(this.data.pdf);
  //   //     reader.onloadend = (e: any) => resolve(window.btoa(e.target.result))
  //   //     reader.onerror = error => reject(error);
  //   //   });
  //   // }

  //   handleRenderPdf(data: any) {
  //     const pdfObject = PDFObject.embed(data, '#pdfContainer');
  //   }
}