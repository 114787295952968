import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from './../../../../shared/services/snackbar.service';
import { ParameterGestOption } from './../../../../shared/models/parameter-gest-option';
import { PropositionService } from './../../../../shared/services/proposition.service';
import { GestOptionService } from './../../../../shared/services/gest-option.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Asset } from './../../../../shared/models/asset';
import { OperationService } from './../../../../shared/services/operation.service';
import { environment, myService } from './../../../../../environments/environment';
import { ResearchService } from 'src/app/shared/services/research.service';
import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { PopupNewProjectComponent } from '../popup-new-project/popup-new-project.component';
import { first } from 'rxjs/operators';
import { OperationCard } from 'src/app/shared/models/operation-card';
import { convertTZ, getDateLabelSample, transformDateFormatHoursPipe } from 'src/app/shared/services/functions';
import { BooleanInput } from '@angular/cdk/coercion';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ReferentialProjectInProgress } from 'src/app/shared/models/referential-project-in-progress';
import { ReferentialProjectOption } from 'src/app/shared/models/referential-project-option';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// Interface for receive several data in the popup get of parent component
export interface DialogDataPopup {
  namePopup: string;
  dataPopup: any;
  size: number;
}

// Interface for list of bank groups for filtered by search keyword for option groups
interface BankData {
  name: string;
  id: {
    name: string;
    id: string;
  }
}

@Component({
  selector: 'app-popup-search-save',
  templateUrl: './popup-search-save.component.html',
  styleUrls: ['./popup-search-save.component.scss']
})
export class PopupSearchSaveComponent implements OnInit {

  subModuleUse: string;

  // Observe and send date option change value
  @Output() dateChange: EventEmitter< MatDatepickerInputEvent< any>> = new EventEmitter< MatDatepickerInputEvent< any>>();

  // Declaration & Init map marker for get all operation
  // initMapMarker: any = { TopLeft:{ Latitude: 51.49506473014368, Longitude: -5.9326171875 }, BottomRight: { Latitude: 41.73852846935917, Longitude: 9.074707031250002 }};

  // Declaration & Init array asset active get in operation selected
  tabAssetActive: Array<any> = [];

  // Declaration for receive value baf synchronized true or false
  valueResponseBafSynchronize: any;

  // Declaration & Init asset status, if name popup is post option lot or cancel option, init with data status get of parents else init at null 
  statusAsset: string = this.data.namePopup === 'postOptionLot' ? this.data.dataPopup.status : null;

  // Declaration & Init asset rank, if name popup is post option lot or cancel option, init with data rang get of parents else init at null
  rangAsset: string = this.data.namePopup === 'postOptionLot' ? this.data.dataPopup.rang : null;

  // Declaration & Init date of dinibility for it's asset, if name popup is post option lot or cancel option, init with data start option get of parents else init at null
  dateDispoAsset: string = this.data.namePopup === 'postOptionLot' ? this.data.dataPopup.startOptionData !== null ? this.data.dataPopup.startOptionData.getDate().toString() + "/" + (this.data.dataPopup.startOptionData.getMonth() + 1).toString() + "/" + this.data.dataPopup.startOptionData.getFullYear().toString() + " " + transformDateFormatHoursPipe(this.data.dataPopup.startOptionData).toString() : new Date().getDate().toString() + "/" + ( new Date().getMonth() + 1 ).toString() + "/" + new Date().getFullYear().toString() + " " + transformDateFormatHoursPipe(new Date()).toString() : ' - ';

  // Declaration & Init is validation option always at true
  isValidationOption: BooleanInput = true;

  // Declaration & Init for hidden span html of rank of the asset bind
  hiddenRank: Boolean = true;

  // Declaration & Init label for date of rdv
  labelRdvDate: string = 'Date du RDV';

  // Declaration & Init Array of name program for mat-select all time disable (show for change all methode)
  tabOptionResidence: Array<BankData> = new Array<BankData>();
  // Declaration & Init Array of name asset for mat-select all time disable (show for change all methode)
  tabOptionNameLot: Array<BankData> = new Array<BankData>();

  // Declaration & Init boolean for active or not button add project
  activeEnv: Boolean = environment.activeBtnNewProjectInPostOption;

  // Declaration & Init Array for get data of mock-up (Time API)
  tabProgram: Array<any> = new Array<any>();

  // Declaration & Init Array of all your research save 
  tabSearchSave: Array<any> = new Array<any>();
  
  // Declaration & Init start date option for cancel option get of parents else return string empty
  startDateOption: string = this.data.namePopup === 'cancelOption' ? this.data.dataPopup.startOption : "";
  // Declaration & Init end date option for cancel option get of parents else return string empty
  endDateOption: string = this.data.namePopup === 'cancelOption' ? this.data.dataPopup.endOption : "";

  // Declaration & Init date rdv option not modify possible
  dateRdvOption: Date = new Date('02/05/2021');

  // Declaration & Init name asset option for post or cancel option get of parents else return null
  nameLot: string = this.data.namePopup === 'postOptionLot' ? this.data.dataPopup.nameLot : null;
  // Declaration & Init name program for post or cancel option get of parents else return null
  nameProgram: string = this.data.namePopup === 'postOptionLot' ? this.data.dataPopup.nameResidence : null;

  // Declaration & Init value code for ng-select name client
  nameClient: any = null;

  // Declaration & Init Array code for ng-select code name client
  toppingList: Array<any> = [];

  // Declaration & Init Array code for ng-select disable
  toppingListNameLot: Array<string> = [this.nameLot];
  toppingListLot: Array<string> = [this.nameProgram];

  // Declaration & Init value code for ng-select code conclusion
  code: string = "EJ59BQ";

  // Declaration & Init Array code for ng-select code conclusion
  toppingListCode: Array<string> = ["EJ59BQ","EJ59BM","EJ59BD"];

  // Declaration & Init value for disable or not ng-select
  isDisable: boolean = true;
  isDisableAsset: boolean = true;

  // Declaration & Init value for refresh tablehousing research save 
  refresh: boolean = true;

  priceTTC: string = "";
  listFieldConclusionCode: Array<any> = [];

  errorMessageRank: Boolean = false;
  notProjectFind: Boolean = false;
  errorMessageDispoOne: Boolean = false;
  errorMessageDispoTwo: Boolean = false;
  errorMessageRuleTwo: Boolean = false;

  messageNotProject: string = "";
  dateDispoAssetCustom: string = this.data.namePopup === 'postOptionLot' ? this.data.dataPopup.startOption !== null ? this.data.dataPopup.startOption : new Date() : this.data.dataPopup.startOption;

  isOpenSelect = {
    nameClient: false,
    conclusionCode: false
  }

  delayDayOptionOne: number = 0;
  delayDayOptionTwo: number = 0;

  // Declaration & Init form group with validator error for the form create post option
  insertSearchForm: FormGroup = this.formBuilder.group({
    nameClient: [ this.nameClient, Validators.required ],
    nameProgram: [ this.nameProgram, Validators.required ],
    nameLot: [ this.nameLot, Validators.required ],
    dateOption: [ { value: this.dateRdvOption, disabled: true } ],
    code: [ "", Validators.required ],
    comment: [ "", Validators.required ]
  })

  private serviceRulePostOptionSubModule: any = null;

  // Declaration - public var for mat dialog - private var for form builder - private service for observe and receive data 
  // - private service research for receive data - private service operation for receive data - private service asset for receive data
  // - Injectable data mat dialog of parent - root to popup choice
  constructor(
    public popupNewProject: MatDialog, private formBuilder: FormBuilder, private serviceOperation: OperationService, private router: Router,
    private serviceConfigObservable: ConfigObservableService, private serviceResearch: ResearchService, public popupLoading: MatDialog,
    private snackbar: SnackbarService, private serviceErrorMessage: ErrorMessageService, private popupService: PopupServiceService,
    private serviceProject: ProjectService, private serviceGestOption: GestOptionService, private serviceProposition: PropositionService,
    public dialogRef: MatDialogRef<PopupSearchSaveComponent>, private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPopup) {
      this.subModuleUse = environment.subModuleUse;

      this.serviceRulePostOptionSubModule = this.injector.get(myService.serviceRulePostOption); 
    }

  // Init next to name popup get of parent : 
  // Init research save with data get with parents - Init post option asset (alone form group) - Init post option project - Init cancel option
  ngOnInit() {
    if (this.data.namePopup === 'saveSearch') {
      this.data.dataPopup.datas.forEach((searchSave: any) => {
        let newObjectSearchSave = {}; 
        // Object.assign(newObjectSearchSave, jsonParseDataSave);
        if (searchSave.Description !== null) {
          let jsonParseDataSave = JSON.parse(searchSave.Description)
          for (const fieldSearchSave in jsonParseDataSave) {
            Object.assign(newObjectSearchSave, { [fieldSearchSave]: JSON.parse(jsonParseDataSave[fieldSearchSave]) });
          }
        } else {
          Object.assign(newObjectSearchSave, {});
        }
        Object.assign(newObjectSearchSave, { Title: searchSave.Title, SearchId: searchSave.SearchId });
        this.tabSearchSave.push(newObjectSearchSave);
      });
      console.log(this.tabSearchSave);
    } else if ( this.data.namePopup === 'postOptionLot' ) {
      // this.serviceConfigObservable.emitOverflowHidden(true);
      this.serviceGestOption.getParametersOption().subscribe((parameters: ParameterGestOption) => {
        this.delayDayOptionOne = Math.round(( parameters.if_proposition_delay1 / 60 ) / 24);
        this.delayDayOptionTwo = Math.round(( parameters.if_proposition_delay2 / 60 ) / 24);

        this.serviceProject.getReferentialProjectOption(this.data.dataPopup.idLot).pipe(first()).subscribe((listProject:any) => {
          this.toppingList = [];
          if (listProject.status === 3) {
            this.messageNotProject = listProject.message;
            this.notProjectFind = true;
          } else {
            listProject.datas.forEach((project: ReferentialProjectOption) => {
              // scheduledstart !== null pour plus-tard
              this.toppingList.push({ name: project['project.if_name'], id: { name: project['project.if_name'], id: project['project.if_projectid'], shelduleStart: project['rdv3.scheduledstart'], idRdv3: project['rdv3.activityid'] } })
            });
          }
          // this.notProjectFind = true;
        }, (error) => {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetReferentialProjectOption, ["custom-style-error"]
          );
        });
      }, (error) => {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetParametersOption, ["custom-style-error"]
        );
      });

      this.nameClient = null;
      this.hiddenRank = this.statusAsset.search('Disponible') === -1 ? true : false;

      this.insertSearchForm = this.formBuilder.group({
        nameClient: [ this.nameClient, Validators.required ],
        nameProgram: [ this.nameProgram, Validators.required ],
        nameLot: [ this.nameLot, Validators.required ],
        dateOption: [ { value: "", disabled: this.isDisable } ]
      });
    } else if ( this.data.namePopup === 'postOptionProject' ) {
      this.serviceConfigObservable.emitOverflowHidden(true);
      this.serviceGestOption.getParametersOption().subscribe((parameters: ParameterGestOption) => {
        this.delayDayOptionOne = Math.round(( parameters.if_proposition_delay1 / 60 ) / 24);
        this.delayDayOptionTwo = Math.round(( parameters.if_proposition_delay2 / 60 ) / 24);

        this.isDisableAsset = this.isDisableAsset;
        this.isDisable = !this.isDisable;

        this.insertSearchForm = this.formBuilder.group({
          nameClient: [{ value: "", disabled: !this.isDisable } , Validators.required ],
          nameProgram: [ this.nameProgram, Validators.required ],
          nameLot: [{ value: this.nameLot, disabled: !this.isDisableAsset }, Validators.required ],
          dateOption: [{ value: "", disabled: !this.isDisable } ]
        });

        this.serviceOperation.getOperationWithLocalizationForPostOption(1, 1000, {})
        .pipe(first()).subscribe((operation: any) => {
            console.log(operation, "operation")
            operation.datas.forEach((operationData: OperationCard) => {
              this.tabOptionResidence.push({ name: operationData.if_operationbusinessname_calc + " - " + operationData.if_developeraccountid_label, id: { name: operationData.if_operationbusinessname_calc + " - " + operationData.if_developeraccountid_label, id: operationData.if_operationid }});                      
            });
        },(error) => {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetOperationWithLocalization, ["custom-style-error"]
          );
        });
  
        this.serviceProject.getReferentialProjectInProgress().pipe(first()).subscribe((listProject:any) => {
          this.toppingList = [];
          listProject.forEach((project: ReferentialProjectInProgress) => {
            // scheduledstart !== null pour plus-
            if ( this.data.dataPopup.rdv.projectId === project.if_projectid ) {
              this.nameClient = { name: project.if_name, id: project.if_projectid, shelduleStart: project.Rdv3.scheduledstart };
              this.dateRdvOption = convertTZ(project.Rdv3.scheduledstart);
              console.log(project.Rdv3.scheduledstart)
            }
            this.toppingList.push({ name: project.if_name, id: { name: project.if_name, id: project.if_projectid, shelduleStart: project.Rdv3.scheduledstart } })
          });
  
          this.insertSearchForm.patchValue({
            nameClient: this.nameClient,
            dateOption: this.dateRdvOption
          })
        }, (error) => {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetReferentialProjectInProgress, ["custom-style-error"]
          );
        });

      }, (error) => {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetParametersOption, ["custom-style-error"]
        );
      })
      
    } else if ( this.data.namePopup === 'cancelOption' ) {
      this.nameClient = this.data.dataPopup.PROJET + ' - ' + this.data.dataPopup.zipCode + ' ' + this.data.dataPopup.VILLE;
      this.nameProgram = this.data.dataPopup.PROGRAMME;
      this.nameLot = this.data.dataPopup.LOT + ' / ' + this.data.dataPopup.TYPE + ' ' + this.data.dataPopup.SHAB + ' - ' + this.data.dataPopup.PROMOTEUR;
      this.priceTTC = this.data.dataPopup.budgetTTC + ' TTC'
      this.startDateOption = this.data.dataPopup.startOption;
      this.endDateOption = this.data.dataPopup.endOption;
      this.rangAsset = this.data.dataPopup.RANG
    }
  }

  // Compare date is use in sort table housing
  private compareDate(a: any, b: any, isAsc: any) {
    
    let myDateArrayA = a.split("/");
    let newDateA = new Date(myDateArrayA[2], Math.round(myDateArrayA[1] - 1), myDateArrayA[0]); 
    let myDateArrayB = b.split("/");
    let newDateB = new Date(myDateArrayB[2], Math.round(myDateArrayB[1] - 1), myDateArrayB[0]); 
    console.log(newDateA.getTime() < newDateB.getTime());
    return newDateA.getTime() < newDateB.getTime()
    
  }

  // Compare date is use in sort table housing
  private compareDateTwo(a: any, b: any, isAsc: any) {
    
    let myDateArrayA = a.split("/");
    let newDateA = new Date(myDateArrayA[2], Math.round(myDateArrayA[1] - 1), myDateArrayA[0]); 
    let myDateArrayB = b.split("/");
    let newDateB = new Date(myDateArrayB[2], Math.round(myDateArrayB[1] - 1), myDateArrayB[0]); 
    console.log(newDateA.getTime(), newDateA.getDate(), newDateA.getMonth(), newDateA.getDay());
    if (newDateA.getTime() > newDateB.getTime()) {
      if (newDateA.getDate() !== newDateB.getDate()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  // Method for text label on select and reset of select simple
  openSelectAndReset(selectName: string, insertFormValue: FormGroup, isOpenSelect: any) {
    if (insertFormValue.value[selectName] !== null ) {
      if (selectName === 'nameClient') {
        console.log(insertFormValue.value[selectName])
        this.nameClient = insertFormValue.value[selectName];
        this.insertSearchForm.patchValue({
          dateOption:  convertTZ(insertFormValue.value[selectName].shelduleStart)
        })
        if (this.dateDispoAssetCustom !== "") {
          this.ruleForDispoDateAndDateOption(insertFormValue.value[selectName].shelduleStart, this.dateDispoAssetCustom);
        } else {
          this.ruleForDispoDateAndDateOption(this.nameClient.shelduleStart, new Date().toString());
        }
      }
      isOpenSelect[selectName] = true;   
    } else {
      this.nameClient = null;
      this.insertSearchForm.patchValue({
        dateOption: ''
      })
      isOpenSelect[selectName] = false; 
      this.errorMessageDispoOne = false;
      this.errorMessageDispoTwo = false;
      this.errorMessageRuleTwo = false;
    }
  }

  // Observe field ng-select name asset and active or not field
  operationChangeValue(tabNameLotEmpty: Array<any>) {
    console.log('change')
    if (this.insertSearchForm.value.nameProgram.id !== '') {
      try {
        this.serviceOperation.getAssetsWithOperationId(this.insertSearchForm.value.nameProgram.id)
                        .pipe(first())
                          .subscribe((asset: any) => {
                              console.log(asset, "Asset")
                              this.tabAssetActive = asset;
                              asset.forEach((assetData: Asset) => {
                                this.tabOptionNameLot.push({ name: assetData['asset.if_assetnumber'] + " - " + assetData['asset.statuscode_label'].slice(2), id: { name: assetData['asset.if_assetnumber'] + " - " + assetData['asset.statuscode_label'].slice(2), id: assetData['asset.if_assetid'] } } );                              
                              });
                          });
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetAssetsWithOperationId, ["custom-style-error"]
        );
      }
      this.isDisableAsset = false;
    } else  {
      this.tabOptionNameLot = [];
      this.isDisableAsset = true;
    }
  }

  // Compare with for select (compare value observe in select with item.id (show construction array items))
  compareWithFn(item: any, selected: any) {
    if (item.id === selected) {
      if (item.id.shelduleStart !== null) {
        this.insertSearchForm.patchValue({
          datOption:  [{ value: item.id.shelduleStart, disabled: !this.isDisable } , Validators.required ]
        })
        if (this.dateDispoAssetCustom !== "") {
          this.ruleForDispoDateAndDateOption(this.nameClient.shelduleStart, this.dateDispoAssetCustom);
        } else {
          this.ruleForDispoDateAndDateOption(this.nameClient.shelduleStart, new Date().toString());
        }
      }
    }
    return item.id === selected;
  }

  

  assetChangeValue() {
    if (this.insertSearchForm.value.nameLot.id !== '') {
      this.tabAssetActive.forEach((asset: Asset) => {
        if (this.insertSearchForm.value.nameLot.id === asset['asset.if_assetid']) {
          this.statusAsset = asset['asset.statuscode_label'].slice(2);
          this.rangAsset = asset['asset.if_lastoptionrank_calc'].toString();
          if ( this.statusAsset.search('Disponible') === -1 ) {
            if (asset['asset.if_lastoptionenddate'] !== undefined) {
              this.hiddenRank = true;
              
              this.ruleForDispoDateAndDateOption(this.nameClient.shelduleStart, convertTZ(asset['asset.if_lastoptionenddate']));
              
              this.dateDispoAssetCustom = asset['asset.if_lastoptionenddate'];
              this.dateDispoAsset = asset['asset.if_lastoptionenddate'] !== null ? getDateLabelSample(convertTZ(asset['asset.if_lastoptionenddate'])) + " " + transformDateFormatHoursPipe(convertTZ((asset['asset.if_lastoptionenddate']))).toString() : "Aucune date disponible";
            } else {
              this.dateDispoAsset = "Aucune date disponible"
            }
          } else {
            this.dateDispoAsset = new Date().getDate().toString() + "/" + ( new Date().getMonth() + 1 ).toString() + "/" + new Date().getFullYear().toString() + " " + transformDateFormatHoursPipe(new Date()).toString();
            this.ruleForDispoDateAndDateOption(this.nameClient.shelduleStart, new Date().toISOString());
            this.hiddenRank = false;
          }
        }
      });
    }
  }

  // Option sur Lot optionné rang 2 ==> Pas de bouton pose d’option
  ruleForDispoDateAndDateOption(shelduleStart: string, assetDispo: any) {
    // console.log(new Date(assetDispo.getTime() - assetDispo.getTimezoneOffset() * 60000).toISOString())
    // console.log(convertTZ(assetDispo.toISOString()))
    let objectErrorMessage: any = this.serviceRulePostOptionSubModule.parametersRuleForDispoDateAndDateOption(this.rangAsset,this.errorMessageRank,this.errorMessageDispoOne,this.errorMessageRuleTwo,this.errorMessageDispoTwo,this.delayDayOptionTwo,this.delayDayOptionOne, shelduleStart, assetDispo)

    this.errorMessageRank = objectErrorMessage.errorMessageRank; 
    this.errorMessageDispoOne = objectErrorMessage.errorMessageDispoOne, 
    this.errorMessageRuleTwo = objectErrorMessage.errorMessageRuleTwo, 
    this.errorMessageDispoTwo = objectErrorMessage.errorMessageDispoTwo

    // if (parseInt(this.rangAsset) < 2) {
    //   this.errorMessageRank = false;
    //   let today = new Date();
    //   // shelduleStart = '2021-09-13T14:05:58'
  
    //   // Si date R3 < date dispo du lot  ce lot n’est pas disponible à la date du RDV
    //   if(this.compareDate(getDateLabelSample(convertTZ(shelduleStart)), getDateLabelSample(convertTZ(assetDispo)), 'asc')) {  
    //     this.errorMessageDispoOne = true;
    //   } else {
    //     this.errorMessageDispoOne = false;
    //   }
  
    //   // Option sur Lot optionné rang 1 ==>	Si date R3 > now+8  vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV
    //   if (this.rangAsset.toString() === '1') {
    //     today.setDate(today.getDate() + this.delayDayOptionTwo)
    //     if(this.compareDateTwo(getDateLabelSample(convertTZ(shelduleStart)), getDateLabelSample(convertTZ(today.toString())), 'asc') ) {
    //       this.errorMessageRuleTwo = true;
    //     } else {
    //       this.errorMessageRuleTwo = false;
    //     }
    //   } // Option sur Lot Dispo ==>	Si date R3 > now+7  vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV 
    //   else {
    //     today.setDate(today.getDate() + this.delayDayOptionOne)
    //     if (this.compareDateTwo(getDateLabelSample(convertTZ(shelduleStart)), getDateLabelSample(convertTZ(today.toString())), 'asc')) {
    //       this.errorMessageDispoTwo = true
    //     } else {
    //       this.errorMessageDispoTwo = false;
    //     }
    //   }
    // } else {
    //   this.errorMessageRank = true;
    // }
  }

  dateOptionChangeValue(eventField: any) {
    if (eventField.value !== null) {
      this.labelRdvDate = '';
    } else {
      this.labelRdvDate = 'Date du RDV';
    }
  }

  // Function get error of your form and return string at your view
  getErrorMessage(control: any) {
    if ( control.hasError('required') ) {
      return '*Ce champ est obligatoire'
    } else {
      return
    }
  }

  // Observe field for delete label if input touched or valid
  openFieldRdvtDate(eventField: any ) {
    console.log(eventField)
    if (eventField.type === "focus" || eventField === true || this.insertSearchForm.value["dateOption"] != null) {
      this.labelRdvDate = '';
    } else if (eventField.type === "blur") {
      this.labelRdvDate = 'Date du RDV';
    }
  }

  // Function observe delete and refesh tablehousing with data research save after 1 seconde
  deleteResearchRefresh(activeRefresh: boolean) {
    setTimeout(() => {
      try {
        this.serviceResearch.getResearchSave().subscribe((AllDataResearchSave: any) => {
          let newListSearchSave: Array<any> = new Array<any>();
          AllDataResearchSave.datas.forEach((searchSave: any) => {
            let jsonParseDataSave = JSON.parse(searchSave.Description)
            let newObjectSearchSave = {}; 
            for (const fieldSearchSave in jsonParseDataSave) {
              Object.assign(newObjectSearchSave, { [fieldSearchSave]: JSON.parse(jsonParseDataSave[fieldSearchSave]) });
            }
            Object.assign(newObjectSearchSave, { Title: searchSave.Title, SearchId: searchSave.SearchId });
            newListSearchSave.push(newObjectSearchSave);
          });
          this.tabSearchSave = newListSearchSave;
          this.refresh = true;
        })
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetResearchSave, ["custom-style-error"]
        );
      }
    }, 1000);
  }

  // Methode for activate open close popup create new project
  openDialogNewProject(namePopup: string, dataPopup: any ) {
    const dialogRef = this.popupNewProject.open(PopupNewProjectComponent, {
      width: '689px',
      height: '589px',
      data: { namePopup: namePopup , dataPopup: dataPopup, size: this.data.size },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
    });
  }

  // Function submit form post option or cancel option asset
  onSubmitPostOption() {
    this.openDialogLoading('loadingGestOption', "")
    let newNameClient;
    let newIdLot;
    let newIdAppointmentR3;
    if (this.data.namePopup === 'postOptionLot' ) {
      newNameClient = this.insertSearchForm.value.nameClient.id;
      newIdLot = this.data.dataPopup.idLot;
      newIdAppointmentR3 = this.insertSearchForm.value.nameClient.idRdv3;
    } else {
      newNameClient = this.nameClient.id;
      newIdLot = this.insertSearchForm.value.nameLot.id;
      newIdAppointmentR3 = this.data.dataPopup.listRdv[this.data.dataPopup.listRdv.length -1].id;
    }
    
    this.serviceGestOption.putCreateProposition(newIdLot, newNameClient, newIdAppointmentR3, null)
      .pipe(first()).subscribe((data: any) => {
        console.log(data);
        if (data.propositionId === null) {
          this.openDialogLoading('notGetBafSynchronizedOptionNotId', data.errorMessage)
        } else {
          this.getBafSynchronized(data);
          this.openDialogLoading('synchronizedBafOption', '');
        }
      }, error => {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessagePutCreateProposition, ["custom-style-error"]
        );
      });
  }

  onSubmitCancelOption() {
    this.openDialogLoading('loadingCancelGestOption', "")
    try {
      this.serviceGestOption.postCancelProposition(this.data.dataPopup.propositionId)
      .pipe(first()).subscribe((data: any) => {
        console.log(data);
        if (data.isError) {
          this.openDialogLoading('errorMessageGestOption', data.errorMessage)
        } else {
          this.serviceConfigObservable.emitRefreshOption(true);
          this.popupLoading.closeAll();
          // this.openDialogLoading('synchronizedBafOption', '');
        }
      })
    } catch(e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessagePostCancelProposition, ["custom-style-error"]
      );
    }
  }


  // Rendre Binding pour prod ou autre (A faire)
  // Methode for get if baf is synchronized after update project
  async getBafSynchronized(data: any) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        // this.popupLoading.closeAll();
        this.openDialogLoading('notGetBafSynchronizedOption', '');
        clearInterval(setIntervalProjectId);
      }

      this.serviceProposition.getBafSynchronized(data.propositionId).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize.datas === true) {
          this.serviceConfigObservable.emitRefreshStock(true);
          this.serviceConfigObservable.emitRefreshOption(true);
          clearInterval(setIntervalProjectId);
          this.openDialogLoading('validGestOption', { start: data.startDate, end: data.endDate })
        }
      }
      console.log(i)
      i++
    }, 2000)
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string, errorMessage: any) {
    this.popupService.openDialogLoading(namePopupLoading, this.data.size, errorMessage);
  }
  
  // Function load research with research id get of tablehousing research save
  loadSendResearch(loadResearchId: any) {
    this.data.dataPopup.datas.forEach((searchSave: any) => {
      if (loadResearchId === searchSave.SearchId) {
        this.serviceConfigObservable.emitLoadResearchSave(searchSave.Params);
      }
    });

    this.dialogRef.close();
  }
}