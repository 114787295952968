import { DatePipe } from '@angular/common';

// Function for size screen
export function initSizeScreen() {
  if ( window.screen.width < 576 ) {
    return 1
  } else if ( window.screen.width >= 576 && window.screen.width < 768 ) { // 768px portrait
    return 1;
  } else if ( window.screen.width >= 768 && window.screen.width < 1122 ) {
    return 2;
  } else if ( window.screen.width >= 992 && window.screen.width < 1440 ) {
    return 3;
  } else {
    return 4;
  }
}

// Function for size screen
export function initSizeInnerWidth() {
  if ( window.innerWidth < 576 ) {
    return 1
  } else if ( window.innerWidth >= 576 && window.innerWidth < 768 ) { // 768px portrait
    return 1;
  } else if ( window.innerWidth >= 768 && window.innerWidth < 1122 ) {
    return 2;
  } else if ( window.innerWidth >= 992 && window.innerWidth < 1440 ) {
    return 3;
  } else {
    return 4;
  }
}

// Function for Date get number month and return string in letter of month get 
export function getMonthLetter(numStringMonth: string) {
    switch (numStringMonth) {
        case '01':
            return 'Janvier';
        case '02':
            return 'Février';
        case '03':
            return 'Mars';
        case '04':
            return 'Avril';
        case '05':
            return 'Mai';
        case '06':
            return 'Juin';
        case '07':
            return 'Juillet';
        case '08':
            return 'Aout';
        case '09':
            return 'Septembre';
        case '10':
            return 'Octobre';
        case '11':
            return 'Novembre';
        case '12':
            return 'Décembre';
        default:
          return console.log(`Désolé aucun numéro ne correspond à un mois ${numStringMonth}`);
    }
}

// Get number and return string number separate for a escpace all the 3 numbers
export function numberWithCommas(num: number) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

// Transform date in letter at format FR
export function transformDateFormatLetterPipe(value: Date) {
  let newValue: any = '';
  let datePipe = new DatePipe("fr-FR");
  newValue = datePipe.transform(value, 'EEEE d MMMM y');
  return newValue
}

// Transform hours in letter at format FR ( H:mm )
export function transformDateFormatHoursPipe(value: Date) {
  let newValue: any = '';
  let datePipe = new DatePipe("fr-FR");
  newValue = datePipe.transform(value, 'H:mm');
  return newValue
}

// Transform hours in letter at format FR ( 9H05 )
export function transformDateFormatHoursPipe2(value: Date) {
  let newValue: any = '';
  let datePipe = new DatePipe("fr-FR");
  newValue = datePipe.transform(value, 'H') + "H" + datePipe.transform(value, 'mm');
  return newValue
}

// Get quarter of the date receive in the method and return quarter in string custom (m + "e TRIM " + y) 
export function getQuarter(d: string) {
  var newDate = new Date(d);
  var m = Math.floor(newDate.getMonth() / 3) + 1;
  m -= m > 4 ? 4 : 0;
  var y = newDate.getFullYear();
  return m + "e TRIM " + y;
}

// Get quarter of the date receive in the method and return quarter in string custom (m + "è Trimestre " + y) 
export function getQuarterWordCompleted(d: string) {
  var newDate = new Date(d);
  console.log(newDate)
  var m = Math.floor(newDate.getMonth() / 3) + 1;
  m -= m > 4 ? 4 : 0;
  var y = newDate.getFullYear();
  console.log(m + "è Trimestre " + y);
  return m + "è Trimestre " + y;
}

// Get quarter of the date receive in the method and return quarter in string custom ("T" + m + " " + y) 
export function getQuarterOneLetter(d: string) {
  var newDate = new Date(d);
  var m = Math.floor(newDate.getMonth() / 3) + 1;
  m -= m > 4 ? 4 : 0;
  var y = newDate.getFullYear();
  return "T" + m + " " + y;
}

//Function for pattern
export function getNumWithEscape(tel: string) {
    let newNum = ""
    for (let i = 0; i < tel.length; i++) {
        if( ( i !== 0 ) && ( ( i % 2) === 0 ) ) {
            console.log(i);
            newNum += tel[i] + " ";
        } else {
            newNum += tel[i]
        }
    }
    return newNum;
}

// Pastille orange		GET /api/Appointments/Search		appointment.if_rdv_activity = 750 010 000
// Pastille rouge		GET /api/Appointments/Search		appointment.if_rdv_activity = 750 010 001
// Pastiller verte		GET /api/Appointments/Search		appointment.if_rdv_activity = 750 010 002 ou 750 010 003

export function getColorRdv(category: number) {
  if (category === 750010001) {
    return '#DEEBF7'
  } else if (category === 750010002) {
    return '#C5E0B4'
  } else if (category === 750010003) {
    return '#FF8F73'
  } else if (category === 750010004) {
    return '#FFE380'
  } else {
    return '#D0D0D0'
  }
}

export function getColorCategoryRdv(category: number) {
  if (category === 750010000) {
    return '#ff9400'
  } else if (category === 750010001) {
    return '#ec6069'
  } else if (category === 750010002 || category === 750010003) {
    return '#00ff0e'
  } else {
    return '#008000'
  }
}

export function getDateLabel(date: any, choice: boolean) {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  if (choice) {
    return date.toLocaleDateString('fr-FR', options) + " à " + transformDateFormatHoursPipe(date).toString()
  }
  return date.toLocaleDateString('fr-FR', options) + " - " + transformDateFormatHoursPipe(date).toString()
}


export function getDateLabelRecall(date: any) {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return date.toLocaleDateString('fr-FR', options) + " - " + transformDateFormatHoursPipe2(date).toString()
}

// Get date and convert to UTC Date ( yyyy-MM-dd'T'HH:mm:ss.SSS'Z' - 1999-03-22T05:06:07.000Z )
/** Convert date to UTC0 */
export function convertTZ(date: any) {
  // console.log(date)
  // console.log(new Date(date + ".000Z"))
  return new Date(date + ".000Z");   
}

// Get data observe and boolean for the data observe of input or of a select simple
export function isNullFieldForm(dataObserve: any, booleanInput: boolean) {
  if (dataObserve !== null) {
    return dataObserve
  } else {
    if (booleanInput) {
      return ""
    } else {
      return null
    }
  }
}

export function getDateLabelSample(date: any) {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }; 
  console.log(date)
  let dateTransforme: any = new Date(date);
  return dateTransforme.toLocaleDateString('fr-FR', options)
}

// Method for observe if data is null and if next to choice return escape or tiret
export function ifNullReturnTiret(variable: any, numReturn: number) {
  if (variable !== null) {
    return variable
  }
  if (numReturn === 1) {
    return ' - '
  }
  return ' '
}