import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ComparationAssetService {

  comparateurActivated: Boolean = this.userService.user.ShowComparator;

  listAssetCompar: Array<string> = new Array<string>()

  constructor(private serviceConfigObservable: ConfigObservableService, private userService: UserService) {
    let listLocalStorage = localStorage.getItem('listAssetCompar')
    if (listLocalStorage !== null) {
      this.listAssetCompar = JSON.parse(listLocalStorage);
    }
   }


  public addAssetInList(assetChoice: string) {
    this.listAssetCompar.push(assetChoice)
    localStorage.setItem('listAssetCompar', JSON.stringify(this.listAssetCompar))
    this.serviceConfigObservable.emitSendNbComparation(this.listAssetCompar.length)
  }

  public removeCompar(assetChoice: string) { 
    const index = this.listAssetCompar.indexOf(assetChoice);
    if (index > -1) {
      this.listAssetCompar.splice(index, 1);
      localStorage.setItem('listAssetCompar', JSON.stringify(this.listAssetCompar.splice(index, 1)))
      this.serviceConfigObservable.emitSendNbComparation(this.listAssetCompar.length)
    }
  }
}
