<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
        <div class="containerTitleImg">
            <img *ngIf="subModuleUse !== 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" [ngClass]="{ 'logoSM': data.size < 2 &&  data.namePopup === 'postOptionLot', 'logoSaveSM': data.size < 2 && data.namePopup === 'saveSearch' }" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <img *ngIf="subModuleUse === 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" [ngClass]="{ 'logoSM': data.size < 2 &&  data.namePopup === 'postOptionLot', 'logoSaveSM': data.size < 2 && data.namePopup === 'saveSearch' }" src="../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
            <h1 *ngIf="namePopup !== 'validGestOption' && namePopup !== 'postDocumentDer' && namePopup !== 'postResearchValid' && namePopup !== 'postDenunciationValid'" class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }">{{ loadingTitle.loadingTitleOne }}</h1>
            <h1 *ngIf="namePopup === 'postDocumentDer'" class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }">{{ loadingTitle.loadingTitleSignDER }}</h1>
            <h1 *ngIf="namePopup === 'validGestOption'" class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }">{{ loadingTitle.loadingTitleOption }}</h1>      
        <div>
      <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup cancel option -->
<mat-dialog-content [ngClass]="{'containerLoadingContent': data.size >= 2, 'containerLoadingContentSM': data.size < 2}">
    <div class="containerTitle">
      <h1 class="titleLoading" *ngIf="namePopup === 'detachedCTC2'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter la modification de votre projet est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'postCreateDenunciation'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre création de dénonciation est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'changePasswordErip'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre changement de mot de passe E-rip est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'duplicateProject'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre action est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'createTask'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre tâche est en cours de création..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'modifyTask'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre tâche est en cours de modification..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'createCallTel'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre appel est en cours de création..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'modifyCallTel'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre appel est en cours de modification..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'closeTask'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre tâche est en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'closeTaskAnnulation'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre tâche est en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'closeCallTel'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre appel est en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'closeCallTelAnnulation'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre appel est en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'createErip'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter la création de votre E-rip est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === ('putProjectPlacement' || 'putProjectImmobilier')" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter la conversion de votre projet est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'postSubscribePlacment'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre souscription est en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingBookOfSale'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter votre book de vente est en préparation</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingConditionsRead'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter conditions générales en cours de validations</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingDownloadDocument'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter nous réunissons vos documents</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingShowDocument'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Veuillez patienter nous préparons votre document</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'createNewProject'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Projet en cours de création..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'updateProject'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Projet en cours de modification..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'closeProject'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Projet en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'putProjectNrp'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Nrp en cours de validation..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'putProjectRecall'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Rappel en cours de création..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'putProjectCloseRecall'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Rappel en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'createNewRdv'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Rendez-vous en cours de création..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'createModifyRdv'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Rendez-vous en cours de modification..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'ruleRdv'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Rendez-vous en cours de fermeture..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'synchronizedBafProject'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Synchronisation du projet en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'synchronizedBafAppointment'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Synchronisation du rendez-vous en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'synchronizedBafOption'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Synchronisation de l'option en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'notGetBafSynchronizedProject'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Le projet met plus de temps que prévu..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'notGetBafSynchronizedOption'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">L'option met plus de temps que prévu..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingGestOption'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Pose d’option en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingCancelGestOption'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Option en cours d’annulation..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'postLoadingResaPaper'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Déclaration de la pré-réservation en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'loadingPostResearch'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Enregistrement de la recherche en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'postDenunciationValid'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Votre dénonciation a bien été pris en compte</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'postResearchValid'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Votre recherche à bien été sauvegardée</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'eripIsCreating'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Création d'un nouveau E-Rip en cours..</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'eripIsModifying'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">E-Rip en cours de modification...</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'eripIsOpening'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Chargement de l'E-Rip...</h1>
      <h1 class="titleLoading" *ngIf="namePopup === 'eripIsSynchronizing'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Synchronisation de l'E-Rip au projet en cours...</h1>
      <h1 class="titleLoadingErrorMessageGestOption" *ngIf="namePopup === 'notGetBafSynchronizedOptionNotId'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">L’option n’a pas pu être posée : <br>{{ data.dataPopup }}</h1>
      <h1 class="titleLoadingValidGestOption" *ngIf="namePopup === 'validGestOption'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Votre option a bien été posée <br>du <span style="font-weight: bold;">{{ data.dataPopup.start | dateFormatLetterPipe }} à {{ data.dataPopup.start | dateFormatHoursPipe }}</span> <br>au <span style="font-weight: bold;">{{ data.dataPopup.end | dateFormatLetterPipe }} à {{ data.dataPopup.end | dateFormatHoursPipe }}</span></h1>
      <h1 class="titleLoadingErrorMessageGestOption" *ngIf="namePopup === 'errorMessageGestOption'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">L’annulation de l’option n’a pas pu s’effectuer : <br>{{ data.dataPopup }}</h1>
    </div>
    <div *ngIf="namePopup !== 'notGetBafSynchronizedProject' && namePopup !== 'notGetBafSynchronizedOption' && namePopup !== 'notGetBafSynchronizedOptionNotId' && namePopup !== 'errorMessageGestOption' && namePopup !== 'validGestOption' && namePopup !== 'postResearchValid' && namePopup !== 'postDenunciationValid'" class="containerLoading">
        <div class="boxes">
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>

    <div class="containerBtnLoading" *ngIf="namePopup === 'notGetBafSynchronizedProject' || namePopup === 'notGetBafSynchronizedOption' || namePopup == 'notGetBafSynchronizedOptionNotId' || namePopup === 'errorMessageGestOption' || namePopup === 'validGestOption' || namePopup === 'postResearchValid' || namePopup === 'postDenunciationValid'">
        <button mat-dialog-close *ngIf="namePopup !== 'postResearchValid' && namePopup !== 'postDenunciationValid'" class="btnLoading">OK</button>
        <button mat-dialog-close *ngIf="namePopup === 'postResearchValid' || namePopup === 'postDenunciationValid'" class="btnMessageSave">Retour au stock</button>
    </div>
</mat-dialog-content>