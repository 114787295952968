import { Injectable } from '@angular/core';
import { convertTZ, getDateLabelSample } from 'src/app/shared/services/functions';

@Injectable({
  providedIn: 'root'
})
export class RulePostOptionServiceInovia {

  constructor() { }

  parametersRuleForDispoDateAndDateOption(rangAsset: string, errorMessageRank: Boolean, errorMessageDispoOne: Boolean, errorMessageRuleTwo: Boolean, errorMessageDispoTwo: Boolean, delayDayOptionTwo: number, delayDayOptionOne: number, shelduleStart: string, assetDispo: string) {
    let objectErrorMessage = { 
      errorMessageRank: false, 
      errorMessageDispoOne: false, 
      errorMessageRuleTwo: false,
      errorMessageDispoTwo: false 
    }
    if (parseInt(rangAsset) < 2) {
      objectErrorMessage.errorMessageRank = false;
      let newAssetDispo = new Date(assetDispo);
      // shelduleStart = '2021-09-13T14:05:58'
  
      // Si date R3 < date dispo du lot  ce lot n’est pas disponible à la date du RDV
      if(new Date(convertTZ(shelduleStart)) < newAssetDispo) {  
        objectErrorMessage.errorMessageDispoOne = true;
      } else {
        objectErrorMessage.errorMessageDispoOne = false;
      }
  
      // Option sur Lot optionné rang 1 ==>	Si date R3 > now+8  vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV (Pas Bon avant)
      // Option sur Lot optionné rang 1 ==>	Si date R3 2022-04-29T11:30:00 > 2022-04-29T09:00:00 Math.round(( parameters.if_proposition_delay2 / 60 ) / 24);  vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV
      if (rangAsset.toString() === '1') {
        newAssetDispo.setDate(newAssetDispo.getDate() + delayDayOptionTwo)
        if(new Date(convertTZ(shelduleStart)) > newAssetDispo) {
          objectErrorMessage.errorMessageRuleTwo = true;
          return objectErrorMessage;
        } else {
          objectErrorMessage.errorMessageRuleTwo = false;
          return objectErrorMessage;
        }
      } // Option sur Lot Dispo ==>	Si date R3 > now+7  vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV (Pas Bon avant)
      // Option sur Lot optionné rang 1 ==>	Si date R3 > Math.round(( parameters.if_proposition_delay1 / 60 ) / 24);  vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV
      else {
        newAssetDispo.setDate(newAssetDispo.getDate() + delayDayOptionOne)
        // console.log(newAssetDispo)
        // console.log(newAssetDispo.toISOString())
        // console.log(convertTZ(shelduleStart))
        if (new Date(convertTZ(shelduleStart)) > newAssetDispo) {
          objectErrorMessage.errorMessageDispoTwo = true;
          return objectErrorMessage;
        } else {
          objectErrorMessage.errorMessageDispoTwo = false;
          return objectErrorMessage;
        }
      }
    } else {
      objectErrorMessage.errorMessageRank = true;
      return objectErrorMessage;
    }
  }

  // Compare date is use in sort table housing
  private compareDate(a: any, b: any, isAsc: any) {
    
    let myDateArrayA = a.split("/");
    let newDateA = new Date(myDateArrayA[2], Math.round(myDateArrayA[1] - 1), myDateArrayA[0]); 
    let myDateArrayB = b.split("/");
    let newDateB = new Date(myDateArrayB[2], Math.round(myDateArrayB[1] - 1), myDateArrayB[0]); 
    console.log(newDateA.getTime() < newDateB.getTime());
    return newDateA.getTime() < newDateB.getTime()
    
  }

  private compareDateTwo(a: any, b: any, isAsc: any) {
    
    let myDateArrayA = a.split("/");
    let newDateA = new Date(myDateArrayA[2], Math.round(myDateArrayA[1] - 1), myDateArrayA[0]); 
    let myDateArrayB = b.split("/");
    let newDateB = new Date(myDateArrayB[2], Math.round(myDateArrayB[1] - 1), myDateArrayB[0]); 
    console.log(newDateA.getTime(), newDateA.getDate(), newDateA.getMonth(), newDateA.getDay());
    if (newDateA.getTime() > newDateB.getTime()) {
      if (newDateA.getDate() !== newDateB.getDate()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
