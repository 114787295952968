import { ProjectId } from 'src/app/shared/models/project-id';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RuleOtherServiceInovia {

  constructor() { }

  // getActivationBtnPostRdvAndCloseProject(this.projectDataBind);
  getActivationBtnPostRdvAndCloseProject(projectDataGetParent: any, project: ProjectId) {
    let projectDataBind = {
      statecodeR1: false,
      statecodeR2: false,
      statecodeR3: false,
      closeProject: false
    }


    if (projectDataGetParent.r1 === 100) {  
      if (project.rdv2_statecode === null && project.rdv3_statecode === null) {
        projectDataBind.statecodeR1 = true;
      
        if (projectDataGetParent.r1 === 100 && project.rdv1_statecode !== null) {
          if (project.rdv1_statecode !== 0 && project.rdv1_statecode !== 3 && project.rdv1_statecode !== 1) {
            projectDataBind.statecodeR1 = true;
          } else {
            projectDataBind.statecodeR1 = false;
          }
          if (projectDataGetParent.r2 === 100 && project.rdv1_statecode !== null && project.rdv2_statecode === null) {
            projectDataBind.statecodeR2 = true;
          }
        }
      }

      if (projectDataGetParent.r2 === 100 && project.rdv2_statecode !== null && project.rdv3_statecode === null) {
        if (project.rdv2_statecode !== 0 && project.rdv2_statecode !== 3) {
          projectDataBind.statecodeR2 = true;
        }
        if (projectDataGetParent.r3 === 100 && project.rdv2_statecode !== null && project.rdv3_statecode === null) {
          projectDataBind.statecodeR3 = true;
        }
      }

      if (projectDataGetParent.r2 === 100 && projectDataGetParent.r3 === 100 && project.rdv3_statecode !== null) {
        if (project.rdv3_statecode !== 0 && project.rdv3_statecode !== 3) {
          projectDataBind.statecodeR3 = true;
        }
      }
    }

    // if (project.rdv1_statecode === null && project.rdv2_statecode === null && project.rdv3_statecode === null) {
    //   projectDataBind.closeProject = true
    // }

    return projectDataBind;
  }
}
