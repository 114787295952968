<table mat-table
    [dataSource]="dataSource" multiTemplateDataRows matSort
    [ngClass]="{'mat-elevation-z8 customTableTag': size > 2, 'mat-elevation-z8 customTableTagMD': size == 2, 'mat-elevation-z8 customTableTagSM': size < 2}">
    <!-- Header of the table -->
    <ng-container matColumnDef="{{column}}" *ngFor="let column of filterColumnOfType('icon')">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'activeBackground': element == expandedElement }"> 
            <span *ngIf="element[column] !== 'play_arrow'" [ngClass]="{ 'activeColorFree': element[column] == 'Libre', 'activeColorOption': element[column] == 'Option', 'activeFontWeight': column == 'PROGRAMME'}">{{element[column]}}</span>
            <mat-icon *ngIf="element[column] === 'play_arrow'" [ngClass]="{ 'iconPlayArrow': element !== expandedElement, 'activeIcon': element == expandedElement }" aria-hidden="false" aria-label="Example user verified icon">{{element[column]}}</mat-icon> 
        </td> 
    </ng-container>

    <!-- All row of the table -->
    <ng-container matColumnDef="{{column}}" *ngFor="let column of filterColumnOfIcon('icon')">
        <th mat-header-cell *matHeaderCellDef><span [ngClass]="{ 'displayNameHeader': column == 'icon' }">{{column}}</span></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{ 'activeBackground': element == expandedElement }"> 
            <span *ngIf="element[column] !== 'play_arrow'">{{element[column]}}</span>
            <mat-icon *ngIf="element[column] === 'play_arrow'" class="arrowEndRow" [ngClass]="{ 'iconPlayArrow': element !== expandedElement, 'activeIcon': element == expandedElement }" aria-hidden="false" aria-label="Example user verified icon">{{element[column]}}</mat-icon> 
        </td> 
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length" [ngClass]="{ 'activeBackground': element == expandedElement }">
            <div class="containerTablehousingDetail"
                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div class="containerContentRowStock" >
                    <div [ngClass]="{'containerTablehousingDescription': size >= 2, 'containerTablehousingDescriptionSM': size < 2}">
                        <div *ngIf="!activeDeleteResearch" style="margin-left: 10px; font-size: 11px; width: 100%;">
                            <p class="nameCritere">
                                - Localisation :&thinsp;
                                <span class="containerSpanNameResearch">
                                    <span class="dataCritere" *ngIf="element.Cities !== undefined">
                                        <span *ngFor="let city of element.Cities">
                                            {{ city }},
                                        </span>
                                    </span>
                                    <span class="dataCritere" *ngIf="element.Departments !== undefined">
                                        <span *ngFor="let department of element.Departments">
                                            {{ department }},
                                        </span>
                                    </span>
                                    <span class="dataCritere" *ngIf="element.Regions !== undefined">
                                        <span *ngFor="let region of element.Regions">
                                            {{ region }},
                                        </span>
                                    </span>
                                </span>
                            </p>
                            <p class="nameCritere">
                                - Type :&thinsp;
                                    <span class="containerSpanNameResearch" *ngIf="element.AssetTypeCodes !== undefined">
                                        <span class="dataCritere" *ngFor="let typology of element.AssetTypeCodes">
                                            {{ typology }},
                                        </span>
                                    </span>
                            </p>
                            <p *ngIf="subModuleUse !== 'submodule_Edelis'" class="nameCritere">
                                - Promoteur :&thinsp;
                                    <span class="containerSpanNameResearch" *ngIf="element.PromoteurIds !== undefined">
                                        <span class="dataCritere" *ngFor="let promoter of element.PromoteurIds">
                                            {{ promoter }},
                                        </span>
                                    </span>
                            </p>
                            <p class="nameCritere">
                                - Operation :&thinsp;
                                    <span class="containerSpanNameResearch" *ngIf="element.OperationIds !== undefined">
                                        <span class="dataCritere" *ngFor="let operation of element.OperationIds">
                                            {{ operation }},
                                        </span>
                                    </span>
                            </p>
                            <p class="nameCritere">
                                - Prix min. :&thinsp; <span class="dataCritere containerSpanNameResearch">{{ element.AssetPricesMin }} €</span>
                            </p>
                            <p class="nameCritere">
                                - Prix max. :&thinsp; <span class="dataCritere containerSpanNameResearch">{{ element.AssetPricesMax }} €</span>
                            </p>
                            <p class="nameCritere">
                                - Dispositif fiscal :&thinsp; 
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.OperationTaxLawCodes !== undefined && element.OperationTaxLawCodes.length !== 0">
                                    <span *ngFor="let taxSystem of element.OperationTaxLawCodes">
                                        {{ taxSystem }},
                                    </span>
                                </span>
                            </p>
                            <p class="nameCritere">
                                - Actable :&thinsp; 
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.ActabilityDates !== undefined && element.ActabilityDates[0].DateMaxIsCurrentDay !== undefined">X</span>
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.ActabilityDates !== undefined && element.ActabilityDates[0].DateMaxIsCurrentDay === undefined && element.ActabilityDates.length !== 0">
                                    <span *ngFor="let actability of element.ActabilityDates">
                                        {{ actability }},
                                    </span>
                                </span>
                            </p>
                            <p class="nameCritere">
                                - Date de livraison :&thinsp; 
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.DeliveryDates !== undefined && element.DeliveryDates.length !== 0">
                                    <span *ngFor="let delivery of element.DeliveryDates">
                                        {{ delivery }},
                                    </span>
                                </span>
                            </p>
                            <p class="nameCritere">
                                - Date d'achèvement des travaux :&thinsp; 
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.CompletionDates !== undefined && element.CompletionDates.length !== 0">
                                    <span *ngFor="let daact of element.CompletionDates">
                                        {{ daact }},
                                    </span>
                                </span>
                            </p>

                            <p *ngIf="subModuleUse === 'submodule_Edelis'" [ngClass]="{'nameCritere': size >= 2, 'nameCritereSM': size < 2}">
                                - Boost honoraires :&thinsp; 
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.FeesBoost">X</span>
                            </p>
                            
                            <p *ngIf="subModuleUse === 'submodule_Edelis'" [ngClass]="{'nameCritere': size >= 2, 'nameCritereSM': size < 2}">
                                - Éligible TVA réduite :&thinsp; 
                                <span class="dataCritere containerSpanNameResearch" *ngIf="element.ReducedTaxEligibility">X</span>
                            </p>
                        </div>

                        <div *ngIf="activeDeleteResearch" class="containerElementDesccription">
                            <h1 class="titleConfirmDelete">Êtes-vous sûr de vouloir supprimer cette recherche ?</h1>
                            <div class="containerBtnConfirmDelete">
                                <button class="btnCancelDelete" (click)="activeDeleteResearch = false">Non</button>
                                <button class="btnYesDelete" (click)="deleteResearch(element.SearchId)">Oui</button>
                            </div>
                        </div>
                        <!-- SearchId -->
                        <div *ngIf="!activeDeleteResearch" [ngClass]="{'containerBtnSearchSave': size >= 2, 'containerBtnSearchSaveSM': size < 2}">
                            <button mat-icon-button [ngClass]="{'btnDeleteSearchSave': size >= 2, 'btnDeleteSearchSaveSM': size < 2 }" (click)="activeDeleteResearch = true">
                                <mat-icon mat-dialog-close class="iconDeleteDesktop" aria-hidden="false" aria-label="Example user verified icon">close</mat-icon>
                                <span>Supprimer</span>
                            </button>
                            <button mat-icon-button [ngClass]="{'btnActionSearchSave': size >= 2, 'btnActionSearchSaveSM': size < 2}" (click)="sendResearch(element.SearchId)">
                                <mat-icon class="iconSearchDesktop" aria-hidden="false" aria-label="Example user verified icon">search</mat-icon>
                                <span>Rechercher</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Activation click on content for expand content -->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
        [ngClass]="{'containerTablehousingRow': size >= 2, 'containerTablehousingRowSM': size < 2}"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="containerTablehousingDetailRow"></tr>
</table>