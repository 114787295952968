import { SnackbarService } from '../../../../../shared/services/snackbar.service';
import { PopupServiceService } from '../../../../../shared/services/popup-service.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// Interface for send several data in the popup
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  title: string;
  subtitle: string;
  backgroundColorContent: string;
  colorContent: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-sales-offers',
  templateUrl: './popup-sales-offers.component.html',
  styleUrls: ['./popup-sales-offers.component.scss']
})
export class PopupSalesOffersComponent implements OnInit {

  subModuleUse: string;

  private ngUnsubscribe = new Subject();

  constructor(public dialogRef: MatDialogRef<PopupSalesOffersComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup,
              private popupLoading: MatDialog, private popupService: PopupServiceService, private serviceSnackbar: SnackbarService) {
                this.subModuleUse = environment.subModuleUse;
              }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.popupService.openDialogLoading(namePopupLoading, this.objectReceivePopup.size, "");
  }

}