import { AuthentificationService } from './authentification.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  // static URL = environment.urlServer;
  environment: any;

	constructor(private http: HttpClient,private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getZipDocumentWithId(listDocumentZip: any): Observable<any> {
    const headers = this.authentificationService.headers;

    var listTest = {
      "OperationId": "550e6c80-130d-e811-a955-000d3ab27f0e",
      "Documents": [
        "ac0f506a-ea4e-e811-a959-000d3ab27d56", "de731bc7-b3ff-e911-a811-000d3aba7e84"
      ]
    }; 
    var mediaType = 'application/zip';
    return this.http
			.post(this.environment.urlServer + 'Documents/Zip', JSON.stringify(listDocumentZip), { headers: headers, observe: 'response', responseType: 'blob'  })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response);
          // var blob = new Blob([response.body], { type: mediaType });
          // saveAs(blob, 'report.pdf');
					return response.body;
				})
      );
  }


  public getPdfBookDocumentWithId(listDocumentPdfBook: any, nameOperation: string): Observable<any> {
    const headers = this.authentificationService.headers;

    var ids = "ac0f506a-ea4e-e811-a959-000d3ab27d56"; 
    var mediaType = 'application/pdf';
    var nameDoucment = 'BOOK DE VENTE - ' + nameOperation;
    console.log(listDocumentPdfBook)

    return this.http
			.post(this.environment.urlServer + 'Documents/PdfBook/', JSON.stringify(listDocumentPdfBook) , { headers: headers, observe: 'response', responseType: 'blob'  })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          var blob = new Blob([response.body], { type: mediaType });
          saveAs(blob, nameDoucment);
          return response.body;
        })
      );
  }

  public getDownloadDocumentWithId(documentDownload: any, nameDownload: string): Observable<any> {
    const headers = this.authentificationService.headers;

    // var ids = "ac0f506a-ea4e-e811-a959-000d3ab27d56"; 
    var mediaType = 'application/octet-stream';
    // var nameDoucment = documentDownload['if_finalfilename'];
    console.log(documentDownload)

    return this.http
			.get(this.environment.urlServer + 'Documents/Download/' + documentDownload, { headers: headers, observe: 'response', responseType: 'blob'  })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          var blob = new Blob([response.body], { type: mediaType });
          saveAs(blob, nameDownload);
          return response.body;
        })
      );
  }

  public getShowDocumentWithIdForPdfViewer(getShowDocumentWithId: string): Observable<any> {
    const headers = this.authentificationService.headers;
    var ids = "ac0f506a-ea4e-e811-a959-000d3ab27d56"; 
    return this.http
			.get(this.environment.urlServer + 'Documents/View/' + getShowDocumentWithId , { headers: headers, observe: 'response', responseType: 'arraybuffer'  })  
			.pipe(map((response: HttpResponse<any>) => {
          // Get BLOB
          return response.body;
        })
      );
  }

  public getShowDocumentWithId(getShowDocumentWithId: string, if_finalfilename?: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
			.get(this.environment.urlServer + 'Documents/View/' + getShowDocumentWithId , { headers: headers, observe: 'response', responseType: 'blob'  })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          var fileURL = URL.createObjectURL(response.body);
          if (if_finalfilename !== undefined) {
            
              fileURL = URL.createObjectURL(new Blob(
                [ response.body ],
                { type: this.GetContentTypeByExtension('.' + if_finalfilename.split('.')[1]) }
              ));

              const fileName: string = if_finalfilename;
              const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

              a.href = fileURL;
              a.download = fileName;
              document.body.appendChild(a);
              a.click();        

              document.body.removeChild(a);
              // URL.revokeObjectURL(fileURL);
          
          }
          window.open(fileURL);

          // if (if_finalfilename !== undefined) {
          //   var blob = new Blob([response.body], { type: mediaType });
          //   saveAs(blob, if_finalfilename);
          // } else {
          //   var blob = new Blob([response.body]);
          //   saveAs(blob);
          // }

          return response.body;
        })
      );
  }
  

  public GetContentTypeByExtension(ext: string)
  {
      switch (ext)
      {
          case ".png":
              return "image/png";
          case ".jpg":
          case ".jpeg":
              return "image/jpeg";
          case ".pdf":
              return "application/pdf";
          case ".doc":
          case ".dot":
          case ".rtf":
              return "application/msword";
          case ".docx":
          case ".odt":
              return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
          case ".docm":
          case ".dotm":
              return "application/vnd.ms-word.document.macroEnabled.12";
          case ".tif":
          case ".tiff":
              return "image/tiff";
          case ".txt":
              return "text/plain";
          case ".xls":
          case ".xlt":
              return "application/vnd.ms-excel";
          case ".xlsx":
              return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          case ".xltx":
              return "application/vnd.openxmlformats-officedocument.spreadsheetml.template";
          case ".xlsm":
              return "application/vnd.ms-excel.sheet.macroEnabled.12";
          case ".xltm":
              return "application/vnd.ms-excel.template.macroEnabled.12";
          default:
              return "application/octet-stream";
      }
  }

  public postDocumentDer(idDer: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'DerDocuments/Send/' + idDer, { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
}
