import { UserService } from './user.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class GestOptionService {

  beforeHeaders: any;
  // static URL = environment.urlGestOptions;
  environment: any;

	constructor(private http: HttpClient, private userService: UserService, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public putCreateProposition(idLot: string, idProject: string, idAppointmentR3: any, dateRdvPostOption: any): Observable<any> {
    this.beforeHeaders = this.authentificationService.headers;
    let headers = {
      'accept': 'text/plain',
      'Content-Type': 'application/json' };

    let proposition: any;
    proposition =  {
        "AssetId": idLot,
        "ProjectId": idProject,
        "SellerId": this.userService.user['if_commercialassignmentid'],
        "PerformerId": this.userService.user['if_commercialassignmentid'],
        "Source": "VitrineB2B"
      }

    if (idAppointmentR3 !== null) {
      proposition.if_r_appointmentid = idAppointmentR3;
    }

    if (dateRdvPostOption !== null) {
      proposition.if_estimatedsigningdate = dateRdvPostOption;
    }

    return this.http
      .put(this.environment.urlGestOptions + 'Propositions/CreateProposition', proposition, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.authentificationService.headers = this.beforeHeaders;
          return response.body;
        })
      );
  }

  public postCancelProposition(idProposition: string): Observable<any> {
    this.beforeHeaders = this.authentificationService.headers;
    let headers = {
      'accept': 'text/plain',
      'Content-Type': 'application/json' };

    const proposition =  {
        "PropositionId": idProposition
      }

    return this.http
      .post(this.environment.urlGestOptions + 'Propositions/CancelProposition', proposition, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.authentificationService.headers = this.beforeHeaders;
          return response.body;
        })
      );
  }

  public postPropositionPreReservation(idProposition: string): Observable<any> {
    this.beforeHeaders = this.authentificationService.headers;
    let headers = {
      'accept': 'text/plain',
      'Content-Type': 'application/json' };

    const proposition =  {
        "PropositionId": idProposition
      }

    return this.http
      .post(this.environment.urlGestOptions + 'Propositions/PreReservation', proposition, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.authentificationService.headers = this.beforeHeaders;
          return response.body;
        })
      );
  }

  public getParametersOption(): Observable<any> {
    this.beforeHeaders = this.authentificationService.headers;
    let headers = {
      'accept': 'text/plain',
      'Content-Type': 'application/json' };

    return this.http
      .get(this.environment.urlGestOptions + 'Parameters', { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.authentificationService.headers = this.beforeHeaders;
          return response.body;
        })
      );
  }

}