import { ConfigObservableService } from './service-config-observable-service.service';
import { AuthentificationService } from './authentification.service';
import { User } from 'src/app/shared/models/user';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { Operation } from '../models/operation';
import { OperationCard } from '../models/operation-card';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  environment: any = null;

  initMap: boolean = true;

  initView: boolean = false;
  namePageActive: string = 'stock';

  isActiveMenu: string = 'mixte'
  showFooter: Boolean = false;
  showMoreCriteria: Boolean = false;

  initScrollMinOperation: number = 875;
  initScrollMaxOperation: number = 0;

  emitOperationLoad: Boolean = false;

  numberOperation: number = 0;
  nbPagesOperation: number = 1;
  
  initMapMarker: any = {};
  initAllDataMapMarker: Array<any> = new Array<any>();
  
  pageOperationLoad: number = 0;
  descriptionOperationActive: Boolean = false;
  activeMapOperation: Boolean = true;
  dataOperationActive: Operation = new Operation();
  tabOperationActive: Array<OperationCard> = new Array<OperationCard>();

  researchActive: Array<any> = new Array<any>();

	constructor(private http: HttpClient,private authentificationService: AuthentificationService, private userService: UserService,
              private serviceConfigObservable: ConfigObservableService) {
                this.environment = environment;
              }

  public getOperationWithMapMarkers(mapMarker: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.post(this.environment.urlServer + 'Operations/MapMarkers', JSON.stringify(mapMarker), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.initAllDataMapMarker = response.body
          this.nbPagesOperation = response.body.length / 1000
					return response.body;
				})
      );
  }

  public getAllNumberOperationWithSizeMap(mapMarker: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.post(this.environment.urlServer + 'Operations/Search/Count', mapMarker !== undefined ? mapMarker : {}, { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
					return response.body.datas;
				})
      );
  }

  public getOperationWithLocalization(mapMarker: any): Observable<any> {
    const headers = this.authentificationService.headers;
    this.initMapMarker = mapMarker;
    // if (this.initAllDataMapMarker !== undefined ) {
    //   if (this.initAllDataMapMarker.length >= 6) {
    //     this.nbPagesOperation = this.initAllDataMapMarker.length / 1
    //   }
    // }

    this.pageOperationLoad += 1;

    if (this.nbPagesOperation === 0) {
      this.serviceConfigObservable.emitChoiceInfinityScroll('choice 1')
    }

    return this.http
			.post(this.environment.urlServer + 'Operations/Search?Page=' + this.pageOperationLoad + '&PageSize=' + 1000, JSON.stringify(mapMarker), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (response.body < 6 && this.nbPagesOperation <= 1) {
            this.serviceConfigObservable.emitHiddenOrShowFooter(true);
            this.serviceConfigObservable.emitClassContainerMap("choice 3");
          }
					return response.body;
				})
      );
  }

  public getOperationAll(mapMarker: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.post(this.environment.urlServer + 'Operations/Search?Page=' + 1 + '&PageSize=' + 10000, JSON.stringify(mapMarker), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
					return response.body;
				})
      );
  }


  getOperationWithLocalizationForPostOption(nbPages: number, nbProgramsForPage: number, mapMarker: any): Observable<any> {
    const headers = this.authentificationService.headers;
    this.initMapMarker = mapMarker;

    return this.http
			.post(this.environment.urlServer + 'Operations/Search?Page=' + nbPages + '&PageSize=' + 1000, JSON.stringify(mapMarker), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (response.body < 6 && this.nbPagesOperation <= 1) {
            this.serviceConfigObservable.emitHiddenOrShowFooter(true);
            this.serviceConfigObservable.emitClassContainerMap("choice 3");
          }
					return response.body;
				})
      );
  }

  public getTypologyWithIdOperation(id: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'Operations/' + id + '/Typologies', { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getTypologiesWithOperationsId(ids: Array<any>): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.post(`${this.environment.urlServer}Typologies/OperationsTypologies`,JSON.stringify(ids), { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }


  public getNumberAllOperation(roleUserChoice: User): Observable<any> {

    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Authorization': roleUserChoice['if_commercialassignmentid'],
      'Content-Type': 'application/json' });
      
		return this.http
			.get(this.environment.urlServer + 'Operations/Count', { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.userService.user = roleUserChoice;
          this.numberOperation = response.body;
					return response.body;
				})
      );
	}

  public getOperationId(id: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'Operations/' + id, { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getOperationThumbnailsWithId(id: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'Operations/' + id + '/Thumbnails', { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getOperationParticularitiesWithId(id: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'Operations/' + id + '/Particularities', { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getOperationDocumentWithId(id: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'Operations/' + id + '/Documents', { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getAssetsWithOperationId(id: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.get(this.environment.urlServer + 'Operations/' + id + '/Assets', { headers: headers, observe: 'response' })  
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getAssetsCounterWithOperationId(id: any): Observable<any>{
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Operations/' + id + '/AssetCounters', {headers: headers})
      .pipe(map((response: any) => {
        console.log(response);
        return response;
      }))
  }
}
