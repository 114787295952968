import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageLoadingServiceInovia {

  constructor() { }

  // Titre popup loading
  loadingTitle = {
    loadingTitleOne: "Loading ...",
    loadingTitleOption: "Votre option est validée",
    loadingTitleSignDER: "Envoi du DER en signature"
  }    
  
  loadingText = {
    loadingTextBookOfSale: "Veuillez patienter votre book de vente est en préparation",
    loadingTextDocument: "Veuillez patienter nous réunissons vos documents",
    loadingTextPrepareDocument: "Veuillez patienter nous préparons votre document",
    loadingTextProjectCreate: "Projet en cours de création..",
    loadingTextProjectModification: "Projet en cours de modification..",
    loadingTextRdvCreate: "Rendez-vous en cours de création..",
    loadingTextRdvModification: "Rendez-vous en cours de modification..",
    loadingTextRdvClose: "Rendez-vous en cours de fermeture..",
    loadingTextSynchroProject: "Synchronisation du projet en cours..",
    loadingTextSynchroRdv: "Synchronisation du rendez-vous en cours..",
    loadingTextSynchroOption: "Synchronisation de l'option en cours..",
    loadingTextProjectTimeOut: "Le projet met plus de temps que prévu..",
    loadingTextOptionTimeOut: "L\'option met plus de temps que prévu..",
    loadingTextOption: "Pose d’option en cours..",
    loadingTextOptionCancel: "Option en cours d’annulation..",
    loadingTextPreResa: "Déclaration de la pré-réservation en cours..",
    loadingTextSaveResearch: "Enregistrement de la recherche en cours..",
    loadingTextResearch: "Votre recherche à bien été sauvegardée"
  }
}