<div class="containerPopupPostOption">
    <!-- Container title popup with mat dialog title -->
    <div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
        <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
            <div class="containerTitleImg">
                <img *ngIf="subModuleUse === 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
                <img *ngIf="subModuleUse !== 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/picto-popup.png">
                <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>      
            <div>
        </div>
    </div>
    
    <!-- Container content popup cancel option -->
    <mat-dialog-content class="containerMatContent" [style]="'background:' + this.objectReceivePopup.backgroundColorContent + '; color:' + this.objectReceivePopup.colorContent">
        <div *ngIf="this.objectReceivePopup.namePopup === 'postOptionDenunciation'" class="containerContent">
            <div class="d-flex flex-column mb-3 containerInformationOption">
                <span>Résidence</span>
                <span>{{ objectReceivePopup.dataPopup['Résidence'] }}</span>
                <span>{{ objectReceivePopup.dataPopup['Contact'] }}</span>
            </div>
            <form class="d-flex flex-row justify-content-between containerFormPostOptionDenunciation" [formGroup]="insertPostOptionDenunciationForm">
                <div class="d-flex flex-column containerSelectAsset">
                    <span class="underLabelField">Choix du lot à optionner</span>
                    <!-- formControl nameAsset -->
                    <ng-select *ngIf="tabOptionAsset.length !== 0" formControlName="nameAsset" class="ngSelectPostOption" placeholder="Lot"
                        [items]="tabOptionAsset" bindLabel="name" bindValue="id"
                        [closeOnSelect]="true" [hideSelected]="true">
                    </ng-select>
                </div>
                <div class="divDatePicker">
                    <span class="underLabelField">Date de RDV</span>
                    <mat-form-field class="formFieldDatePicker" floatLabel="never">
                        <mat-label>{{ labelDateRdv }}</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="dateRdv">
                        <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </form>
        </div>
    
        <div *ngIf="this.objectReceivePopup.namePopup === 'postOptionStock'" class="containerContent">
            <div class="d-flex flex-column mb-3 containerInformationOption">
                <span>Résidence</span>
                <span>{{ objectReceivePopup.dataPopup['Résidence'] }}</span>
                <span>{{ objectReceivePopup.dataPopup['Lot'] }}</span>
            </div>
            <form class="d-flex flex-row justify-content-between containerFormPostOptionDenunciation" [formGroup]="insertPostOptionStockForm">
                <div class="d-flex flex-column containerSelectAsset">
                    <span class="underLabelField">Choix de la dénonciation</span>
                    <!-- formControl denunciation -->
                    <ng-select *ngIf="tabOptionDenunciation.length !== 0" formControlName="denunciation" class="ngSelectPostOption" placeholder="Dénonciation"
                        [items]="tabOptionDenunciation" bindLabel="name" bindValue="id"
                        [closeOnSelect]="true" [hideSelected]="true">
                    </ng-select>
                </div>
                <div class="divDatePicker">
                    <span class="underLabelField">Date de RDV</span>
                    <mat-form-field class="formFieldDatePicker" floatLabel="never">
                        <mat-label>{{ labelDateRdv }}</mat-label>
                        <input matInput [matDatepicker]="picker1" formControlName="dateRdv">
                        <mat-datepicker-toggle matSuffix [for]="$any(picker1)"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </mat-dialog-content>
    
    <div *ngIf="this.objectReceivePopup.namePopup === 'postOptionDenunciation'" class="containerAction">
        <button mat-dialog-close class="btnActionAnnulation">Annuler</button>
        <button (click)="onSubmitPostOptionDenunciation()" class="btnActionValidation">Valider</button>
    </div>
    
    <div *ngIf="this.objectReceivePopup.namePopup === 'postOptionStock'" class="containerAction">
        <button mat-dialog-close class="btnActionAnnulation">Annuler</button>
        <button (click)="onSubmitPostOptionStock()" class="btnActionValidation">Valider</button>
    </div>
</div>