import { ReceivePopupCustomButton } from "./receive-popup-custom-button";

export class ReceivePopupCustom {
    public "size"!: number;
    public "namePopup"!: string;

    public "title"!: string;
    public "text"!: string;
    public "innerHtml"!: string;
    public "objectCheckbox"!: any;
    public "activateLoading"!: boolean;
    public "activateCheckbox"!: boolean;
    public "activateButton"!: ReceivePopupCustomButton | null;

    public "id": string;
}
