<!-- Container title popup with mat dialog title (new project, deduplication contact and rule project) -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="!activePopupDeduplication && !activePopupRuleProject" [ngClass]="{ 'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Nouveau projet</h1>
            <h1 *ngIf="activePopupDeduplication" [ngClass]="{ 'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Dédoublonnage : Ce contact existe déjà</h1>
            <h1 *ngIf="activePopupRuleProject" [ngClass]="{ 'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Statuer le projet</h1>        
        </div>
        <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup new project -->
<mat-dialog-content *ngIf="!activePopupDeduplication && !activePopupRuleProject" [ngClass]="{'containerMatDialog': data.size >= 2, 'containerMatDialogSM': data.size < 2 }">
    <form [ngClass]="{'postNewProjectForm': data.size >= 2, 'postNewProjectFormSM': data.size < 2 }" [formGroup]="insertNewProjectForm">
        <div [ngClass]="{'containerMatFormField': data.size >= 2, 'containerMatFormFieldSM': data.size < 2 }">
            <span *ngIf="data.size < 2" class="titleNatureProjectSM">Nature du projet : </span>
            <section class="sectionCheckboxNewProject">
                <span *ngIf="data.size > 2" class="titleNatureProject">Nature du projet : </span>
                <mat-checkbox class="immovableCheckBox" formControlName="immovable" (change)="changeValueImmovable($event, 'immovable')">Immobilier</mat-checkbox>
                <mat-checkbox formControlName="financial" (change)="changeValueImmovable($event, 'financial')">Placement & Services</mat-checkbox>
            </section>
            <span *ngIf="activeMessageErrorCheckBox" class="errorMessageCheckBox">Le type de projet est obligatoire</span>

            <!-- formControl firstName and error -->
            <mat-form-field class="formFieldNewProjectRequired" appearance="fill">
                <mat-label class="labelField">{{ labelFirstName }}</mat-label>
                <input type="text"
                    matInput
                    formControlName="firstname"
                    >
                <mat-error *ngIf="this.insertNewProjectForm.controls.firstname.invalid">
                    {{ getErrorMessageRequired(this.insertNewProjectForm.controls.firstname, "firstName") }}
                </mat-error>
            </mat-form-field>

            <!-- formControl lastName and error -->
            <mat-form-field class="formFieldNewProjectRequired" appearance="fill">
                <mat-label class="labelField">{{ labelLastName }}</mat-label>
                <input type="text"
                    matInput
                    formControlName="lastname"
                    >
                <mat-error *ngIf="this.insertNewProjectForm.controls.lastname.invalid">
                    {{ getErrorMessageRequired(this.insertNewProjectForm.controls.lastname, "lastName") }}
                </mat-error>
            </mat-form-field>

            <!-- formControl email and error -->
            <mat-form-field class="formFieldNewProjectRequired" appearance="fill">
                <mat-label class="labelField">{{ labelEmail }}</mat-label>
                <input type="email"
                    matInput
                    formControlName="email"
                    >
                <mat-error *ngIf="this.insertNewProjectForm.controls.email.invalid">
                    {{ getErrorMessageEmail() }}
                </mat-error>
            </mat-form-field>

            <!-- formControl mobile phone and error -->
            <mat-form-field class="formFieldNewProjectRequired" appearance="fill">
                <mat-label class="labelField">{{ labelMobilePhone }}</mat-label>
                <input
                    matInput
                    formControlName="mobilePhone"
                    >
                <mat-error *ngIf="this.insertNewProjectForm.controls.mobilePhone.invalid">
                    {{ getErrorMessageRequired(this.insertNewProjectForm.controls.mobilePhone, "mobilePhone") }}
                </mat-error>
            </mat-form-field>

            <!-- formControl phone and error -->
            <mat-form-field class="formFieldNewProject" appearance="fill">
                <mat-label class="labelField">{{ labelPhone }}</mat-label>
                <input
                    matInput
                    formControlName="phone"
                    >
                <mat-error *ngIf="this.insertNewProjectForm.controls.phone.invalid">
                    {{ getErrorMessageRequired(this.insertNewProjectForm.controls.phone, "phone") }}
                </mat-error>
            </mat-form-field>

            <!-- <span *ngIf="activeMessageErrorSelect" class="errorMessageSelect">L'origine du projet est requis</span> -->

            <div style="margin-top: 0px;" [ngClass]="{'divOriginNewProject': data.size < 2}">
                <!-- formControl origin and error with span ngif -->
                <span *ngIf="isOpenSelect.origin" class="spanSelectOrigin">Origine</span>
                <ng-select *ngIf="listFieldOrigin.length !== 0" class="selectOrigin" formControlName="origin" placeholder="Origine"
                    [items]="listFieldOrigin" bindLabel="name" bindValue="id" (click)="openSelectAndReset('origin', insertNewProjectForm.value.origin, isOpenSelect)" (clear)="openSelectAndReset('origin', insertNewProjectForm.value.origin, isOpenSelect)"
                    [closeOnSelect]="true" [multiple]="false" [hideSelected]="true">
                </ng-select>
                <span *ngIf="activeMessageErrorSelect" class="errorMessageSelect">L'origine du projet est requis</span>
            </div>

            <!-- formControl address one -->
            <mat-form-field class="formFieldNewProject" appearance="fill">
                <mat-label class="labelField">{{ labelAddressOne }}</mat-label>
                <input type="text"
                    matInput
                    formControlName="addressOne"
                    >
            </mat-form-field>

            <!-- formControl address two -->
            <mat-form-field class="formFieldNewProject" appearance="fill">
                <mat-label class="labelField">{{ labelAddressTwo }}</mat-label>
                <input type="text"
                    matInput
                    formControlName="addressTwo"
                    >
            </mat-form-field>

            <!-- formControl zipCode and error -->
            <mat-form-field class="formFieldNewProject" appearance="fill">
                <mat-label class="labelField">{{ labelZipCode }}</mat-label>
                <input
                    matInput 
                    formControlName="zipCode"
                    >
            </mat-form-field>
            
            <div style="margin-top: -5px;">
                <!-- formControl city and error with span ngif -->
                <span *ngIf="isOpenSelect.city" class="spanSelectCity">Ville</span>
                <ng-select #cityTerm class="selectCityNewProject" formControlName="city" placeholder="Ville"
                    [items]="listFieldCity" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, cityTerm)"
                    [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('city', insertNewProjectForm.value.city, isOpenSelect)" (clear)="openSelectAndReset('city', insertNewProjectForm.value.city, isOpenSelect)">
                </ng-select>
            </div>
        </div>
        <div [ngClass]="{'containerBtnActionNewProject': data.size >= 2, 'containerBtnActionNewProjectSM': data.size < 2 }">
            <button mat-dialog-close [ngClass]="{'btnCancel': data.size >= 2, 'btnCancelSM': data.size < 2 }" type="button" >Annuler</button>
            <button mat-icon-button [ngClass]="{'btnCreate': data.size >= 2, 'btnCreateSM': data.size < 2 }" (click)="onSubmitCreateProject(false)" >Créer le projet</button>
            <button mat-icon-button class="btnCreateCompleted" (click)="onSubmitCreateProject(true)" >Créer et compléter</button>
        </div>
    </form>
</mat-dialog-content>

<!-- Container content popup deduplication contact -->
<mat-dialog-content *ngIf="activePopupDeduplication" class="containerAssociateContact">
    
    <!-- <p *ngIf="listContactExist.length === 1" class="titleDeduplication">Vous allez créer un nouveau projet pour un contact qui existe déjà, nous vous proposons donc d'associer ce projet au contact déjà présent en base :</p>
    <p *ngIf="listContactExist.length > 1" class="titleDeduplication">Vous allez créer un nouveau projet pour un contact qui a été identifié plusieurs fois en base de données. Veuillez sélectionner le contact existant auquel vous souhaitez associer ce nouveau projet :</p> -->
    
    <p class="titleDeduplication">Vous allez créer un nouveau projet pour un contact qui existe déjà dans la base de données. Nous allons donc associer ce projet au contact existant et récupérer ses données</p>
    <div class="containerCheckboxContact" *ngFor="let contactExist of listContactExist; let i = index">
        <div style="width: 100px">
            <mat-checkbox class="checkboxContactExist" [(ngModel)]="contactExist.checked" [disabled]="contactExist.checked" (change)="changeContactExist(i)"></mat-checkbox>
        </div>
        <p class="paragrapheCheckbox">{{ contactExist.lastname }} {{ contactExist.firstname }} <br>
        <!-- Date de création : {{ contactExist.datecreate }}<br> -->
        Tel : {{ contactExist.tel }}<br>
        Mobile : {{ contactExist.mobile }}<br>
        Mail : {{ contactExist.mail }}<br>
        Adresse : {{ contactExist.adresse }}
    </p>
    </div>
    <div class="containerBtnAssociateContact">
        <button class="btnCancelAssociateContact" [ngClass]="{ 'btnPostOptionSM': data.size < 2 }" (click)="closeAssociateContact()">Annuler</button>
        <button class="btnAssociateContact" [ngClass]="{ 'btnPostOptionSM': data.size < 2 }" (click)="associateContact()">Associer au contact</button>
    </div>
</mat-dialog-content>

<!-- Container content popup rule project -->
<mat-dialog-content *ngIf="activePopupRuleProject">
    <p [ngClass]="{'paragrapheRuleProject': data.size >= 2, 'paragrapheRuleProjectSM': data.size < 2 }">Projet : {{ data.dataPopup.event2.if_name }}<br></p>
    <form [ngClass]="{'ruleProjectForm': data.size >= 2, 'ruleProjectFormSM': data.size < 2 }"  [formGroup]="insertRuleProjectForm" (ngSubmit)="onSubmitPostFormRule(disabledButton = true)">
        <table cellspacing="0">
            <tr>
                <td>

                    <span *ngIf="isOpenSelect.conclusionCode" class="spanSelectCode">Code conclusion</span>
                    <!-- formControl conclusionCode -->
                    <ng-select *ngIf="listFieldConclusionCode.length !== 0" [ngClass]="{'selectConclusionCode': data.size > 2, 'selectConclusionCodeMD': data.size == 2, 'selectConclusionCodeSM': data.size < 2 }" formControlName="conclusionCode" placeholder="Code conclusion"
                        [items]="listFieldConclusionCode" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('conclusionCode', insertRuleProjectForm.value.conclusionCode, isOpenSelect)" (clear)="openSelectAndReset('conclusionCode', insertRuleProjectForm.value.conclusionCode, isOpenSelect)">
                    </ng-select>
                </td>
            </tr>
        </table>
        <table cellspacing="0">
            <tr [ngClass]="{'trNewProject': data.size >= 2, 'trNewProjectSM': data.size < 2}">
                <td>
                    <span *ngIf="requiredComment" class="commentRequired">Le commentaire est requis</span>
                    <!-- formControl comment -->
                    <mat-form-field [ngClass]="{'formFieldCommentRuleProject': data.size > 2, 'formFieldCommentRuleProjectMD': data.size == 2, 'formFieldCommentRuleProjectSM': data.size < 2 }" appearance="fill">
                        <mat-label class="labelField">{{ labelComment }}</mat-label>
                        <input type="text"
                            matInput
                            formControlName="comment"
                            >
                    </mat-form-field>
                </td>
            </tr>
        </table>
        <div class="containerBtnRuleProject">
            <button mat-dialog-close class="btnCancelProject" [disabled]="disabledButton">Annuler</button>
            <button class="btnCreateProjectRule" type="submit" [disabled]="disabledButton">Statuer le projet</button>
        </div>
    </form>
</mat-dialog-content>