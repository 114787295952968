import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class LegalNoticeService {

  environment: any = null;
  // static URL = environment.urlServer;

	constructor(private http: HttpClient, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getLegalNotice(): Observable<any> {

    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Users/LegalNotices', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }
}
