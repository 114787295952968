import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { ReferentialProjectAppointmentRdvType } from './../../../../shared/models/referential-project-appointment-rdv-type';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from './../../../../shared/services/snackbar.service';
import { ReferentialProjectInProgress } from './../../../../shared/models/referential-project-in-progress';
import { AppointmentService } from 'src/app/shared/services/appointment.service';
import { CodeConclusion } from './../../../../shared/models/code-conclusion';
import { CityService } from 'src/app/shared/services/city.service';
import { ReferentialAppointmentLocation } from './../../../../shared/models/referential-appointment-location';
import { ReferentialAppointmentModality } from './../../../../shared/models/referential-appointment-modality';
import { ProjectService } from 'src/app/shared/services/project.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { convertTZ, getColorRdv, isNullFieldForm, transformDateFormatHoursPipe } from './../../../../shared/services/functions'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupNewProjectComponent } from '../popup-new-project/popup-new-project.component';
import { first, takeUntil } from 'rxjs/operators';
import { PopupLoadingComponent } from '../popup-loading/popup-loading.component';
import { HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivityApiService } from 'src/app/shared/services/activity-api.service';
import { Subject } from 'rxjs';

// Interface list of color for new rdv
export interface listColor {
  nameColor: string;
  color: string;
}

// Interface for send several data in the popup for the save of research
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  rdv: any;
}

@Component({
  selector: 'app-popup-form-rdv',
  templateUrl: './popup-form-rdv.component.html',
  styleUrls: ['./popup-form-rdv.component.scss']
})
export class PopupFormRdvComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  time: any = {hour: 13, minute: 30}

  timeDuring: any = null;

  subModuleUse: string;

  activeSelectType: boolean = false;

  requiredComment: boolean = false;

  // Declaration & Init date of today
  dateToday: Date = new Date();

  // Declaration & Init name popup get of parents
  namePopup = this.data.namePopup;

  // Declaration & Init data for show appointment selected
  showRdv: any = null

  // Declaration & Init data for edit appointment or put the appointment selected
  dataRdv: any = null

  // Declaration & Init data for close the appointment selected
  ruleRdv: any = null

  // Declaration & Init data for disable date if the name popup is a modify rdv
  disableHoraire: boolean =  this.data.namePopup === 'modifyRdv' ? true : false;

  // Declaration & Init array for select the done values for appointment selected at close 
  listRdvDoneValues: Array<any> = [];

    // Declaration & Init array for select the time during for appointment selected at close 
  listFieldTimeDuring: Array<any> = [];

  // Declaration & Init name project get of parents if the data is different of undefined
  project: any = null;
  
  // Declaration & Init name category get of parents if the data is different of undefined
  category: any = null;

  // Declaration & Init start date rdv get of parents if the data is different of undefined
  startDate: Date = new Date();

  // Declaration & Init end date rdv get of parents if the data is different of undefined
  endDate: Date = new Date(this.dateToday.setHours(this.dateToday.getHours() + 1));

  // Declaration & Init modality rdv get of parents if the data is different of undefined
  modality: any = null;

  // Declaration & Init location rdv get of parents if the data is different of undefined
  location: any = null;

  // Declaration & Init city rdv get of parents if the data is different of undefined
  city: any = null;
  
  // Declaration & Init address one rdv get of parents if the data is different of undefined
  addressOne: string = "";

  // Declaration & Init subject rdv get of parents if the data is different of undefined
  subject: string = "";

  // Declaration & Init address two rdv get of parents if the data is different of undefined
  addressTwo: string = "";

  // Declaration & Init zip code rdv get of parents if the data is different of undefined
  zipCode: string = "";
  
  // Declaration & Init comment rdv get of parents if the data is different of undefined
  comment: string = "";
  
  // Declaration & Init internal memo rdv get of parents if the data is different of undefined
  internalMemo: string = "";

  // Active or not field location
  activeLocation: Boolean = false;
  // Active or not field address of client
  activeAddressClient: Boolean = false;
  // Active or not ===> class on ng-select name project and button add new project
  activeDisableTitle: boolean = false;

  // Active or not error message on the ng-select
  activeErrorMessageSelect: Boolean = false;
  // Active or not error message on the ng-select location
  errorMessageSelectLocation: Boolean  = false;
  // Active or not error message on the input location
  errorMessageInputAddress: Boolean = false;

  isOpenSelect = {
    city: false,
    conclusionCode: false
  }

  // All label for the created, modify and rule rdv  
  labelProjet: string = 'Concernant';
  labelSubject: string = "Sujet"
  labelAddressOne: string = 'Adresse 1';
  labelAddressTwo: string = 'Adresse 2';
  labelZipCode: string = 'Code postal';
  labelCity: string = 'Ville';
  labelComment: string = 'Commentaire';
  labelInternalMemo: string = 'Note interne';
  labelStartDate: string = "";
  labelEndDate: string = "";
  labelDateRdvClose: string = 'Date de fermeture'

  // All the array for the list of ng-select in the created, modify and rule rdv
  listFieldCategory: Array<any> = [];
  listFieldModality: Array<any> = [];
  listFieldLocation: Array<any> = [];
  listFieldProject: Array<any> = [];
  listFieldCity: Array<any> = [];
  listFieldConclusionCode: Array<any> = [];

  // Declaration & Init form group with validator error for the form create and modify rdv
  insertRdvForm: FormGroup = this.formBuilder.group({
    category: [ null, Validators.required ],
    project: [ null, Validators.required ],
    subject: [ "" ],
    ckeckBoxPlanifyCalendar: false,
    startDate: [ { value: this.startDate, disabled: this.disableHoraire }, Validators.required ],
    endDate: [ { value: this.endDate, disabled: this.disableHoraire }, Validators.required ],
    timeDuring: [ { value: this.timeDuring, disabled: this.disableHoraire }, Validators.required ],
    time: [ { value: this.time, disabled: this.disableHoraire }, Validators.required ],
    modality: [ null, Validators.required ],
    location: [ null ],
    addressOne: [ "" ],
    addressTwo: [ "" ],
    zipCode: [ "" ],
    city: [ null ],
    comment: [ "" ],
    internalMemo: [ "" ]
  })

  // Declaration & Init form group with validator error for the form rule rdv
  insertRuleRdvForm: FormGroup = this.formBuilder.group({
    dateRdvClose: [ { value: new Date(), disabled: true }, Validators.required ],
    rdvPastYes: [ false, Validators.required ],
    rdvPastNo: [ false, Validators.required ],
    conclusionCode: [ null, Validators.required ],
    comment: [ "", Validators.required ]
  })

  // Declaration for receive value baf synchronized true or false
  valueResponseBafSynchronize: any;

  // Construction : Declaration & Init --> Private var form builder for the construction of form group, Public var for mat dialog of create of new project 
  // Public var for mat dialog of loading, Public var for mat dialog of form of appointment, Service project for get/post/put data project in api, 
  // Public dialog ref for the popup form appointment and for the popup loading, Injectable data mat dialog of parent - root to popup choice
  constructor(
    private formBuilder: FormBuilder, public popupNewProject: MatDialog, public popupLoading: MatDialog, public popupFormRdv: MatDialog, private snackbar: SnackbarService,
    private serviceErrorMessage: ErrorMessageService, private serviceProject: ProjectService, public dialogRef: MatDialogRef<PopupFormRdvComponent>, 
    public dialogRefLoading: MatDialogRef<PopupLoadingComponent>, private serviceCity: CityService, private serviceAppointment: AppointmentService, 
    private serviceActivityApi: ActivityApiService, private servicePopup: PopupServiceService,
    private serviceConfigObservable: ConfigObservableService,@Inject(MAT_DIALOG_DATA) public data: DialogDataPopup) {
      this.subModuleUse = environment.subModuleUse;
    }

  // Init and disable field and init value in the form group modify or create rdv
  ngOnInit() {
    // If the name of the popup choice is different of showRdv (show appointment) and ruleRdv (close appointment)-------------------
    if (this.namePopup !== 'showRdv' && this.namePopup !== 'ruleRdv') {
      let type: number = 750010000;
      this.listFieldTimeDuring = [{ 
        name: '30 min.', id: { name: '30 min.', id: '30' 
      }}, {
          name: '1 heure', id: { name: '1 heure', id: '60' 
      }}, {
        name: '1 heure 30', id: { name: '1 heure 30', id: '90' 
      }}, {
        name: '2 heures', id: { name: '2 heures', id: '120' 
      }}, {
        name: '3 heures', id: { name: '3 heures', id: '180' 
      }}, {
        name: '4 heures', id: { name: '4 heures', id: '240' 
      }}, {
        name: '5 heures', id: { name: '5 heures', id: '300' 
      }}, {
        name: '6 heures', id: { name: '6 heures', id: '360' 
      }}, {
        name: '7 heures', id: { name: '7 heures', id: '420' 
      }}]
      // If the name popup choice is modifyRdv (modify appointment)----------------------------------------------------------------- 
      if (this.namePopup === 'modifyRdv') {

        if (this.data.rdv['appointment.if_modalities'] !== null) {
          if (this.data.rdv['appointment.if_modalities'] !== 750010000) {
            this.activeLocation = false;
            this.activeAddressClient = false;
          } else  {
            this.activeLocation = true;
          }
        } else {
          this.activeLocation = false;
          this.activeAddressClient = false;
        }
      
        if (this.data.rdv['appointment.if_location'] !== null) {
          if (this.data.rdv['appointment.if_location'] !== 750010002) {
            this.activeAddressClient = false;
          } else  {
            this.activeAddressClient = true;
          }
        }  else {
          this.activeAddressClient = false;
        }
        let timeDuringCalc = null;
        // console.log(this.data, '----------------------------------------------------------------------------------------')
        // console.log(new Date(this.data.rdv.newEnd).getTime() - new Date(this.data.rdv.newStart).getTime())
        // console.log(new Date(this.data.rdv['appointment.scheduledend']).getTime() - new Date(this.data.rdv['appointment.scheduledstart']).getTime())
        if (this.data.rdv.activDragAndDrop)  {
          timeDuringCalc = new Date(this.data.rdv.newEnd).getTime() - new Date(this.data.rdv.newStart).getTime()
        } else {
          timeDuringCalc = new Date(this.data.rdv['appointment.scheduledend']).getTime() - new Date(this.data.rdv['appointment.scheduledstart']).getTime()
        }
        console.log(timeDuringCalc, 'ok')
        let seconds = Math.floor(timeDuringCalc / 1000);
        let minutes = Math.floor(seconds / 60);
        // let minutes = Math.floor((timeDuringCalc / (1000 * 60)) % 60)
        console.log(minutes)

        if (minutes == 30) {
          this.timeDuring = { name: '30 min.', id: '30' }
        } else if (minutes == 60) {
          this.timeDuring = { name: '1 heure', id: '60' }
        } else {
          let hours = minutes / 60
          let newMinutes = hours - Math.floor(hours)
          let minutesText = newMinutes !== 0 ? ' ' + newMinutes * 60 : ''
          if (hours == 1 && minutes == 30) {
            this.timeDuring = { name: Math.floor(hours) + ' heure' + minutesText, id: minutes }
          } else {
            this.timeDuring = { name: Math.floor(hours) + ' heures' + minutesText, id: minutes }
          }
        } 
        
        console.log(convertTZ(this.data.rdv.newStart))
        console.log(convertTZ(this.data.rdv['appointment.scheduledstart']))
        let newTime = this.data.rdv.activDragAndDrop ? transformDateFormatHoursPipe(new Date(this.data.rdv.newStart)).split(':') : transformDateFormatHoursPipe(convertTZ(this.data.rdv['appointment.scheduledstart'])).split(':')
        this.time = { hour: parseInt(newTime[0]), minute: parseInt(newTime[1]) }

        // En attente du champ de l'API
        if (this.data.rdv['appointment.if_lock_appointmentdates'] !== 1) {
          this.disableHoraire = false;
          this.insertRdvForm.controls['startDate'].enable()
          this.insertRdvForm.controls['endDate'].enable()
          this.insertRdvForm.controls['timeDuring'].enable()
          this.insertRdvForm.controls['time'].enable()
          this.insertRdvForm.patchValue({
            timeDuring: this.timeDuring,
            time: this.time,
          })
        } else {
          this.listFieldTimeDuring = [this.timeDuring]
        }

        // console.log(this.data, '-------------------------------------------------------------------------------', new Date(this.data.rdv.newStart), new Date(this.data.rdv.newEnd).toISOString())
        this.dataRdv = {
          type: { name: this.data.rdv['aptypes.if_fluxsiteb2blabel'], id: this.data.rdv['aptypes.if_fluxsiteb2bcode'], color: true, guid: this.data.rdv['aptypes.if_appointmenttypesid'] },
          project: this.data.rdv['appointment.regardingobjectid_label'],
          subject: this.data.rdv['appointment.if_subjectcomplement'],
          modality: this.data.rdv['appointment.if_modalities'],
          location: this.data.rdv['appointment.if_location'],
          address: this.data.rdv['appointment.location'],
          comment: this.data.rdv['appointment.description'],
          internalMemo: this.data.rdv['appointment.if_privatecomments'],
          category: this.data.rdv['aptypes.if_fluxsiteb2bcod'],
          startDate: this.data.rdv.activDragAndDrop ? new Date(this.data.rdv.newStart).toISOString() : convertTZ(this.data.rdv['appointment.scheduledstart']),
          endDate: this.data.rdv.activDragAndDrop ? new Date(this.data.rdv.newEnd).toISOString() : convertTZ(this.data.rdv['appointment.scheduledend']),
          addressOne: this.data.rdv['appointment.if_address_street1'],
          addressTwo: this.data.rdv['appointment.if_address_street2'],
          zipCode: this.data.rdv['appointment.if_address_postalcode'],
          city: this.data.rdv['appointment.if_address_city'] !== null ? { name: this.data.rdv['appointment.if_address_city'] + " (" + this.data.rdv['appointment.if_address_postalcode'].substring(0, 2) + ")", id: this.data.rdv['appointment.if_address_cityid'] } : null,
          id: this.data.rdv['appointment.activityid'],
          projectId: this.data.rdv['project.if_projectid']
        }
      } // If the name popup choice is editRdvWithInProgress ( edit appointment if name project already choice )----------------------
      else if (this.namePopup === 'editRdvWithInProgress') {
        if (this.data.rdv.typeRdv === 'R1') {
          type = 750010001;
        } else if (this.data.rdv.typeRdv === 'R2') {
          type = 750010002
        } else if (this.data.rdv.typeRdv === 'R3') {
          type = 750010003
        } else if (this.data.rdv.typeRdv === 'R4') {
          type = 750010004
        }
        // this.dataRdv = {
        //   type: type,
        //   project: this.data.rdv['project.if_name'],
        //   subject: this.data.rdv.subject,
        //   modality: this.data.rdv.modality,
        //   location: this.data.rdv.location,
        //   address: this.data.rdv.address,
        //   comment: this.data.rdv.comment,
        //   internalMemo: this.data.rdv.internalMemo,
        //   category: this.data.rdv.category,
        //   startDate: this.startDate,
        //   endDate: this.endDate,
        //   addressOne: "",
        //   addressTwo: "",
        //   zipCode: "",
        //   city: "",
        //   id: "",
        //   projectId: this.data.rdv.projectId
        // }
        this.dataRdv = {
          type: type,
          project: this.data.rdv.event2['if_name'],
          subject: "",
          modality: "",
          location: "",
          address: "",
          comment: "",
          internalMemo: "",
          category: "",
          startDate: this.startDate,
          endDate: this.endDate,
          addressOne: "",
          addressTwo: "",
          zipCode: "",
          city: "",
          id: "",
          projectId: this.data.rdv !== undefined ? this.data.rdv.projectId : ""
        }
      } // If the name popup choice is editRdv ( edit appointment if name project is not choice ) --------------------------------------
      else if (this.namePopup === 'editRdv') {
        if (this.data.rdv.typeRdv === 'R1') {
          type = 750010001;
        } else if (this.data.rdv.typeRdv === 'R2') {
          type = 750010002
        } else if (this.data.rdv.typeRdv === 'R3') {
          type = 750010003
        } else if (this.data.rdv.typeRdv === 'R4') {
          type = 750010004
        }
        this.dataRdv = {
          type: type,
          project: "",
          subject: "",
          modality: "",
          location: "",
          address: "",
          comment: "",
          internalMemo: "",
          category: "",
          startDate: this.startDate,
          endDate: this.endDate,
          addressOne: "",
          addressTwo: "",
          zipCode: "",
          city: "",
          id: "",
          projectId: this.data.rdv !== undefined ? this.data.rdv.projectId : ""
        }
      } else if (this.data.namePopup == 'editRdvWithReservation') {
        if (this.data.rdv.typeRdv === 'R1') {
          type = 750010001;
        } else if (this.data.rdv.typeRdv === 'R2') {
          type = 750010002
        } else if (this.data.rdv.typeRdv === 'R3') {
          type = 750010003
        } else if (this.data.rdv.typeRdv === 'R4') {
          type = 750010004
        }
        this.dataRdv = {
          type: type,
          project: "",
          subject: "",
          modality: "",
          location: "",
          address: "",
          comment: "",
          internalMemo: "",
          category: "",
          startDate: this.startDate,
          endDate: this.endDate,
          addressOne: "",
          addressTwo: "",
          zipCode: "",
          city: "",
          id: "",
          projectId: ""
        }
      }

      if (this.namePopup !== 'modifyRdv') {
        if (this.dataRdv.type == 750010004) {
          if (this.data.namePopup != 'editRdvWithReservation') {
            this.activeDisableTitle = true;
            this.project = { name: this.data.rdv['project.if_name'], id: "" };
            this.listFieldProject.push({ name: this.data.rdv['project.if_name'], id: { name: this.data.rdv['project.if_name'], id: "" } })
            this.insertRdvForm.patchValue({
              project: this.project
            })
          } else {
            this.activeDisableTitle = true;
            let newNameProject = 'Résa - ' + this.data.rdv['contact.fullname'] + ' / ' + this.data.rdv['asset.if_assetnumber']
            this.project = { name: newNameProject , id: this.data.rdv['reservation.if_reservationid'] };
            this.listFieldProject.push({ name: newNameProject, id: { name: newNameProject, id: this.data.rdv['reservation.if_reservationid'] } })
            this.insertRdvForm.patchValue({
              project: this.project
            })
          }
        } else {
          try {
            // Get referential project in progress in the API for bind name project in the select and the contruction of array for the select with the name of project
            this.serviceProject.getReferentialProjectInProgress().pipe(first()).subscribe((listProject: Array<ReferentialProjectInProgress>) => {
              listProject.forEach((projectInProgress: ReferentialProjectInProgress) => {
                if (this.data.rdv !== undefined) {
                  if (this.dataRdv.projectId !== undefined) {
                    if (this.dataRdv.projectId !== "" && this.dataRdv.projectId !== null) {
                      this.activeDisableTitle = true;
                      this.project = { name: this.dataRdv.project, id: this.dataRdv.projectId };
                    }
                  }
                }
                this.listFieldProject.push({ name: projectInProgress.if_name, id: { name: projectInProgress.if_name, id: projectInProgress.if_projectid } });
              });
      
              this.insertRdvForm.patchValue({
                project: this.project
              })
            });
          } catch(e) {
            this.snackbar.openSnackBar(
              this.serviceErrorMessage.errorMessageGetReferentialProjectInProgress, ["custom-style-error"]
            );
          }
        }
      } else {
        this.activeDisableTitle = true;
        this.project = { name: this.dataRdv.project, id: this.dataRdv.projectId };
        this.listFieldProject.push({ name: this.dataRdv.project, id: { name: this.dataRdv.project, id: this.dataRdv.projectId } });
        this.insertRdvForm.patchValue({
          project: this.project
        })
      }
      
      try {
        // Get referential location of all appointment in the API for bind location appointment in the select and 
        // the contruction of array for the select with the location of the appointment
        this.serviceProject.getAppointmentLocations().pipe(first()).subscribe((listAppointmentLocations: Array<ReferentialAppointmentLocation>) => {
          console.log(listAppointmentLocations)
          listAppointmentLocations.forEach((appointmentLocation: ReferentialAppointmentLocation) => {
            if (this.data.rdv !== undefined && this.data.namePopup !== 'editRdv') {
              if (this.dataRdv.location === appointmentLocation.Value) {
                this.location = { name: appointmentLocation.Label, id: appointmentLocation.Value }
              }
            }
            this.listFieldLocation.push({ name: appointmentLocation.Label, id: { name: appointmentLocation.Label, id: appointmentLocation.Value } })
          })
    
          this.insertRdvForm.patchValue({
            location: isNullFieldForm(this.location, false)
          })
        })
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetAppointmentLocations, ["custom-style-error"]
        );
      }
  
      try {
        // Get referential modality possible of all the appointment in the API for bind modality appointment in the select and 
        // the contruction of array for the select with the modality of the appointment
        this.serviceProject.getAppointmentModalities().pipe(first()).subscribe((listAppointmentModalities: Array<ReferentialAppointmentModality>) => {
          console.log(listAppointmentModalities)
          listAppointmentModalities.forEach((appointmentModality: ReferentialAppointmentModality) => {
            if (appointmentModality.Label !== 'Autre') {
              if (this.data.rdv !== undefined && this.data.namePopup !== 'editRdv') {
                if (this.dataRdv.modality === appointmentModality.Value) {
                  this.modality = { name: appointmentModality.Label, id: appointmentModality.Value }
                }
              }
              this.listFieldModality.push({ name: appointmentModality.Label, id: { name: appointmentModality.Label, id: appointmentModality.Value } })
            }
          })
    
          this.insertRdvForm.patchValue({
            modality: isNullFieldForm(this.modality, false)
          })
        })
      } catch(e) {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessageGetAppointmentModalities, ["custom-style-error"]
        );
      }
  
      // If the name popup choice is modifyRdv (modify appointment)-----------------------------------------------------------------
      if (this.data.namePopup === 'modifyRdv') {
        try {
          if (this.dataRdv.type !== 750010004) {
            // Get all the appointment type in the API for bind appointment type in the select and 
            // the contruction of array for the select with the type of the appointment
            this.category = this.dataRdv.type
            this.listFieldCategory.push(this.dataRdv.type)
            this.insertRdvForm.patchValue({
              category: this.category
            });
          } else {
              // Get all the appointment type in the API for bind appointment type in the select and 
              // the contruction of array for the select with the type of the appointment
              this.serviceProject.getBookingAppointmentRdvTypes(this.dataRdv.type).pipe(first()).subscribe((listAppointmentRdvTypes: Array<ReferentialProjectAppointmentRdvType>) => {
                
                console.log(listAppointmentRdvTypes)
                listAppointmentRdvTypes.forEach((appointmentRdvTypes: ReferentialProjectAppointmentRdvType) => {
                  if (this.dataRdv.category === appointmentRdvTypes['appointmenttypes.if_rdvtype']) {
                    this.category = { name: appointmentRdvTypes['appointmenttypes.if_name'], id: appointmentRdvTypes['appointmenttypes.if_rdvtype'], color: false, guid: appointmentRdvTypes['appointmenttypes.if_appointmenttypesid'] }
                  }
                  this.listFieldCategory.push({ name: appointmentRdvTypes['appointmenttypes.if_name'], id: appointmentRdvTypes['appointmenttypes.if_rdvtype'], color: false, guid: appointmentRdvTypes['appointmenttypes.if_appointmenttypesid'] })
                })
                this.insertRdvForm.patchValue({
                  category: this.category
                });
              })
          }
        } catch(e) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetAppointmentRdvTypes, ["custom-style-error"]
          );
        }

        this.startDate = this.data.rdv !== undefined ? (this.dataRdv.startDate !== "" ? new Date(this.dataRdv.startDate) : new Date() ) : new Date();
        this.endDate = this.data.rdv !== undefined ? (this.dataRdv.endDate !== "" ? new Date(this.dataRdv.endDate) : new Date(this.dateToday.setHours(this.dateToday.getHours() + 1)) ) : new Date(this.dateToday.setHours(this.dateToday.getHours() + 1));
        this.addressOne = this.data.rdv !== undefined ? (this.dataRdv.addressOne !== "" ? this.dataRdv.addressOne : "") : "";
        this.addressTwo = this.data.rdv !== undefined ? (this.dataRdv.addressTwo !== "" ? this.dataRdv.addressTwo : "") : "";
        // this.zipCode = this.data.rdv !== undefined ? (this.dataRdv.zipcode !== "" ? this.dataRdv.zipcode : "") : "";
        this.subject = this.data.rdv !== undefined ? (this.dataRdv.subject !== "" ? this.dataRdv.subject : "") : "";
        this.comment = this.data.rdv !== undefined ? (this.dataRdv.comment !== "" ? this.dataRdv.comment : "") : "";
        this.internalMemo = this.data.rdv !== undefined ? (this.dataRdv.internalMemo !== "" ? this.dataRdv.internalMemo : "") : "";
  
        this.insertRdvForm.controls['category'].disable();
        this.insertRdvForm.controls['project'].disable();

        this.insertRdvForm.patchValue({
          project: this.project,
          startDate: this.startDate,
          timeDuring: this.timeDuring,
          endDate: this.endDate,
          addressOne: this.addressOne,
          addressTwo: this.addressTwo,
          zipCode: this.dataRdv.zipCode,
          city: this.dataRdv.city,
          subject: this.subject,
          comment: this.comment,
          time: this.time,
          internalMemo: this.internalMemo
        });
        
        this.activeDisableTitle = true;

      } // If the name popup choice is different of modify, close and show appointment -------------------------------------------------------------------------------
      else {
        try {
          if (this.dataRdv.type !== 750010004) {
            this.serviceProject.getProjectAppointmentRdvTypes(this.dataRdv.type).pipe(first()).subscribe((listAppointmentRdvTypes: Array<ReferentialProjectAppointmentRdvType>) => {
              
              console.log(listAppointmentRdvTypes)
              if (listAppointmentRdvTypes.length !== 0) {
                if (listAppointmentRdvTypes.length > 1) {
                  listAppointmentRdvTypes.forEach((appointmentRdvTypes: ReferentialProjectAppointmentRdvType) => {
                    this.listFieldCategory.push({ name: appointmentRdvTypes['appointmenttypes.if_fluxsiteb2blabel'], id: appointmentRdvTypes['appointmenttypes.if_rdvtype'], color: false, guid: appointmentRdvTypes['appointmenttypes.if_appointmenttypesid'] })
                  })
                } else {
                  this.listFieldCategory.push({ name: listAppointmentRdvTypes[0]['appointmenttypes.if_fluxsiteb2blabel'], id: listAppointmentRdvTypes[0]['appointmenttypes.if_rdvtype'], color: true, guid: listAppointmentRdvTypes[0]['appointmenttypes.if_appointmenttypesid'] })
                  this.insertRdvForm.patchValue({
                    category: { name: listAppointmentRdvTypes[0]['appointmenttypes.if_fluxsiteb2blabel'], id: listAppointmentRdvTypes[0]['appointmenttypes.if_rdvtype'], color: true, guid: listAppointmentRdvTypes[0]['appointmenttypes.if_appointmenttypesid'] }
                  });
                }
              }
            })
          } else {
            this.serviceProject.getBookingAppointmentRdvTypes(this.dataRdv.type).pipe(first()).subscribe((listAppointmentRdvTypes: Array<ReferentialProjectAppointmentRdvType>) => {
              
              console.log(listAppointmentRdvTypes)
              if (listAppointmentRdvTypes.length > 1) {
                if (listAppointmentRdvTypes.length <= 3) {
                  this.activeSelectType = false
                } else {
                  this.activeSelectType = true
                }
                listAppointmentRdvTypes.forEach((appointmentRdvTypes: ReferentialProjectAppointmentRdvType) => {
                  this.listFieldCategory.push({ name: appointmentRdvTypes['appointmenttypes.if_fluxsiteb2blabel'], id: appointmentRdvTypes['appointmenttypes.if_rdvtype'], color: false, guid: appointmentRdvTypes['appointmenttypes.if_appointmenttypesid'] })
                })
              } else {
                this.activeSelectType = false
                this.listFieldCategory.push({ name: listAppointmentRdvTypes[0]['appointmenttypes.if_fluxsiteb2blabel'], id: listAppointmentRdvTypes[0]['appointmenttypes.if_rdvtype'], color: true, guid: listAppointmentRdvTypes[0]['appointmenttypes.if_appointmenttypesid'] })
                this.insertRdvForm.patchValue({
                  category: { name: listAppointmentRdvTypes[0]['appointmenttypes.if_fluxsiteb2blabel'], id: listAppointmentRdvTypes[0]['appointmenttypes.if_rdvtype'], color: true, guid: listAppointmentRdvTypes[0]['appointmenttypes.if_appointmenttypesid'] }
                });
              }
            })
          }
          if (this.data.namePopup === 'editRdvWithInProgress' || this.data.namePopup === 'editRdvWithReservation') {
            this.startDate = new Date();
            this.endDate = new Date(this.dateToday.setHours(this.dateToday.getHours() + 1));    
  
            this.insertRdvForm.controls['project'].disable();
          
            this.insertRdvForm.patchValue({
              project: this.project,
              startDate: this.startDate,
              endDate: this.endDate
            });
            this.activeDisableTitle = true;
          } else {
            this.serviceProject.getReferentialProjectInProgress().pipe(first()).subscribe((listProject: Array<ReferentialProjectInProgress>) => {
              listProject.forEach((projectInProgress: ReferentialProjectInProgress) => {
                if (this.data.rdv !== undefined) {
                  if (this.data.rdv.projectId !== undefined) {
                    if (this.data.rdv.projectId === projectInProgress.if_projectid) {
                      this.project = { name: projectInProgress.if_name, id: projectInProgress.if_projectid };
                    }
                  }
                }
                this.listFieldProject.push({ name: projectInProgress.if_name, id: { name: projectInProgress.if_name, id: projectInProgress.if_projectid } });
              });
            });
            
            this.activeDisableTitle = false;
          }
        } catch(e) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetAppointmentRdvTypes, ["custom-style-error"]
          );
        } 
      }
    } else {
      // If the name of the popup choice is shwoRdv for show appointment use condition below
      if (this.namePopup !== 'ruleRdv') {
        this.showRdv = {
          title: this.data.rdv['appointment.subject'],
          start: convertTZ(this.data.rdv['appointment.scheduledstart']),
          end: convertTZ(this.data.rdv['appointment.scheduledend']),
          project: this.data.rdv['appointment.regardingobjectid_label'],
          category: this.data.rdv['appointment.if_rdv_activity_label'],
          modality: this.data.rdv['appointment.if_modalities_label'],
          location: this.data.rdv['appointment.if_modalities'] === 750010000 ? this.data.rdv['appointment.if_location_label'] : null,
          emplacement: this.data.rdv['appointment.location'],
          comment: this.data.rdv['appointment.description'],
          internalMemo: this.data.rdv['appointment.if_privatecomments']
        }
      } // If the name of the popup choice is ruleRdv for close appointment use condition below 
      else {
        this.ruleRdv = {
          start: convertTZ(this.data.rdv.rdvRule['appointment.scheduledstart']),
          end: convertTZ(this.data.rdv.rdvRule['appointment.scheduledend']),
          project: this.data.rdv.rdvRule['appointment.regardingobjectid_label'],
          emplacement: this.data.rdv.rdvRule['appointment.location'],
          id: this.data.rdv.rdvRule['appointment.activityid'],
          projectId: this.data.rdv.rdvRule['project.if_projectid']
        }

        try {
          // Get all the done values of the appointment in the API for select the done values for appointment selected at close 
          this.serviceProject.getRdvDoneValues().pipe(first()).subscribe((listRdvDoneValues: Array<any>) => {
            this.listRdvDoneValues = listRdvDoneValues;
          });
        } catch(e) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetRdvDoneValue, ["custom-style-error"]
          );
        }
      }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  convertTZ(date: any) {
    // const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
    return new Date(date + ".000Z");   
  }

  // Compare with for select (compare value observe in select with item.id (show construction array items))
  compareWithFn(item: any, selected: any) {
    return item.id === selected;
  }

  // Compare with for select (compare value observe in select with item.id (show construction array items))
  compareWithFnCategory(item: any, selected: any) {
    return item === selected;
  }
  // Method for text label on select and reset of select simple
  openSelectAndReset(selectName: string, insertFormValue: FormGroup, isOpenSelect: any) {
    if (insertFormValue.value[selectName] !== null ) {
      isOpenSelect[selectName] = true;   
    } else {
      isOpenSelect[selectName] = false;  
    }
  }

  changeValueAgenda(valueActivateAgenda: any) {

    let listContainerAllContentCreateTask = document.getElementsByClassName('containerAllContentCreateTask') as HTMLCollectionOf<HTMLElement>;
    if ( listContainerAllContentCreateTask[0] !== undefined) {
      if (valueActivateAgenda.checked) {
        listContainerAllContentCreateTask[0].style.width = (window.screen.width - 200) + "px"; 
      } else {
        listContainerAllContentCreateTask[0].style.width = "auto";
      }
    }

    let listContainerForm = document.getElementsByClassName('formRdvEditAndModify') as HTMLCollectionOf<HTMLElement>;
    if ( listContainerForm[0] !== undefined) {
      if (valueActivateAgenda.checked) {
        listContainerForm[0].style.width = "54%";
      } else {
        listContainerForm[0].style.width = "100%";
      }
    }
    
  }

  // Custom research select for city with string of 3 letter min  
  customSearchFn(keyUp: any, term: any, nameSearch: any, isOpenSelect: any) {
    if (keyUp === "city") {
      isOpenSelect[keyUp] = true;
      this.getDataApiwithSearchTerm(keyUp, term, true);
    } else {
      if (term.searchTerm !== null) {
        isOpenSelect[keyUp] = true;

        if (term.searchTerm.toLocaleLowerCase().length >= 3) {
          this.getDataApiwithSearchTerm(nameSearch, term.searchTerm, false);
        }
      } else {
        isOpenSelect[keyUp] = false;
      }
    }
  }

  changeValueCategory(value: any, index: number) {
    this.listFieldCategory.forEach((fieldCategory: any, idx: number) => {
      if (idx == index) {
        this.listFieldCategory[idx].color = true
      } else {
        this.listFieldCategory[idx].color = false
      }
    })
    this.insertRdvForm.patchValue({
      category: value
    })
  }

  // Get data for the API with search term insert or touched in the input select 
  getDataApiwithSearchTerm(choiceSearchFn: string, term: string, activeInit: boolean) {
    
    if (choiceSearchFn === "city") {
      this.serviceCity.getCities(1, 1000, term).pipe(first()).subscribe((cities: any) => {
        console.log(cities)
        this.listFieldCity = [];
        cities.datas.forEach((city: any, index: number) => {
          // if (this.data.rdv['project.if_ctc1cityid'] === city['city.if_cityid'] && activeInit ) {
          //   this.city = { name: city['city.if_name'] + " (" + city['city.if_postalcode'].substring(0, 2) + ")", id: city['city.if_cityid'] };  
          // }
          this.listFieldCity.push({ name: city['city.if_name'] + " (" + city['city.if_postalcode'].substring(0, 2) + ")", id: { name: city['city.if_name'] + " (" + city['city.if_postalcode'].substring(0, 2) + ")", id: city['city.if_cityid']} });
        });
        setTimeout(() => {
          this.insertRdvForm.patchValue({
            city: isNullFieldForm(this.city, false)
          })
        }, 100)
        return;
      })
    }
  }

  
  // Observe field for delete label if input touched or valid
  openFieldStartDate(eventField: any ) {
    if (eventField.type === "focus" || eventField === true || this.insertRdvForm.value["startDate"] != null) {
      if (this.insertRdvForm.value.startDate.getTime() > this.insertRdvForm.value.endDate.getTime()) {
        this.insertRdvForm.patchValue({
          endDate: this.insertRdvForm.value.startDate
        })
      }
      this.labelStartDate = '';
    } else if (eventField.type === "blur") {
      this.labelStartDate = 'Date et heure de début';
    }
  }
   
  // Observe field for delete label if input touched or valid
  openFieldEndDate(eventField: any ) {
    if (eventField.type === "focus" || eventField === true || this.insertRdvForm.value["endDate"] != null) {
      if (this.insertRdvForm.value.startDate.getTime() > this.insertRdvForm.value.endDate.getTime()) {
        this.insertRdvForm.patchValue({
          startDate: this.insertRdvForm.value.endDate
        })
      }
      this.labelEndDate = '';
    } else {
      this.labelEndDate = 'Date et heure de fin';
    }
  }

  // Function get error of your form and return string at your view
  getErrorMessageRequired(controlFormSelected: any, nameControl: string) {
    if ( nameControl === "startDate") {
      if (controlFormSelected.getError('required')) {
        return 'La date et heure de début du RDV est requis';
      } else {
        return "";
      }
    } else if ( nameControl === "endDate") {
      if (controlFormSelected.getError('required')) {
        return 'La date et heure de fin du RDV est requis';
      } else {
        return "";
      }
    } else if ( nameControl === "addressOne") {
      if (controlFormSelected.getError('required')) {
        return 'L\'emplacement du RDV est requis';
      } else {
        return "";
      }
    } else {
      return "";
    }
  }
   
  // Observe field ng-select category and active or not field
  categoryChangeValue() {
    console.log('ok');
    if (this.insertRdvForm.value.modality !== null) {
      if (this.insertRdvForm.value.modality.id !== 750010000) {
        this.activeLocation = false;
        this.activeAddressClient = false;
        // this.insertRdvForm.patchValue({
        //   location: null
        // })
      } else  {
        this.activeLocation = true;
        // this.activeAddressClient = true;
        this.insertRdvForm.patchValue({
          location: null
        })
      }
    } else {
      this.activeLocation = false;
      this.activeAddressClient = false;
    }
  }

  // Observe field ng-select location and active or not field
  locationChangeValue() {
    if (this.insertRdvForm.value.location !== null) {
      if (this.insertRdvForm.value.location.id !== 750010002) {
        this.activeAddressClient = false;
      } else  {
        this.activeAddressClient = true;
      }
    }  else {
      this.activeAddressClient = false;
    }
  }

  // Open the pop-up of create new project
  openDialogNewProject(namePopup: string, dataPopup: any ) {
    const dialogRef = this.popupNewProject.open(PopupNewProjectComponent, {
      width: '689px',
      height: '589px',
      data: { namePopup: namePopup , dataPopup: dataPopup, size: this.data.size },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
    });
  }

  // Function submit form create or modify rdv 
  onSubmitPostForm() {
    let color = "";
    this.activeErrorMessageSelect = true;
    
    if ( !this.insertRdvForm.invalid && (this.insertRdvForm.value.modality.id === 750010000 ? ( this.insertRdvForm.value.location !== null ? 
    this.insertRdvForm.value.location.id !== 750010002 ? true : this.insertRdvForm.value.addressOne !== "" : false ) : 
    ((this.insertRdvForm.value.modality.id === 750010002 || this.insertRdvForm.value.modality.id === 750010003 || this.insertRdvForm.value.modality.id === 750010001) ? true : false )) ) {
      // If the name popup choice is modifyRdv (modify appointment)-----------------------------------------------------------------------------------------------------------
      if (this.data.namePopup !== 'modifyRdv') {
        let newStartDate = new Date(this.insertRdvForm.value.startDate).setHours(this.insertRdvForm.value.time.hour)
        newStartDate = new Date(newStartDate).setMinutes(this.insertRdvForm.value.time.minute)
        let newEndDate = new Date(newStartDate + this.insertRdvForm.value.timeDuring.id*60000)
        let newRdv: any;

        color = getColorRdv(this.insertRdvForm.value.category);
        if (this.insertRdvForm.value.modality.id !== 750010000) {
          let nameProject = ""
          let idProject = ""
          if (this.insertRdvForm.value.project !== undefined) {
            nameProject = this.insertRdvForm.value.project.name
            idProject = this.insertRdvForm.value.project.id
          } else {
            nameProject = this.dataRdv.project
            idProject = this.dataRdv.projectId
          }
          newRdv = { rdv: {
            title: this.insertRdvForm.value.subject + " / " +  nameProject,
            start: new Date(newStartDate),
            end: newEndDate,
            color: {
              primary: color,
              secondary: color
            },
            project: { name: nameProject, id: idProject },
            subject: this.insertRdvForm.value.subject,
            category: this.insertRdvForm.value.category,
            modality: this.insertRdvForm.value.modality,
            location: this.insertRdvForm.value.location,
            comment: this.insertRdvForm.value.comment,
            internalMemo: this.insertRdvForm.value.internalMemo,
            address: '',
            id: Math.random().toString(),
            actions: '',
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          }}
        } else if (this.insertRdvForm.value.location.id !== 750010002) {
          let nameProject = ""
          let idProject = ""
          if (this.insertRdvForm.value.project !== undefined) {
            nameProject = this.insertRdvForm.value.project.name
            idProject = this.insertRdvForm.value.project.id
          } else {
            nameProject = this.dataRdv.project
            idProject = this.dataRdv.projectId
          }
          newRdv = { rdv: {
            title: this.insertRdvForm.value.subject + " / " + nameProject,
            start: new Date(newStartDate),
            end: newEndDate,
            color: {
              primary: color,
              secondary: color
            },
            project: { name: nameProject, id: idProject },
            subject: this.insertRdvForm.value.subject,
            category: this.insertRdvForm.value.category,
            modality: this.insertRdvForm.value.modality,
            location: this.insertRdvForm.value.location,
            comment: this.insertRdvForm.value.comment,
            internalMemo: this.insertRdvForm.value.internalMemo,
            id: Math.random().toString(),
            actions: '',
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          }}
        } else {
          let nameProject = ""
          let idProject = ""
          if (this.insertRdvForm.value.project !== undefined) {
            nameProject = this.insertRdvForm.value.project.name
            idProject = this.insertRdvForm.value.project.id
          } else {
            nameProject = this.dataRdv.project
            idProject = this.dataRdv.projectId
          }
          newRdv = { rdv: {
            title: this.insertRdvForm.value.subject + " / " + nameProject,
            start: new Date(newStartDate),
            end: newEndDate,
            color: {
              primary: color,
              secondary: color
            },
            project: { name: nameProject, id: idProject },
            subject: this.insertRdvForm.value.subject,
            category: this.insertRdvForm.value.category,
            modality: this.insertRdvForm.value.modality,
            location: this.insertRdvForm.value.modality.id === 750010000 ? this.insertRdvForm.value.location : "",
            addressOne: this.insertRdvForm.value.addressOne,
            addressTwo: this.insertRdvForm.value.addressTwo,
            zipCode: this.insertRdvForm.value.zipCode,
            city: this.insertRdvForm.value.city !== null ? this.insertRdvForm.value.city.id : null,
            comment: this.insertRdvForm.value.comment,
            internalMemo: this.insertRdvForm.value.internalMemo,
            id: Math.random().toString(),
            actions: '',
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          }
        }}
        this.dialogRef.close(newRdv)
      }
      // If the name popup choice is editRdvWithInProgress ( edit appointment if name project already choice ) or editRdv ( edit appointment if name project is not choice ) ----------------------
      else {
        let newRdv: any;
        let newStartDate = this.dataRdv.startDate
        if (!this.disableHoraire) {
          newStartDate = this.insertRdvForm.value.startDate
        }
        newStartDate = new Date(newStartDate).setHours(this.insertRdvForm.value.time.hour)
        newStartDate = new Date(newStartDate).setMinutes(this.insertRdvForm.value.time.minute)
        let newEndDate = new Date(newStartDate + this.insertRdvForm.value.timeDuring.id*60000)
        color = getColorRdv(this.data.rdv['aptypes.if_fluxsiteb2bcode']);
        if (this.data.rdv['appointment.if_lock_appointmentdates'] !== 1) {
          newRdv = { rdv: {
            title: this.insertRdvForm.value.subject + " / " + this.project.name,
            start: new Date(newStartDate),
            end: newEndDate,
            color: {
              primary: color,
              secondary: color
            },
            status: this.data.rdv['appointment.if_rdv_activity_label'],
            project: this.project,
            subject: this.insertRdvForm.value.subject,
            category: this.category,
            modality: this.insertRdvForm.value.modality,
            location: this.insertRdvForm.value.modality.id === 750010000 ? this.insertRdvForm.value.location : "",
            addressOne: this.insertRdvForm.value.addressOne,
            addressTwo: this.insertRdvForm.value.addressTwo,
            zipCode: this.insertRdvForm.value.zipCode,
            city: this.insertRdvForm.value.city !== null ? this.insertRdvForm.value.city.id : null,
            comment: this.insertRdvForm.value.comment,
            internalMemo: this.insertRdvForm.value.internalMemo,
            id: this.dataRdv.id,
            actions: '',
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          }}
          this.dialogRef.close(newRdv)
        } else {
          newRdv = { rdv: {
            title: this.insertRdvForm.value.subject + " / " + this.project.name,
            start: new Date(newStartDate),
            end: newEndDate,
            color: {
              primary: color,
              secondary: color
            },
            status: this.data.rdv['appointment.if_rdv_activity_label'],
            project: this.project,
            subject: this.insertRdvForm.value.subject,
            category: this.category,
            modality: this.insertRdvForm.value.modality,
            location: this.insertRdvForm.value.modality.id === 750010000 ? this.insertRdvForm.value.location : "",
            addressOne: this.insertRdvForm.value.addressOne,
            addressTwo: this.insertRdvForm.value.addressTwo,
            zipCode: this.insertRdvForm.value.zipCode,
            city: this.insertRdvForm.value.city !== null ? this.insertRdvForm.value.city.id : null,
            comment: this.insertRdvForm.value.comment,
            internalMemo: this.insertRdvForm.value.internalMemo,
            id: this.dataRdv.id,
            actions: '',
            resizable: {
              beforeStart: true,
              afterEnd: true,
            },
            draggable: true,
          }}
          this.dialogRef.close(newRdv)
        }
      }
    } else {
      if ((this.insertRdvForm.controls.modality.invalid || this.insertRdvForm.value.modality.id === 750010000) && this.insertRdvForm.value.location === null) {
        this.errorMessageSelectLocation = true;
      } else {
        this.errorMessageSelectLocation = false;
      }

      if ((this.insertRdvForm.controls.modality.invalid || this.insertRdvForm.value.modality.id === 750010000) && this.insertRdvForm.value.location === null && this.insertRdvForm.value.addressOne === "") {
        this.errorMessageInputAddress = true;
      } else {
        this.errorMessageInputAddress = false;
      }
    }
  }

  // Function active content popup rule rdv
  openRuleDialog() {
    this.namePopup = 'ruleRdv';

    this.ruleRdv = {
      start: convertTZ(this.data.rdv['appointment.scheduledstart']),
      end: convertTZ(this.data.rdv['appointment.scheduledend']),
      project: this.data.rdv['appointment.regardingobjectid_label'],
      emplacement: this.data.rdv['appointment.location'],
      id: this.data.rdv['appointment.activityid'],
      projectId: this.data.rdv['project.if_projectid']
    }
    
    try {
      this.serviceProject.getRdvDoneValues().pipe(first()).subscribe((listRdvDoneValues: Array<any>) => {
        console.log(listRdvDoneValues)
        this.listRdvDoneValues = listRdvDoneValues;
      });
    } catch(e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetRdvDoneValue, ["custom-style-error"]
      );
    }
  }

  // Function submit form rule rdv and create new rdv or alone rule rdv
  onSubmitPostFormRule(functionButton: string) {
    // champ commentaire obligatoire si 1 === if_cc_rdv_commentrequired
    // champ   requiredComment = true
    if (!(this.insertRuleRdvForm.value.rdvPastYes == false && this.insertRuleRdvForm.value.rdvPastNo == false) && this.insertRuleRdvForm.value.conclusionCode !== null) {
      if (this.insertRuleRdvForm.value.conclusionCode.commentRequired !== null) {
        if (this.insertRuleRdvForm.value.conclusionCode.commentRequired === 1) {
          if (this.insertRuleRdvForm.value.comment === "") {
            this.requiredComment = true
            return
          } else {
            this.requiredComment = false
          }
        }
      }
      let rdvDoneValuePost = false;
  
      // Check params rdv done values for post data for close the appointment 
      this.listRdvDoneValues.forEach(rdvDoneValue => {
        if(rdvDoneValue.Value === 750010000) {
          if (this.insertRuleRdvForm.value.rdvPastYes === true) {
            rdvDoneValuePost = rdvDoneValue.Value
          }
        }
        if(rdvDoneValue.Value === 750010001) {
          if (this.insertRuleRdvForm.value.rdvPastNo === true) {
            rdvDoneValuePost = rdvDoneValue.Value
          }
        }
      });
      
      // Check params other for post data for close the appointment
      let dateRdvClose = new Date(); 
      let dataPostRule = {
        "if_rdv_done": rdvDoneValuePost,
        "if_conclusioncode": this.insertRuleRdvForm.value.conclusionCode.id,
        "if_closedate": dateRdvClose.toISOString(),
        // "if_closedate": new Date(dateRdvClose.getTime() - dateRdvClose.getTimezoneOffset() * 60000).toISOString(),
        "if_conclusiondesc": this.insertRuleRdvForm.value.comment
      }
  
      // Method for open the popup of loading 
      this.openDialogLoading('ruleRdv');

      // Method for post the close of the appointment with the id of the appointment select and data built above
      this.serviceActivityApi.postAppointmentClose(this.ruleRdv.id ,dataPostRule).pipe(takeUntil(this.ngUnsubscribe)).subscribe((appointmentClose: any) => {
        
        // Dialog ref for close all popup open
        this.dialogRef.close(appointmentClose);
     
      }, (error) => {
        this.snackbar.openSnackBar(
          this.serviceErrorMessage.errorMessagePostAppointmentClose, ["custom-style-error"]
        );
        this.popupLoading.closeAll();
        // Dialog ref for close all popup open
        this.dialogRef.close("");
      })
    }
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.servicePopup.openDialogLoading(namePopupLoading, this.data.size, "", this.ruleRdv)
  }

  // Method for check, than the user can choice yes or no but only yes or no
  changeValueYesOrNo(value: any, nameCheckBox: string) {
    if (nameCheckBox === 'rdvPastYes') {
      if (value.checked === false && this.insertRuleRdvForm.value.rdvPastNo === false) {
        this.listFieldConclusionCode = [];

        this.initSelectAppointmentCodeConclusion(false);

        this.insertRuleRdvForm.patchValue({
          rdvPastNo: true,
          rdvPastYes: false,
          conclusionCode: null
        })
      } else if (value.checked === true) {
        if (this.insertRuleRdvForm.value.rdvPastNo === true) {
          this.listFieldConclusionCode = [];
          
          this.initSelectAppointmentCodeConclusion(true);
          
          this.insertRuleRdvForm.patchValue({
            rdvPastNo: false,
            rdvPastYes: true,
            conclusionCode: null
          })
        } else {
          this.listFieldConclusionCode = [];
          
          this.initSelectAppointmentCodeConclusion(true);
          
          this.insertRuleRdvForm.patchValue({
            rdvPastNo: false,
            rdvPastYes: true,
            conclusionCode: null
          })
        }
      } 
    }
    if (nameCheckBox === 'rdvPastNo') {
      if (value.checked === false && this.insertRuleRdvForm.value.rdvPastYes === false) { 
        this.listFieldConclusionCode = [];
        
        this.initSelectAppointmentCodeConclusion(true);
       
        this.insertRuleRdvForm.patchValue({
          rdvPastYes: true,
          rdvPastNo: false,
          conclusionCode: null
        })
      } else if (value.checked === true) {
        if (this.insertRuleRdvForm.value.rdvPastYes === true) { 
          this.listFieldConclusionCode = [];
          
          this.initSelectAppointmentCodeConclusion(false);

          this.insertRuleRdvForm.patchValue({
            rdvPastNo: true,
            rdvPastYes: false,
            conclusionCode: null
          })
        } else {
          this.listFieldConclusionCode = [];
          
          this.initSelectAppointmentCodeConclusion(false);
          
          this.insertRuleRdvForm.patchValue({
            rdvPastNo: true,
            rdvPastYes: false,
            conclusionCode: null
          })
        }
      }
    }
  }

  initSelectAppointmentCodeConclusion(rdvEffectue: boolean) {
    try {
      console.log(this.data.rdv)
      let if_mainconclusioncodeid = ""
      let RdvType = 0
      if (this.data.rdv.rdvRule !== undefined) {
        if (this.data.rdv.rdvRule['appointment.if_mainconclusioncodeid'] !== null) {
          if_mainconclusioncodeid = this.data.rdv.rdvRule['appointment.if_mainconclusioncodeid'];
        }
        if (this.data.rdv.rdvRule['appointment.if_rdvtype_calc'] !== null) {
          RdvType = this.data.rdv.rdvRule['appointment.if_rdvtype_calc'];
        }
      } else if (this.data.rdv['appointment.if_mainconclusioncodeid'] !== undefined) {
        if (this.data.rdv['appointment.if_mainconclusioncodeid'] !== null) {
          if_mainconclusioncodeid = this.data.rdv['appointment.if_mainconclusioncodeid'];
        }
        if (this.data.rdv['appointment.if_rdvtype_calc'] !== null) {
          RdvType = this.data.rdv['appointment.if_rdvtype_calc'];
        }
      }

      let  dataSendForGetAppointmentCodeConclusions = {
        "RdvEffectue": rdvEffectue ? 750010000 : 750010001,
        "RdvType": RdvType,
        "MainCodeConclusionId": if_mainconclusioncodeid
      }
      // Get all the code of conclusion of the appointment in the API for the construction of select of conclusion code
      this.serviceProject.getAppointmentCodeConclusions(dataSendForGetAppointmentCodeConclusions).pipe(first()).subscribe((listCodeConclusion: Array<any>) => {
        if(listCodeConclusion.length === 0) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetAppointmentCodeConclusionCustom, ["custom-style-zero-data"]
          );
        }
        listCodeConclusion.forEach((codeConclusion: CodeConclusion) => {
          this.listFieldConclusionCode.push({ name: codeConclusion['if_fluxsiteb2blabel'], id: { name: codeConclusion['if_fluxsiteb2blabel'], id: codeConclusion['if_conclusioncodeid'], commentRequired: codeConclusion['if_cc_rdv_commentrequired'] } })
        })
      });
    } catch(e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetAppointmentCodeConclusion, ["custom-style-error"]
      );
    }
  }

  // Methode for get if baf is synchronized after update project
  async getBafSynchronized(data: any, namePopup: string) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        // this.popupLoading.closeAll();
        this.openDialogLoading('notGetBafSynchronizedProject');
        clearInterval(setIntervalProjectId);
      }

      this.serviceAppointment.getBafSynchronizedAppointment(data).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body.datas;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize === true) {
          this.serviceConfigObservable.emitRefreshAppointmentCreate(data);
          localStorage.setItem('IdProjectBafUpdate' , this.ruleRdv.projectId)
          this.serviceConfigObservable.emitRefreshInProgress(true)
          this.popupLoading.closeAll();
          clearInterval(setIntervalProjectId);
        }
      }
      console.log(i)
      i++
    }, 1000)
  }
}

