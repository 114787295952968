import { UserService } from './user.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  // static URL = environment.urlServer;
  environment: any;

  listInvestmentOperationPeriodicity: Array<any> = Array<any>()

  listInvestmentOperationNature: Array<any> = Array<any>()

  // EnCours = 1,
  // Gagne = 2,
  // Perdu = 3,
  // Nouveau = 4,
  // ToDo = 5,
  // Anomalie = 6

	constructor(private http: HttpClient, private userService: UserService, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getProjectWithId(id: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/' + id, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getProjectActivityAudits(id: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/' + id + '/ActivityAudits', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }



  public getReferentialAvancementProject(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Referentials/ProjectProgressionCodes', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getReferentialAvancement(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Referentials/ProjectSteps', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getListNameProject(page: number, nbPage: number, term: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/SearchReferentials?Page=' + page + '&PageSize=' + nbPage + '&Search=' + term, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getNbProjectInProgress(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/TabCounter/EnCours', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
    
  public getProjectInProgress(dataResearch: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Projects/Search/EnCours', dataResearch, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public postProjectContactExist(dataContact: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Contacts/FindOccurences', dataContact, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }


  public getNbProjectWin(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/TabCounter/Win', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getProjectWin(dataResearch: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Projects/Search/Win', dataResearch, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

   public getNbProjectLost(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/TabCounter/Lost', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getProjectLost(dataResearch: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Projects/Search/Lost', dataResearch, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
  
  public getNbProjectNew(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/TabCounter/New', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
  
  public getProjectNew(dataResearch: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Projects/Search/New', dataResearch, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getNbProjectToDo(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/TabCounter/ARecontacter', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
  
  public getProjectToDo(dataResearch: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Projects/Search/ARecontacter', dataResearch,  { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getNbProjectAnomalies(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Projects/TabCounter/Anomalie', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getProjectAnomalies(dataResearch: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Projects/Search/Anomalie', dataResearch, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

    public getReferentialProjectInProgress(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Projects/Selectable', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body.datas;
          })
        );
    }

    public getReferentialProjectOption(assetId: number): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Projects/ToPropositions/' + assetId, { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body.datas;
          })
        );
    }

    public addCommercialId() {
      const user = this.userService.user;
      return {
        Key: "if_commercialassignmentid", Value : user['if_commercialassignmentid']
      };
    }

    public postProject(dataProjectSave: any): Observable<any> {
      const headers = this.authentificationService.headers;
      
      return this.http
        .post(this.environment.urlServer + 'Projects/Create', JSON.stringify(dataProjectSave), { headers: headers, observe: 'response' })
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public putProject(dataProjectSave: any, projectId: string): Observable<any> {
      const headers = this.authentificationService.headers;
      
      return this.http
        .put(this.environment.urlServer + 'Projects/' + projectId, JSON.stringify(dataProjectSave), { headers: headers, observe: 'response' })
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public closeProject(dataProjectClose: any, projectId: string): Observable<any> {
      const headers = this.authentificationService.headers;
      
      return this.http
        .put(this.environment.urlServer + 'Projects/' + projectId + '/Close', JSON.stringify(dataProjectClose), { headers: headers, observe: 'response' })
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }


    public getMarketingOrigins(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/MarketingOrigins', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getFamilySituations(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/FamilySituations', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getCsps(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/Csps', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getMatrimonialRegimes(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/MatrimonialRegimes', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getSalutations(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/Salutations', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getEmploymentContractTypes(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/EmploymentContractTypes', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getTaxExemptionLaws(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/TaxExemptionLaws', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body;
          })
        );
    }

    public getCommunicationChannel(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/CommunicationChannel', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getInvestmentObjectives(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/InvestmentObjectives', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getInvestorProfiles(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/InvestorProfiles', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getAssetTypes(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/AssetTypes', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getInvestmentTypes(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/InvestmentTypes', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getMainSearchprofils(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/MainSearchprofils', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getAppointmentLocations(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/AppointmentLocations', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getAppointmentRdvTypes(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/AppointmentRdvTypes', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getAppointmentModalities(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/AppointmentModalities', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getPurchaseTimeframes(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/PurchaseTimeframes', { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getCustomerProperties(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/CustomerProperties' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    // public getAppointmentCodeConclusions(): Observable<any> {
    //   const headers = this.authentificationService.headers;
  
    //   return this.http
    //     .get(this.environment.urlServer + 'Referentials/AppointmentCodeConclusions' , { headers: headers, observe: 'response' })  
    //     .pipe(map((response: HttpResponse<any>) => {
    //         console.log(response.body.datas);
    //         return response.body.datas;
    //       })
    //     );
    // }

    // {
    //   "RdvEffectue": 0,
    //   "RdvType": 0,
    //   "MainCodeConclusionId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    // }

    public getAppointmentCodeConclusions(mainCodeConclusion: any): Observable<any> {
      const headers = this.authentificationService.headers;
      // let dataPostConclusionCode: any = {}
      // if (mainCodeConclusion !== "") {
      //   dataPostConclusionCode = {
      //     "RdvEffectue": 0,
      //     "RdvType": 0,
      //     "MainCodeConclusionId": mainCodeConclusion
      //   }
      // }
  
      return this.http
        .post(this.environment.urlServer + 'Referentials/AppointmentCodeConclusions', JSON.stringify(mainCodeConclusion), { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body);
            return response.body.datas;
          })
        );
    }

    public getRdvDoneValues(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/RdvDoneValues' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getTmiValue(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/Tmis' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getInvestmentRisks(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/Investment_risks' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getProjectAppointmentRdvTypes(typeRdv?: string): Observable<any> {
      const headers = this.authentificationService.headers;
      let newType = ''
      if (typeRdv !== undefined) {
        newType = '?Search=' + typeRdv;
      }
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/ProjectAppointmentRdvTypes' + newType, { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getBookingAppointmentRdvTypes(typeRdv?: string): Observable<any> {
      const headers = this.authentificationService.headers;
  
      let newType = ''
      if (typeRdv !== undefined) {
        newType = '?Search=' + typeRdv;
      }

      return this.http
        .get(this.environment.urlServer + 'Referentials/BookingAppointmentRdvTypes' +  newType, { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getAppointmentAvailability(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/AppointmentAvailability' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getAnnualInComeTypeQuintesensCustom(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/Customs/qui_ctc1_annualincometype' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getMainCodeConclusions(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/MainCodeConclusions' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getProjectCodeConclusions(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/ProjectCodeConclusions' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getProjectInvestmentOperationNature(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/InvestmentOperationNature' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public getProjectInvestmentOperationPeriodicity(): Observable<any> {
      const headers = this.authentificationService.headers;
  
      return this.http
        .get(this.environment.urlServer + 'Referentials/InvestmentOperationPeriodicity' , { headers: headers, observe: 'response' })  
        .pipe(map((response: HttpResponse<any>) => {
            console.log(response.body.datas);
            return response.body.datas;
          })
        );
    }

    public async getBafSynchronized(id: string) {
      const headers = this.authentificationService.headers;
  
      return await this.http
        .get(this.environment.urlServer + 'Baf/IsBafSynchronized/if_project/if_projectid/' + id, { headers: headers, observe: 'response' })
        .pipe().toPromise();
    }

    
}
