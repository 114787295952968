<!-- Container title popup with mat dialog title -->
<div class="containerPopupBreakingNews">
    <div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
        <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
            <div class="containerTitleImg">
                <img *ngIf="subModuleUse === 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
                <img *ngIf="subModuleUse !== 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/picto-popup.png">
                <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>      
            <div>
        </div>
    </div>
    
    <!-- Container content popup cancel option -->
    <mat-dialog-content class="containerMatContent" [style]="'background:' + this.objectReceivePopup.backgroundColorContent + '; color:' + this.objectReceivePopup.colorContent">
        <div class="containerContent">
            <!-- <div *ngIf="this.objectReceivePopup.dataPopup.breakingNews.if_shortdescription_defaultvisualid !== null" class="containerImgVisuelBreakingNews">
                <img class="imgVisuelBreakingNews" [title]="this.objectReceivePopup.dataPopup.breakingNews.if_shortdescription_defaultvisualid_label" [alt]="this.objectReceivePopup.dataPopup.breakingNews.if_shortdescription_defaultvisualid_label" src="../../../../../../Resources/{{ this.objectReceivePopup.dataPopup.breakingNews.if_shortdescription_defaultvisualid_label }}">
            </div>
            <div class="mb-3 titleContent" [innerHTML]="this.objectReceivePopup.dataPopup.breakingNews.if_htmlshortdescription"></div> -->
            <div *ngIf="this.objectReceivePopup.dataPopup.breakingNews.if_longdescription_defaultvisualid !== null" class="containerImgVisuelBreakingNews">
                <img class="imgVisuelBreakingNews" [title]="this.objectReceivePopup.dataPopup.breakingNews.if_longdescription_defaultvisualid_label" [alt]="this.objectReceivePopup.dataPopup.breakingNews.if_longdescription_defaultvisualid_label" src="../../../../../../Resources/big/{{ this.objectReceivePopup.dataPopup.breakingNews.if_longdescription_defaultvisualid }}.jpg">
            </div>
            <div class="textContent" [innerHTML]="this.objectReceivePopup.dataPopup.breakingNews.if_htmllongdescription"></div>
        </div>
    </mat-dialog-content>
    
    <div class="containerAction">
        <button mat-dialog-close class="btnAction">OK</button>
    </div>
</div>