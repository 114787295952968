import { ConfigObservableService } from './shared/services/service-config-observable-service.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'LaFactory';

  subModuleUse = environment.subModuleUse;

  namePageActiveHtml: string = ""

  // accessing favicon link tag
  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');
  favTitle: HTMLLinkElement | null = document.querySelector('#appTitleProject');

  private environment: any;
  constructor(private serviceConfigObservable: ConfigObservableService) {
    this.environment = environment;
    if (environment.production) {
      console.log = function(){} 
    }

    this.serviceConfigObservable.configObservableSendRouteHtml.subscribe(route => {
      this.namePageActiveHtml = route;
      this.changeIcon();
      this.changeTitle();
    })
    
    this.changeIcon();
    this.changeTitle();
  }

  ngOnInit() {
    this.loadScript();
  }

  public loadScript() {
    /* Create your script */
    const body = <HTMLDivElement> document.body;
    const script = document.createElement('script');
    if (this.environment.appGoogleMapUrl) {
      script.src = this.environment.appGoogleMapUrl;
    }

    /* Do your stuff with environement variable or localstorage */
    // console.log(environment.envName);
    // console.log(localStorage.getItem("envName"));

    /* add your script to DOM */
    body.appendChild(script);
  }

  changeIcon() {
    console.log(this.favIcon)
    if (this.favIcon !== null) {
      this.favIcon.href = this.subModuleUse + '/assets/favicon.ico';
      console.log(this.favIcon.href)
    }
    console.log(this.favIcon)
  }

  changeTitle() {
    console.log(this.favTitle)

    if (this.favTitle !== null) {
      if (this.subModuleUse == 'submodule_Quintesens') {
        this.favTitle.innerHTML = 'Quintésens - ' + this.namePageActiveHtml;
      } else if (this.subModuleUse == 'submodule_Inovia') {
        this.favTitle.innerHTML = 'Inovia - ' + this.namePageActiveHtml;
      } else if (this.subModuleUse == 'submodule_Quartus') {
        this.favTitle.innerHTML = 'Quartus - ' + this.namePageActiveHtml;
      } else if (this.subModuleUse == 'submodule_Edelis') {
        this.favTitle.innerHTML = 'Edelis - ' + this.namePageActiveHtml;
      } else if (this.subModuleUse == 'submodule_Stellium') {
        this.favTitle.innerHTML = 'Stellium - ' + this.namePageActiveHtml;
      }
    }
  }
}
