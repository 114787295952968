import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

// Interface for send several data in the popup
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  title: string;
  backgroundColorContent: string;
  colorContent: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-accepted-rule',
  templateUrl: './popup-accepted-rule.component.html',
  styleUrls: ['./popup-accepted-rule.component.scss']
})
export class PopupAcceptedRuleComponent implements OnInit {

  subModuleUse: string;

  private ngUnsubscribe = new Subject();

  constructor(public dialogRef: MatDialogRef<PopupAcceptedRuleComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup) {
                this.subModuleUse = environment.subModuleUse;
              }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  validationPopup() {
    this.dialogRef.close({
      validation: true
    })
  }

}