import { UserService } from 'src/app/shared/services/user.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Favorite } from '../models/favorite';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteAssetService {

  environment: any = null;
  private headers = this.authentificationService.headers;
  
  favoriteActivated: boolean = this.userService.user.ShowFavorite;
  
  listFavorite: Array<Favorite> = new Array<Favorite>();

	constructor(private http: HttpClient, private authentificationService: AuthentificationService, private userService: UserService) {
    this.environment = environment;
    // this.listFavorite = [
    //   {
    //     "if_assetid": "",
    //     "if_assetid_label": "",
    //     "if_favoriteid": "efc1c690-1459-e811-a95d-000d3ab27f0e",
    //     "if_name": "HORIZON LIFE / smarceau",
    //     "if_operationid": "",
    //     "if_operationid_label": "",
    //     "if_partnercontactid": "101a9554-8c0d-e811-a951-000d3ab11b7a",
    //     "if_partnercontactid_label": "Sophie MARCEAU",
    //     "if_programid": "f3ce883a-283e-e811-a956-000d3ab27f6e",
    //     "if_programid_label": "HORIZON LIFE",
    //     "statecode": 0,
    //     "statecode_label": "Actif",
    //     "statuscode": 1,
    //     "statuscode_label": "Actif"
    //   },
    //   {
    //     "if_assetid": "",
    //     "if_assetid_label": "",
    //     "if_favoriteid": "137d7f41-1559-e811-a95d-000d3ab27f0e",
    //     "if_name": "HORIZON LIFE - A / smarceau",
    //     "if_operationid": "05cf883a-283e-e811-a956-000d3ab27f6e",
    //     "if_operationid_label": "HORIZON LIFE - HORIZON LIFE A",
    //     "if_partnercontactid": "101a9554-8c0d-e811-a951-000d3ab11b7a",
    //     "if_partnercontactid_label": "Sophie MARCEAU",
    //     "if_programid": "",
    //     "if_programid_label": "",
    //     "statecode": 0,
    //     "statecode_label": "Actif",
    //     "statuscode": 1,
    //     "statuscode_label": "Actif"
    //   },
    //   {
    //     "if_assetid": "9e0278c6-313e-e811-a956-000d3ab27f6e",
    //     "if_assetid_label": "A001 - T2",
    //     "if_favoriteid": "802d7f6c-1559-e811-a95d-000d3ab27f0e",
    //     "if_name": "A001 - T2 / smarceau",
    //     "if_operationid": "",
    //     "if_operationid_label": "",
    //     "if_partnercontactid": "101a9554-8c0d-e811-a951-000d3ab11b7a",
    //     "if_partnercontactid_label": "Sophie MARCEAU",
    //     "if_programid": "",
    //     "if_programid_label": "",
    //     "statecode": 0,
    //     "statecode_label": "Actif",
    //     "statuscode": 1,
    //     "statuscode_label": "Actif"
    //   },
    //   {
    //     "if_assetid": "",
    //     "if_assetid_label": "",
    //     "if_favoriteid": "0b8e4a20-705f-e811-a95e-000d3ab27f0e",
    //     "if_name": "Favori Sophie MARCEAU",
    //     "if_operationid": "",
    //     "if_operationid_label": "",
    //     "if_partnercontactid": "101a9554-8c0d-e811-a951-000d3ab11b7a",
    //     "if_partnercontactid_label": "Sophie MARCEAU",
    //     "if_programid": "",
    //     "if_programid_label": "",
    //     "statecode": 1,
    //     "statecode_label": "Inactif",
    //     "statuscode": 2,
    //     "statuscode_label": "Inactif"
    //   },
    //   {
    //     "if_assetid": "",
    //     "if_assetid_label": "",
    //     "if_favoriteid": "54051d64-3c7a-ed11-81ad-0022489fd5b4",
    //     "if_name": "",
    //     "if_operationid": "fef4196c-441c-ec11-b6e6-000d3adbe567",
    //     "if_operationid_label": "",
    //     "if_partnercontactid": "101a9554-8c0d-e811-a951-000d3ab11b7a",
    //     "if_partnercontactid_label": "Sophie MARCEAU",
    //     "if_programid": "",
    //     "if_programid_label": "",
    //     "statecode": 0,
    //     "statecode_label": "Actif",
    //     "statuscode": 1,
    //     "statuscode_label": "Actif"
    //   }
    // ]
  }

  public getMany_Favorites(): Observable<any> {

    return this.http
      .get(this.environment.urlServer + 'Favorites', { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.listFavorite = response.body.datas;
          return response.body.datas;
        })
      );
  }

  public isProgrammeInListFavorite(operationId: string): Favorite | undefined {
    return this.listFavorite.find((favorite: Favorite) => { return favorite.if_operationid == operationId });
  }

  public addFavorite(requestFields: any) {
    return this.http
      .post(this.environment.urlServer + 'Favorites', JSON.stringify(requestFields), { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body.datas);
          return response.body.datas;
        })
      );
  }

  public deleteFavorite(id: string) {
    return this.http
      .delete(this.environment.urlServer + 'Favorites/' + id, { headers: this.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
}
