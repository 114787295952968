<div class="d-flex flex-column h-100">
    <div> <!--ENTETE -->
        <div class="d-flex align-items-center mb-2">
            <div class="d-flex flex-column w-100 mb-2">
                <span class="fs-large"> <b>Documents attendus</b></span>
            </div>
        </div>
      
    </div>

    <div class="p-3 h-100">  <!--CORPS -->
        <div class="ms-2">
            <span class="d-flex flex-row" style="gap: 10px;">Client : <span> <b>{{objectReceivePopup.projectData.CLIENT}} </b></span></span>
        </div>
        <iframe [src]="url" width="100%" height="100%" style="border:none;">
        </iframe>
    </div>

    <div class="mt-auto d-flex justify-content-center gap-3">  <!--ACTIONS -->
        <button mat-dialog-close [ngClass]="{'btnCancel': objectReceivePopup.size >= 2, 'btnCancelSM': objectReceivePopup.size < 2 }" type="button" >FERMER</button>
    </div>
</div>

