import { Injectable } from '@angular/core';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component'
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private snackBar: MatSnackBar) {  }

  openSnackBar(message: string, panelClass: string[] ) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass
    });
  }

  open(message: string, panelClass: string[], time: number ) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: message,
      panelClass: panelClass,
      duration: time
    });
  }

}