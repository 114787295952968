import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';


export interface DialogDataPopup {
  size: number;
  projectId:string;
  entityName:string;
  projectData:any;
}


@Component({
  selector: 'app-popup-customer-area',
  templateUrl: './popup-customer-area.component.html',
  styleUrls: ['./popup-customer-area.component.scss']
})
export class PopupCustomerAreaComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopupCustomerAreaComponent>, 
    @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup,
    private sanitizer: DomSanitizer) { }

  url?:SafeResourceUrl;
  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.customerAreaProjectUrl
          .replace('{projectId}',this.objectReceivePopup.projectId)
          .replace('{entityName}',this.objectReceivePopup.entityName)
      );
  }

}
