import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { ActivityApiService } from 'src/app/shared/services/activity-api.service';
import { ProjectService } from 'src/app/shared/services/project.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { PopupCreateTask } from './../../../../shared/models/popup-create-task';
import { environment } from 'src/environments/environment';
import { Component, Inject, OnInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReferentialProjectInProgress } from 'src/app/shared/models/referential-project-in-progress';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-popup-create-task',
  templateUrl: './popup-create-task.component.html',
  styleUrls: ['./popup-create-task.component.scss']
})
export class PopupCreateTaskComponent implements OnInit {

  formCreateTask: FormGroup = this.formBuilder.group({})

  listConcernant: Array<any> = new Array<any>();
  listPriority: Array<any> = new Array<any>();
  listFieldTimeDuring: Array<any> = new Array<any>();


  isOpenSelectCreateTask = {
    selectConcernant: false,
    selectPriority: false,
    selectTimeDuring: false
  }

  labelSelectConcernant: string = "Concernant";
  labelSelectPriority: string = "Priorite";
  labelSelectDuring: string = "Priorite";
  labelDateEcheance: string = "DATE D’ECHEANCE";
  labelSubject: string = "Sujet";
  labelDescription: string = "Description";
  labelStartDate: string = "DATE DE DEBUT";
  labelSelectTimeDuring: string = "Duree";

  requiredSubject = true;

  disableHoraire: boolean = false;

  subModuleUse: string;

  // Le champ "subject" est aussi utilisé par "if_subjectcomplement" ( la champ sujet est aussi le champ complément)
  // Declaration - Injectable data mat dialog of parent - root to popup choice
  constructor(private servicePopup: PopupServiceService, public dialogRef: MatDialogRef<PopupCreateTaskComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: PopupCreateTask,
    private formBuilder: FormBuilder, private serviceProject: ProjectService, private serviceUser: UserService, private serviceActivity: ActivityApiService) {
    this.subModuleUse = environment.subModuleUse;
  }

  ngOnInit() {

    this.objectReceivePopup.composant.forEach((field: any) => {
      this.formCreateTask.addControl(field.name, new FormControl({ value: field.data, disabled: field.readonly }));
      if (field.name == "selectConcernant") {
        if (field.readonly) {
          this.listConcernant = [field.data];
          this.isOpenSelectCreateTask.selectConcernant = true;
        } else {
          this.serviceProject.getReferentialProjectInProgress().pipe(first()).subscribe((listProject: Array<ReferentialProjectInProgress>) => {
            listProject.forEach((projectInProgress: ReferentialProjectInProgress) => {
              this.listConcernant.push({ name: projectInProgress.if_name, id: { name: projectInProgress.if_name, id: projectInProgress.if_projectid } });
            });
          })
          // this.listConcernant = [{ name: "Aucune donnée", id: { name: "Aucune donnée", id: "Aucune donnée"  }}];  
        }
      }

      if (field.name == "selectPriority") {
        if (field.data !== null) {
          this.isOpenSelectCreateTask.selectPriority = true;
        }
        if (field.readonly) {
          this.listPriority = [field.data];
          this.isOpenSelectCreateTask.selectPriority = true;
        } else {
          // Normal, Faible, Haute
          this.listPriority = [{ name: "Faible", id: { name: "Faible", id: "0" } }, { name: "Normal", id: { name: "Normal", id: "1" } }, { name: "Haute", id: { name: "Haute", id: "2" } }];
        }
      }

      if (field.name == "selectTimeDuring") {
        if (field.data !== null) {
          this.isOpenSelectCreateTask.selectTimeDuring = true;
        }
        if (field.readonly) {
          this.listFieldTimeDuring = [field.data];
          this.isOpenSelectCreateTask.selectTimeDuring = true;
        } else {
          this.listFieldTimeDuring = [{
            name: '30 min.', id: {
              name: '30 min.', id: '30'
            }
          }, {
            name: '1 heure', id: {
              name: '1 heure', id: '60'
            }
          }, {
            name: '1 heure 30', id: {
              name: '1 heure 30', id: '90'
            }
          }, {
            name: '2 heures', id: {
              name: '2 heures', id: '120'
            }
          }, {
            name: '3 heures', id: {
              name: '3 heures', id: '180'
            }
          }, {
            name: '4 heures', id: {
              name: '4 heures', id: '240'
            }
          }, {
            name: '5 heures', id: {
              name: '5 heures', id: '300'
            }
          }, {
            name: '6 heures', id: {
              name: '6 heures', id: '360'
            }
          }, {
            name: '7 heures', id: {
              name: '7 heures', id: '420'
            }
          }];
        }
      }
      if (field.name == "ckeckBoxPlanifyCalendar") {
        if (field.data !== null) {
          this.changeValueAgenda({ checked: field.data });
        }
      }

      if (field.name == "if_subjectcomplement") {
        this.labelSubject = "Complément";
        this.formCreateTask.addControl("subject", new FormControl({ value: field.data, disabled: field.readonly }));
        this.requiredSubject = false;
      }
    })

  }

  changeValueAgenda(valueActivateAgenda: any) {
    
    if (valueActivateAgenda.checked) {
      this.formCreateTask.patchValue({ startDate: this.formCreateTask.value.dateEcheance })
    } else {
      this.formCreateTask.patchValue({ dateEcheance: this.formCreateTask.value.startDate })
    }

    let listContainerAllContentCreateTask = document.getElementsByClassName('containerAllContentCreateTask') as HTMLCollectionOf<HTMLElement>;
    if (listContainerAllContentCreateTask[0] !== undefined) {
      if (valueActivateAgenda.checked) {
        listContainerAllContentCreateTask[0].style.width = (window.screen.width - 200) + "px";
      } else {
        listContainerAllContentCreateTask[0].style.width = "auto";
      }
    }

    let listContainerForm = document.getElementsByClassName('containerForm') as HTMLCollectionOf<HTMLElement>;
    if (listContainerForm[0] !== undefined) {
      if (valueActivateAgenda.checked) {
        listContainerForm[0].style.width = "70%";
      } else {
        listContainerForm[0].style.width = "100%";
      }
    }

  }

  closePopupAnnulation() {
    if (this.objectReceivePopup.namePopup === "createTask") {
      localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
      this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false })
    } else if (this.objectReceivePopup.namePopup === "modifyTask") {
      localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
      this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false })
    } else if (this.objectReceivePopup.namePopup === 'createCallTel') {
      localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
      this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false })
    } else if (this.objectReceivePopup.namePopup === 'modifyCallTel') {
      localStorage.setItem('IdProjectBafUpdate' , this.objectReceivePopup.id);
      this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: true, validation: false })
    }


  }
















































  closePopupValidation() {

    let objectPostCreateTask: Array<any> = [];

    if (this.objectReceivePopup.namePopup === "createTask") {
      let valueSelectConsultant = null
      if (this.formCreateTask.value.selectConcernant !== undefined) {
        valueSelectConsultant = this.formCreateTask.value.selectConcernant !== null ? this.formCreateTask.value.selectConcernant.id : null;
      } else if (this.listConcernant.length === 1) {
        valueSelectConsultant = this.listConcernant[0].id.id
      }

      if (!this.formCreateTask.value.ckeckBoxPlanifyCalendar) {
        let newEndDate = new Date(this.formCreateTask.value.dateEcheance).setHours(8)
        objectPostCreateTask = [
          { "Key": "subject", "Value": this.formCreateTask.value.subject },
          { "Key": "scheduledend", "Value": new Date(newEndDate).toISOString() },
          { "Key": "description", "Value": this.formCreateTask.value.description },
          // { "Key": "prioritycode", "Value": this.formCreateTask.value.selectPriority.id },
          { "Key": "if_commercialassigmentid", "Value": this.serviceUser.user['if_commercialassignmentid'] },
          { "Key": "if_scheduledincalendar", "Value": "750010001" },
        ]
      } else {
        let newStartDate = new Date(this.formCreateTask.value.startDate).setHours(this.formCreateTask.value.time.hour)
        newStartDate = new Date(newStartDate).setMinutes(this.formCreateTask.value.time.minute)
        let newEndDate = new Date(newStartDate + this.formCreateTask.value.selectTimeDuring.id * 60000)

        objectPostCreateTask = [
          { "Key": "subject", "Value": this.formCreateTask.value.subject, },
          { "Key": "scheduledstart", "Value": new Date(newStartDate).toISOString(), },
          { "Key": "scheduledend", "Value": newEndDate.toISOString(), },
          { "Key": "description", "Value": this.formCreateTask.value.description, },
          // { "Key": "prioritycode", "Value": this.formCreateTask.value.selectPriority.id, },
          { "Key": "if_commercialassigmentid", "Value": this.serviceUser.user['if_commercialassignmentid'], },
          { "Key": "if_scheduledincalendar", "Value": "750010000" },
        ]
      }

      if(this.formCreateTask.value.selectPriority != null){
        if(this.formCreateTask.value.selectPriority.id != null){
          objectPostCreateTask.push({ "Key": "prioritycode", "Value": this.formCreateTask.value.selectPriority.id })
        }
      }
      // else objectPostCreateTask.push({ "Key": "prioritycode", "Value": "1" })

      if (valueSelectConsultant !== null) {
        objectPostCreateTask.push({ "Key": "regardingobjectid", "EntityName": "if_project", "Id": valueSelectConsultant });
      }

      this.servicePopup.openDialogLoading('createTask', this.objectReceivePopup.size, "")
      this.serviceActivity.postTaskActivity({ "Fields": objectPostCreateTask }).subscribe((responsePostTask: any) => {
        // console.log(responsePostTask)
        localStorage.setItem('IdProjectBafUpdate' , this.serviceUser.user['if_commercialassignmentid']);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    }
    else if (this.objectReceivePopup.namePopup === "modifyTask") {
      let valueSelectConsultant = null
      if (this.formCreateTask.value.selectConcernant !== undefined) {
        valueSelectConsultant = this.formCreateTask.value.selectConcernant !== null ? this.formCreateTask.value.selectConcernant.id : null;
      } else if (this.listConcernant.length === 1) {
        valueSelectConsultant = this.listConcernant[0].id.id
      }

      let startDate = ""
      let endDate = ""

      if (!this.formCreateTask.value.ckeckBoxPlanifyCalendar) {

        try {
          endDate = this.formCreateTask.value.dateEcheance.toISOString();
        } catch (e) {
          endDate = this.formCreateTask.value.dateEcheance;
        }

        objectPostCreateTask = [
          { "Key": "subject", "Value": this.formCreateTask.value.subject, },
          { "Key": "scheduledend", "Value": endDate, },
          { "Key": "description", "Value": this.formCreateTask.value.description, },
          { "Key": "prioritycode", "Value": this.formCreateTask.value.selectPriority.id, },
          { "Key": "if_commercialassigmentid", "Value": this.serviceUser.user['if_commercialassignmentid'], },
          { "Key": "if_scheduledincalendar", "Value": "750010001" },
        ]
        if (valueSelectConsultant !== null) {
          if (valueSelectConsultant.id !== null) {
            objectPostCreateTask.push({ "Key": "regardingobjectid", "EntityName": "if_project", "Id": valueSelectConsultant });
          }
        }
      } else {
        let newStartDate = new Date(this.formCreateTask.value.startDate).setHours(this.formCreateTask.value.time.hour)
        newStartDate = new Date(newStartDate).setMinutes(this.formCreateTask.value.time.minute)
        let newEndDate = new Date(newStartDate + this.formCreateTask.value.selectTimeDuring.id * 60000)


        try {
          startDate = new Date(newStartDate).toISOString();
        } catch (e) {
          startDate = this.formCreateTask.value.startDate;
        }

        objectPostCreateTask = [
          { "Key": "subject", "Value": this.formCreateTask.value.subject, },
          { "Key": "scheduledstart", "Value": startDate, },
          { "Key": "scheduledend", "Value": newEndDate.toISOString(), },
          { "Key": "description", "Value": this.formCreateTask.value.description, },
          { "Key": "prioritycode", "Value": this.formCreateTask.value.selectPriority.id, },
          { "Key": "if_commercialassigmentid", "Value": this.serviceUser.user['if_commercialassignmentid'], },
          { "Key": "if_scheduledincalendar", "Value": "750010000" },
        ]
        if (valueSelectConsultant !== null) {
          if (valueSelectConsultant.id !== null) {
            objectPostCreateTask.push({ "Key": "regardingobjectid", "EntityName": "if_project", "Id": valueSelectConsultant });
          }
        }

      }

      this.servicePopup.openDialogLoading('modifyTask', this.objectReceivePopup.size, "")
      this.serviceActivity.putTaskActivity({ "Fields": objectPostCreateTask }, this.objectReceivePopup.id).subscribe((responsePostTask: any) => {
        // console.log(responsePostTask)
        localStorage.setItem('IdProjectBafUpdate' , this.serviceUser.user['if_commercialassignmentid']);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    }
    else if (this.objectReceivePopup.namePopup === 'createCallTel') {
      // let newStartDate = new Date(this.formCreateTask.value.dateEcheance).setHours(this.formCreateTask.value.time.hour)
      // newStartDate = new Date(newStartDate).setMinutes(this.formCreateTask.value.time.minute)
      // let newEndDate = new Date(newStartDate + this.formCreateTask.value.selectTimeDuring.id*60000)
      let valueSelectConsultant = null
      if (this.formCreateTask.value.selectConcernant !== undefined) {
        valueSelectConsultant = this.formCreateTask.value.selectConcernant !== null ? this.formCreateTask.value.selectConcernant.id : null;
      } else if (this.listConcernant.length === 1) {
        valueSelectConsultant = this.listConcernant[0].id.id
      }
      objectPostCreateTask = [
        { "Key": "if_subjectcomplement", "Value": this.formCreateTask.value.subject, },
        { "Key": "description", "Value": this.formCreateTask.value.description, },
        { "Key": "if_commercialassigmentid", "Value": this.serviceUser.user['if_commercialassignmentid'] },
        { "Key": "if_scheduledincalendar", "Value": this.formCreateTask.value.ckeckBoxPlanifyCalendar ? "750010000" : "750010001" },
      ]
      if (valueSelectConsultant !== null) {
        if (valueSelectConsultant !== null) {
          objectPostCreateTask.push({ "Key": "regardingobjectid", "EntityName": "if_project", "Id": valueSelectConsultant });
        }
      }
      if (this.formCreateTask.value.ckeckBoxPlanifyCalendar) { // Cas ou la checkbox est coché
        // let newEndDate = new Date(newStartDate + this.formCreateTask.value.selectTimeDuring.id * 60000)
        let newStartDate = new Date(this.formCreateTask.value.startDate).setHours(this.formCreateTask.value.time.hour)
        newStartDate = new Date(newStartDate).setMinutes(this.formCreateTask.value.time.minute)
        let newEndDate = new Date(newStartDate + this.formCreateTask.value.selectTimeDuring.id * 60000)

        objectPostCreateTask.push(
          { "Key": "scheduledstart", "Value": new Date(newStartDate).toISOString(), },
          { "Key": "scheduledend", "Value": newEndDate.toISOString(), });
      } else {
        var endDateFormatted = new Date(new Date(this.formCreateTask.value.dateEcheance.setHours(this.formCreateTask.value.time.hour)).setHours(this.formCreateTask.value.time.hour)).setMinutes(this.formCreateTask.value.time.minute);
        objectPostCreateTask.push({ "Key": "scheduledend", "Value": new Date(endDateFormatted).toISOString(), })
      }

      // Je ne connais pas le champ pour la checkbox ajouter dans l'agenda
      this.servicePopup.openDialogLoading('createCallTel', this.objectReceivePopup.size, "")
      this.serviceActivity.postPhoneCallActivity({ "Fields": objectPostCreateTask }).subscribe((responsePostTask: any) => {
        console.log(responsePostTask)
        localStorage.setItem('IdProjectBafUpdate' , this.serviceUser.user['if_commercialassignmentid']);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    }
    else if (this.objectReceivePopup.namePopup === 'modifyCallTel') {

      let newStartDate = new Date(this.formCreateTask.value.startDate).setHours(this.formCreateTask.value.time.hour)
      newStartDate = new Date(newStartDate).setMinutes(this.formCreateTask.value.time.minute)
      let valueSelectConsultant = null
      if (this.formCreateTask.value.selectConcernant !== undefined) {
        valueSelectConsultant = this.formCreateTask.value.selectConcernant !== null ? this.formCreateTask.value.selectConcernant.id : null;
      } else if (this.listConcernant.length === 1) {
        valueSelectConsultant = this.listConcernant[0].id.id
      }

      objectPostCreateTask = [
        { "Key": "if_subjectcomplement", "Value": this.formCreateTask.value.subject, },
        { "Key": "description", "Value": this.formCreateTask.value.description, },
        { "Key": "if_commercialassigmentid", "Value": this.serviceUser.user['if_commercialassignmentid'] },
        { "Key": "if_scheduledincalendar", "Value": this.formCreateTask.value.ckeckBoxPlanifyCalendar ? "750010000" : "750010001" },
      ]
      if (valueSelectConsultant !== null) {
        if (valueSelectConsultant.id !== null) {
          objectPostCreateTask.push({ "Key": "regardingobjectid", "EntityName": "if_project", "Id": valueSelectConsultant });
        }
      }
      if (this.formCreateTask.value.ckeckBoxPlanifyCalendar) { // Cas ou la checkbox est coché
        let newEndDate = new Date(newStartDate + this.formCreateTask.value.selectTimeDuring.id * 60000)
        objectPostCreateTask.push(
          { "Key": "scheduledstart", "Value": new Date(newStartDate).toISOString(), },
          { "Key": "scheduledend", "Value": newEndDate.toISOString(), });
      } else {
        var endDateFormatted = new Date(new Date(this.formCreateTask.value.dateEcheance.setHours(this.formCreateTask.value.time.hour)).setHours(this.formCreateTask.value.time.hour)).setMinutes(this.formCreateTask.value.time.minute);
        objectPostCreateTask.push({ "Key": "scheduledend", "Value": new Date(endDateFormatted).toISOString(), })
      }


      // Je ne connais pas le champ pour la checkbox ajouter dans l'agenda
      this.servicePopup.openDialogLoading('modifyCallTel', this.objectReceivePopup.size, "")
      this.serviceActivity.putPhoneCallActivity({ "Fields": objectPostCreateTask }, this.objectReceivePopup.id).subscribe((responsePostTask: any) => {
        console.log(responsePostTask)
        // if (this.objectReceivePopup.id) {
        //   localStorage.setItem('IdProjectBafUpdate', this.objectReceivePopup.id)
        // }
        // this.serviceConfigObservable.emitRefreshInProgress(true)
        localStorage.setItem('IdProjectBafUpdate' , this.serviceUser.user['if_commercialassignmentid']);
        this.dialogRef.close({ namePopup: this.objectReceivePopup.namePopup, annulation: false, validation: true })
      })
    }

  }





























































  // Compare with for select (compare value observe in select with item (show construction array items))
  compareWithFn(item: any, selected: any) {
    return item === selected;
  }

  // Method for text label on select and reset of select simple
  openSelectAndReset(selectName: string, insertFormValue: FormGroup, isOpenSelect: any, labelSideBar?: string) {
    if (insertFormValue.value[selectName] !== null) {
      isOpenSelect[selectName] = true;
    } else {
      isOpenSelect[selectName] = false;
    }
  }
}



