import { Component, Inject, Injectable, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

// @Injectable({
//   providedIn: 'root'
// })

export interface ModalDatas {
  size: number;
  withCloseBtn: boolean;
  withValidateBtn: boolean;

  modalTitle: string ;
  modalBody: string ;
  closeBtnLabel: string ;
  validateBtnLabel: string ;
  modalDatas : any;
}


@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements OnInit {


  //#region   P R O P E R T I E S
  //
  //
  withCloseBtn: boolean = true;
  withValidateBtn: boolean = true;

  modalTitle: string = "";
  modalBody: string = "";
  closeBtnLabel: string = "";
  validateBtnLabel: string = "";

  subModuleUse: string = "";
  //
  //
  //#endregion


  // constructor(private injector: Injector,public dialogRef: MatDialogRef<ModalComponent>,@Inject(MAT_DIALOG_DATA) public data: ModalDatas) {
    constructor(@Inject(MAT_DIALOG_DATA) public data: ModalDatas) {
    if (data.withCloseBtn != null)
      this.withCloseBtn = data.withCloseBtn;

    if (data.withValidateBtn != null)
      this.withValidateBtn = data.withValidateBtn;

    if (data.modalTitle != "" || data.modalTitle != undefined)
      this.modalTitle = data?.modalTitle;

    if (data.modalBody != "" || data.modalBody != undefined)
      this.modalBody = data.modalBody;

    if (data.closeBtnLabel != "" || data.closeBtnLabel != undefined)
      this.closeBtnLabel = data.closeBtnLabel;

    if (data.validateBtnLabel != "" || data.validateBtnLabel != undefined)
      this.validateBtnLabel = data.validateBtnLabel;

      this.subModuleUse = environment.subModuleUse;
  }

  ngOnInit(): void {
  }

  display2Modal(option: ({
    withCloseBtn: boolean,
    withActionBtn: boolean,
    modalTitle: string,
    modalBody: string,
    closeBtnLabel: string,
    actionBtnLabel: string
  })) {

    this.withCloseBtn = option.withCloseBtn ? false : true;
    this.withValidateBtn = option.withActionBtn ? false : true;

    if (option.modalTitle != "" || option.modalTitle != undefined)
      this.modalTitle = option?.modalTitle;

    if (option.modalBody != "" || option.modalBody != undefined)
      this.modalBody = option.modalBody;

    if (option.closeBtnLabel != "" || option.closeBtnLabel != undefined)
      this.closeBtnLabel = option.closeBtnLabel;

    if (option.actionBtnLabel != "" || option.actionBtnLabel != undefined)
      this.validateBtnLabel = option.actionBtnLabel;
  }


}
