import { UserService } from 'src/app/shared/services/user.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/shared/services/authentification.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DenunciationService {

  environment: any = null;

	constructor(private http: HttpClient, private authentificationService: AuthentificationService, private serviceUser: UserService) {
    this.environment = environment;
  }

  public postCreateDenunciation(dataCreateDenunciation: any): Observable<any> {
    const headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json',
      'Authorization': this.serviceUser.user.Security.leadprovider_token
    });

    return this.http
      .post(this.environment.urlFournisseurApi + 'Projects/Import', dataCreateDenunciation, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public postDenunciation(dataResearchSales: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Sales/Leads/Search', dataResearchSales, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getDenunciationAccepted(OperationIds: string): Observable<any> {
    const headers = this.authentificationService.headers;

    let requestLeads = {
      "OperationIds": [
        OperationIds
      ]
    }

    return this.http
      .post(this.environment.urlServer + 'Sales/Leads/SearchReferentials?Page=1', requestLeads, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getListNameDenunciation(page: number, nbPage: number, term: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Sales/Leads/SearchReferentials?Page=' + page + '&PageSize=' + nbPage + '&Search=' + term, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }
}
