import { Component, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ResizeService } from './resize.service';
import { SCREEN_SIZE } from './screen-size.enum';

@Component({
  selector: 'app-size-detector',
  templateUrl: './size-detector.component.html',
  styleUrls: ['./size-detector.component.scss']
})
export class SizeDetectorComponent implements AfterViewInit {
  prefix = 'is-';
  sizes = [
    {
      id: SCREEN_SIZE.XS, name: 'xs',
      css: `d-block d-sm-none`
    },
    {
      id: SCREEN_SIZE.SM, name: 'sm',
      css: `d-none d-sm-block d-md-none`
    },
    {
      id: SCREEN_SIZE.MD, name: 'md',
      css: `d-none d-md-block d-lg-none`
    },
    {
      id: SCREEN_SIZE.LG, name: 'lg',
      css: `d-none d-lg-block d-xl-none`
    },
    {
      id: SCREEN_SIZE.XL, name: 'xl',
      css: `d-none d-xl-block`
    },
  ];

  constructor(private elementRef: ElementRef, private resizeSvc: ResizeService) { }

  @HostListener("window:resize", [])
  private onResize() {
    this.detectScreenSize();
  }

  ngAfterViewInit() {
    this.detectScreenSize();
  }

  private detectScreenSize() {
    if ( window.screen.width < 576 ) {
      this.resizeSvc.onResize(0)
    } else if ( window.screen.width >= 576 && window.screen.width < 768 ) { // 768px portrait
      this.resizeSvc.onResize(1);
    } else if ( window.screen.width >= 768 && window.screen.width < 1122 ) {
      this.resizeSvc.onResize(2);
    } else if ( window.screen.width >= 1122 && window.screen.width < 1440 ) {
      this.resizeSvc.onResize(3);
    } else {
      this.resizeSvc.onResize(4);
    }
    
    // this.resizeSvc.onResize(currentSize.id);
    // const currentSize: any = this.sizes.find(x => {
    //   const el = this.elementRef.nativeElement.querySelector(`.${this.prefix}${x.id}`);
    //   const isVisible = window.getComputedStyle(el).display != 'none';

    //   return isVisible;
    // });

    // this.resizeSvc.onResize(currentSize.id);
  }

}