import { PropositionService } from 'src/app/shared/services/proposition.service';
import { myComponents } from 'src/environments/environment';
import { PopupBreakingNewsComponent } from './../../pages/connected/components/Popup/popup-breaking-news/popup-breaking-news.component';
import { PopupCreateTaskComponent } from './../../pages/connected/components/popup-create-task/popup-create-task.component';
import { PopupCustomComponent } from './../../pages/connected/components/popup-custom/popup-custom.component';
import { TableHousingService } from 'src/app/shared/services/table-housing.service';
import { OperationService } from 'src/app/shared/services/operation.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { PopupPlanViewerComponent } from './../../pages/connected/components/popup-plan-viewer/popup-plan-viewer.component';
import { AppointmentService } from './appointment.service';
import { ErrorMessageService } from './error-message.service';
import { ActivityApiService } from './activity-api.service';
import { ProjectService } from './project.service';
import { Injectable } from '@angular/core';
import { PopupFormSaleComponent } from 'src/app/pages/connected/components/popup-form-sale/popup-form-sale.component';
import { MatDialog } from '@angular/material/dialog';
import { ConfigObservableService } from './service-config-observable-service.service';
import { PeriodictElement } from '../models/periodict-element';
import { PopupFormRdvComponent } from 'src/app/pages/connected/components/popup-form-rdv/popup-form-rdv.component';
import { first } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';
import { PopupLoadingComponent } from 'src/app/pages/connected/components/popup-loading/popup-loading.component';
import { HttpResponse } from '@angular/common/http';
import { convertTZ } from './functions';
import { Subject } from 'rxjs';
import { AppointmentId } from '../models/appointment-id';
import { PopupCompletedProjectComponent } from 'src/app/pages/connected/components/popup-completed-project/popup-completed-project.component';
import { Router } from '@angular/router';
import { PopupSearchSaveComponent } from 'src/app/pages/connected/components/popup-search-save/popup-search-save.component';
import { PopupConvertProjectComponent } from 'src/app/pages/connected/components/popup-convert-project/popup-convert-project.component';
import { ProjectTableHousing } from '../models/project-table-housing';
import { ModalComponent, ModalDatas } from 'src/app/pages/connected/components/modal/modal.component';
import { ReceivePopupCustom } from '../models/receive-popup-custom';
import { NotResponseRdvComponent } from 'src/app/pages/connected/components/not-response-rdv/not-response-rdv.component';
import { PopupCreateTask } from '../models/popup-create-task';
import { PopupImgCarouselComponent } from 'src/app/pages/connected/components/popup-img-carousel/popup-img-carousel.component';
import { PopupVideoCarouselComponent } from 'src/app/pages/connected/components/popup-video-carousel/popup-video-carousel.component';
import { PopupSalesOffersComponent } from 'src/app/pages/connected/components/Popup/popup-sales-offers/popup-sales-offers.component';
import { PopupErrorDescriptionOperationComponent } from 'src/app/pages/connected/components/Popup/popup-error-description-operation/popup-error-description-operation.component';
import { PopupAcceptedRuleComponent } from 'src/app/pages/connected/components/Popup/popup-accepted-rule/popup-accepted-rule.component';
import { PopupCustomerAreaComponent } from 'src/app/pages/connected/components/Popup/popup-customer-area/popup-customer-area.component';
@Injectable({
  providedIn: 'root'
})
export class PopupServiceService {

  // Declaration for receive value baf synchronized true or false
  valueResponseBafSynchronize: any;

  myComponents: any

  dialogRefLoading: any = null;
  public configObservableSendSpinnerLoadButton = new Subject<boolean>();
  public configObservableNewPostOptionEvent = new Subject<PeriodictElement>();
  public configObservableNewPostCancelOptionEvent = new Subject<PeriodictElement>();

  constructor(public popupLoading: MatDialog, public popupRecallRdv: MatDialog, public popupSaleForm: MatDialog, public popupFormRdv: MatDialog, public popupErrorDescriptionOperation: MatDialog, 
    public popupBreakingNews: MatDialog, public popupSalesOffers: MatDialog, public popupPostDenunciation: MatDialog, public popupPostOptionDenunciation: MatDialog,
    public popupPlan: MatDialog, public modalPopup: MatDialog, public popupPostLot: MatDialog, public popupImgCarousel: MatDialog, private documentService: DocumentService,
    private serviceConfigObservable: ConfigObservableService, private serviceErrorMessage: ErrorMessageService, private snackbar: SnackbarService,
    private serviceProject: ProjectService, private serviceActivityApi: ActivityApiService, private serviceAppointment: AppointmentService,
    private serviceTableHousing: TableHousingService, private propositionService: PropositionService,
    private serviceOperation: OperationService, public popupSearch: MatDialog, public popupCompletedProject: MatDialog,
    public popupAcceptedRule: MatDialog, private router: Router) {
      this.myComponents = myComponents;
     }


  /******************************************** Service with Function for all popup of web site  ***************************************************************/

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string, size: SCREEN_SIZE, errorMessage: string, ruleRdv?: any) {
    this.dialogRefLoading = this.popupLoading.open(PopupLoadingComponent, {
      width: '100%',
      height: 'auto',
      maxWidth: '150vw',
      data: { namePopup: namePopupLoading, dataPopup: errorMessage, size: size },
      panelClass: 'customDialogLoading',
      disableClose: true
    });

    this.dialogRefLoading.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${namePopupLoading}, Result : ${result}`);
      // this.popupLoading.closeAll()
      if (namePopupLoading === "postResearchValid" || namePopupLoading === "postDenunciationValid") {
        this.popupLoading.closeAll()
      } else if (namePopupLoading === "errorMessageGestOption" || namePopupLoading === "validGestOption" || namePopupLoading === "notGetBafSynchronizedOption" || namePopupLoading === 'notGetBafSynchronizedOptionNotId') {
        this.popupLoading.closeAll()
        if (namePopupLoading === "validGestOption" || namePopupLoading === "errorMessageGestOption") {
          this.propositionService.activationTagPageSales = 'Options'
          this.router.navigate(['/log/sale']);
        }
      } else {
        // if (ruleRdv !== undefined) {
        //   if (ruleRdv.projectId !== undefined) {
        //     localStorage.setItem('IdProjectBafUpdate' , ruleRdv.projectId)
        //     this.serviceConfigObservable.emitRefreshInProgress(true)
        //     this.serviceConfigObservable.emitRefreshAppointmentUpdate(true)
        //   } else {

        //   }
        // }
      }
    });
  }

  openPopupCreateTask(objectCreateTask: PopupCreateTask, nameComponent: string) {
    const dialogRef = this.popupRecallRdv.open(PopupCreateTaskComponent, {
      width: '100%',
      height: 'auto',
      maxWidth: '150vw',
      data: objectCreateTask,
      panelClass: 'styleForPopupCreateTask',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.namePopup === 'createTask') {
        console.log(`Close dialog popup form createTask, Result : ${result}`);
        this.popupLoading.closeAll()

        if (result.validation) {
          this.serviceConfigObservable.emitRefreshInProgress(true);
          if (nameComponent !== "project" && result.validation) {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === 'modifyTask') {
        console.log(`Close dialog popup form modifyTask, Result : ${result}`);
        this.popupLoading.closeAll()

        if (result.validation) {
          this.serviceConfigObservable.emitRefreshInProgress(true);
          if (nameComponent !== "project" && result.validation) {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === 'createCallTel') {
        console.log(`Close dialog popup form createCallTel, Result : ${result}`);
        this.popupLoading.closeAll()

        if (result.validation) {
          this.serviceConfigObservable.emitRefreshInProgress(true);
          if (nameComponent !== "project" && result.validation) {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === 'modifyCallTel') {
        console.log(`Close dialog popup form modifyCallTel, Result : ${result}`);
        this.popupLoading.closeAll()

        if (result.validation) {
          this.serviceConfigObservable.emitRefreshInProgress(true);
          if (nameComponent !== "project" && result.validation) {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
    });
  }



  // Function open dialog popup custom 
  openDialogCustomPopup(objectReceivePopup: ReceivePopupCustom, nameComponent: string) {
    var size = "";
    switch (objectReceivePopup.size) {
      case 4:
        size = "150vw";
        break;
      case 3:
        size = "125vw";
        break;
      case 0:
      case 1:
      case 2:
        size = "90vw";
        break;
      default:
        size = "150vw";
    }
    const dialogRef = this.popupLoading.open(PopupCustomComponent, {
      // width: '100%',
      height: 'auto',
      maxWidth: size,
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result.namePopup === "closeTask") {
        if (result.validation) {
          this.popupLoading.closeAll()
          this.serviceConfigObservable.emitRefreshInProgress(true);
          if (nameComponent !== "project") {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === "closeTaskAnnulation") {
        if (result.validation) {
          this.popupLoading.closeAll()
          this.serviceConfigObservable.emitRefreshInProgress(true);

          if (nameComponent !== "project") {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === "closeCallTel") {
        if (result.validation) {
          this.popupLoading.closeAll();
          this.serviceConfigObservable.emitRefreshInProgress(true);

          if (nameComponent !== "project") {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === "closeCallTelAnnulation") {
        if (result.validation) {
          this.popupLoading.closeAll()
          this.serviceConfigObservable.emitRefreshInProgress(true);

          if (nameComponent !== "project") {
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          }
        }
      }
      if (result.namePopup === "duplicateProject") {
        if (result.annulation) {
          console.log(`Close dialog popup form duplicateProject, Result : ${result}`);
        }
        if (result.validation) {
          console.log(`Close dialog popup form duplicateProject, Result : ${result}`);
          this.popupLoading.closeAll()

          this.serviceConfigObservable.emitRefreshInProgress(true);

        }
      }
      if (result.namePopup === "duplicateProjectPlacement") {
        if (result.annulation) {
          console.log(`Close dialog popup form duplicateProject, Result : ${result}`);
        }
        if (result.validation) {
          console.log(`Close dialog popup form duplicateProject, Result : ${result}`);
          this.popupLoading.closeAll()

          this.serviceConfigObservable.emitRefreshInProgress(true);

        }
      }
    });
  }

    /******************************************************** POPUP PLAN *****************************************************************************************/

    openPopupImgCarousel(objectReceivePopup: any, ) {
      const dialogRef = this.popupImgCarousel.open(PopupImgCarouselComponent, {
        // width: '100%',
        height: 'auto',
        maxWidth: "150vw",
        data: objectReceivePopup,
        panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
        disableClose: true
      });
    
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
        this.serviceConfigObservable.emitClosePopupCarouselSuiviChantier(true);
      });
    }

    openPopupVideoCarousel(objectReceivePopup: any, ) {
      const dialogRef = this.popupImgCarousel.open(PopupVideoCarouselComponent, {
        // width: '100%',
        height: 'auto',
        maxWidth: "150vw",
        data: objectReceivePopup,
        panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
        disableClose: true
      });
    
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      });
    }

  /******************************************************** POPUP PLAN *****************************************************************************************/

  // Methode for activate open close popup of pdf select and custom popup (look at popup-search-save.component.html and scss)
  openPopupPlan(idPlanAsset: string, namePdf: string, size: SCREEN_SIZE) {
    console.log(idPlanAsset);
    if (idPlanAsset !== 'assets/defaultimage.png') {
      this.openDialogLoading('loadingShowDocument', size, "")
      let tabSplitIdAsset = idPlanAsset.split('.')
      this.documentService.getShowDocumentWithIdForPdfViewer(tabSplitIdAsset[0])
        .pipe(first()).subscribe((urlDocument: string) => {
          // let pdfSrc = ["../../../../../Resources/Big/" + idPlanAsset, "Plan_Lot_" + namePdf + '.' + tabSplitIdAsset[tabSplitIdAsset.length - 1]]
          let pdfSrc = [urlDocument, "Plan_Lot_" + namePdf]

          let minHeight = 'auto';
          let maxWidth = '95vw';
          //let width = '75%';
          let height = 'auto';
          let panelClass = 'CustomPopupPlanSM'

          if (size !== undefined) {
            if (size < 2) {
              maxWidth = '95vw';
              height = 'auto'
              panelClass = 'CustomPopupPlanSM'
            }
          }

          this.popupLoading.closeAll()

          const dialogRef = this.popupPlan.open(PopupPlanViewerComponent, {
            maxWidth: maxWidth,
            minHeight: minHeight,
            width: 'auto',
            height: height,
            data: { pdf: pdfSrc[0], namePdf: pdfSrc[1], id: idPlanAsset.split('.')[0], image: idPlanAsset, size: size },
            disableClose: true,
            panelClass: panelClass
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log(`Close dialog popup pdf ${pdfSrc[1]}, Result : ${result}`);
            if (size < 2) {
              this.serviceConfigObservable.emitOverflowHidden(false);
            }
          });
        }, error => {
          this.popupLoading.closeAll()
        })
    }
  }

  /******************************************************** POPUP SIGN DER  ***********************************************************************************/

  // Open popup sign der next to name page send of front html
  openPopupSignDER(namePopup: string, dataPopup: any, size: SCREEN_SIZE) {
    const dialogRef = this.popupSaleForm.open(PopupFormSaleComponent, {
      width: '672px',
      height: '565px',
      data: { namePopup: namePopup, dataPopup: dataPopup, size: size },
      disableClose: true,
      panelClass: 'customDialogFormSale'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Close dialog popup form der ${namePopup}, Result : ${result}`);
      if (result !== "") {
        this.serviceConfigObservable.emitRefreshInProgress(true);
      }
    });
  }

  openPopupSignDER_Custom(namePopup: string, dataPopup: any, size: SCREEN_SIZE) {
    let maxWidth = '80vw';
    let minHeight = 'initial';
    let maxHeight = 'initial';
    let width = '672px';
    let height = '565px';
    let panelClass = 'customDialogFormSale';

    if (size !== undefined) {
      if (size < 2) {
        maxWidth = '95vw';
        maxHeight = '640px';
        width = 'auto';
        height = 'auto';
        minHeight = 'auto';
        panelClass = 'customDialogFormSale'
      }
    }

    const dialogRef = this.popupSaleForm.open(PopupFormSaleComponent, {
      width: width,
      height: height,
      maxWidth: maxWidth,
      minHeight: minHeight,
      maxHeight: maxHeight,
      data: { namePopup: namePopup, dataPopup: dataPopup, size: size },
      disableClose: true,
      panelClass: panelClass
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Close dialog popup form der ${namePopup}, Result : ${result}`);
      if (result !== "") {
        this.serviceConfigObservable.emitRefreshInProgress(true);
      }
    });
  }

  openPopupConvertProject_Custom(namePopup: string, dataPopup: any, size: SCREEN_SIZE) {
    let maxWidth = '80vw';
    let minHeight = 'initial';
    let maxHeight = 'initial';
    let width = '672px';
    let height = '565px';
    let panelClass = 'customDialogFormSale';

    if (size !== undefined) {
      if (size < 2) {
        maxWidth = '95vw';
        maxHeight = '640px';
        width = 'auto';
        height = 'auto';
        minHeight = 'auto';
        panelClass = 'customDialogFormSale'
      }
    }

    const dialogRef = this.popupSaleForm.open(PopupConvertProjectComponent, {
      width: width,
      height: height,
      maxWidth: maxWidth,
      minHeight: minHeight,
      maxHeight: maxHeight,
      data: { namePopup: namePopup, dataPopup: dataPopup, size: size },
      disableClose: true,
      panelClass: panelClass
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Close dialog popup form der ${namePopup}, Result : ${result}`);
      if (result !== "") {
        this.serviceConfigObservable.emitRefreshInProgress(true);
      }
    });
  }

  /******************************************************** POPUP EDIT PROJECT *******************************************************************************/
  // Open popup edit project

  openPopupEditProject_Custom(namePopup: string, dataPopup: any, size: SCREEN_SIZE, spinnerLoad: Boolean) {
    try {
      if (namePopup === 'EditContactOtherPageProject') {
        this.serviceProject.getProjectWithId(dataPopup.projectId).pipe(first()).subscribe((project: any) => {

          let maxWidth = '150vw';
          let minHeight = 'initial';
          let maxHeight = 'initial';
          let width = '100%';
          let height = '1300px';
          let panelClass = 'customDialogCompleteProjectLarge';
          if (size == 2) {
            panelClass = 'customDialogCompleteProjectLargeMD';
          }

          if (size !== undefined) {
            if (size <= 2) {
              maxWidth = '95vw';
              maxHeight = '640px';
              width = 'auto';
              height = 'auto';
              minHeight = 'auto';
              panelClass = 'customDialogCompleteProjectLargeSM'
            }
          }

          let newDataPopup = dataPopup;
          newDataPopup.event2 = project;
          this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
          const dialogRef = this.popupCompletedProject.open(PopupCompletedProjectComponent, {
            width: width,
            height: height,
            maxWidth: maxWidth,
            minHeight: minHeight,
            maxHeight: maxHeight,
            data: { namePopup: namePopup, dataPopup: newDataPopup, size: size },
            disableClose: true,
            panelClass: panelClass // Attention le custom de class est situer dans le composant parent qui ouvre la popup
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log(`Close dialog popup edit project ${namePopup}, Result : ${result}`);
          });
        })
      } else {
        let maxWidth = '150vw';
        let minHeight = 'initial';
        let maxHeight = 'initial';
        let width = '100%';
        let height = '1300px';
        let panelClass = 'customDialogCompleteProjectLarge';
        if (size == 2) {
          panelClass = 'customDialogCompleteProjectLargeMD';
        }

        if (size !== undefined) {
          if (size <= 2) {
            maxWidth = '95vw';
            maxHeight = '640px';
            width = 'auto';
            height = 'auto';
            minHeight = 'auto';
            panelClass = 'customDialogCompleteProjectLargeSM'
          }
        }

        let newDataPopup = dataPopup;
        // newDataPopup.event = project;
        this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
        const dialogRef = this.popupCompletedProject.open(PopupCompletedProjectComponent, {
          width: width,
          height: height,
          maxWidth: maxWidth,
          minHeight: minHeight,
          maxHeight: maxHeight,
          data: { namePopup: namePopup, dataPopup: newDataPopup, size: size },
          disableClose: true,
          panelClass: panelClass // Attention le custom de class est situer dans le composant parent qui ouvre la popup
        });

        dialogRef.afterClosed().subscribe(result => {
          console.log(`Close dialog popup edit project ${namePopup}, Result : ${result}`);
        });
      }
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetProjectWithId, ["custom-style-error"]
      );
    }
  }

  /******************************************************** POPUP CREATE NEW RDV *******************************************************************************/

  // Methode for get if baf is synchronized after update project
  async getBafSynchronized(data: any, openNewRdv: boolean, namePopup: string, programData: PeriodictElement | ProjectTableHousing, size: SCREEN_SIZE, spinnerLoad: boolean) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        // this.popupLoading.closeAll();
        this.openDialogLoading('notGetBafSynchronizedProject', size, "");
        clearInterval(setIntervalProjectId);
      }

      this.serviceAppointment.getBafSynchronizedAppointment(data).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body.datas;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize === true) {
          if (namePopup === 'editRdv') {
            this.serviceConfigObservable.emitRefreshAppointmentCreate(data);
          }
          if (namePopup == 'editRdvWithInProgress') {
            //  Voir pour ajouter un get sur la recherche en cours 
            if (programData.projectId !== undefined) {
              localStorage.setItem('IdProjectBafUpdate', programData.projectId)
            }
            this.serviceConfigObservable.emitRefreshInProgress(true)
          }
          this.popupLoading.closeAll();
          if (openNewRdv) {
            this.openPopupCreateNewRdv_CustomCardTableHousingMobile('editRdv', programData, size, spinnerLoad)
            clearInterval(setIntervalProjectId);
          } else {
            clearInterval(setIntervalProjectId);
          }
        }
      }
      console.log(i)
      i++
    }, 1000)
  }

  // Methode for get if baf is synchronized after update project
  async getBafSynchronizedCloseAppointment(data: any, openNewRdv: boolean, programData: PeriodictElement, size: SCREEN_SIZE, spinnerLoad: boolean) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        // this.popupLoading.closeAll();
        this.openDialogLoading('notGetBafSynchronizedProject', size, "");
        clearInterval(setIntervalProjectId);
      }

      this.serviceAppointment.getBafSynchronizedCloseAppointment(data).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body.datas;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize === true) {
          //  Voir pour ajouter un get sur la recherche en cours 

          if (programData.projectId !== undefined) {
            localStorage.setItem('IdProjectBafUpdate', programData.projectId)
          }
          this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
          this.serviceConfigObservable.emitRefreshInProgress(true)
          this.popupLoading.closeAll();
          if (openNewRdv) {
            this.openPopupCreateNewRdv_CustomCardTableHousingMobile('editRdv', programData, size, spinnerLoad)
            clearInterval(setIntervalProjectId);
          } else {
            clearInterval(setIntervalProjectId);
          }
        }
      }
      console.log(i)
      i++
    }, 1000)
  }

  // Open popup create ( new / close / modify ) RDV with name popup and data rdv or not if is new event

  // Create observable for spinner load
  openPopupCreateNewRdv_CustomCardTableHousingMobile(namePopup: string, programData: PeriodictElement | ProjectTableHousing, size: SCREEN_SIZE, spinnerLoad: boolean, type?: string) {
    if (namePopup !== 'ruleRdv') {
      if (programData.projectId !== undefined) {
        try {

          // max-width: 150vw;
          // width: 100%;
          // height: auto;
          // position: static;

          let maxWidth = '150vw';
          let width = '100%';
          let height = 'auto';

          let minHeight = 'auto';
          // let maxWidth = '80vw';
          // let width = '689px';
          // let height = '880px';
          let panelClass = 'customDialogNewFormRdv';
          if (size == 2) {
            panelClass = 'customDialogNewFormRdvMD';
          }


          if (size < 2) {
            minHeight = 'auto';
            maxWidth = '95vw';
            width = 'auto';
            height = 'auto';
            panelClass = 'customDialogNewFormRdvSM';
          }

          if (namePopup == 'editRdvWithReservation') {
            let newRdvData: any = programData.event2;
            newRdvData.reservationId = programData.reservationId;
            newRdvData.typeRdv = type

            const dialogRef = this.popupFormRdv.open(PopupFormRdvComponent, {
              width: width,
              height: height,
              maxWidth: maxWidth,
              minHeight: minHeight,
              data: { size: size, namePopup: namePopup, rdv: newRdvData },
              disableClose: true,
              panelClass: panelClass
            });

            // If dialog is close and if return data edit or modify rdv
            dialogRef.afterClosed().subscribe((result: any) => {
              console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
              if (result !== undefined && result !== "") {
                // Open dialog popup loading
                this.openDialogLoading("createNewRdv", size, "");

                // Creation data for post api for create event rdv
                let dataPost: any;
                dataPost =
                {
                  "if_rdvtype": result.rdv.category.id !== undefined ? result.rdv.category.id : result.rdv.category,
                  "scheduledstart": result.rdv.start.toISOString(),
                  "scheduledend": result.rdv.end.toISOString(),
                  // "scheduledstart": new Date(result.rdv.start.getTime() - result.rdv.start.getTimezoneOffset() * 60000).toISOString(),
                  // "scheduledend": new Date(result.rdv.end.getTime() - result.rdv.end.getTimezoneOffset() * 60000).toISOString(),
                  "if_modalities": result.rdv.modality.id,
                  "if_appointmenttypesid": result.rdv.category.guid,
                  "if_availability": "750010002",
                  "regardingobjectid": {
                    "EntityName": newRdvData.typeRdv == 'R4' ? "if_reservation" : "if_project",
                    // "if_availability": result.rdv.availability,
                    "id": newRdvData.typeRdv == 'R4' ? newRdvData.reservationId : result.rdv.project.id
                  },

                }

                if (result.rdv.subject !== "") {
                  dataPost["if_subjectcomplement"] = result.rdv.subject;

                }
                if (result.rdv.comment !== "") {
                  dataPost["description"] = result.rdv.comment;
                }
                if (result.rdv.internalMemo !== "") {
                  dataPost["if_privatecomments"] = result.rdv.internalMemo;
                }
                if (result.rdv.location !== null && result.rdv.location !== "") {
                  dataPost["if_location"] = result.rdv.location.id;
                  if (result.rdv.location.id === 750010002) {
                    dataPost["AdditionnalFields"] = [];
                    if (result.rdv.addressOne !== undefined) {
                      if (result.rdv.addressOne !== "") {
                        dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                      }
                    }
                    if (result.rdv.addressTwo !== undefined) {
                      if (result.rdv.addressTwo !== "") {
                        dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                      }
                    }
                    if (result.rdv.zipCode !== undefined) {
                      if (result.rdv.zipCode !== "") {
                        dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                      }
                    }
                    if (result.rdv.city !== undefined) {
                      if (result.rdv.city !== null) {
                        dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                      }
                    }
                  }
                }
                this.serviceActivityApi.postAppointment(dataPost).pipe(first()).subscribe((data: any) => {
                  console.log(data)
                  this.openDialogLoading('synchronizedBafAppointment', size, "");
                  this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
                  this.getBafSynchronized(data, false, namePopup, programData, size, spinnerLoad);
                  // this.popupLoading.closeAll();
                }, (error) => {
                  this.snackbar.openSnackBar(
                    this.serviceErrorMessage.errorMessagePostAppointment, ["custom-style-error"]
                  );
                  this.popupLoading.closeAll()
                  this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
                });
              } else {
                this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
              }
            });

          } else {

            if (namePopup === 'editRdvWithInProgress') {
              let newRdvData: any = programData
              newRdvData.typeRdv = type
              newRdvData.projectId = programData.projectId;

              const dialogRef = this.popupFormRdv.open(PopupFormRdvComponent, {
                width: width,
                height: height,
                maxWidth: maxWidth,
                minHeight: minHeight,
                data: { size: size, namePopup: namePopup, rdv: newRdvData },
                disableClose: true,
                panelClass: panelClass
              });

              // If dialog is close and if return data edit or modify rdv
              dialogRef.afterClosed().subscribe((result: any) => {
                console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
                this.methodAfterClosePopupRdv(result, namePopup, size, spinnerLoad, newRdvData, programData.event2, programData)
              });
            } else {
              this.serviceProject.getProjectWithId(programData.projectId).pipe(first()).subscribe((project: any) => {
                let newRdvData = project

                newRdvData.projectId = programData.projectId;

                const dialogRef = this.popupFormRdv.open(PopupFormRdvComponent, {
                  width: width,
                  height: height,
                  maxWidth: maxWidth,
                  minHeight: minHeight,
                  data: { size: size, namePopup: namePopup, rdv: newRdvData },
                  disableClose: true,
                  panelClass: panelClass
                });

                // If dialog is close and if return data edit or modify rdv
                dialogRef.afterClosed().subscribe((result: any) => {
                  console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
                  this.methodAfterClosePopupRdv(result, namePopup, size, spinnerLoad, newRdvData, project, programData)
                });
              });
            }
          }
        } catch (e) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageGetProjectWithId, ["custom-style-error"]
          );
          this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
        }
      } else {
        this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
      }
    } else {
      this.configObservableSendSpinnerLoadButton.next(spinnerLoad = false);
      let id: any = programData

      let minHeight = 'auto';
      let maxWidth = '80vw';
      let width = '689px';
      let height = '880px';
      let panelClass = 'customDialogNewFormRdv';

      if (size < 2) {
        minHeight = 'auto';
        maxWidth = '95vw';
        width = 'auto';
        height = 'auto';
        panelClass = 'customDialogNewFormRdvSM';
      }

      this.serviceActivityApi.getAppointmentId(id).pipe(first()).subscribe((data: any) => {
        console.log(data)
        let newData = { rdvRule: data }
        const dialogRef = this.popupFormRdv.open(PopupFormRdvComponent, {
          width: width,
          height: height,
          maxWidth: maxWidth,
          minHeight: minHeight,
          data: { size: size, namePopup: namePopup, rdv: newData },
          disableClose: true,
          panelClass: panelClass
        });

        dialogRef.afterClosed().subscribe((result: any) => {
          console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
          if (result !== undefined) {
            if (result !== "") {
              this.openDialogLoading('synchronizedBafAppointment', size, "");
              let periodicityObject = new PeriodictElement();
              periodicityObject.projectId = data['project.if_projectid'];
              this.getBafSynchronizedCloseAppointment(result, false, periodicityObject, size, false);
              // if (programData.projectId !== undefined) {
              //   localStorage.setItem('IdProjectBafUpdate' , programData.projectId)
              // }
              // this.serviceConfigObservable.emitRefreshInProgress(true);
              // this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
              // this.popupLoading.closeAll();
            }
          }
          this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
        });
      })
    }
  }

  methodAfterClosePopupRdv(result: any, namePopup: string, size: SCREEN_SIZE, spinnerLoad: boolean, newRdvData?: any, project?: any, programData?: any) {
    if (result !== undefined && result !== "") {
      if (namePopup === 'editRdv' || namePopup === 'editRdvWithInProgress') {

        // Open dialog popup loading
        this.openDialogLoading("createNewRdv", size, "");

        // Creation data for post api for create event rdv
        let dataPost: any;
        dataPost =
        {
          "if_rdvtype": result.rdv.category.id !== undefined ? result.rdv.category.id : result.rdv.category,
          "scheduledstart": result.rdv.start.toISOString(),
          "scheduledend": result.rdv.end.toISOString(),
          // "scheduledstart": new Date(result.rdv.start.getTime() - result.rdv.start.getTimezoneOffset() * 60000).toISOString(),
          // "scheduledend": new Date(result.rdv.end.getTime() - result.rdv.end.getTimezoneOffset() * 60000).toISOString(),
          "if_modalities": result.rdv.modality.id,
          "if_appointmenttypesid": result.rdv.category.guid,
          "if_availability": "750010002",
          "regardingobjectid": {
            "EntityName": newRdvData.typeRdv == 'R4' ? "if_reservation" : "if_project",
            // "if_availability": result.rdv.availability,
            "id": newRdvData.typeRdv == 'R4' ? newRdvData.reservationId : result.rdv.project.id
          },

        }

        if (result.rdv.subject !== "") {
          dataPost["if_subjectcomplement"] = result.rdv.subject;

        }
        if (result.rdv.comment !== "") {
          dataPost["description"] = result.rdv.comment;
        }
        if (result.rdv.internalMemo !== "") {
          dataPost["if_privatecomments"] = result.rdv.internalMemo;
        }
        if (result.rdv.location !== null && result.rdv.location !== "") {
          dataPost["if_location"] = result.rdv.location.id;
          if (result.rdv.location.id === 750010002) {
            dataPost["AdditionnalFields"] = [];
            if (result.rdv.addressOne !== undefined) {
              if (result.rdv.addressOne !== "") {
                dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
              }
            }
            if (result.rdv.addressTwo !== undefined) {
              if (result.rdv.addressTwo !== "") {
                dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
              }
            }
            if (result.rdv.zipCode !== undefined) {
              if (result.rdv.zipCode !== "") {
                dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
              }
            }
            if (result.rdv.city !== undefined) {
              if (result.rdv.city !== null) {
                dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
              }
            }
          }
        }
        this.serviceActivityApi.postAppointment(dataPost).pipe(first()).subscribe((data: any) => {
          console.log(data)
          this.openDialogLoading('synchronizedBafAppointment', size, "");
          this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
          this.getBafSynchronized(data, false, namePopup, programData, size, spinnerLoad);
          // this.popupLoading.closeAll();
        }, (error) => {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessagePostAppointment, ["custom-style-error"]
          );
          this.popupLoading.closeAll()
          this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
        });
      } else if (namePopup === 'modifyRdv') {

        // Open dialog popup loading
        this.openDialogLoading("createModifyRdv", size, "");

        // Creation data for put api for put event rdv
        let dataPost: any;
        dataPost = {};

        if ((result.rdv.start).toString() !== new Date(convertTZ(project['appointment.scheduledstart'])).toString()) {
          dataPost["scheduledstart"] = result.rdv.start.toISOString();
          // dataPost["scheduledstart"] = new Date(result.rdv.start.getTime() - result.rdv.start.getTimezoneOffset() * 60000).toISOString();
        }

        if ((result.rdv.end).toString() !== new Date(convertTZ(project['appointment.scheduledend'])).toString()) {
          dataPost["scheduledend"] = result.rdv.end.toISOString();
          // dataPost["scheduledend"] = new Date(result.rdv.end.getTime() - result.rdv.end.getTimezoneOffset() * 60000).toISOString();
        }

        if (result.rdv.subject !== project['appointment.if_modalities']) {
          dataPost["if_modalities"] = result.rdv.modality.id;
        }

        if (result.rdv.subject !== project['appointment.if_subjectcomplement']) {
          dataPost["if_subjectcomplement"] = result.rdv.subject;
        }

        if (result.rdv.comment !== project['appointment.description']) {
          dataPost["description"] = result.rdv.comment;
        }
        if (result.rdv.internalMemo !== project['appointment.if_privatecomments']) {
          dataPost["if_privatecomments"] = result.rdv.internalMemo;
        }
        if (result.rdv.location !== null && result.rdv.location !== "") {
          if (project['appointment.if_location'] !== undefined) {
            if (result.rdv.location !== project['appointment.if_location']) {
              dataPost["if_location"] = result.rdv.location.id;
              if (result.rdv.location.id === 750010002) {
                dataPost["AdditionnalFields"] = [];
                if (project['appointment.if_address_street1'] !== undefined) {
                  if (result.rdv.addressOne !== project['appointment.if_address_street1']) {
                    if (result.rdv.addressOne !== undefined) {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                    } else {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": "" })
                    }
                  }
                } else {
                  if (result.rdv.addressOne !== undefined) {
                    if (result.rdv.addressOne !== "") {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                    }
                  }
                }
                if (project['appointment.if_address_street2'] !== undefined) {
                  if (result.rdv.addressTwo !== project['appointment.if_address_street2']) {
                    if (result.rdv.addressTwo !== undefined) {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                    } else {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": "" })
                    }
                  }
                } else {
                  if (result.rdv.addressTwo !== undefined) {
                    if (result.rdv.addressTwo !== "") {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                    }
                  }
                }
                if (project['appointment.if_address_postalcode'] !== undefined) {
                  if (result.rdv.zipCode !== project['appointment.if_address_postalcode']) {
                    if (result.rdv.zipCode !== undefined) {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                    } else {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": "" })
                    }
                  }
                } else {
                  if (result.rdv.zipCode !== undefined) {
                    if (result.rdv.zipCode !== "") {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                    }
                  }
                }
                if (project['appointment.if_address_cityid'] !== undefined) {
                  if (result.rdv.city !== project['appointment.if_address_cityid']) {
                    if (result.rdv.city !== undefined) {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                    } else {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": "" })
                    }
                  }
                } else {
                  if (result.rdv.city !== undefined) {
                    if (result.rdv.city !== null) {
                      dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                    }
                  }
                }
              }
            }
          } else {
            dataPost["if_location"] = result.rdv.location.id;
            if (result.rdv.location.id === 750010002) {
              dataPost["AdditionnalFields"] = [];
              if (result.rdv.addressOne !== undefined) {
                if (result.rdv.addressOne !== "") {
                  dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                }
              }
              if (result.rdv.addressTwo !== undefined) {
                if (result.rdv.addressTwo !== "") {
                  dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                }
              }
              if (result.rdv.zipCode !== undefined) {
                if (result.rdv.zipCode !== "") {
                  dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                }
              }
              if (result.rdv.city !== undefined) {
                if (result.rdv.city !== null) {
                  dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                }
              }
            }
          }
        }
        try {
          this.serviceActivityApi.updateAppointmentId(result.rdv.id, dataPost).pipe(first()).subscribe((data: any) => {
            console.log(data)
            if (programData.projectId !== undefined) {
              localStorage.setItem('IdProjectBafUpdate', programData.projectId)
            }
            this.serviceConfigObservable.emitRefreshInProgress(true);
            this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
            this.popupLoading.closeAll();
            this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
          })
        } catch (e) {
          this.snackbar.openSnackBar(
            this.serviceErrorMessage.errorMessageUpdateAppointmentId, ["custom-style-error"]
          );
          this.popupLoading.closeAll()
          this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
        }
      }
    } else {
      this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
    }
  }

  openPopupUpdateRdv_CustomService(namePopup: string, size: SCREEN_SIZE, spinnerLoad: boolean, idRdv: string, dataPanelClass: { normal: string, sizeEgalTwo: string, sizeInfTwo: string }, DragAndDrop: any) {
    try {

      let maxWidth = '150vw';
      let width = '100%';
      let height = 'auto';

      let minHeight = 'auto';
      // let maxWidth = '80vw';
      // let width = '689px';
      // let height = '880px';
      let panelClass = dataPanelClass.normal;
      if (size == 2) {
        panelClass = dataPanelClass.sizeEgalTwo;
      }

      if (size < 2) {
        minHeight = 'auto';
        maxWidth = '95vw';
        width = 'auto';
        height = 'auto';
        panelClass = dataPanelClass.sizeInfTwo;
      }

      this.serviceActivityApi.getAppointmentId(idRdv).pipe(first()).subscribe((rdv: AppointmentId) => {
        let newRdvData: any = rdv
        if (DragAndDrop.activDragAndDrop) {
          newRdvData.activDragAndDrop = DragAndDrop.activDragAndDrop
          newRdvData.newStart = DragAndDrop.newStart
          newRdvData.newEnd = DragAndDrop.newEnd
        }

        const dialogRef = this.popupFormRdv.open(PopupFormRdvComponent, {
          width: width,
          height: height,
          maxWidth: maxWidth,
          minHeight: minHeight,
          data: { size: size, namePopup: namePopup, rdv: newRdvData },
          disableClose: true,
          panelClass: panelClass
        });

        // If dialog is close and if return data edit or modify rdv
        dialogRef.afterClosed().subscribe((result: any) => {
          console.log(`Close dialog popup form rdv ${namePopup}, Result : ${result}`);
          this.serviceConfigObservable.emitSendDialogRefClose(0);
          if (result !== undefined && result !== "") {
            if (result.rdv !== undefined) {
              if (namePopup === 'modifyRdv') {
                // Open dialog popup loading
                this.openDialogLoading("createModifyRdv", size, "");

                // Creation data for put api for put event rdv
                let dataPost: any;
                dataPost = {};

                if ((result.rdv.start).toString() !== new Date(convertTZ(rdv['appointment.scheduledstart'])).toString()) {
                  dataPost["scheduledstart"] = result.rdv.start.toISOString();
                  // dataPost["scheduledstart"] = new Date(result.rdv.start.getTime() - result.rdv.start.getTimezoneOffset() * 60000).toISOString();
                }

                if ((result.rdv.end).toString() !== new Date(convertTZ(rdv['appointment.scheduledend'])).toString()) {
                  dataPost["scheduledend"] = result.rdv.end.toISOString();
                  // dataPost["scheduledend"] = new Date(result.rdv.end.getTime() - result.rdv.end.getTimezoneOffset() * 60000).toISOString();
                }

                if (result.rdv.modality.id !== rdv['appointment.if_modalities']) {
                  dataPost["if_modalities"] = result.rdv.modality.id;
                }

                if (result.rdv.subject !== rdv['appointment.if_subjectcomplement']) {
                  dataPost["if_subjectcomplement"] = result.rdv.subject;
                }

                if (result.rdv.comment !== rdv['appointment.description']) {
                  dataPost["description"] = result.rdv.comment;
                }
                if (result.rdv.internalMemo !== rdv['appointment.if_privatecomments']) {
                  dataPost["if_privatecomments"] = result.rdv.internalMemo;
                }

                if (result.rdv.location !== null && result.rdv.location !== "") {
                  if (rdv['appointment.if_location'] !== undefined) {
                    if (result.rdv.location !== rdv['appointment.if_location']) {
                      dataPost["if_location"] = result.rdv.location.id;
                      if (result.rdv.location.id === 750010002) {
                        dataPost["AdditionnalFields"] = [];
                        if (rdv['appointment.if_address_street1'] !== undefined) {
                          if (result.rdv.addressOne !== rdv['appointment.if_address_street1']) {
                            if (result.rdv.addressOne !== undefined) {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                            } else {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": "" })
                            }
                          }
                        } else {
                          if (result.rdv.addressOne !== undefined) {
                            if (result.rdv.addressOne !== "") {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                            }
                          }
                        }
                        if (rdv['appointment.if_address_street2'] !== undefined) {
                          if (result.rdv.addressTwo !== rdv['appointment.if_address_street2']) {
                            if (result.rdv.addressTwo !== undefined) {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                            } else {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": "" })
                            }
                          }
                        } else {
                          if (result.rdv.addressTwo !== undefined) {
                            if (result.rdv.addressTwo !== "") {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                            }
                          }
                        }
                        if (rdv['appointment.if_address_postalcode'] !== undefined) {
                          if (result.rdv.zipCode !== rdv['appointment.if_address_postalcode']) {
                            if (result.rdv.zipCode !== undefined) {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                            } else {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": "" })
                            }
                          }
                        } else {
                          if (result.rdv.zipCode !== undefined) {
                            if (result.rdv.zipCode !== "") {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                            }
                          }
                        }
                        if (rdv['appointment.if_address_cityid'] !== undefined) {
                          if (result.rdv.city !== rdv['appointment.if_address_cityid']) {
                            if (result.rdv.city !== undefined) {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                            } else {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": "" })
                            }
                          }
                        } else {
                          if (result.rdv.city !== undefined) {
                            if (result.rdv.city !== null) {
                              dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                            }
                          }
                        }
                      }
                    }
                  } else {
                    dataPost["if_location"] = result.rdv.location.id;
                    if (result.rdv.location.id === 750010002) {
                      dataPost["AdditionnalFields"] = [];
                      if (result.rdv.addressOne !== undefined) {
                        if (result.rdv.addressOne !== "") {
                          dataPost["AdditionnalFields"].push({ "Key": "if_address_street1", "Value": result.rdv.addressOne })
                        }
                      }
                      if (result.rdv.addressTwo !== undefined) {
                        if (result.rdv.addressTwo !== "") {
                          dataPost["AdditionnalFields"].push({ "Key": "if_address_street2", "Value": result.rdv.addressTwo })
                        }
                      }
                      if (result.rdv.zipCode !== undefined) {
                        if (result.rdv.zipCode !== "") {
                          dataPost["AdditionnalFields"].push({ "Key": "if_address_postalcode", "Value": result.rdv.zipCode })
                        }
                      }
                      if (result.rdv.city !== undefined) {
                        if (result.rdv.city !== null) {
                          dataPost["AdditionnalFields"].push({ "Key": "if_address_cityid", "Value": result.rdv.city })
                        }
                      }
                    }
                  }
                }
                try {
                  this.serviceActivityApi.updateAppointmentId(result.rdv.id, dataPost).pipe(first()).subscribe((data: any) => {
                    console.log(data)
                    this.serviceConfigObservable.emitRefreshInProgress(true);
                    this.serviceConfigObservable.emitRefreshAppointmentUpdate(true);
                    this.popupLoading.closeAll();
                    this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
                  })
                } catch (e) {
                  this.snackbar.openSnackBar(
                    this.serviceErrorMessage.errorMessageUpdateAppointmentId, ["custom-style-error"]
                  );
                  this.popupLoading.closeAll()
                  this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
                }
              }
            } else {
              this.openDialogLoading('synchronizedBafAppointment', size, "");
              let periodicityObject = new PeriodictElement();
              periodicityObject.projectId = rdv['project.if_projectid'];
              this.getBafSynchronizedCloseAppointment(result, false, periodicityObject, size, false)
            }
          } else {
            this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
          }
        });
      });
    } catch (e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageGetProjectWithId, ["custom-style-error"]
      );
      this.configObservableSendSpinnerLoadButton.next(spinnerLoad = true);
    }

  }

  // openPopupPostOption_CustomService()
  // Open popup post option next to name page ( send or get )
  openPopupPostOption_CustomService(programData: PeriodictElement, namePageActive: string, nameTableProject: string, size: SCREEN_SIZE, namePopup?: string) {
    if (namePageActive === 'stock') {
      // this.newPostOptionEvent.emit(programData.dataDialog);
      console.log(namePopup)
      this.serviceOperation.descriptionOperationActive = true;
      this.serviceConfigObservable.emitSendActiveDescription(true);
      this.popupSearch.closeAll()
      let width = '689px';
      let height = '492px';

      const dialogRef = this.popupSearch.open(PopupSearchSaveComponent, {
        width: width,
        height: height,
        data: { namePopup: 'postOptionLot', dataPopup: programData.dataDialog, size: size },
        disableClose: true
      });

      dialogRef.afterOpened().subscribe(result => {
        console.log(dialogRef);
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Close dialog popup form post option ${namePopup}, Result : ${result}`);
        this.popupSearch.closeAll()
        this.serviceOperation.descriptionOperationActive = false;
        this.serviceConfigObservable.emitSendActiveDescription(false);
      });
    } else if (namePageActive === 'project') {
      this.configObservableNewPostOptionEvent.next(programData);
    } else if (namePageActive === 'sale') {
      if (nameTableProject === 'option') {
        if (namePopup === 'cancelOption') {
          this.serviceTableHousing.namePopupForMatDialog = namePopup;
          this.configObservableNewPostCancelOptionEvent.next(programData);
        } else {
          // this.newPostOptionEvent.emit(programData);
        }
      }
    }
  }


  openActionValidationPopup(modalTitle: string, modalBody: string, closeBtnLabel: string, actionBtnLabel: string, withCloseBtn: boolean, withValidateBtn: boolean) {
    var modalDatas: ModalDatas = {
      size: 4,
      withCloseBtn: withCloseBtn,
      withValidateBtn: withValidateBtn,

      modalTitle: modalTitle,
      modalBody: modalBody,
      closeBtnLabel: closeBtnLabel,
      validateBtnLabel: actionBtnLabel,
      modalDatas: null
    }

    return this.modalPopup.open(ModalComponent, {
      width: '672px',
      height: '565px',
      data: modalDatas,
      panelClass: 'customModalPopup',
      disableClose: true
    });
  }


  openPopupBreakingNews(objectReceivePopup: any) {
    const dialogRef = this.popupBreakingNews.open(PopupBreakingNewsComponent, {
      // width: '100%',
      height: 'auto',
      maxWidth: "150vw",
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result) {
        if (result.annulation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.popupBreakingNews.closeAll();
        }
        if (result.validation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          setTimeout(() => {
            this.popupBreakingNews.closeAll();  
          }, 1500)
        }
      }
    });
  }

  openPopupAcceptedRule(objectReceivePopup: any) {
    const dialogRef = this.popupAcceptedRule.open(PopupAcceptedRuleComponent, {
      // width: '100%',
      height: 'auto',
      maxWidth: "150vw",
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForPopupAcceptedRule2' : 'styleForPopupAcceptedRule',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result) {
        if (result.annulation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.popupAcceptedRule.closeAll();
        }
        if (result.validation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.serviceConfigObservable.emitPutDetachedContactSecond(true);  
        }
      }
    });
  }


  openPopupErrorDescriptionOperation(objectReceivePopup: any) {
    const dialogRef = this.popupErrorDescriptionOperation.open(PopupErrorDescriptionOperationComponent, {
      // width: '100%',
      height: 'auto',
      maxWidth: "150vw",
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result) {
        if (result.annulation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.popupErrorDescriptionOperation.closeAll();
        }
        if (result.validation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          setTimeout(() => {
            this.popupErrorDescriptionOperation.closeAll();  
          }, 1500)
        }
      }
    });
  }

  openPopupSalesOffers(objectReceivePopup: any) {
    const dialogRef = this.popupSalesOffers.open(PopupSalesOffersComponent, {
      // width: '100%',
      height: 'auto',
      maxWidth: "150vw",
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result) {
        if (result.annulation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.popupSalesOffers.closeAll();
        }
        if (result.validation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          setTimeout(() => {
            this.popupSalesOffers.closeAll();  
          }, 1500)
        }
      }
    });
  }

  openPopupPostDenunciation(PeriodictElement: any ,size: SCREEN_SIZE) {
    let objectReceivePopup = {
      size: size,
      namePopup: "postDenunciation",
      title: "Dénonciation de contact",
      dataPopup: PeriodictElement,
      backgroundColorContent: '#303a44',
      colorContent: '#ffffff'
    }
    
    const dialogRef = this.popupPostDenunciation.open(this.myComponents[1], {
      // width: '100%',
      height: 'auto',
      maxWidth: "150vw",
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result) {
        if (result.annulation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.popupPostDenunciation.closeAll();
        }
        if (result.validation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          setTimeout(() => {
            this.popupPostDenunciation.closeAll();  
          }, 1500)
        }
      }
    });
  }

  openPopupPostOptionDenunciation(objectReceivePopup: any) {
    const dialogRef = this.popupPostOptionDenunciation.open(this.myComponents[0], {
      // width: '100%',
      height: 'auto',
      maxWidth: "150vw",
      data: objectReceivePopup,
      panelClass: objectReceivePopup.size < 2 ? 'styleForCustomPopup2' : 'styleForCustomPopup',
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Close dialog popup form rdv ${objectReceivePopup.namePopup}, Result : ${result}`);
      if (result) {
        if (result.annulation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          this.popupPostOptionDenunciation.closeAll();
        }
        if (result.validation) {
          console.log(`Close dialog popup form ${objectReceivePopup.namePopup}, Result : ${result}`);
          setTimeout(() => {
            this.popupPostOptionDenunciation.closeAll();  
          }, 1500)
        }
      }
    });
  }

  openPopupCardLot_PostOption(namePopup: string, dataPopup: any, size: SCREEN_SIZE, width: string, height: string, maxWidth: string, minHeight: string, panelClass: string, activationMenu: string ) {
    
    if (activationMenu === 'stock') {
      let dialogRef = this.popupPostLot.open(this.myComponents[0], {
        width: width,
        height: height,
        maxWidth: maxWidth,
        minHeight: minHeight,
        data: { namePopup: namePopup, dataPopup: dataPopup, size: size },
        disableClose: true,
        panelClass: panelClass
      });
  
      dialogRef.afterOpened().subscribe((result: any) => {
        console.log(dialogRef);
      })
  
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(`Dialog result: ${result}`);
        this.popupPostLot.closeAll()
        this.serviceOperation.descriptionOperationActive = false;
        
      });
    } else if (activationMenu === 'store') {
      this.serviceOperation.descriptionOperationActive = true;
      this.serviceConfigObservable.emitSendActiveDescription(true);
      this.popupSearch.closeAll()
      let dialogRef: any = this.popupSearch.open(this.myComponents[1], {
        width: width,
        height: height,
        data: { namePopup: namePopup, dataPopup: dataPopup, size: size },
        disableClose: true
      });
      
      dialogRef.afterOpened().subscribe((result: any) => {
        console.log(dialogRef);
      })
  
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(`Close dialog popup form post option ${namePopup}, Result : ${result}`);
        this.popupSearch.closeAll()
        this.serviceOperation.descriptionOperationActive = false;
        this.serviceConfigObservable.emitSendActiveDescription(false);
      });
    }


  }


  openPopupCustomerArea( projectId: string, entityName:string, size: SCREEN_SIZE, projectData:any) {

      let dialogRef = this.popupPostLot.open(PopupCustomerAreaComponent, {
        height: '100%',
        width: '50%',
        data: { projectId: projectId,entityName:entityName, size: size, projectData:projectData },
        disableClose: true,
        panelClass: "customModalPopup"
      });
  
      dialogRef.afterOpened().subscribe((result: any) => {
        console.log(dialogRef);
      })
  
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log(`Dialog result: ${result}`);
        this.popupPostLot.closeAll()
        this.serviceOperation.descriptionOperationActive = false;
        
      });
    


  }





}
