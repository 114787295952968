import { ITimelineItem } from 'src/app/shared/models/TableHousingMobile/TimelineItem'
import { Favorite } from './favorite';

export class PeriodictElement {
    // Interface PeriodicElement with all field ( field lot for research and field lot for a program )
    public "Résidence"?: string;
    public "Lot"?: string;
    public "id_operation"?: string;
    public "modeCreationOptionDate"?: boolean;
    public "modeCreationOption"?: boolean;
    public "productWin"?: Array<any>;
    public "favorite"?: Favorite;
    public "activButtonErip"?: Boolean;
    public "civility"?: string;
    public "eripId"?: string;
    public "activeComparation"?: boolean;
    public "activeCoupCoeur"?: boolean;
    public "AssetId"?: string;
    public "dateProgressionCode"?: string;
    public "hoursProgressionCode"?: string;
    public "MODALITÉ"?: string;
    public "TEL MOBILE"?: string;
    public "priceAssetTTC"?: string;
    public "priceAssetHT"?: string;
    public "priceLoyerHT"?: string;
    public "priceLoyerTTC"?: string;
    public "pricingTotalHT"?: string;
    public "pricingTotalTTC"?: string;
    public "pricingLogementTTC"?: string;
    public "pricingLogementHT"?: string;
    public "pricingMobilierTTC"?: string;
    public "pricingMobilierHT"?: string;
    public "pricingLotsSecondairesTTC"?: string;
    public "pricingLotsSecondairesHT"?: string;
    public "urlDocuSign"?: string;
    public "idDocuSign"?: string;
    public "statusCodeDocuSign"?: string;
    public "Date d'opération"?: string;
    public "dataPlacement"?: any;
    public "operationPlacementId"?: string;
    public "arrayOperationPlacement"?: Array<any>;
    public "btnShowDocuSign"?: boolean;
    public "btnShowResa"?: boolean;
    public "SOLUTION"?: string;
    public "N° CONTRAT"?: string;
    public "PRODUIT"?: string;
    public "DATE SIGN."?: string;
    public "DATE RETRACT."?: string;
    public "DATE VALID"?: string;
    public "DATE RAPPEL"?: string;
    public "DATE DISPO"?: string;
    public "COLLECTE"?: string;
    public "Opération"?: string;
    public "Conseiller"?: string;
    public "Date de début"?: string;
    public "Date de fin"?: string;
    public "Montant"?: string;
    public "Périodicité"?: string;
    public "% Co."?: string;
    public "Cumul"?: string;
    public "page"?: string;
    public "projectId"?: string;
    public "propositionId"?: string;
    public "typeRdv"?: string;
    public "reservationId"?: string;
    public "rdv"?: any;
    public "phase"?: string;
    public "primoAccedant"?: string;
    public "researchDepartment"?: string;
    public "horizonInvestisment"?: string;
    public "disponibilityEpargne"?: string;
    public "closeProject"?: boolean;
    public "monthRent"?: string;
    public "statecode"?: number;
    public "statecodeR1"?: boolean;
    public "statecodeR2"?: boolean;
    public "statecodeR3"?: boolean;
    public "dataDialog"?: any;
    public "AllProgram"?: any;
    public "addressOne"?: string;
    public "addressTwo"?: string;
    public "PROGRAMME"?: string;
    public "LOT"?: string;
    public "TYPO"?: string;
    public "Surf."?: string;
    public "SURF."?: string;
    public "Etage"?: string;
    public "STATUT"?: string;
    public "RANG"?: string;
    public "TYPE"?: string;
    public "typeImg"?: Array<any>;
    public "activeImmovable"?: boolean;
    public "activeFinancial"?: boolean;
    public "SHAB"?: string;
    public "ETAGE"?: string;
    public "ORIENT."?: string;
    public "ORIENTATION"?: string;
    public "LOYER"?: string;
    public "PRIX TTC"?: string;
    public "PRIX HT"?: string;
    public "PARKING"?: string;
    public 'CODE'?: number;
    public "CLIENT"?: string;
    public "ID"?: string;
    public "AVANCEMENT"?: string;
    public "ORIGINE"?: string;
    public "originId"?: string;
    public "DATE CREA"?: string;
    public "DATE R3"?: string;
    public "DATE FERMETURE"?: string;
    public "BUDGET IMMO"?: string;
    public "BUDGET PLACEMENT"?: string;
    public "CODE CONCLUSION"?: string;
    public "commentConclusion"?: string;
    public "VILLE"?: string;
    public "TEL"?: string;
    public "MAIL"?: string;
    public "DER"?: string;
    public "showBtnDer"?: boolean;
    public "typeImgDer"?: Array<any>;
    public "IsCrm": boolean;
    public "imgOrigine"?: Array<any>;
    public "BUDGET MAX"?: string;
    public "DATE OPT."?: string;
    public "PROMOTEUR"?: string;
    public "PRIX"?: string;
    public "FISCALITE"?: string;
    public "PROJET"?: string;
    public "DATE FIN OPT."?: string;
    public "N° LOT"?: string;
    public "TEL MOB"?: string;
    public "FISCALITÉ"?: string;
    public "DATE OPTION"?: string;
    public "DATE PRÉ-RÉSA"?: string;
    public "DATE RÉSA"?: string;
    public "FINANCEMENT"?: string;
    public "ACTABLE"?: string;
    public "DATE ACTE"?: string;
    public "DATE PREV ACTE"?: string;
    public "DATE LIVRAISON"?: string;
    public "PRODUIT FI"?: string;
    public "DATE SOUSCRIPTION"?: string;
    public "MONTANT INITIAL"?: string;
    public "MONTANT VP"?: string;
    public "MONTANT VI"?: string;
    public "PÉRIODICITÉ"?: string;
    public "MONTANT CUMULÉ"?: string;
    public "DATE RÉTRACT."?: string;
    public "placement"?: any;
    public "OPÉRATION"?: string;
    public "Reservation"?: Array<any>;
    public "MONTANT"?: string;
    public "FRAIS"?: string;
    public "Statut"?: string;
    public "RENTA"?: string;
    public "PINEL"?: boolean;
    public "avancement"?: string;
    public "stateAvancement"?: string;
    public "activity"?: string;
    public "stateActivity"?: number;
    public "Rang"?: string;
    public "parking"?: Array<string>;
    public "if_name"?: string;
    public "terrace"?: string;
    public "Propositions"?: Array<any>;
    public "dataRecall"?: any;
    public "nrp"?: any;
    public "startOptionData"?: any;
    public "startOption"?: string;
    public "endOption"?: string;
    public "dateCreatded"?: string;
    public "dateCreatdedTime"?: string;
    public "address"?: string;
    public "zipCode"?: string;
    public "tel1"?: string;
    public "tel2"?: string;
    public "secondClient"?: string;
    public "secondAddress"?: string;
    public "secondAddressTwo"?: string;
    public "secondZipCode"?: string;
    public "secondCity"?: string;
    public "secondTel1"?: string; 
    public "secondTel2"?: string; 
    public "secondMail"?: string;
    public "situation"?: string;
    public "goal"?: string;
    public "budget"?: string;
    public "budgetTTC"?: string;
    public "createdBy"?: string;
    public "NOM"?: string;
    public "DATE"?: string;
    public "CONCERNANT"?: string;
    public "RDV"?: string;
    public "r1"?: any;
    public "r2"?: any;
    public "r3"?: any;
    public "comment"?: string;
    public "taxSystem"?: string; 
    public "annexSurface"?: string;
    public "surfacePondere"?: string;
    public "profession"?: string;
    public "employer"?: string;
    public "horizon"?: string;
    public "renta"?: string;
    public "device"?: string;
    public "program"?: string;
    public "surfMin"?: string;
    public "budgetMin"?: string;
    public "budgetMax"?: string;
    public "firstname"?: string;
    public "lastname"?: string;
    public "zone"?: string;
    public "balcony"?: string;
    public "DAACT"?: string;
    public "selected"?: string;
    public "other"?: string;  
    public "actability"?: string;
    public "dateActability"?: string; 
    public "delivery"?: string;
    public "image"?: string;
    public "typeText"?: string;
    public "rent"?: string;
    public "dateSignClient"?: string;
    public "signPromoter"?: string;
    public "datePrevActe"?: string;
    public "financialStatut"?: string;
    public "title"?: string;
    public "showButtonSusbcribe"?: boolean;
    public "listRdv"?: Array<any>;
    public "searchSave"?: string;
    public "localization"?: string;
    public "typology"?: Array<string>;
    public "typologyAlone"?: string;
    public "deviceMin"?: string;
    public "deviceMax"?: string;
    public "loyerMin"?: string;
    public "loyerMax"?: string;
    public "productCible"?: Array<string>;
    public "product"?: Array<any>;
    public "estimationProfil"?: string;
    public "pricemin"?: string;
    public "pricemax"?: string;
    public "taxsystem"?: string;
    public "datedelivery"?: string;
    public "SearchId"?: string;
    public "operationIdAlone"?: string;
    public "OperationIds": Array<string>;
    public "PromoteurIds": Array<string>;
    public "AssetTypeCodes": Array<string>;
    public "AssetOrientationCodes": Array<string>;
    public "AssetStageCodes": Array<string>;
    public "OperationTaxLawCodes": Array<string>;
    public "Regions": Array<string>;
    public "Departments": Array<string>;
    public "Cities": Array<string>;
    public "AssetPricesMin": string;
    public "AssetPricesMax": string;
    public "DeliveryDates": Array<any>;
    public "ActabilityDates": Array<any>;
    public "CompletionDates": Array<any>;
    public "event"?: any;
    public "event2"?: any;
    public "icon"?: string;
    public "ERIP":string;
    public "DOC":string;
    public "imgErip":string;
    public "imgDOC":string;
    public "TimelineItems" : Array<ITimelineItem>;
    public "IsUserCanDuplicateConvertProject" : boolean;
    public "if_commercialassignmentid": string;
}
