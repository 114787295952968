import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export interface DialogDataPopup {
  size: number;
  namePopup: string;
  title: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-video-carousel',
  templateUrl: './popup-video-carousel.component.html',
  styleUrls: ['./popup-video-carousel.component.scss']
})

export class PopupVideoCarouselComponent implements OnInit {

  videoUrl:any = "";
  subModuleUse: string;
  isVideoUrlEmpty:boolean = true ;
  currentSlide = 0;

  constructor(public dialogRef: MatDialogRef<PopupVideoCarouselComponent>, 
    @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup,
    private sanitizer : DomSanitizer) {
    this.subModuleUse = environment.subModuleUse;
    this.isVideoUrlEmpty = this.objectReceivePopup.dataPopup.videoCarouselName == null || this.objectReceivePopup.dataPopup.videoCarouselName == undefined || this.objectReceivePopup.dataPopup.videoCarouselName == '' ;
    this.videoUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(this.objectReceivePopup.dataPopup.videoCarouselName);
    }

  ngOnInit(): void {
  }

}
