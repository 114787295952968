import { NameBtnLegalNotice } from './../models/Footer/name-btn-legal-notice';
import { PeriodictElement } from 'src/app/shared/models/periodict-element';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ConfigObservableService {
	
	public configObservableClosePopupCarouselSuiviChantier = new Subject<boolean>()

	emitClosePopupCarouselSuiviChantier(activation: boolean) {
		this.configObservableClosePopupCarouselSuiviChantier.next(activation);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservablePutDetachedContactSecond = new Subject<boolean>()

	emitPutDetachedContactSecond(activation: boolean) {
		this.configObservablePutDetachedContactSecond.next(activation);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/
	
	public configObservableNamePageForPicto = new Subject<string>()

	emitNamePageForPicto(namePageForPicto: string) {
		this.configObservableNamePageForPicto.next(namePageForPicto);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableReloadAuthentificationSSO = new Subject<Boolean>()

	emitReloadAuthentificationSSO(reloadAuthentificationSSO: Boolean) {
		this.configObservableReloadAuthentificationSSO.next(reloadAuthentificationSSO);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableLoginSSO_Token = new Subject<string>()

	emitLoginSSO_Token(loginSSO_Token: string) {
		this.configObservableLoginSSO_Token.next(loginSSO_Token);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/
	
	public configObservableFilterOrderChange = new Subject<any>()

	emitFilterOrderChange(filterOrderChange: any) {
		this.configObservableFilterOrderChange.next(filterOrderChange);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableNameBtnLegalNotice = new Subject<NameBtnLegalNotice>()

	emitNameBtnFooter(nameBtnLegalNotice: NameBtnLegalNotice) {
		this.configObservableNameBtnLegalNotice.next(nameBtnLegalNotice);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendPageActiveForPagination = new Subject<number>()

	emitPageActiveForPagination(pageActiveForPagination: number) {
		this.configObservableSendPageActiveForPagination.next(pageActiveForPagination);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendCustomPaginationSroll = new Subject<boolean>()

	emitCustomPaginationSroll(activationPaginationScroll: boolean) {
		this.configObservableSendCustomPaginationSroll.next(activationPaginationScroll);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableGetNbFavorite = new Subject<boolean>()

	emitGetNbFavorite(getActivation: boolean) {
		this.configObservableGetNbFavorite.next(getActivation);
	}
	
	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendNbComparation = new Subject<number>()

	emitSendNbComparation(nbComparation: number) {
		this.configObservableSendNbComparation.next(nbComparation);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendNbProgram = new Subject<number>();

	emitSendNbProgram(nbProgram: number) {
		this.configObservableSendNbProgram.next(nbProgram);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRoute = new Subject<string>();

	emitSendRoute(route: string) {
		this.configObservableSendRoute.next(route);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRouteHtml = new Subject<string>();

	emitSendRouteHtml(route: string) {
		this.configObservableSendRouteHtml.next(route);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendControlZoomMap = new Subject<Boolean>();

	emitControlZoomMap(dataControlZoomMap: any) {
		this.configObservableSendControlZoomMap.next(dataControlZoomMap);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendInfinityScroll = new Subject<string>();

	emitChoiceInfinityScroll(dataChoice: string) {
		this.configObservableSendInfinityScroll.next(dataChoice);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendHiddenFooter = new Subject<Boolean>();

	emitHiddenOrShowFooter(dataChoice: Boolean) {
		this.configObservableSendHiddenFooter.next(dataChoice);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendClassContainerMap = new Subject<string>();

	emitClassContainerMap(dataChoice: string) {
		this.configObservableSendClassContainerMap.next(dataChoice);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendTabDataOperation = new Subject<any>();

	emitNewPageProgram(tabDataOperation: any) {
		this.configObservableSendTabDataOperation.next(tabDataOperation);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendTabDataAsset = new Subject<any>();

	emitNewPageAsset(tabDataAsset: any) {
		this.configObservableSendTabDataAsset.next(tabDataAsset);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendActiveMethodeCloseProgram = new Subject<Boolean>();

	emitActiveMethodeCloseProgram(activeMethode: any) {
		this.configObservableSendActiveMethodeCloseProgram.next(activeMethode);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendLoadingHomeAssetOrOperation = new Subject<Boolean>();

	emitLoadingHomeAssetOrOperation(activeMethode: any) {
		this.configObservableSendLoadingHomeAssetOrOperation.next(activeMethode);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendLoadResearchSave = new Subject<any>();

	emitLoadResearchSave(loadResearchSave: any) {
		this.configObservableSendLoadResearchSave.next(loadResearchSave);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendLoadSortPlacement = new Subject<any>();

	emitLoadSortPlacement(loadResearchSort: any) {
		this.configObservableSendLoadSortPlacement.next(loadResearchSort);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendLoadSort = new Subject<any>();

	emitLoadSort(loadResearchSort: any) {
		this.configObservableSendLoadSort.next(loadResearchSort);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSearchSaveForm = new Subject<any>();

	emitLoadResearchSaveForm(loadResearchSaveForm: any) {
		this.configObservableSearchSaveForm.next(loadResearchSaveForm);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendEventRdv = new Subject<any>();

	emitEventRdv(eventRdv: any) {
		this.configObservableSendEventRdv.next(eventRdv);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendEventRdvInCalandar = new Subject<any>();

	emitEventRdvInCalandar(eventRdvCalandar: any) {
		this.configObservableSendEventRdvInCalandar.next(eventRdvCalandar);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendEventRdvRuleInCalandar = new Subject<any>();

	emitEventRdvRuleInCalandar(eventRdvCalandar: any) {
		this.configObservableSendEventRdvRuleInCalandar.next(eventRdvCalandar);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/
	
	public configObservableSendRefreshInProgress = new Subject<boolean>();

	emitRefreshInProgress(sendRefresh: any) {
		this.configObservableSendRefreshInProgress.next(sendRefresh);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshSale = new Subject<string>();

	emitRefreshSale(sendNameRefresh: any) {
		this.configObservableSendRefreshSale.next(sendNameRefresh);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshOption = new Subject<boolean>();

	emitRefreshOption(sendRefresh: any) {
		this.configObservableSendRefreshOption.next(sendRefresh);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshStock = new Subject<boolean>();

	emitRefreshStock(sendRefresh: any) {
		this.configObservableSendRefreshStock.next(sendRefresh);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendDescriptionPopup = new Subject<boolean>();
	
	emitSendActiveDescription(sendDescription: any) {
		this.configObservableSendDescriptionPopup.next(sendDescription);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRdvSelect = new Subject<string>();

	emitNewSendRdvSelect(sendProjectId: string) {
		this.configObservableSendRdvSelect.next(sendProjectId);	
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshOperationPlacement = new Subject<boolean>();

	emitRefreshOperationPlacement(dataChoice: boolean) {
		this.configObservableSendRefreshOperationPlacement.next(dataChoice);	
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendOverflowHidden = new Subject<boolean>();

	emitOverflowHidden(dataChoice: boolean) {
		this.configObservableSendOverflowHidden.next(dataChoice);	
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableGoToElementUpdateBaf = new Subject<PeriodictElement>();

	emitGoToElementUpdateBaf(element: PeriodictElement) {
		this.configObservableGoToElementUpdateBaf.next(element);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendResearchProject = new Subject<any>();

	emitResearchProject(dataResearchProject: any) {
		this.configObservableSendResearchProject.next(dataResearchProject);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendResearchSales = new Subject<any>();

	emitResearchSales(dataResearchSale: any) {
		this.configObservableSendResearchSales.next(dataResearchSale);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendResearchAppointmentMultiple = new Subject<any>();

	emitResearchAppointmentMultiple(dataResearchAppointmentMultiple: any) {
		this.configObservableSendResearchAppointmentMultiple.next(dataResearchAppointmentMultiple);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendResearchAppointmentMultipleConnected = new Subject<any>();

	emitResearchAppointmentMultipleConnected(dataResearchAppointmentMultiple: any) {
		this.configObservableSendResearchAppointmentMultipleConnected.next(dataResearchAppointmentMultiple);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshOperations = new Subject<any>();

	emitRefreshOperations(dataResearchAppointmentMultiple: any) {
		this.configObservableSendRefreshOperations.next(dataResearchAppointmentMultiple);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendActiveLoading = new Subject<boolean>();

	emitSendActiveLoading(dataResearch: boolean) {
		this.configObservableSendActiveLoading.next(dataResearch);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public subscriptionSendDialogRefClose = new Subject<number>();

	emitSendDialogRefClose(dialogRefClose: number) {
		this.subscriptionSendDialogRefClose.next(dialogRefClose);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshAppointmentCreate = new Subject<any>();

	emitRefreshAppointmentCreate(dataPostAppointment: any) {
		this.configObservableSendRefreshAppointmentCreate.next(dataPostAppointment);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/

	public configObservableSendRefreshAppointmentUpdate = new Subject<boolean>();

	emitClosePopupLoading(activeClose: boolean) {
		this.configObservableSendClosePopupLoading.next(activeClose);
	}

	/*------------------------------------------------------------------------------------------------------------------------------------------------*/
	
	public configObservableSendClosePopupLoading = new Subject<boolean>();
	
	emitRefreshAppointmentUpdate(activeRefresh: boolean) {
		this.configObservableSendRefreshAppointmentUpdate.next(activeRefresh);
	}
	
}