<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>      
            <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon" style="margin-left: auto;">close</mat-icon>
        <div>
    </div>
</div>

<!-- Container content popup cancel option -->
<mat-dialog-content>
    <div class="containerContent">
        <!-- <video *ngIf="objectReceivePopup.dataPopup.videoCarouselName !== undefined" controls width="250">
        <source src="https://www.youtube.com/embed/2Ehx48Q3ZBM"
            onerror="this.src='assets/defaultFondGray.png'" type="video/mp4">
    </video> -->
    
    <iframe *ngIf="!isVideoUrlEmpty" width="560" height="315" [src]="videoUrl" frameborder="0"  allow="autoplay; encrypted-media" allowfullscreen></iframe>
    <div *ngIf="isVideoUrlEmpty">
        Aucune vidéo disponible
    </div>    
</div>
</mat-dialog-content>