import { ConfigObservableService } from './service-config-observable-service.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { User } from '../../shared/models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NameBtnLegalNotice } from '../models/Footer/name-btn-legal-notice';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	environment: any = null;

	user: User = new User();
	constructor(private http: HttpClient, public router: Router, private serviceConfigObservable: ConfigObservableService) {
    this.environment = environment;
  }

	// récupère un user et le stock
	public getMe() {
      let token = localStorage.getItem('TOKEN');

      if (!token) {
        token = '';
      }

      let headers = new HttpHeaders({
        'accept': 'text/plain',
        'Authorization': token 
      });

      return this.http.get(this.environment.urlServer + 'Users/UserInfos', { headers: headers }).pipe(tap((user: any) => {
        console.log(user);
        this.user = user.datas
        this.emitNameLegalNotice_toFooter();
      }));
	}


  public getMeReturnValue(): Observable<any> {
    let token = localStorage.getItem('TOKEN');

    if (!token) {
      token = '';
    }

    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Authorization': token 
    });

    return this.http.get(this.environment.urlServer + 'Users/UserInfos', { headers: headers , observe: 'response' })  
    .pipe(map((response: HttpResponse<any>) => {
        console.log(response.body);
        this.user = response.body.datas;
        this.emitNameLegalNotice_toFooter();
        return response.body.datas;
      })
    );
  }

  emitNameLegalNotice_toFooter() {
    let nameBtnLegalNotice: NameBtnLegalNotice = {
      'cgu_name': this.user['society.if_ml_cgu_buttontitle'],
      'cgv_name': this.user['society.if_ml_cgv_buttonname'],
      'ml_name': this.user['society.if_ml_ml_buttonname'],
      'pc_name': this.user['society.if_ml_pc_buttontitle']
    }
    this.serviceConfigObservable.emitNameBtnFooter(nameBtnLegalNotice);
  }

  addPageInNavbarAndAnalitics(nameOperation?: string) {
    let namePageActiveHtml: string = '';
    let namePageActive: string = '';
    let urlActive: string[] = this.router.url.split('/');
    if (urlActive.length <= 3) {
      namePageActive = urlActive[urlActive.length - 1];
    }
    if (urlActive.length > 3) {
      namePageActive = urlActive[urlActive.length - 2];
    }

    if (namePageActive == '') {
      namePageActiveHtml = 'Login'
    } else if (namePageActive == 'project') {
      namePageActiveHtml = 'Projets'
    } else if (namePageActive == 'sale') {
      namePageActiveHtml = 'Ventes'
    } else if (namePageActive == 'calendar') {
      namePageActiveHtml = 'Agendas'
    } else if (namePageActive == 'legalNotice') {
      namePageActiveHtml = 'Mentions légales'
    } else if (namePageActive == 'privacyPolicy') {
      namePageActiveHtml = 'Politique de confidentialité'
    } else if (namePageActive == 'rdv') {
      namePageActiveHtml = 'ACTIVITES'
    } else if (namePageActive == 'cgv') {
      namePageActiveHtml = 'CGV'
    } else if (namePageActive == 'cgu') {
      namePageActiveHtml = 'CGU'
    } else if (namePageActive == 'placement') {
      namePageActiveHtml = 'Placement'
    } else if (namePageActive == 'favorite') {
      namePageActiveHtml = 'Favoris'
    } else if (namePageActive == 'realEstateComparator') {
      namePageActiveHtml = 'Comparateur Immobilier'
    } else if (namePageActive == 'stock') {
      if (nameOperation !== undefined) {
        namePageActiveHtml = 'Stock' + ' - ' + nameOperation
      } else {
        namePageActiveHtml = 'Stock'
      }
    } else if (namePageActive == 'oldstock') {
      if (nameOperation !== undefined) {
        namePageActiveHtml = 'Historique du stock' + ' - ' + nameOperation
      } else {
        namePageActiveHtml = 'Historique du stock'
      }
    } else {
      if (nameOperation) {
        namePageActiveHtml = nameOperation
      } else {
        namePageActiveHtml = namePageActive
      }
    }

    this.serviceConfigObservable.emitSendRoute(namePageActive)
    this.serviceConfigObservable.emitSendRouteHtml(namePageActiveHtml)
  }

}