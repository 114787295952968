<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': objectReceivePopup.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo"
                src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup"
                [ngClass]="{ 'titlePopupSM': objectReceivePopup.size < 2 }">{{ objectReceivePopup.title }}</h1>
            <div>
            </div>
        </div>

        <!-- Container content popup cancel option -->
        <mat-dialog-content
            [ngClass]="{'containerLoadingContent': objectReceivePopup.size >= 2, 'containerLoadingContentSM': objectReceivePopup.size < 2}">












            



             <!-- DESKTOP PART -->

            <div class="d-none d-xl-block d-lg-block d-md-block">
                <!-- Desktop version -->
                <div class="containerAllContentCreateTask">

                    <form class="containerForm" [formGroup]="formCreateTask">
                        <div class="containerSelectGeneric">
                            <div *ngIf="isOpenSelectCreateTask.selectConcernant" class="containerLabelSelectGeneric">
                                <span *ngIf="isOpenSelectCreateTask.selectConcernant" class="spanSelectLabel">{{
                                    labelSelectConcernant }}</span>
                            </div>
                            <ng-select *ngIf="listConcernant.length !== 0"
                                [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                formControlName="selectConcernant" [placeholder]="labelSelectConcernant"
                                [items]="listConcernant" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true"
                                (click)="openSelectAndReset('selectConcernant', formCreateTask, isOpenSelectCreateTask)"
                                (clear)="openSelectAndReset('selectConcernant', formCreateTask, isOpenSelectCreateTask)">
                            </ng-select>
                        </div>
                        <div *ngIf="(objectReceivePopup.namePopup === 'createCallTel' || objectReceivePopup.namePopup === 'modifyCallTel') && !formCreateTask.value?.ckeckBoxPlanifyCalendar"
                            class="containerPartDate">
                            <div class="divDatePickerCreateTask"
                                [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                <mat-form-field class="formFieldDatePicker"
                                    [ngClass]="{ 'formFieldOptionSM': objectReceivePopup.size < 2 }">
                                    <mat-label class="customLabelPicker">{{ labelDateEcheance }}</mat-label>
                                    <input matInput [matDatepicker]="picker2" formControlName="dateEcheance">
                                    <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="divDatePickerCreateTask"
                                [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                <ngb-timepicker class="timePicker" [disabled]="disableHoraire" formControlName="time">
                                </ngb-timepicker>
                            </div>
                        </div>
                        <div>
                            <mat-checkbox class="fieldResearchCheckBox" formControlName="ckeckBoxPlanifyCalendar"
                                (change)="changeValueAgenda($event)">Planifier dans mon agenda</mat-checkbox>
                        </div>

                        <div *ngIf="!formCreateTask.value?.ckeckBoxPlanifyCalendar && objectReceivePopup.namePopup !== 'createCallTel' && objectReceivePopup.namePopup !== 'modifyCallTel'"
                            class="containerPartDate">
                            <div class="divDatePickerCreateTask"
                                [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                <mat-form-field class="formFieldDatePicker"
                                    [ngClass]="{ 'formFieldOptionSM': objectReceivePopup.size < 2 }">
                                    <mat-label class="customLabelPicker">{{ labelDateEcheance }}</mat-label>
                                    <input matInput [matDatepicker]="picker2" formControlName="dateEcheance">
                                    <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="selectPartDate containerSelectGeneric">
                                <div *ngIf="isOpenSelectCreateTask.selectPriority" class="containerLabelSelectGeneric">
                                    <span *ngIf="isOpenSelectCreateTask.selectPriority" class="spanSelectLabel">{{
                                        labelSelectPriority }}</span>
                                </div>
                                <ng-select *ngIf="listPriority.length !== 0" name="selectorPriority"
                                    [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                    formControlName="selectPriority" [placeholder]="labelSelectPriority"
                                    [items]="listPriority" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                    [closeOnSelect]="true" [clearable]="false" [multiple]="false" [hideSelected]="true"
                                    (click)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)"
                                    (clear)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)">
                                </ng-select>
                            </div>
                        </div>

                        <div *ngIf="formCreateTask.value?.ckeckBoxPlanifyCalendar" class="containerAllPartDate">
                            <div class="containerPartDate">
                                <div class="divDatePickerCreateTask"
                                    [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                    <mat-form-field class="formFieldDatePicker"
                                        [ngClass]="{ 'formFieldOptionSM': objectReceivePopup.size < 2 }">
                                        <mat-label class="customLabelPicker">{{ labelStartDate }}</mat-label>
                                        <input matInput [matDatepicker]="picker3" formControlName="startDate">
                                        <mat-datepicker-toggle matSuffix [for]="$any(picker3)"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="divDatePickerCreateTask"
                                    [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                    <ngb-timepicker class="timePicker" [disabled]="disableHoraire"
                                        formControlName="time"></ngb-timepicker>
                                </div>
                            </div>

                            <div class="containerPartDate">
                                <div class="selectPartDate containerSelectGeneric">
                                    <div *ngIf="isOpenSelectCreateTask.selectTimeDuring"
                                        class="containerLabelSelectGeneric">
                                        <span *ngIf="isOpenSelectCreateTask.selectTimeDuring" class="spanSelectLabel">{{
                                            labelSelectTimeDuring }}</span>
                                    </div>
                                    <ng-select *ngIf="listFieldTimeDuring.length !== 0"
                                        [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                        formControlName="selectTimeDuring" [placeholder]="labelSelectTimeDuring"
                                        [items]="listFieldTimeDuring" bindLabel="name" bindValue="id"
                                        [compareWith]="compareWithFn" [closeOnSelect]="true" [multiple]="false"
                                        [hideSelected]="true"
                                        (click)="openSelectAndReset('selectTimeDuring', formCreateTask, isOpenSelectCreateTask)"
                                        (clear)="openSelectAndReset('selectTimeDuring', formCreateTask, isOpenSelectCreateTask)">
                                    </ng-select>
                                </div>
                                <div class="selectPartDate containerSelectGeneric">
                                    <div *ngIf="isOpenSelectCreateTask.selectPriority"
                                        class="containerLabelSelectGeneric">
                                        <span *ngIf="isOpenSelectCreateTask.selectPriority" class="spanSelectLabel">{{
                                            labelSelectPriority }}</span>
                                    </div>
                                    <ng-select *ngIf="listPriority.length !== 0"
                                        [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                        formControlName="selectPriority" [placeholder]="labelSelectPriority"
                                        [items]="listPriority" bindLabel="name" bindValue="id"
                                        [compareWith]="compareWithFn" [closeOnSelect]="true" [clearable]="false"
                                        [multiple]="false" [hideSelected]="true"
                                        (click)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)"
                                        (clear)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 10px;">
                            <mat-form-field class="formFieldInputResidence" appearance="fill">
                                <mat-label class="labelField">{{ labelSubject }}</mat-label>
                                <input type="text" matInput formControlName="subject" [required]="requiredSubject">
                            </mat-form-field>
                        </div>

                        <div
                            [ngClass]="{'collumnCritère': objectReceivePopup.size > 2, 'collumnCritèreSM': objectReceivePopup.size <= 2}">
                            <!-- formControl comment and error -->
                            <mat-form-field
                                [ngClass]="{'formFieldTextAreaCommentCritèreProject': objectReceivePopup.size > 2, 'formFieldTextAreaCommentCritèreProjectMD': objectReceivePopup.size == 2, 'formFieldTextAreaCommentCritèreProjectSM': objectReceivePopup.size < 2}"
                                appearance="fill">
                                <mat-label class="labelField">{{ labelDescription }}</mat-label>
                                <textarea class="description" #txtarea matInput maxlength="15000"
                                    formControlName="description"></textarea>
                            </mat-form-field>
                        </div>




                        <div class="containerBtnLoading">
                            <button (click)="closePopupAnnulation()" class="btnAnnulation">Annuler</button>
                            <button (click)="closePopupValidation()" class="btnValidation"
                                [disabled]="formCreateTask.invalid">Valider</button>
                        </div>
                    </form>

                    <app-calendar style="margin-left: 35px; width: 100%;"
                        *ngIf="formCreateTask.value?.ckeckBoxPlanifyCalendar && objectReceivePopup.actiavtionCalendar"
                        [size]='objectReceivePopup.size' [rowThumbnail]="[]"></app-calendar>
                </div>
            </div>





























                <!-- RESPONSIVE PART -->

            <div class="d-block d-sm-block d-md-none">
                <div [formGroup]="formCreateTask" class="container">

                    <form class="containerFormSm" [formGroup]="formCreateTask">
                        <div class="containerSelectGeneric">
                            <div *ngIf="isOpenSelectCreateTask.selectConcernant" class="containerLabelSelectGeneric">
                                <span *ngIf="isOpenSelectCreateTask.selectConcernant" class="spanSelectLabel">{{
                                    labelSelectConcernant }}</span>
                            </div>
                            <ng-select *ngIf="listConcernant.length !== 0"
                                [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                formControlName="selectConcernant"
                                [items]="listConcernant" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true"
                                (click)="openSelectAndReset('selectConcernant', formCreateTask, isOpenSelectCreateTask)"
                                (clear)="openSelectAndReset('selectConcernant', formCreateTask, isOpenSelectCreateTask)">
                            </ng-select>
                        </div>
                        <div *ngIf="(objectReceivePopup.namePopup === 'createCallTel' || objectReceivePopup.namePopup === 'modifyCallTel') && !formCreateTask.value?.ckeckBoxPlanifyCalendar"
                            class="containerPartDate">
                            <div class="divDatePickerCreateTask"
                                [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                <mat-form-field class="formFieldDatePicker"
                                    [ngClass]="{ 'formFieldOptionSM': objectReceivePopup.size < 2 }">
                                    <mat-label class="customLabelPicker">{{ labelDateEcheance }}</mat-label>
                                    <input matInput [matDatepicker]="picker2" formControlName="dateEcheance">
                                    <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="divDatePickerCreateTask"
                                [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                <ngb-timepicker class="timePicker" [disabled]="disableHoraire" formControlName="time">
                                </ngb-timepicker>
                            </div>
                        </div>
                        <div>
                            <mat-checkbox class="fieldResearchCheckBox" formControlName="ckeckBoxPlanifyCalendar"
                                (change)="changeValueAgenda($event)">Planifier dans mon agenda</mat-checkbox>
                        </div>

                        <div *ngIf="!formCreateTask.value?.ckeckBoxPlanifyCalendar && objectReceivePopup.namePopup !== 'createCallTel' && objectReceivePopup.namePopup !== 'modifyCallTel'"
                            class="containerPartDate">
                            <div class="divDatePickerCreateTask"
                                [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                <mat-form-field class="formFieldDatePicker"
                                    [ngClass]="{ 'formFieldOptionSM': objectReceivePopup.size < 2 }">
                                    <mat-label class="customLabelPicker">{{ labelDateEcheance }}</mat-label>
                                    <input matInput [matDatepicker]="picker2" formControlName="dateEcheance">
                                    <mat-datepicker-toggle matSuffix [for]="$any(picker2)"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="selectPartDate containerSelectGeneric">
                                <div *ngIf="isOpenSelectCreateTask.selectPriority" class="containerLabelSelectGeneric">
                                    <span *ngIf="isOpenSelectCreateTask.selectPriority" class="spanSelectLabel">{{
                                        labelSelectPriority }}</span>
                                </div>
                                <ng-select *ngIf="listPriority.length !== 0" name="selectorPriority"
                                    [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                    formControlName="selectPriority" [placeholder]="labelSelectPriority"
                                    [items]="listPriority" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                    [closeOnSelect]="true" [clearable]="false" [multiple]="false" [hideSelected]="true"
                                    (click)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)"
                                    (clear)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)">
                                </ng-select>
                            </div>
                        </div>

                        <div *ngIf="formCreateTask.value?.ckeckBoxPlanifyCalendar" class="containerAllPartDate">
                            <div class="containerPartDate">
                                <div class="divDatePickerCreateTask"
                                    [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                    <mat-form-field class="formFieldDatePicker"
                                        [ngClass]="{ 'formFieldOptionSM': objectReceivePopup.size < 2 }">
                                        <mat-label class="customLabelPicker">{{ labelStartDate }}</mat-label>
                                        <input matInput [matDatepicker]="picker3" formControlName="startDate">
                                        <mat-datepicker-toggle matSuffix [for]="$any(picker3)"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3></mat-datepicker>
                                    </mat-form-field>
                                </div>
                                <div class="divDatePickerCreateTask"
                                    [ngClass]="{ 'inputPostOptionSM': objectReceivePopup.size < 2 }">
                                    <ngb-timepicker class="timePicker" [disabled]="disableHoraire"
                                        formControlName="time"></ngb-timepicker>
                                </div>
                            </div>

                            <div class="containerPartDate">
                                <div class="selectPartDate containerSelectGeneric">
                                    <div *ngIf="isOpenSelectCreateTask.selectTimeDuring"
                                        class="containerLabelSelectGeneric">
                                        <span *ngIf="isOpenSelectCreateTask.selectTimeDuring" class="spanSelectLabel">{{
                                            labelSelectTimeDuring }}</span>
                                    </div>
                                    <ng-select *ngIf="listFieldTimeDuring.length !== 0"
                                        [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                        formControlName="selectTimeDuring" [placeholder]="labelSelectTimeDuring"
                                        [items]="listFieldTimeDuring" bindLabel="name" bindValue="id"
                                        [compareWith]="compareWithFn" [closeOnSelect]="true" [multiple]="false"
                                        [hideSelected]="true"
                                        (click)="openSelectAndReset('selectTimeDuring', formCreateTask, isOpenSelectCreateTask)"
                                        (clear)="openSelectAndReset('selectTimeDuring', formCreateTask, isOpenSelectCreateTask)">
                                    </ng-select>
                                </div>
                                <div class="selectPartDate containerSelectGeneric">
                                    <div *ngIf="isOpenSelectCreateTask.selectPriority"
                                        class="containerLabelSelectGeneric">
                                        <span *ngIf="isOpenSelectCreateTask.selectPriority" class="spanSelectLabel">{{
                                            labelSelectPriority }}</span>
                                    </div>
                                    <ng-select *ngIf="listPriority.length !== 0"
                                        [ngClass]="{ 'selectFieldResidenceDescription': objectReceivePopup.size >= 2, 'selectCustomTwoLargePopupSM': objectReceivePopup.size < 2 }"
                                        formControlName="selectPriority" [placeholder]="labelSelectPriority"
                                        [items]="listPriority" bindLabel="name" bindValue="id"
                                        [compareWith]="compareWithFn" [closeOnSelect]="true" [clearable]="false"
                                        [multiple]="false" [hideSelected]="true"
                                        (click)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)"
                                        (clear)="openSelectAndReset('selectPriority', formCreateTask, isOpenSelectCreateTask)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>

                        <div style="margin-top: 10px;">
                            <mat-form-field class="formFieldInputResidence" appearance="fill">
                                <mat-label class="labelField">{{ labelSubject }}</mat-label>
                                <input type="text" matInput formControlName="subject" [required]="requiredSubject">
                            </mat-form-field>
                        </div>

                        <div
                            [ngClass]="{'collumnCritère': objectReceivePopup.size > 2, 'collumnCritèreSM': objectReceivePopup.size <= 2}">
                            <!-- formControl comment and error -->
                            <mat-form-field
                                [ngClass]="{'formFieldTextAreaCommentCritèreProject': objectReceivePopup.size > 2, 'formFieldTextAreaCommentCritèreProjectMD': objectReceivePopup.size == 2, 'formFieldTextAreaCommentCritèreProjectSM': objectReceivePopup.size < 2}"
                                appearance="fill">
                                <mat-label class="labelField">{{ labelDescription }}</mat-label>
                                <textarea class="description" #txtarea matInput maxlength="15000"
                                    formControlName="description"></textarea>
                            </mat-form-field>
                        </div>




                        <div class="containerBtnLoading">
                            <button (click)="closePopupAnnulation()" class="btnAnnulation">Annuler</button>
                            <button (click)="closePopupValidation()" class="btnValidation"
                                [disabled]="formCreateTask.invalid">Valider</button>
                        </div>
                    </form>

                </div>
            </div>












        </mat-dialog-content>