import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { DocumentService } from 'src/app/shared/services/document.service';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from './../../../../shared/services/snackbar.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { GestOptionService } from 'src/app/shared/services/gest-option.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { environment } from 'src/environments/environment';

// Interface for send several data in the popup for the save of research
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-form-sale',
  templateUrl: './popup-form-sale.component.html',
  styleUrls: ['./popup-form-sale.component.scss']
})
export class PopupFormSaleComponent implements OnInit {

  subModuleUse: string;

  // Declaration & Init name popup get of parents
  namePopup: string = this.data.namePopup;

  // Label comment for cancel option
  labelComment: string = 'Commentaire';

  // Array for the list of ng-select in the cancel option
  listFieldConclusionCode: Array<any> = [{ name: "A25101", id: "A25101"}, { name: "B51324", id: "B51324"}];  

  // Declaration & Init form group with validator error for the form cancel option
  insertCancelOptionForm: FormGroup = this.formBuilder.group({
    conclusionCode: [ null, Validators.required ],
    comment: [ "", Validators.required ]
  })

  // Declaration & Init form group with validator error for the form electronic signature and electronic signature DER
  insertSignElecForm: FormGroup = this.formBuilder.group({
    conformity: [ false, Validators.required ],
    acceptSign: [ false, Validators.required ]
  })

  // Declaration - private var for form builder - Injectable data mat dialog of parent - root to popup choice
  constructor(
    private formBuilder: FormBuilder, private serviceGestOption: GestOptionService, private snackbar: SnackbarService, 
    private serviceErrorMessage: ErrorMessageService, private popupLoading: MatDialog, private serviceDocument: DocumentService,
    public dialogRef: MatDialogRef<PopupFormSaleComponent>, private serviceConfigObservable: ConfigObservableService, private servicePopup: PopupServiceService,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPopup) {
      this.subModuleUse = environment.subModuleUse;
    }

  ngOnInit() {
    console.log(this.data)
  }

  // Get error and return message error
  getErrorMessageRequired(controlFormSelected: any, nameControl: string) {
    if ( nameControl === "startDate") {
      if (controlFormSelected.getError('required')) {
        return 'La date et heure de début du RDV est requis';
      } else {
        return "";
      }
    } else if ( nameControl === "endDate") {
      if (controlFormSelected.getError('required')) {
        return 'La date et heure de fin du RDV est requis';
      } else {
        return "";
      }
    } else if ( nameControl === "address") {
      if (controlFormSelected.getError('required')) {
        return 'L\'emplacement du RDV est requis';
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  // Function submit form electronic signature and electronic signature DER
  onSubmitSignElecForm() {
    console.log(this.data.dataPopup.event2.if_ctc1_derid)
    this.openDialogLoading('postDocumentDer', '')
    this.serviceDocument.postDocumentDer(this.data.dataPopup.event2.if_ctc1_derid).pipe(first())
      .subscribe((document: any) => {
        console.log(document)
        if (document !== null) {
          this.popupLoading.closeAll()
        }
      })
  }


  // Function submit form electronic signature and electronic signature DER
  onSubmitSignElecFormDocuSign() {
    window.open(this.data.dataPopup.urlDocuSign)
    this.popupLoading.closeAll()
  }

  onSubmitPreResa() {
    this.openDialogLoading('postLoadingResaPaper', '');

    try {
      this.serviceGestOption.postPropositionPreReservation(this.data.dataPopup.propositionId).pipe(first())
      .subscribe((data: any) => {
        console.log(data)
        this.popupLoading.closeAll()
        this.serviceConfigObservable.emitRefreshOption(true);
      }) 
    } catch(e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessagePostPropositionPreReservation, ["custom-style-error"]
      );
      this.popupLoading.closeAll()
    }
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string, errorMessage: any) {
    this.servicePopup.openDialogLoading(namePopupLoading, this.data.size, errorMessage);
  }
  
}
