<!-- Container title popup with mat dialog title (post option and cancel option on a asset and research save) -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
        <div class="containerTitleImg">
            <img *ngIf="subModuleUse === 'submodule_Edelis'" style="width: 65px;" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logoCreateSaveSearch" src="../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
            <img *ngIf="subModuleUse !== 'submodule_Edelis'" style="width: 40px;" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logoCreateSaveSearch" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }" *ngIf=" data.namePopup === 'saveSearch' ">Recherches sauvegardées</h1>
            <h1 class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }" *ngIf=" data.namePopup !== 'saveSearch' && data.namePopup !== 'cancelOption' ">Pose d'option</h1>
            <h1 class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }" *ngIf=" data.namePopup === 'cancelOption' ">Voulez vous vraiment annuler cette option ?</h1>
        </div>
        <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup research save -->
<mat-dialog-content [ngClass]="{'containerSearchSave': data.size >= 2, 'containerSearchSaveSM': data.size < 2}" *ngIf=" data.namePopup === 'saveSearch' ">
    <h1 [ngClass]="{'titleNotResearchFind': data.size >= 2, 'titleNotResearchFindSM': data.size < 2}" *ngIf="tabSearchSave.length === 0">Vous n'avez pas de sauvegarde de recherche</h1>

    <app-table-housing-search-save *ngIf="refresh && tabSearchSave.length !== 0" [dataSearchSave]="tabSearchSave" (sendActiveGetSearchSave)="deleteResearchRefresh(refresh = false)" (sendResearchEvent)="loadSendResearch($event)"></app-table-housing-search-save>
    
    <div *ngIf="!refresh" class="containerLoadingTableHousing">
        <mat-spinner [mode]="'indeterminate'"></mat-spinner>
    </div>

    <button mat-dialog-close [ngClass]="{'btnCancelSearchSave': data.size > 2, 'btnCancelSearchSaveMD': data.size == 2, 'btnCancelSearchSaveSM': data.size < 2}">Annuler</button>
</mat-dialog-content>

<!-- Container content popup post option on a asset -->
<mat-dialog-content [ngClass]="{ 'containerPostOption': data.size >= 2, 'containerPostOptionSM': data.size < 2 }" *ngIf=" data.namePopup === 'postOptionProject' || data.namePopup === 'postOptionLot' ">
    <form [ngClass]="{'postOptionForm': data.size >= 2, 'postOptionFormSM': data.size < 2}" [formGroup]="insertSearchForm" (ngSubmit)="onSubmitPostOption()">
        <div class="containerInput">
            <div class="containerFieldNameClient" [ngClass]="{'containerFieldNotProject': notProjectFind}">
                <!-- formControl nameClient -->
                <span *ngIf="isOpenSelect.nameClient" class="spanSelectOrigin">Nom du projet</span>
                <ng-select *ngIf="toppingList.length !== 0" [ngClass]="{'selectNameClient': data.size >= 2, 'selectNameClientMD': data.size == 2, 'selectNameClientSM': data.size < 2 }" formControlName="nameClient" [value]="nameClient" placeholder="Nom du projet"
                    [items]="toppingList" bindLabel="name" bindValue="id" [disabled]="isDisable" [compareWith]="compareWithFn" (click)="openSelectAndReset('nameClient', insertSearchForm, isOpenSelect)" (clear)="openSelectAndReset('nameClient', insertSearchForm, isOpenSelect)"
                    [closeOnSelect]="true" [hideSelected]="true">
                </ng-select>

                <span *ngIf="notProjectFind && data.size >= 2" [ngClass]="{'msgNotProject': data.size >= 2, 'msgNotProjectSM': data.size < 2}">{{ messageNotProject }}</span>
                
                <!-- When is publish on dev or recette the button is hidden  ( wait for moment ) -->
                <!-- <button type="button" *ngIf="isDisable && activeEnv" class="btnOpenAddClient" [ngClass]="{ 'btnOpenAddClientSM': data.size < 2, 'btnOpenAddClientTablet': ( data.size === 2 ) || ( data.size === 3 ) }" (click)="openDialogNewProject('postNewProject', $event)">+</button> -->
            </div>

            <!-- Div + formControl dateOption with mat date picker -->
            <div *ngIf="nameClient !== null" class="inputPostOption" [ngClass]="{ 'inputPostOptionSM': data.size < 2 }">
                <mat-form-field class="formFieldDatePicker" [ngClass]="{ 'formFieldOptionSM': data.size < 2 }">
                    <mat-label class="labelInput">{{ labelRdvDate }}</mat-label>
                    <input matInput readonly [disabled]="!isDisable" [ngxMatDatetimePicker]="picker1" (blur)="openFieldRdvtDate($event)" (focus)="openFieldRdvtDate($event)" (change)="openFieldRdvtDate($event)" (dateChange)="dateOptionChangeValue($event)" formControlName="dateOption">
                    <mat-datepicker-toggle matSuffix [disabled]="!isDisable" [for]="$any(picker1)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker1></ngx-mat-datetime-picker>
                </mat-form-field>
            </div>

            <div *ngIf="errorMessageDispoOne || errorMessageDispoTwo || errorMessageRuleTwo || errorMessageRank">
                <span [ngClass]="{'spanErrorRule': data.size >= 2, 'spanErrorRuleSM': data.size < 2 }" *ngIf="errorMessageDispoOne">Ce lot n’est pas disponible à la date du RDV</span>
                <span [ngClass]="{'spanErrorRule': data.size >= 2, 'spanErrorRuleSM': data.size < 2 }" *ngIf="errorMessageDispoTwo">Vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV</span>
                <span [ngClass]="{'spanErrorRule': data.size >= 2, 'spanErrorRuleSM': data.size < 2 }" *ngIf="errorMessageRuleTwo">Vous ne pouvez pas encore poser d’option sur ce lot pour ce RDV</span>
                <span [ngClass]="{'spanErrorRule': data.size >= 2, 'spanErrorRuleSM': data.size < 2 }" *ngIf="errorMessageRank">Pas plus de 2 rangs d'option</span>
            </div>

            <!-- Container select insert if active -->
            <div *ngIf="tabOptionResidence.length !== 0" [ngClass]="{ 'containerActiveSelect': data.size >= 2, 'containerActiveSelectSM': data.size < 2 }">
                <!-- formControl nameProgram -->
                <ng-select formControlName="nameProgram" class="selectAddressAndName" [value]="nameProgram" placeholder="Programme"
                    [items]="tabOptionResidence" bindLabel="name" bindValue="id" [disabled]="isDisable" (change)="operationChangeValue(tabOptionNameLot = [])"
                    [closeOnSelect]="true" [hideSelected]="true">
                </ng-select>

                <!-- formControl nameLot -->
                <ng-select *ngIf="tabOptionNameLot.length !== 0" formControlName="nameLot" class="selectAddressAndName" [value]="nameLot" placeholder="Lot"
                    [items]="tabOptionNameLot" bindLabel="name" bindValue="id" [disabled]="!isDisableAsset" (change)="assetChangeValue()"
                    [closeOnSelect]="true" [hideSelected]="true">
                </ng-select>
            </div>


            <!-- Container select disable if active -->
            <div *ngIf="isDisable" [ngClass]="{'containerDisableSelect': data.size >= 2, 'containerDisableSelectSM': data.size < 2}">
                <!-- formControl nameProgram -->
                <mat-form-field *ngIf="isDisable" class="formFieldAddress">
                    <mat-select panelClass="selectChoice" placeholder="Programme" [value]="nameProgram"
                                    formControlName="nameProgram" [disabled]="isDisable">
                        <mat-option *ngFor="let topping of toppingListLot" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>
                
                <!-- formControl nameLot -->
                <mat-form-field *ngIf="isDisable" class="formFieldProduct2" [ngClass]="{ 'formFieldProduct2SM': data.size < 2 }">
                    <mat-select panelClass="selectChoice" placeholder="Lot" [value]="nameLot"
                                    formControlName="nameLot" [disabled]="isDisable">
                        <mat-option *ngFor="let topping of toppingListNameLot" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <p *ngIf="!insertSearchForm.controls.nameLot.invalid" class="spanTextRang">
                Statut : <span class="spanRang">{{ statusAsset }}</span><br>
                <span *ngIf="hiddenRank">Rang : <span class="spanRang">{{ rangAsset }}</span></span><br *ngIf="hiddenRank">
                Date de dispo : <span class="spanRang">{{ dateDispoAsset }}</span>
            </p>
        </div>
        <span *ngIf="notProjectFind && data.size < 2" [ngClass]="{'msgNotProject': data.size >= 2, 'msgNotProjectSM': data.size < 2}">{{ messageNotProject }}</span>
        <span *ngIf="!isValidationOption" class="spanErrorDate">ATTENTION : La disponibilité du lot n’est pas compatible avec la date de votre RDV</span>
        <div  [ngClass]="{'containerBtnPostOption' : data.size >= 2, 'containerBtnPostOptionSM' : data.size < 2 }">
            <button mat-dialog-close [ngClass]="{'btnCancelOption': data.size >= 2, 'btnCancelOptionSM': data.size < 2 }" type="button" >Annuler</button>
            <button mat-icon-button *ngIf="!errorMessageRank && !notProjectFind && !errorMessageDispoOne && !errorMessageDispoTwo && !errorMessageRuleTwo" class="btnPostOption" [ngClass]="{ 'btnPostOptionSM': data.size < 2 }" type="submit" [disabled]="insertSearchForm.invalid || !isValidationOption">Valider option</button>
        </div>
    </form>
</mat-dialog-content>

<!-- Container content popup cancel option on a asset -->
<mat-dialog-content [ngClass]="{'containerPostOption': data.size >= 2, 'containerPostOptionSM': data.size < 2 }" *ngIf="data.namePopup === 'cancelOption'">
    <form class="postOptionForm" [formGroup]="insertSearchForm" (ngSubmit)="onSubmitCancelOption()">
        <div class="containerInputCancelOption">
            <p [ngClass]="{'paragrapheCancelOption': data.size >= 2, 'paragrapheCancelOptionSM': data.size < 2 }">
                {{ nameClient }}<br>
                {{ nameProgram }} - {{ nameLot }}<br>
                PRIX TTC :&nbsp;{{ priceTTC }}<br>
                Du {{ startDateOption }} au {{ endDateOption }}<br>
                Rang {{ rangAsset }}
            </p>
        </div>

        <div [ngClass]="{'containerBtnPostOption' : data.size >= 2, 'containerBtnPostOptionSM' : data.size < 2 }">
            <button mat-icon-button [ngClass]="{'btnCancelOption': data.size >= 2, 'btnCancelOptionSM': data.size < 2 }" type="submit" >Oui</button>
            <button mat-dialog-close [ngClass]="{'btnNotCancelOption': data.size >= 2, 'btnNotCancelOptionSM': data.size < 2 }" type="button" >Non</button>
        </div>
    </form>
</mat-dialog-content>