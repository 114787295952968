import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

// Interface for send several data in the popup
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  title: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-img-carousel',
  templateUrl: './popup-img-carousel.component.html',
  styleUrls: ['./popup-img-carousel.component.scss']
})
export class PopupImgCarouselComponent implements OnInit {

  subModuleUse: string;

  currentSlide = 0;

  constructor(public dialogRef: MatDialogRef<PopupImgCarouselComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup) {
    this.subModuleUse = environment.subModuleUse;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const paginationBtns = document.querySelectorAll(".pagination-btn");
      const prevBtn = document.querySelector("#prev");
      const nextBtn = document.querySelector("#next");
      
      this.currentSlide = this.objectReceivePopup.dataPopup.listImgCarousel.map((e: any) => e.src).indexOf(this.objectReceivePopup.dataPopup.imgCarouselSelected);

      // Initialize the slider
      this.updateSlider();

      // Add event listener to the pagination buttons
      paginationBtns.forEach((button, index) => {
        button.addEventListener("click", () => {
          this.currentSlide = index;
    
          this.updateSlider();
        });
      });
    
      // Add event listener to the control buttons
    
      prevBtn?.addEventListener("click", () => {
        this.currentSlide--;
        this.updateSlider();
      });
      nextBtn?.addEventListener("click", () => {
        this.currentSlide++;
        this.updateSlider();
      });
    }, 500)
  }


  updateSlider() {
    // this.objectReceivePopup.dataPopup.imgCarouselSelected = ""

    const paginationBtns = document.querySelectorAll(".pagination-btn");
    const slider = document.querySelector(".slider");
    let prevBtn: any = document.querySelector("#prev");
    let nextBtn: any = document.querySelector("#next");
    const slides = document.querySelectorAll(".slide");
    // update slides

    slides.forEach((slide, index) => {
      console.log(slide);
      slide.classList.remove("active");

      if (index === this.currentSlide) {
        slide.classList.add("active");
      }
    });
    // Update pagination buttons

    paginationBtns.forEach((button, index) => {
      button.classList.remove("active");

      if (index === this.currentSlide) {
        button.classList.add("active");
      }
    });
    // Update control buttons

    if (this.currentSlide === 0) {
      prevBtn.disabled = true;
    } else {
      prevBtn.disabled = false;
    }
    if (this.currentSlide === slides.length - 1) {
      nextBtn.disabled = true;
    } else {
      nextBtn.disabled = false;
    }
  }

}
