import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Asset } from 'src/app/shared/models/asset';
import { PopupServiceService } from 'src/app/shared/services/popup-service.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { OperationService } from 'src/app/shared/services/operation.service';
import { HttpResponse } from '@angular/common/http';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { DenunciationService } from 'src/app/shared/services/denunciation.service';
import { GestOptionService } from 'src/app/shared/services/gest-option.service';
import { PropositionService } from 'src/app/shared/services/proposition.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { LeadAccepted } from 'src/app/shared/models/lead-accepted';

// Interface for send several data in the popup
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  title: string;
  backgroundColorContent: string;
  colorContent: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-post-option-denunciation',
  templateUrl: './popup-post-option-denunciation.component.html',
  styleUrls: ['./popup-post-option-denunciation.component.scss']
})
export class PopupPostOptionDenunciationComponent implements OnInit {

  subModuleUse: string;

  private ngUnsubscribe = new Subject();

  valueResponseBafSynchronize: any = null;

  labelDateRdv: string = 'Date de RDV';

  tabOptionAsset: Array<any> = Array<any>();
  tabOptionDenunciation: Array<any> = Array<any>();

  // Declaration & Init form group with validator error for the form in the stepper one
  insertPostOptionDenunciationForm: FormGroup = this.formBuilder.group({
    nameAsset: [null, Validators.required],
    dateRdv: ["", Validators.required]
  })

  insertPostOptionStockForm: FormGroup = this.formBuilder.group({
    denunciation: [null, Validators.required],
    dateRdv: ["", Validators.required]
  })

  constructor(public dialogRef: MatDialogRef<PopupPostOptionDenunciationComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup, private serviceDenunciation: DenunciationService,
              private formBuilder: FormBuilder, private popupLoading: MatDialog, private popupService: PopupServiceService, private serviceOperation: OperationService,
              private serviceSnackbar: SnackbarService, private serviceErrorMessage: ErrorMessageService, private serviceGestOption: GestOptionService, private serviceProposition: PropositionService,
              private serviceConfigObservable: ConfigObservableService) {
                this.subModuleUse = environment.subModuleUse;
              }

  ngOnInit(): void {
    if (this.objectReceivePopup.namePopup === 'postOptionDenunciation') {
      this.serviceOperation.getAssetsWithOperationId(this.objectReceivePopup.dataPopup.id_operation).pipe(first(), takeUntil(this.ngUnsubscribe))
        .subscribe((asset: any) => {
            asset.forEach((assetData: Asset) => {
              this.tabOptionAsset.push({ name: assetData['asset.if_assetnumber'] + " - " + assetData['asset.statuscode_label'].slice(2), id: { name: assetData['asset.if_assetnumber'] + " - " + assetData['asset.statuscode_label'].slice(2), id: assetData['asset.if_assetid'] } } );                              
            });
        }, error => {
          this.tabOptionAsset = [{ name: 'Aucune données', id: { name: 'Aucune données', id: 'Aucune données' } }]
        });
    } else if (this.objectReceivePopup.namePopup === 'postOptionStock') {
      this.serviceDenunciation.getDenunciationAccepted(this.objectReceivePopup.dataPopup.id_operation).pipe(first(), takeUntil(this.ngUnsubscribe))
        .subscribe((denunciation: any) => {
          denunciation.forEach((denunciationData: LeadAccepted) => {
              this.tabOptionDenunciation.push({ name: denunciationData.if_ctc1_lastname + ' ' + denunciationData.if_ctc1_firstname, id: { name: denunciationData.search_text, id: ['lead.if_importprojectid'] } } );                              
            });
        }, error => {
          this.tabOptionDenunciation = [{ name: 'Aucune données', id: { name: 'Aucune données', id: 'Aucune données' } }]
        });
      
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string, messageError: any) {
    this.popupService.openDialogLoading(namePopupLoading, this.objectReceivePopup.size, messageError);
  }

  onSubmitPostOptionDenunciation() {
    let newNameClient;
    let newIdLot;
    let dateRdvPostOption;
    
    newNameClient = this.objectReceivePopup.dataPopup.idDenunciation;
    newIdLot = this.insertPostOptionDenunciationForm.value.nameAsset.id;
    dateRdvPostOption = this.insertPostOptionDenunciationForm.value.dateRdv.toISOString();

    this.putCreateOption(newIdLot, newNameClient, dateRdvPostOption);
  }

  onSubmitPostOptionStock() {
    this.openDialogLoading('loadingGestOption', "")
    let newNameClient;
    let newIdLot;
    let dateRdvPostOption;
        
    newNameClient = this.insertPostOptionStockForm.value.denunciation.id;
    newIdLot = this.objectReceivePopup.dataPopup.AssetId;
    dateRdvPostOption = this.insertPostOptionDenunciationForm.value.dateRdv.toISOString();  
    
    this.putCreateOption(newIdLot, newNameClient, dateRdvPostOption);
  }

  putCreateOption(newIdLot: string, newNameClient: string, dateRdvPostOption: any) {
    this.serviceGestOption.putCreateProposition(newIdLot, newNameClient, null, dateRdvPostOption)
      .pipe(first()).subscribe((data: any) => {
        console.log(data);
        if (data.propositionId === null) {
          this.openDialogLoading('notGetBafSynchronizedOptionNotId', data.errorMessage)
        } else {
          this.getBafSynchronized(data);
          this.openDialogLoading('synchronizedBafOption', '');
        }
      }, error => {
        this.serviceSnackbar.openSnackBar(
          this.serviceErrorMessage.errorMessagePutCreateProposition, ["custom-style-error"]
        );
      });
  }

  // Rendre Binding pour prod ou autre (A faire)
  // Methode for get if baf is synchronized after update project
  async getBafSynchronized(data: any) {
    let i = 0
    let setIntervalProjectId = setInterval(() => {
      if (i > 10) {
        // this.popupLoading.closeAll();
        this.openDialogLoading('notGetBafSynchronizedOption', '');
        clearInterval(setIntervalProjectId);
      }

      this.serviceProposition.getBafSynchronized(data.propositionId).then((response: HttpResponse<any>) => {
        this.valueResponseBafSynchronize = response.body;
      });

      if (this.valueResponseBafSynchronize !== undefined) {
        if (this.valueResponseBafSynchronize.datas === true) {
          this.serviceConfigObservable.emitRefreshStock(true);
          this.serviceConfigObservable.emitRefreshOption(true);
          clearInterval(setIntervalProjectId);
          this.openDialogLoading('validGestOption', { start: data.startDate, end: data.endDate })
        }
      }
      console.log(i)
      i++
    }, 2000)
  }

}