<!-- Container title popup with mat dialog title -->
<div class="containerPopupBreakingNews">
    <div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
        <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
            <div class="containerTitleImg">
                <img *ngIf="subModuleUse === 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
                <img *ngIf="subModuleUse !== 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/picto-popup.png">
                <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>      
            <div>
        </div>
    </div>
    
    <!-- Container content popup cancel option -->
    <mat-dialog-content class="containerMatContent" [style]="'background:' + this.objectReceivePopup.backgroundColorContent + '; color:' + this.objectReceivePopup.colorContent">
        <div class="containerContent">
            La résidence n'est actuellement plus commercialisée.
        </div>
    </mat-dialog-content>
    
    <div class="containerAction">
        <button class="btnAction" (click)="returnToPageBack()">OK</button>
    </div>
</div>