<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" [ngClass]="{ 'logoSM': data.size < 2 &&  data.namePopup === 'postOptionLot', 'logoSaveSM': data.size < 2 && data.namePopup === 'saveSearch' }" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 class="titlePopup" *ngIf="namePopup === 'editRdv' || namePopup === 'editRdvWithReservation' || namePopup === 'editRdvWithInProgress'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Nouveau RDV</h1>
            <h1 class="titlePopup" *ngIf="namePopup === 'showRdv'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Votre RDV</h1>
            <h1 class="titlePopup" *ngIf="namePopup === 'modifyRdv'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Modifier RDV</h1>
            <h1 class="titlePopup" *ngIf="namePopup === 'ruleRdv'" [ngClass]="{ 'titlePopupSM': data.size < 2 }">Fermer le RDV</h1>
        </div>
        <!-- <mat-icon mat-dialog-close [ngClass]="{ 'iconCloseSM': data.size < 2 }" aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup create and modify rdv -->
<mat-dialog-content *ngIf="namePopup !== 'ruleRdv' && namePopup !== 'showRdv'" [ngClass]="{'containerPostRdv': data.size > 2, 'containerPostRdvMD': data.size == 2, 'containerPostRdvSM': data.size < 2 }">
    <div class="containerAllContentCreateTask">

        <form class="formRdvEditAndModify"  [formGroup]="insertRdvForm" (ngSubmit)="onSubmitPostForm()">
            <table cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                    <td>
                        <div *ngIf="!activeSelectType" class="containerBtnCategory">
                            <div *ngFor="let fieldCategory of listFieldCategory; let i = index;" class="containerForBtnCategory">
                                <button class="btnCategory" [ngClass]="{'categoryChoice': fieldCategory.color }" type="button" (click)="changeValueCategory(fieldCategory, i)">{{ fieldCategory.name }}</button>
                            </div>
                        </div>

                        <div *ngIf="activeSelectType" class="containerBtnCategory">
                            <ng-select *ngIf="listFieldCategory.length !== 0" [ngClass]="{'selectCategory': data.size >= 2, 'selectCategorySM': data.size < 2}" formControlName="category" placeholder="Catégorie"
                                [items]="listFieldCategory" bindLabel="name" [compareWith]="compareWithFnCategory"
                                [closeOnSelect]="true" [multiple]="false" [hideSelected]="true">
                            </ng-select>
                            <span *ngIf="this.insertRdvForm.controls.category.invalid && activeErrorMessageSelect" class="errorMessageSelect">La catégorie du RDV est requis</span>
                        </div>
                    </td>
                    <td [ngClass]="{'tdSelectProject': data.size >= 2, 'tdSelectProjectSM': data.size < 2 }">
                        <!-- formControl project and error with span ngif -->
                        <ng-select *ngIf="listFieldProject.length !== 0" [ngClass]="{'selectProjectDisabled': activeDisableTitle, 'selectProject': !activeDisableTitle}" formControlName="project" placeholder="Concernant"
                            [items]="listFieldProject" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true">
                        </ng-select>
                        <span *ngIf="this.insertRdvForm.controls.project.invalid && activeErrorMessageSelect" class="errorMessageSelect errorMessageSelectProject">Le nom du RDV est requis</span>
                        <!-- <button *ngIf="!activeDisableTitle" class="btnOpenAddClient" type="button" [ngClass]="{ 'btnOpenAddClientSM': data.size < 2, 'btnOpenAddClientTablet': ( data.size === 2 ) || ( data.size === 3 ) }" (click)="openDialogNewProject('postNewProject', $event)">+</button> -->
                    </td>
                </tr>
            </table>

            <table Scellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                    <td>
                        <!-- formControl address and error -->
                        <mat-form-field [ngClass]="{'formFieldSubjectNewRdv': data.size >= 2, 'formFieldSubjectNewRdvSM': data.size < 2}" appearance="fill">
                            <mat-label class="labelField">{{ labelSubject }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="subject"
                                >
                        </mat-form-field>
                    </td>
                </tr>
            </table>

            <mat-checkbox *ngIf="data.size > 2" class="fieldResearchCheckBox" formControlName="ckeckBoxPlanifyCalendar" (change)="changeValueAgenda($event)">Afficher mon agenda</mat-checkbox>

            <table cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowCustomSM': data.size < 2 }">
                    <td style="display: flex; align-items: center; width: 162px;">
                        <!-- Div + formControl startDate with mat date picker and ngx mat datetime picker  -->
                        <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size < 2 }">
                            <mat-form-field [ngClass]="{ 'formFieldDatePicker': !disableHoraire, 'formFieldDatePickerDisable': disableHoraire, 'formFieldOptionSM': data.size < 2 }">
                                <mat-label>{{ labelStartDate }}</mat-label>
                                <input matInput [disabled]="disableHoraire" (dateInput)="openFieldStartDate($event)" [matDatepicker]="picker1" (blur)="openFieldStartDate($event)" (focus)="openFieldStartDate($event)" (change)="openFieldStartDate($event)" formControlName="startDate">
                                <mat-datepicker-toggle matSuffix [disabled]="disableHoraire" [for]="$any(picker1)"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </td>
                    <td>
                        <!-- Div + formControl endDate with mat date picker and ngx mat datetime picker  -->
                        <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size < 2 }">
                            <ngb-timepicker class="timePicker" [disabled]="disableHoraire" formControlName="time"></ngb-timepicker>
                        </div>
                    </td>
                    <td style="display: flex; align-items: center; width: 162px;">
                        <!-- Div + formControl endDate with mat date picker and ngx mat datetime picker  -->
                        <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size < 2 }">
                            <ng-select *ngIf="listFieldTimeDuring.length !== 0" class="selectTimeDuring" formControlName="timeDuring" placeholder="DUREE"
                            [items]="listFieldTimeDuring" bindLabel="name" bindValue="id" (change)="locationChangeValue()" [compareWith]="compareWithFn"
                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" [disabled]="disableHoraire">
                            </ng-select>
                            <span *ngIf="this.insertRdvForm.controls.timeDuring.invalid && activeErrorMessageSelect" class="errorMessageSelectTime">La durée du RDV est requise</span>
                        </div>
                    </td>
                </tr>
            </table>

            <table cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowCustomSM': data.size < 2 }">
                    <td>
                        <!-- formControl modality and error with span ngif -->
                        <ng-select *ngIf="listFieldModality.length !== 0" [ngClass]="{'selectCategory': data.size >= 2, 'selectCategorySM': data.size < 2}" formControlName="modality" placeholder="Modalité"
                            [items]="listFieldModality" bindLabel="name" bindValue="id" (change)="categoryChangeValue()" [compareWith]="compareWithFn"
                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true">
                        </ng-select>
                        <span *ngIf="this.insertRdvForm.controls.modality.invalid && activeErrorMessageSelect" class="errorMessageSelect">La modalité du RDV est requise</span>
                    </td>
                    <td *ngIf="activeLocation">
                        <!-- formControl location and error with span ngif -->
                        <ng-select *ngIf="listFieldLocation.length !== 0" [ngClass]="{'selectCategory': data.size >= 2, 'selectCategorySM': data.size < 2}" formControlName="location" placeholder="Lieu"
                            [items]="listFieldLocation" bindLabel="name" bindValue="id" (change)="locationChangeValue()" [compareWith]="compareWithFn"
                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true">
                        </ng-select>
                        <span *ngIf="errorMessageSelectLocation" class="errorMessageSelect">Le lieu du RDV est requis</span>
                    </td>
                </tr>
            </table>

            <table *ngIf="activeAddressClient" cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                    <td>
                        <!-- formControl addressOne and error -->
                        <mat-form-field class="formFieldAddressNewRdv" appearance="fill">
                            <mat-label class="labelField">{{ labelAddressOne }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="addressOne"
                                >
                            <span *ngIf="errorMessageInputAddress" class="errorMessageSelect errorMessageSelectAddress">L\'emplacement du RDV est requis</span>
                        </mat-form-field>
                    </td>
                    <td>
                        <!-- formControl addressTwo and error -->
                        <mat-form-field class="formFieldAddressNewRdv" appearance="fill">
                            <mat-label class="labelField">{{ labelAddressTwo }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="addressTwo"
                                >
                        </mat-form-field>
                    </td>
                </tr>
            </table>

            <table *ngIf="activeAddressClient" cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                    <td>
                        <!-- formControl address and error -->
                        <mat-form-field class="formFieldAddressNewRdv" appearance="fill">
                            <mat-label class="labelField">{{ labelZipCode }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="zipCode"
                                >
                        </mat-form-field>
                    </td>
                    <td>
                        <!-- formControl city and error with span ngif -->
                        <span *ngIf="isOpenSelect.city" class="spanSelectOrigin">Ville</span>
                        <ng-select #cityTerm [ngClass]="{'selectOrigin': data.size >= 2, 'selectOriginSM': data.size < 2}" formControlName="city" placeholder="Ville"
                            [items]="listFieldCity" bindLabel="name" bindValue="id" [compareWith]="compareWithFn" (keyup)="customSearchFn($event, cityTerm, 'city', isOpenSelect)"
                            [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('city', insertRdvForm, isOpenSelect)" (clear)="openSelectAndReset('city', insertRdvForm, isOpenSelect)">
                        </ng-select>
                    </td>
                </tr>
            </table>

            <table cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowCustomSM': data.size < 2 }">
                    <td>
                        <!-- formControl comment -->
                        <mat-form-field [ngClass]="{'formFieldCommentOrInternalMemo': data.size >= 2, 'formFieldCommentOrInternalMemoSM': data.size < 2 }" appearance="fill">
                            <mat-label class="labelField">{{ labelComment }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="comment"
                                >
                        </mat-form-field>
                    </td>
                </tr>
            </table>
            <table cellspacing="0">
                <tr class="trNewRdv" [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                    <td style="margin-top: 5px;">
                        <!-- formControl internalMemo -->
                        <mat-form-field [ngClass]="{'formFieldCommentOrInternalMemo': data.size >= 2, 'formFieldCommentOrInternalMemoSM': data.size < 2 }" appearance="fill">
                            <mat-label class="labelField">{{ labelInternalMemo }}</mat-label>
                            <input type="text"
                                matInput
                                formControlName="internalMemo"
                                >
                        </mat-form-field>
                    </td>
                </tr>
            </table>

            <!-- Container btn create rdv -->
            <div *ngIf="namePopup === 'editRdv' || namePopup === 'editRdvWithInProgress' || namePopup === 'editRdvWithReservation'" [ngClass]="{'containerBtn': data.size >= 2, 'containerBtnSM': data.size < 2 }">
                <button mat-dialog-close class="btnCancel">Annuler</button>
                <button [ngClass]="{'btnCreateRdv': data.size >= 2, 'btnCreateRdvSM': data.size < 2}" type="submit">Créer le rdv</button>
            </div>

            <!-- Container btn modify rdv -->
            <div *ngIf="namePopup === 'modifyRdv'" [ngClass]="{'containerBtn': data.size >= 2, 'containerBtnSM': data.size < 2 }">
                <button mat-dialog-close class="btnCancel" style="width: 150px;">Annuler</button>
                <button [ngClass]="{'btnCreateRdv': data.size >= 2, 'btnCreateRdvSM': data.size < 2}" style="width: 150px;" type="submit">Modifier le RDV</button>
                <!-- <button class="btnCreateRdv" type="button">Statuer le RDV</button> -->
                <button [ngClass]="{'btnRuleRdv': data.size >= 2, 'btnRuleRdvSM': data.size < 2}" style="width: 150px;" type="button" (click)="openRuleDialog()">Statuer le RDV</button>
            </div>
        </form>

        <app-calendar style="margin-left: 35px; width: 100%;" *ngIf="insertRdvForm.value?.ckeckBoxPlanifyCalendar" [size]='data.size' [rowThumbnail]="[]"></app-calendar>
    </div>
</mat-dialog-content>

<!-- Container content popup show rdv -->
<mat-dialog-content *ngIf="namePopup === 'showRdv'" class="containerRdv" [ngClass]="{ 'containerPostOptionSM': data.size < 2 }">
    <p>Votre rendez-vous {{ showRdv.title }}</p>
    <p>Début: {{ showRdv.start | dateFormatLetterPipe }} à {{ showRdv.start | dateFormatHoursPipe }}</p>
    <p>Fin: {{ showRdv.end | dateFormatLetterPipe }} à {{ showRdv.end | dateFormatHoursPipe }}</p>
    <p>Concernant: {{ showRdv.project }}</p>
    <p>Statut: {{ showRdv.category }}</p>
    <p>Modalité: {{ showRdv.modality }}</p>
    <p *ngIf="showRdv.location !== null">Lieu: {{ showRdv.location }}</p>
    <p>Emplacement: {{ showRdv.emplacement }}</p>
    <p>Commentaire: {{ showRdv.comment }}</p>
    <p>Note interne: {{ showRdv.internalMemo }}</p>
</mat-dialog-content>

<!-- Container content popup rule rdv -->
<mat-dialog-content *ngIf="namePopup === 'ruleRdv'" class="containerRdv" [ngClass]="{ 'containerPostOptionSM': data.size < 2 }">
    <p [ngClass]="{'paragrapheRuleRdv': data.size >= 2, 'paragrapheRuleRdvSM': data.size < 2}">Concernant : <span class="valueRuleRdv">{{ ruleRdv.project }}</span><br>
    Date de début : <span class="valueRuleRdv">{{ ruleRdv.start | dateFormatLetterPipe }} à {{ ruleRdv.start | dateFormatHoursPipe }}</span> <br>
    Date de fin : <span class="valueRuleRdv">{{ ruleRdv.end | dateFormatLetterPipe }} à {{ ruleRdv.end | dateFormatHoursPipe }}</span> <br>
    Emplacement: <br><span class="valueRuleRdv">{{ ruleRdv.emplacement }}</span>
    </p>
    <form [ngClass]="{'ruleRdvForm': data.size >= 2, 'ruleRdvFormSM': data.size < 2}" [formGroup]="insertRuleRdvForm" (ngSubmit)="onSubmitPostFormRule('ruleRdv')">
        <table cellspacing="0">
            <tr [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                <td>
                    <!-- Div + formControl birthday with mat date picker -->
                    <div class="divDatePicker" [ngClass]="{ 'inputPostOptionSM': data.size < 2 }">
                        <mat-form-field class="formFieldDatePickerLargeDisable" [ngClass]="{ 'formFieldOptionSM': data.size < 2, 'formFieldDatePicker': data.size < 3 }">
                            <mat-label class="labelInput">{{ labelDateRdvClose }}</mat-label>
                            <input matInput readonly [matDatepicker]="picker3" formControlName="dateRdvClose">
                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                            <mat-datepicker #picker3></mat-datepicker>
                        </mat-form-field>
                    </div>
                </td>
            </tr>
        </table>
        <section class="sectionCheckboxNewProject">
            <span [ngClass]="{'titleNatureProject': data.size >= 2, 'titleNatureProjectSM': data.size < 2 }">RDV effectué : </span>
            <mat-checkbox class="immovableCheckBox" formControlName="rdvPastYes" (change)="changeValueYesOrNo($event, 'rdvPastYes')">Oui</mat-checkbox>
            <mat-checkbox formControlName="rdvPastNo" (change)="changeValueYesOrNo($event, 'rdvPastNo')">Non</mat-checkbox>
        </section>
        <table *ngIf="!(insertRuleRdvForm.value.rdvPastYes == false && insertRuleRdvForm.value.rdvPastNo == false)" cellspacing="0">
            <tr [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                <td>
                    <!-- formControl conclusionCode -->
                    <span *ngIf="isOpenSelect.conclusionCode" class="spanSelectOrigin">Code conclusion</span>
                    <ng-select *ngIf="listFieldConclusionCode.length !== 0" [ngClass]="{'selectConclusionCode': data.size >= 2, 'selectConclusionCodeSM': data.size < 2}" formControlName="conclusionCode" placeholder="Code conclusion"
                        [items]="listFieldConclusionCode" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('conclusionCode', insertRuleRdvForm, isOpenSelect)" (clear)="openSelectAndReset('conclusionCode', insertRuleRdvForm, isOpenSelect)">
                    </ng-select>
                </td>
            </tr>
        </table>
        <table *ngIf="!(insertRuleRdvForm.value.rdvPastYes == false && insertRuleRdvForm.value.rdvPastNo == false)" cellspacing="0">
            <tr class="trNewRdv" [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                <td>
                    <span *ngIf="requiredComment" class="commentRequired">Le commentaire est requis</span>
                    <!-- formControl comment -->
                    <mat-form-field [ngClass]="{'formFieldCommentRuleRdv': data.size >= 2 , 'formFieldCommentRuleRdvSM': data.size < 2 }" appearance="fill">
                        <mat-label class="labelField">{{ labelComment }}</mat-label>
                        <input type="text"
                            matInput
                            formControlName="comment"
                            >
                    </mat-form-field>
                </td>
            </tr>
        </table>
        <div [ngClass]="{'containerBtn': data.size >= 2, 'containerBtnSM': data.size < 2 }">
            <button mat-dialog-close class="btnCancelRule">Annuler</button>
            <button [ngClass]="{'btnCreateRdvRule': data.size >= 2, 'btnCreateRdvRuleSM': data.size < 2 }" type="submit">Statuer le RDV</button>
            <!-- <button [ngClass]="{'btnRuleAndCreateRdv': data.size >= 2, 'btnRuleAndCreateRdvSM': data.size < 2 }" type="button" (click)="onSubmitPostFormRule('editRdv')">Statuer le RDV et créer RDV suivant</button> -->
        </div>
    </form>
</mat-dialog-content>