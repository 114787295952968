import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor() { }

  getPdf(pdfBase64: any) {
    return of(pdfBase64).pipe(delay(3000));
  }
}
