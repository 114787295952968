<app-size-detector></app-size-detector>
<app-connector-cas-apereo *ngIf="subModuleUse == 'submodule_Stellium'"></app-connector-cas-apereo>
<router-outlet></router-outlet>
<app-footer-all-page style="position: relative; display: block;"></app-footer-all-page>


<!-- <script>
    // Choice Service with sub module selected
    if (this.subModuleUse == 'submodule_Quintesens') {
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', 'G-SFDMTP50B6');
    } 
</script> -->