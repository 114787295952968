import { environment, myService } from './../../../../../environments/environment';
import { SCREEN_SIZE } from './../../../../size-detector/screen-size.enum';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';
import { SnackbarService } from './../../../../shared/services/snackbar.service';
import { Component, EventEmitter, Input, Output, ViewChild, Injector, OnInit, AfterViewInit, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
// Interface PeriodicElement with all field ( field lot for research and field lot for a program )  
import { PeriodictElement } from 'src/app/shared/models/periodict-element';
import { ResearchService } from 'src/app/shared/services/research.service';
import { initSizeScreen } from 'src/app/shared/services/functions';

@Component({
  selector: 'app-table-housing-search-save',
  templateUrl: './table-housing-search-save.component.html',
  styleUrls: ['./table-housing-search-save.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableHousingSearchSaveComponent implements OnInit, AfterViewInit {

  // Declaration & Init array of data of API of research save get of parents else init array empty
  @Input() dataSearchSave: Array<any> = [];

  // Emit data dialog of post option for opening of the popup of post option
  @Output() newPostOptionEvent = new EventEmitter<any>();
  
  // Send search id for activated function for init research in the parent
  @Output() sendResearchEvent = new EventEmitter<string>();
  
  // Send activation of get of all the research save in API 
  @Output() sendActiveGetSearchSave = new EventEmitter<Boolean>();

  size: SCREEN_SIZE = 4;

  // Declaration & Init name page activated
  namePageActive: string = 'stock';

  // Declaration & Init Array for the creation of row table and content row
  ELEMENT_DATA: PeriodictElement[] = new Array<PeriodictElement>();
  // Declaration & Init Array for init view table
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  // Declaration & Init boolean for activated div for confirm delete research
  activeDeleteResearch: boolean = false;

  // Declaration & Init array of string for init column of mat component tablehousing 
  columnsToDisplay: Array<string> = new Array<string>();

  // Declaration & Init exanded element for the expantion of row of tablehousing
  expandedElement: PeriodictElement | null | undefined;

  // Init periodic element for all lot of all program
  initPeriodicElement: PeriodictElement = new PeriodictElement();

  //ViewChild for sort the column table
  @ViewChild(MatSort) sort!: MatSort;

  subModuleUse: string;

  private serviceTableHousing: any;

  // Construction Router for get name page active, Service research for get/post/put data research in api, Service tablehousing for get/post/put data tablehousing in api,
  // Private service for observe and receive data
  constructor(public router: Router, private snackbar: SnackbarService, private serviceErrorMessage: ErrorMessageService,
              private serviceResearch: ResearchService, private injector: Injector,
              private serviceConfigObservable: ConfigObservableService) {

    this.subModuleUse = environment.subModuleUse;
    this.serviceTableHousing = this.injector.get(myService.serviceTableHousing)
    
    // Observe route send for init name page activated
    this.serviceConfigObservable.configObservableSendRoute.subscribe(route => {
      this.namePageActive = route;
    })
    // Observe result check data send of tablehousing service for init the data source of mat tablehousing component
    this.serviceTableHousing.configObservableSearchSave.subscribe((resultCheckData: PeriodictElement[]) => {
      this.dataSource = new MatTableDataSource(resultCheckData);
    });
  }

  // Initialized according to whether it is an array with all the programs or only one
  ngOnInit(): void {
    console.log('table save')
    let urlActive: string[] = this.router.url.split('/');
    this.namePageActive = urlActive[urlActive.length - 1] + 'search';

    this.size = initSizeScreen()

    this.initTableHousing(this.dataSearchSave);
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.columnsToDisplay = ['title', 'icon'];
    // this.serviceTableHousing.checkData(this.namePageActive, this.dataSearchSave)
    this.initTableHousing(changes.dataSearchSave.currentValue)
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values
  }

  initTableHousing(dataSearchSave: Array<any>) {
    this.columnsToDisplay = ['title', 'icon'];
    this.serviceTableHousing.checkData(this.namePageActive, dataSearchSave)
  }

  // Sort all data after the initialisation
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  // Method for filter icon in the column of icon 
  filterColumnOfIcon(nameColumn: string){
    return this.columnsToDisplay.filter(column => column == nameColumn);
  }

  // Method for filter icon in the column of type
  filterColumnOfType(nameColumn: string){
    return this.columnsToDisplay.filter(column => column !== nameColumn);
  }

  // Method for create data for open popup and post option in the parents
  openPopupPostOption(programData: PeriodictElement) {
      let allDataProgram = JSON.parse(localStorage.getItem('tabDataProgram') || '{}');
      let newProgram = allDataProgram.filter((program: any) => program.id === programData.AllProgram.id);
      newProgram[0].lot = newProgram[0].lot.filter((lot: any) => lot.nameLot === programData.LOT);

      this.newPostOptionEvent.emit( programData.dataDialog );
  }

  // Method for delete research of research save with the id of the research selected and emit activation at parents for refresh research
  deleteResearch(searchId: string) {
    try {
      this.serviceResearch.deleteResearchSaveWithId(searchId).subscribe((data) => {
        console.log(data);
      })
    } catch(e) {
      this.snackbar.openSnackBar(
        this.serviceErrorMessage.errorMessageDeleteResearchSaveWithId, ["custom-style-error"]
      );
    }
    this.sendActiveGetSearchSave.emit(true);
    this.activeDeleteResearch = false;
  }

  // Method for send id research save selected in the parents for load research selected in the view
  sendResearch(searchId: string) {
    this.sendResearchEvent.emit(searchId)
  }
}