import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { UserService } from 'src/app/shared/services/user.service';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { DenunciationService } from '../../../../../shared/services/denunciation.service';
import { SnackbarService } from '../../../../../shared/services/snackbar.service';
import { PopupServiceService } from '../../../../../shared/services/popup-service.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorMessageService } from 'src/app/shared/services/error-message.service';

// Interface for send several data in the popup
export interface DialogDataPopup {
  size: SCREEN_SIZE;
  namePopup: string;
  title: string;
  backgroundColorContent: string;
  colorContent: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-post-denunciation',
  templateUrl: './popup-post-denunciation.component.html',
  styleUrls: ['./popup-post-denunciation.component.scss']
})
export class PopupPostDenunciationComponent implements OnInit {

  subModuleUse: string;

  private ngUnsubscribe = new Subject();

  labelLastname: string = 'Nom';
  labelFirstname: string = 'Prénom';
  labelZipcode: string = 'Code Postal';
  labelCity: string = 'Ville';
  labelEmail: string = 'Email';
  labelPhone: string = 'Téléphone';

  // Declaration & Init form group with validator error for the form in the stepper one
  insertPostDenunciationForm: FormGroup = this.formBuilder.group({
    lastname: ["", Validators.required],
    firstname: ["", Validators.required],
    zipcode: [""],
    city: [""],
    email: ["", Validators.required, Validators.email],
    phone: ["", Validators.required],
  })

  constructor(public dialogRef: MatDialogRef<PopupPostDenunciationComponent>, @Inject(MAT_DIALOG_DATA) public objectReceivePopup: DialogDataPopup,
              private formBuilder: FormBuilder, private popupLoading: MatDialog, private serviceUser: UserService, private serviceConfigObservable: ConfigObservableService,
              private popupService: PopupServiceService, private serviceDenunciation: DenunciationService, private serviceErrorMessage: ErrorMessageService,
              private serviceSnackbar: SnackbarService) {
                this.subModuleUse = environment.subModuleUse;
              }

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Function open dialog loading with name popup choice
  openDialogLoading(namePopupLoading: string) {
    this.popupService.openDialogLoading(namePopupLoading, this.objectReceivePopup.size, "");
  }

  onSubmitPostDenunciation() {
    if (!this.insertPostDenunciationForm.invalid) {
      this.openDialogLoading('postCreateDenunciation');
      let objectRequestDenunciation = [
        {
          "Value": this.insertPostDenunciationForm.value.lastname,
          "Key": "if_ctc1_lastname"
        },
        {
          "Value": this.insertPostDenunciationForm.value.firstname,
          "Key": "if_ctc1_firstname"
        },
        {
          "Value": this.insertPostDenunciationForm.value.email,
          "Key": "if_ctc1_email1"
        },
        {
          "Value": this.insertPostDenunciationForm.value.phone,
          "Key": "if_ctc1_mobilephone"
        },
        {
          "Value": this.objectReceivePopup.dataPopup.operationIdAlone,
          "Key": "if_operationid"
        },
        {
            "Value": this.serviceUser.user.if_commercialassignmentid,
            "Key": "if_commercialassigmentid"
        },
        {
            "Value": "750010000",
            "Key": "if_project_type"
        },
        {
            "Value": "750010000",
            "Key": "if_flow_mode"
        }
      ]

      if (this.insertPostDenunciationForm.value.city !== '') {
        objectRequestDenunciation.push({
          "Value": this.insertPostDenunciationForm.value.city,
          "Key": "if_ctc1_city" 
        });
      }

      if (this.insertPostDenunciationForm.value.zipcode !== '') {
        objectRequestDenunciation.push({
          "Value": this.insertPostDenunciationForm.value.zipcode,
          "Key": "if_ctc1_postalcode" 
        });
      }

      this.serviceDenunciation.postCreateDenunciation({ "Fields": objectRequestDenunciation }).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        this.serviceConfigObservable.emitClosePopupLoading(true);
        this.openDialogLoading('postDenunciationValid');
      }, error => {
        this.serviceSnackbar.openSnackBar(
          this.serviceErrorMessage.errorMessagePostCreateDenunciation, ["custom-style-error"]
          );
        this.serviceConfigObservable.emitClosePopupLoading(true);
      })
    }
  }

}