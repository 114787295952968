import { UserService } from './user.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityApiService {

  beforeHeaders: HttpHeaders = new HttpHeaders();
  // static URL = environment.urlActivityApi;
  environment: any;

	constructor(private http: HttpClient, private userService: UserService, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getAppointmentWithStartAndEndDate(page: number, pageSize: number, scheduleStart: string, scheduleEnd: string, severalOrSolo: boolean, arrayConsultant: Array<string>): Observable<any> {
    
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

      let dataPost: any = {};
      dataPost.StartDate = scheduleStart;
      dataPost.EndDate = scheduleEnd;
      dataPost.Emails = []
      
      if (severalOrSolo && arrayConsultant.length !== 0) {
        dataPost.Emails = arrayConsultant
      } else if (!severalOrSolo) {
        dataPost.Emails = [ this.userService.user['partnercontact.emailaddress'] ]
      }

    return this.http
      .post(this.environment.urlActivityApi + 'Calendars/Search', dataPost, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }


  public getTaskWithStartAndEndDate(page: number, pageSize: number, scheduleStart: string, scheduleEnd: string, severalOrSolo: boolean, arrayConsultant: Array<string>): Observable<any> {
    
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

      let dataPost: any = {};
      dataPost.StartDate = scheduleStart;
      dataPost.EndDate = scheduleEnd;
      dataPost.Emails = [ this.userService.user['partnercontact.emailaddress'] ]

    return this.http
      .post(this.environment.urlActivityApi + 'TodoList/Search', dataPost, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }

  public postTaskActivity(dataEvent: any): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .post(this.environment.urlActivityApi + 'CrmTasks/', JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public putTaskActivity(dataEvent: any, idTasks: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlActivityApi + 'CrmTasks/' + idTasks, JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public postPhoneCallActivity(dataEvent: any): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .post(this.environment.urlActivityApi + 'CrmPhoneCalls/', JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public putPhoneCallActivity(dataEvent: any, idPhoneCall: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlActivityApi + 'CrmPhoneCalls/' + idPhoneCall, JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  

  public closeTaskActivity(idTasks: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlActivityApi + 'CrmTasks/' + idTasks + '/Close', JSON.stringify({}), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public cancelTaskActivity(idTasks: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlActivityApi + 'CrmTasks/' + idTasks + '/Cancel', JSON.stringify({}), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public closePhoneCalls(idPhoneCalls: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlActivityApi + 'CrmPhoneCalls/' + idPhoneCalls + '/Close', JSON.stringify({}), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public cancelPhoneCalls(idPhoneCalls: string): Observable<any> {
    const headers = this.authentificationService.headers;
    return this.http
      .put(this.environment.urlActivityApi + 'CrmPhoneCalls/' + idPhoneCalls + '/Cancel', JSON.stringify({}), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  
  public postAppointment(dataEvent: any): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return this.http
      .post(this.environment.urlActivityApi + 'CrmAppointments', dataEvent, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }


  public getAppointmentId(id: string): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return this.http
      .get(this.environment.urlActivityApi + 'CrmAppointments/' + id, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }

  public getReferentialsAffectationId(id: string): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

      let idSend = id
      if (id == '') {
        idSend = this.userService.user['if_commercialassignmentid']
      }

    return this.http
      .get(this.environment.urlActivityApi + 'Referentials/Affectations/' + idSend, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }

  public updateAppointmentId(appointmentId: string,dataEvent: any): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return this.http
      .put(this.environment.urlActivityApi + 'CrmAppointments/' + appointmentId, JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          console.log(response.body);
          return response.body;
        })
      );
  }

  public postAppointmentClose(id: string, dataEvent: any): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return this.http
      .put(this.environment.urlActivityApi + 'CrmAppointments/Close/' + id, JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }

  public postReferentialsDepartment(dataEvent: any): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return this.http
      .post(this.environment.urlActivityApi + 'Referentials/Departments', dataEvent, { observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }


  public postReferentialsConsultant(dataEvent: any): Observable<any> {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    let headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return this.http
      .post(this.environment.urlActivityApi + 'Referentials/Consultants', JSON.stringify(dataEvent), { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          if (this.beforeHeaders.get('Authorization') !== null) {
            this.authentificationService.headers = this.beforeHeaders;
          }
          return response.body;
        })
      );
  }

  public async getBafSynchronizedAppointmentGoogle(id: string) {
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    const headers = new HttpHeaders({
      'accept': 'text/plain',
      'Content-Type': 'application/json' });

    return await this.http
      .get(this.environment.urlActivityApi + 'Synchronize/SynchronizeCrmActivity/' + id , { headers: headers, observe: 'response' })
      .pipe().toPromise();
  }

  // public putCreateProposition(idLot: string, idProject: string, idAppointmentR3: string): Observable<any> {
  //   if (this.authentificationService.headers.get('Authorization') !== null) {
    //   this.beforeHeaders = this.authentificationService.headers;
    // }
  //   let headers = {
  //     'accept': 'text/plain',
  //     'Content-Type': 'application/json' };

  //   let proposition: any;
  //   proposition =  {
  //       "AssetId": idLot,
  //       "ProjectId": idProject,
  //       "SellerId": this.userService.user['assignment.if_commercialassignmentid'],
  //       "PerformerId": this.userService.user['assignment.if_commercialassignmentid'],
  //       "Source": "VitrineB2B"
  //     }

  //   if (idAppointmentR3 !== null) {
  //     proposition.if_r_appointmentid = idAppointmentR3;
  //   }

  //   return this.http
  //     .put(this.environment.urlActivityApi + 'Propositions/CreateProposition', proposition, { observe: 'response' })  
  //     .pipe(map((response: HttpResponse<any>) => {
  //         console.log(response.body);
  //         this.authentificationService.headers = this.beforeHeaders;
  //         return response.body;
  //       })
  //     );
  // }

  // public postCancelProposition(idProposition: string): Observable<any> {
  //   this.beforeHeaders = this.authentificationService.headers;
  //   let headers = {
  //     'accept': 'text/plain',
  //     'Content-Type': 'application/json' };

  //   const proposition =  {
  //       "PropositionId": idProposition
  //     }

  //   return this.http
  //     .post(this.environment.urlActivityApi + 'Propositions/CancelProposition', proposition, { observe: 'response' })  
  //     .pipe(map((response: HttpResponse<any>) => {
  //         console.log(response.body);
  //         this.authentificationService.headers = this.beforeHeaders;
  //         return response.body;
  //       })
  //     );
  // }

  // public postPropositionPreReservation(idProposition: string): Observable<any> {
  //   this.beforeHeaders = this.authentificationService.headers;
  //   let headers = {
  //     'accept': 'text/plain',
  //     'Content-Type': 'application/json' };

  //   const proposition =  {
  //       "PropositionId": idProposition
  //     }

  //   return this.http
  //     .post(this.environment.urlActivityApi + 'Propositions/PreReservation', proposition, { observe: 'response' })  
  //     .pipe(map((response: HttpResponse<any>) => {
  //         console.log(response.body);
  //         this.authentificationService.headers = this.beforeHeaders;
  //         return response.body;
  //       })
  //     );
  // }

  // public getParametersOption(): Observable<any> {
  //   this.beforeHeaders = this.authentificationService.headers;
  //   let headers = {
  //     'accept': 'text/plain',
  //     'Content-Type': 'application/json' };

  //   return this.http
  //     .get(this.environment.urlActivityApi + 'Parameters', { observe: 'response' })  
  //     .pipe(map((response: HttpResponse<any>) => {
  //         console.log(response.body);
  //         this.authentificationService.headers = this.beforeHeaders;
  //         return response.body;
  //       })
  //     );
  // }

}