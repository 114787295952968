import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { first, takeUntil } from 'rxjs/operators';
import { AuthentificationService } from 'src/app/shared/services/authentification.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthCasApereoService } from 'src/app/shared/services/auth-cas-apereo.service';
import { AuthStorageService } from 'src/app/shared/services/auth-storage.service';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

interface ObjectSubscription {
  configObservableReloadAuthentificationSSO: Subscription | undefined;
}

@Component({
  selector: 'app-connector-cas-apereo',
  templateUrl: './connector-cas-apereo.component.html',
  styleUrls: ['./connector-cas-apereo.component.scss']
})
export class ConnectorCasApereoComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();

  private objectForSubscribtion: ObjectSubscription = {
    configObservableReloadAuthentificationSSO: undefined,
  }
  
  constructor(private auth: AuthCasApereoService, private authStorage: AuthStorageService, private serviceConfigObservable: ConfigObservableService,
    private router: Router, 
              private authenticationService: AuthentificationService, private userService: UserService, private snackbar: SnackbarService) {
    this.objectForSubscribtion.configObservableReloadAuthentificationSSO = this.serviceConfigObservable.configObservableReloadAuthentificationSSO.subscribe((reloadAuthentificationSSO: any) => {
      this.authStorage.removeToken();
      this.reloadAuthentification();
    });
   }
  // isLoad: boolean = false;

  ngOnInit(): void {
    let url = window.localStorage.getItem('urlInit');
    if (url == null || url == undefined) {
      window.localStorage.setItem('urlInit', window.location.pathname);
    }

    if (!this.auth.isAuthenticated()) {
      this.saveToken();
      this.auth.verifyLogin().then();
    }
    if (!this.auth.isAuthenticated()) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
    // else if (this.auth.isAuthenticated()) {
    //   this.isLoad = false;
    // } else {
    //   this.isLoad = true;
    // }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  
    if (this.objectForSubscribtion.configObservableReloadAuthentificationSSO !== undefined) {
      this.objectForSubscribtion.configObservableReloadAuthentificationSSO.unsubscribe();
    }
  }

  saveToken() {
    let ticket = window.location.hash.replace("#access_token=", '')

    if (ticket) {
      this.authStorage.saveToken(ticket)

      // this.loginWithSSO(ticket);
    }
  }

  getToken() {
    return this.authStorage.getToken();
  }

  reloadAuthentification() {
    if (!this.auth.isAuthenticated()) {
      this.saveToken();
      this.auth.verifyLogin().then();
    }
    if (!this.auth.isAuthenticated()) {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } 
    // else if (this.auth.isAuthenticated()) {
    //   this.isLoad = false;
    // } else {
    //   this.isLoad = true;
    // }
  }

  // loginWithSSO(ticket: string) {
  //   if (ticket) {
  //     // this.authStorage.saveToken(ticket)

  //     let ssoParam = ticket.split('&token_type')[0];
  //     this.authenticationService.getTokenSSO(ssoParam).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: string) => {
  //       console.log(data)
  //       if (data !== null) {
  //         this.userService.getMeReturnValue().pipe(first()).subscribe(
  //           (userData) => {
  //             this.userService.user = userData
  //             if (userData['partnercontact.if_mustchangepassword'] === 0) {
  //               this.router.navigate([ '/log/stock' ]);
  //             }
  //           },
  //           (error) => {
  //             this.snackbar.openSnackBar(
  //               'Votre compte n\'est pas autorisé.', ["custom-style-error"]
  //               );
  //             console.log(error);
              
  //             // this.serviceConfigObservable.emitReloadAuthentificationSSO(true);
  //           }
  //         );
  //       } else {
  //         this.snackbar.openSnackBar(
  //           'Une erreur s\'est produite veuillez vérifier votre Identifiant ou votre Mot de passe', ["custom-style-error"]
  //           );

  //         // this.serviceConfigObservable.emitReloadAuthentificationSSO(true);
  //       }
        
  //     },
  //     (error) => {
  //       this.snackbar.openSnackBar(
  //         'Une erreur s\'est produite veuillez vérifier votre Identifiant ou votre Mot de passe', ["custom-style-error"]
  //         );
  //       console.log(error);

  //       // this.serviceConfigObservable.emitReloadAuthentificationSSO(true);
  //     });
  //   }
  // }

}
