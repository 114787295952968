import { City } from './../models/city';
import { Department } from './../models/department';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { PeriodictElement } from '../models/periodict-element';
import { AuthentificationService } from './authentification.service';
import { environment } from './../../../environments/environment';
import { ConfigObservableService } from './service-config-observable-service.service';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { Region } from '../models/region';

// Interface for array of field input computeur, tablet and mobile
interface fieldInput {
  nameControl: string;
  label: string;
  option: BankGroupCity[];
  list: Array<BankData>;
}

// Interface for list of bank groups for filtered by search keyword for option groups
interface BankData {
    name: string;
    id: {
      name: string;
      id: any;
    }
  }

interface BankCity {
    name: string;
    id: string;
    idRegion: number;
    idDepartment: number;
  }

interface BankGroupCity {
    name: string;
    region: BankCity[]
  }

@Injectable({
  providedIn: 'root'
})
export class ResearchService {
  
  // static URL = environment.urlServer;
  environment: any;

  public referentialStock: any;

  public configObservableNewTabData = new Subject<Array<PeriodictElement>>();

  public configObservableLocationProgram = new Subject<Array<BankGroupCity>>();
  public configObservableLocationClient = new Subject<Array<BankGroupCity>>();
  banksGroupLocationProgram: Array<BankGroupCity> = new Array<BankGroupCity>();
  banksGroupLocationClient: Array<BankGroupCity> = new Array<BankGroupCity>();

  dataSortLocationProgram: Array<any> = [];

  constructor(private http: HttpClient,private authentificationService: AuthentificationService) {
    this.environment = environment;
  }


  public getReferentialHistoryStock(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Referentials/HistoryFilters/InStock', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.referentialStock = response.body.datas
          return response.body;
        })
      );
  }

  public getReferentialStock(productCode?: string): Observable<any> {
    const headers = this.authentificationService.headers;

    let routeApi = 'Referentials/Filters/InStock';
    if (productCode !== undefined) {
      if (productCode !== '') {
        routeApi = 'Referentials/Filters/InStock?ProductCodes=' + productCode;
      }
    }

    return this.http
      .get(this.environment.urlServer + routeApi, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          this.referentialStock = response.body.datas
          return response.body;
        })
      );
  }

  public getResearchSave(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Research', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public deleteResearchSaveWithId(id: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .delete(this.environment.urlServer + 'Research/' + id , { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public postResearch(dataResearchSave: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
			.post(this.environment.urlServer + 'Research', JSON.stringify(dataResearchSave), { headers: headers, observe: 'response' })
			.pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
					return response.body;
				})
      );
  }

  // Method use in research for show type and return boolean
  initTypeIseEmpty( tabProgramResearch: any , fieldResearch: any ) {
    if ( tabProgramResearch[fieldResearch] !== null ) {
      if ( typeof tabProgramResearch[fieldResearch] === "object" ) {
        return tabProgramResearch[fieldResearch].length !== 0;
      } else {
        return tabProgramResearch[fieldResearch] !== "";
      }
    } else {
      return false;
    }
  }

  // Function for get data for mat select
  getLocationProgram(tabProgram: any, namePage?: string) {
    if (namePage === 'research') {
      let tabRegion: Array<Region> = tabProgram.Regions;
      let dataSortCities: any = [];
      let dataSortDepartement: any = [];
      let dataSortRegion: any = [];
      let index = 0;
      tabRegion.forEach((region: Region) => {
        index += 1;
        region.Departments.forEach((department: Department) => {
          department.Cities.forEach((city: City) => {
            dataSortCities.push({ type: 'Ville', name: city.if_name + " (" + city.if_postalcode.substring(0, 2) + ")", id: { type: 'Ville', name: city.if_name, id: city.if_cityid }, groupId: index });
          })
          dataSortDepartement.push({ type: 'Departement', name: department.if_name, id: { type: 'Departement', name: department.if_name, id: department.if_departmentid }, groupId: index });
        })
        dataSortRegion.push({ type: 'Region', name: region.if_name, id: { type: 'Region', name: region.if_name, id: region.if_regionid }, groupId: index });
      });
      if ( namePage === 'research' ) {        
        dataSortCities.sort((a: any,b :any) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
        dataSortDepartement.sort((a: any,b :any) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
        dataSortRegion.sort((a: any,b :any) => (a.name > b.name) ? 1 : ((a.name < b.name) ? -1 : 0));
        Array.prototype.push.apply(dataSortCities,dataSortDepartement);
        Array.prototype.push.apply(dataSortCities,dataSortRegion);
        this.dataSortLocationProgram = dataSortCities;
        
        this.configObservableLocationProgram.next(this.dataSortLocationProgram);
      }
    }
  }


  public configObservableTabInputHome = new Subject<Array<fieldInput>>();
  public configObservabletabInputProject = new Subject<Array<fieldInput>>();
  public configObservabletabInputSale = new Subject<Array<fieldInput>>();
  public configObservableValueForBudget = new Subject<any>();
  tabInputHomeSave: Array<fieldInput> = new Array<fieldInput>();
  valueForBudget: any = {};
  tabInputProjectSave: Array<fieldInput> = new Array<fieldInput>();
  tabInputSaleSave: Array<fieldInput> = new Array<fieldInput>();

  // Init All the last field of the research, field for field------------------------------------------------------------------------------------------------------------------------
  initSelectAll(tabProgramOrClient: any, tabInput: Array<fieldInput>, fieldValid: Array<any>,  namepPage?: string) {

    if ( namepPage === 'research' ) {
      // if ( this.tabInputHomeSave.length >= 1 ) {
      //   this.configObservableTabInputHome.next(this.tabInputHomeSave);
      //   this.configObservableValueForBudget.next(this.valueForBudget);
      // } else  {
        if ( tabInput.find(field => field.nameControl === 'delivery' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'delivery' )
          let tabDelivery: Array<any> = tabProgramOrClient.DeliveryDates;
          tabDelivery.forEach((dataDelivery: any) => {
            let dateDeliveryTrim = this.getQuarter(dataDelivery);
            if ( !tabInput[tabInput.indexOf(field[0])].list.find(field => field.name === dateDeliveryTrim) ) {
              tabInput[tabInput.indexOf(field[0])].list.push({ name: dateDeliveryTrim, id: { name: dateDeliveryTrim, id: dateDeliveryTrim } });
            }
          });
        }

        if ( tabInput.find(field => field.nameControl === 'daact' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'daact' )
          let tabDelivery: Array<any> = tabProgramOrClient.CompletionDates;
          tabDelivery.forEach((dataCompletionDates: any) => {
            let dateTrim = this.getQuarter(dataCompletionDates)
            if ( !tabInput[tabInput.indexOf(field[0])].list.find(field => field.name === dateTrim) ) {
              tabInput[tabInput.indexOf(field[0])].list.push({ name: dateTrim, id: { name: dateTrim, id: dateTrim } });
            } 
          });
        }

        if ( tabInput.find(field => field.nameControl === 'nature' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'nature' )
          let tabNature: Array<any> = tabProgramOrClient.AssetNatures;
          tabNature.forEach((dataNature: any) => {
            tabInput[tabInput.indexOf(field[0])].list.push({name: dataNature.if_fluxsiteb2blabel, id: {name: dataNature.if_fluxsiteb2blabel, id: dataNature.if_fluxsiteb2bcode }});
          });
        }

        if ( tabInput.find(field => field.nameControl === 'active' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'active' )
          let tabActability: Array<any> = tabProgramOrClient.ActabilityDates;

         
          if (this.environment.subModuleUse !== 'submodule_Stellium') {
            tabActability.forEach((dataActability: any) => {
              let dataActabilityTrim = this.getQuarter(dataActability)
              if ( !tabInput[tabInput.indexOf(field[0])].list.find(field => field.name === dataActabilityTrim) ) {
                tabInput[tabInput.indexOf(field[0])].list.push({ name: dataActabilityTrim, id: { name: dataActabilityTrim, id: dataActabilityTrim } });
              }
            });
          } else {
            tabInput[tabInput.indexOf(field[0])].list.push({ name: 'Tous', id: { name: 'Tous', id: false } });
            tabInput[tabInput.indexOf(field[0])].list.push({ name: 'Oui', id: { name: 'Oui', id: true } });
          }
        }

        if ( tabInput.find(field => field.nameControl === 'promoter' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'promoter' )
          let tabPromoter: Array<any> = tabProgramOrClient.Promoteurs;
          tabPromoter.forEach((dataPromoter: any) => {
            tabInput[tabInput.indexOf(field[0])].list.push({ name: dataPromoter.if_operationdeveloper_label, id: { name: dataPromoter.if_operationdeveloper_label, id: dataPromoter.if_operationdeveloper } });
          });
        }

        if ( tabInput.find(field => field.nameControl === 'taxSystem' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'taxSystem' )
          let tabTaxSystem: Array<any> = tabProgramOrClient.OperationTaxLaws;
          if (tabTaxSystem.length !== 0 ) {
            tabTaxSystem.forEach((dataTaxSystem: any) => {
              tabInput[tabInput.indexOf(field[0])].list.push({ name: dataTaxSystem.if_fluxsiteb2blabel, id: { name: dataTaxSystem.if_fluxsiteb2blabel, id: dataTaxSystem.if_fluxsiteb2bcode } });
            });
          } else {
            tabInput[tabInput.indexOf(field[0])].list.push({ name: 'Pas de donnée', id: { name: '', id: '' } });
          }
        }

        if ( tabInput.find(field => field.nameControl === 'taxSystem_asset' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'taxSystem_asset' )
          let tabTaxSystem: Array<any> = tabProgramOrClient.AssetTaxLaws;
          if (tabTaxSystem.length !== 0 ) {
            tabTaxSystem.forEach((dataTaxSystem: any) => {
              tabInput[tabInput.indexOf(field[0])].list.push({ name: dataTaxSystem.if_fluxsiteb2blabel, id: { name: dataTaxSystem.if_fluxsiteb2blabel, id: dataTaxSystem.if_fluxsiteb2bcode } });
            });
          } else {
            tabInput[tabInput.indexOf(field[0])].list.push({ name: 'Pas de donnée', id: { name: '', id: '' } });
          }
        }


        if ( tabInput.find(field => field.nameControl === 'typology' ) ) {
          let field: fieldInput[] = tabInput.filter(field => field.nameControl === 'typology' )
          let tabTypology: Array<any> = tabProgramOrClient.AssetTypes;
          tabTypology.forEach((dataTypology: any) => {
            tabInput[tabInput.indexOf(field[0])].list.push({ name: dataTypology.if_fluxsiteb2blabel, id: { name: dataTypology.if_fluxsiteb2blabel, id: dataTypology.if_fluxsiteb2bcode } });
          });
        }

        this.tabInputHomeSave = tabInput;
        
        this.configObservableTabInputHome.next(this.tabInputHomeSave);
        
        if (tabProgramOrClient.Prices !== undefined) {
          this.valueForBudget = {
            tabControl: [tabProgramOrClient.Prices.PriceMin, tabProgramOrClient.Prices.PriceMax],
            optionsSlider: {
              floor: ( Math.ceil(tabProgramOrClient.Prices.PriceMin) ),
              ceil: ( Math.round(tabProgramOrClient.Prices.PriceMax) )
            },
            minValue: Math.ceil(tabProgramOrClient.Prices.PriceMin),
            highValue: Math.round(tabProgramOrClient.Prices.PriceMax)
          }
          this.configObservableValueForBudget.next(this.valueForBudget);
        }
      // }
    }
  }

  getQuarter(d: string) {
    var newDate = new Date(d);
    var m = Math.floor(newDate.getMonth() / 3) + 1;
    m -= m > 4 ? 4 : 0;
    var y = newDate.getFullYear();
    return m + "è Trimestre " + y;
  }

}
