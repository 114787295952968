
<!-- <div class="main">
    <img id="map" class="imagePlanLot" src="{{ srcPdf }}" onerror="this.src='assets/defaultimage.png'">
</div> -->

<div class="content-wrapper">
    <div [ngClass]="{ 'containerBtnPopupPlan': data.size >= 2, 'containerBtnPopupPlanSM': data.size < 2 }">
        <!-- <mat-icon class="iconDownload" aria-hidden="false" aria-label="Example user verified icon" (click)="downloadDocument()">get_app</mat-icon>
        <mat-icon class="iconZoomPlus" aria-hidden="false" aria-label="Example user verified icon" (click)="zoomin()">zoom_in</mat-icon>
        <mat-icon class="iconZoomMoins" aria-hidden="false" aria-label="Example user verified icon" (click)="zoomout()">zoom_out</mat-icon> -->
        <mat-icon mat-dialog-close class="iconClose" aria-hidden="false" aria-label="Example user verified icon">close</mat-icon>
    </div>
    <!-- <ejs-pdfviewer id="pdfViewer" [serviceUrl]='service' [documentPath]='document' style="height:640px;display:block"></ejs-pdfviewer> -->
    <img id="map" class="imagePlanLot" src="../../../../../Resources/{{ data.image }}" onerror="this.src='assets/defaultimage.png'">
</div>