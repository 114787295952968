import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatHoursPipe'
})
export class DateFormatHoursPipe implements PipeTransform {

  transform(value: Date) {
    console.log(value);
    let newValue: any = '';
    let datePipe = new DatePipe("fr-FR");
    newValue = datePipe.transform(value, 'H:mm');
    return newValue
  }

}
