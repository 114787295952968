import { environment } from './../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthentificationService } from './authentification.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class EripService {

  beforeHeaders: HttpHeaders = new HttpHeaders();

  headers: HttpHeaders = new HttpHeaders();

  /** @param connectedErip Boolean to check if the erip will be displayed on the website. The information is include on the user profile */
  isEripActivated: boolean = false;

  /** @param isEripEnabledForCurrentSubModule check if Erip feature is enabled for the current SubModule */
  isEripEnabledForCurrentSubModule: boolean = false;

  isConnected: Boolean = false;
  readOnlyFields: Array<String> = [];

  environment: any = null;

  constructor(private http: HttpClient, private userService: UserService, private authentificationService: AuthentificationService) {
    this.environment = environment;

    if (this.environment.subModuleUse == 'submodule_Inovia' || this.environment.subModuleUse == 'submodule_Quintesens') {
      this.isEripEnabledForCurrentSubModule = true;
      if (this.environment.urlEripApi != '' && this.environment.urlEripApi != "" && this.environment.urlEripApi != null && this.environment.urlEripApi != undefined) {
        this.GetEripReadOnlyFields().pipe().subscribe((success: any) => {
          console.log(success)
          // success.body.map((e: any) => this.readOnlyFields.push(e.if_factory_fieldname))
          this.readOnlyFields = success.body.map((x: any) => x.if_factory_fieldname)
        });
      }
    }
  }

  public getEripIsConnected(): Observable<any> {

    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }

    return this.http
      .get(this.environment.urlEripApi + 'Users/' + this.userService.user['partnercontact.if_partnercontactid'], {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map((response: HttpResponse<any>) => {
        console.log(response.body);
        if (this.beforeHeaders.get('Authorization') !== null) {
          this.authentificationService.headers = this.beforeHeaders;
        }
        this.isConnected = response.body
        return response.body;
      })
      );
  }


  public IsFieldIsLocked(fieldName: string): Boolean {
    return this.readOnlyFields.includes(fieldName);
  }


  // public LoadAndSynchronisedEripId(eripId: string, projectId: string): Observable<any> {

  //   if (this.authentificationService.headers.get('Authorization') !== null) {
  //     this.beforeHeaders = this.authentificationService.headers;
  //   }

  //   return this.http
  //     .post(this.environment.urlEripApi + 'Erips/LoadAndSynchronised', JSON.stringify({ "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'], "EripId": eripId, "ProjectId": projectId }), {
  //       headers: new HttpHeaders({
  //         'Accept': 'text/plain',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.environment.tokenErip
  //       }), observe: 'response'
  //     })
  //     .pipe(map((response: HttpResponse<any>) => {
  //       console.log(response.body);
  //       if (this.beforeHeaders.get('Authorization') !== null) {
  //         this.authentificationService.headers = this.beforeHeaders;
  //       }
  //       return response.body;
  //     })
  //     );
  // }

  // public IsConnectedEripReplacePassword(password: string): Observable<any> {

  //   if (this.authentificationService.headers.get('Authorization') !== null) {
  //     this.beforeHeaders = this.authentificationService.headers;
  //   }

  //   return this.http
  //     .post(this.environment.urlEripApi + 'Users/IsConnectedEripReplacePassword', JSON.stringify({ "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'], "Password": password }), {
  //       headers: new HttpHeaders({
  //         'Accept': 'text/plain',
  //         'Content-Type': 'application/json',
  //         'Authorization': 'Bearer ' + this.environment.tokenErip
  //       }), observe: 'response'
  //     })
  //     .pipe(map((response: HttpResponse<any>) => {
  //       console.log(response.body);
  //       if (this.beforeHeaders.get('Authorization') !== null) {
  //         this.authentificationService.headers = this.beforeHeaders;
  //       }
  //       this.isConnected = response.body
  //       return response.body;
  //     })
  //     );
  // }

  public TestUserEripConnection() {
    return this.http
      .get(this.environment.urlEripApi + `Erips/TestERIPConnection?clientGuid=${this.userService.user['partnercontact.if_partnercontactid']}`, {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map(res => {
        return res
      }));
  }

  public IsUserCanAccessToEripPlatform() {
    return this.http
      .get(this.environment.urlEripApi + `Erips/IsUserCanAccessToEripPlatform?clientGuid=${this.userService.user['partnercontact.if_partnercontactid']}`, {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map(res => {
        return res
      }));

  }

  public UpdateEripPassword(newPassword: string) {
    var bodyData = JSON.stringify({
      PartnerContactGuid: this.userService.user['partnercontact.if_partnercontactid'],
      NewPassword: newPassword
    });
    return this.http.post(this.environment.urlEripApi + "Erips/UpdateERIPPassword", bodyData, {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.environment.tokenErip
      }), observe: 'response'
    }).pipe(map(res => {
      return res;
    }));
  }

  public GetEripsInformationsByEripGuid(eripGuid: string) {
    var bodyData = JSON.stringify({
      PartnerContactId: this.userService.user['partnercontact.if_partnercontactid'],
      EripId: eripGuid
    });
    return this.http.post(this.environment.urlEripApi + "Erips/GetId", bodyData, {
      headers: new HttpHeaders({
        'Accept': 'text/plain',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.environment.tokenErip
      }), observe: 'response'
    }).pipe(map(res => {
      return res;
    }));

  }

  public IsFieldIsReadOnly(fieldName: string): Boolean {
    return true;
  }

  public createErip(projectId: string, Civility: string, FirstName: string, LastName: string): Observable<any> {
    let jsonSend = {
      "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'],
      "ProjectId": projectId,
      "Civility": Civility,
      "FirstName": FirstName,
      "LastName": LastName
    }


    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }

    return this.http
      .post(this.environment.urlEripApi + 'Erips/', JSON.stringify(jsonSend), {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map((response) => {
        // console.log(response.body);
        // if (this.beforeHeaders.get('Authorization') !== null) {
        //   this.authentificationService.headers = this.beforeHeaders;
        // }
        // return response.body.result;
        return response;
      })
      );
  }


  public FindOrCreateErip(projectId: string) {
    let jsonSend = {
      "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'],
      "ProjectId": projectId,
      "Civility": "",
      "FirstName": "",
      "LastName": ""
    }
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    return this.http
      .post(this.environment.urlEripApi + 'Erips/FindOrCreateErip', JSON.stringify(jsonSend), {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map((response) => {
        return response;
      })
      );
  }

  public CreateEripProject(projectId: string) {
    // let jsonSend = {
    //   "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'],
    //   "ProjectId": projectId,
    //   "Civility": "",
    //   "FirstName": "",
    //   "LastName": ""
    // }
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    return this.http
      .get(this.environment.urlEripApi + `FactoryErip/CreateNewErip?ProjectId=${projectId}`, {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map((response) => {
        return response;
      })
      );
  }

  public SynchronizeFactoryEripObject(eripGuid: string) {
    // let jsonSend = {
    //   "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'],
    //   "EripId": eripGuid
    // }
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    return this.http
      .get(this.environment.urlEripApi + `FactoryErip/SyncErip?FactoryEripGuid=${eripGuid}`, {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map((response) => {
        return response;
      })
      );
  }

  public UpdateErip(eripGuid: string) {
    // let jsonSend = {
    //   "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'],
    //   "EripId": eripGuid
    // }
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    return this.http
      .get(this.environment.urlEripApi + `FactoryErip/UpdateErip?FactoryEripGuid=${eripGuid}`, {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip,
        }),
        observe: 'response',
        responseType: 'text'
      });
  }


  public GetEripReadOnlyFields() {
    // let jsonSend = {
    //   "PartnerContactId": this.userService.user['partnercontact.if_partnercontactid'],
    //   "EripId": eripGuid
    // }
    if (this.authentificationService.headers.get('Authorization') !== null) {
      this.beforeHeaders = this.authentificationService.headers;
    }
    return this.http
      .get(this.environment.urlEripApi + `Erips/GetEripReadOnlyFields`, {
        headers: new HttpHeaders({
          'Accept': 'text/plain',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.environment.tokenErip
        }), observe: 'response'
      })
      .pipe(map((response) => {
        return response;
      })
      );
  }

  // public connexionApiErip(): Observable<any> {
  //   // const headers = this.authentificationService.headers;

  //   var jsonLogin = {"login": "benjamin@inovia-group.fr", "password":"Bde59130!ER"};

  //   let get = fetch(this.environment.urlEripApi + 'login', {
  //     method: "POST",
  //     headers: [
  //       ["Content-Type", "application/json"],
  //       ["Content-Type", "text/plain"],
  //       ['Access-Control-Allow-Origin', 'e-rip.fr'],
  //       // ['Origin', 'https://e-rip.fr'],
  //       // ['Referer', 'https://e-rip.fr/login']
  //     ],
  //     credentials: "include",
  //     body: JSON.stringify(jsonLogin)
  //   });

  //   console.log(get)



  //   // })

  //   // (async () => {
  //   //   const response = await fetch(this.environment.urlEripApi + 'Login', JSON.stringify(jsonLogin));
  //   //   const text = await response.text();
  //   //   console.log(text);
  //   // })();



  //   const headers= new HttpHeaders({
  //     'content-type': 'application/json',
  //     'Access-Control-Allow-Origin': 'e-rip.fr',
  //     // 'Origin': 'https://e-rip.fr',
  //     // 'Referer': 'https://e-rip.fr/login'
  //   })

  //   return this.http
  // 		.post(this.environment.urlEripApi + 'login', JSON.stringify(jsonLogin), { observe: 'response' })
  // 		.pipe(map((response: HttpResponse<any>) => {
  //         console.log(response);
  //         // var blob = new Blob([response.body], { type: mediaType });
  //         // saveAs(blob, 'report.pdf');
  // 				return response.body;
  // 			})
  //     );
  // }
  // // https://e-rip.fr/api/login

}
