import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  
  environment: any = null;

	constructor(private http: HttpClient, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public postReservation(dataResearchSales: any): Observable<any> {
    const headers = this.authentificationService.headers;

   let test = { "ContactNames": [
      {
        "Firstname": "string",
        "Lastname": "string"
      }
    ],
    "OperationIds": [
      "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    ],
    "OperationDeveloperIds": [
      "3fa85f64-5717-4562-b3fc-2c963f66afa6"
    ]
  }

    return this.http
      .post(this.environment.urlServer + 'Reservations', dataResearchSales, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getReservationIdActivities(id: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Reservations/' + id + '/Activities' , { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getReservationIndicators(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Reservations/Indicators', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body.datas;
        })
      );


  }





  public getSalesReservations(dataResearchSales: any): Observable<any> {
    const headers = this.authentificationService.headers;

      return this.http
      .post(this.environment.urlServer + 'Sales/Reservations/Search', dataResearchSales, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getSalesReservationsIndicators(dataResearchSales: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'Sales/Reservations/SearchCount',dataResearchSales, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          return response.body;
        })
      );
  }


}
