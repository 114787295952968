<!-- Container title popup with mat dialog title (new project, deduplication contact and rule project) -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="activePlacement" [ngClass]="{ 'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Convertir en projet de Placement </h1>   
            <h1 *ngIf="!activePlacement" [ngClass]="{ 'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Convertir en projet Immobilier </h1>        
        </div>
        <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup deduplication contact -->
<mat-dialog-content class="containerAssociateContact">
    <div class="containerCheckboxContact">
        <p class="titleDeduplication">Voulez-vous convertir le type de ce projet ?</p>
        <p class="warningConvertProject">Les critères projet spécifiques seront supprimés</p>       
    </div>
    <div class="containerBtnAssociateContact">
        <button class="btnCancelAssociateContact" [ngClass]="{ 'btnPostOptionSM': data.size < 2 }" (click)="closeConvertProject()">Annuler</button>
        <button class="btnAssociateContact" [ngClass]="{ 'btnPostOptionSM': data.size < 2 }" (click)="convertProject()">Confirmer</button>
    </div>
</mat-dialog-content>