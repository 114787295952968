import { NameBtnLegalNotice } from '../../shared/models/Footer/name-btn-legal-notice';
import { LegalNoticeService } from './../../shared/services/legal-notice.service';
import { SCREEN_SIZE } from './../../size-detector/screen-size.enum';
import { OperationService } from './../../shared/services/operation.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { Router } from '@angular/router';
import { initSizeScreen } from 'src/app/shared/services/functions';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

interface ObjectSubscription {
  configObservableSendHiddenFooter: Subscription | undefined;
  configObservableNameBtnLegalNotice: Subscription | undefined;
}

@Component({
  selector: 'app-footer-all-page',
  templateUrl: './footer-all-page.component.html',
  styleUrls: ['./footer-all-page.component.scss']
})
export class FooterAllPageComponent implements OnInit, OnDestroy {

  private objectForSubscribtion: ObjectSubscription = {
    configObservableSendHiddenFooter: undefined,
    configObservableNameBtnLegalNotice: undefined
  }

  // Init size screen for desktop
  size: SCREEN_SIZE = 4;

  // Activate different part of the footer
  showNetworkSocial: Boolean = true;
  showConnected: Boolean = false;
  showEndPage: Boolean = true;
  iconReturn: Boolean = true;

  subModuleUse: string = '';

  nameSocietyChoice: string = '';

  nameBtnLegalNotice: NameBtnLegalNotice = new NameBtnLegalNotice();

  // Contsructor get part footer activate and observe if the footer must not be activated or must be activated
  constructor(location: Location, private router: Router, private serviceConfigObservable: ConfigObservableService,
              private serviceOperation: OperationService, private serviceLegalNotice: LegalNoticeService) {
    this.subModuleUse = environment.subModuleUse;
    if (this.subModuleUse == 'submodule_Quintesens') {
      this.nameSocietyChoice = 'QUINTESENS';
    } else if (this.subModuleUse == 'submodule_Inovia') {
      this.nameSocietyChoice = 'INOVIA';
    } else if (this.subModuleUse == 'submodule_Quartus') {
      this.nameSocietyChoice = 'QUARTUS';
    } else if (this.subModuleUse == 'submodule_Edelis') {
      this.nameSocietyChoice = 'EDELIS';
    } else if (this.subModuleUse == 'submodule_Stellium') {
      this.nameSocietyChoice = 'STELLIUM';
    }

    router.events.subscribe(val => {
      // console.log(location.path())
      // console.log(location.path() != "/registration")
      if (location.path().includes('/log')) {
        if (this.size <= 1) {
          this.showNetworkSocial = false;
          this.iconReturn = false;
        } else {
          this.showNetworkSocial = true;
        }
        this.showConnected = true;
        this.iconReturn = false;
        this.showEndPage = false;
      } else {
        if (this.size <= 1) {
          this.iconReturn = false;
        }
        this.showNetworkSocial = true;
        this.showConnected = false;
        this.showEndPage = true;
      }
    });
    this.objectForSubscribtion.configObservableSendHiddenFooter = this.serviceConfigObservable.configObservableSendHiddenFooter.subscribe((dataChoice: Boolean) => {
      if (this.size <= 1) {
        this.showNetworkSocial = false;
        this.iconReturn = false;
      }
      this.serviceOperation.showFooter = dataChoice;
      this.showEndPage = dataChoice;
    })

    this.objectForSubscribtion.configObservableNameBtnLegalNotice = this.serviceConfigObservable.configObservableNameBtnLegalNotice.subscribe((nameBtnLegalNotice: NameBtnLegalNotice) => {
      this.nameBtnLegalNotice = nameBtnLegalNotice;
    })
  }


  // Init size with function in services/function.ts
  ngOnInit(): void {
    this.size = initSizeScreen();
  }

  ngOnDestroy() {
    if (this.objectForSubscribtion.configObservableSendHiddenFooter !== undefined) {
      this.objectForSubscribtion.configObservableSendHiddenFooter.unsubscribe();
    }

    if (this.objectForSubscribtion.configObservableNameBtnLegalNotice !== undefined) {
      this.objectForSubscribtion.configObservableNameBtnLegalNotice.unsubscribe();
    }
  }

  gotoPrivacyPolicy(){
    this.router.navigate(['/log/privacyPolicy']);  // define your component where you want to go
  }

  gotoLegalNotice(){
    this.router.navigate(['/log/legalNotice']);  // define your component where you want to go
  }

  gotoCgv(){
    this.router.navigate(['/log/cgv']);  // define your component where you want to go
  }

  gotoCgu(){
    this.router.navigate(['/log/cgu']);  // define your component where you want to go
  }

  // Function return to the header page
  returnStartPage() {
    window.scrollTo(0, 0);
  }

}
