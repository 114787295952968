export class PopupCreateTask {
    public "size"!: number;
    public "namePopup"!: string;
    public "actiavtionCalendar"!: boolean;

    public "title"!: string;
    public "composant"!: Array<any>;
    public "id"!: string;
    // [{
    //     name: string;
    //     data: any;
    //     label: string;
    //     // selectConcernant: {
    //     //     multiple: boolean,
    //     //     activation: boolean,
    //     //     readonly: boolean,
    //     //     data: any,
    //     //     label: string
    //     // },



        
    // }];
}
