<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="namePopup === 'signElec'|| namePopup === 'signDER'" [ngClass]="{'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Envoyer en signature électronique</h1>
            <h1 *ngIf="namePopup === 'resaPaper'" [ngClass]="{'titlePopup': data.size >= 2, 'titlePopupSM': data.size < 2 }">Déclarer la réservation format papier</h1>
        </div>
        <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup electronic signature -->
<mat-dialog-content *ngIf="namePopup === 'signElec'" class="containerSale" [ngClass]="{ 'containerPostOptionSM': data.size < 2 }">
    <p [ngClass]="{'paragrapheSale': data.size >= 2, 'paragrapheSaleSM': data.size < 2}">{{ data.dataPopup.PROJET }} – {{ data.dataPopup.zipCode }} {{ data.dataPopup.VILLE }}<br>
        {{ data.dataPopup.MAIL }} – {{ data.dataPopup.tel2 }}<br><br>
        {{ data.dataPopup.PROGRAMME }} – {{ data.dataPopup.LOT }} / {{ data.dataPopup.TYPE }} / {{ data.dataPopup.SHAB }} – {{ data.dataPopup.PROMOTEUR }}<br>
    Prix TTC : {{ data.dataPopup.budgetTTC }} TTC
    </p>
    <form [ngClass]="{'saleForm': data.size >= 2, 'saleFormSM': data.size < 2}"  [formGroup]="insertSignElecForm" (ngSubmit)="onSubmitSignElecFormDocuSign()">
        <table cellspacing="0">
            <tr [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                <!-- <td>
                    <mat-checkbox class="colorCheckbox" formControlName="conformity">
                        <span [ngClass]="{'labelCheckbox': data.size >= 2, 'labelCheckboxSM': data.size < 2}">Les données clients sont complètes et conformes</span>
                    </mat-checkbox>
                    <mat-checkbox class="colorCheckbox" formControlName="acceptSign">
                        <span [ngClass]="{'labelCheckbox': data.size >= 2, 'labelCheckboxSM': data.size < 2}">Le client accepter d’effectuer une signature électronique</span>
                    </mat-checkbox>
                </td> -->
            </tr>
        </table>
        <div class="containerBtn">
            <button mat-dialog-close class="btnCancel">Annuler</button>
            <button class="btnSendValidate" type="submit">Ouvrir Docu Sign.</button>
        </div>
    </form>
</mat-dialog-content>

<!-- Container content popup electronic signature DER -->
<mat-dialog-content *ngIf="namePopup === 'signDER'" class="containerSale" [ngClass]="{ 'containerPostOptionSM': data.size < 2 }">
    <p [ngClass]="{'paragrapheSale': data.size >= 2, 'paragrapheSaleSM': data.size < 2}">{{ data.dataPopup.CLIENT }} – {{ data.dataPopup.zipCode }} {{ data.dataPopup.VILLE }}<br>
        {{ data.dataPopup.MAIL }} – {{ data.dataPopup.TEL }}<br><br>
    </p>
    <form [ngClass]="{'saleForm': data.size >= 2, 'saleFormSM': data.size < 2}" [formGroup]="insertSignElecForm" (ngSubmit)="onSubmitSignElecForm()">
        <!-- <table cellspacing="0">
            <tr [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                <td>
                    <mat-checkbox class="colorCheckbox" formControlName="conformity">
                        <span [ngClass]="{'labelCheckbox': data.size >= 2, 'labelCheckboxSM': data.size < 2}">Les données clients sont complètes et conformes</span>
                    </mat-checkbox>
                    
                    <mat-checkbox class="colorCheckbox" formControlName="acceptSign">
                        <span [ngClass]="{'labelCheckbox': data.size >= 2, 'labelCheckboxSM': data.size < 2}">Le client accepter d’effectuer une signature électronique</span>
                    </mat-checkbox>
                </td>
            </tr>
        </table> -->
        <div class="containerBtn">
            <button mat-dialog-close class="btnCancel">Annuler</button>
            <button class="btnSendValidate" type="submit">Valider l’envoi</button>
        </div>
    </form>
</mat-dialog-content>





<!-- Container content popup electronic signature DER -->
<mat-dialog-content *ngIf="namePopup === 'resaPaper'" class="containerSale" [ngClass]="{ 'containerPostOptionSM': data.size < 2 }">
    <p class="paragrapheSaleResaPaper">La déclaration d'une signature de Contrat de réservation en format papier va créer une pré-réservation dans le système. Cela a pour effet de figer l'option et ne plus permettre la pose d'option sur ce lot.
        <br>La validation de la pré-réservation en Réservation sera  effectuée par le Back office à réception du document papier.
    </p>
    <form [ngClass]="{'saleForm': data.size >= 2, 'saleFormSM': data.size < 2}"  [formGroup]="insertSignElecForm" (ngSubmit)="onSubmitPreResa()">
        <table cellspacing="0">
            <tr [ngClass]="{ 'containerTableRowSM': data.size < 2 }">
                <td>
                    <p [ngClass]="{'paragrapheResponseResa': data.size >= 2, 'paragrapheResponseResaSM': data.size < 2 }">
                        Projet : <span class="responseResa">Projet {{ data.dataPopup.PROJET }}</span><br>
                        Programme : <span class="responseResa">{{ data.dataPopup.PROGRAMME }}</span><br>
                        Lot : <span class="responseResa">{{ data.dataPopup.LOT }}</span><br>
                        Option : <span class="responseResa">Du {{ data.dataPopup.startOption }} au {{ data.dataPopup.endOption }}</span><br>
                        <!-- Date du R3 : <span class="responseResa">Demande de champ</span> -->
                    </p> 
                </td>
            </tr>
        </table>
        <div class="containerBtn">
            <button mat-dialog-close class="btnCancelResa" type="button">Annuler</button>
            <button class="btnSendValidateResa" type="submit">Declarer la pre-resa</button>
        </div>
    </form>
</mat-dialog-content>