<!-- Icon button ==> Return header page -->
<button *ngIf="showConnected && showEndPage && iconReturn" class="returnStartPage" (click)="returnStartPage()">
    <mat-icon class="iconReturn" aria-hidden="false" aria-label="Example user verified icon">expand_less</mat-icon>
</button>

 <!-- Container social network and redirect to page social network -->
<!-- <div *ngIf="showEndPage && showNetworkSocial" class="footerSocialNetwork" [ngClass]="{'footerSocialNetworkXS': size === 0}">
    <h1 class="titleSocialNetwork" [ngClass]="{'titleSocialNetworkSM': size <= 1 }">Suivez-Nous</h1>
    <div class="containerIconSocialNetwork" [ngClass]="{'containerIconSocialNetworkSM': size <= 1 }">
        <a><img class="iconSimple" [ngClass]="{'iconSimpleSM': size <= 1 }" src="../../../../assets/facebook.svg" /></a>
        <a><img class="iconSimple" [ngClass]="{'iconSimpleSM': size <= 1 }" src="../../../../assets/instagram.svg" /></a>
        <a><img class="iconSimple" [ngClass]="{'iconSimpleSM': size <= 1 }" src="../../../../assets/twitter-sign.svg" /></a>
        <a><img class="iconSimple" [ngClass]="{'iconSimpleSM': size <= 1 }" src="../../../../assets/linkedin-logo.svg" /></a>
        <a><img class="iconSimple" [ngClass]="{'iconSimpleSM': size <= 1 }" src="../../../../assets/youtube.svg" /></a>
    </div>
</div> -->

<!-- Container footer helper page -->
<div *ngIf="showConnected && showEndPage" class="footer">
        <img class="pictoFooter" src="../../../../{{ subModuleUse }}/assets/Logo_carré_blanc.png" />
        <p class="paragrapheFooter">
            <a (click)="gotoLegalNotice()">{{ nameBtnLegalNotice.ml_name }}</a> | <a (click)="gotoCgv()">{{ nameBtnLegalNotice.cgv_name }}</a> | <a (click)="gotoCgu()">{{ nameBtnLegalNotice.cgu_name }}</a> | <a (click)="gotoPrivacyPolicy()">{{ nameBtnLegalNotice.pc_name }}</a>
        </p>
        <a class="titleSociety">{{ nameSocietyChoice }} – © INOVIA GROUP</a><br>
</div>