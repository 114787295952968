<div class="containerPopupSalesOffers">
    <!-- Container title popup with mat dialog title -->
    <div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
        <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSM': objectReceivePopup.size < 2 }">
            <div class="containerTitleImg">
                <img *ngIf="subModuleUse === 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/imageLogoHeader.png">
                <img *ngIf="subModuleUse !== 'submodule_Edelis'" alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../../{{ subModuleUse }}/assets/picto-popup.png">
                <div class="ml-auto">
                    <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup">{{ objectReceivePopup.title }}</h1>  
                    <h2 class="subtitlePopup">{{ objectReceivePopup.subtitle }}</h2>    
                </div>
            <div>
        </div>
    </div>
    
    <!-- Container content popup cancel option -->
    <mat-dialog-content *ngIf="objectReceivePopup.namePopup !== 'salesOffers_pageDescriptionProgramme'" class="containerMatContent" [style]="'background:' + this.objectReceivePopup.backgroundColorContent + '; color:' + this.objectReceivePopup.colorContent">
        <div class="containerContent">
            <div class="mb-3 titleContent" [innerHTML]="this.objectReceivePopup.dataPopup.titleSalesOffers"></div>
            <div class="textContent" [innerHTML]="this.objectReceivePopup.dataPopup.dateSalesOffers"></div>
            <span>Descriptif de l’offre</span>
            <div class="textContent" [innerHTML]="this.objectReceivePopup.dataPopup.decriptionSalesOffers"></div>
            <span>Mentions légales</span>
            <div class="textContent" [innerHTML]="this.objectReceivePopup.dataPopup.legalNoticeSalesOffers"></div>
        </div>
    </mat-dialog-content>
    
    <mat-dialog-content *ngIf="objectReceivePopup.namePopup === 'salesOffers_pageDescriptionProgramme'" class="containerMatContent" [style]="'background:' + this.objectReceivePopup.backgroundColorContent + '; color:' + this.objectReceivePopup.colorContent">
        <div class="containerContent" *ngFor="let saleOffers of this.objectReceivePopup.dataPopup.listAllSalesOffers; let i = index;">
            <hr class="separationOffer" *ngIf="i !== 0">
            <div class="mb-3 titleContent" [innerHTML]="saleOffers.titleSalesOffers"></div>
            <div class="textContent" [innerHTML]="saleOffers.dateSalesOffers"></div>
            <span>Descriptif de l’offre</span>
            <div class="textContent" [innerHTML]="saleOffers.decriptionSalesOffers"></div>
            <span *ngIf="saleOffers.legalNoticeSalesOffers !== null">Mentions légales</span>
            <div class="textContent" [innerHTML]="saleOffers.legalNoticeSalesOffers"></div>
        </div>
    </mat-dialog-content>
    <div class="containerAction">
        <button mat-dialog-close class="btnAction">OK</button>
    </div>
</div>