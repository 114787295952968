import { AuthentificationService } from 'src/app/shared/services/authentification.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class TrackedWorksiteService {

  environment: any = null;

	constructor(private http: HttpClient, private serviceAuthentification: AuthentificationService) {
    this.environment = environment;
  }

  public getTrackedWorksiteOld(OperationId: string): Observable<any> {
    return this.http
      .post(
        this.environment.urlServer + 'SuiviChantier/GetTopSuiviChantiers', {"OperationId": OperationId,"selectTop":"1"}, { headers: this.serviceAuthentification.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public generateTrackedWorksite(id: string, nameDocument: string, data: any): Observable<any>{
    const headers = this.serviceAuthentification.headers;
    var mediaType = 'application/pdf';
    
    return this.http
      .post(this.environment.urlServer + 'Operations/' + id + '/WorkSites/Pdf', data, { headers: headers, observe: 'response', responseType: 'blob'  })
      .pipe(map((response: HttpResponse<any>) => {
        var blob = new Blob([response.body], { type: mediaType });
        saveAs(blob, nameDocument);
        return response.body;
      })
    );
  }

  public getTrackedWorksite(id: string): Observable<any> {

    const headers = this.serviceAuthentification.headers;

    return this.http
      .get(this.environment.urlServer + 'Operations/' + id + '/WorkSites' , { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }

  public getAllExistTrackedWorksite(OperationId: string): Observable<any> {
    return this.http
      .post(
        this.environment.urlServer + 'SuiviChantier/NewGetSuiviChantiers', {"OperationId": OperationId }, { headers: this.serviceAuthentification.headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }
}
