import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { environment, myService } from 'src/environments/environment';


import { Component, Inject, OnInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Interface for receive several data in the popup get of parent component
export interface DialogDataPopup {
  size: number;
  namePopup: string;
  dataPopup: any;
}

@Component({
  selector: 'app-popup-loading',
  templateUrl: './popup-loading.component.html',
  styleUrls: ['./popup-loading.component.scss']
})
export class PopupLoadingComponent implements OnInit {

  // Declaration & Init name popup get of parents
  namePopup = this.data.namePopup;

  // Declaration & Init title loading
  loadingTitle: any;

  // Declaration & Init title loading
  loadingText: any;

  subModuleUse: string;

  private serviceMessageLoading: any = null;

  // Declaration - Injectable data mat dialog of parent - root to popup choice
  constructor(private injector: Injector, public dialogRef: MatDialogRef<PopupLoadingComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogDataPopup,
              private serviceConfigObservable: ConfigObservableService) {
      this.subModuleUse = environment.subModuleUse;

      this.serviceMessageLoading = this.injector.get(myService.serviceMessageLoading);
     
      this.loadingTitle = this.serviceMessageLoading.loadingTitle;
      this.loadingText = this.serviceMessageLoading.loadingText;

      this.serviceConfigObservable.configObservableSendClosePopupLoading.subscribe((valueSend: Boolean) => {
        this.dialogRef.close()
      })
    }

  ngOnInit() {
    
  }
}
