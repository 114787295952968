import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {

  // static URL = environment.urlServer;
  environment: any;

	constructor(private http: HttpClient, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getDepartments(page: number, nbPage: number, term: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'Referentials/Departments/Search?Page=' + page + '&PageSize=' + nbPage + '&Search=' + term, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body;
        })
      );
  }
}
