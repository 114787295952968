<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': data.size < 2 }">
  <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': data.size < 2 }">
    <div class="containerTitleImg">
      <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" [ngClass]="{ 'logoSM': data.size < 2 , 'logoSaveSM': data.size < 2 }" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
      <h1 class="titlePopup" [ngClass]="{ 'titlePopupSM': data.size < 2 }" >{{modalTitle}}</h1>
    </div>
  </div>
</div>


<mat-dialog-content
  [ngClass]="{ 'containerLoadingContent': data.size >= 2, 'containerRecallDialogContentSM': data.size < 2 }">

  <div class="containerBody">
    <div class="containerBodyContent">
      <h1 class="titleLoading" [ngClass]="{ 'titlePopupSM': data.size < 2 }">{{modalBody}}</h1>
    </div>
    <div class="containerBtnLoading">
      <button [mat-dialog-close]="false" *ngIf="withCloseBtn"  class="btnCancel">{{closeBtnLabel}}</button>
      <button [mat-dialog-close]="true" *ngIf="withValidateBtn" class="btnValidate">{{validateBtnLabel}}</button>
    </div>
  </div>
</mat-dialog-content>


