<!-- Container title popup with mat dialog title -->
<div mat-dialog-title [ngClass]="{ 'matDialogTitleSM': objectReceivePopup.size < 2 }">
    <div class="containerTitlePopup" [ngClass]="{ 'containerTitlePopupSaveSM': objectReceivePopup.size < 2 }">
        <div class="containerTitleImg">
            <img alt="logo_vitrine_lafactory" title="Logo Vitrine LaFactory" class="logo" src="../../../../../{{ subModuleUse }}/assets/picto-popup.png">
            <h1 *ngIf="objectReceivePopup.title !== ''" class="titlePopup" [ngClass]="{ 'titlePopupSM': objectReceivePopup.size < 2 }">{{ objectReceivePopup.title }}</h1>      
        <div>
      <!-- <mat-icon mat-dialog-close aria-hidden="false" aria-label="Example user verified icon">close</mat-icon> -->
    </div>
</div>

<!-- Container content popup cancel option -->
<mat-dialog-content [ngClass]="{'containerLoadingContent': objectReceivePopup.size >= 2, 'pt-3 containerLoadingContentSM': objectReceivePopup.size < 2}">
    <div class="containerTitle">
      <h1 class="titleLoading">{{ objectReceivePopup.text }}</h1>
      <div [innerHTML]="objectReceivePopup.innerHtml"></div>
    </div>
    <div *ngIf="objectReceivePopup.activateLoading" class="containerLoading">
        <div class="boxes">
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
    <div class="containerActivateCheckbox" *ngIf="objectReceivePopup.activateCheckbox">
        <mat-radio-group class="containerMatRadioGroup" aria-label="Select an option" [(ngModel)]="checkbox1">
            <mat-radio-button *ngIf="objectReceivePopup.objectCheckbox.checkbox1.value !== null" class="matRadioButton" [value]="objectReceivePopup.objectCheckbox.checkbox1.value"><div [innerHTML]="objectReceivePopup.objectCheckbox.checkbox1.name"></div></mat-radio-button>
            <mat-radio-button *ngIf="objectReceivePopup.objectCheckbox.checkbox2.value !== null" class="matRadioButton" [value]="objectReceivePopup.objectCheckbox.checkbox2.value"><div [innerHTML]="objectReceivePopup.objectCheckbox.checkbox2.name"></div></mat-radio-button>
            <mat-radio-button *ngIf="objectReceivePopup.objectCheckbox.checkbox3.value !== null" class="matRadioButton" [value]="objectReceivePopup.objectCheckbox.checkbox3.value"><div [innerHTML]="objectReceivePopup.objectCheckbox.checkbox3.name"></div></mat-radio-button>
        </mat-radio-group>

        <div *ngIf="objectReceivePopup.namePopup == 'duplicateProject'">
            <div *ngIf="checkbox1 == '3'">
                <form class="ruleProjectForm"  [formGroup]="insertCloseProjectForm" (ngSubmit)="onSubmitPostFormCloseProject(disabledButton = true)">
                    <span>La conversion entraine la fermeture du projet initial <b>{{ this.objectReceivePopup.objectCheckbox.projectName }}</b>.</span>
                    <span>Veillez saisir son code conclusion :</span>
                    <div class="d-flex flex-column justify-content-center align-items-center mt-2">
                        <table cellspacing="0">
                            <tr>
                                <td>
                                    <span *ngIf="isOpenSelect.conclusionCode" class="spanSelectCode">Code conclusion</span>
                                    <!-- formControl conclusionCode -->
                                    <ng-select *ngIf="listFieldConclusionCode.length !== 0" [ngClass]="{'selectConclusionCode': objectReceivePopup.size > 2, 'selectConclusionCodeMD': objectReceivePopup.size == 2, 'selectConclusionCodeSM': objectReceivePopup.size < 2, 'selectConclusionCodeMargin': insertCloseProjectForm.value.conclusionCode !== null || initErrorSelect }" formControlName="conclusionCode" placeholder="Code conclusion"
                                        [items]="listFieldConclusionCode" bindLabel="name" bindValue="id" [compareWith]="compareWithFn"
                                        [closeOnSelect]="true" [multiple]="false" [hideSelected]="true" (click)="openSelectAndReset('conclusionCode', insertCloseProjectForm.value.conclusionCode, isOpenSelect)" (clear)="openSelectAndReset('conclusionCode', insertCloseProjectForm.value.conclusionCode, isOpenSelect)">
                                    </ng-select>
                                    <span *ngIf="insertCloseProjectForm.value.conclusionCode === null && !initErrorSelect" class="errorSpanForm">Le code conclusion est requis</span>
                                </td>
                            </tr>
                        </table>
                        <table cellspacing="0">
                            <tr [ngClass]="{'trNewProject': objectReceivePopup.size >= 2, 'trNewProjectSM': objectReceivePopup.size < 2}">
                                <td>
                                    <span *ngIf="requiredComment" class="commentRequired">Le commentaire est requis</span>
                                    <!-- formControl comment -->
                                    <mat-form-field [ngClass]="{'formFieldCommentRuleProject': objectReceivePopup.size > 2, 'formFieldCommentRuleProjectMD': objectReceivePopup.size == 2, 'formFieldCommentRuleProjectSM': objectReceivePopup.size < 2 }" appearance="fill">
                                        <mat-label class="labelField">{{ labelComment }}</mat-label>
                                        <input type="text"
                                            matInput
                                            formControlName="comment"
                                            >
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <!-- <div class="containerBtnRuleProject">
                        <button mat-dialog-close class="btnCancelProject" [disabled]="disabledButton">Annuler</button>
                        <button class="btnCreateProjectRule" type="submit" [disabled]="disabledButton">Statuer le projet</button>
                    </div> -->
                </form>
            </div>
        </div>
        <!-- <mat-checkbox class="fieldResearchCheckBox" [(ngModel)]="checkbox1">Planifier dans mon agenda</mat-checkbox>
        <mat-checkbox class="fieldResearchCheckBox" [(ngModel)]="checkbox2">Planifier dans mon agenda</mat-checkbox> -->
    </div>
    <div class="containerBtnLoading" *ngIf="objectReceivePopup.activateButton !== null">
        <button (click)="closePopupAnnulation()" *ngIf="objectReceivePopup.activateButton.annulation.key" class="btnAnnulation">{{ objectReceivePopup.activateButton.annulation.value }}</button>
        <button (click)="closePopupValidation()" *ngIf="objectReceivePopup.activateButton.validate.key && objectReceivePopup.namePopup !== 'duplicateProject'" class="btnValidation">{{ objectReceivePopup.activateButton.validate.value }}</button>

        <button (click)="closePopupValidation()" *ngIf="objectReceivePopup.namePopup == 'duplicateProject' && checkbox1 !== '3'" class="btnValidation">{{ objectReceivePopup.activateButton.validate.value }}</button>
        <button (click)="onSubmitPostFormCloseProject(disabledButton = true)" *ngIf="objectReceivePopup.namePopup == 'duplicateProject' && checkbox1 == '3'" class="btnValidation">{{ objectReceivePopup.activateButton.validate.value }}</button>
         
    </div>
</mat-dialog-content>
