import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderInterceptor } from './cores/auth.interceptor';
// import { FooterAllPageComponent } from './components/footer-all-page/footer-all-page.component';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatButtonModule } from '@angular/material/button';
import {MatBadgeModule} from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SizeDetectorComponent } from './size-detector/size-detector.component';
import { ResizeService } from './size-detector/resize.service';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { CalendarDateFormatter, CalendarModule, CalendarNativeDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as materialCore from '@angular/material/core';


import { myComponents } from '../environments/environment';
import { ConnectorCasApereoComponent } from './components/connector-cas-apereo/connector-cas-apereo.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { TableHousingSearchSaveComponent } from './pages/connected/components/table-housing-search-save/table-housing-search-save.component';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
  }
  getFirstDayOfWeek() {
    let num: number = 1;
    return num;
  }
}

class CustomDateFormatter extends CalendarNativeDateFormatter {
  public dayViewHour({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat('fr', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SizeDetectorComponent,
    // FooterAllPageComponent,
    SnackbarComponent,
    TableHousingSearchSaveComponent,
    ConnectorCasApereoComponent,
    SafeHtmlPipe,
    ...myComponents
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    LeafletModule,
    MatIconModule,
    MatSnackBarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    NgxMatSelectSearchModule,
    NgSelectModule,
    MatTableModule,
    MatSortModule,
    CalendarModule.forRoot(
      {
      provide: DateAdapter,
      useFactory: adapterFactory,
    },{
      dateFormatter: {
        provide: CalendarDateFormatter, 
        useClass: CustomDateFormatter
      }
    }),
  ],
  exports: [
    // FooterAllPageComponent,
    ...myComponents
  ],
  entryComponents: [
    ...myComponents
  ],
  providers: [
    ResizeService,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    {
			provide: HTTP_INTERCEPTORS,
			useClass: HeaderInterceptor,
			multi: true
		},
    // {
    //   provide: DateAdapter,
    //   useClass: CustomDateAdapter
    // },
    {
      provide: materialCore.DateAdapter,
      useClass: CustomDateAdapter
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, 
      useValue: {duration: 3500}
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
