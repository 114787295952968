import { EripService } from './erip.service';
import { ComparationAssetService } from 'src/app/shared/services/comparation-asset.service';
import { FavoriteAssetService } from 'src/app/shared/services/favorite-asset.service';
import { ConfigObservableService } from 'src/app/shared/services/service-config-observable-service.service';
import { ReservationService } from './reservation.service';
import { InvestmentOperation } from './../models/investment-operation';
import { convertTZ } from 'src/app/shared/services/functions';
import { PropositionPrebooking } from 'src/app/shared/models/proposition-prebooking';
import { PropositionInProgress } from './../models/proposition-in-progress';
import { ProjectInProgress } from './../models/project-in-progress';
import { Deed } from './../models/deed';
import { Reservation } from './../models/reservation';
import { OperationService } from './operation.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Asset } from '../models/asset';
import { PeriodictElement } from '../models/periodict-element';
import { getQuarterOneLetter, numberWithCommas, transformDateFormatHoursPipe, transformDateFormatLetterPipe } from './functions';
import { InvestmentSale } from '../models/investment-sale';
import { first } from 'rxjs/operators';
import { AcitvityReservation } from '../models/acitvity-reservation';
import { UserService } from './user.service';
import { Favorite } from '../models/favorite';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TableHousingService {

  public namePopupForMatDialog: any;

  public tabSearchSave: Array<any> = new Array<any>();
  public placement: any;

  public allTabProgram: Array<any> = new Array<any>();
  public allTabOperationAsset: Array<any> = new Array<any>();
  public oneOperationAsset : Array<Asset> = new Array<Asset>();
  public projectTabProgram: Array<any> = new Array<any>();
  public saleTabProgram: Array<any> = new Array<any>();
  public rdvTabRdv: Array<any> = new Array<any>();

  public configObservableSearchSave = new Subject<Array<PeriodictElement>>();
  public configObservableSaleProgram = new Subject<Array<PeriodictElement>>();
  public configObservableOperationPlacement = new Subject<Array<PeriodictElement>>();

  
  public configObservableAllOperationAsset = new Subject<Array<PeriodictElement>>();
  public configObservableOneOperationAsset = new Subject<Array<PeriodictElement>>();
  public configObservableProjectProgram = new Subject<Array<PeriodictElement>>();
  public configObservableRdv = new Subject<Array<PeriodictElement>>();
  public configObservablePlacement = new Subject<Array<PeriodictElement>>();

  public ELEMENT_DATA_SEARCH_SAVE: Array<PeriodictElement> = new Array<PeriodictElement>();
  public ELEMENT_DATA_PLACEMENT: Array<PeriodictElement> = new Array<PeriodictElement>();

  public ELEMENT_DATA_ALL_OPERATION_ASSET: Array<PeriodictElement> = new Array<PeriodictElement>();
  public ELEMENT_DATA_ONE_OPERATION_ASSET: Array<PeriodictElement> = new Array<PeriodictElement>();
  public ELEMENT_DATA_PROJECT_PROGRAM: Array<PeriodictElement> = new Array<PeriodictElement>();
  public ELEMENT_DATA_RDV: Array<PeriodictElement> = new Array<PeriodictElement>();
  public ELEMENT_DATA_SALE_PROGRAM: Array<PeriodictElement> = new Array<PeriodictElement>();
  public ELEMENT_DATA_LOAD: Array<PeriodictElement> = new Array<PeriodictElement>();

  constructor(private operationService: OperationService, private serviceComparationAsset: ComparationAssetService, private serviceFavorite: FavoriteAssetService, private serviceReservation: ReservationService,
    private serviceConfigObservable: ConfigObservableService, private serviceErip: EripService, private userService: UserService) {
    this.allTabProgram = JSON.parse(localStorage.getItem('tabDataProgram') || '{}');
   }

  checkData(namePageActive: string, tabProgram: Array<any>) {
    if ( namePageActive === 'stocksearch' || namePageActive === 'storesearch' ) {
      if ( tabProgram === this.tabSearchSave && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SEARCH_SAVE && this.ELEMENT_DATA_SEARCH_SAVE.length >= 1 ) {
        this.configObservableSearchSave.next(this.ELEMENT_DATA_SEARCH_SAVE)
      } else {
        this.ELEMENT_DATA_SEARCH_SAVE = new Array<PeriodictElement>();
        this.uploadSearchSave(tabProgram);
      }
    } else if (namePageActive === 'placement') {
      this.ELEMENT_DATA_PLACEMENT = new Array<PeriodictElement>();
      this.uploadPlacement(tabProgram);
    } else if ( namePageActive === 'stockAll' || namePageActive === 'storeAll' || namePageActive === 'favoriteAll') {
      this.ELEMENT_DATA_ALL_OPERATION_ASSET = new Array<PeriodictElement>();
      this.uploadAllOperationAsset(tabProgram);
    } else if ( namePageActive === 'stockAlone' || namePageActive === 'storeAlone') {
      if ( tabProgram === this.oneOperationAsset && this.ELEMENT_DATA_ONE_OPERATION_ASSET.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_ONE_OPERATION_ASSET ) {
        this.configObservableOneOperationAsset.next(this.ELEMENT_DATA_ONE_OPERATION_ASSET)
      } else {
        this.ELEMENT_DATA_ONE_OPERATION_ASSET = new Array<PeriodictElement>();
        this.uploadOneProgram(tabProgram);
      }
    } else if ( namePageActive === 'project-inProgresssProject' || namePageActive === 'project-newProject' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_PROJECT_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_PROJECT_PROGRAM ) {
        this.configObservableProjectProgram.next(this.ELEMENT_DATA_PROJECT_PROGRAM)
      } else {
        this.ELEMENT_DATA_PROJECT_PROGRAM = new Array<PeriodictElement>();
        this.uploadProjectInProgressOrNew(tabProgram);
      }
    } else if ( namePageActive === 'project-winProject' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_PROJECT_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_PROJECT_PROGRAM ) {
        this.configObservableProjectProgram.next(this.ELEMENT_DATA_PROJECT_PROGRAM)
      } else {
        this.ELEMENT_DATA_PROJECT_PROGRAM = new Array<PeriodictElement>();
        this.uploadProjectInProgressOrNew(tabProgram);
      }
    } else if ( namePageActive === 'project-lostProject' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_PROJECT_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_PROJECT_PROGRAM ) {
        this.configObservableProjectProgram.next(this.ELEMENT_DATA_PROJECT_PROGRAM)
      } else {
        this.ELEMENT_DATA_PROJECT_PROGRAM = new Array<PeriodictElement>();
        this.uploadProjectInProgressOrNew(tabProgram);
      }
    } else if ( namePageActive === 'Rdv' || namePageActive === 'rdv' ) {
      if ( tabProgram === this.rdvTabRdv && this.ELEMENT_DATA_RDV.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_RDV ) {
        this.configObservableRdv.next(this.ELEMENT_DATA_RDV)
      } else {
        this.ELEMENT_DATA_RDV = new Array<PeriodictElement>();
        this.uploadRdv(tabProgram);
      }
    } else if ( namePageActive === 'sale-option' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_SALE_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SALE_PROGRAM ) {
        this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
      } else {
        this.uploadSaleOptionInProgress(tabProgram)
      }
    } else if ( namePageActive === 'sale-preresa' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_SALE_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SALE_PROGRAM ) {
        this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
      } else {
        this.uploadSalePrebooking(tabProgram)
      }
    } else if ( namePageActive === 'sale-reservation' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_SALE_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SALE_PROGRAM ) {
        this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
      } else {
        this.uploadSaleReservation(tabProgram)
      }
    } else if ( namePageActive === 'sale-actable' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_SALE_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SALE_PROGRAM ) {
        this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
      } else {
        this.uploadSaleDeed(tabProgram)
      }
    } else if ( namePageActive === 'sale-placement' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_SALE_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SALE_PROGRAM ) {
        this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
      } else {
        this.uploadSalePlacement(tabProgram)
      }
    } else if ( namePageActive === 'project-search' || namePageActive === 'sale-search' ) {
      if ( tabProgram === this.projectTabProgram && this.ELEMENT_DATA_SALE_PROGRAM.length >= 1 && this.ELEMENT_DATA_LOAD === this.ELEMENT_DATA_SALE_PROGRAM ) {
        this.configObservableOperationPlacement.next(this.ELEMENT_DATA_SALE_PROGRAM)
      } else {
        this.uploadOperationPlacement(tabProgram)
      }
    }
  }

  uploadSearchSave(tabSearchSave: Array<any>) {
    this.ELEMENT_DATA_SEARCH_SAVE = new Array<PeriodictElement>();
    this.tabSearchSave = tabSearchSave;
    if (tabSearchSave.length > 0) {
      tabSearchSave.forEach(save => {
  
        let searchSaveData: PeriodictElement = new PeriodictElement();
  
        searchSaveData.searchSave = save;
        searchSaveData.title = save.Title;
        searchSaveData.SearchId = save.SearchId;
        if (save.OperationIds !== undefined && save.OperationIds !== null) {
          searchSaveData.OperationIds = [];
          if (save.OperationIds.length !== 0) {
            save.OperationIds.forEach((operationName: string) => {
              searchSaveData.OperationIds.push(operationName)
            });
          }
        }
  
        if (save.PromoteurIds !== undefined && save.PromoteurIds !== null) {
          searchSaveData.PromoteurIds = [];
          if (save.PromoteurIds.length !== 0) {
            save.PromoteurIds.forEach((promoteurName: string) => {
              searchSaveData.PromoteurIds.push(promoteurName)
            });
          }
        }
  
        if (save.AssetTypeCodes !== undefined && save.AssetTypeCodes !== null) {
          searchSaveData.AssetTypeCodes = [];
          if (save.AssetTypeCodes.length !== 0) {
            save.AssetTypeCodes.forEach((AssetTypeName: string) => {
              searchSaveData.AssetTypeCodes.push(AssetTypeName)
            });
          }
        }
  
        if (save.AssetOrientationCodes !== undefined && save.AssetOrientationCodes !== null) {
          searchSaveData.AssetOrientationCodes = [];
          if (save.AssetOrientationCodes.length !== 0) {
            save.AssetOrientationCodes.forEach((AssetOrientationName: string) => {
              searchSaveData.AssetOrientationCodes.push(AssetOrientationName)
            });
          }
        }
  
        if (save.AssetStageCodes !== undefined && save.AssetStageCodes !== null) {
          searchSaveData.AssetStageCodes = [];
          if (save.AssetStageCodes.length !== 0) {
            save.AssetStageCodes.forEach((AssetStageName: string) => {
              searchSaveData.AssetStageCodes.push(AssetStageName)
            });
          }
        }
  
        if (save.OperationTaxLawCodes !== undefined && save.OperationTaxLawCodes !== null) {
          searchSaveData.OperationTaxLawCodes = [];
          if (save.OperationTaxLawCodes.length !== 0) {
            save.OperationTaxLawCodes.forEach((OperationTaxLawName: string) => {
              searchSaveData.OperationTaxLawCodes.push(OperationTaxLawName)
            });
          }
        }
  
        if (save.Regions !== undefined && save.Regions !== null) {
          searchSaveData.Regions = [];
          if (save.Regions.length !== 0) {
            save.Regions.forEach((regionsName: string) => {
              searchSaveData.Regions.push(regionsName)
            });
          }
        }
  
        if (save.Departments !== undefined && save.Departments !== null) {
          searchSaveData.Departments = [];
          if (save.Departments.length !== 0) {
            save.Departments.forEach((departmentsName: string) => {
              searchSaveData.Departments.push(departmentsName)
            });
          }
        }
  
        if (save.Cities !== undefined && save.Cities !== null) {
          searchSaveData.Cities = [];
          if (save.Cities.length !== 0) {
            save.Cities.forEach((citiesName: string) => {
              searchSaveData.Cities.push(citiesName)
            });
          }
        }
  
        searchSaveData.AssetPricesMin = save.AssetPrices.Min;
        searchSaveData.AssetPricesMax = save.AssetPrices.Max;
        
        if (save.DeliveryDates !== undefined && save.DeliveryDates !== null) {
          searchSaveData.DeliveryDates = [];
          if (save.DeliveryDates.length !== 0) {
            save.DeliveryDates.forEach((deliveryDatesName: any) => {
              searchSaveData.DeliveryDates.push(deliveryDatesName);
              // searchSaveData.DeliveryDates.push({ DateMin: deliveryDatesName.DateMin, DateMax: deliveryDatesName.DateMax })
            });
          }
        }
  
        if (save.ActabilityDates !== undefined && save.ActabilityDates !== null) {
          searchSaveData.ActabilityDates = [];
          if (save.ActabilityDates.length !== 0) {
            save.ActabilityDates.forEach((actabilityDatesName: any) => {
              searchSaveData.ActabilityDates.push(actabilityDatesName);
              // searchSaveData.ActabilityDates.push({ DateMin: actabilityDatesName.DateMin, DateMax: actabilityDatesName.DateMax })
            });
          }
        }

        if (save.CompletionDates !== undefined && save.CompletionDates !== null) {
          searchSaveData.CompletionDates = [];
          if (save.CompletionDates.length !== 0) {
            save.CompletionDates.forEach((completionDatesName: any) => {
              searchSaveData.CompletionDates.push(completionDatesName);
              // searchSaveData.ActabilityDates.push({ DateMin: actabilityDatesName.DateMin, DateMax: actabilityDatesName.DateMax })
            });
          }
        }
  
        // searchSaveData.localization = save.localization;
        // searchSaveData.typology = save.typology;
        // searchSaveData.pricemin = save.pricemin;
        // searchSaveData.pricemax = save.pricemax;
        // searchSaveData.taxSystem = save.taxSystem;
        // searchSaveData.actability = save.actability;
        // searchSaveData.datedelivery = save.datedelivery;
  
        searchSaveData.icon = 'play_arrow';
  
        this.ELEMENT_DATA_SEARCH_SAVE.push(searchSaveData);
      });
    }
    
    this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SEARCH_SAVE;
		this.configObservableSearchSave.next(this.ELEMENT_DATA_SEARCH_SAVE)
	}


  uploadPlacement(placement: any) {
    this.ELEMENT_DATA_PLACEMENT = new Array<PeriodictElement>();
    this.placement = placement;
    if (placement.listInvestment.length > 0) {
      placement.listInvestment.forEach((placementOperation: any) => {
  
        let placementData: PeriodictElement = new PeriodictElement();
  
        placementData.placement = placementOperation;
        placementData.DATE = placementOperation.date !== null ? this.getDateLabelSample(this.convertTZ(placementOperation.date)) : "";
        placementData['OPÉRATION'] = placementOperation.operation;
        placementData.MONTANT = placementOperation.amount;
        placementData.FRAIS = placementOperation.fresh;
        placementData['PÉRIODICITÉ'] = placementOperation.periodicity;
        placementData.operationPlacementId = '';
        
        placementData.icon = 'play_arrow';
  
        this.ELEMENT_DATA_PLACEMENT.push(placementData);
      });
    }
    
    this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_PLACEMENT;
		this.configObservablePlacement.next(this.ELEMENT_DATA_PLACEMENT)
	}

  uploadAllOperationAsset(tabAssetAllOperation: Array<Asset>) {
		this.ELEMENT_DATA_ALL_OPERATION_ASSET = new Array<PeriodictElement>();
    // if (tabAssetAllOperation[0]['asset.statuscode_label'] !== undefined ) {
    //   this.allTabOperationAsset = tabAssetAllOperation;
    // } else {
    //   this.allTabOperationAsset = tabAssetAllOperation[0];
    // }
    this.allTabOperationAsset = tabAssetAllOperation;
    console.log(tabAssetAllOperation)
    if (tabAssetAllOperation.length !== 0) {
      tabAssetAllOperation.forEach(asset => {

        let programData: PeriodictElement = new PeriodictElement();
        
        programData['DATE DISPO'] = ' - '
        programData.STATUT = asset['asset.statuscode_label'].slice(2);
        if (programData.STATUT === 'Optionné') {
          programData.RANG = asset['asset.if_lastoptionrank_calc'].toString();
          programData.STATUT = programData.STATUT + ' (' + asset['asset.if_lastoptionrank_calc'].toString() + ')';
          if (asset['asset.if_lastoptionenddate'] !== undefined) {
            if (asset['asset.if_lastoptionenddate'] !== null) {
              programData['DATE DISPO'] = this.getDateLabelSample(this.convertTZ(asset['asset.if_lastoptionenddate'])) + "    " + transformDateFormatHoursPipe(new Date(convertTZ(asset['asset.if_lastoptionenddate']))).toString();
            }
          }
        } else {
          programData.RANG = '';
        }

        if ( programData.STATUT !== 'Reserve' ) {  
          programData.AllProgram = asset;
          programData.dataDialog = { startOptionData: asset['asset.if_lastoptionenddate'] !== null ? new Date(this.convertTZ(asset['asset.if_lastoptionenddate'])) : null, startOption: asset['asset.if_lastoptionenddate'] !== null ? new Date(this.convertTZ(asset['asset.if_lastoptionenddate'])) : null , endOption: "", status: programData.STATUT, rang: asset['asset.if_lastoptionrank_calc'], nameLot: programData.STATUT !== 'Disponible' ?  `${asset['asset.if_assetnumber']} - Optionné` : `${asset['asset.if_assetnumber']} - ${programData.STATUT}`, idLot: asset['asset.if_assetid'], nameResidence: asset['operation.if_operationbusinessname_calc'] };
          
          programData.selected = 'allAsset';
          
          programData.AssetId = asset['asset.if_assetid']

          programData.PROGRAMME = asset['operation.if_operationbusinessname_calc'];
          programData.TYPO = asset['assettype_if_fluxsiteb2blabel'];
          programData.LOT = asset['asset.if_assetnumber'];
          programData['SURF.'] = asset["asset.if_livingspace"] !== null ? asset["asset.if_livingspace"].toString() + " m²" : ' -  ';
          programData.ETAGE = asset['assetstage_if_fluxsiteb2blabel'] !== null ? asset['assetstage_if_fluxsiteb2blabel'] : ' - ';
          programData.LOYER = asset['asset.if_assetrent'] !== null ? asset['asset.if_assetrent'].toString() + " €" : ' - ';
          programData.RENTA = asset['asset.if_assetrent_profitabilityrate'] !== null ? asset['asset.if_assetrent_profitabilityrate'].toString() + ' %' : ' - ';
          programData.PRIX = numberWithCommas(Math.round(asset['asset.if_pricecom_tax1_cur'])) + " € TTC";
          programData['PRIX TTC'] = numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax1_cur'])) + " €";
          programData['PRIX HT'] = numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax0_cur'])) + " €";
          programData.VILLE = asset['city.if_name'] !== null ? asset['city.if_name'] : ' - '
          
          programData.icon = 'play_arrow';

          programData.modeCreationOptionDate = asset['operation.if_front_proposition_openstartdate'] !== null ? new Date(this.convertTZ(asset['operation.if_front_proposition_openstartdate'])) < new Date() : true

          programData.modeCreationOption = false;
          if (asset['operation.if_front_proposition_mode'] !== null && this.userService.user.CreateOption) {
            let if_salescat_calc = this.userService.user.if_salescat_calc;
            if (asset['operation.if_front_proposition_mode'] === 750010000) {
              programData.modeCreationOption = false;
            } else if ( ( if_salescat_calc === asset['operation.if_front_proposition_mode'] ) || ( asset['operation.if_front_proposition_mode'] === 750010003 ) ) {
              programData.modeCreationOption = true;
            }
          }

          programData.operationIdAlone = asset['operation.if_operationid'];
          programData.PROMOTEUR = asset['operation.if_developeraccountid_label'];
  
          programData.parking = asset['asset.if_subasset_desc'] !== null ? asset['asset.if_subasset_desc']?.split('\r\n') : [' - '];
          programData.taxSystem = asset['operation.taxlaw_if_fluxsiteb2blabel'] !== null ? asset['operation.taxlaw_if_fluxsiteb2blabel'] : ' - ';
          programData.typeText = asset['assettype.if_roomnumber'] !== null ? asset['assettype.if_roomnumber'].toString() + " pièces" : " - ";            
          programData.zone = asset['program.taxzone_if_fluxsiteb2blabel'] !== null ? asset['program.taxzone_if_fluxsiteb2blabel'] : ' - ';
          programData.annexSurface = asset["asset.if_totaladditionalareas"] !== null ? asset["asset.if_totaladditionalareas"]?.toString() + " m²" : '';
          programData.SHAB = asset["asset.if_livingspace"] !== null ? asset["asset.if_livingspace"].toString() + " m²" : '';
          programData.surfacePondere = asset["asset.if_assetweightedarea"] !== null ? asset["asset.if_assetweightedarea"].toString() + " m²" : ' - ';
          programData.terrace = ((asset['asset.if_terracearea'] !== undefined ? asset['asset.if_terracearea'] : 0) + (asset['asset.if_terracearea2'] !== undefined ? asset['asset.if_terracearea2'] : 0)).toFixed(2).toString() + ' m²';
          programData.balcony = ((asset['asset.if_balconyarea'] !== undefined ? asset['asset.if_balconyarea'] : 0) + (asset['asset.if_balconyarea2'] !== undefined ? asset['asset.if_balconyarea2'] : 0)).toFixed(2).toString() + ' m²';
          programData.ORIENTATION = asset['assetorientation_if_fluxsiteb2blabel'] !== null ? asset['assetorientation_if_fluxsiteb2blabel'] : ' - ';
          if (asset['operation.if_actabilitydate'] !== undefined && asset['operation.if_actabilitydate'] !== null) {
            programData.actability = getQuarterOneLetter(asset['operation.if_actabilitydate'] !== undefined ? asset['operation.if_actabilitydate'] : ' - ');
          } else {
            programData.actability = '-';
          }
          if (asset['operation.if_deliverydate'] !== undefined && asset['operation.if_deliverydate'] !== null) {
            programData.delivery = getQuarterOneLetter(asset['operation.if_deliverydate'] !== undefined ? asset['operation.if_deliverydate'] : ' - ');
          } else {
            programData.delivery = '-';
          }
          if (asset['operation.if_completiondate'] !== undefined && asset['operation.if_completiondate'] !== null) {
            programData.DAACT = getQuarterOneLetter(asset['operation.if_completiondate'] !== undefined ? asset['operation.if_completiondate'] : ' - ');
          } else {
            programData.DAACT = '-';
          }
          if (asset['asset.if_assetplan'] !== null) {
            programData.image = asset['asset.if_assetplan'] + ".jpg";
          } else {
            programData.image = "assets/defaultimage.png"
          }

          programData.PINEL = asset['asset.if_pinelplus_asset_calc'] == 750010000 ? true : false;

          programData.priceAssetTTC = asset['pricing.if_pricelgs_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelgs_tax1_cur'])) + ' €' : ' 0 € '; // Prix du lot TTC
          programData.priceAssetHT = asset['pricing.if_pricelgs_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelgs_tax0_cur'])) + ' €' : ' 0 € '; // Pris du lot HT
          programData.priceLoyerTTC = asset['pricing.if_pricerent_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricerent_tax1_cur'])) + ' €' : ' 0 € '; 
          programData.priceLoyerHT = asset['pricing.if_pricerent_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricerent_tax0_cur'])) + ' €' : ' 0 € '; // Pris du loyer HT = if_pricerent_tax0_cur
          // programData.pricingLogementTTC = asset['pricing.if_pricelog_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelog_tax1_cur'])) + ' €' : ' - ';
          // programData.pricingLogementHT = asset['pricing.if_pricelog_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelog_tax0_cur'])) + ' €' : ' - ';
          programData.pricingMobilierTTC =  asset['pricing.if_pricemob_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricemob_tax1_cur'])) + ' €' : ' - ';
          programData.pricingMobilierHT = asset['pricing.if_pricemob_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricemob_tax0_cur'])) + ' €' : ' - ';
          programData.pricingLotsSecondairesTTC = asset['pricing.if_pricels_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricels_tax1_cur'])) + ' €' : ' - ';
          programData.pricingLotsSecondairesHT = asset['pricing.if_pricels_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricels_tax0_cur'])) + ' €' : ' - ';
          programData.pricingTotalTTC = asset['pricing.if_pricenetcom_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax1_cur'])) + ' €' : ' - ';
          programData.pricingTotalHT = asset['pricing.if_pricenetcom_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax0_cur'])) + ' €' : ' - ';

          programData.activeComparation = true;
          this.serviceComparationAsset.listAssetCompar.forEach((assetId: string) => {
            if (programData.AssetId == assetId) {
              programData.activeComparation = false;
            }
          });

          this.serviceFavorite.listFavorite.forEach((favorite: Favorite) => {
            if (programData.AssetId == favorite.if_assetid) {
              programData.favorite = favorite;
            }
          });

          programData.projectId = asset['asset.if_assetid'];
           // Go to element sur le tableaux
           let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
           if (IdSaleBafUpdate !== null) {
             if (programData.projectId == IdSaleBafUpdate) {
               setTimeout(() => {
                 console.log('service table housing')
                 this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
                 localStorage.removeItem('IdProjectBafUpdate')
               }, 1000)
             }
           }

          this.ELEMENT_DATA_ALL_OPERATION_ASSET.push(programData);
        }
      });
    } else {
      let programData: PeriodictElement = new PeriodictElement();
      this.ELEMENT_DATA_ALL_OPERATION_ASSET.push(programData);
    }
    
    this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_ALL_OPERATION_ASSET
		this.configObservableAllOperationAsset.next(this.ELEMENT_DATA_ALL_OPERATION_ASSET)
	}

  uploadOneProgram(tabAssetsSelected: Array<Array<Asset>>) {
    this.ELEMENT_DATA_ONE_OPERATION_ASSET = new Array<PeriodictElement>();

    if (tabAssetsSelected.length !== 0) {
      tabAssetsSelected[0].forEach(asset => { 
          let programData: PeriodictElement = new PeriodictElement();
          programData['DATE DISPO'] = ' - '
          programData.STATUT = asset['asset.statuscode_label'].slice(2);
          if (programData.STATUT === 'Optionné') {
            programData.RANG = asset['asset.if_lastoptionrank_calc'].toString();
            programData.STATUT = programData.STATUT + ' (' + asset['asset.if_lastoptionrank_calc'].toString() + ')';
            if (asset['asset.if_lastoptionenddate'] !== undefined) {
              if (asset['asset.if_lastoptionenddate'] !== null) {
                programData['DATE DISPO'] = this.getDateLabelSample(this.convertTZ(asset['asset.if_lastoptionenddate'])) + "    " + transformDateFormatHoursPipe(new Date(convertTZ(asset['asset.if_lastoptionenddate']))).toString();
              }
            }
          } else {
            programData.RANG = '';
          }
          if ( programData.STATUT !== 'Reserve' ) {   

            programData.AllProgram = asset;

            programData.AssetId = asset['asset.if_assetid']

            programData.dataDialog = { startOptionData: asset['asset.if_lastoptionenddate'] !== null ? new Date(this.convertTZ(asset['asset.if_lastoptionenddate'])) : null, startOption: asset['asset.if_lastoptionenddate'] !== null ? new Date(this.convertTZ(asset['asset.if_lastoptionenddate'])) : null , endOption: "", status: programData.STATUT, rang: asset['asset.if_lastoptionrank_calc'] , nameLot: programData.STATUT !== 'Disponible' ?  `${asset['asset.if_assetnumber']} - Optionné` : `${asset['asset.if_assetnumber']} - ${programData.STATUT}`, idLot: asset['asset.if_assetid'],  nameResidence: this.operationService.dataOperationActive['operation.if_operationbusinessname_calc'] };

            programData.selected = 'assetOperation';
            programData.LOT = asset['asset.if_assetnumber'];
            programData.TYPO = asset['assettype_if_fluxsiteb2blabel'];
            programData['SURF.'] = asset["asset.if_livingspace"] !== null ? asset["asset.if_livingspace"].toString() + " m²" : ' - ';
            programData.ETAGE = asset['assetstage_if_fluxsiteb2blabel'] !== null ? asset['assetstage_if_fluxsiteb2blabel'] : ' - ';
            programData['ORIENT.'] = asset['assetorientation_if_fluxsiteb2blabel'] !== null ? asset['assetorientation_if_fluxsiteb2blabel'] : ' - ';
            programData['PRIX TTC'] = numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax1_cur'])) + " €";
            programData['PRIX HT'] = numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax0_cur'])) + " €";
            programData.RENTA = asset['asset.if_assetrent_profitabilityrate'] !== null ? asset['asset.if_assetrent_profitabilityrate'].toString() + ' %' : ' - ';
            programData.PINEL = asset['asset.if_pinelplus_asset_calc'] == 750010000 ? true : false;

            programData.icon = 'play_arrow';

            programData.modeCreationOptionDate = asset['operation.if_front_proposition_openstartdate'] !== null ? new Date(this.convertTZ(asset['operation.if_front_proposition_openstartdate'])) < new Date() : true

            programData.modeCreationOption = false;
            if (asset['operation.if_front_proposition_mode'] !== null && this.userService.user.CreateOption) {
              let if_salescat_calc = this.userService.user.if_salescat_calc;
              if (asset['operation.if_front_proposition_mode'] === 750010000) {
                programData.modeCreationOption = false;
              } else if ( ( if_salescat_calc === asset['operation.if_front_proposition_mode'] ) || ( asset['operation.if_front_proposition_mode'] === 750010003 ) ) {
                programData.modeCreationOption = true;
              }
            }

            programData.priceAssetTTC = asset['pricing.if_pricelgs_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelgs_tax1_cur'])) + ' €' : ' 0 € '; // Prix du lot TTC
            programData.priceAssetHT = asset['pricing.if_pricelgs_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelgs_tax0_cur'])) + ' €' : ' 0 € '; // Pris du lot HT
            programData.priceLoyerTTC = asset['pricing.if_pricerent_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricerent_tax1_cur'])) + ' €' : ' 0 € '; 
            programData.priceLoyerHT = asset['pricing.if_pricerent_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricerent_tax0_cur'])) + ' €' : ' 0 € '; // Pris du loyer HT = if_pricerent_tax0_cur
            // programData.pricingLogementTTC = asset['pricing.if_pricelog_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelog_tax1_cur'])) + ' €' : ' - ';
            // programData.pricingLogementHT = asset['pricing.if_pricelog_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricelog_tax0_cur'])) + ' €' : ' - ';
            programData.pricingMobilierTTC =  asset['pricing.if_pricemob_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricemob_tax1_cur'])) + ' €' : ' - ';
            programData.pricingMobilierHT = asset['pricing.if_pricemob_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricemob_tax0_cur'])) + ' €' : ' - ';
            programData.pricingLotsSecondairesTTC = asset['pricing.if_pricels_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricels_tax1_cur'])) + ' €' : ' - ';
            programData.pricingLotsSecondairesHT = asset['pricing.if_pricels_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricels_tax0_cur'])) + ' €' : ' - ';
            programData.pricingTotalTTC = asset['pricing.if_pricenetcom_tax1_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax1_cur'])) + ' €' : ' - ';
            programData.pricingTotalHT = asset['pricing.if_pricenetcom_tax0_cur'] !== null ? numberWithCommas(Math.round(asset['pricing.if_pricenetcom_tax0_cur'])) + ' €' : ' - ';

            programData.PROGRAMME = asset['operation.if_operationbusinessname_calc'];
            programData.operationIdAlone = asset['operation.if_operationid'];
            programData.PROMOTEUR = asset['operation.if_developeraccountid_label'];

            programData.parking = asset['asset.if_subasset_desc'] !== null ? asset['asset.if_subasset_desc']?.split('\r\n') : [' - '];
            programData.taxSystem = asset['operation.taxlaw_if_fluxsiteb2blabel'] !== null ? asset['operation.taxlaw_if_fluxsiteb2blabel'] : ' - ';
            programData.typeText = asset['assettype.if_roomnumber'].toString() + " pièces";
            programData.zone = asset['program.taxzone_if_fluxsiteb2blabel'] !== null ? asset['program.taxzone_if_fluxsiteb2blabel'] : ' - ';
            programData.annexSurface = asset["asset.if_totaladditionalareas"] !== null ? asset["asset.if_totaladditionalareas"]?.toString() + " m²" : ' - ';
            programData.SHAB = asset["asset.if_livingspace"] !== null ? asset["asset.if_livingspace"].toString() + " m²" : '';
            programData.surfacePondere = asset["asset.if_assetweightedarea"] !== null ? asset["asset.if_assetweightedarea"].toString() + " m²" : ' - ';
            programData.terrace = ((asset['asset.if_terracearea'] !== undefined ? asset['asset.if_terracearea'] : 0) + (asset['asset.if_terracearea2'] !== undefined ? asset['asset.if_terracearea2'] : 0)).toFixed(2).toString() + ' m²';
            programData.balcony = ((asset['asset.if_balconyarea'] !== undefined ? asset['asset.if_balconyarea'] : 0) + (asset['asset.if_balconyarea2'] !== undefined ? asset['asset.if_balconyarea2'] : 0)).toFixed(2).toString() + ' m²';
            programData.ORIENTATION = asset['assetorientation_if_fluxsiteb2blabel'] !== null ? asset['assetorientation_if_fluxsiteb2blabel'] : ' - ';
            
            if (asset['operation.if_actabilitydate'] !== undefined && asset['operation.if_actabilitydate'] !== null) {
              programData.actability = getQuarterOneLetter(asset['operation.if_actabilitydate'] !== undefined ? asset['operation.if_actabilitydate'] : ' - ');
            } else {
              programData.actability = '-';
            }
            if (asset['operation.if_deliverydate'] !== undefined && asset['operation.if_deliverydate'] !== null) {
              programData.delivery = getQuarterOneLetter(asset['operation.if_deliverydate'] !== undefined ? asset['operation.if_deliverydate'] : ' - ');
            } else {
              programData.delivery = '-';
            }
            programData.LOYER = asset['asset.if_assetrent'] !== null ? asset['asset.if_assetrent'].toString() + " €" : ' - ';
            if (asset['operation.if_completiondate'] !== undefined && asset['operation.if_completiondate'] !== null) {
              programData.DAACT = getQuarterOneLetter(asset['operation.if_completiondate'] !== undefined ? asset['operation.if_completiondate'] : ' - ');
            } else {
              programData.DAACT = '-';
            }
            if (asset['asset.if_assetplan'] !== null) {
              programData.image = asset['asset.if_assetplan'] + ".jpg";
            } else {
              programData.image = "assets/defaultimage.png"
            }

            programData.activeComparation = true;
            this.serviceComparationAsset.listAssetCompar.forEach((assetId: string) => {
              if (programData.AssetId == assetId) {
                programData.activeComparation = false;
              }
            });

            this.serviceFavorite.listFavorite.forEach((favorite: Favorite) => {
              if (programData.AssetId == favorite.if_assetid) {
                programData.favorite = favorite;
              }
            });
            
            programData.projectId = asset['asset.if_assetid'];
            // Go to element sur le tableaux
            let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
            if (IdSaleBafUpdate !== null) {
              if (programData.projectId == IdSaleBafUpdate) {
                setTimeout(() => {
                  console.log('service table housing')
                  this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
                  localStorage.removeItem('IdProjectBafUpdate')
                }, 1000)
              }
            }
            this.ELEMENT_DATA_ONE_OPERATION_ASSET.push(programData);
        }
      });
    } else {
      let programData: PeriodictElement = new PeriodictElement();
      this.ELEMENT_DATA_ONE_OPERATION_ASSET.push(programData);
    }
    this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_ONE_OPERATION_ASSET;
    this.configObservableOneOperationAsset.next(this.ELEMENT_DATA_ONE_OPERATION_ASSET)
  }
  
  activFinishWork: number = 0;
  uploadProjectInProgressOrNew(tabInProgress: Array<ProjectInProgress>) {
    this.ELEMENT_DATA_PROJECT_PROGRAM = new Array<PeriodictElement>();
  
    this.activFinishWork = 0;

    tabInProgress.forEach((projectInProgress: ProjectInProgress) => {
      this.initProgramDataForInProgressOrNewOrTreat(projectInProgress, false);
    });

    if (this.activFinishWork >= tabInProgress.length) {
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_PROJECT_PROGRAM;
      this.configObservableProjectProgram.next(this.ELEMENT_DATA_PROJECT_PROGRAM)
    } else {
      let myInterval: any = null;
      myInterval = setInterval(() => {
       if (this.activFinishWork >= tabInProgress.length) {
         this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_PROJECT_PROGRAM;
         this.configObservableProjectProgram.next(this.ELEMENT_DATA_PROJECT_PROGRAM)
         clearInterval(myInterval);
       }
      }, 500);
    }
  }

  initProgramDataForInProgressOrNewOrTreat(projectInProgress: ProjectInProgress, eripFound: Boolean) {
    let programData: PeriodictElement = new PeriodictElement();

    programData.event = projectInProgress;

    programData.ID = projectInProgress.if_id_lafactory !== null ? projectInProgress.if_id_lafactory : ""; 
    programData.projectId = projectInProgress.if_projectid !== null ? projectInProgress.if_projectid : ""; 
    programData.activButtonErip = eripFound;
    programData.CLIENT = projectInProgress['if_ctc1lastname'] + " " + projectInProgress['if_ctc1firstname'];
    if (projectInProgress.progressionconclusion_if_fluxsiteb2blabel !== null) {
      programData.avancement = programData.AVANCEMENT = projectInProgress.progressionconclusion_if_fluxsiteb2blabel !== null ? projectInProgress.progressionconclusion_if_fluxsiteb2blabel : ' - ';
    } else {
      programData.avancement = programData.AVANCEMENT = projectInProgress.if_progressioncodeid_label !== null ? projectInProgress.if_progressioncodeid_label : ' - '
    }
    programData['DATE CREA'] =  projectInProgress.if_projectinitdate !== null ? this.getDateLabelSample(this.convertTZ(projectInProgress.if_projectinitdate)) : ' - ';
    programData['DATE RAPPEL'] = projectInProgress.if_customertocallback_nextdate !== null ? this.getDateLabelSample(this.convertTZ(projectInProgress.if_customertocallback_nextdate)) :  ' - ';
    programData['DATE R3'] =  projectInProgress.rdv3_scheduledstart !== null ? this.getDateLabelSample(this.convertTZ(projectInProgress.rdv3_scheduledstart)) : ' - ';

    programData.dateCreatded = projectInProgress.if_projectinitdate !== null ? this.getDateLabelSample(this.convertTZ(projectInProgress.if_projectinitdate)) : ' - '
    programData.dateCreatdedTime = projectInProgress.if_projectinitdate !== null ? transformDateFormatHoursPipe(this.convertTZ(projectInProgress.if_projectinitdate)).toString() : ' - ';

    programData.ORIGINE = projectInProgress.marketorg_if_fluxsiteb2blabel !== null ? projectInProgress.marketorg_if_fluxsiteb2blabel : ' - ';
    programData.originId = projectInProgress.marketorg_if_marketingoriginid !== null ? projectInProgress.marketorg_if_marketingoriginid : ' - ';

    programData['DATE FERMETURE'] = projectInProgress.if_closedate !== null ? this.getDateLabelSample(this.convertTZ(projectInProgress.if_closedate)) : ' - ';
    programData['CODE CONCLUSION'] = projectInProgress.progressionmainconclusion_if_fluxsiteb2blabel !== null ? projectInProgress.progressionmainconclusion_if_fluxsiteb2blabel : ' - '

    programData["TEL MOB"] = projectInProgress.if_ctc1mobilephone !== null ? projectInProgress.if_ctc1mobilephone : ' - ';

    if (projectInProgress.if_isrealestateproject !== null || projectInProgress.if_isinvestmentproject !== null) {
      programData.TYPE = "Yes"
      programData.typeImg = [];
      programData.activeImmovable = false;
      programData.activeFinancial = false;
      if (projectInProgress.if_isinvestmentproject !== null) {
        programData.typeImg.push({
          srcImg: projectInProgress.if_isinvestmentproject !== 0 ? 'assets/placement.svg' : ''
        })
        programData.activeFinancial = projectInProgress.if_isinvestmentproject !== 0 ? true : false;
      }

      if (projectInProgress.if_isrealestateproject !== null) {
        programData.typeImg.push({
          srcImg: projectInProgress.if_isrealestateproject !== 0 ? 'assets/immo.svg' : ''
        })
        programData.activeImmovable = projectInProgress.if_isrealestateproject !== 0 ? true : false;
      }
    } else {
      programData.activeImmovable = false;
      programData.activeFinancial = false;
      programData.TYPE = ' - ';
    }


    programData.VILLE = projectInProgress['if_ctc1city'] !== null ? projectInProgress['if_ctc1city'] : ' - ';
    programData.TEL = projectInProgress['if_ctc1mobilephone'] !== null ? projectInProgress['if_ctc1mobilephone'] : ' - ';
    programData.MAIL = projectInProgress['if_ctc1email1'] !== null ? projectInProgress['if_ctc1email1'] : ' - ';
    // programData.DER = projectInProgress.DocContactDer.statuscode_label !== null ? projectInProgress.DocContactDer.statuscode_label : ' - ';
    programData.DER = ' - ';
    programData.typeImgDer = [];

    if (projectInProgress.sign_if_fluxsiteb2bcode !== null) {
      programData.DER = 'Yes';
      if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER0') {
        programData.typeImgDer.push({
          srcImg: 'assets/DER à envoyer.svg'
        })
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'AT' ) {
        programData.DER = 'A TRAITER ';
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER1' ) {
          programData.typeImgDer.push({
            srcImg: 'assets/DER envoyé.svg'
          })
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER5' ) {
        programData.typeImgDer.push({
          srcImg: 'assets/DER erreur.svg'
        })
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER4' ) {
        programData.typeImgDer.push({
          srcImg: 'assets/DER expiré.svg'
        })
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER3' ) {
        programData.typeImgDer.push({
          srcImg: 'assets/DER refusé.svg'
        })
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER2' ) {
        programData.typeImgDer.push({
          srcImg: 'assets/DER signé.svg'
        })
      } else if (projectInProgress.sign_if_fluxsiteb2bcode == 'DER6' ) {
        programData.typeImgDer.push({
          srcImg: 'assets/DER en attente de signature.svg'
        })
      }
    }

    programData.icon = 'play_arrow';

    programData.ERIP= " - "
    if(this.userService.user.Erip && this.userService.user['partnercontact.if_erip_active'] == 1 )
      {
        programData.ERIP = "Yes";
        var isIdEripIsEmpty = programData.event.if_id_erip_calc == null || programData.event.if_id_erip_calc == ""
        if(isIdEripIsEmpty){
          programData.imgErip = "assets/NEWERIP.png"; // TodoErip :  projectInProgress.If ERIP_Project_id is empty ? CreateNewErip.png : EERIP.png
        }else{
          if(!isIdEripIsEmpty && programData.event.if_erip_projecttoupdate_calc == "750010000")
            programData.imgErip = "assets/DESYNCHROERIP.png";
          else programData.imgErip = "assets/ERIP.png"; // TodoErip :  projectInProgress.If ERIP_Project_id is empty ? CreateNewErip.png : EERIP.png
        }
      }

      if(environment.isCustomerAreaEnabled && programData.event.if_doclib1id){
        programData.DOC = "Yes";
      }
      programData.imgDOC = 'assets/CUSTOMERAREA.svg';


    // Go to element sur le tableaux
    let IdProjectBafUpdate = localStorage.getItem('IdProjectBafUpdate')
    if (IdProjectBafUpdate !== null) {
      if (programData.projectId == IdProjectBafUpdate) {
        setTimeout(() => {
          console.log('service table housing')
          this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
          localStorage.removeItem('IdProjectBafUpdate')
        }, 1000)
      }
    }

    this.ELEMENT_DATA_PROJECT_PROGRAM.push(programData);
    this.activFinishWork++
  }


  getDateLabel(date: any, choice: boolean) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    if (choice) {
      return date.toLocaleDateString('fr-FR', options) + " à " + transformDateFormatHoursPipe(date).toString()
    }
    return date.toLocaleDateString('fr-FR', options) + " - " + transformDateFormatHoursPipe(date).toString()
  }

  // getDateLabelRecall(date: any) {
  //   const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  //   return date.toLocaleDateString('fr-FR', options) + " - " + transformDateFormatHoursPipe2(date).toString()
  // }

  getDateLabelSample(date: any) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('fr-FR', options)
  }

  convertTZ(date: any) {
    // const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
    return new Date(date + ".000Z");   
  }

    uploadRdv(tabRdv: Array<any>) {
      this.ELEMENT_DATA_RDV = new Array<PeriodictElement>();
  
      tabRdv.forEach((rdv) => {
        let programData: PeriodictElement = new PeriodictElement();
        
        programData.event = rdv;

        programData.projectId = rdv.projectData !== undefined ? rdv.projectData.id : '';
        programData.ORIGINE = 'Yes';
        programData.IsCrm = rdv.IsCrm;
        programData.imgOrigine = [];
        if (rdv.IsCrm === true) {
          programData.imgOrigine.push({
            srcImg: 'assets/imgFactory.png'
          });
        }
        if (rdv.IsGoogle === true) {
          programData.imgOrigine.push({
            srcImg: 'assets/calendargoogle.svg'
          })
        }
        programData.STATUT = rdv.status !== null ? rdv.status : 'RDV planifié';
        programData.RDV = rdv.subject;
        programData.CONCERNANT = rdv.project;
        programData.DATE = this.getDateLabelSample(rdv.start) + "    " + transformDateFormatHoursPipe(new Date(rdv.start)).toString() + ' - ' + transformDateFormatHoursPipe(new Date(rdv.end)).toString();
        // programData.DATE = transformDateFormatLetterPipe(new Date(rdv.start)).toString() + " à " + transformDateFormatHoursPipe(new Date(rdv.start)).toString();
        programData.icon = 'play_arrow';

        if (rdv.projectData !== undefined) {
          programData.CLIENT = this.isNotNullAndEmpty(rdv.projectData.firstname) + " " + this.isNotNullAndEmpty(rdv.projectData.lastname);
          programData.addressOne = this.isNotNullAndEmpty(rdv.projectData.addressOne);
          programData.addressTwo = this.isNotNullAndEmpty(rdv.projectData.addressTwo);
          programData.zipCode = this.isNotNullAndEmpty(rdv.projectData.zipCode);
          programData.VILLE = this.isNotNullAndEmpty(rdv.projectData.city);
          programData.tel1 = this.isNotNullAndEmpty(rdv.projectData.phone);
          programData.tel2 = this.isNotNullAndEmpty(rdv.projectData.mobilePhone);
          programData.MAIL = this.isNotNullAndEmpty(rdv.projectData.mail);
          programData['CODE CONCLUSION'] = rdv.projectData['appointment.if_conclusioncodeid_label'] !== null ? rdv.projectData['appointment.if_conclusioncodeid_label'] : ' - ';
          programData['MODALITÉ'] = rdv.projectData['appointment.if_modalities_label'] !== null ? rdv.projectData['appointment.if_modalities_label']  : ' - ';
          programData['TEL MOBILE'] = rdv.projectData['project.if_ctc1mobilephone'] !== null ? rdv.projectData['project.if_ctc1mobilephone']  : ' - ';
  
          programData.statecode = rdv.projectData.statecode
  
          if (rdv.projectData.createAt !== ' - ') {
            programData.dateCreatded = transformDateFormatLetterPipe(new Date(rdv.projectData.createAt)).toString() + " à " + transformDateFormatHoursPipe(new Date(rdv.projectData.createAt)).toString()
          }
        }

        programData.dateCreatded = ' - ';
        programData.createdBy = 'Pas de champ';

        // Go to element sur le tableaux
        let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
        if (IdSaleBafUpdate !== null) {
          if (programData.projectId == IdSaleBafUpdate) {
            setTimeout(() => {
              console.log('service table housing')
              this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
              localStorage.removeItem('IdProjectBafUpdate')
            }, 1000)
          }
        }

        this.ELEMENT_DATA_RDV.push(programData); 
      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_RDV;
      this.configObservableRdv.next(this.ELEMENT_DATA_RDV);
    }

    isNotNullAndEmpty(dataObserve: any) {
      if (dataObserve !== null && dataObserve !== "") {
        return dataObserve;
      } else {
        return "";
      }
    }
  

    // this.columnsToDisplay = ['PROJET', 'DATE OPTION', 'DATE RÉSA', 'PROMOTEUR', 'PROGRAMME', 'N° LOT', 'PRIX TTC', 'FINANCEMENT', 'ACTABLE', 'DATE ACTE', 'icon'];
    uploadSaleReservation(tabReservation: Array<Reservation>) {
      this.ELEMENT_DATA_SALE_PROGRAM = new Array<PeriodictElement>();
  
      tabReservation.forEach((reservation, i) => {
        
        let programData: PeriodictElement = new PeriodictElement();
        
        const newNameLot = reservation['asset.if_assetnumber'] + ' - ' + (reservation['operation.if_developeraccountid_label'] !== null ? reservation['operation.if_developeraccountid_label'].toString() : ' - ');
        
        programData.event2 = reservation
        // client.option.endOption
        programData.dataDialog = { startOption: "Pas de champ", endOption: "Pas de champ", nameLot: newNameLot, nameResidence: reservation['operation.if_operationbusinessname_calc'] !== null ? reservation['operation.if_operationbusinessname_calc'].toString() : ' - ' };
  
        programData.projectId = this.isNotNullAndEmpty(reservation['reservation.if_originatingprojectid']);
        programData.reservationId = this.isNotNullAndEmpty(reservation['reservation.if_reservationid']);

        // programData.PROJET = reservation['project.if_name'] !== null ? reservation['project.if_name'].toString() : ' - ';
        programData.PROJET = this.isNotNullAndEmpty(reservation['contact.lastname']) + " " + this.isNotNullAndEmpty(reservation['contact.firstname']);
        // programData['DATE OPTION'] = reservation['proposition.if_estimatedsigningdate'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['proposition.if_estimatedsigningdate'])) : ' - ';
        programData['DATE RÉSA'] = reservation['reservation.if_signingdate'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['reservation.if_signingdate'])) : ' - ';
        programData['DATE CREA'] = reservation['reservation.createdon'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['reservation.createdon'])) : ' - ';
        programData.dateCreatded = reservation['reservation.createdon'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['reservation.createdon'])) : ' - ';
        programData.dateCreatdedTime = reservation['reservation.createdon'] !== null ? transformDateFormatHoursPipe(this.convertTZ(reservation['reservation.createdon'])).toString() : ' - ';
        programData.PROMOTEUR = reservation['operation.if_developeraccountid_label'] !== null ? reservation['operation.if_developeraccountid_label'].toString() : ' - ';
        programData.PROGRAMME = reservation['operation.if_operationbusinessname_calc'] !== null ? reservation['operation.if_operationbusinessname_calc'].toString() : ' - ';
        programData['N° LOT'] = reservation['asset.if_assetnumber'] !== null ? reservation['asset.if_assetnumber'].toString() : ' - ';
        programData['PRIX TTC'] = reservation['reservation.if_priceselling_tax1_cur'] !== null ? numberWithCommas(reservation['reservation.if_priceselling_tax1_cur']) + " €" : ' - ';
        programData['FINANCEMENT'] = reservation['loan.if_odpprogression_label'] !== null ? reservation['loan.if_odpprogression_label'] : ' - ';

        // programData.ACTABLE = "Non"
        // if (new Date(reservation['operation.if_actabilitydate']).getFullYear() <= new Date().getFullYear()) {
        //   if (new Date(reservation['operation.if_actabilitydate']).getMonth() <= new Date().getMonth()) {
        //     if ( new Date(reservation['operation.if_actabilitydate']).getDate() < new Date().getDate() ) {
        //       programData.ACTABLE = "Oui"      
        //     }
        //   }
        // }

        if(environment.isCustomerAreaEnabled){
          programData.DOC = "Yes";
          programData.imgDOC = 'assets/CUSTOMERAREA.svg';
        }

        programData.ACTABLE = "Non"
        if ( new Date(reservation['operation.if_actabilitydate']) < new Date()) {
          programData.ACTABLE = "Oui"
        }
        
        programData['DATE PREV ACTE'] = reservation['reservation.if_dateprvacte'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['reservation.if_dateprvacte'])) : ' - '; 
        // new Date(reservation['operation.if_actabilitydate']).getDate().toString() + "/" + new Date(reservation['operation.if_actabilitydate']).getMonth().toString() + "/" + new Date(reservation['operation.if_actabilitydate']).getFullYear().toString();
        programData.icon = 'play_arrow';

        programData.CLIENT = reservation['contact.lastname'] + " - " + reservation['contact.firstname'];
        programData.addressOne = reservation['contact.address1_line1'] !== null ? reservation['contact.address1_line1'].toString() : ' - ';
        programData.zipCode = reservation['contact.address1_postalcode'] !== null ? reservation['contact.address1_postalcode'].toString() : ' - ';
        programData.VILLE = reservation['contact.address1_city'] !== null ? reservation['contact.address1_city'].toString() : ' - ';
        programData.tel1 = reservation['contact.telephone2'] !== null ? reservation['contact.telephone2'].toString() : ' - ';
        programData.tel2 = reservation['contact.mobilephone'] !== null ? reservation['contact.mobilephone'].toString() : ' - ';
        programData.MAIL = reservation['contact.emailaddress1'] !== null ? reservation['contact.emailaddress1'].toString() : ' - ';
        programData.ORIGINE = reservation['project.if_mkg_originid_label'] !== null ? reservation['project.if_mkg_originid_label'].toString() : ' - ';
        programData.LOT = reservation['asset.if_assetnumber'] !== null ? reservation['asset.if_assetnumber'].toString() : ' - ';
        programData.actability = reservation['operation.if_actabilitydate'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['operation.if_actabilitydate'])) : ' - ';
        programData.DAACT = reservation['operation.if_completiondate'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['operation.if_completiondate'])) : ' - ';
        
        
        programData.delivery = reservation['operation.if_deliverydate'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['operation.if_deliverydate'])) : ' - ';
        programData.TYPE = reservation['assettype.if_fluxsiteb2blabel'] !== null ? reservation['assettype.if_fluxsiteb2blabel'] : ' - ';
        programData.SHAB = reservation['asset.if_livingspace'] !== null ? reservation['asset.if_livingspace'] + " m²" : ' - ';
        programData.FISCALITE = reservation['tax.if_fluxsiteb2blabel'] !== null ? reservation['tax.if_fluxsiteb2blabel'] : ' - ';
        programData.ETAGE = reservation['stage.if_fluxsiteb2blabel'] !== null ? reservation['stage.if_fluxsiteb2blabel'] : ' - ';
        programData.ORIENTATION = reservation['orientation.if_fluxsiteb2blabel'] !== null ? reservation['orientation.if_fluxsiteb2blabel'] : ' - ';
        programData.budgetTTC = reservation['reservation.if_priceselling_tax1_cur'] !== null ? numberWithCommas(reservation['reservation.if_priceselling_tax1_cur']) + " €" : ' - ';
        if (reservation['asset.if_assetrent'] !== undefined) {
          programData.rent = reservation['asset.if_assetrent'] !== null ? reservation['asset.if_assetrent'].toString() + ' €' : ' - ';
        } else {
          programData.rent = ' - ';
        }
        if (reservation['asset.if_assetrent_profitabilityrate'] !== undefined) {
          programData.RENTA = reservation['asset.if_assetrent_profitabilityrate'] !== null ? reservation['asset.if_assetrent_profitabilityrate'].toString() + ' %' : ' - ';
        } else {
          programData.RENTA = ' - ';
        }
        programData.dateSignClient = reservation['reservation.if_signingdate'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['reservation.if_signingdate'])) : ' - ';
        // programData.signPromoter = "Pas de champ";
        programData.datePrevActe = reservation['reservation.if_dateprvacte'] !== null ? this.getDateLabelSample(this.convertTZ(reservation['reservation.if_dateprvacte'])) : ' - '; 
        programData.financialStatut = reservation['loan.if_odpprogression_label'] !== null ? reservation['loan.if_odpprogression_label'] : ' - ';

        // programData.activity = reservation.if_projectactivity_calc_label !== null ? reservation.if_projectactivity_calc_label : ' - ';
        // programData.r1 = reservation.if_r1_infosprogressrate
        // programData.r2 = reservation.if_r2_infosprogressrate
        // programData.r3 = reservation.if_r3_infosprogressrate
        
        
        // programData.avancement = reservation.Progression.Conclusion.if_fluxsiteb2blabel !== null ? reservation.Progression.Conclusion.if_fluxsiteb2blabel : ' - ';
       
        programData.listRdv = [];
        if (reservation['rdv1.scheduledstart'] !== null) {
          programData.listRdv.push({ 
            start: "le " + this.getDateLabel(this.convertTZ(reservation['rdv1.scheduledstart']), true),
            startCustom: this.getDateLabelSample(this.convertTZ(reservation['rdv1.scheduledstart'])),
            startCustom2: transformDateFormatHoursPipe(this.convertTZ(reservation['rdv1.scheduledstart'])).toString(),
            schedulstart: reservation['rdv1.scheduledstart'] !== null ? reservation['rdv1.scheduledstart'] : ' - ',
            type: reservation['rdv1.if_rdvtype'],
            rdvCode: reservation['rdv1.if_conclusioncodeid_label'],
            id: reservation['rdv1.if_conclusioncodeid'],
            statecode: reservation['rdv1.statecode'],
            text: 'R1'
            // event: { nameProject: "Nom du projet (champ)", startDate: "Date de début (champ)", endDate: "Date de fin (champ)", modality: "Modalité (champ)", location: "Lieu (champ)" }
            // event: id rdv for get rdv with get http client
          })
        }
        if (reservation['rdv2.scheduledstart'] !== null) {
          programData.listRdv.push({ 
            start: "le " + this.getDateLabel(this.convertTZ(reservation['rdv2.scheduledstart']), true),
            startCustom: this.getDateLabelSample(this.convertTZ(reservation['rdv2.scheduledstart'])),
            startCustom2: transformDateFormatHoursPipe(this.convertTZ(reservation['rdv2.scheduledstart'])).toString(),
            schedulstart: reservation['rdv2.scheduledstart'] !== null ? reservation['rdv2.scheduledstart'] : ' - ',
            type: reservation['rdv2.if_rdvtype'],
            rdvCode: reservation['rdv2.if_conclusioncodeid_label'],
            id: reservation['rdv2.if_conclusioncodeid'],
            statecode: reservation['rdv2.statecode'],
            text: 'R2'
            // event: { nameProject: "Nom du projet (champ)", startDate: "Date de début (champ)", endDate: "Date de fin (champ)", modality: "Modalité (champ)", location: "Lieu (champ)" }
            // event: id rdv for get rdv with get http client
          })
        } 
        if (reservation['rdv3.scheduledstart'] !== null) {
          programData.listRdv.push({ 
            start: "le " + this.getDateLabel(this.convertTZ(reservation['rdv3.scheduledstart']), true),
            startCustom: this.getDateLabelSample(this.convertTZ(reservation['rdv3.scheduledstart'])),
            startCustom2: transformDateFormatHoursPipe(this.convertTZ(reservation['rdv3.scheduledstart'])).toString(),
            schedulstart: reservation['rdv3.scheduledstart'] !== null ? reservation['rdv3.scheduledstart'] : ' - ',
            type: reservation['rdv3.if_rdvtype'],
            rdvCode: reservation['rdv3.if_conclusioncodeid_label'],
            id: reservation['rdv3.if_conclusioncodeid'],
            statecode: reservation['rdv3.statecode'],
            text: 'R3'
            // event: { nameProject: "Nom du projet (champ)", startDate: "Date de début (champ)", endDate: "Date de fin (champ)", modality: "Modalité (champ)", location: "Lieu (champ)" }
            // event: id rdv for get rdv with get http client
          })
        }

        this.serviceReservation.getReservationIdActivities(reservation['reservation.if_reservationid']).pipe(first()).subscribe((listActivities: Array<AcitvityReservation>) => {
          console.log(listActivities)
          if (listActivities.length !== 0) {
            let newListActivitySort = listActivities
            newListActivitySort.sort((a, b) => (a['activity.scheduledstart'] < b['activity.scheduledstart'] ? -1 : 1));
            newListActivitySort.forEach((activiy: AcitvityReservation) => {
              if (programData.listRdv !== undefined) {
                programData.listRdv.push({ 
                  start: "le " + this.getDateLabel(this.convertTZ(activiy['activity.scheduledstart']), true),
                  startCustom: activiy['activity.scheduledstart'] !== null ? this.getDateLabelSample(this.convertTZ(activiy['activity.scheduledstart'])) : ' - ',
                  startCustom2: activiy['activity.scheduledstart'] !== null ? transformDateFormatHoursPipe(this.convertTZ(activiy['activity.scheduledstart'])).toString() : ' - ',
                  schedulstart: activiy['activity.scheduledstart'] !== null ? activiy['activity.scheduledstart'] : ' - ',
                  type: activiy['aptypes.if_fluxsiteb2blabel'],
                  rdvCode: activiy['conclusioncode.if_fluxsiteb2blabel'],
                  id: activiy['activity.activityid'],
                  statecode: activiy['activity.if_appointmentpartner_calendar_visibility'],
                  text: activiy['aptypes.if_fluxsiteb2blabel']
                  // event: { nameProject: "Nom du projet (champ)", startDate: "Date de début (champ)", endDate: "Date de fin (champ)", modality: "Modalité (champ)", location: "Lieu (champ)" }
                  // event: id rdv for get rdv with get http client
                })
              }
            })
          } 
        })

        // Go to element sur le tableaux
        let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
        if (IdSaleBafUpdate !== null) {
          if (programData.projectId == IdSaleBafUpdate) {
            setTimeout(() => {
              console.log('service table housing')
              this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
              localStorage.removeItem('IdProjectBafUpdate')
            }, 1000)
          }
        }

        this.ELEMENT_DATA_SALE_PROGRAM.push(programData); 
      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SALE_PROGRAM
      this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
    }

    // this.columnsToDisplay = ['PROJET', 'DATE OPTION', 'DATE RÉSA', 'DATE ACTE', 'PROMOTEUR', 'PROGRAMME', 'N° LOT', 'PRIX TTC', 'DATE LIVRAISON', 'icon'];
    uploadSaleDeed(tabDeeds: Array<Deed>) {
      this.ELEMENT_DATA_SALE_PROGRAM = new Array<PeriodictElement>();
  
      tabDeeds.forEach((deed, i) => {
        
        let programData: PeriodictElement = new PeriodictElement();
        
        const newNameLot = deed['asset.if_assetnumber'] + ' - ' + (deed['operation.if_developeraccountid_label'] !== null ? deed['operation.if_developeraccountid_label'].toString() : ' - ');
        // client.option.endOption
        programData.dataDialog = { startOption: "Pas de champ", endOption: "Pas de champ", nameLot: newNameLot, nameResidence: deed['operation.if_operationbusinessname_calc'] !== null ? deed['operation.if_operationbusinessname_calc'].toString() : ' - ' };
  
        programData.PROJET = this.isNotNullAndEmpty(deed['contact.lastname']) + " " + this.isNotNullAndEmpty(deed['contact.firstname'])
        // programData['DATE OPTION'] = deed['proposition.if_estimatedsigningdate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['proposition.if_estimatedsigningdate'])) : ' - ';
        programData['DATE RÉSA'] = deed['reservation.if_signingdate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['reservation.if_signingdate'])) : ' - ';
        programData.PROMOTEUR = deed['operation.if_developeraccountid_label'] !== null ? deed['operation.if_developeraccountid_label'].toString() : ' - ';
        programData.PROGRAMME = deed['operation.if_operationbusinessname_calc'] !== null ? deed['operation.if_operationbusinessname_calc'].toString() : ' - ';
        programData['N° LOT'] = deed['asset.if_assetnumber'] !== null ? deed['asset.if_assetnumber'].toString() : ' - ';
        programData['PRIX TTC'] = deed['deed.if_pricenetselling_tax1_cur'] !== null ? numberWithCommas(Math.round(deed['deed.if_pricenetselling_tax1_cur'])) + " € TTC" : ' - ';
        programData['DATE LIVRAISON'] = deed['deed.if_deliverydate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['deed.if_deliverydate'])) : ' - ';
        programData['DATE ACTE'] = deed['deed.if_actdate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['deed.if_actdate'])) : ' - ';
        programData.icon = 'play_arrow';

        programData.CLIENT = deed['contact.lastname'] + " - " + deed['contact.firstname'];
        programData.address = deed['contact.address1_line1'] !== null ? deed['contact.address1_line1'].toString() : ' - ';
        programData.zipCode = deed['contact.address1_postalcode'] !== null ? deed['contact.address1_postalcode'].toString() : ' - ';
        programData.VILLE = deed['contact.address1_city'] !== null ? deed['contact.address1_city'].toString() : ' - ';
        programData.tel1 = deed['contact.telephone2'] !== null ? deed['contact.telephone2'].toString() : ' - ';
        programData.tel2 = deed['contact.mobilephone'] !== null ? deed['contact.mobilephone'].toString() : ' - ';
        programData.MAIL = deed['contact.emailaddress1'] !== null ? deed['contact.emailaddress1'].toString() : ' - ';
        programData.ORIGINE = deed['project.if_mkg_originid_label'] !== null ? deed['project.if_mkg_originid_label'].toString() : ' - ';
        programData.LOT = deed['asset.if_assetnumber'] !== null ? deed['asset.if_assetnumber'].toString() : ' - ';
        programData.actability = deed['operation.if_actabilitydate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['operation.if_actabilitydate'])) : ' - ';
        programData.DAACT = deed['operation.if_completiondate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['operation.if_completiondate'])) : ' - ';
        programData.delivery = deed['deed.if_deliverydate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['deed.if_deliverydate'])) : ' - ';
        programData.FISCALITE = deed['taxlaw.if_fluxsiteb2blabel'] !== null ? deed['taxlaw.if_fluxsiteb2blabel'] : ' - ';
        programData.TYPE = deed['assettype.if_fluxsiteb2blabel'] !== null ? deed['assettype.if_fluxsiteb2blabel'] : ' - ';
        programData.SHAB = deed['asset.if_livingspace'] !== null ? deed['asset.if_livingspace'] + " m²" : ' - ';
        programData.ETAGE = deed['stage.if_fluxsiteb2blabel'] !== null ? deed['stage.if_fluxsiteb2blabel'] : ' - ';
        programData.ORIENTATION = deed['orientation.if_fluxsiteb2blabel'] !== null ? deed['orientation.if_fluxsiteb2blabel'] : ' - ';

        programData.budgetTTC = deed['deed.if_pricenetselling_tax1_cur'] !== null ? numberWithCommas(Math.round(deed['deed.if_pricenetselling_tax1_cur'])) + " € TTC" : ' - ';
        programData.rent = deed['asset.if_assetrent'] !== null ? deed['asset.if_assetrent'].toString() + ' €' : ' - ';
        programData.RENTA = deed['asset.if_assetrent_profitabilityrate'] !== null ? deed['asset.if_assetrent_profitabilityrate'].toString() + ' %' : ' - ';;
        programData.dateSignClient =  deed['reservation.if_signingdate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['reservation.if_signingdate'])) : ' - ';
        // programData.signPromoter = "Pas de champ";
        
        // programData.dateActability = deed['operation.if_actabilitydate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['operation.if_actabilitydate'])) : ' - ';
        programData.dateActability = deed['deed.if_actdate'] !== null ? this.getDateLabelSample(this.convertTZ(deed['deed.if_actdate'])) : ' - ';

        programData.projectId = deed['deed.if_deedid'];
        // Go to element sur le tableaux
        let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
        if (IdSaleBafUpdate !== null) {
          if (programData.projectId == IdSaleBafUpdate) {
            setTimeout(() => {
              console.log('service table housing')
              this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
              localStorage.removeItem('IdProjectBafUpdate')
            }, 1000)
          }
        }

        this.ELEMENT_DATA_SALE_PROGRAM.push(programData); 
      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SALE_PROGRAM
      this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
    }

    // this.columnsToDisplay = ['PROJET', 'DATE FIN OPT.', 'RANG', 'PROGRAMME', 'N° LOT', 'PRIX TTC', 'FISCALITÉ', 'STATUT', 'icon'];
    uploadSaleOptionInProgress(tabClient: Array<PropositionInProgress>) {
      this.ELEMENT_DATA_SALE_PROGRAM = new Array<PeriodictElement>();
  
      tabClient.forEach((client, i) => {
       
        let programData: PeriodictElement = new PeriodictElement();

        // programData.dataDialog = { startOption: client.option.startOption, endOption: client.option.endOption, nameLot: newNameLot + ' - ' + programOfClient.namePromoter,  nameResidence: programOfClient.residence };
  
        programData.projectId = client['proposition.if_projectid'];
        programData.propositionId = client['proposition.if_propositionid'];
        programData.statecode = client['proposition.statuscode'];

        programData.PROJET = this.isNotNullAndEmpty(client['project.if_ctc1lastname']) + " " + this.isNotNullAndEmpty(client['project.if_ctc1firstname']);
        programData['DATE FIN OPT.'] = client['proposition.if_finalenddate'] !== null ? this.getDateLabelSample(this.convertTZ(client['proposition.if_finalenddate'])).toString() + " à " + transformDateFormatHoursPipe(this.convertTZ(client['proposition.if_finalenddate'])).toString() : " - ";
        programData.RANG = this.isNotNullAndEmpty(client['proposition.if_rank_calc']).toString();
        programData.PROGRAMME = client['operation.if_operationbusinessname_calc'] !== null ? client['operation.if_operationbusinessname_calc'] : ' - ';
        programData['N° LOT'] = client['asset.if_assetnumber'] !== null ? client['asset.if_assetnumber'] : ' - ';
        programData['PRIX TTC'] = client['price.if_pricecom_tax1_cur'] !== null ? numberWithCommas(client['price.if_pricecom_tax1_cur']) + ' €' : ' - ';
        programData.FISCALITÉ = client['taxlaw.if_fluxsiteb2blabel'] !== null ? client['taxlaw.if_fluxsiteb2blabel'] :  ' - ';
        programData.STATUT = client['proposition.statuscode_label'] !== null ? client['proposition.statuscode_label'] : ' - ';
        programData.icon = 'play_arrow';

        programData.lastname = this.isNotNullAndEmpty(client['project.if_ctc1lastname']);
        programData.firstname = this.isNotNullAndEmpty(client['project.if_ctc1firstname']);
        programData.addressOne = client['project.if_ctc1_street1'] !== null ? client['project.if_ctc1_street1'] : ' - ';
        programData.addressTwo = client['project.if_ctc1_street2'] !== null ? client['project.if_ctc1_street2'] : ' - ';
        programData.zipCode = client['project.if_ctc1_postalcode'] !== null ? client['project.if_ctc1_postalcode'] : ' - ';
        programData.VILLE = client['project.if_ctc1city'] !== null ? client['project.if_ctc1city'] : ' - ';
        programData.tel1 = client['project.if_ctc1personnalphone'] !== null ? client['project.if_ctc1personnalphone'] : ' - ';
        programData.tel2 = client['project.if_ctc1mobilephone'] !== null ? client['project.if_ctc1mobilephone'] : ' - ';
        programData.MAIL = client['project.if_ctc1email1'] !== null ? client['project.if_ctc1email1'] : ' - ';
        programData.ORIGINE = client['project.if_mkg_originid_label'] !== null ? client['project.if_mkg_originid_label'] : ' - ';
        
        programData.PROMOTEUR = client['operation.if_developeraccountid_label'] !== null ? client['operation.if_developeraccountid_label'] : ' - ';
        programData.dateActability = client['operation.if_actabilitydate'] !== null ? this.getDateLabelSample(this.convertTZ(client['operation.if_actabilitydate'])).toString() : ' - ';
        programData.DAACT = client['operation.if_completiondate'] !== null ? this.getDateLabelSample(this.convertTZ(client['operation.if_completiondate'])).toString() : ' - ';
        programData.datedelivery = client['operation.if_deliverydate'] !== null ? this.getDateLabelSample(this.convertTZ(client['operation.if_deliverydate'])).toString() : ' - ';
        programData.TYPE = client['assettype.if_fluxsiteb2blabel'] !== null ? client['assettype.if_fluxsiteb2blabel'] : ' - ';
        programData.SHAB = client['asset.if_livingspace'] !== null ? client['asset.if_livingspace'].toString() + " m²" : ' - ';
        programData.ETAGE = client['stage.if_fluxsiteb2blabel'] !== null ? client['stage.if_fluxsiteb2blabel'] : ' - ';
        programData.ORIENTATION = client['orientation.if_fluxsiteb2blabel'] !== null ? client['orientation.if_fluxsiteb2blabel'] : ' - ';
        programData.LOYER = client['asset.if_assetrent'] !== null ? client['asset.if_assetrent'] + " €" : ' - ';
        programData.renta = client['asset.if_assetrent_profitabilityrate'] !== null ? client['asset.if_assetrent_profitabilityrate'] + " %" : ' - ';
        programData.PRIX = client['price.if_pricecom_tax0_cur'] !== null ? numberWithCommas(client['price.if_pricecom_tax0_cur']) + " €" : ' - ';

        programData.FISCALITE = client['taxlaw.if_fluxsiteb2blabel'] !== null ? client['taxlaw.if_fluxsiteb2blabel'] :  ' - ';
        programData.LOT = client['asset.if_assetnumber'] !== null ? client['asset.if_assetnumber'] : ' - ';
        programData.budgetTTC = client['price.if_pricecom_tax1_cur'] !== null ? numberWithCommas(client['price.if_pricecom_tax1_cur']) + " €" : ' - ';

        programData.startOptionData = client['proposition.if_optiondate'] !== null ? this.convertTZ(client['proposition.if_optiondate']) : null;
        programData.startOption = client['proposition.if_optiondate'] !== null ? this.getDateLabelSample(this.convertTZ(client['proposition.if_optiondate'])).toString() + " à " + transformDateFormatHoursPipe(this.convertTZ(client['proposition.if_optiondate'])).toString() : " - ";
        programData.endOption = client['proposition.if_finalenddate'] !== null ? this.getDateLabelSample(this.convertTZ(client['proposition.if_finalenddate'])).toString() + " à " + transformDateFormatHoursPipe(this.convertTZ(client['proposition.if_finalenddate'])).toString() : " - ";
  
        programData.btnShowDocuSign = client['operation.if_docusign_active'];

        programData.btnShowResa = client['proposition.if_show_bt_prebooking'];

        programData.urlDocuSign = client['docbooking.if_docusign_envelope_url'] !== null ? client['docbooking.if_docusign_envelope_url'] : '';
        programData.idDocuSign = client['docbooking.if_doc_booking_contractid'] !== null ? client['docbooking.if_doc_booking_contractid'] : '';
        programData.statusCodeDocuSign = client['signstatuscode.if_code'] !== null ? client['signstatuscode.if_code'] : '';

        // Go to element sur le tableaux
        let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
        if (IdSaleBafUpdate !== null) {
          if (programData.projectId == IdSaleBafUpdate) {
            setTimeout(() => {
              console.log('service table housing')
              this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
              localStorage.removeItem('IdProjectBafUpdate')
            }, 1000)
          }
        }

        this.ELEMENT_DATA_SALE_PROGRAM.push(programData);
      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SALE_PROGRAM
      this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
    }

    // this.columnsToDisplay = ['PROJET', 'DATE OPTION', 'DATE PRÉ-RÉSA', 'PROGRAMME', 'N° LOT', 'PRIX TTC', 'FISCALITÉ', 'STATUT', 'icon'];
    uploadSalePrebooking(tabClient: Array<PropositionPrebooking>) {
      this.ELEMENT_DATA_SALE_PROGRAM = new Array<PeriodictElement>();
  
      tabClient.forEach((client, i) => {

        let programData: PeriodictElement = new PeriodictElement();
        
        // programData.dataDialog = { startOption: client.option.startOption, endOption: client.option.endOption, nameLot: newNameLot + ' - ' + programOfClient.namePromoter,  nameResidence: programOfClient.residence };
  
        programData.projectId = client['proposition.if_projectid'];

        programData.PROJET = this.isNotNullAndEmpty(client['project.if_ctc1lastname']) + " " + this.isNotNullAndEmpty(client['project.if_ctc1firstname']);
        programData['DATE OPTION'] = client['proposition.if_optiondate'] !== null ? this.getDateLabelSample(this.convertTZ(client['proposition.if_optiondate'])).toString() + " à " + transformDateFormatHoursPipe(this.convertTZ(client['proposition.if_optiondate'])).toString() : " - ";
        programData['DATE PRÉ-RÉSA'] = client['proposition.if_vnrdate'] !== null ? this.getDateLabelSample(this.convertTZ(client['proposition.if_vnrdate'])).toString() + " à " + transformDateFormatHoursPipe(this.convertTZ(client['proposition.if_vnrdate'])).toString() : " - ";
        programData.PROGRAMME = client['operation.if_operationbusinessname_calc'] !== null ? client['operation.if_operationbusinessname_calc'] : ' - ';
        programData['N° LOT'] = client['asset.if_assetnumber'] !== null ? client['asset.if_assetnumber'] : ' - ';
        programData['PRIX TTC'] = client['price.if_pricecom_tax1_cur'] !== null ? numberWithCommas(client['price.if_pricecom_tax1_cur']) + ' €' : ' - ';
        programData.FISCALITÉ = client['taxlaw.if_fluxsiteb2blabel'] !== null ? client['taxlaw.if_fluxsiteb2blabel'] :  ' - ';
        programData.STATUT = client['proposition.statuscode_label'] !== null ? client['proposition.statuscode_label'] : ' - ';
        programData.icon = 'play_arrow';

        programData.lastname = this.isNotNullAndEmpty(client['project.if_ctc1lastname']);
        programData.firstname = this.isNotNullAndEmpty(client['project.if_ctc1firstname']);
        programData.addressOne = client['project.if_ctc1_street1'] !== null ? client['project.if_ctc1_street1'] : ' - ';
        programData.addressTwo = client['project.if_ctc1_street2'] !== null ? client['project.if_ctc1_street2'] : ' - ';
        programData.zipCode = client['project.if_ctc1_postalcode'] !== null ? client['project.if_ctc1_postalcode'] : ' - ';
        programData.VILLE = client['project.if_ctc1city'] !== null ? client['project.if_ctc1city'] : ' - ';
        programData.tel1 = client['project.if_ctc1personnalphone'] !== null ? client['project.if_ctc1personnalphone'] : ' - ';
        programData.tel2 = client['project.if_ctc1mobilephone'] !== null ? client['project.if_ctc1mobilephone'] : ' - ';
        programData.MAIL = client['project.if_ctc1email1'] !== null ? client['project.if_ctc1email1'] : ' - ';
        programData.ORIGINE = client['project.if_mkg_originid_label'] !== null ? client['project.if_mkg_originid_label'] : ' - ';
        
        programData.PROMOTEUR = client['operation.if_developeraccountid_label'] !== null ? client['operation.if_developeraccountid_label'] : ' - ';
        programData.dateActability = client['operation.if_actabilitydate'] !== null ? this.getDateLabelSample(this.convertTZ(client['operation.if_actabilitydate'])).toString() : ' - ';
        programData.DAACT = client['operation.if_completiondate'] !== null ? this.getDateLabelSample(this.convertTZ(client['operation.if_completiondate'])).toString() : ' - ';
        programData.datedelivery = client['operation.if_deliverydate'] !== null ? this.getDateLabelSample(this.convertTZ(client['operation.if_deliverydate'])).toString() : ' - ';
        programData.TYPE = client['assettype.if_fluxsiteb2blabel'] !== null ? client['assettype.if_fluxsiteb2blabel'] : ' - ';
        programData.SHAB = client['asset.if_livingspace'] !== null ? client['asset.if_livingspace'].toString() + " m²" : ' - ';
        programData.ETAGE = client['stage.if_fluxsiteb2blabel'] !== null ? client['stage.if_fluxsiteb2blabel'] : ' - ';
        programData.ORIENTATION = client['orientation.if_fluxsiteb2blabel'] !== null ? client['orientation.if_fluxsiteb2blabel'] : ' - ';
        programData.LOYER = client['asset.if_assetrent'] !== null ? client['asset.if_assetrent'] + " €" : ' - ';
        programData.renta = client['asset.if_assetrent_profitabilityrate'] !== null ? client['asset.if_assetrent_profitabilityrate'] + " %" : ' - ';
        programData.PRIX = client['price.if_pricecom_tax0_cur'] !== null ? numberWithCommas(client['price.if_pricecom_tax0_cur']) + " €" : ' - ';

        programData.FISCALITE = client['taxlaw.if_fluxsiteb2blabel'] !== null ? client['taxlaw.if_fluxsiteb2blabel'] :  ' - ';
        programData.LOT = client['asset.if_assetnumber'] !== null ? client['asset.if_assetnumber'] : ' - ';
        programData.budgetTTC = client['price.if_pricecom_tax1_cur'] !== null ? numberWithCommas(client['price.if_pricecom_tax1_cur']) + " €" : ' - ';

        programData.dateSignClient = client['proposition.if_signingdate'] !== null ? this.getDateLabelSample(this.convertTZ(client['proposition.if_signingdate'])).toString() : ' - ';
  
        // Go to element sur le tableaux
        let IdProjectBafUpdate = localStorage.getItem('IdProjectBafUpdate')
        if (IdProjectBafUpdate !== null) {
          if (programData.projectId == IdProjectBafUpdate) {
            setTimeout(() => {
              console.log('service table housing')
              this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
              localStorage.removeItem('IdProjectBafUpdate')
            }, 1000)
          }
        }
      
        this.ELEMENT_DATA_SALE_PROGRAM.push(programData); 

      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SALE_PROGRAM
      this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
    }


    // this.columnsToDisplay = ['PROJET', 'SOLUTION', 'PRODUIT', 'DATE SIGN.', 'DATE RETRACT.', 'DATE VALID', 'COLLECTE', 'icon'];
    uploadSalePlacement(tabClient: Array<InvestmentSale>) {
      this.ELEMENT_DATA_SALE_PROGRAM = new Array<PeriodictElement>();
  
      tabClient.forEach((client: InvestmentSale, i) => {
          let programData: PeriodictElement = new PeriodictElement();

          programData.PROJET = client.project.if_ctc1lastname + ' ' + client.project.if_ctc1firstname;
          programData['AVANCEMENT'] = client.conclusion.if_fluxsiteb2blabel !== null ? client.conclusion.if_fluxsiteb2blabel : ' - ';
          
          
          // Changer le champ c'est celui de solution en attente de martin 
          programData['N° CONTRAT'] = client['if_contractnumber']



          programData.PRODUIT = client.investproduct.if_name;
          programData['DATE SIGN.'] = client['if_signingdate'] !== null ? this.getDateLabelSample(this.convertTZ(client['if_signingdate'])) : ''
          programData['DATE RETRACT.'] = client['if_retractiondate'] !== null ? this.getDateLabelSample(this.convertTZ(client['if_retractiondate'])) : ''
          programData['DATE VALID'] = client['if_validationdate'] !== null ? this.getDateLabelSample(this.convertTZ(client['if_validationdate'])) : ''
          programData.COLLECTE = client['if_total_operationamount_cum'] !== null ? numberWithCommas(Math.round(parseInt(client['if_total_operationamount_cum']))) + ' €' : 0 + ' €'
          programData.Statut = client['statuscode_label']

          if(environment.isCustomerAreaEnabled && client.project.if_doclib1id){
            programData.DOC = "Yes";
            programData.imgDOC = 'assets/CUSTOMERAREA.svg';
          }
          programData.dataPlacement = client

          let listOperationPlacement: Array<any> = []
          // client.InvestmentOperations.forEach((clientInvest: any) => {
          //   let programDataInvest: any = {
          //     operation: clientInvest.if_operationnature_label,
          //     startDate: clientInvest.if_startdate !== null ? this.getDateLabelSample(this.convertTZ(clientInvest.if_startdate)) : '',
          //     endDate: clientInvest.if_enddate !== null ? this.getDateLabelSample(this.convertTZ( clientInvest.if_enddate)) : "",
          //     montant: numberWithCommas(Math.round(clientInvest.if_amount !== null ? parseInt(clientInvest.if_amount) : 0)) + ' €',
          //     periodicity: clientInvest.if_periodicity_label !== null ? clientInvest.if_periodicity_label : "",
          //     renta: clientInvest.if_feesrate !== null ? clientInvest.if_feesrate + "%" : "0" + "%",
          //     cumul: numberWithCommas(Math.round(clientInvest.if_totalamount_calc !== null ? parseInt(clientInvest.if_totalamount_calc) : 0)) + ' €',
          //     operationPlacementId: client['if_investmentsaleid']
          //   }
            
          //   listOperationPlacement.push(programDataInvest); 
          // });

          programData.arrayOperationPlacement = listOperationPlacement

          programData.projectId = client.if_investmentsaleid;
          // Go to element sur le tableaux
          let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
          if (IdSaleBafUpdate !== null) {
            if (programData.projectId == IdSaleBafUpdate) {
              setTimeout(() => {
                console.log('service table housing')
                this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
                localStorage.removeItem('IdProjectBafUpdate')
              }, 1000)
            }
          }
        

          this.ELEMENT_DATA_SALE_PROGRAM.push(programData); 
    
      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SALE_PROGRAM
      this.configObservableSaleProgram.next(this.ELEMENT_DATA_SALE_PROGRAM)
    }


    uploadOperationPlacement(tabClient: Array<any>) {
      this.ELEMENT_DATA_SALE_PROGRAM = new Array<PeriodictElement>();

      
      tabClient[0].listInvestment.forEach((client: InvestmentOperation) => {
        let programData: PeriodictElement = new PeriodictElement();
        programData.if_commercialassignmentid = client.if_commercialassignmentid;
        programData.Opération = client.if_operationnature_label;
        programData.Conseiller = client.if_commerciallastname !== null ? client.if_commerciallastname + ' ' + client.if_commercialfirstname : ' - ';
        programData['Date d\'opération'] = client.if_operationdate !== null ? this.getDateLabelSample(this.convertTZ(client.if_operationdate)) : ' - ';
        programData['Date de début'] = client.if_startdate !== null ? this.getDateLabelSample(this.convertTZ(client.if_startdate)) : "";
        programData['Date de fin'] = client.if_enddate !== null ? this.getDateLabelSample(this.convertTZ(client.if_enddate)) : "";
        programData.Montant = numberWithCommas(Math.round(client.if_amount !== null ? parseInt(client.if_amount) : 0)) + ' €';
        programData.Périodicité = client.if_periodicity_label !== null ? client.if_periodicity_label : "";
        programData['% Co.'] = client.if_feesrate !== null ? client.if_feesrate + "%" : "0" + "%";
        programData.Cumul = numberWithCommas(Math.round(client.if_totalamount_calc !== null ? parseInt(client.if_totalamount_calc) : 0)) + ' €';
        programData.Statut = client.statuscode_label
        programData.operationPlacementId = client.if_investmentoperationid;
        programData.statecode = client.statecode;

        programData.projectId = client.if_commercialassignmentid;
        // Go to element sur le tableaux
        let IdSaleBafUpdate = localStorage.getItem('IdProjectBafUpdate')
        if (IdSaleBafUpdate !== null) {
          if (programData.projectId == IdSaleBafUpdate) {
            setTimeout(() => {
              console.log('service table housing')
              this.serviceConfigObservable.emitGoToElementUpdateBaf(programData)
              localStorage.removeItem('IdProjectBafUpdate')
            }, 1000)
          }
        }

        this.ELEMENT_DATA_SALE_PROGRAM.push(programData); 
      });
  
      this.ELEMENT_DATA_LOAD = this.ELEMENT_DATA_SALE_PROGRAM
      this.configObservableOperationPlacement.next(this.ELEMENT_DATA_SALE_PROGRAM)
    }
}
