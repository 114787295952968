import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AuthStorageService } from './auth-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthCasApereoService {

  constructor(private authStorage: AuthStorageService) { }

  verifyLogin(): Promise<any> {
    if (!this.isAuthenticated()) {
      this.openLogin();
    }
    return this.validateLogin();
  }

  validateLogin(): Promise<any> {
    let promise = new Promise((resolve, reject) => {});
    return promise;
  }

  openLogin() {
    window.open(this.getUrlLogin(), '_self');
  }

  logout() {
    this.authStorage.removeToken();
    window.open(environment.casUrl + 'cas/logout?service=' + environment.appUrl, '_self');
  }

  getUrlLogin(): string {
    return environment.casUrl + 'cas/oauth2.0/authorize?service=' + environment.casUrl + 'cas/oauth2.0/callbackAuthorize&client_id=' + environment.client_id + '&redirect_uri=' + environment.appUrl + environment.callback + "&response_type=token&client_name=CasOAuthClient";
  }

  isAuthenticated(): boolean {
    return this.isNotEmpty(this.authStorage.getToken());
  }

  isEmpty(obj: any): boolean {
    return obj == undefined || obj == null || obj == '' || obj == ' ';
  }

  isNotEmpty(obj: any): boolean {
    return !this.isEmpty(obj);
  }
}
