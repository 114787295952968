import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class InvestService {

  environment: any = null;

	constructor(private http: HttpClient, private authentificationService: AuthentificationService) {
    this.environment = environment;
  }

  public getInvestProduct(idTypeProduct: string): Observable<any> {
    const headers = this.authentificationService.headers;

    let postIdTypeProduct: any = {}

      if (idTypeProduct !== "") {
        postIdTypeProduct = {
          "if_investmenttypeid": idTypeProduct
        }
      }

    return this.http
      .post(this.environment.urlServer + 'InvestProducts', postIdTypeProduct, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getInvestProductsDocuments(idDocuments: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'InvestProducts/' + idDocuments + '/Documents', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public postInvestSale(Page: number, PageSize: number, dataResearchSalesPlacement: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'InvestSales/Search?Page=' + Page + '&PageSize=' + PageSize, dataResearchSalesPlacement, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public postInvestSaleSearchCount(dataResearchSalesPlacement: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'InvestSales/SearchCount', dataResearchSalesPlacement, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getInvestSaleIndicator(): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'InvestSales/Indicators', { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public getInvestSaleId(id: string): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .get(this.environment.urlServer + 'InvestSales/' + id, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }


  public postInvestPlacement(dataPlacement: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'InvestSales/', dataPlacement, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public putInvestPlacement(id: string, dataPlacement: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .put(this.environment.urlServer + 'InvestSales/' + id, dataPlacement, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public postInvestOperation(dataPlacement: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .post(this.environment.urlServer + 'InvestOperations/', dataPlacement, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

  public putInvestOperation(id: string, dataPlacement: any): Observable<any> {
    const headers = this.authentificationService.headers;

    return this.http
      .put(this.environment.urlServer + 'InvestOperations/' + id, dataPlacement, { headers: headers, observe: 'response' })  
      .pipe(map((response: HttpResponse<any>) => {
          console.log(response.body);
          return response.body.datas;
        })
      );
  }

}
