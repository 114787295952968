import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor() { }

  errorMessageGetSalesOffers = 'erreur s\’est produite, les données des offres commerciale enregistrées n\'ont pas été récupérées'

  errorMessagePostCreateDenunciation = 'Une erreur s\'est produite, les données saisies n\'ont pas été enregistrées';

  errorMessageDeleteFavorite = 'Une erreur s\’est produite, pour supprimer le favoris sélectionné';

  errorMessagePostFavoriteOperation = "Une erreur s\’est produite, les données de l'opération en favories saisie n\'ont pas été enregistrées"

  errorMessageGetAllFavorite = "Une erreur s\’est produite, les données de favories en cours enregistrées n\'ont pas été récupérées"

  errorMessageGetListAssetComparator = 'Un lot a été retiré de votre liste car il n\'est plus diffusé';

  errorMessageComparationListCompleted = 'Votre liste de comparaison est pleine, Veuillez supprimer un lot';

  errorMessageComparationRemoveAssetList = 'Un problème s\'est produit, Votre liste de comparaison est trop pleine, Veuillez vider votre cache';
  
  errorMessageCivilityEmpty = 'Veuillez renseigner votre civilité !';

  errorMessageChangePassword = 'Veuillez vérifier votre mot de passe !';

  errorMessageGetEripIsConnected = 'Partenaire non trouvé ! E-rip non-disponible !';

  /************************************************************ Page project ( PROJET ) *********************************************************************************************/

  // Root use ==> GEST OPTION ==> api/Propositions/InProgress
  errorMessageGetPropositionInProgress = 'Une erreur s\’est produite, les données de la proposition en cours enregistrée n\'ont pas été récupérées';

  // Root use ==> GEST OPTION ==> api/Propositions/Prebooking
  errorMessageGetPropositionPrebooking = 'Une erreur s\’est produite, les données de pré-réservation enregistrées n\'ont pas été récupérées';


  /************************************************************ Page project ( PROJET ) *********************************************************************************************/

  // Root use ==> api/Projects/Search/ToDo
  errorMessageGetProjectToDo = 'Une erreur s\’est produite, les données de projets à rappeler enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Indicators/5 (ToDo)
  errorMessageGetNbProjectToDo = 'Une erreur s\’est produite, les données de nombre de projets à rappeler enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/InProgress/Search/
  errorMessageGetProjectInProgress = 'Une erreur s\’est produite, les données de projets en cours enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Indicators/1 (InProgress)
  errorMessageGetNbProjectInProgress = 'Une erreur s\’est produite, les données de nombre de projets en cours enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Indicators/2 (Win)
  errorMessageGetProjectWin = 'Une erreur s\’est produite, les données de projets gagnés enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/TabCounter/New
  errorMessageGetNbProjectNew = 'Une erreur s\’est produite, les données de nombre de nouveaux projets enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Search/New
  errorMessageGetProjectNew = 'Une erreur s\’est produite, les données de nouveaux projets enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Search/Win
  errorMessageGetNbProjectWin = 'Une erreur s\’est produite, les données de nombre de projets gagnés enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Search/Lost
  errorMessageGetProjectLost = 'Une erreur s\’est produite, les données de projets perdus enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Indicators/3 (Lost)
  errorMessageGetNbProjectLost = 'Une erreur s\’est produite, les données de nombre de projets perdus enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Indicators/6 (Anomalies)
  errorMessageGetNbProjectAnomalies = 'Une erreur s\’est produite, les données de nombre de projets avec des anomalies enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Projects/Search/6 (Anomalies)
  errorMessageGetProjectAnomalies = 'Une erreur s\’est produite, les données de nombre de projets avec des anomalies enregistrées n\'ont pas été récupérées';

//   EnCours = 1,
// Gagne = 2,
// Perdu = 3,
// Nouveau = 4,
// ToDo = 5,
// Anomalie = 6

 /************************************************************ Page project ( PROJET ) *********************************************************************************************/

  // Root use ==> api/Projects/{ Id }/Close + JSON dataProjectClose (voir swagger)
  errorMessageProjectCloseProject = 'Une erreur s\’est produite, les données de ventes des options en cours enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Propositions/InProgress/Indicator
  errorMessageGetPropositionInProgressIndicator = 'Une erreur s\’est produite, les données de ventes des options en cours enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Propositions/PreBooking/Indicator
  errorMessageGetPropositionPrebookingIndicator = 'Une erreur s\’est produite, les données de nombre de ventes des pré-résas en cours enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Reservations/Indicators
  errorMessageGetReservationIndicators = 'Une erreur s\’est produite, les données de ventes des réservations en cours enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Deeds/Indicators
  errorMessageGetActesIndicators = 'Une erreur s\’est produite, les données de nombre de ventes des actes enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Reservations
  errorMessagePostPropositionReservation = 'Une erreur s\’est produite, les données de réservations enregistrées n\'ont pas été récupérées';

  // Root use ==> api/InvestSales/Indicators
  errorMessageGetInvestSalesIndicator = 'Une erreur s\’est produite, les données de nombre de ventes des placments enregistrées n\'ont pas été récupérées';

  // Root use ==> api/InvestSales
  errorMessageGetInvestSales = 'Une erreur s\’est produite, les données de placements enregistrées n\'ont pas été récupérées';

  // Root use ==> api/Deeds/Search
  errorMessageGetDeedsSearch = 'Une erreur s\’est produite, les données d\'actes enregistrées n\'ont pas été récupérées';
  
  /************************************************************ Page placement ( PLACEMENTS ) *********************************************************************************************/

  // Root use ==> api/Invests/Products
  errorMessageGetInvestProduct = 'Une erreur s\’est produite, les données de placements enregistrées n\'ont pas été récupérées';

  /************************************************************ Component : tablehousing save search ( STOCK ) *********************************************************************************************/

  // Root use Delete ==> api/Research/{id}
  errorMessageDeleteResearchSaveWithId = 'Une erreur s\’est produite, pour supprimer la recherche sélectionnée';

  /************************************************************ Component : popup search save ( STOCK, PROJETS ) *********************************************************************************************/

  // Root use ==> GEST OPTION ==> api/Parameters
  errorMessageGetParametersOption = 'Une erreur s\’est produite, les données pour le paramétrage d\'une option n\'ont pas été récupérées';

  // Root use ==>  api/Projects/ToPropositions/{id}
  errorMessageGetReferentialProjectOption = 'Une erreur s\’est produite, les données pour la selection d\'un projet pour une option n\'ont pas été récupérées';

  // Root use PUT ==> GEST OPTION ==> api/Propositions/CreateProposition + JSON Proposition (voir swagger)
  errorMessagePutCreateProposition = 'Une erreur s\’est produite, les données saisies pour modifier une option n\'ont pas été enregistrées';

  // Root use POST ==> GEST OPTION ==> api/Propositions/CancelProposition + JSON Proposition (voir swagger)
  errorMessagePostCancelProposition = 'Une erreur s\’est produite, les données saisies pour fermer une option n\'ont pas été enregistrées';

  // Root use ==>  api/Research
  errorMessageGetResearchSave = 'Une erreur s\’est produite, les données de recherche enregistrées n\'ont pas été récupérées';

  /************************************************************ Component : popup create new project ( PROJETS ) *********************************************************************************************/
  
  // Root use POST ==>  api/Projects/Create + JSON Project (voir swagger)
  errorMessagePostProject = 'Une erreur s\’est produite, les données saisies n\'ont pas été enregistrées';

  /************************************************************ Component : popup form sale ( VENTES ) *********************************************************************************************/

  // Root use POST ==> GEST OPTION ==> api/Propositions/PreReservation + JSON Proposition (voir swagger)
  errorMessagePostPropositionPreReservation = 'Une erreur s\’est produite, les données saisies n\'ont pas été enregistrées';

  /************************************************************ Component : popup form rdv ( RDV ) *********************************************************************************************/
  // Root use ==> api/Projects/AppointmentLocations
  errorMessageGetAppointmentLocations = 'Une erreur s\’est produite, les données pour la selection des types de localisation d\'un rendez-vous n\'ont pas été récupérées'
  
  // Root use ==> api/Projects/Selectable
  errorMessageGetReferentialProjectInProgress = 'Une erreur s\’est produite, les données pour la selection d\'un projet n\'ont pas été récupérées';

  // Root use ==> api/Referentials/AppointmentModalities
  errorMessageGetAppointmentModalities = 'Une erreur s\’est produite, les données pour la selection des types de modalités d\'un rendez-vous n\'ont pas été récupérées';
  
  // Root use ==> api/Referentials/AppointmentRdvTypes
  errorMessageGetAppointmentRdvTypes = 'Une erreur s\’est produite, les données pour la selection des types de rendez-vous n\'ont pas été récupérées';

  // Root use ==> api/Referentials/AppointmentCodeConclusions
  errorMessageGetAppointmentCodeConclusion = 'Une erreur s\’est produite, les données pour la selection de code de conclusion n\'ont pas été récupérées';

  // Root use ==> api/Referentials/AppointmentCodeConclusions => Aucun code conclusion found
  errorMessageGetAppointmentCodeConclusionCustom = 'Aucune données pour la selection de code de conclusion n\'a été trouvées';

  // Root use ==> api/Referentials/RdvDoneValues
  errorMessageGetRdvDoneValue = 'Une erreur s\’est produite, les données pour la selection de code de fermeture d\'un rendez-vous n\'ont pas été récupérées';

  // Route use POST ==>  api/Appointments/{id}/Close + JSON Appointment (voir swagger)
  errorMessagePostAppointmentClose = 'Une erreur s\’est produite, le rendez-vous n\'à pas été fermé';

  /************************************************************ Component : popup create save search ( STOCK ) *********************************************************************************************/

  // Root use ==> api/Research/ + JSON
  errorMessagePostResearchStock = 'Une erreur s\’est produite, les données pour la recherche de stock n\'ont pas été récupérées';

  /************************************************************ Component : tablehousing ( PROJET, RDV ) *********************************************************************************************/

  // Root use ==> api/Projects/{id}
  errorMessageGetProjectWithId = 'Une erreur s\’est produite, les données du projet selectionné n\'ont pas été récupérées';

  /************************************************************ Component : popup completed project ( PROJETS ) *********************************************************************************************/

  // Root use ==> api/Referentials/CustomerProperties
  errorMessageGetCustomerProperties = 'Une erreur s\’est produite, les données pour la selection des situations n\'ont pas été récupérées';


  // Root use ==> api/Referentials/PurchaseTimeframes
  errorMessageGetPurchaseTimeframes = 'Une erreur s\’est produite, les données pour la selection d\'horizon d\'investisment n\'ont pas été récupérées';


  // Root use ==> api/Referentials/MainSearchprofils
  errorMessageGetMainSearchprofils = 'Une erreur s\’est produite, les données pour la selection d\'objectif n\'ont pas été récupérées';


  // Root use ==> api/Referentials/InvestmentTypes
  errorMessageGetInvestmentTypes = 'Une erreur s\’est produite, les données pour la selection des produits ciblés n\'ont pas été récupérées';


  // Root use ==> api/Referentials/InvestmentTypes
  errorMessageGetAssetTypes = 'Une erreur s\’est produite, les données pour la selection des typologies n\'ont pas été récupérées';


  // Root use ==> api/Referentials/InvestorProfiles
  errorMessageGetInvestorProfiles = 'Une erreur s\’est produite, les données pour la selection des profils n\'ont pas été récupérées';


  // Root use ==> api/Referentials/InvestmentObjectives
  errorMessageGetInvestmentObjectives = 'Une erreur s\’est produite, les données pour la selection d\'objectif d\'investissement n\'ont pas été récupérées';


  // Root use ==> api/Referentials/CommunicationChannel
  errorMessageGetCommunicationChannel = 'Une erreur s\’est produite, les données pour la selection des canaux n\'ont pas été récupérées';


  // Root use ==> api/Referentials/EmploymentContractTypes
  errorMessageGetEmploymentContractTypes = 'Une erreur s\’est produite, les données pour la selection des contrats de travail n\'ont pas été récupérées';


  // Root use ==> api/Referentials/TaxExemptionLaws
  errorMessageGetTaxExemptionLaws = 'Une erreur s\’est produite, les données pour la selection des lois fiscales n\'ont pas été récupérées';


  // Root use ==> api/Referentials/Salutations
  errorMessageGetSalutations = 'Une erreur s\’est produite, les données pour la selection des civilités n\'ont pas été récupérées';


  // Root use ==> api/Referentials/MatrimonialRegimes
  errorMessageGetMatrimonialRegimes = 'Une erreur s\’est produite, les données pour la selection des régimes matrimoniaux n\'ont pas été récupérées';


  // Root use ==> api/Referentials/Csps
  errorMessageGetCsps = 'Une erreur s\’est produite, les données pour la selection des catégories socio-professionnelles n\'ont pas été récupérées';


  // Root use ==> api/Referentials/FamilySituations
  errorMessageGetFamilySituations = 'Une erreur s\’est produite, les données pour la selection des situations familiales n\'ont pas été récupérées';


  // Root use ==> api/Referentials/MarketingOrigins
  errorMessageGetMarketingOrigins = 'Une erreur s\’est produite, les données pour la selection des origines marketing n\'ont pas été récupérées';

  // Root use ==> api/Referentials/ProjectCodeConclusions
  errorMessageProjectListCodeConclusion = 'Une erreur s\’est produite, les données pour la selection de code de conclusion n\'ont pas été récupérées';

  // Root use ==> api/Referentials/ProjectCodeConclusions
  errorMessageProjectListCodeConclusionCustom = 'Aucune données pour la selection de code de conclusion d\'un projet n\'a pas été trouvées';

  // Root use PUT ==> api/Projects/{id} + JSON (voir swagger)
  errorMessagePutProject = 'Une erreur s\’est produite, les données saisies pour modifier le projet n\'ont pas été modifiées';

  /************************************************************ Component Calandar ( RDV ) *********************************************************************************************/

  // Route use ==>  api/Appointments/Search?Page=' + 1 + '&PageSize=' + 1000 + '&ScheduledStart=' + start day + '&ScheduledEnd=' + end day
  errorMessageGetAppointmentWithStartAndEndDate = 'Une erreur s\’est produite, les données de rendez-vous n\'ont pas été récupérées';

  // Route use ==>  api/Appointments/Search?Page=' + 1 + '&PageSize=' + 1000 + '&ScheduledStart=' + start day + '&ScheduledEnd=' + end day
  errorMessageGetAppointmentCalendar = "Erreur lors de la récupération du calendrier. Adresse mail non active."

  // Route use ==>  api/Appointments/{id}
  errorMessageGetAppointmentId = 'Une erreur s\’est produite, les données du rendez-vous sélectionné n\'ont pas été récupérées';


  // Route use POST ==>  api/Appointments/ + JSON Appointment (voir swagger)
  errorMessagePostAppointment = 'Une erreur s\’est produite, les données du rendez-vous saisi n\'ont pas été enregistrées';


  // Route use UPDATE ==>  api/Appointments/{id} + JSON Appointment (voir swagger)
  errorMessageUpdateAppointmentId = 'Une erreur s\’est produite, les données du rendez-vous saisi n\'ont pas été modifiées';


  /**************************************** Component : card program ( STOCK ) / popup completed project ( PROJETS ) *************************************************************/

  // Route use ==>  api/Operations/{id}/Typologies
  errorMessageGetTypologyWithIdOperation = 'Une erreur s\’est produite, les données des typologies de l\'opération n\'ont pas été récupérées';


  /************************************************************ Page HOME ( STOCK ) *********************************************************************************************/

  // Route use ==>  api/FrontSelections/FrontSelections/Operations
  errorMessageGetFrontSelections = 'Une erreur s\’est produite, les données de sélections du carousel n\'ont pas été récupérées';


  // Route use ==>  api/Referentials/Filters/InStock
  errorMessageGetReferentialStock = 'Une erreur s\’est produite, les données des selections de la recherche n\'ont pas été récupérées';


  // Route use ==>  api/Operations/
  errorMessageGetOperationWithId = 'Une erreur s\’est produite, l\'opération n\'à pas été trouvée pour récupérer les données de l\'opération';


  // Route use ==>  api/Operations/{id}/Thumbnails
  errorMessageGetOperationThumbnailsWithId = 'Une erreur s\’est produite, l\'opération n\'à pas été trouvée pour récupérer les images et/ou vidéos de l\'opération';


  // Route use ==>  api/Operations/{id}/Particularities
  errorMessageGetOperationParticularitiesWithId = 'Une erreur s\’est produite, l\'opération n\'a pas été trouvée pour récupérer les particularités de l\'opération';


  // Route use ==>  api/Operations/{id}/Assets
  errorMessageGetAssetsWithOperationId = 'Une erreur s\’est produite, l\'opération n\'a pas été trouvée pour récupérer les lots de l\'opération';


  // Route use ==>  api/Operations/{id}/Documents
  errorMessageGetOperationDocumentWithId = 'Une erreur s\’est produite, l\'opération n\'a pas été trouvée pour récupérer les documents de l\'opération';


  // Route use ==>  api/Documents/Zip + JSON => listDocumentZip
  // {
  //   "OperationId": "550e6c80-130d-e811-a955-000d3ab27f0e",
  //   "Documents": [
  //     "ac0f506a-ea4e-e811-a959-000d3ab27d56", "de731bc7-b3ff-e911-a811-000d3aba7e84"
  //   ]
  // };
  errorMessageGetZipDocumentWithId = 'Une erreur s\’est produite, le document sera bientôt disponible';


  // Route use ==>  api/Documents/Zip + JSON => listDocumentZip
  // {
  //   "OperationId": "550e6c80-130d-e811-a955-000d3ab27f0e",
  //   "Documents": [
  //     "ac0f506a-ea4e-e811-a959-000d3ab27d56", "de731bc7-b3ff-e911-a811-000d3aba7e84"
  //   ]
  // };
  errorMessageGetPdfBookDocumentWithId = 'Une erreur s\’est produite, le document sera bientôt disponible';


  // Route use ==>  Documents/View/
  errorMessageGetShowDocumentWithId = 'Une erreur s\’est produite, le document sera bientôt disponible';


  // Route use ==>  api/Operations/MapMarkers + JSON => Map marker (voir swagger)
  errorMessageGetOperationWithMapMarkers = 'Une erreur s\’est produite, les opérations à afficher sur la carte n\'ont pas été récupérées';


  // Route use ==>  api/Assets/Search?Page=' + 1 + '&PageSize=' + 6 + JSON => Map marker (voir swagger)
  errorMessageGetOperationWithLocalization = 'Une erreur s\’est produite, les lots n\'ont pas été récupérés';

    
  // Route use ==>  api/Assets/Search/Count + JSON => Map marker (voir swagger)
  errorMessageGetCountAssetWithLocalization = 'Une erreur s\’est produite, le nombre de lots n\'a pas été récupéré';
  

  // Route use ==>  api/Assets/Search?Page=' + 1 + '&PageSize=' + 11 + JSON => Map marker (voir swagger)
  errorMessageGetAssetWithLocalization = 'Une erreur s\’est produite, les lots n\'ont pas été récupérées';


  // Route use ==>  api/Operations/Search/Count + + JSON (voir swagger)
  errorMessageGetAllNumberOperationWithSizeMap = 'Une erreur s\’est produite, le nombre d\'opérations n\'a pas été récupéré';

  // Route use ==>  api/HistoryStock/Search/Count + + JSON (voir swagger)
  errorMessageGetAllNumberHistoryStockWithSizeMap = 'Une erreur s\’est produite, le nombre d\'opérations de l\'historique des stocks n\'a pas été récupéré';

  // Route use ==>  api/HistoryStock/MapMarkers + JSON => Map marker (voir swagger)
  errorMessageGetHistoryStockWithMapMarkers = 'Une erreur s\’est produite, les opérations de l\'historique des stocks pour la carte n\'ont pas été récupérées';


  // Route use ==>  api/HistoryStock/Search?Page=' + 1 + '&PageSize=' + 6 + JSON => Map marker (voir swagger)
  errorMessageGetHistoryStockWithLocalization = 'Une erreur s\’est produite, les opération de l\'historique des stocks à afficher sur la carte n\'ont pas été récupérées';

  errorMessageGetTrackedWorkSite = "Une erreur s\'est produite, aucun suivi de chantier n'a été trouvé";
  errorMessageGetGenerateTrackedWorkSite = "Une erreur s\'est produite, suite au téléchargement de votre suivi de chantier";
}
