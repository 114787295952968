// import { UserRole } from './../../shared/enum/user-role.enum';

export class User {
    /** 
     * @param Erip Check if Erip is globally activated (for the client).
    */
    public "Erip"!: boolean;

    public "SaleDestinations"!: Array<string>;
    public "CancelProposition"!: boolean;
    public "CreateLead"!: boolean;
    public "CreateOption"!: boolean;
    public "CreateProject"!: boolean;
    public "DuplicateProject"!: boolean;
    public "ShowOperationProgression"!: boolean;
    public "ShowComparator"!: boolean;
    public "ShowFavorite"!: boolean;
    public "ShowWorksite"!: boolean;
    public "StatusCodes"!: Array<number>;
    public "Security"!: { "leadprovider_token": string };
    public "channel.if_assetdiffusion"!: number;
    public "channel.if_assetpricing_current_selection"!: number;
    public "channel.if_assetpricing_target_selection"!: string;
    public "channel.if_assetpricingdiffusion"!: number;
    public "channel.if_diffusionchannelid"!: string;
    public "channel.if_documentdiffusion"!: number;
    public "if_commercialassignmentid"!: string;
    public "if_salescat_calc"!: number;
    public "partneraccount.emailaddress"!: string;
    public "partneraccount.if_city"!: string;
    public "partneraccount.if_mobilephone"!: string;
    public "partneraccount.if_name"!: string;
    public "partneraccount.if_partneraccountid"!: string;
    public "partneraccount.if_phonenumber1"!: string;
    public "partneraccount.if_postalcode"!: string;
    public "partneraccount.if_street1"!: string;
    public "partneraccount.if_street2"!: string;
    public "partnercontact.emailaddress"!: string;
    public "partnercontact.if_city"!: string;
    public "partnercontact.if_mobilephone"!: string;
    public "partnercontact.if_defaultcommercialassigmentid"!: string;
    
    public "partnercontact.if_extranetuser"!: string;
    public "partnercontact.if_firstname"!: string;
    public "partnercontact.if_internalcontact"!: number;
    public "partnercontact.if_lastname"!: string;
    public "partnercontact.if_mustchangepassword"!: number;
    public "partnercontact.if_mustreadconditions"!: number;
    public "partnercontact.if_partnercontactid"!: string;
    public "partnercontact.if_postalcode"!: string;
    public "partnercontact.if_name"!: string;
    public "partnercontact.if_street1"!: string;
    public "partnercontact.if_street2"!: string;
    public "role.if_calendarvisibilitylevel"!: number;
    public "role.if_fonc_project_creation"!: number;
    public "role.if_fonc_project_duplication"!: number;
    public "role.if_name"!: string;
    public "role.if_salesaffectation"!: number;
    public "role.if_sequence"!: number;
    public "role.if_visibilitylevel"!: number;
    public "session.Token"!: string;
    public "society.if_commercialsocietyid"!: string;
    public "society.if_ml_cgu_buttontitle"!: string;
    public "society.if_ml_cgu_title"!: string;
    public "society.if_ml_cgv_buttonname"!: string;
    public "society.if_ml_cgv_title"!: string;
    public "society.if_ml_ml_buttonname"!: string;
    public "society.if_ml_ml_title"!: string;
    public "society.if_ml_pc_buttontitle"!: string;
    public "society.if_ml_pc_title"!: string;
    public "society.if_ml_popup_buttonname"!: string;
    public "society.if_ml_popup_title"!: string;
    public "society.if_name"!: string;
    public "ManagerView"!: boolean;
    
    // Référent du CGP
    public "systemuser.address1_telephone1"!: string;
    public "systemuser.fullname"!: string;
    public "systemuser.internalemailaddress"!: string;








    /**
     * @param partnercontact.if_erip_active Return '1' if Erip is activated for this profile.
     */
    public "partnercontact.if_erip_active"!: number;
	
	public image!: string;
	public createdAt?: Date;

	constructor(input?: User) {
		Object.assign(this, input);
		this.createdAt = new Date(Date.now());
	}
}